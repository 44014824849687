import React from 'react'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import { TextInput } from './TextInput'
import { PasswordValidator } from '../utilities/commonValidators'

export default function UserPasswordChangeForm ({ onSubmit, disabled = false }) {
  return (
    <div className='row panel panel-default'>
      <div className='container-fluid push-down-super-small'>
        <Form onSubmit={onSubmit} validate={validate}>
          {({ pristine, invalid, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className='col-xs-12'>
                <Field
                  name='currentPassword'
                  type='password'
                  label='Current Password'
                  component={TextInput}
                  placeholder='Enter your current password'
                  autoComplete='current-password'
                />
                <Field
                  name='newPassword'
                  type='password'
                  label='New Password'
                  component={TextInput}
                  placeholder='Enter a new password'
                  autoComplete='new-password'
                />
                <Field
                  name='passwordConfirmation'
                  type='password'
                  label='New Password Confirmation'
                  component={TextInput}
                  placeholder='Enter the same new password'
                  autoComplete='new-password'
                />
                <button
                  type='submit'
                  className='btn btn-lg btn-primary pull-right space-left'
                  disabled={pristine || invalid || disabled}>Save New Password
                </button>
              </div>
            </form>
          )}
        </Form>
      </div>
      <br />
    </div>
  )
}

function validate (values) {
  const errors = {}

  if (!values.currentPassword) {
    errors.currentPassword = 'Required'
  } else if (!PasswordValidator.test(values.currentPassword)) {
    errors.currentPassword = 'Current password must be at least 6 characters'
  }

  if (!values.newPassword) {
    errors.newPassword = 'Required'
  } else if (!PasswordValidator.test(values.newPassword)) {
    errors.newPassword = 'New password must be at least 6 characters'
  }

  if (!values.passwordConfirmation) {
    errors.passwordConfirmation = 'Required'
  } else if (!PasswordValidator.test(values.passwordConfirmation)) {
    errors.passwordConfirmation = 'Password confirmation must be at least 6 characters'
  } else if (values.newPassword !== values.passwordConfirmation) {
    errors.passwordConfirmation = 'Password does not match new password'
  }

  return errors
}

UserPasswordChangeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}
