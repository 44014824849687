import { loader } from 'graphql.macro'

export default async function deleteAssetFromJob ({ dataLoaders, variables }) {
  const schema = loader('./deleteAssetFromJob.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      assetId: variables.assetId,
      setOnSelf: variables.setOnSelf
    }
  })
  return response.deleteAssetFromJob
}
