
export const states = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
}

export const provinces = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NS: 'Nova Scotia',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan'
}

export const stateToAbbreviation = (state) => {
  if (state.length === 2) return state

  const stateMapping = {
    // US
    alabama: 'AL',
    alaska: 'AK',
    americansamoa: 'AS',
    arizona: 'AZ',
    arkansas: 'AR',
    california: 'CA',
    colorado: 'CO',
    connecticut: 'CT',
    delaware: 'DE',
    districtofcolumbia: 'DC',
    federatedstatesofmicronesia: 'FM',
    florida: 'FL',
    georgia: 'GA',
    guam: 'GU',
    hawaii: 'HI',
    idaho: 'ID',
    illinois: 'IL',
    indiana: 'IN',
    iowa: 'IA',
    kansas: 'KS',
    kentucky: 'KY',
    louisiana: 'LA',
    maine: 'ME',
    marshallislands: 'MH',
    maryland: 'MD',
    massachusetts: 'MA',
    michigan: 'MI',
    minnesota: 'MN',
    mississippi: 'MS',
    missouri: 'MO',
    montana: 'MT',
    nebraska: 'NE',
    nevada: 'NV',
    newhampshire: 'NH',
    newjersey: 'NJ',
    newmexico: 'NM',
    newyork: 'NY',
    northcarolina: 'NC',
    northdakota: 'ND',
    northernmarianaislands: 'MP',
    ohio: 'OH',
    oklahoma: 'OK',
    oregon: 'OR',
    palau: 'PW',
    pennsylvania: 'PA',
    puertorico: 'PR',
    rhodeisland: 'RI',
    southcarolina: 'SC',
    southdakota: 'SD',
    tennessee: 'TN',
    texas: 'TX',
    utah: 'UT',
    vermont: 'VT',
    virginislands: 'VI',
    virginia: 'VA',
    washington: 'WA',
    westvirginia: 'WV',
    wisconsin: 'WI',
    wyoming: 'WY',

    //  Canada
    alberta: 'AB',
    britishcolumbia: 'BC',
    manitoba: 'MB',
    newbrunswick: 'NB',
    newfoundlandandlabrador: 'NL',
    novascotia: 'NS',
    ontario: 'ON',
    princeedwardisland: 'PE',
    québec: 'QC',
    quebec: 'QC',
    saskatchewan: 'SK'
  }
  const normalizedState = state.toLowerCase().replace(/\s/g, '')
  return stateMapping[normalizedState]
}

export const prettyAddress = (components) => {
  let str = ''
  str += components?.addressline1 || components?.calculated_addressline1 || components?.addressLine1 || 'unknown'
  str += ` ${components?.city || components?.calculated_city || 'unknown'}`
  str += `, ${components?.state || components?.calculated_state || 'unknown'}`
  str += ` ${components?.zip || components?.calculated_zip || 'unknown'}`

  return str
}

export const prettyAddressWithDetails = (components) => {
  let pretty = prettyAddress(components)
  if (components && (components.address_details || components.addressDetails)) {
    pretty += ` (${components.address_details || components.addressDetails})`
  } else if (components && components.job && (components.job.address_details || components.job.addressDetails)) {
    pretty += ` (${components.job.address_details || components.job.addressDetails})`
  } else if (components && components.addressDetails) {
    pretty += ` (${components.addressDetails})`
  }
  return pretty
}

export const googleMapsLink = (components) => {
  const place = `${components.latitude || components.calculated_latitude}, ${components.longitude || components.calculated_longitude}`
  return `https://www.google.com/maps/place/${encodeURIComponent(place)}`
}

export const isLatitude = num => isFinite(num) && Math.abs(num) <= 90
export const isLongitude = num => isFinite(num) && Math.abs(num) <= 180

export const buildAddressComponents = (place) => {
  const addressComponents = {}
  place.address_components.map((obj) => {
    const key = obj.types[0]
    const val = obj.long_name
    addressComponents[key] = val
    return null
  })
  return addressComponents
}
