/**
 * Parsers that work especially well with react-final-forms `parse` <Field parse={parser} /> prop.
 */

/**
 * Parse USD formatting to floating point number, retaining a maximum of 2 decimals and periods.
 *
 * @param {string|number} value
 * @return {string}
 *
 * @example
 * parseUSDToFloat(200) // 200
 * formatToUsd(200.) // 200
 * formatToUsd(200.33) // 200.233
 */
export function parseUSDToNumber (value) {
  if (!value || typeof value !== 'string') return value
  return value.trim().replace(/[^0-9.]/g, '')
}
