import { put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import BASE_URI from '../../../../utilities/BASE_URI'
import { getHeaders } from '../../../../utilities/getHeaders'
import notify from '../../../../utilities/notify'

export const REQUEST_CURRENT_HAULER_TEAM_MEMBER_NOTIFICATIONS_ACTION = 'REQUEST_CURRENT_HAULER_TEAM_MEMBER_NOTIFICATIONS_ACTION'
export const RECEIVE_CURRENT_HAULER_TEAM_MEMBER_NOTIFICATIONS_SUCCESS = 'RECEIVE_CURRENT_HAULER_TEAM_MEMBER_NOTIFICATIONS_SUCCESS'
export const RECEIVE_CURRENT_HAULER_TEAM_MEMBER_NOTIFICATIONS_FAILURE = 'RECEIVE_CURRENT_HAULER_TEAM_MEMBER_NOTIFICATIONS_FAILURE'

function * requestCurrentHaulerTeamMemberNotifications (data) {
  try {
    const { id, page } = data.payload
    const response = yield axios.get(`${BASE_URI}/hauler/notifications?receiving_user_id=${id}&page=${page}`, getHeaders())
    yield put({ type: RECEIVE_CURRENT_HAULER_TEAM_MEMBER_NOTIFICATIONS_SUCCESS, payload: response.data })
  } catch (e) {
    const errorMessage = e.response.errors[0].message
    notify('error', errorMessage, 5)
    yield put({ type: RECEIVE_CURRENT_HAULER_TEAM_MEMBER_NOTIFICATIONS_FAILURE, payload: { errors: [{ code: 0, title: errorMessage }] } })
  }
}

export default function * currentHaulerTeamMemberNotificationsSaga () {
  yield takeLatest(REQUEST_CURRENT_HAULER_TEAM_MEMBER_NOTIFICATIONS_ACTION, requestCurrentHaulerTeamMemberNotifications)
}
