import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, Link } from 'react-router-dom'
import dayjs from '../../utilities/dayjs'
import StaticGoogleMap from '../../pages/shared/locationPickers/StaticGoogleMap'
import { ROUTES } from '../../routes'

export default function AssetDetailsDisplay ({ resource }) {
  const parsedDate = dayjs(resource?.lastMoved)
  const formattedDate = parsedDate.format('dddd, MMM D, YYYY h:mm a')
  const formattedDayCount = parsedDate.fromNow()

  return (
    <div className='row'>
      <div className='col-xs-12 col-md-6'>
        <ul className='list-group'>

          <div className='list-group-item'>
            <i className='material-icons large-icon pull-right'>label</i>
            <div className='list-group-item-heading'>Asset Type</div>
            <div className='list-group-item-text'>
              <Link to={'/hauler/resource-types'}>
                {resource?.resourceType.name}
              </Link>
            </div>
          </div>
          {resource?.lastMoved && <div className='list-group-item'>
            <i className='material-icons large-icon pull-right'>access_time</i>
            <div className='list-group-item-heading'>Set At Current Location</div>
            <div className='list-group-item-text'>
              <strong>
                {formattedDayCount}&nbsp;
              </strong>
              on {formattedDate}
            </div>
          </div>}
          <div className='list-group-item'>
            <i className='material-icons large-icon pull-right'>speaker_notes</i>
            <div className='list-group-item-heading'>Placement Note</div>
            <div className='list-group-item-text'>
              {resource.placementNote || 'No Placement Note'}
            </div>
          </div>

        </ul>
      </div>
      <div className='col-xs-12 col-md-6'>
        <ul className='list-group'>

          <div className='list-group-item'>
            <i className='material-icons large-icon pull-right'>map</i>
            <div className='list-group-item-heading'>Current Location</div>
            <div className='list-group-item-text'>
              {resource.job?.id && <div>
                <p>
                  Being Used by&nbsp;
                  <Link to={`/hauler/jobs/${resource.job?.id}`}>
                    <strong>Job {resource.job?.customId}</strong>
                  </Link>
                </p>
              </div>}
              {resource.site?.id && <div>
                <p>
                  Being Used by&nbsp;
                  <Link to={generatePath(ROUTES.site, { id: resource.site?.id })}>
                    <strong>Site {resource.site.name}</strong>
                  </Link>
                </p>
              </div>}
              {resource?.currentLocation?.latitude && resource?.currentLocation?.longitude
                ? <StaticGoogleMap
                    latitude={resource?.currentLocation?.latitude}
                    longitude={resource?.currentLocation?.longitude}
                    addrComponents={resource.currentLocation}
                  />
                : <h3 className='text-center'>No Location to Map</h3>}
            </div>
          </div>
        </ul>
      </div>
    </div>
  )
}

AssetDetailsDisplay.propTypes = {
  resource: PropTypes.object.isRequired
}
