import React from 'react'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import AutocompleteGoogleMap from '../../pages/shared/locationPickers/AutocompleteGoogleMap'
import { prettyAddress } from '../../utilities/locationUtilities'
import { required } from '../../utilities/commonValidators'

export default function SignUpStepTwo ({ onNext, onPrev, signUpData }) {
  function handleLocationChange (form, newLocation) {
    form.batch(() => {
      form.change('latitude', newLocation.latitude)
      form.change('longitude', newLocation.longitude)
      form.change('placeId', newLocation.place_id)
      form.change('addressline1', newLocation.addressline1)
      form.change('city', newLocation.city)
      form.change('state', newLocation.state)
      form.change('zip', newLocation.zip)
      form.change('country', newLocation.country)
    })
  }

  return (
    <div className='panel text-center'>
      <div className='container-fluid text-center'>
        <br />
        <h4>Where is your company based?</h4>
      </div>
      <Form onSubmit={onNext} initialValues={{
        latitude: signUpData.latitude,
        longitude: signUpData.longitude,
        placeId: signUpData.placeId,
        addressline1: signUpData.addressline1,
        city: signUpData.city,
        state: signUpData.state,
        zip: signUpData.zip,
        country: signUpData.country
      }}>
        {({ invalid, form, handleSubmit, values }) => (
          <form className='container-fluid spacer__bottom--large row' onSubmit={handleSubmit}>
            <div className='container-fluid text-center'>
              <AutocompleteGoogleMap
                placeholder={'Your company location'}
                latitude={signUpData.latitude}
                longitude={signUpData.longitude}
                place_id={signUpData.placeId}
                initialValue={signUpData.addressline1 ? prettyAddress(signUpData) : ''}
                onLocationChange={newLocation => { handleLocationChange(form, newLocation) }}
              />

              {/* Hidden fields still need to be here for react-final-form to consider them in form state i.e. pristine */}
              <Field validate={required} name='latitude'>{() => null}</Field>
              <Field validate={required} name='longitude'>{() => null}</Field>
              <Field validate={required} name='placeId'>{() => null}</Field>
              <Field validate={required} name='addressline1'>{() => null}</Field>
              <Field validate={required} name='city'>{() => null}</Field>
              <Field validate={required} name='state'>{() => null}</Field>
              <Field validate={required} name='zip'>{() => null}</Field>
              <Field validate={required} name='country'>{() => null}</Field>

            </div>
            <div className='row center__all--large'>
              <button
                type='button'
                className='btn btn-link btn-lg push-down-very-small'
                onClick={() => onPrev(values)}>
                Back
              </button>
              <button
                type='submit'
                className='btn btn-success btn-lg push-down-very-small space-left'
                disabled={invalid}>
                Next
              </button>
            </div>
          </form>
        )}
      </Form>
    </div>
  )
}

SignUpStepTwo.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  signUpData: PropTypes.object.isRequired
}
