import React from 'react'
import PropTypes from 'prop-types'
import ResourceTypeStatusLabel from '../../../../shared/ResourceTypeStatusLabel'
import { generatePath, Link } from 'react-router-dom'
import { ROUTES } from '../../../../../routes'

export default function ResourceType ({ resourceType }) {
  const editResourceTypePath = generatePath(ROUTES.editResourceType, { id: resourceType.id })
  const search = `?${new URLSearchParams({ resourceTypeId: resourceType.id }).toString()}`
  const ticketsSearchPath = { pathname: ROUTES.ticketSearch, search }
  const resourcesPath = { pathname: ROUTES.resources, search }
  return (
    <div className='list-group-item'>
      <h4 className='list-group-item-heading'>
        <div className='font-size-0-75em inline-block status-pill'>
          <ResourceTypeStatusLabel status={resourceType.status} />
        </div>
        &nbsp;
        {`${resourceType.name} (${resourceType.shortCode})`}
        <Link className='btn btn-primary-dk pull-right' to={editResourceTypePath}>
          <span className='pull-left hidden-xs'>Edit Asset Type</span>
          <i className='material-icons pull-right thumbster-button-icon'>mode_edit</i>
        </Link>
        <Link className='btn btn-link pull-right hidden-xs' to={ticketsSearchPath}>
          <span className='pull-left'>{resourceType.ticketsCount} TICKETS</span>
          <i className='material-icons pull-right thumbster-button-icon'>search</i>
        </Link>
        {resourceType.activeResourcesCount > 0 && (
          <Link className='btn btn-link pull-right hidden-xs' to={resourcesPath}>
            <span className='pull-left'>{`${resourceType.activeResourcesCount} Assets`}</span>
            <i className='material-icons pull-right thumbster-button-icon'>search</i>
          </Link>
        )}
      </h4>
    </div>
  )
}

ResourceType.propTypes = {
  resourceType: PropTypes.object.isRequired
}
