import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Form, Field, FormSpy } from 'react-final-form'
import fromPairs from 'lodash/fromPairs'
import map from 'lodash/map'
import { TextInput } from './TextInput'
import { DatePickerInput } from './DatePickerInput'
import { SelectInput } from './SelectInput'
import { required } from '../utilities/commonValidators'

export default function DispatchTicketSearchForm ({
  onSubmit,
  ticketTypes = [],
  resourceTypes = [],
  drivers = [],
  initialValues,
  handleClear
}) {
  const ticketTypesOptions = useMemo(() => {
    return fromPairs(map(ticketTypes, tt => [tt.id, tt.name]))
  }, [ticketTypes])

  const resourceTypesOptions = useMemo(() => {
    return fromPairs(map(resourceTypes, rt => [rt.id, rt.name]))
  }, [resourceTypes])

  const driversOptions = useMemo(() => {
    return fromPairs(map(drivers, d => [d.id, `${d.firstName} ${d.lastName}`]))
  }, [drivers])

  function handleSubmit (values, form) {
    const formState = form.getState()
    if (formState.invalid) return
    onSubmit(values)
  }

  return (
    <div className='row panel panel-default dispatch-panel'>
      <Form initialValues={initialValues} onSubmit={handleSubmit} validate={validate}>
        {({ form, handleSubmit, values }) => (
          <form onSubmit={handleSubmit} autoComplete='off'>
            <div className='col-xs-12 col-sm-12 col-md-2'>
              <Field
                name='date'
                placeholder='Date'
                component={DatePickerInput}
              />
            </div>

            <div className='col-xs-12 col-sm-12 col-md-1'>
              <Field
                name='status'
                placeholder='Status'
                component={SelectInput}
                options={{
                  active: '- All Active -',
                  open: '  Open',
                  en_route: '  En Route',
                  terminal: '- All Closed -',
                  cancelled: '  Cancelled',
                  completed: '  Completed'
                }}
              />
            </div>

            <div className='col-xs-12 col-sm-12 col-md-2'>
              <Field
                name='address'
                placeholder='Address'
                component={TextInput}
              />
            </div>

            <div className='col-xs-12 col-sm-12 col-md-2'>
              <Field
                name='ticketTypeId'
                placeholder='Ticket Type'
                component={SelectInput}
                options={ticketTypesOptions}
              />
            </div>

            <div className='col-xs-12 col-sm-12 col-md-2'>
              <Field
                name='resourceTypeId'
                placeholder='Asset Type'
                component={SelectInput}
                options={resourceTypesOptions}
              />
            </div>

            <div className='col-xs-12 col-sm-12 col-md-2'>
              <Field
                name='driverId'
                alphabetize
                placeholder='Driver'
                component={SelectInput}
                options={driversOptions}
              />
            </div>

            <div className='col-xs-12 col-sm-12 col-md-1 margin-top-0-5-pct'>
              <button
                className='btn btn-sm btn-default pull-right'
                type='button'
                onClick={() => handleClear(values, form)}>
                <span className='pull-left' style={{ marginTop: '6%' }}>Clear</span>
                <i className='material-icons pull-right'>clear</i>
              </button>
            </div>

            <FormSpy subscription={{ values: true, invalid: true }} onChange={() => form.submit()} />
          </form>
        )}
      </Form>
    </div>
  )
}

DispatchTicketSearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  drivers: PropTypes.arrayOf(PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string
  })),
  resourceTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })),
  ticketTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })),
  initialValues: PropTypes.shape({
    date: PropTypes.string,
    status: PropTypes.string,
    address: PropTypes.string,
    ticketTypeId: PropTypes.string,
    resourceTypeId: PropTypes.string,
    driverId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }).isRequired,
  handleClear: PropTypes.func.isRequired
}

function validate (values) {
  return {
    date: required(values.date)
  }
}
