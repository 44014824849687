import React from 'react'
import PropTypes from 'prop-types'

export default function FormExplanation ({ messages }) {
  return (
    <div>
      <h5 className='text-center'> HELP </h5>
      {
        messages.map(message => (
          <div key={message}>
            <hr />
            <h6>{message}</h6>
          </div>
        ))
      }
    </div>
  )
}

FormExplanation.propTypes = {
  messages: PropTypes.array
}
