import React from 'react'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import { TextInput } from './TextInput'
import { composeValidators, mustMatch, required, validPassword } from '../utilities/commonValidators'

export default function NewPasswordForm ({ onSubmit, disabled = false }) {
  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, pristine, invalid, values }) => (
        <form onSubmit={handleSubmit}>
          <div className='row'>
            <div className='center__all--small'>
              <Field
                name='password'
                type='password'
                label='Password'
                component={TextInput}
                autoComplete='new-password'
                validate={composeValidators(required, validPassword)}
              />
              <Field
                name='passwordConfirmation'
                type='password'
                label='Password Confirmation'
                component={TextInput}
                autoComplete='new-password'
                validate={composeValidators(required, validPassword, mustMatch(values.password, 'Password'))}
              />
              <button
                type='submit'
                className='btn btn-success push-down-very-small'
                disabled={pristine || invalid || disabled}>
                <span className='pull-left'>Save New Password</span>
                <i className='material-icons pull-right thumbster-button-icon'>check</i>
              </button>
            </div>
          </div>
        </form>
      )}
    </Form>
  )
}

NewPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}
