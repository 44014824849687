import { intercomConnection } from '../../intercomConnection'

export default async function updateTicketFlagged ({ dataLoaders, variables }) {
  const response = await dataLoaders.thumbster.axios.post(
    `/hauler/tickets/${variables.ticketId}/actions/change_flag`,
    { flag: variables.flag }
  )
  intercomConnection.trackEvent('ticket_flag_changed', { flag: variables.flag })
  return response.data
}
