import React from 'react'
import { useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { Link, useHistory, useParams } from 'react-router-dom'
import notify from '../../utilities/notify'
import { ROUTES } from '../../routes'
import QUERY_KEYS from '../../graphql/queryKeys'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import PageTitle from '../../components/page-title'
import { Spinner } from '../shared/Spinner'
import FormExplanation from '../shared/FormExplanation'
import FeeTypeForm from '../../components/fee-type/FeeTypeForm'
import FeeTypeStatusLabel from '../shared/FeeTypeStatusLabel'
import updateFeeTypeMutation from '../../graphql/mutations/updateFeeType'
import feeTypeQuery from '../../graphql/queries/feeType'
import feeTypeQuickbooksItemQuery from '../../graphql/queries/feeTypeQuickbooksItem'
import styles from './index.module.scss'
import cn from 'classnames'
import useMutation from '../../hooks/useMutation'
import useQuery, { generateQueryKey } from '../../hooks/useQuery'
import { parseUSDToNumber } from '../../utilities/parsers'
import PropTypes from 'prop-types'
import unlinkFeeTypeFromQboMutation from '../../graphql/mutations/unlinkFeeTypeFromQbo'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import { useQueryClient } from 'react-query'

export default function EditFeeTypePage () {
  const { id } = useParams()
  const history = useHistory()
  const { user, hauler } = useSelector(({ user, hauler }) => ({ user: user.user, hauler: user.hauler }))
  const queryClient = useQueryClient()

  const { data: feeTypeData, isFetching: isFetchingFeeType } = useQuery([
    QUERY_KEYS.feeType,
    user.haulerId,
    id
  ],
  feeTypeQuery,
  {
    onError () {
      notify('error', 'Error fetching fee type')
    }
  })

  const { data: feeTypeQuickbooksItemData, isFetching: isFetchingQuickbooksItem } = useQuery(
    [QUERY_KEYS.feeTypeQuickbooksItem, { id: feeTypeData?.feeType.id, haulerId: user.haulerId }],
    feeTypeQuickbooksItemQuery,
    {
      enabled: hauler.quickbooks.isConnected && feeTypeData?.feeType.qboItemId != null
    }
  )

  const { mutateAsync: updateFeeType, isLoading: isUpdatingFeeType } = useMutation(updateFeeTypeMutation, {
    onSuccess () {
      notify('success', 'Fee Type updated successfully')
      history.push(ROUTES.feeTypes)
    },
    onError () {
      notify('error', 'Failed to update fee type')
    }
  })

  const { mutate: unlinkFeeTypeFromQbo, isLoading: isUnlinkingFeeTypeFromQbo } = useMutation(unlinkFeeTypeFromQboMutation, {
    onSuccess (data) {
      const feeTypeQueryKey = generateQueryKey([QUERY_KEYS.feeType, user.haulerId, data.feeType.id], user.id)
      queryClient.setQueryData(feeTypeQueryKey, oldData => ({
        ...(oldData ?? {}),
        feeType: { ...(oldData?.feeType ?? {}), ...data.feeType }
      }))
      const qboItemQueryKey = generateQueryKey([QUERY_KEYS.feeTypeQuickbooksItem, { id: data?.feeType.id, haulerId: user.haulerId }], user.id)
      queryClient.setQueryData(qboItemQueryKey, oldData => ({
        ...(oldData ?? {}),
        feeType: { ...(oldData?.feeType ?? {}), ...data.feeType }
      }))
      notify('success', 'Fee type successfully unlinked from QBO item')
    },
    onError (error) {
      captureErrorAndNotify(error, 'Failed to unlink fee type from QBO item')
    }
  })

  function handleFormSubmit (data) {
    return updateFeeType({
      id,
      name: data.name,
      defaultAmount: parseFloat(parseUSDToNumber(data.defaultAmount)),
      description: data.description ? data.description : null,
      haulerId: user.haulerId
    })
  }

  function updateFeeTypeStatus (status) {
    return updateFeeType({ id, status, haulerId: user.haulerId })
  }

  function handleUnlinkFeeTypeFromQbo () {
    unlinkFeeTypeFromQbo({ id })
  }

  return (
    <NormalLayoutContainer showBackLink>
      <PageTitle className='dis-flex-page-title'>
        <div>Edit Existing Fee Type</div>
        <Link to={ROUTES.feeTypes} className='dis-btn dis-btn-blank dis-btn-lg'>Cancel</Link>
      </PageTitle>

      {isFetchingFeeType
        ? <Spinner isFetching />
        : feeTypeData && (
          <div className='dis-form-page-container'>
            <div className='dis-form-container-left'>
              <h4>Change Status</h4>
              <div className={cn('dis-panel', styles.statusContainer)}>
                <div className={styles.statusLabelContainer}>
                  Current Status:
                  <span className={styles.statusLabel}>
                    <FeeTypeStatusLabel status={feeTypeData.feeType.status} />
                  </span>
                </div>

                <ReactTooltip />
                {feeTypeData.feeType.status === 'enabled'
                  ? (
                    <button
                      className='dis-btn dis-btn-danger btn-sm'
                      onClick={() => updateFeeTypeStatus('disabled')}>
                      <strong>Disable</strong>
                    </button>)
                  : (<button
                      className='dis-btn dis-btn-primary dis-btn-sm'
                      onClick={() => updateFeeTypeStatus('enabled')}>
                    Enable
                  </button>)}
              </div>

              <h4>Change Name and Default Amount</h4>
              <div className='dis-panel'>
                <div className='dis-panel-body'>
                  <FeeTypeForm
                    onSubmit={handleFormSubmit}
                    initialValues={feeTypeData.feeType}
                    disabled={isUpdatingFeeType}
                  />
                </div>
              </div>

              <div className='dis-panel'>
                <div className={cn(styles.qboPanelBody, 'dis-panel-body')}>
                  <div className={styles.qboProduct}>
                    <span className={styles.qboTitle}>QuickBooks Product:</span>
                    <span className={styles.qboProductName}>
                      <QboSyncName
                        qboConnected={hauler.quickbooks.isConnected}
                        feeTypeQboItemId={feeTypeData.feeType.qboItemId}
                        qboItem={feeTypeQuickbooksItemData?.feeType.qboItem}
                      />
                    </span>
                  </div>
                  <QboActionButton
                    qboConnected={hauler.quickbooks.isConnected}
                    feeTypeQboItemId={feeTypeData.feeType.qboItemId}
                    onQboUnlink={handleUnlinkFeeTypeFromQbo}
                    isQboLoading={isFetchingQuickbooksItem || isUnlinkingFeeTypeFromQbo}
                  />
                </div>
              </div>
            </div>
            <div className='dis-form-container-right'>
              <FormExplanation
                messages={[
                  'You can edit the visible name of an existing Fee Type on this page.',
                  'Fee Types Represent the Line Items that you charge for on an Invoice',
                  'Note that editing the name of an Asset Type here changes the name of that Fee Type across the system.'
                ]}
              />
            </div>
          </div>
        )}
    </NormalLayoutContainer>
  )
}

function QboSyncName ({ qboConnected, feeTypeQboItemId, qboItem }) {
  if (!qboConnected || !feeTypeQboItemId) return 'Not Synced'
  if (!qboItem) return 'Unknown'
  return qboItem.name
}
QboSyncName.propTypes = {
  qboConnected: PropTypes.bool.isRequired,
  feeTypeQboItemId: PropTypes.string,
  qboItem: PropTypes.shape({
    name: PropTypes.string.isRequired
  })
}

function QboActionButton ({ qboConnected, feeTypeQboItemId, isQboLoading, onQboUnlink }) {
  if (!qboConnected || !feeTypeQboItemId || isQboLoading) return null

  return (
    <button
      onClick={onQboUnlink}
      className={cn('dis-btn dis-btn-link')}>
      Unlink
    </button>
  )
}
QboActionButton.propTypes = {
  qboConnected: PropTypes.bool.isRequired,
  feeTypeQboItemId: PropTypes.string,
  isQboLoading: PropTypes.bool.isRequired,
  onQboUnlink: PropTypes.func.isRequired
}
