import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

export default function JobStatusLabel ({ status, linkPath }) {
  if (!status) return null

  const label = <span className={labelClasses(status)}>{status.toUpperCase()}</span>
  if (linkPath) {
    return <Link to={linkPath}>{label}</Link>
  }
  return label
}

JobStatusLabel.propTypes = {
  linkPath: PropTypes.string,
  status: PropTypes.oneOf(['open', 'closed'])
}

function labelClasses (status) {
  let classes = 'label label-pill'
  if (status === 'open') {
    classes += ' label-success'
  } else if (status === 'closed') {
    classes += ' label-danger'
  }
  return classes
}
