
import {
  REQUEST_HAULER_TEAM_MEMBER_ACTIVATE_ACTION,
  RECEIVE_HAULER_TEAM_MEMBER_ACTIVATE_SUCCESS,
  RECEIVE_HAULER_TEAM_MEMBER_ACTIVATE_FAILURE
} from '../sagas/haulerTeamMemberActivateDeactivateSaga'

const initialState = {
  isFetching: false,
  errors: []
}

const haulerTeamMemberActivateReducer = (state = initialState, action) => {
  const { payload, type } = action
  switch (type) {
    case REQUEST_HAULER_TEAM_MEMBER_ACTIVATE_ACTION:
      return {
        ...state,
        isFetching: true,
        errors: []
      }
    case RECEIVE_HAULER_TEAM_MEMBER_ACTIVATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errors: []
      }
    case RECEIVE_HAULER_TEAM_MEMBER_ACTIVATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: payload.errors
      }

    default:
      return state
  }
}

export default haulerTeamMemberActivateReducer
