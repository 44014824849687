import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { generatePath, Link, useParams, useHistory } from 'react-router-dom'
import { ObjectParam, useQueryParams } from 'use-query-params'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import PageTitle from '../../components/page-title'
import { Spinner } from '../shared/Spinner'
import QUERY_KEYS from '../../graphql/queryKeys'
import { intercomConnection } from '../../intercomConnection'
import SiteDisplay from '../hauler/sites/_id/index/components/SiteDisplay'
import SiteResourcesDisplay from '../hauler/sites/_id/index/components/SiteResourcesDisplay'
import { ROUTES } from '../../routes'
import useQuery from '../../hooks/useQuery'
import useMutation from '../../hooks/useMutation'
import notify from '../../utilities/notify'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import resourceSearchQuery from '../../graphql/queries/resourcesSearch'
import siteQuery from '../../graphql/queries/site'
import addAssetToSiteMutation from '../../graphql/mutations/addAssetToSite'
import deleteAssetFromSiteMutation from '../../graphql/mutations/deleteAssetFromSite'
import destroySiteMutation from '../../graphql/mutations/destroySite'

const pageSize = 25

export default function SiteDetailsPage () {
  const [fakeCount, setFakeCount] = useState(null)
  const history = useHistory()
  const { id } = useParams()

  const { user, siteResourcesSearchForm } =
    useSelector(({ user, form }) => ({
      user,
      siteResourcesSearchForm: form.siteResourcesSearchForm
    }))

  const [queryParams, setQueryParams] = useQueryParams({
    sort: ObjectParam,
    pager: ObjectParam
  })

  const { data: { site } = {}, isFetching } = useQuery([
    QUERY_KEYS.site,
    id
  ],
  siteQuery,
  {
    onError: (error) => handleError(error, 'Error fetching site')
  })

  const { data: assets, isFetching: assetsFetching, refetch: refetchAssets } = useQuery([
    QUERY_KEYS.assetsOnSite,
    {
      haulerId: user.user.haulerId,
      sort: queryParams.sort,
      siteId: site?.id,
      first: queryParams.pager?.direction === undefined || queryParams.pager?.direction === 'after' ? pageSize : undefined,
      last: queryParams.pager?.direction === 'before' ? pageSize : undefined,
      after: queryParams.pager?.direction === 'after' ? queryParams.pager?.cursor : undefined,
      before: queryParams.pager?.direction === 'before' ? queryParams.pager?.cursor : undefined
    }
  ],
  resourceSearchQuery,
  {
    enabled: Boolean(user.user.haulerId),
    onError: (error) => handleError(error, 'Error fetching resources'),
    refetchOnWindowFocus: false
  })

  const { data: allAssets, isFetching: allAssetsFetching, refetch: refetchAllAssets } = useQuery([
    QUERY_KEYS.allAssets,
    {
      haulerId: user.user.haulerId,
      sort: {
        column: 'uid',
        direction: 'asc'
      },
      notSiteId: id
    }
  ],
  resourceSearchQuery,
  {
    enabled: Boolean(user.user.haulerId),
    onError: (error) => handleError(error, 'Error fetching resources'),
    refetchOnWindowFocus: false
  })

  const { mutate: addAssetToSite, isLoading: addAssetToSiteLoading } = useMutation(addAssetToSiteMutation, {
    onSuccess: (data) => {
      refetchAssets()
      notify('success', 'Asset Updated Successfully!')
    },
    onError: error => {
      console.error(error)
      notify('error', 'Failed to Update Asset')
    },
    onSettled: refetchAllAssets
  })

  const { mutate: deleteAssetFromSite, isLoading: deleteAssetFromSiteLoading } = useMutation(deleteAssetFromSiteMutation, {
    onSuccess: (data) => {
      refetchAssets()
      notify('success', 'Asset Successfully Removed from Site!')
    },
    onError: error => {
      console.error(error)
      notify('error', 'Failed to Remove Asset')
    },
    onSettled: refetchAllAssets
  })

  const { mutate: destroySite, isLoading: destroySiteLoading } = useMutation(destroySiteMutation, {
    onSuccess: () => {
      intercomConnection.trackEvent('site_deleted', { id })
      history.replace(ROUTES.sitesSearch)
      notify('success', 'Site deleted successfully')
    },
    onError: error => {
      captureErrorAndNotify(error, 'Failed to destroy site')
    }
  })

  const handleSubmit = (e) => {
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault()
    }

    addAssetToSite({ assetId: siteResourcesSearchForm.values.id, siteId: id })
  }

  const handleRemoveResourceFromSite = (id) => {
    deleteAssetFromSite({ assetId: Number(id), setOnSelf: false })
  }

  function handleError (error, message) {
    console.error(error)
    notify('error', message)
  }

  function onPageRequest (direction, cursor) {
    setQueryParams({ pager: { cursor, direction } })

    if (!fakeCount) return
    if (direction === 'before') {
      setFakeCount(prevFakeCount => ({ start: prevFakeCount.start - pageSize, end: prevFakeCount.end - pageSize }))
    }
    if (direction === 'after') {
      setFakeCount(prevFakeCount => ({ start: prevFakeCount.start + pageSize, end: prevFakeCount.end + pageSize }))
    }
  }

  useEffect(function setDefaultQueryParams () {
    const defaultQueryParams = {}
    if (!queryParams.sort) {
      defaultQueryParams.sort = { column: 'lastMoved', direction: 'asc' }
    }
    setQueryParams(defaultQueryParams, 'replaceIn')
  }, [queryParams, setQueryParams])

  return (
    <NormalLayoutContainer showBackLink>
      <PageTitle>
        {`Site - ${site?.name}`}
        <Link
          className='dis-btn dis-btn-lg dis-btn-primary-dk pull-right push-down-super-small'
          to={ generatePath(ROUTES.editSite, { id }) }>
          Edit Site
          <i className='material-icons dis-btn-icon'>mode_edit</i>
        </Link>
      </PageTitle>

      <div className='dis-form-page-container'>
        <div className='dis-form-container-left'>
          {isFetching
            ? <Spinner isFetching />
            : <SiteDisplay
                showMetric={user.hauler.country !== 'US'}
                site={site}
              />}
        </div>
        <div className='dis-form-container-right'>
          {!isFetching && !allAssetsFetching && (
            <Spinner isFetching={isFetching || allAssetsFetching || assetsFetching || addAssetToSiteLoading || deleteAssetFromSiteLoading || destroySiteLoading}>
              <SiteResourcesDisplay
                site={site}
                siteName={site?.name}
                siteResources={assets?.assets}
                allResources={allAssets?.assets?.nodes}
                handleSubmit={handleSubmit}
                handleRemoveResourceFromSite={handleRemoveResourceFromSite}
                onPageRequest={onPageRequest}
              />
            </Spinner>
          )}
        </div>

      </div>
      {/* Destroy Resource Button */}
      <button
        className='btn btn-link center-block'
        onClick={() => {
          // eslint-disable-next-line no-restricted-globals
          const result = confirm(
            'THIS CANNOT BE UNDONE! Deleting a Site will remove any record ' +
            'that it existed.')
          if (result) {
            destroySite({ id })
          }
        }}>
        <span className='pull-left'>Destroy Site</span>
        <i className='material-icons pull-right thumbster-button-icon'>delete</i>
      </button>

    </NormalLayoutContainer>
  )
}
