import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Spinner from 'react-spinkit'
import styles from './numberInput.module.scss'
import inputStyles from './inputs.module.scss'
import classNamePropTypes from '../../../utilities/classNamePropTypes'

export default function NumberInput ({
  className = null,
  value = null,
  disabled = false,
  loading = false,
  onChange,
  min = 0,
  labelName,
  labelText
}) {
  return (loading
    ? <Spinner name='circle' className={classNames(inputStyles.spinner, inputStyles.centered)} fadeIn='none' />
    : (
      <>
        <input
          id={labelName}
          aria-label={labelText}
          className={classNames(styles.number, className)}
          disabled={disabled}
          value={value || ''}
          onChange={event => onChange(Number(event.currentTarget.value))}
          type='number'
          min={min}
        />
      </>
      ))
}

NumberInput.propTypes = {
  className: classNamePropTypes,
  value: PropTypes.number,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number,
  labelName: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired
}
