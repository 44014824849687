import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import DiscountAndTaxFormFields from '../discount-and-tax-form-fields'
import styles from './index.module.scss'
import cn from 'classnames'
import { commaDeliminate } from '../../utilities/stringUtilities'
import {
  handleOnChangeDiscountAmount,
  handleOnChangeTaxAmount,
  handleOnChangeTaxCode,
  handleOnChangeDiscountPercent,
  handleOnChangeTaxPercent,
  removeDiscount,
  removeTax
} from '../discount-and-tax-form-fields/utils'
export default function InvoiceDiscountTaxForm ({
  handleSubmit,
  hasInvoiceTickets,
  hasTicketDiscount,
  hasTicketTax,
  isConnectedToQB,
  isLoading,
  initialValues,
  showDiscountAndTax,
  taxCodes,
  usingAST,
  ticketFeeTotal
}) {
  function validate (values) {
    const errors = {
      discountAndTax: {}
    }
    if (!hasTicketTax && (values.qboNonAST && parseFloat(values.discountAndTax.taxAmount) && !values.discountAndTax.qboTaxCodeId)) {
      errors.discountAndTax.qboTaxCodeId = 'Required'
    }
    return errors
  }

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues} validate={validate} keepDirtyOnReinitialize>
      {({ handleSubmit, invalid, form, values }) => (
        <form className={styles.discountTaxForm} onSubmit={handleSubmit}>
          <div className={styles.discountAndTaxesContainer}>
            {hasTicketDiscount
              ? <>
                <div className={styles.totalContainer}>
                  <div>Discount</div>
                  <div>(${commaDeliminate(values.discountAndTax.discountAmount)})</div>
                </div>
                <div className={styles.ticketMessaging}>A discount has been added to one or more tickets, so discounts can only be updated at the ticket level.</div>
              </>
              : <> {!values.showDiscountForm && ticketFeeTotal > 0 && showDiscountAndTax && <button
                  type='button'
                  onClick={() => form.change('showDiscountForm', true)}
                  className={`${styles.displayFormButton} ${styles.discountAndTaxesButton}`}>
                + Add Discount
              </button> }
                <DiscountAndTaxFormFields
                  handleOnChangeAmount={handleOnChangeDiscountAmount}
                  handleOnChangePercent={handleOnChangeDiscountPercent}
                  remove={() => removeDiscount(form)}
                  ticketFeeTotal={ticketFeeTotal}
                  type='discount'
                  form={form}
                  show={values.showDiscountForm}
                  isConnectedToQB={isConnectedToQB}
                />
              </>}
            {ticketFeeTotal > 0 && showDiscountAndTax && <div className={cn(styles.totalContainer, styles.subtotal, {
              [styles.showDiscountAndTaxTotal]: showDiscountAndTax
            })}>
              <div>Subtotal</div>
              <div>
                ${commaDeliminate(ticketFeeTotal - parseFloat(values.discountAndTax.discountAmount || 0))}
              </div>
            </div>}
            {hasTicketTax
              ? <>
                <div className={styles.totalContainer}>
                  <div>Tax</div>
                  <div>${commaDeliminate(values.discountAndTax.taxAmount)}</div>
                </div>
                <div className={styles.ticketMessaging}>A tax has been added on one or more tickets, so taxes can only be updated at the ticket level.</div>
              </>
              : <>
                {!values.showTaxForm && ticketFeeTotal > 0 && showDiscountAndTax && <button
                  type='button'
                  onClick={() => form.change('showTaxForm', true)}
                  className={`${styles.displayFormButton} ${styles.discountAndTaxesButton}`}>
                  + Add Tax
                </button>}
                <DiscountAndTaxFormFields
                  handleOnChangeAmount={handleOnChangeTaxAmount}
                  handleOnChangePercent={handleOnChangeTaxPercent}
                  handleOnChangeTaxCode={handleOnChangeTaxCode}
                  remove={() => removeTax(form)}
                  ticketFeeTotal={ticketFeeTotal}
                  type='tax'
                  form={form}
                  show={values.showTaxForm}
                  isConnectedToQB={isConnectedToQB}
                  usingAST={usingAST}
                  taxCodes={taxCodes}
                />
              </>}
            {ticketFeeTotal > 0 && <div className={cn(styles.totalContainer, styles.total, {
              [styles.showDiscountAndTaxTotal]: showDiscountAndTax
            })}>
              <div>Total</div>
              <div>
                ${commaDeliminate((ticketFeeTotal - parseFloat(values.discountAndTax.discountAmount || 0)) + parseFloat(values.discountAndTax.taxAmount || 0))}
              </div>
            </div>}
          </div>
          <button type='submit'
            className='dis-btn dis-btn-lg dis-btn-primary'
            disabled={invalid || !hasInvoiceTickets || isLoading}>
            Save & Continue
            <i className='material-icons dis-btn-icon'>arrow_forward</i>
          </button>
        </form>
      )}
    </Form>
  )
}

InvoiceDiscountTaxForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  hasInvoiceTickets: PropTypes.bool.isRequired,
  hasTicketDiscount: PropTypes.bool.isRequired,
  hasTicketTax: PropTypes.bool.isRequired,
  isConnectedToQB: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  showDiscountAndTax: PropTypes.bool.isRequired,
  taxCodes: PropTypes.array,
  usingAST: PropTypes.bool,
  ticketFeeTotal: PropTypes.number
}
