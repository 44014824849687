import { useQuery as reactQueryUseQuery } from 'react-query'
import { useSelector } from 'react-redux'

/**
 * Custom hook that wraps react-query's useQuery. We do this for a few reasons:
 * * Allows us to pass common custom data to query functions like dataLoaders
 * * Allows us to set reasonable defaults like only make API requests if we have authentication.
 * * Allows us to be reactive to user changes automatically without boilerplate code in every component
 * * Gives us the flexibility to replace react-query in the future without changing 100 files
 *
 * @param {string|string[]} queryKey - Akin to react-query's query keys https://react-query-v3.tanstack.com/guides/query-keys
 * @param {function} queryFn - Akin to react-query's query functions https://react-query-v3.tanstack.com/guides/query-functions
 * @param {Object} [options = {}] - Akin to react-query options https://react-query-v3.tanstack.com/reference/useQuery#_top
 *  with some additional options.
 */
export default function useQuery (queryKey, queryFn, options = {}) {
  const { user, dataLoaders } = useSelector(({ user, dataLoaders }) => ({ user: user.user, dataLoaders }))
  options = setOptionDefaults(options)

  return reactQueryUseQuery(
    generateQueryKey(queryKey, user?.id),
    ({ queryKey }) => queryFn({
      dataLoaders,
      queryKey
    }),
    {
      ...options,
      enabled: options.enabled &&
        (options.requireAuth ? dataLoaders.isAuth : true)
    }
  )
}

/**
 * Sets our defaults for our useQuery options
 * @param {Object} options
 * @return {Object}
 */
function setOptionDefaults (options) {
  return {
    ...options,
    // Most API requests REQUIRE authentication headers to be sent which is why we default these to true
    requireAuth: options.requireAuth ?? true,
    // Queries should be enabled by default, in other words, execute queries immediately unless told otherwise
    enabled: options.enabled ?? true
  }
}

/**
 * Generates the query key that useQuery will use based off the original queryKey combined with the user ID. This is exported because it is useful
 * for other parts of the application to be able to generate the same exact query key that useQuery uses so that it can modify or access the cache
 * data that React Query holds.
 * @param {string|Array} queryKey
 * @param {string|undefined} userId
 * @return Array
 */
export function generateQueryKey (queryKey, userId) {
  return typeof queryKey === 'string' ? [queryKey, userId] : [[queryKey[0], userId], ...queryKey.slice(1)]
}
