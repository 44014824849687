import { loader } from 'graphql.macro'

export default async function exportClientToWastepay ({ dataLoaders, variables }) {
  const schema = loader('./exportClientToWastepay.graphql')

  const inputArgs = {
    id: variables.id
  }

  if (variables.forceCreate != null) {
    inputArgs.forceCreate = variables.forceCreate
  }

  if (variables.customerId != null) {
    inputArgs.customerId = variables.customerId
  }

  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: inputArgs
  })
  return response.exportClientToWastepay
}
