import { loader } from 'graphql.macro'

export default async function createTicketType ({ dataLoaders, variables }) {
  const schema = loader('./createTicketType.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      defaultBillingStatus: variables.defaultBillingStatus,
      name: variables.name,
      shortCode: variables.shortCode
    }
  })

  return response.createTicketType
}
