import { loader } from 'graphql.macro'

export default function notificationsQuery ({ dataLoaders, queryKey }) {
  const [, variables] = queryKey

  if (!variables.userId) {
    throw new Error(`Missing or invalid userId: ${variables.userId}`)
  }

  const schema = loader('./notifications.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(schema, {
    userId: variables.userId,
    first: variables.first,
    last: variables.last,
    before: variables.before,
    after: variables.after
  })
}
