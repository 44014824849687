import React from 'react'
import styles from './ticket-activities.module.scss'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import { TextareaInput } from '../../TextareaInput'

export default function CreateTicketActivityForm ({
  onSubmit,
  onCancel,
  disabled = false
}) {
  function handleSubmit (data, form) {
    return onSubmit(data)
      .then(() => form.reset())
  }

  return (
    <Form onSubmit={handleSubmit} validate={validate}>
      {({ pristine, invalid, handleSubmit, submitting }) => (
        <form className={styles.activityForm} onSubmit={handleSubmit}>
          <Field
            component={TextareaInput}
            label='Message (Optional)'
            name='message'
            placeholder='(Comments are for each individual ticket and can be used for placement details, messages for drivers, etc.
             Drivers can view comments on the mobile app.)'
          />
          <div className={styles.activityFormBtns}>
            <button
              type='button'
              className='dis-btn dis-btn-lg dis-btn-white push-down-super-small'
              onClick={onCancel}
              disabled={submitting}>
              Cancel
            </button>
            <button
              type='submit'
              className='dis-btn dis-btn-lg dis-btn-success push-down-super-small'
              disabled={pristine || invalid || submitting || disabled}>
              Save
              <i className='material-icons dis-btn-icon'>check</i>
            </button>
          </div>
        </form>
      )}
    </Form>
  )
}

CreateTicketActivityForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

function validate (values) {
  const errors = {}

  if (!values.message) {
    errors.message = 'Required'
  } else if (values.message.length > 250) {
    errors.message = 'First Name must be less than 250 characters'
  }

  return errors
}
