import React from 'react'
import PropTypes from 'prop-types'
import SiteType from './SiteType'

export default function SiteTypesList ({ siteTypes, onEdit }) {
  return (
    <div className='row panel panel-default'>
      {siteTypes.length === 0 && <h4 className='text-center'>No Site Types</h4>}
      {
        siteTypes.map(siteType => (
          <SiteType
            key={siteType.id}
            siteType={siteType}
            onEdit={id => onEdit(id)}
          />
        ))
      }
    </div>
  )
}

SiteTypesList.propTypes = {
  onEdit: PropTypes.func.isRequired,
  siteTypes: PropTypes.array.isRequired
}
