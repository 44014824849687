import {
  REQUEST_RECENT_ACTIVITY_ACTION,
  RECEIVE_RECENT_ACTIVITY_SUCCESS,
  RECEIVE_RECENT_ACTIVITY_FAILURE
} from '../sagas/recentSaga'
import { camelCasedObjectToSnakeCasedObject } from '../../../utilities/randomUtilities'

export const initialState = {
  isFetching: false,
  errors: [],
  activities: [],
  pagination: {}
}

export default function recentReducer (reduxState = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case REQUEST_RECENT_ACTIVITY_ACTION:
      return {
        ...reduxState,
        isFetching: true,
        errors: []
      }
    case RECEIVE_RECENT_ACTIVITY_SUCCESS:
      return {
        ...reduxState,
        isFetching: false,
        activities: payload.activities,
        pagination: camelCasedObjectToSnakeCasedObject(payload.pagination)
      }
    case RECEIVE_RECENT_ACTIVITY_FAILURE:
      return {
        ...reduxState,
        isFetching: false,
        errors: payload.errors
      }
    default:
      return reduxState
  }
}
