import React from 'react'
import PropTypes from 'prop-types'
import { ANDROID_APP_LINK, IOS_APP_LINK } from '../utilities/appStoreLinks'
import { useDispatch } from 'react-redux'
import USER_ACTIONS from '../store/user/userActions'

export default function NotAuthorizedPage ({ checkRole = false }) {
  const dispatch = useDispatch()

  function backToLogin () {
    localStorage.removeItem('auth')
    // Clearing the user should kick us back to the login page
    dispatch({ type: USER_ACTIONS.CLEAR_USER })
  }

  return (
    <div className='center__all--large push-down-very-large'>
      {checkRole
        ? (
          <>
            <h4>You are not authorized to login to this portal. Please use the mobile app to view your assigned tickets.</h4>
            <div className='row push-down-very-large'>
              <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 text-center'>
                <a href={IOS_APP_LINK} target='_blank' rel='noopener noreferrer' className='flex justify-center my-10'>
                  <img src='./App Store.png' alt='Thumbster Connect iOS' />
                </a>
              </div>
              <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 text-center'>
                <a href={ANDROID_APP_LINK} target='_blank' rel='noopener noreferrer' className='flex justify-center my-10'>
                  <img src='./Google Play.png' alt='Thumbster Connect Google Play' />
                </a>
              </div>
            </div>
            <button type='button' className='btn btn-link btn-lg push-down-medium' onClick={backToLogin}>
              Back to Login
            </button>
          </>
          )
        : (
          <h4>You are not authorized to view this page.</h4>
          )}
    </div>
  )
}

NotAuthorizedPage.propTypes = {
  checkRole: PropTypes.bool
}
