import React from 'react'
import NormalLayoutContainer from '../../../shared/NormalLayoutContainer'
import PageTitle from '../../../../components/page-title'
import { Spinner } from '../../../shared/Spinner'
import ResourcesLocationsChart from '../../../recent/components/ResourcesLocationsChart'
import ResourcesAgingGraph from '../../../recent/components/ResourcesAgingGraph'
import { captureErrorAndNotify } from '../../../../utilities/errorHandlers'
import QUERY_KEYS from '../../../../graphql/queryKeys'
import useQuery from '../../../../hooks/useQuery'
import { useSelector } from 'react-redux'
import resourcesChartsQuery from '../../../../graphql/queries/resourcesCharts'

export default function HaulerResourcesChartsContainer () {
  const { user } = useSelector(({ user }) => ({ user: user.user }))

  const { data: { assetsCharts } = {}, isFetching: isFetchingAssetsCharts } = useQuery(
    [QUERY_KEYS.resourcesCharts, { haulerId: user.haulerId }],
    resourcesChartsQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'There was a problem retrieving charts data.')
      },
      placeholderData: { assetsCharts: {} }
    })

  return (
    <NormalLayoutContainer showBackLink>
      <div className='container-fluid'>
        <PageTitle>Assets Charts</PageTitle>
        {isFetchingAssetsCharts
          ? <Spinner isFetching />
          : (
            <div>
              <div className='row push-down-small'>
                <div className='col-xs-12 col-md-8 col-md-offset-2'>
                  <ResourcesLocationsChart chartLabels={assetsCharts.locationLabel} />
                </div>
              </div>

              <div className='row push-down-large'>
                <div className='col-xs-12 col-md-8 col-md-offset-2'>
                  <ResourcesAgingGraph locationAgeCount={assetsCharts.locationAgeCount} />
                </div>
              </div>

              <div className='push-down-small' />
            </div>
            )
          }
      </div>
    </NormalLayoutContainer>
  )
}
