import React, { useCallback, useEffect, useState } from 'react'
import styles from './index.module.scss'
import cn from 'classnames'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import { Spinner } from '../shared/Spinner'
import PageTitle from '../../components/page-title'
import InvoicesSearchForm from '../../components/invoice/invoices-search-form'
import InvoicesTable from '../../components/invoice/InvoicesTable'
import { ROUTES } from '../../routes'
import { ObjectParam, StringParam, useQueryParams } from 'use-query-params'
import QUERY_KEYS from '../../graphql/queryKeys'
import invoicesQuery from '../../graphql/queries/invoices'
import invoiceSettingsQuery from '../../graphql/queries/invoiceSettings'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import useQuery from '../../hooks/useQuery'

const pageSize = 25

export default function InvoicesPage () {
  const [fakeCount, setFakeCount] = useState(null)
  const { hauler, isWastepayApproved, isWastepayEnabled } = useSelector(({ user }) => ({
    hauler: user.hauler,
    isWastepayApproved: user.hauler.isWastepayApproved,
    isWastepayEnabled: user.hauler.isWastepayEnabled
  }))
  const history = useHistory()
  const [queryParams, setQueryParams] = useQueryParams({
    sort: ObjectParam,
    invoiceNumber: StringParam,
    status: StringParam,
    createdDateRange: StringParam,
    accountName: StringParam,
    pager: ObjectParam
  })
  // Curried function to make sure we are always using 'replace' update.
  const setQueryParamsReplace = useCallback((params) => {
    setQueryParams(params, 'replace')
  }, [setQueryParams])

  const { data: invoiceSettingsData, isFetching: isFetchingInvoiceSettings } = useQuery([
    QUERY_KEYS.invoiceSettings,
    hauler.id
  ],
  invoiceSettingsQuery,
  {
    onError: (error) => captureErrorAndNotify(error, 'Error Fetching Invoice Settings')
  })

  const { data: invoices, isFetching: isFetchingInvoices } = useQuery([
    QUERY_KEYS.invoices,
    hauler.id,
    queryParams.sort,
    queryParams.invoiceNumber,
    queryParams.status,
    queryParams.createdDateRange,
    queryParams.accountName,
    queryParams.pager,
    null
  ],
  invoicesQuery,
  {
    enabled: Boolean(queryParams.sort),
    initialData: { invoices: {} },
    // take the result of the invoicesQuery and identify and change the status to 'OVERDUE' before render
    select: ({ invoices }) => (
      identifyOverdueInvoices(invoices)
    )
  })

  function identifyOverdueInvoices (invoicesList) {
    const currentDate = new Date()

    // Check if invoices.nodes is an array before iterating through it
    if (invoicesList && invoicesList.nodes && Array.isArray(invoicesList.nodes)) {
      const invoicesNodes = invoicesList.nodes.map((invoice) => {
        const dueDate = new Date(invoice.dueDate)
        const isOpen = invoice.status === 'OPEN'

        if (invoiceSettingsData?.invoiceSettings?.sendInvoicesFrom === 'DISPATCHER' && dueDate < currentDate && isOpen) {
          invoice.status = 'OVERDUE'
        }
        return invoice
      })
      invoicesList.nodes = invoicesNodes
    }

    return invoicesList
  }

  function clearForm () {
    setFakeCount(null)
    setQueryParamsReplace({})
  }

  function handleSearch ({ invoiceNumber, status, createdDateRange, accountName }) {
    setFakeCount(null)
    setQueryParamsReplace({ invoiceNumber, status, createdDateRange, accountName })
  }

  useEffect(function toggleFakePagination () {
    if (isFetchingInvoices || fakeCount !== null || !invoices.totalCount) return
    setFakeCount({ start: 1, end: Math.min(pageSize, invoices.totalCount) })
  }, [invoices, isFetchingInvoices, fakeCount, setFakeCount])

  function onPageRequest (direction, cursor) {
    setQueryParamsReplace({ ...queryParams, pager: { cursor, direction, pageSize } })

    if (!fakeCount) return
    if (direction === 'before') {
      setFakeCount(prevFakeCount => ({ start: prevFakeCount.start - pageSize, end: prevFakeCount.end - pageSize }))
    }
    if (direction === 'after') {
      setFakeCount(prevFakeCount => ({ start: prevFakeCount.start + pageSize, end: prevFakeCount.end + pageSize }))
    }
  }

  function onSortChange (newSortColumn) {
    let newSortDirection = 'desc'
    if (newSortColumn === queryParams.sort.column) {
      newSortDirection = queryParams.sort.direction === 'asc' ? 'desc' : 'asc'
    }

    setFakeCount(null)
    setQueryParamsReplace({
      ...{
        invoiceNumber: queryParams.invoiceNumber,
        status: queryParams.status,
        createdDateRange: queryParams.createdDateRange,
        accountName: queryParams.accountName
      },
      sort: { column: newSortColumn, direction: newSortDirection }
    })
  }

  // Always make sure we have the bare minimum query parameters by setting reasonable defaults if not already set.
  useEffect(function setDefaultQueryParams () {
    const defaultQueryParams = {}
    if (!queryParams.sort) {
      defaultQueryParams.sort = { column: 'createdAt', direction: 'desc' }
    }
    setQueryParamsReplace({ ...queryParams, ...defaultQueryParams })
  }, [queryParams, setQueryParamsReplace])

  useEffect(function handleQuickBooksConnection () {
    if (!hauler.canCreateInvoice) {
      history.push(ROUTES.invoiceSettings)
    }
  }, [history, hauler.canCreateInvoice])

  return (
    <NormalLayoutContainer>
      <div className={styles.header}>
        <PageTitle>Invoices</PageTitle>
        {(hauler.canCreateInvoice) && <Link to={ROUTES.invoiceNewClient} className='dis-btn dis-btn-lg dis-btn-primary'>
          <span className={styles.newBtnText}>New Invoice</span>
          <i className={cn(styles.newBtnIcon, 'material-icons dis-btn-icon')}>add</i>
        </Link>}
      </div>

      <InvoicesSearchForm
        onSubmit={handleSearch}
        onClear={clearForm}
        isFetching={isFetchingInvoices}
        isWastepayApproved={isWastepayApproved}
        isWastepayEnabled={isWastepayEnabled}
        initialValues={{
          invoiceNumber: queryParams.invoiceNumber,
          status: queryParams.status,
          createdDateRange: queryParams.createdDateRange,
          accountName: queryParams.accountName
        }}
      />
      {
        isFetchingInvoiceSettings || isFetchingInvoices || !Object.keys(invoices).length
          ? <Spinner isFetching />
          : (
            <InvoicesTable
              invoices={invoices}
              onPageRequest={onPageRequest}
              onSortChange={onSortChange}
              fakeCount={fakeCount}
              sort={queryParams.sort}
              hauler={hauler}
              invoiceProvider={invoiceSettingsData?.invoiceSettings?.sendInvoicesFrom ?? ''}
            />
            )
      }
    </NormalLayoutContainer>
  )
}

InvoicesPage.propTypes = {
}
