import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import NormalLayoutContainer from './shared/NormalLayoutContainer'
import useQuery from '../hooks/useQuery'
import QUERY_KEYS from '../graphql/queryKeys'
import resourceImportQuery from '../graphql/queries/resourceImport'
import completeResourceImportMutation from '../graphql/mutations/completeResourceImport'
import { captureErrorAndNotify } from '../utilities/errorHandlers'
import { Spinner } from './shared/Spinner'
import { useSelector } from 'react-redux'
import useMutation from '../hooks/useMutation'
import ImportDetails from '../components/ImportDetails'
import { ROUTES } from '../routes'
import ValidatedResourceImportRowsTable from '../components/import/ValidatedResourceImportRowsTable'
import CompletedResourceImportRowsTable from '../components/import/CompletedResourceImportRowsTable'

export default function ResourceImportPage () {
  const { resourceImportId } = useParams()
  const { haulerChannel } = useSelector(({ pusher }) => ({ haulerChannel: pusher.haulerChannel }))

  const {
    data: resourceImportData,
    // Important to use isLoading instead of isFetching here, this page does a bit of background refreshing, we do not want to show the spinner
    // everytime we do this background refetch
    isLoading: isLoadingResourceImport,
    refetch: refetchResourceImport
  } = useQuery(
    [QUERY_KEYS.resourceImport, { id: resourceImportId }],
    resourceImportQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Failed to retrieve import')
      }
    })

  const { mutate: completeResourceImport, isLoading: isCompletingResourceImport } = useMutation(completeResourceImportMutation, {
    onError (error) {
      captureErrorAndNotify(error, 'Failed to complete import')
    }
  })

  function handleCompletion () {
    completeResourceImport({ resourceImportId })
  }

  useEffect(function listenToChanges () {
    if (!haulerChannel) return

    const eventName = 'change-resource_import'
    haulerChannel.bind(eventName, refetchResourceImport)

    return function cleanupListening () {
      haulerChannel.unbind(eventName, refetchResourceImport)
    }
  }, [haulerChannel, refetchResourceImport])

  return (
    <NormalLayoutContainer>
      {isLoadingResourceImport
        ? <Spinner isFetching />
        : (
          <ImportDetails
            pluralImportName='assets'
            details={resourceImportData.resourceImport}
            onConfirm={handleCompletion}
            disabled={isCompletingResourceImport}
            listPath={ROUTES.resources}
            validatedImportRowsTableComponent={ValidatedResourceImportRowsTable}
            completedImportRowsTableComponent={CompletedResourceImportRowsTable}
          />
          )
      }

    </NormalLayoutContainer>
  )
}
