import { loader } from 'graphql.macro'

export default function clientsQuery ({ dataLoaders, queryKey }) {
  const [, haulerId, sort, searchTerm = null, pager = null, minInvoiceableTickets = null, ticketStatusSelect = null, clientId = null] = queryKey
  // Having to maintain this map is a bit of a pain, but we can't store underscores in the query parameters with the
  // ObjectParam type, so we need this to go from camelCase to our GraphQL enum CAPITALIZED_UNDERSCORE
  const sortColumnEnumMap = {
    customId: 'CUSTOM_ID',
    notes: 'NOTES',
    name: 'NAME',
    lastName: 'LAST_NAME',
    primaryPhone: 'PRIMARY_PHONE',
    openJobsCount: 'OPEN_JOBS_COUNT',
    closedJobsCount: 'CLOSED_JOBS_COUNT',
    invoiceableTicketsCount: 'INVOICEABLE_TICKETS_COUNT'
  }

  const sortColumnEnum = sortColumnEnumMap[sort.column]
  if (!sortColumnEnum) {
    return Promise.reject(new Error(`Invalid sort.column: ${sort.column}`))
  }

  let graphQLVariables = {
    haulerId,
    sortColumn: sortColumnEnum,
    sortDirection: sort.direction.toUpperCase(),
    searchTerm,
    invoiceableTicketStatus: ticketStatusSelect ? ticketStatusSelect.replace('-', '_') : null
  }
  if (pager) {
    const paginatedVariables = {
      before: pager.direction === 'before' ? pager.cursor : null,
      after: pager.direction === 'after' ? pager.cursor : null,
      first: pager.direction === 'after' ? Number(pager.pageSize) : null,
      last: pager.direction === 'before' ? Number(pager.pageSize) : null
    }
    graphQLVariables = { ...graphQLVariables, ...paginatedVariables }
  }
  if (minInvoiceableTickets) {
    graphQLVariables = { ...graphQLVariables, minInvoiceableTickets }
  }
  if (clientId) {
    graphQLVariables = { ...graphQLVariables, clientId }
  }

  const schema = loader('./clients.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(schema, graphQLVariables)
}
