import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

export default function CompleteTicketButton ({ onClick, ticket, disabled = false }) {
  const status = ticket.status
  const driverId = ticket.driverId
  const notTerminal = status.length > 0 && status !== 'completed' && status !== 'cancelled'

  return notTerminal && (
    <div data-tip={(!driverId) ? 'Can only complete a ticket if it has a driver.' : null}>
      <ReactTooltip />
      <button
        type='button'
        className='dis-btn dis-btn-lg dis-btn-primary-dk'
        onClick={onClick}
        disabled={!driverId || disabled}>
        Complete Ticket
        <i className='material-icons dis-btn-icon'>check</i>
      </button>
    </div>
  )
}

CompleteTicketButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  ticket: PropTypes.shape({
    driverId: PropTypes.number,
    id: PropTypes.string,
    status: PropTypes.string
  }).isRequired,
  disabled: PropTypes.bool
}
