import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from '../modal/Modal'
import classNames from 'classnames'
import receiptByTransactionMutation from '../../graphql/mutations/wastepayReceiptByTransactions/wastepayReceiptByTransaction'
import dispatcherReceiptByTransactionMutation from '../../graphql/mutations/dispatcherReceiptByTransactions/dispatcherReceiptByTransaction'
import { EmailValidator } from '../../utilities/commonValidators'
import notify from '../../utilities/notify'
import Spinner from 'react-spinkit'
import styles from './index.module.scss'
import useMutation from '../../hooks/useMutation'

export default function ReceiptActions ({
  transactionId,
  invoiceId,
  parentClass = null,
  client,
  buttons,
  emails = [],
  spinnerClass = '',
  receiptProvider,
  transactionType
}) {
  const [modalOpen, setModalOpen] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [actionLoading, setActionLoading] = useState(false)
  const [emailInput, setEmailInput] = useState(null)

  const { mutate: receiptByTransaction } = useMutation(receiptByTransactionMutation, {
    onSuccess ({ wastepayReceiptByTransaction }) {
      const { link } = wastepayReceiptByTransaction
      setActionLoading(false)
      if (link) {
        window.open(link, '_blank', 'noopener,noreferrer')
        return
      }
      setModalOpen(false)
      setEmailInput(null)

      notify('success', 'Receipt sent to emails listed')
    },
    onError () {
      setActionLoading(false)
      notify('error', 'There was a problem sending/saving the receipt.')
    }
  })
  const { mutate: dispatcherReceiptByTransaction } = useMutation(dispatcherReceiptByTransactionMutation, {
    onSuccess ({ dispatcherReceiptByTransaction }) {
      const { link } = dispatcherReceiptByTransaction
      setActionLoading(false)
      if (link) {
        window.open(link, '_blank', 'noopener,noreferrer')
        return
      }
      setModalOpen(false)
      setEmailInput(null)

      notify('success', 'Receipt sent to emails listed')
    },
    onError () {
      setActionLoading(false)
      notify('error', 'There was a problem sending/saving the receipt.')
    }
  })

  const wrapperClass = parentClass || classNames(styles.wrapper)

  function closeModal () {
    setEmailError(false)
    setModalOpen(false)
    setEmailInput(null)
  }

  function sendEmail (e) {
    e.preventDefault()
    const emails = e.target?.emails?.value
    const emailValidation = new RegExp(EmailValidator)
    let valid = true

    setEmailError(false)
    const sendToEmails = emails.split(',').map(v => {
      const email = v.trim()

      if (!emailValidation.test(email)) {
        valid = false
      }

      return {
        email
      }
    })

    if (valid) {
      return receiptAction('EMAIL', sendToEmails)
    }

    return setEmailError(true)
  }

  function receiptAction (actionType, sendTo = []) {
    const input = {
      transactionId,
      invoiceId,
      clientId: client.id,
      action: actionType.toUpperCase(),
      saleType: transactionType
    }

    if (sendTo.length) {
      input.sendTo = sendTo
    }

    if (receiptProvider === 'DISPATCHER') {
      dispatcherReceiptByTransaction(input)
    } else {
      receiptByTransaction(input)
    }
  }

  function handleReceiptActionBtn (actionType) {
    if (actionType.toLowerCase() === 'email') {
      return setModalOpen(true)
    }

    setActionLoading(true)
    return receiptAction(actionType.toUpperCase())
  }

  return (
    <>
      <Modal isOpen={modalOpen} closeModal={closeModal}>
        <form name='send-receipt-capture-email' className='dis-modal-inner' onSubmit={sendEmail} onReset={closeModal}>
          <h3>
            Send Receipt
          </h3>
          <label className={classNames('dis-form-label-input-wrapper', styles['modal-input'], {
            'has-error': emailError
          })}>
            Email Address (comma separated)
            <input
              name='emails'
              autoComplete='off'
              type='text'
              value={emailInput}
              defaultValue={emails.join(',')}
              onChange={(e) => setEmailInput(e.target.value)}
            />
            {!emailError || <h6>1 or more emails are invalid</h6> }
          </label>
          <div className={styles['modal-button-wrapper']}>
            <button type='reset' className='dis-btn dis-btn-sm dis-btn-no-shadow'>Cancel</button>
            <button type='submit' className='dis-btn dis-btn-sm dis-btn-primary' disabled={!emailInput?.length}>Send {
              actionLoading
                ? <Spinner name='circle' className={classNames(styles['spinner-left'])} fadeIn='none' />
                : <span className='material-icons'>send</span>
            }</button>
          </div>
        </form>
      </Modal>
      <div className={wrapperClass}>
        {
          buttons.map(({ action, text, icon, btnClasses }, i) => {
            const iconType = action === 'email' ? 'email' : 'print'
            const defaultStyleWithIcon = btnClasses || classNames('dis-btn dis-btn-blank dis-btn-sm', styles['button-icon'])
            const defaultStyleWithoutIcon = btnClasses || 'dis-btn-link dis-btn-sm'

            if (icon) {
              return (
                <button className={classNames(defaultStyleWithIcon)} key={`receipt-button-${i}`} type='button'
                  onClick={() => handleReceiptActionBtn(action)}>
                  {
                    actionLoading
                      ? <Spinner name='circle' className={classNames(styles['spinner-right'])} fadeIn='none' />
                      : <i className='material-icons'>{iconType}</i>
                  }
                  {text}
                </button>
              )
            }
            return (
              <button className={classNames(defaultStyleWithoutIcon)} key={`receipt-button-${i}`} type='button'
                onClick={() => handleReceiptActionBtn(action)}> {
                actionLoading
                  ? <Spinner name='circle' fadeIn='none' className={spinnerClass} />
                  : text
              }</button>
            )
          })
        }
      </div>
    </>
  )
}

ReceiptActions.propTypes = {
  transactionId: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.shape({
    action: PropTypes.oneOf(['email', 'print']).isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.bool,
    btnClasses: PropTypes.string
  })),
  parentClass: PropTypes.string,
  invoiceId: PropTypes.string.isRequired,
  client: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  emails: PropTypes.arrayOf(PropTypes.string),
  spinnerClass: PropTypes.string,
  receiptProvider: PropTypes.oneOf(['DISPATCHER', 'WASTEPAY']).isRequired,
  transactionType: PropTypes.oneOf(['REFUND', 'SALE']).isRequired
}
