import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import USER_ACTIONS from '../../store/user/userActions'
import { useQueryClient } from 'react-query'
import QUERY_KEYS from '../../graphql/queryKeys'
import invoiceSettingsQuery from '../../graphql/queries/invoiceSettings'
import paymentTermsQuery from '../../graphql/queries/paymentTerms'
import updateInvoiceSettings from '../../graphql/mutations/updateInvoiceSettings'
import disconnectWastepay from '../../graphql/mutations/disconnectWastepay'
import invoicePreview from '../../graphql/queries/invoicePreview'
import wastepayReceiptPreview from '../../graphql/queries/wastepayReceiptPreviewQuery'
import notify from '../../utilities/notify'
import { formatFormValuesForSubmit } from '../../utilities/randomUtilities'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import InvoiceSettingsForm from '../../components/invoice-settings-form'
import useQuery, { generateQueryKey } from '../../hooks/useQuery'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import useMutation from '../../hooks/useMutation'

export default function InvoiceSettings () {
  const { user, hauler, accessToken } = useSelector(({ user }) => ({ user: user.user, hauler: user.hauler, accessToken: user.accessToken }))
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const [currentTab, setCurrentTab] = useState('Invoice')

  const [previewInputs, setPreviewInputs] = useState({
    companyName: null,
    companyAddress: null,
    companyPhone: null,
    emailMessage: null,
    transactionType: 'SALE', // the tab for what to show defaults to "Payment" on page load and has no state, so just go with it.
    sendAsName: null,
    subject: null,
    showLineItems: false,
    fetch: false
  })

  function determineTransactionType (invoiceSettings) {
    if (invoiceSettings?.sendInvoicesFrom === 'DISPATCHER') {
      return 'INVOICE'
    } else if (previewInputs.transactionType.toUpperCase() === 'SALE') {
      return 'SALE'
    } else {
      return 'REFUND'
    }
  }

  function setDefaultPreviewInputs ({ invoiceSettings }) {
    const isPayment = previewInputs.transactionType.toUpperCase() === 'SALE'
    let emailMessage = ''
    let subject = ''
    let showLineItems = false
    if (invoiceSettings.sendInvoicesFrom === 'DISPATCHER') {
      emailMessage = invoiceSettings.invoiceEmailMessage || null
      subject = invoiceSettings.invoiceEmailSubject || ''
    } else if (isPayment) {
      emailMessage = invoiceSettings.paymentEmailMessage || null
      subject = invoiceSettings.paymentEmailSubject || ''
      showLineItems = invoiceSettings.sendPaymentLineItems || false
    } else {
      emailMessage = invoiceSettings.refundEmailMessage || null
      subject = invoiceSettings.refundEmailSubject || ''
    }

    const inputs = {
      companyName: invoiceSettings.companyName || '',
      companyAddress: invoiceSettings.companyAddress || '',
      companyPhone: invoiceSettings.prettyPhone || '',
      transactionType: determineTransactionType(invoiceSettings),
      sendAsName: invoiceSettings.sendAsName || '',
      subject: `${subject} - Invoice #[XXXXX]`,
      fetch: true,
      showLineItems,
      emailMessage
    }

    setPreviewInputs(inputs)
  }

  function handleSubmit (vals, form) {
    const initialValues = form.getState().initialValues
    const settings = formatFormValuesForSubmit(initialValues, vals)
    updateSettings({ input: { haulerId: hauler.id, settings } }, {
      onSuccess (data) {
        updateInvoiceSettingsCache(data.invoiceSettings)
        dispatch({ type: USER_ACTIONS.UPDATE_HAULER, payload: { hauler: { canCreateInvoice: data.hauler.canCreateInvoice } } })
        notify('success', 'Invoice Settings Updated Successfully')
      },
      onError: (error) => captureErrorAndNotify(error, 'Failed To Update Invoice Settings')
    })
  }

  function handleDisconnectWastepay () {
    return disconnectWastepayMutation({ haulerId: hauler.id }, {
      onSuccess () {
        notify('success', 'Wastepay Successfully Disconnected')
        dispatch({ type: USER_ACTIONS.UPDATE_HAULER, payload: { hauler: { isWastepayConnected: false } } })
      },
      onError: (error) => captureErrorAndNotify(error, 'Failed To Disconnect Wastepay')
    })
  }

  const { data: invoiceSettings, isFetching: invoiceSettingsFetching } = useQuery([
    QUERY_KEYS.invoiceSettings,
    hauler.id
  ],
  invoiceSettingsQuery,
  {
    onSuccess: setDefaultPreviewInputs,
    onError: () => notify('error', 'Error Fetching Invoice Settings')
  })

  const { isFetching: invoicePreviewFetching, refetch: fetchInvoicePreview } = useQuery([
    QUERY_KEYS.invoicePreview,
    previewInputs
  ],
  invoicePreview,
  {
    enabled: false,
    onError: () => notify('error', 'Error Getting Invoice Preview'),
    onSuccess: ({ invoicePreview }) => {
      document.getElementById('emailPreview').setAttribute('srcdoc', invoicePreview.html)
    },
    onSettled: () => setPreviewInputs({ ...previewInputs, ...{ fetch: false } })
  })

  const { isFetching: wastepayReceiptPreviewFetching, refetch: fetchWastepayReceiptPreview } = useQuery([
    QUERY_KEYS.wastepayReceiptPreview,
    previewInputs
  ],
  wastepayReceiptPreview,
  {
    enabled: false,
    onError: () => notify('error', 'Error Getting Email Preview'),
    onSuccess: ({ wastepayReceiptPreview }) => {
      document.getElementById('emailPreview').setAttribute('srcdoc', wastepayReceiptPreview.html)
    },
    onSettled: () => setPreviewInputs({ ...previewInputs, ...{ fetch: false } })
  })

  const { data: { paymentTerms } = [], isFetching: paymentTermsFetching } = useQuery([
    QUERY_KEYS.paymentTerms
  ],
  paymentTermsQuery,
  {
    onError: () => notify('error', 'Error Fetching Invoice Settings')
  })

  const { mutate: updateSettings, isLoading: updateSettingsLoading } = useMutation(updateInvoiceSettings)
  const { mutate: disconnectWastepayMutation, isLoading: disconnectWastepayLoading } = useMutation(disconnectWastepay)

  useEffect(function onInitialRender () {
    setCurrentTab(invoiceSettings?.invoiceSettings?.sendInvoicesFrom === 'DISPATCHER' ? 'Invoice' : 'Payment')
  }, [invoiceSettings])

  useEffect(() => {
    if (!invoiceSettings) return
    if (previewInputs.fetch && hauler.isWastepayConnected) {
      if (previewInputs.transactionType.toUpperCase() === 'INVOICE') {
        fetchInvoicePreview()
      } else {
        fetchWastepayReceiptPreview()
      }
    }
  }, [previewInputs, fetchWastepayReceiptPreview, hauler, fetchInvoicePreview, invoiceSettings, currentTab])

  function handleUpload () {
    const widget = cloudinary.createUploadWidget(
      {
        cloud_name: 'thumbster',
        upload_preset: 'hauler_logos',
        resource_type: 'image',
        multiple: false,
        theme: 'white',
        tags: [`hauler_wp_logo_${hauler.id}`, process.env.NODE_ENV],
        clientAllowedFormats: ['svg', 'png', 'jpg'],
        maxImageHeight: 200,
        maxImageWidth: 200,
        validateMaxWidthHeight: true,
        context: {
          environment: process.env.NODE_ENV,
          hauler_id: hauler.id
        }
      },
      (error, result) => {
        if (error) {
          if (error.kind && (error.kind === 'error') && error.message) {
            notify('error', 'Error Uploading Logo')
            return
          }
        }
        if (result?.info?.files) {
          const logoPublicId = result.info.files?.[0]?.uploadInfo?.public_id
          if (logoPublicId) {
            updateSettings({ input: { haulerId: hauler.id, settings: { logoPublicId } } }, {
              onSuccess (data) {
                updateInvoiceSettingsCache(data.invoiceSettings)
                notify('success', 'Logo Updated Successfully')
              },
              onError: () => notify('error', 'Error Uploading Logo')
            })
          }
        }
      })
    widget.open()
  }

  function handleDeleteLogo () {
    updateSettings({ input: { haulerId: hauler.id, settings: { logoPublicId: null } } }, {
      onSuccess (data) {
        updateInvoiceSettingsCache(data.invoiceSettings)
        notify('success', 'Logo Removed Successfully')
      },
      onError: () => notify('error', 'Error Removing Logo')
    })
  }

  function updateInvoiceSettingsCache (newInvoiceSettings) {
    const queryKey = generateQueryKey([QUERY_KEYS.invoiceSettings, hauler.id], user.id)
    queryClient.setQueryData(queryKey, oldData => ({
      ...(oldData ?? {}),
      invoiceSettings: { ...(oldData?.invoiceSettings ?? {}), ...newInvoiceSettings }
    }))
  }

  return (
    <div>
      <NormalLayoutContainer showBackLink>
        <InvoiceSettingsForm
          accessToken={accessToken}
          disconnectWastepayLoading={disconnectWastepayLoading}
          handleDeleteLogo={handleDeleteLogo}
          handleDisconnectWastepay={handleDisconnectWastepay}
          handleUpload={handleUpload}
          hauler={hauler}
          onSubmit={handleSubmit}
          invoiceSettings={invoiceSettings}
          isFetching={invoiceSettingsFetching || updateSettingsLoading || paymentTermsFetching}
          logoPublicId={invoiceSettings?.invoiceSettings?.logoPublicId}
          paymentTerms={paymentTerms}
          previewInputs={previewInputs}
          setPreviewInputs={setPreviewInputs}
          receiptPreviewFetching={wastepayReceiptPreviewFetching || invoicePreviewFetching}
          initialValues={{
            defaultPaymentTerms: invoiceSettings?.invoiceSettings?.defaultPaymentTerms,
            defaultTerms: invoiceSettings?.invoiceSettings?.defaultTerms,
            sendInvoicesFrom: invoiceSettings?.invoiceSettings?.sendInvoicesFrom,
            sendAsName: invoiceSettings?.invoiceSettings?.sendAsName,
            companyName: invoiceSettings?.invoiceSettings?.companyName,
            companyPhone: invoiceSettings?.invoiceSettings?.prettyPhone,
            companyAddress: invoiceSettings?.invoiceSettings?.companyAddress,
            sendPaymentReceipt: invoiceSettings?.invoiceSettings?.sendPaymentReceipt,
            sendPaymentLineItems: invoiceSettings?.invoiceSettings?.sendPaymentLineItems,
            invoiceEmailSubject: invoiceSettings?.invoiceSettings?.invoiceEmailSubject,
            invoiceEmailMessage: invoiceSettings?.invoiceSettings?.invoiceEmailMessage,
            paymentEmailSubject: invoiceSettings?.invoiceSettings?.paymentEmailSubject,
            paymentEmailMessage: invoiceSettings?.invoiceSettings?.paymentEmailMessage,
            sendRefundReceipt: invoiceSettings?.invoiceSettings?.sendRefundReceipt,
            refundEmailSubject: invoiceSettings?.invoiceSettings?.refundEmailSubject,
            refundEmailMessage: invoiceSettings?.invoiceSettings?.refundEmailMessage,
            includePayNowLink: invoiceSettings?.invoiceSettings?.includePayNowLink ?? false
          }}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      </NormalLayoutContainer>
    </div>
  )
}
