import { loader } from 'graphql.macro'
import { parseUSDToNumber } from '../../utilities/parsers'

export default async function createDispatcherPayment ({ dataLoaders, variables }) {
  const schema = loader('./createDispatcherPayment.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      invoiceId: variables.invoiceId,
      sendTo: (variables.email ?? '').split(',').map(email => ({ email })),
      paymentDetails: {
        paymentAmount: parseFloat(parseUSDToNumber(variables.paymentAmount)),
        paymentDate: variables.paymentDate,
        paymentMethod: variables.paymentMethod.toUpperCase(),
        checkNumber: variables.checkNumber,
        notes: variables.notes
      }
    }
  })
  return response.createDispatcherPayment
}
