import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import NormalLayoutContainer from '../../../../shared/NormalLayoutContainer'
import { PageTitle } from '../../../../../components/page-title'
import { Spinner } from '../../../../shared/Spinner'
import {
  REQUEST_CURRENT_HAULER_TEAM_MEMBER_ACTION,
  REQUEST_UPDATE_CURRENT_HAULER_TEAM_MEMBER_ACTION
} from '../../sagas/currentHaulerTeamMemberSaga'
import HaulerTeamMemberEditForm from './components/HaulerTeamMemberEditForm'
import HaulerTeamMemberEditExplanation from './components/HaulerTeamMemberEditExplanation'

export default function HaulerTeamEditPage () {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()

  const { haulerTeamMemberEditForm, currentHaulerTeamMember } = useSelector(
    ({ currentHaulerTeamMember, form }) => ({
      haulerTeamMemberEditForm: form.haulerTeamMemberEditForm,
      currentHaulerTeamMember
    }))

  const {
    first_name: firstName,
    isFetching,
    last_name: lastName
  } = currentHaulerTeamMember

  const cancelEdits = useCallback(() => {
    history.push(`/hauler/team/${id}`)
  }, [history, id])

  const handleSubmit = useCallback((e) => {
    e.preventDefault()
    haulerTeamMemberEditForm.values.primary_phone = haulerTeamMemberEditForm.values.pretty_primary_phone
    dispatch({
      type: REQUEST_UPDATE_CURRENT_HAULER_TEAM_MEMBER_ACTION,
      payload: { team_member: haulerTeamMemberEditForm.values }
    })
  }, [dispatch, haulerTeamMemberEditForm])

  useEffect(() => {
    dispatch({ type: REQUEST_CURRENT_HAULER_TEAM_MEMBER_ACTION, payload: id })
  }, [dispatch, id])

  return (
    <div>
      <NormalLayoutContainer showBackLink>
        <div className='container-fluid'>
          <PageTitle title={`Team Member ${firstName} ${lastName} Profile`}>
            <button
              className='btn btn-link pull-right push-down-very-small'
              onClick={cancelEdits}>
              Cancel
            </button>
          </PageTitle>

          <Spinner isFetching={isFetching}>
            <div className='row'>
              <div className='col-xs-12 col-sm-12 col-md-7 col-lg-7'>
                <HaulerTeamMemberEditForm
                  isFetching={isFetching}
                  team_member={currentHaulerTeamMember}
                  onSubmit={handleSubmit}
                />
              </div>
              <div className='col-xs-12 col-md-4 col-md-offset-1'>
                <HaulerTeamMemberEditExplanation />
              </div>
            </div>
          </Spinner>
        </div>
      </NormalLayoutContainer>
    </div>
  )
}

HaulerTeamEditPage.propTypes = {}
