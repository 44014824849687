import { loader } from 'graphql.macro'

export default async function updateTicketType ({ dataLoaders, variables }) {
  const schema = loader('./updateTicketType.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      ticketTypeId: variables.ticketTypeId,
      name: variables.name,
      shortCode: variables.shortCode,
      status: variables.status,
      defaultBillingStatus: variables.defaultBillingStatus
    }
  })
  return response.updateTicketType
}
