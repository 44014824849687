import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import { TextInput } from '../TextInput'
import {
  composeValidators,
  maxStrLength,
  required,
  validEmail,
  validPhone,
  validPassword, mustMatch
} from '../../utilities/commonValidators'
import normalizePhone from '../../pages/shared/normalizers/normalizePhone'

export default function SignUpStepThree ({ onPrev, onSubmit, signUpData, disabled = false }) {
  return (
    <div className='panel text-center'>
      <div className='container-fluid text-center'>
        <br />
        <h4>Now a bit about you.</h4>
        <br />
      </div>
      <Form onSubmit={onSubmit} initialValues={{
        firstName: signUpData.firstName,
        lastName: signUpData.lastName,
        email: signUpData.email,
        primaryPhone: signUpData.primaryPhone,
        password: signUpData.password,
        passwordConfirmation: signUpData.passwordConfirmation
      }}>
        {({
          handleSubmit,
          values,
          hasValidationErrors,
          hasSubmitErrors,
          modifiedSinceLastSubmit,
          submitting
        }) => (
          <form className='container-fluid spacer__bottom--large' onSubmit={handleSubmit}>
            <div className='row'>
              <div className='push-down-very-small center__all--large'>
                <Field
                  name='firstName'
                  placeholder='First Name'
                  component={TextInput}
                  maxLength='100'
                  autoComplete='given-name'
                  validate={composeValidators(required, maxStrLength(100))}
                />
                <Field
                  name='lastName'
                  placeholder='Last Name'
                  component={TextInput}
                  maxLength='1000'
                  autoComplete='family-name'
                  validate={composeValidators(required, maxStrLength(100))}
                />
                <Field
                  name='email'
                  type='email'
                  placeholder='Email'
                  component={TextInput}
                  maxLength='64'
                  autoComplete='email'
                  validate={composeValidators(required, validEmail)}
                />
                <Field
                  name='primaryPhone'
                  placeholder='Primary Phone Number'
                  component={TextInput}
                  format={normalizePhone}
                  validate={composeValidators(required, validPhone)}
                />
                <Field
                  name='password'
                  type='password'
                  placeholder='Password'
                  component={TextInput}
                  maxLength='40'
                  autoComplete='new-password'
                  validate={composeValidators(required, validPassword)}
                />
                <Field
                  name='passwordConfirmation'
                  type='password'
                  placeholder='Confirm Password'
                  component={TextInput}
                  maxLength='40'
                  autoComplete='new-password'
                  validate={composeValidators(required, validPassword, mustMatch(values.password, 'Password'))}
                />
              </div>
            </div>
            <div className='row center__all--large'>
              <button
                type='button'
                className='btn btn-link btn-lg push-down-very-small'
                onClick={() => onPrev(values)}>
                Back
              </button>
              <button
                type='submit'
                className='btn btn-success btn-lg push-down-very-small space-left'
                disabled={hasValidationErrors || (hasSubmitErrors && !modifiedSinceLastSubmit) || submitting || disabled}>
                Next
              </button>
            </div>
          </form>
        )}
      </Form>
    </div>
  )
}

SignUpStepThree.propTypes = {
  onPrev: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  signUpData: PropTypes.object.isRequired,
  disabled: PropTypes.bool
}
