import React from 'react'
import PropTypes from 'prop-types'
import styles from './feeTypeForm.module.scss'
import cn from 'classnames'
import { Field, Form } from 'react-final-form'
import { formatToUSD } from '../../utilities/formatters'

export default function FeeTypeForm ({ onSubmit, initialValues, disabled = false }) {
  function handleSubmit (data) {
    return onSubmit(data)
      .catch(error => {
        if (error?.response?.errors === undefined || !Array.isArray(error.response.errors)) return
        const errors = {}
        let hasError = false
        error.response.errors.forEach(error => {
          if (error.message === 'Validation failed: Name has already been taken') {
            hasError = true
            errors.name = 'Name has already been taken'
          }
        })

        return hasError ? errors : undefined
      })
  }

  const editing = initialValues !== undefined
  const formattedInitialValues = {
    ...initialValues,
    defaultAmount: formatToUSD(initialValues?.defaultAmount)
  }

  return (
    <Form onSubmit={handleSubmit} validate={validate} initialValues={formattedInitialValues}>
      {({
        hasValidationErrors,
        hasSubmitErrors,
        modifiedSinceLastSubmit,
        submitting,
        pristine,
        handleSubmit
      }) => (
        <form onSubmit={handleSubmit}>
          <Field name='name'>
            {({ input, meta }) => (
              <div className={cn(styles.field, {
                'has-error': (meta.touched && meta.error) || (meta.submitError && !meta.modifiedSinceLastSubmit)
              })}>
                <label className='control-label' htmlFor='name'>Fee Type Name</label>
                <input
                  type='text'
                  { ...input }
                  className='form-control'
                  maxLength='35'
                />
                {meta.touched && meta.error && <h6>{meta.error}</h6> }
                {meta.submitError && !meta.modifiedSinceLastSubmit && <h6>{meta.submitError}</h6> }
              </div>
            )}
          </Field>

          <Field name='defaultAmount' formatOnBlur={true} format={formatToUSD}>
            {({ input, meta }) => (
              <div className={cn(styles.field, { 'has-error': meta.touched && meta.error })}>
                <label className='control-label' htmlFor='default amount'>Default Amount</label>
                <input
                  type='text'
                  {...input}
                  className='form-control'
                  maxLength='9'
                  inputMode='numeric'
                />
                {meta.touched && meta.error && <h6>{meta.error}</h6> }
              </div>
            )}
          </Field>

          <Field name='description'>
            {({ input, meta }) => (
              <div className={cn(styles.field, { 'has-error': meta.touched && meta.error })}>
                <label className='control-label' htmlFor='description'>Description (optional)</label>
                <textarea
                  {...input}
                  className='form-control'
                  maxLength='4000'
                />
                {meta.touched && meta.error && <h6>{meta.error}</h6> }
              </div>
            )}
          </Field>

          {editing && (
            <div className='container-fluid row'>
              <h6><em>Changing this Fee Type will be automatically reflected on your existing sites, jobs, tickets, and assets.</em></h6>
            </div>
          )}

          <button
            type='submit'
            className='btn btn-lg btn-primary push-down-super-small pull-right space-left'
            disabled={hasValidationErrors || (hasSubmitErrors && !modifiedSinceLastSubmit) || pristine || submitting || disabled}>
            <span className='pull-left'>{editing ? 'Save Changes' : 'Save New Fee Type'}</span>
            <i className='material-icons pull-right thumbster-button-icon'>
              add_circle
            </i>
          </button>

          <br /><br /><br /><br /><br />
        </form>
      )}
    </Form>
  )
}

FeeTypeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  disabled: PropTypes.bool
}

function validate (data) {
  const errors = {}
  if (!data.name) {
    errors.name = 'Required'
  }

  if (!data.defaultAmount) {
    errors.defaultAmount = 'Required'
  } else if (data.defaultAmount > 999_999) {
    errors.defaultAmount = 'Value too large'
  }

  if (data.description && data.description > 4000) {
    errors.description = 'Description must be 4000 characters or less'
  }

  return errors
}
