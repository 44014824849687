
import {
  REQUEST_CURRENT_JOB_ACTION,
  RECEIVE_CURRENT_JOB_SUCCESS,
  RECEIVE_CURRENT_JOB_FAILURE,
  REQUEST_DELETE_CURRENT_JOB_ACTION,
  RECEIVE_DELETE_CURRENT_JOB_SUCCESS,
  RECEIVE_DELETE_CURRENT_JOB_FAILURE,
  REQUEST_UPDATE_CURRENT_JOB_STATUS_ACTION,
  RECEIVE_UPDATE_CURRENT_JOB_STATUS_SUCCESS,
  RECEIVE_UPDATE_CURRENT_JOB_STATUS_FAILURE
} from '../sagas/currentJobSaga'

const initialState = {
  isFetching: false,
  errors: [],
  newData: false,
  currentJob: {
    id: '',
    client_id: '',
    latitude: 0,
    longitude: 0,
    addressline1: '',
    addressline2: '',
    city: '',
    state: '',
    zip: '',
    place_id: '',
    country: '',
    notes: '',
    first_name: '',
    last_name: '',
    email: '',
    pretty_primary_phone: '',
    secondary_first_name: '',
    secondary_last_name: '',
    secondary_email: '',
    pretty_secondary_phone: '',
    resource_type: {
      id: '',
      name: ''
    },
    resources: [],
    client: {}
  }
}
const currentJobReducer = (reduxState = initialState, action) => {
  const { payload, type } = action
  switch (type) {
    case REQUEST_CURRENT_JOB_ACTION:
      return {
        ...reduxState,
        isFetching: !!payload.showSpinner,
        errors: [],
        currentJob: initialState.currentJob
      }
    case RECEIVE_CURRENT_JOB_SUCCESS:
      return {
        ...reduxState,
        isFetching: false,
        errors: [],
        currentJob: payload.job
      }
    case RECEIVE_CURRENT_JOB_FAILURE:
      return {
        ...reduxState,
        isFetching: false,
        errors: payload.errors
      }
    case REQUEST_UPDATE_CURRENT_JOB_STATUS_ACTION:
      return {
        ...reduxState,
        isFetching: true,
        errors: []
      }
    case RECEIVE_UPDATE_CURRENT_JOB_STATUS_SUCCESS:
      return {
        ...reduxState,
        isFetching: false,
        errors: [],
        currentJob: payload.job
      }
    case RECEIVE_UPDATE_CURRENT_JOB_STATUS_FAILURE:
      return {
        ...reduxState,
        isFetching: false,
        errors: payload.errors
      }
    case REQUEST_DELETE_CURRENT_JOB_ACTION:
      return {
        ...reduxState,
        isFetching: true,
        errors: []
      }
    case RECEIVE_DELETE_CURRENT_JOB_SUCCESS:
      return {
        ...reduxState,
        isFetching: false,
        errors: []
      }
    case RECEIVE_DELETE_CURRENT_JOB_FAILURE:
      return {
        ...reduxState,
        isFetching: false,
        errors: payload.errors
      }

    default:
      return reduxState
  }
}

export default currentJobReducer
