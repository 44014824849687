import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import InventoryWidgetSelect from './InventoryWidgetSelect'
import { generatePath, Link } from 'react-router-dom'
import { alphabeticalSort } from '../../utilities/arrayUtilities'
import { gql } from 'graphql-request'
import { ROUTES } from '../../routes'
import { useSelector } from 'react-redux'

/**
 * Make a site type compatible with react select library.
 */
function makeSiteTypeSelection (siteType, inventory) {
  return {
    value: siteType.id,
    label: `${siteType.name} (${siteType.shortCode}) - [${inventory.reduce((acc, { siteType: inventorySiteType }) => {
      if (siteType.id === inventorySiteType.id) {
        acc++
      }
      return acc
    }, 0)}]`,
    shortName: siteType.shortCode
  }
}

/**
 * Make a asset type compatible with react select library.
 */
function makeAssetTypeSelection (assetType) {
  return {
    value: assetType.id,
    label: assetType.shortCode
  }
}

function updateUserPreferences (graphQLClient, userId, type, typeIds) {
  const query = gql`
    mutation SaveUserAssetInventoryPreferences($userId: ID!, $type: AssetInventoryUserPreferenceEnum!, $typeIds: [ID!]! ) {
      saveAssetInventoryPreference(input: { userId: $userId, type: $type, typeIds: $typeIds }) {
        userPreferences {
          preferredSiteTypes
          preferredAssetTypes
        }
      }
    }
  `
  graphQLClient.request(query, { userId, type, typeIds })
    .catch(console.error)
}

export default function InventoryWidgetTable ({ inventory, siteTypes, assetTypes, userPreferences }) {
  const [selectedSiteTypes, setSelectedSiteTypes] = useState([])
  const [selectedAssetTypes, setSelectedAssetTypes] = useState([])
  const { user, graphQLClient } = useSelector(({ user, dataLoaders }) => ({
    user: user.user,
    graphQLClient: dataLoaders.dispatcher.graphQLClient
  }))

  useEffect(function setSelectionsToUserPreferences () {
    if (userPreferences === null) return
    setSelectedSiteTypes(siteTypes.reduce((acc, siteType) => {
      if (userPreferences.preferredSiteTypes.includes(siteType.id)) {
        acc = [...acc, makeSiteTypeSelection(siteType, inventory)]
      }

      return acc
    }, []))
    setSelectedAssetTypes(assetTypes.reduce((acc, assetType) => {
      if (userPreferences.preferredAssetTypes.includes(assetType.id)) {
        acc = [...acc, makeAssetTypeSelection(assetType)]
      }

      return acc.sort((a, b) => alphabeticalSort(a.label, b.label))
    }, []))
  }, [userPreferences, inventory, siteTypes, assetTypes])

  const selectedInventory = useMemo(() => {
    return inventory.filter(({ siteType }) => {
      return selectedSiteTypes.find(selectedSite => selectedSite.value === siteType.id)
    })
  }, [inventory, selectedSiteTypes])

  const selectableSites = useMemo(() => {
    return siteTypes
      .map(siteType => makeSiteTypeSelection(siteType, inventory))
      .sort((a, b) => alphabeticalSort(a.label, b.label))
  }, [siteTypes, inventory])

  const selectableAssets = useMemo(() => {
    return assetTypes
      .map(assetType => makeAssetTypeSelection(assetType))
      .sort((a, b) => alphabeticalSort(a.label, b.label))
  }, [assetTypes])

  const totals = useMemo(() => {
    return selectedAssetTypes.map(selectedAsset => {
      return (
        <td key={selectedAsset.value}>
          {selectedInventory.reduce((acc, { assetTypes: inventoryAssetTypes }) => {
            const matchedAsset = inventoryAssetTypes.find(a => a.id === selectedAsset.value)
            if (!matchedAsset) {
              return acc
            }
            acc += matchedAsset.count
            return acc
          }, 0)}
        </td>
      )
    })
  }, [selectedAssetTypes, selectedInventory])

  const handleSelectedSiteChange = useCallback(changedSites => {
    if (!changedSites) {
      setSelectedSiteTypes([])
      updateUserPreferences(graphQLClient, user.id, 'SITE', [])
      return
    }
    setSelectedSiteTypes(changedSites.sort((a, b) => alphabeticalSort(a.shortName, b.shortName)))
    updateUserPreferences(graphQLClient, user.id, 'SITE', changedSites.map(changedSite => changedSite.value))
  }, [setSelectedSiteTypes, user.id, graphQLClient])
  const handleSelectedAssetChange = useCallback(changedAssets => {
    if (!changedAssets) {
      setSelectedAssetTypes([])
      updateUserPreferences(graphQLClient, user.id, 'ASSET', [])
      return
    }
    setSelectedAssetTypes(changedAssets.sort((a, b) => alphabeticalSort(a.label, b.label)))
    updateUserPreferences(graphQLClient, user.id, 'ASSET', changedAssets.map(changedAsset => changedAsset.value))
  }, [setSelectedAssetTypes, user.id, graphQLClient])

  return (
    <>
      <div className='inventory-widget__select-header'>
        <div className='inventory-widget__select-header__select'>
          <div className={classNames('font-normal text-left', {
            'text-gray': !selectedInventory.length
          })}>Site Types</div>
          <InventoryWidgetSelect
            disabled={!selectableSites.length}
            options={[
              {
                label: 'Select Site Type(s)',
                options: selectableSites
              }
            ]}
            onChange={handleSelectedSiteChange}
            value={selectedSiteTypes}
            defaultValue={selectedSiteTypes}
          />
        </div>
        <div className='inventory-widget__select-header__select'>
          <div className={classNames('font-normal text-left', {
            'text-gray': !inventory.length
          })}>Asset Types</div>
          <InventoryWidgetSelect
            disabled={!selectableAssets.length}
            options={[
              {
                label: 'Select Asset(s)',
                options: selectableAssets
              }
            ]}
            onChange={handleSelectedAssetChange}
            value={selectedAssetTypes}
            defaultValue={selectedAssetTypes}
          />
        </div>
      </div>
      <div className='inventory-widget__table__wrapper'>
        <table className='inventory-widget__table'>
          <thead>
            {selectedSiteTypes.length > 0 && <tr>
              <th>
                Sites
              </th>
              {selectedAssetTypes.map(selectedAsset => (
                <th key={selectedAsset.value}>
                  {selectedAsset.label}
                </th>
              ))}
            </tr>}
          </thead>
          <tbody>
            {selectedSiteTypes.length > 0 &&
            !selectedInventory.some(({ siteType }) =>
              selectedSiteTypes.find(selectedSite => selectedSite.value === siteType.id) !== undefined)
              ? (<tr className='inventory-widget__empty'>
                <td colSpan='100%'>
                  <div>There are currently no <Link to={ROUTES.sitesSearch}>sites</Link> associated with the selected site types.</div>
                </td>
              </tr>)
              : !selectedSiteTypes.length
                  ? <tr className='inventory-widget__empty'>
                    <td colSpan='100%'>
                      {selectedAssetTypes.length
                        ? <div>To view your available assets, select one or more site types from the dropdown above.</div>
                        : <div>There are currently no assets assigned to any <Link to={ROUTES.sitesSearch}>sites</Link>.</div>}
                    </td>
                  </tr>
                  : <>
                    {selectedInventory.map(({ assetTypes: inventoryAssetTypes, yard, id }) => (
                      <tr key={id}>
                        <td><Link to={generatePath(ROUTES.site, { id })}>{yard}</Link></td>
                        {selectedAssetTypes.map(selectedAsset => {
                          const matchedAsset = inventoryAssetTypes.find(a => a.id === selectedAsset.value)
                          return (
                            <td key={selectedAsset.value} className='asset'>{matchedAsset?.count ?? null}</td>
                          )
                        })}
                      </tr>
                    ))}
                  </>}
          </tbody>
          {selectedSiteTypes.length > 0 && <tfoot>
            <tr>
              <td>Total</td>
              {totals}
            </tr>
          </tfoot>}
        </table>
      </div>
    </>
  )
}

InventoryWidgetTable.propTypes = {
  siteTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    shortCode: PropTypes.string.isRequired
  })).isRequired,
  assetTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    shortCode: PropTypes.string.isRequired
  })).isRequired,
  inventory: PropTypes.arrayOf(PropTypes.shape({
    yard: PropTypes.string.isRequired,
    siteType: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      shortCode: PropTypes.string.isRequired
    }).isRequired,
    assetTypes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      shortCode: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired
    })).isRequired
  })).isRequired,
  userPreferences: PropTypes.shape({
    preferredAssetTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    preferredSiteTypes: PropTypes.arrayOf(PropTypes.string).isRequired
  })
}
