import { put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import { history } from '../../../../history'
import BASE_URI from '../../../../utilities/BASE_URI'
import notify from '../../../../utilities/notify'
import { handleError } from '../../../../utilities/handleError'
import { getHeaders } from '../../../../utilities/getHeaders'
import { intercomConnection } from '../../../../intercomConnection'

export const REQUEST_CURRENT_HAULER_TEAM_MEMBER_ACTION = 'REQUEST_CURRENT_HAULER_TEAM_MEMBER_ACTION'
export const RECEIVE_CURRENT_HAULER_TEAM_MEMBER_SUCCESS = 'RECEIVE_CURRENT_HAULER_TEAM_MEMBER_SUCCESS'
export const RECEIVE_CURRENT_HAULER_TEAM_MEMBER_FAILURE = 'RECEIVE_CURRENT_HAULER_TEAM_MEMBER_FAILURE'

export const REQUEST_UPDATE_CURRENT_HAULER_TEAM_MEMBER_ACTION = 'REQUEST_UPDATE_CURRENT_HAULER_TEAM_MEMBER_ACTION'
export const RECEIVE_UPDATE_CURRENT_HAULER_TEAM_MEMBER_SUCCESS = 'RECEIVE_UPDATE_CURRENT_HAULER_TEAM_MEMBER_SUCCESS'
export const RECEIVE_UPDATE_CURRENT_HAULER_TEAM_MEMBER_FAILURE = 'RECEIVE_UPDATE_CURRENT_HAULER_TEAM_MEMBER_FAILURE'

function * requestCurrentHaulerTeamMember (data) {
  try {
    const id = data.payload
    const response = yield axios.get(`${BASE_URI}/hauler/users/${id}`, getHeaders())
    yield put({ type: RECEIVE_CURRENT_HAULER_TEAM_MEMBER_SUCCESS, payload: response.data })
  } catch (e) {
    const errors = handleError(e)
    yield put({ type: RECEIVE_CURRENT_HAULER_TEAM_MEMBER_FAILURE, payload: { errors } })
  }
}

function * requestUpdateCurrentHaulerTeamMember (data) {
  try {
    const { team_member: teamMember } = data.payload
    const response = yield axios.patch(
      `${BASE_URI}/hauler/users/${teamMember.id}`,
      teamMember,
      getHeaders()
    )
    yield put({ type: RECEIVE_UPDATE_CURRENT_HAULER_TEAM_MEMBER_SUCCESS, payload: response.data })
    yield intercomConnection.trackEvent('team_member_updated', {
      id: response.data.user.id,
      email: response.data.user.email
    })
    yield history.push(`/hauler/team/${teamMember.id}`)
    yield notify('success', 'Team Member Updated Successfully')
  } catch (e) {
    const errors = handleError(e)
    yield put({ type: RECEIVE_UPDATE_CURRENT_HAULER_TEAM_MEMBER_FAILURE, payload: { errors } })
  }
}

export default function * currentHaulerTeamMemberSaga () {
  yield takeLatest(REQUEST_CURRENT_HAULER_TEAM_MEMBER_ACTION, requestCurrentHaulerTeamMember)
  yield takeLatest(REQUEST_UPDATE_CURRENT_HAULER_TEAM_MEMBER_ACTION, requestUpdateCurrentHaulerTeamMember)
}
