import { loader } from 'graphql.macro'

export default async function updateInvoiceDiscountAndTax ({ dataLoaders, variables }) {
  const schema = loader('./updateInvoiceDiscountAndTax.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      haulerId: variables.haulerId,
      invoiceId: variables.invoiceId,
      discountAndTax: variables.discountAndTax
    }
  })
  return response.updateInvoiceDiscountAndTax
}
