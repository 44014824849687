import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, Link } from 'react-router-dom'
import { commaDeliminate } from '../../utilities/stringUtilities'
import Tooltip from 'react-bootstrap/lib/Tooltip'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'
import { prettyAddress } from '../../utilities/locationUtilities'
import { ROUTES } from '../../routes'

export default function SitesTable ({ sites }) {
  return (
    <div className='table table-responsive'>
      <table className='table table-striped'>
        <thead>
          <tr>
            <th width='20%'>Name</th>
            <th width='10%'>Site Notes</th>
            <th width='10%'>Site Cost</th>
            <th width='20%'>Site Type</th>
            <th width='20%'>Current Location</th>
            <th width='20%'>Action</th>
          </tr>
        </thead>
        <tbody>
          {sites.map(site => {
            const sitePath = generatePath(ROUTES.site, { id: site.id })
            return (
              <tr key={site.id}>
                <td>
                  <strong>
                    <Link to={sitePath}>{site.name}</Link>
                  </strong>
                </td>

                <td>
                  {site?.note
                    ? (
                      <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip'>{site.note}</Tooltip>}>
                        <i className='material-icons'>speaker_notes</i>
                      </OverlayTrigger>)
                    : 'None'}
                </td>

                <td>
                  ${commaDeliminate(site?.cost || 0)}
                </td>

                <td>
                  {site?.siteType?.name} ({site?.siteType?.shortCode})
                </td>

                <td>
                  {prettyAddress(site?.location)}
                </td>

                <td>
                  <div className='btn-group'>
                    <Link to={sitePath} className='btn btn-sm btn-primary-dk'>
                      Details
                    </Link>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a className='btn btn-primary-dk btn-sm dropdown-toggle hidden-xs hidden-sm drop-down' data-toggle='dropdown'>
                      <span className='caret' />
                    </a>
                    <ul className='dropdown-menu'>
                      <li>
                        <Link to={generatePath(ROUTES.editSite, { id: site.id })}>
                          <span>Edit Site</span>
                          <i className='material-icons'>edit</i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

SitesTable.propTypes = {
  sites: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    siteType: PropTypes.shape({
      shortCode: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }),
    cost: PropTypes.number.isRequired,
    location: PropTypes.shape({
      addressLine1: PropTypes.string.isRequired,
      addressLine2: PropTypes.string,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired
    })
  }))
}
