import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Spinner from 'react-spinkit'
import styles from './index.module.scss'
import { formatToUSD } from '../../utilities/formatters'
import { Field, Form } from 'react-final-form'
import { TextInput } from '../TextInput'
import cn from 'classnames'
import { DatePickerInput } from '../DatePickerInput'
import validate from './utils'

export default function CashCheckPaymentForm ({ onSubmit, initialValues = {}, invoice = {}, isSolo, submitting }) {
  return (
    <Form
      className={cn({ [styles.soloForm]: isSolo })}
      onSubmit={onSubmit}
      validate={validate(invoice?.outstandingBalance ?? 0)}
      initialValues={initialValues}
      component={InternalForm}
      invoice={invoice}
      isSolo={isSolo}
      isLoading={submitting}
    />
  )
}

CashCheckPaymentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  invoice: PropTypes.shape({
    outstandingBalance: PropTypes.number
  }),
  isSolo: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
}

function InternalForm ({ form, handleSubmit, values, errors, valid, invoice = {}, isSolo, isLoading }) {
  useEffect(function handlePaymentMethodChange () {
    if (values.paymentMethod !== 'check') {
      form.change('checkNumber', null)
    }
    if (values.paymentMethod !== 'other') {
      form.change('notes', null)
    }
  }, [form, values.paymentMethod])

  const hasError = meta => meta.touched && meta.error && !meta.active

  function handleReset () {
    form.reset()
  }

  return (
    <form onSubmit={handleSubmit} className={cn({ [styles.soloForm]: isSolo })}>
      <div className={styles.paymentOptions}>
        {errors.paymentMethod && <span>{errors.paymentMethod}</span>}
        <label htmlFor='payment-cash'>
          <Field
            name='paymentMethod'
            component='input'
            type='radio'
            value='cash'
            id='payment-cash'
          />
          Cash
        </label>
        <div>
          <label htmlFor='payment-check'>
            <Field
              name='paymentMethod'
              component='input'
              type='radio'
              value='check'
              id='payment-check'
            />
            Check
          </label>

          {values.paymentMethod === 'check' && (
            <Field
              className={cn(styles.blockInput, styles.checkNumberInput)}
              name='checkNumber'
              component='input'
              type='text'
              placeholder='Check number'
              id='checkNumber'
              maxLength={20}
            />
          )}
        </div>
        <div>
          <label htmlFor='payment-other'>
            <Field
              name='paymentMethod'
              component='input'
              type='radio'
              value='other'
              id='payment-other'
            />
            Other
          </label>
          {values.paymentMethod === 'other' && (
            <Field
              className={styles.blockInput}
              name='notes'
              component='input'
              type='text'
              placeholder='Notes'
              id='notes'
              maxLength={20}
            />
          )}
        </div>
      </div>

      <Field name='paymentAmount'
        format={formatToUSD}
        formatOnBlur={true}
        component={TextInput}
        defaultValue={formatToUSD(invoice.outstandingBalance)}>
        {({ input, meta }) => (
          <div className={cn({ 'has-error': (meta.error && meta.touched) }, styles.bottomSpacing)}>
            <label className='control-label' htmlFor='paymenAmount'>Payment Amount</label>
            <input
              {...input}
              type='text'
              className={cn('form-control')}
            />
            {meta.error && meta.touched && <span>{meta.error}</span>}
          </div>
        )}
      </Field>
      <Field
        name='paymentDate'
        label='Payment Date'
        component={DatePickerInput}
      />
      <Field name='email'>
        {({ input, meta }) => (
          <div className={cn({ 'has-error': hasError(meta) }, styles.bottomSpacing)}>
            <label className={styles.fullWidthInput}>
              Emails for receipt (comma separated)
              <textarea
                {...input}
                className='form-control'
              />
            </label>
            {hasError(meta) && <h6>{meta.error}</h6>}
          </div>
        )}
      </Field>

      <div className={styles.buttons}>
        <button
          className={cn('dis-btn dis-btn-white dis-btn-lg', styles.btn)}
          type='button'
          onClick={handleReset}
          disabled={isLoading}>
          Reset
        </button>
        <button type='submit'
          className={cn('dis-btn dis-btn-primary dis-btn-lg', styles.btn)}
          disabled={!valid || isLoading}>
          {isLoading ? <Spinner name='circle' fadeIn='none' /> : `Add Payment $${formatToUSD(values.paymentAmount)}` }
        </button>
      </div>
    </form>
  )
}

InternalForm.propTypes = {
  /**
   * https://final-form.org/docs/react-final-form/types/FormRenderProps#form
   */
  form: PropTypes.shape({
    /**
     * https://final-form.org/docs/final-form/types/FormApi#change
     */
    change: PropTypes.func.isRequired,
    /**
     * https://final-form.org/docs/final-form/types/FormApi#reset
     */
    reset: PropTypes.func.isRequired
  }).isRequired,
  /**
   * https://final-form.org/docs/react-final-form/types/FormRenderProps#handlesubmit
   */
  handleSubmit: PropTypes.func.isRequired,
  /**
   * https://final-form.org/docs/final-form/types/FormState#values
   */
  values: PropTypes.shape({
    paymentMethod: PropTypes.string,
    checkNumber: PropTypes.string,
    notes: PropTypes.string,
    paymentAmount: PropTypes.string,
    paymentDate: PropTypes.string,
    email: PropTypes.string
  }).isRequired,
  /**
   * https://final-form.org/docs/final-form/types/FormState#errors
   */
  errors: PropTypes.shape({
    paymentMethod: PropTypes.string,
    checkNumber: PropTypes.string,
    notes: PropTypes.string,
    paymentAmount: PropTypes.string,
    paymentDate: PropTypes.string,
    email: PropTypes.string
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  /**
   * https://final-form.org/docs/final-form/types/FormState#valid
   */
  valid: PropTypes.bool.isRequired,
  invoice: PropTypes.shape({
    outstandingBalance: PropTypes.number
  }),
  isSolo: PropTypes.bool.isRequired
}
