import { loader } from 'graphql.macro'

export default async function wastepayChargeSavedCard ({ dataLoaders, variables }) {
  const schema = loader('./wastepayChargeSavedCard.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      amount: variables.amount,
      cardId: variables.cardId,
      description: variables.description,
      invoiceId: variables.invoiceId,
      sendTo: variables.sendTo
    }
  })
  return response.wastepayChargeSavedCard
}
