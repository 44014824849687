import React from 'react'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import { EmailValidator, PrimaryPhoneValidator } from '../../utilities/commonValidators'
import { TextInput } from '../TextInput'
import normalizePhone from '../../pages/shared/normalizers/normalizePhone'

export default function UserProfileEditForm ({ user, onSubmit, disabled = false }) {
  return (
    <div className='row panel panel-default'>
      <div className='container-fluid push-down-super-small'>
        <Form onSubmit={onSubmit} validate={validate} initialValues={{
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          prettyPrimaryPhone: user.prettyPrimaryPhone
        }}>
          {({ handleSubmit, invalid, pristine }) => (
            <form onSubmit={handleSubmit}>
              <div className='row'>
                <div className='col-xs-12'>
                  <Field
                    name='firstName'
                    label='First Name'
                    component={TextInput}
                    autoComplete='given-name'
                  />
                  <Field
                    name='lastName'
                    label='Last Name'
                    component={TextInput}
                    autoComplete='family-name'
                  />
                  <Field
                    name='email'
                    type='email'
                    label='Email'
                    component={TextInput}
                    autoComplete='email'
                    maxLength={64}
                  />
                  <Field
                    name='prettyPrimaryPhone'
                    label='Phone Number'
                    component={TextInput}
                    normalize={normalizePhone}
                    autoComplete='tel-national'
                  />
                  <button
                    type='submit'
                    className='btn btn-lg btn-primary pull-right space-left'
                    disabled={pristine || invalid || disabled}>
                    Save Profile Changes
                  </button>
                </div>
              </div>
            </form>
          )}
        </Form>
      </div>
      <br />
    </div>
  )
}

UserProfileEditForm.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

function validate (values) {
  const errors = {}

  if (!values.firstName) {
    errors.firstName = 'Required'
  } else if (values.firstName.length > 100) {
    errors.firstName = 'First Name must be less than 100 characters'
  }

  if (!values.lastName) {
    errors.lastName = 'Required'
  } else if (values.lastName.length > 100) {
    errors.lastName = 'Last Name must be less than 100 characters'
  }

  if (!values.email) {
    errors.email = 'Required'
  } else if (values.email.length > 64) {
    errors.email = 'Email cannot be more than 64 characters'
  } else if (!EmailValidator.test(values.email)) {
    errors.email = 'Invalid email'
  }

  if (!values.prettyPrimaryPhone) {
    errors.prettyPrimaryPhone = 'Required'
  } else if (!PrimaryPhoneValidator(values.prettyPrimaryPhone)) {
    errors.prettyPrimaryPhone = 'Invalid Phone Number'
  }

  return errors
}
