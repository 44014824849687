import React from 'react'
import PropTypes from 'prop-types'
import Notification from './Notification'
import CursorPagination from '../cursor-pagination'

export default function NotificationsList ({ notifications, onRead, onPageRequest, fakeCount, totalCount, pageInfo }) {
  return (
    <div className='row panel panel-default'>
      {
        notifications.nodes.map((notification) => (
          <Notification
            key={notification.id}
            id={notification.id}
            read={notification.read}
            entityId={notification.entityId}
            entityType={notification.entityType}
            message={notification.message}
            createdAt={notification.createdAt}
            sendingUser={notification.sendingUser}
            onRead={onRead}
          />
        ))
      }

      <div className='panel-footer row'>
        {totalCount > 0 && <CursorPagination
          pageInfo={pageInfo}
          totalCount={totalCount}
          onPageRequest={onPageRequest}
          fakeCount={fakeCount}
                           />}
      </div>
    </div>
  )
}

NotificationsList.propTypes = {
  notifications: PropTypes.object.isRequired,
  onPageRequest: PropTypes.func.isRequired,
  onRead: PropTypes.func.isRequired,
  fakeCount: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired
  }),
  totalCount: PropTypes.number.isRequired,
  pageInfo: PropTypes.shape({
    endCursor: PropTypes.string,
    hasNextPage: PropTypes.bool.isRequired,
    hasPreviousPage: PropTypes.bool.isRequired,
    startCursor: PropTypes.string
  }).isRequired
}
