import { EmailValidator, PrimaryPhoneValidator } from '../../../../../../utilities/commonValidators'

export const validate = (values) => {
  const errors = {}

  /* First Name */
  if (!values.first_name) {
    errors.first_name = 'Required'
  } else if (values.first_name.length > 100) {
    errors.first_name = 'First Name must be less than 100 characters'
  }

  /* Last Name */
  if (!values.last_name) {
    errors.last_name = 'Required'
  } else if (values.last_name.length > 100) {
    errors.last_name = 'Last Name must be less than 100 characters'
  }

  /* Email */
  if (!values.email) {
    errors.email = 'Required'
  } else if (values.email.length > 64) {
    errors.email = 'Email cannot be more than 64 characters'
  } else if (!EmailValidator.test(values.email)) {
    errors.email = 'Invalid email'
  }

  /* Primary Phone */
  if (!values.pretty_primary_phone) {
    errors.pretty_primary_phone = 'Required'
  } else if (!PrimaryPhoneValidator(values.pretty_primary_phone)) { // eslint-disable-line new-cap
    errors.pretty_primary_phone = 'Invalid Phone Number'
  }

  /* Role */
  if (!values.role) {
    errors.role = 'Required'
  }

  return errors
}
