import map from 'lodash/map'
import PropTypes from 'prop-types'
import React from 'react'

export const SelectInput = ({
  input,
  label,
  placeholder,
  options,
  meta: {
    touched,
    error
  },
  disabled,
  alphabetize = false
}) => {
  let formGroupClasses = 'form-group'
  if (touched && error) {
    formGroupClasses += ' has-error'
  }

  let selectOptions
  if (alphabetize === true) {
    selectOptions = map(
      Object.keys(options).sort((a, b) => sortAlphabetize(a, b, options)),
      (key) => <option key={`${key}_${options[key]}`} value={key}>{options[key]}</option>
    )
  } else {
    selectOptions = map(
      options, (name, value) => <option key={`${name}_${value}`} value={value}>{name}</option>
    )
  }

  return (
    <div className={formGroupClasses}>
      {label && <label className='control-label'>{label}</label>}
      <select
        disabled={disabled}
        className='form-control'
        {...input}>
        <option key='placeholder' value=''>{placeholder || ''}</option>
        ;
        {selectOptions}
      </select>
      {(touched && error) && <h5>{error}</h5>}
    </div>
  )
}

SelectInput.propTypes = {
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  options: PropTypes.any,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  }),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  alphabetize: PropTypes.bool
}

function sortAlphabetize (a, b, options) {
  if (options[a] < options[b]) { return -1 }
  if (options[a] > options[b]) { return 1 }
  return 0
}
