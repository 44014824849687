import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import NormalLayoutContainer from '../../../shared/NormalLayoutContainer'
import { PageTitle } from '../../../../components/page-title'
import {
  REQUEST_SITE_TYPES_ACTION,
  REQUEST_SITE_TYPE_CREATE_ACTION,
  CLEAR_SITE_TYPE_CREATE_ERRORS
} from '../sagas/siteTypesSaga'
import CreateSiteTypeForm from './components/CreateSiteTypeForm'
import CreateSiteTypeExplanation from './components/CreateSiteTypeExplanation'
import ExistingSiteTypeList from './components/ExistingSiteTypeList'

export default function HaulerSiteTypesNewContainer () {
  const history = useHistory()
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState({
    name: '',
    shortCode: ''
  })

  const { siteTypes: { siteTypesArray }, siteTypesCreate } =
    useSelector(({ siteTypes, siteTypesCreate }) => ({
      siteTypes,
      siteTypesCreate
    }))

  const cancelCreateResourceType = () => {
    history.push('/hauler/site-types')
  }

  const clearCreateNewSiteTypeErrors = () => {
    dispatch({ type: CLEAR_SITE_TYPE_CREATE_ERRORS, payload: {} })
  }

  const createSiteType = (siteType) => {
    dispatch({ type: REQUEST_SITE_TYPE_CREATE_ACTION, payload: { siteType } })
  }

  const handleFormSubmit = (e) => {
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault()
    }
    createSiteType({
      name: formValues.name,
      short_code: formValues.shortCode
    })
  }

  useEffect(() => {
    dispatch({ type: REQUEST_SITE_TYPES_ACTION, payload: {} })
  }, [dispatch])

  return (
    <div>
      <NormalLayoutContainer showBackLink>
        <div className='container-fluid'>
          <PageTitle title='Create a New Site Type'>
            <button
              className='btn btn-link btn-lg pull-right push-down-super-small'
              onClick={cancelCreateResourceType}>
              Cancel
            </button>
          </PageTitle>

          <div className='row'>
            <div className='col-xs-12 col-sm-12 col-md-7 col-lg-7'>
              <CreateSiteTypeForm
                isFetching={siteTypesCreate.isFetching}
                onSubmit={handleFormSubmit}
                cancelCreateResourceType={cancelCreateResourceType}
                formValues={formValues}
                setFormValues={setFormValues}
                resErrors={siteTypesCreate.errors}
                clearErrors={clearCreateNewSiteTypeErrors}
              />

            </div>
            <div className='col-xs-12 col-sm-12 col-md-4 col-md-offset-1 col-lg-4 col-lg-offset-1'>
              <div className='row'>
                <CreateSiteTypeExplanation />
                <hr />
                <ExistingSiteTypeList
                  siteTypes={siteTypesArray}
                />
              </div>
            </div>
          </div>

        </div>
      </NormalLayoutContainer>
    </div>
  )
}
