import React from 'react'
import PropTypes from 'prop-types'

export const ActionText = props => (
  <h3 className='text-center login__h3'>{props.text}</h3>
)

ActionText.propTypes = {
  text: PropTypes.string.isRequired
}
