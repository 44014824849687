import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'
import Select from '../Select'

export default function FeeTypeSelect ({
  options = [],
  handleSelect,
  form,
  input,
  ticketFeeTotal
}) {
  const feeTypeOpts = [{ id: null, name: null }].concat(options)

  const ReactSelectAdapter = () => (
    <Select
      {...input}
      placeholder=''
      isMulti={false}
      isSearchable={true}
      closeMenuOnSelect={true}
      removeSelected={false}
      value={null}
      options={feeTypeOpts.map(feeType => ({
        value: feeType.id,
        label: feeType.name
      }))}
      onChange={(val) => handleSelect(val, form, ticketFeeTotal)}
    />
  )

  return (
    <div>
      <Field
        name='feeTypeId'
        component={ReactSelectAdapter}
      />
    </div>
  )
}

FeeTypeSelect.propTypes = {
  options: PropTypes.array,
  handleSelect: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  ticketFeeTotal: PropTypes.number
}
