import React, { useEffect } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import { PageTitle } from '../../components/page-title'
import { Spinner } from '../shared/Spinner'
import { REQUEST_CURRENT_RESOURCE_ACTIVITIES_ACTIONS } from '../hauler/resources/sagas/currentResourceActivitiesSaga'
import notify from '../../utilities/notify'
import AssetDetailsDisplay from '../../components/asset-details-display'
import { ResourceActivitiesDisplay } from '../hauler/resources/_id/index/components/ResourceActivitiesDisplay'
import useQuery from '../../hooks/useQuery'
import QUERY_KEYS from '../../graphql/queryKeys'
import assetQuery from '../../graphql/queries/assetQuery'
import useMutation from '../../hooks/useMutation'
import deleteResourceMutation from '../../graphql/mutations/deleteResource'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'

export default function AssetDetailsPage () {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()

  const { currentResourceActivities } = useSelector(({ currentResourceActivities }) => ({
    currentResourceActivities
  }))

  const confirmDestoryResource = () => {
    deleteResource({ id })
  }

  const onActivitiesPageRequest = (page = 1) => {
    dispatch({ type: REQUEST_CURRENT_RESOURCE_ACTIVITIES_ACTIONS, payload: { id, page } })
  }

  const { data: asset, isFetching: assetFetching } = useQuery([
    QUERY_KEYS.asset,
    id
  ],
  assetQuery,
  {
    onError: (error) => captureErrorAndNotify(error, 'Error retrieving asset')
  })

  useEffect(function onInitialRender () {
    dispatch({ type: REQUEST_CURRENT_RESOURCE_ACTIVITIES_ACTIONS, payload: { id, page: 1 } })
  }, [dispatch, id])

  const { mutate: deleteResource, isLoading: deleteAssetLoading } = useMutation(deleteResourceMutation, {
    onSuccess () {
      history.goBack()
      notify('success', 'Asset destroyed successfully!')
    },
    onError (error) {
      captureErrorAndNotify(error, `Error destroying asset ${asset?.asset?.uid}`)
    }
  })

  const isFetching = assetFetching || deleteAssetLoading

  return (
    <div>
      <NormalLayoutContainer showBackLink>
        <div className='container-fluid'>
          <PageTitle title={`Asset - ${asset?.asset?.uid}`}>
            <Link
              className='btn btn-lg btn-primary-dk pull-right push-down-super-small'
              to={`/hauler/resources/${id}/edit`}>
              <span>Edit Asset</span>
              <i className='material-icons'>mode_edit</i>
            </Link>

          </PageTitle>
          <br />

          <div className='row'>
            <Spinner isFetching={isFetching}>
              <div>
                <AssetDetailsDisplay resource={asset?.asset} />
              </div>

            </Spinner>
          </div>

          <Spinner isFetching={currentResourceActivities.isFetching}>
            <ResourceActivitiesDisplay
              activities={currentResourceActivities.activities}
              pagination={currentResourceActivities.pagination}
              onPageRequest={onActivitiesPageRequest}
            />
          </Spinner>

        </div>
        {/* Destroy Resource Button */}
        <button
          className='btn btn-link center-block'
          onClick={() => {
            // eslint-disable-next-line no-restricted-globals
            const result = confirm(
              'THIS CANNOT BE UNDONE! Deleting an asset will remove any record ' +
                    'that it existed, including its location at a job site.')
            if (result) {
              confirmDestoryResource()
            }
          }}>
          <span className='pull-left'>Destroy Asset</span>
          <i className='material-icons pull-right thumbster-button-icon'>delete</i>
        </button>
        <br />
      </NormalLayoutContainer>
    </div>
  )
}
