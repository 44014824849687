import { loader } from 'graphql.macro'

export default async function wastepayDeleteCard ({ dataLoaders, variables }) {
  const schema = loader('./wastepayDeleteCard.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      clientId: variables.clientId,
      cardId: variables.cardId
    }
  })
  return response.wastepayDeleteCard
}
