import React, { useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from '../../../utilities/dayjs'
import classNames from 'classnames'
import styles from './ticket-activities.module.scss'
import { Link, generatePath } from 'react-router-dom'
import { ROUTES } from '../../../routes'
import CursorPagination from '../../cursor-pagination'
import CreateTicketActivityForm from './CreateTicketActivityForm'
import ActivityIcon from '../../../pages/shared/ActivityIcon'

export default function TicketActivitiesDisplay ({
  activities = [],
  totalCount,
  pageInfo,
  fakeCount,
  onSubmit,
  onPageRequest,
  commentsOnly,
  setCommentsOnly
}) {
  const [addingComment, setAddingComment] = useState(false)

  function handleSubmit (data) {
    return onSubmit(data)
      .then(() => setAddingComment(false))
  }

  return (
    <div className='dis-panel'>
      <div className={classNames('dis-panel-header', styles.activitiesHeader)}>
        <h5>Ticket Activities & Comments</h5>
        <div>
          <button
            type='button'
            className={classNames('dis-btn dis-btn-sm', styles.activitiesButton, {
              [`${styles.activeButton}`]: !commentsOnly,
              [`${styles.inactiveButton}`]: commentsOnly
            })}
            onClick={() => setCommentsOnly(false)}>
            All Activities
          </button>
          <button
            type='button'
            className={classNames('dis-btn dis-btn-sm', styles.activitiesButton, {
              [`${styles.activeButton}`]: commentsOnly,
              [`${styles.inactiveButton}`]: !commentsOnly
            })}
            onClick={() => {
              setCommentsOnly(true)
            }}>
            Comments Only
          </button>
        </div>
      </div>
      <div className='dis-panel-body'>
        {addingComment &&
          <li className={classNames('dis-list-item dis-panel', styles.activityFormItem)}>
            <CreateTicketActivityForm
              onSubmit={handleSubmit}
              onCancel={() => setAddingComment(false)}
            />
          </li>
        }
        {commentsOnly && totalCount > 0 && !addingComment &&
          <div className={styles.addBtnContainer}>
            <AddCommentButton onClick={() => setAddingComment(true)} />
          </div>
        }
        {activities.map(activity => {
          const comment = activity.key === 'ticket.add_comment'
          const listItemClasses = `dis-list-item ${comment ? 'highlight-bg' : ''}`
          return (
            <li className={listItemClasses} key={activity.id}>
              <div className={styles.activitiesItemRow}>
                <div className='font-size-1-25em'>
                  {comment ? `Comment: ${activity.message}` : activity.message}
                </div>
                <ActivityIcon iconName={activity.key} />
              </div>

              <div className={styles.activitiesItemRow}>
                <h6>
                  <em>
                    By
                    {activity.ownerType.toUpperCase() === 'USER'
                      ? <Link to={generatePath(ROUTES.teamMember, { id: activity.authorId })}>
                        {` ${activity.author}`}
                      </Link>
                      : ` ${activity.author}`
                    }
                  </em>
                </h6>
                <h6>
                  {dayjs(activity.createdAt).format('dddd, MMMM Do YYYY, h:mm:ss a')}
                </h6>
              </div>
            </li>
          )
        })}
        {commentsOnly && totalCount === 0 && !addingComment && (
          <div className={styles.noComments}>
            <span className={styles.noCommentsText}>No comments.</span>
            <AddCommentButton onClick={() => setAddingComment(true)} />
          </div>
        )}
      </div>
      <div className={styles.panelFooter}>
        {totalCount > 0 && <CursorPagination
          pageInfo={pageInfo}
          totalCount={totalCount}
          onPageRequest={onPageRequest}
          fakeCount={fakeCount}
                           />}
      </div>
    </div>
  )
}
TicketActivitiesDisplay.propTypes = {
  activities: PropTypes.array,
  totalCount: PropTypes.number.isRequired,
  pageInfo: PropTypes.shape({
    endCursor: PropTypes.string,
    hasNextPage: PropTypes.bool.isRequired,
    hasPreviousPage: PropTypes.bool.isRequired,
    startCursor: PropTypes.string
  }).isRequired,
  fakeCount: PropTypes.object.isRequired,
  onPageRequest: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  commentsOnly: PropTypes.bool.isRequired,
  setCommentsOnly: PropTypes.func.isRequired
}

function AddCommentButton ({ onClick }) {
  return (
    <button className={classNames('dis-btn dis-btn-lg dis-btn-primary', styles.addCommentBtn)} onClick={onClick}>
      Add Ticket Comment
      <i className='material-icons dis-btn-icon'>add_circle</i>
    </button>
  )
}
AddCommentButton.propTypes = {
  onClick: PropTypes.func.isRequired
}
