import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import lodashFind from 'lodash/find'
import cn from 'classnames'
import NormalLayoutContainer from '../../../shared/NormalLayoutContainer'
import notify from '../../../../utilities/notify'
import {
  executeRetrieveHauler,
  executeRetrieveTicketTypesQuery,
  executeTicketTypeRequiredActionsMutation,
  executeUpdateHaulerTicketCompletionFlagMutation
} from './HaulerTicketTypesRequiredActionsContainerUtils'
import { GridHeader } from './components/GridHeader'
import { GridRow } from './components/GridRow'
import { useSelector } from 'react-redux'

export default function HaulerTicketTypesRequiredActionsContainer () {
  const [ticketTypes, setTicketTypes] = useState([])
  const [requireTicketCompletion, setRequireTicketCompletion] = useState(null)
  const { graphQLClient, user } = useSelector(({ dataLoaders, user }) => ({
    graphQLClient: dataLoaders.dispatcher.graphQLClient,
    user: user.user
  }))

  const retrieveTicketCompletion = useCallback(async () => {
    try {
      const data = await executeRetrieveHauler(graphQLClient, user.haulerId)
      setRequireTicketCompletion(data.hauler.requireTicketCompletion)
    } catch (e) {
      notify('error', 'Failed to retrieve data')
    }
  }, [setRequireTicketCompletion, graphQLClient, user.haulerId])

  const retrieveTicketTypes = useCallback(async () => {
    try {
      const data = await executeRetrieveTicketTypesQuery(graphQLClient, user.haulerId)
      setTicketTypes(data.ticketTypes)
    } catch (e) {
      notify('error', 'Failed to retrieve data')
    }
  }, [setTicketTypes, user.haulerId, graphQLClient])

  useEffect(function loadPageData () {
    retrieveTicketTypes()
    retrieveTicketCompletion()
  }, [retrieveTicketTypes, retrieveTicketCompletion])

  const handleChange = useCallback(async (newValues) => {
    try {
      const {
        requireAddAsset,
        requireRemoveAsset,
        requireAddImage,
        requireEnterWeight
      } = lodashFind(ticketTypes, { id: newValues.ticketTypeId })

      const params = {
        requireAddAsset,
        requireRemoveAsset,
        requireAddImage,
        requireEnterWeight,
        ...newValues
      }
      await executeTicketTypeRequiredActionsMutation(graphQLClient, params)
      retrieveTicketTypes()
    } catch (e) {
      notify('error', 'Failed to update data')
    }
  }, [retrieveTicketTypes, ticketTypes, graphQLClient])

  const handleRequireCompletionChange = async () => {
    const newValue = !requireTicketCompletion
    setRequireTicketCompletion(newValue)
    await executeUpdateHaulerTicketCompletionFlagMutation(graphQLClient, { requireTicketCompletion: newValue })
    executeRetrieveHauler(graphQLClient, user.haulerId)
  }

  return (
    <div className='mt-8'>
      <NormalLayoutContainer>
        <h2>Required Actions</h2>
        <p className='text-2xl'>
          Required Actions allow you to configure specific steps that must be completed before
          a specific ticket type can be completed. Drivers will be prompted in the app to complete
          any outstanding actions upon trying to complete a ticket.
        </p>

        <div className='panel panel-default px-12 py-8 mt-12'>
          <h4>Requirement Options</h4>
          <div className='flex justify-start mt-8'>
            <input
              type='checkbox'
              className={cn('h-5 w-5 text-gray-600', requireTicketCompletion === null && 'invisible')}
              name='requireCompletion'
              checked={requireTicketCompletion}
              onChange={handleRequireCompletionChange}
            />
            <label htmlFor='requireCompletion' className='ml-8 text-2xl'>
              Require driver to complete a ticket before starting the next one
            </label>
          </div>
        </div>
        <div className='panel panel-default px-12 py-8 mt-12'>
          <h4>Required Actions to Complete a Ticket</h4>
          <p className='text-xl'>
            <strong>Please note:</strong> To help avoid issues when completing tickets, if you have selected to require
            Removing an Asset, please ensure that you have Adding an Asset checked off for at least one Ticket Type.
          </p>
          <div className={`grid grid-cols-5 grid-rows-${ticketTypes.length + 1}`}>
            <GridHeader
              label='Ticket Type'
            />
            <GridHeader
              additionalClasses='justify-center'
              label='Add an Asset'
              infoText='Requires that the driver adds an asset on the ticket'
            />
            <GridHeader
              additionalClasses='justify-center'
              label='Remove an Asset'
              infoText='Requires that the driver removes an asset on the ticket'
            />
            <GridHeader
              additionalClasses='justify-center'
              label='Enter a Weight'
              infoText='Requires that the weight value on the ticket is not 0'
            />
            <GridHeader
              additionalClasses='justify-center'
              label='Add an Image'
              infoText='Requires that the driver has added an image to this ticket'
            />
            {
              ticketTypes.map((ticketType, idx) =>
                (
                  <GridRow
                    key={ticketType.id}
                    handleChange={handleChange}
                    ticketType={ticketType}
                    striped={idx % 2 !== 0}
                  />
                )
              )}
          </div>
        </div>
      </NormalLayoutContainer>
    </div>
  )
}

HaulerTicketTypesRequiredActionsContainer.propTypes = {
  ticketTypes: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    errors: PropTypes.array.isRequired,
    create: PropTypes.object,
    edit: PropTypes.object,
    ticketTypesArray: PropTypes.array
  })
}
