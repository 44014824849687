import React from 'react'
import PropTypes from 'prop-types'

export default function ExistingResourceOrTicketTypeList ({ listItems, type }) {
  return (
    <div className='row'>
      <h4>{`Existing ${type} Types`}</h4>
      <ul className='list-group'>
        {listItems.map(item => {
          return (
            <li key={item.id} className='list-group-item'>
              {`${item.name} (${item.shortCode})`}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

ExistingResourceOrTicketTypeList.propTypes = {
  listItems: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired
}
