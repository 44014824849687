import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useHistory } from 'react-router-dom'
import styles from './nav.module.css'
import { intercomConnection } from '../../intercomConnection'
import { ROUTES } from '../../routes'
import USER_ACTIONS from '../../store/user/userActions'
import revokeRefreshTokenMutation from '../../graphql/mutations/revokeRefreshToken'
import useMutation from '../../hooks/useMutation'
import useQuery from '../../hooks/useQuery'
import QUERY_KEYS from '../../graphql/queryKeys'
import notificationsQuery from '../../graphql/queries/notifications'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import { makeGraphQLClient } from '../../utilities/graphqlClient'
import { RECEIVE_NEW_REQUESTS_COUNT } from '../../store/new-requests/reducers/newRequestReducer'
import { NEW_PUSHER_SERVICE_REQUEST_DATA } from '../../store/pusher/pusherActions'
import { loader } from 'graphql.macro'
import { useChannelEvent } from '../../hooks/pusher'

function Nav ({ innerRef }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const { hauler, newRequestsCount, newRequestsCountRefreshNeeded, haulerChannel, refreshToken, user } = useSelector(({
    user: { hauler, refreshToken, user },
    newRequests,
    pusher
  }) => ({
    hauler,
    refreshToken,
    newRequestsCount: newRequests.count,
    newRequestsCountRefreshNeeded: newRequests.newRequestsCountRefreshNeeded,
    user,
    haulerChannel: pusher.haulerChannel
  }))

  const { data: notifications } = useQuery([
    QUERY_KEYS.notifications,
    {
      userId: user.id,
      first: 25
    }
  ],
  notificationsQuery,
  {
    onError: (error) => captureErrorAndNotify(error, 'Error fetching notifications'),
    refetchOnWindowFocus: false
  })

  const { mutate: revokeRefreshToken } = useMutation(revokeRefreshTokenMutation)

  const handleExternalNewServiceChange = useCallback(() => {
    const graphQLClient = makeGraphQLClient()
    const retrieveUnconfirmedServicesCount = loader('../../graphql/queries/unconfirmedServicesCount.graphql')
    graphQLClient.request(retrieveUnconfirmedServicesCount, { haulerId: hauler.id })
      .then(({ unconfirmedServices }) => {
        dispatch({ type: NEW_PUSHER_SERVICE_REQUEST_DATA, payload: { newRequestsCountRefreshNeeded: false } })
        dispatch({ type: RECEIVE_NEW_REQUESTS_COUNT, payload: { count: unconfirmedServices.count } })
      })
      .catch(error => {
        console.error('Error retrieving unconfirmed services:', error)
      })
  }, [dispatch, hauler.id])

  useChannelEvent(haulerChannel, 'new_service_request', handleExternalNewServiceChange)
  useChannelEvent(haulerChannel, 'delete_service_request', handleExternalNewServiceChange)

  useEffect(() => {
    if (hauler.proBrokerAccess && newRequestsCountRefreshNeeded) {
      handleExternalNewServiceChange(hauler.id)
    }
  }, [handleExternalNewServiceChange, newRequestsCountRefreshNeeded, hauler.proBrokerAccess, hauler.id])

  function handleSignOut () {
    // We don't necessarily care about the status of revoking the refresh token, yeah it would be nice to make sure,
    // BUT it is more important we kick the user from the app no matter what by removing local storage.
    revokeRefreshToken(refreshToken)
    localStorage.removeItem('auth')
    // Clearing the user should kick us back to the login page
    dispatch({ type: USER_ACTIONS.CLEAR_USER })
  }

  const rootLink = '/'
  const dispatchLink = '/dispatch'
  const recentLink = '/recent'
  const ticketsLink = '/hauler/tickets/search'
  const assetsLink = '/hauler/resources'
  const reportsLink = '/reports'
  const clientsLinks = ROUTES.clients
  const haulerLinks = [
    '/hauler',
    '/hauler/team',
    '/hauler/ticket-types',
    '/hauler/resource-types',
    ROUTES.userProfile,
    '/hauler/site-types'
  ]
  const notificationsLink = '/notifications'
  const dispatchActive = location.pathname === dispatchLink
  const recentActive = (location.pathname === recentLink) || (location.pathname === rootLink)

  return (
    <nav ref={innerRef} className='navbar navbar-inverse navbar-fixed-top'>
      <div className='container-fluid'>
        <div className='navbar-header'>
          <button
            type='button' className='navbar-toggle collapsed' data-toggle='collapse' data-target='#navbar'
            aria-expanded='false' aria-controls='navbar'>
            <span className='sr-only' />
            <span className='icon-bar' />
            <span className='icon-bar' />
            <span className='icon-bar' />
          </button>
          <div className='push-down-super-small'>
            <Link to='/' className='center-logo-text'>
              <img
                className={styles.logoImg}
                src='https://www.dispatcher.com/images/Dispatcher-Inverse-Signature.svg' alt='Dispatcher'
              />
            </Link>
          </div>
        </div>
        <div id='navbar' className='navbar-collapse collapse'>

          <ul className='nav navbar-nav navbar-right'>
            {hauler.proBrokerAccess && <li>
              <button
                onClick={() => history.push(ROUTES.dispatch)}
                className={classNames('btn btn-success push-down-super-small', {
                  [`${styles.noNewRequests}`]: !newRequestsCount
                })}>
                {`New Requests (${newRequestsCount})`}
              </button>
            </li>}
            <li className={`${dispatchActive ? 'active' : ''}`}><Link to='/dispatch'>Dispatch</Link></li>
            <li className={location.pathname === ROUTES.invoices ? 'active' : ''}>
              <Link to={ROUTES.invoices} role='button'>Invoices</Link>
            </li>
            <li className={location.pathname === ticketsLink ? 'active' : ''}><Link to='/hauler/tickets/search' role='button'>Tickets</Link>
            </li>

            <li className={location.pathname === ROUTES.jobs ? 'active' : ''}><Link to={ROUTES.jobs} role='button'>Jobs</Link>
            </li>

            <li className={location.pathname === clientsLinks ? 'active' : ''}><Link to={ROUTES.clients} role='button'>Accounts</Link>
            </li>

            <li className={location.pathname === assetsLink ? 'active' : ''}>
              <Link to='/hauler/resources'>Assets</Link>
            </li>

            <li className={location.pathname === reportsLink ? 'active' : ''}>
              <Link to='/reports'>Reports</Link>
            </li>

            <li className={recentActive ? 'active' : ''}>
              <Link to='/recent' role='button'>
                <i className='material-icons'>home</i>
              </Link>
            </li>

            <li className={location.pathname === notificationsLink ? 'active' : ''}><Link to='/notifications' role='button'>
              <i className='material-icons'>notifications</i>
              {(() => {
                let unread = 0
                if (notifications?.notifications.nodes.length) {
                  notifications?.notifications.nodes.map((notification) => {
                    if (!notification.read) {
                      unread += 1
                    }
                    return null
                  })

                  if (unread > 0) {
                    return (
                      <label className='label label-alert'>{unread}</label>
                    )
                  }
                  return null
                }
              })()}
            </Link>
            </li>

            <li>
              <Link to={location.pathname} role='button'>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <i
                  className='material-icons'
                  onClick={intercomConnection.triggerIntercom}>help</i>
              </Link>
            </li>

            <li className={haulerLinks.indexOf(location.pathname) !== -1 ? 'dropdown active' : 'dropdown'}>

              <a href='/hauler' className='dropdown-toggle nav-icon' data-toggle='dropdown' role='button'>
                <i className='material-icons'>settings</i>
              </a>
              <ul className='dropdown-menu' id='nav-dropdown-right'>
                <li><Link to='/hauler'>Company Settings</Link></li>
                <li role='separator' className='divider' />
                <li><Link to='/hauler/team'>Company Team</Link></li>
                <li role='separator' className='divider' />
                <li><Link to='/hauler/ticket-types'>Ticket Types</Link></li>
                <li role='separator' className='divider' />
                <li><Link to='/hauler/resource-types'>Asset Types</Link></li>
                <li role='separator' className='divider' />
                <li><Link to='/hauler/site-types'>Site Types</Link></li>
                <li role='separator' className='divider' />
                <li><Link to={ROUTES.sitesSearch}>Sites</Link></li>
                <li role='separator' className='divider' />
                <li><Link to={ROUTES.feeTypes}>Fee Types</Link></li>
                <li role='separator' className='divider' />
                {hauler.proSettings && (
                  <>
                    <li><Link to={ROUTES.pro}>PRO Settings</Link></li>
                    <li role='separator' className='divider' />
                  </>
                )}
                <li><Link to={ROUTES.userProfile}>User Profile</Link></li>
                <li role='separator' className='divider' />
                <li><Link to={ROUTES.quickbooksSettings}>QuickBooks</Link></li>
                <li role='separator' className='divider' />
                <li>
                  <button type='button' onClick={handleSignOut}>
                    Sign Out
                    {/* <i className="material-icons pull-right thumbster-button-icon">exit_to_app</i> */}
                  </button>
                </li>
              </ul>
            </li>

          </ul>
        </div>
      </div>
    </nav>
  )
}

Nav.propTypes = {
  innerRef: PropTypes.any
}

const NavComponent = React.forwardRef(function NavComponent (props, ref) {
  return (
    <Nav innerRef={ref} {...props} />
  )
})

export default NavComponent
