import { loader } from 'graphql.macro'

export default async function exportRefundToQuickbooks ({ dataLoaders, variables }) {
  const schema = loader('./exportRefundToQuickbooks.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      invoiceId: variables.invoiceId,
      transactionId: variables.transactionId,
      dispatcherRefundId: variables.dispatcherRefundId
    }
  })
  return response.exportRefundToQuickbooks
}
