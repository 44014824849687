import React from 'react'
import { Form, Field } from 'react-final-form'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './index.module.scss'
import { enumToTitleCase } from '../../utilities/stringUtilities'
import { required } from '../../utilities/commonValidators'
import { FORM_ERROR } from 'final-form'
import { TICKET_BILLING_STATUSES } from '../../utilities/constants'

export default function TicketTypeForm ({ onSubmit, initialValues = {}, disabled = false }) {
  function handleSubmit (data) {
    return onSubmit(data)
      .catch(error => {
        const formErrors = { [FORM_ERROR]: 'Update failed' }
        if (error?.response?.errors === undefined) return formErrors

        error.response.errors.forEach(err => {
          if (err?.extensions?.code === 'INPUT_ERROR') {
            formErrors[err.extensions.argument] = err.message
          }
        })

        return formErrors
      })
  }

  return (
    <div className='dis-panel'>
      <div className='dis-panel-body'>
        <Form onSubmit={handleSubmit} initialValues={initialValues}>
          {({ handleSubmit, pristine, invalid }) => (
            <form onSubmit={handleSubmit}>
              <h5>Change Name and Short Code</h5>
              <div className={styles.nameShortCode}>
                <Field name='name' validate={required}>
                  {({ input, meta }) => (
                    <div className={classNames(styles.textInput, { 'has-error': meta.error || (meta.submitError && !meta.dirtySinceLastSubmit) })}>
                      <label className='control-label' htmlFor='name'>Ticket Type Name</label>
                      <input
                        {...input}
                        id='name'
                        type='text'
                        className='form-control'
                        maxLength='15'
                      />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                      {meta.submitError && !meta.dirtySinceLastSubmit && <h6>Name {meta.submitError}</h6>}
                    </div>
                  )}
                </Field>
                <Field name='shortCode' validate={required}>
                  {({ input, meta }) => (
                    <div className={classNames(styles.textInput, { 'has-error': meta.error || (meta.submitError && !meta.dirtySinceLastSubmit) })}>
                      <label className='control-label' htmlFor='shortCode'>Ticket Type Short Code</label>
                      <input
                        {...input}
                        id='shortCode'
                        type='text'
                        className='form-control'
                        maxLength='2'
                      />
                      {meta.error && meta.touched && <h6>{meta.error}</h6>}
                      {meta.submitError && !meta.dirtySinceLastSubmit && <h6>Short Code {meta.submitError}</h6>}
                    </div>
                  )}
                </Field>
              </div>
              <h6 className={styles.editMessaging}>
                <em>Updates to the name and short code will be automatically reflected in all existing tickets of this type.</em>
              </h6>

              <h5>Change Settings</h5>

              <label htmlFor='defaultBillingStatus' className='control-label'>Default Billing Status</label>
              <Field name='defaultBillingStatus' parse={value => value || null}>
                {({ input }) => (
                  <select
                    {...input}
                    id='defaultBillingStatus'
                    className='form-control'>
                    <option value=''></option>
                    {Object.keys(TICKET_BILLING_STATUSES).map(status => <option value={status} key={status}>{enumToTitleCase(status)}</option>)}
                  </select>
                )}
              </Field>
              <h6 className={styles.editMessaging}>
                <em>Updates to settings will be reflected only on tickets created after changes are saved.</em>
              </h6>

              <div className={styles.buttonContainer}>
                <button
                  type='submit'
                  className='dis-btn dis-btn-primary dis-btn-lg'
                  disabled={disabled || pristine || invalid}>
                  <span className='pull-left'>Save Changes</span>
                  <i className='material-icons pull-right thumbster-button-icon'>check</i>
                </button>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  )
}

TicketTypeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    shortCode: PropTypes.string.isRequired,
    defaultBillingStatus: PropTypes.string
  }),
  disabled: PropTypes.bool
}
