import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import QUERY_KEYS from '../../graphql/queryKeys'
import notify from '../../utilities/notify'
import styles from './index.module.scss'
import cn from 'classnames'
import { TICKET_BILLING_STATUSES } from '../../utilities/constants'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import PageTitle from '../../components/page-title'
import { Spinner } from '../shared/Spinner'
import FormExplanation from '../shared/FormExplanation'
import TicketTypeForm from '../../components/ticket-type-form'
import EditTicketTypeStatus from '../../components/ticket-type-form/EditTicketTypeStatus'
import useMutation from '../../hooks/useMutation'
import useQuery, { generateQueryKey } from '../../hooks/useQuery'
import { ROUTES } from '../../routes'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import ticketTypeQuery from '../../graphql/queries/ticketType'
import updateTicketTypeMutation from '../../graphql/mutations/updateTicketType'
import updateTicketTypeSmsMutation from '../../graphql/mutations/updateTicketTypeSms'
import SMSTable from '../../components/ticket-types-sms-table'

export default function EditTicketTypePage () {
  const history = useHistory()
  const queryClient = useQueryClient()
  const { id } = useParams()
  const { user, hauler } = useSelector(({ user }) => ({
    user: user.user,
    hauler: user.hauler
  }))

  const { data: { ticketType }, isFetching: isFetchingTicketType } = useQuery(
    [QUERY_KEYS.ticketType, user.haulerId, id],
    ticketTypeQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Error retrieving ticket type')
      },
      placeholderData: { ticketType: {} }
    })

  const { mutateAsync: updateTicketType, isLoading: isUpdatingTicketType } = useMutation(updateTicketTypeMutation, {
    onSuccess ({ ticketType: newTicketType }) {
      const statusChanged = newTicketType.status !== ticketType.status
      notify('success', statusChanged ? `Ticket type successfully ${newTicketType.status}` : 'Ticket type updated successfully')
      history.push(ROUTES.ticketTypes)
    },
    onError (error) {
      if (error?.response?.errors !== undefined && error.response.errors.find(err => err?.extensions?.code !== 'INPUT_ERROR') === undefined) {
        return
      }

      captureErrorAndNotify(error, 'Unable to update ticket type')
    }
  })

  const { mutateAsync: updateTicketTypeSms, isLoading: isUpdatingTicketTypeSms } = useMutation(updateTicketTypeSmsMutation, {
    onSuccess (data) {
      // need to update the query because I guess checkboxes in JSX don't checkbox as they should
      queryClient.setQueryData(generateQueryKey([QUERY_KEYS.ticketType, user.haulerId, id], user.id), oldData => ({
        ticketType: { ...(oldData?.ticketType ?? {}), ...data.ticketType }
      }))
    },
    onError (error) {
      captureErrorAndNotify(error, 'Unable to activate/deactivate sms selection')
    }
  })

  function handleUpdate (data) {
    return updateTicketType({ ticketTypeId: id, ...ticketType, ...data })
  }

  function handleSmsChange (e) {
    const step = {
      stepName: e.currentTarget.name,
      active: e.currentTarget.checked
    }
    updateTicketTypeSms({
      ticketTypeId: id,
      steps: [step]
    })
  }

  return (
    <NormalLayoutContainer showBackLink>
      <PageTitle>
        <div className={styles.pageTitle}>
          <div>Edit Existing Ticket Type</div>
          <Link className='dis-btn dis-btn-blank dis-btn-lg' to={ROUTES.ticketTypes}>
            Cancel
          </Link>
        </div>
      </PageTitle>

      {isFetchingTicketType
        ? <Spinner isFetching />
        : (
          <div className='dis-form-page-container'>
            <div className='dis-form-container-left'>
              <div className={cn(styles.statusPanel, 'dis-panel')}>
                <div className={styles.statusDisplay}>
                  <EditTicketTypeStatus updateTicketType={handleUpdate} ticketType={ticketType} />
                </div>
              </div>

              <TicketTypeForm
                initialValues={ticketType}
                billingStatuses={TICKET_BILLING_STATUSES}
                disabled={isUpdatingTicketType}
                onSubmit={handleUpdate}
              />
              { hauler.smsActive && (
                <div className='dis-panel'>
                  <div className='dis-panel-body'>
                    <h5>
                      Statuses To Send SMS
                    </h5>
                    <SMSTable
                      ticketTypes={[ticketType]}
                      handleChange={handleSmsChange}
                      isLoading={isUpdatingTicketTypeSms}
                      showTicketTypeName={false}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className='dis-form-container-right'>
              <FormExplanation
                messages={[
                  'Ticket Types are the different actions you can take on your assets',
                  'For a dumpster you may want ticket types like "Drop off", "Swap Out", or "Final Pickup"',
                  'The short code is used when this ticket type is displayed on the map'
                ]}
              />
            </div>
          </div>
          )
      }
    </NormalLayoutContainer>
  )
}
