import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

export default function ModalStatusBar ({ farthestStage }) {
  return (
    <div className='flex justify-center items-center'>
      <TimelineNode stage={1} farthestStage={farthestStage} />
      <TimelineDash stage={2} farthestStage={farthestStage} />
      <TimelineNode stage={2} farthestStage={farthestStage} />
      <TimelineDash stage={3} farthestStage={farthestStage} />
      <TimelineNode stage={3} farthestStage={farthestStage} />
    </div>
  )
}

ModalStatusBar.propTypes = {
  farthestStage: PropTypes.number.isRequired
}

function TimelineDash ({ farthestStage, stage }) {
  const bgColor = farthestStage >= stage ? 'bg-green' : 'bg-gray-light'
  return (
    <div className={`h-1 w-16 ${bgColor}`} />
  )
}

TimelineDash.propTypes = {
  farthestStage: PropTypes.number.isRequired,
  stage: PropTypes.number.isRequired
}

function TimelineNode ({ farthestStage, stage }) {
  const isFurther = farthestStage >= stage
  return (
    <div className={cn('rounded-full h-12 w-12 flex items-center justify-center', { 'bg-green': isFurther, 'bg-gray-light': !isFurther })}>
      {stage}
    </div>
  )
}

TimelineNode.propTypes = {
  farthestStage: PropTypes.number.isRequired,
  stage: PropTypes.number.isRequired
}
