import { EmailValidator } from '../../utilities/commonValidators'

export function validate (values) {
  const errors = {}

  if (!values.email) {
    errors.email = 'Required'
  }

  if (values.email) {
    const emailArray = values.email.replace(/\s+/g, '').split(',')
    const invalidEmail = emailArray.some(email => !EmailValidator.test(email))
    if (invalidEmail) {
      errors.email = 'Invalid Email'
    }
  }

  if (values.saleCC == null) {
    errors.saleCC = 'Must select a card'
  }

  if (isNaN(values.saleCC)) {
    if (!values.streetAddress) {
      errors.streetAddress = 'Required'
    }

    if (!values.city) {
      errors.city = 'Required'
    }

    if (!values.state) {
      errors.state = 'Required'
    }

    if (!values.zip) {
      errors.zip = 'Required'
    }
  }

  return errors
}

export function validateBillingAddress (values) {
  const errors = {}

  if (!values.streetAddress) {
    errors.streetAddress = 'Required'
  }

  if (!values.city) {
    errors.city = 'Required'
  }

  if (!values.state) {
    errors.state = 'Required'
  }

  if (!values.zip) {
    errors.zip = 'Required'
  }

  return errors
}

export function formatJobAddressComponents (val) {
  if (val?.toLowerCase() === 'unknown') return null
  return val
}
