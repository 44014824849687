import React from 'react'
import PropTypes from 'prop-types'
import Spinner from 'react-spinkit'
import cn from 'classnames'
import styles from './index.module.scss'

export default function TicketTypesSmsTable ({
  ticketTypes,
  handleChange,
  isLoading,
  showTicketTypeName = false
}) {
  return (
    <table className='dis-table dis-table--center'>
      <thead>
        <tr>
          { showTicketTypeName && (
            <th>
              Ticket Type Name
            </th>
          )}
          <th>On Creation</th>
          <th>Enroute</th>
          <th>On Complete</th>
        </tr>
      </thead>
      <tbody>
        {ticketTypes.map((ticketType, idx) => (
          <tr key={`tt-${idx}`}>
            { showTicketTypeName && (
              <td>
                {ticketType.name} ({ticketType.shortCode})
              </td>
            )}
            <td>
              {isLoading
                ? (
                  <Spinner name='circle' className={cn(styles.spinner)} fadeIn='none' />
                  )
                : (
                  <input
                    checked={ticketType.smsOnCreate}
                    type='checkbox'
                    name='CREATE'
                    onChange={event => handleChange(event, ticketType.id)}
                    aria-label='activate sms on ticket creation'
                  />
                  )
            }
            </td>
            <td>
              {isLoading
                ? (
                  <Spinner name='circle' className={styles.spinner} fadeIn='none' />
                  )
                : (
                  <input
                    checked={ticketType.smsOnEnroute}
                    type='checkbox'
                    name='ENROUTE'
                    onChange={event => handleChange(event, ticketType.id)}
                    aria-label='activate sms when ticket is marked as Enroute'
                  />
                  )
            }
            </td>
            <td>
              {isLoading
                ? (
                  <Spinner name='circle' className={styles.spinner} fadeIn='none' />
                  )
                : (
                  <input
                    checked={ticketType.smsOnComplete}
                    type='checkbox'
                    name='COMPLETE'
                    onChange={event => handleChange(event, ticketType.id)}
                    aria-label='activate sms on ticket completion'
                  />
                  )
            }
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

TicketTypesSmsTable.propTypes = {
  ticketTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    name: PropTypes.string.isRequired,
    smsOnCreate: PropTypes.bool.isRequired,
    smsOnEnroute: PropTypes.bool.isRequired,
    smsOnComplete: PropTypes.bool.isRequired,
    shortCode: PropTypes.string.isRequired
  }).isRequired).isRequired,
  handleChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showTicketTypeName: PropTypes.bool
}
