import { useEffect } from 'react'

export function useChannelEvent (channel, eventName, callback) {
  useEffect(function listenToEvent () {
    if (!channel || !eventName || !callback) return

    channel.bind(eventName, callback)

    return function cleanupListenToEvent () {
      channel.unbind(eventName, callback)
    }
  }, [channel, eventName, callback])
}
