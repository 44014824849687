import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import NormalLayoutContainer from './shared/NormalLayoutContainer'
import useQuery from '../hooks/useQuery'
import QUERY_KEYS from '../graphql/queryKeys'
import clientImportQuery from '../graphql/queries/clientImport'
import completeClientImportMutation from '../graphql/mutations/completeClientImport'
import { captureErrorAndNotify } from '../utilities/errorHandlers'
import { Spinner } from './shared/Spinner'
import { useSelector } from 'react-redux'
import useMutation from '../hooks/useMutation'
import ImportDetails from '../components/ImportDetails'
import { ROUTES } from '../routes'
import ValidatedClientImportRowsTable from '../components/import/ValidatedClientImportRowsTable'
import CompletedClientImportRowsTable from '../components/import/CompletedClientImportRowsTable'

export default function ClientImportPage () {
  const { clientImportId } = useParams()
  const { haulerChannel } = useSelector(({ pusher }) => ({ haulerChannel: pusher.haulerChannel }))

  const {
    data: clientImportData,
    // Important to use isLoading instead of isFetching here, this page does a bit of background refreshing, we do not want to show the spinner
    // everytime we do this background refetch
    isLoading: isLoadingClientImport,
    refetch: refetchClientImport
  } = useQuery(
    [QUERY_KEYS.clientImport, { id: clientImportId }],
    clientImportQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Failed to retrieve import')
      }
    })

  const { mutate: completeClientImport, isLoading: isCompletingClientImport } = useMutation(completeClientImportMutation, {
    onError (error) {
      captureErrorAndNotify(error, 'Failed to complete import')
    }
  })

  function handleCompletion () {
    completeClientImport({ clientImportId })
  }

  useEffect(function listenToChanges () {
    if (!haulerChannel) return

    const eventName = 'change-client_import'
    haulerChannel.bind(eventName, refetchClientImport)

    return function cleanupListening () {
      haulerChannel.unbind(eventName, refetchClientImport)
    }
  }, [haulerChannel, refetchClientImport])

  return (
    <NormalLayoutContainer>
      {isLoadingClientImport
        ? <Spinner isFetching />
        : (
          <ImportDetails
            pluralImportName='accounts'
            details={clientImportData.clientImport}
            onConfirm={handleCompletion}
            disabled={isCompletingClientImport}
            listPath={ROUTES.clients}
            validatedImportRowsTableComponent={ValidatedClientImportRowsTable}
            completedImportRowsTableComponent={CompletedClientImportRowsTable}
          />
          )
      }

    </NormalLayoutContainer>
  )
}
