import React from 'react'
import { generatePath, Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useQuery from '../../hooks/useQuery'
import useMutation from '../../hooks/useMutation'
import QUERY_KEYS from '../../graphql/queryKeys'
import siteTypesQuery from '../../graphql/queries/siteTypes'
import createSiteMutation from '../../graphql/mutations/createSite'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import notify from '../../utilities/notify'
import { intercomConnection } from '../../intercomConnection'
import CreateSiteForm from '../../components/create-site-form'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import PageTitle from '../../components/page-title'
import { Spinner } from '../shared/Spinner'
import FormExplanation from '../shared/FormExplanation'
import { ROUTES } from '../../routes'

export default function CreateSitePage () {
  const history = useHistory()
  const { user, hauler } = useSelector(({ user: { user, hauler } }) => ({ user, hauler }))

  const handleFormSubmit = (vals) => {
    if (vals.cost) vals.cost = parseFloat(vals.cost.replace(/,/g, ''))
    createSite(vals)
  }

  const { data, isLoading } = useQuery(
    [
      QUERY_KEYS.siteTypes,
      user.haulerId
    ],
    siteTypesQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Failed to retrieve site types')
      }
    }
  )

  const { mutate: createSite, isLoading: createSiteLoading } = useMutation(createSiteMutation, {
    onSuccess () {
      history.push(ROUTES.sitesSearch)
      intercomConnection.trackEvent('site_created', {})
      notify('success', 'Site created successfully')
    },
    onError (error) {
      captureErrorAndNotify(error, 'Error adding site')
    }
  })

  return (
    <div>
      <NormalLayoutContainer showBackLink>
        <PageTitle>
          <div className='dis-flex-page-title'>
            <div>Create a Site</div>
            <Link className='dis-btn dis-btn-blank dis-btn-lg' to={generatePath(ROUTES.sitesSearch)}>
              Cancel
            </Link>
          </div>
        </PageTitle>
        {isLoading
          ? <Spinner isFetching />
          : (
            <div className='dis-form-page-container'>
              <div className='dis-form-container-left'>
                <CreateSiteForm
                  isFetching={createSiteLoading}
                  onSubmit={handleFormSubmit}
                  siteTypes={data.siteTypes}
                  showMetric={hauler.country !== 'US'}
                />
              </div>
              <div className='dis-form-container-right'>
                <FormExplanation
                  messages={[
                    `Sites are the Repair Shops, Container Yards, Transfer Stations, etc. you use everyday. 
                    These are not the job sites used for jobs and tickets.`
                  ]}
                />
              </div>
            </div>)}
      </NormalLayoutContainer>
    </div>
  )
}
