import { loader } from 'graphql.macro'

export default function usersQuery ({ dataLoaders, queryKey }) {
  const [, haulerId, hideDeactivated = null, sortColumn = null, sortDirection = null, userRole = null] = queryKey

  const graphQLVariables = {
    attributes: {
      haulerId
    }
  }

  if (sortColumn) {
    graphQLVariables.attributes.sortColumn = sortColumn
  }

  if (sortDirection) {
    graphQLVariables.attributes.sortDirection = sortDirection
  }

  if (hideDeactivated) {
    graphQLVariables.attributes.hideDeactivated = hideDeactivated
  }

  if (userRole) {
    graphQLVariables.attributes.userRole = userRole
  }

  const usersSchema = loader('./users.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(usersSchema, graphQLVariables)
}
