import {
  REQUEST_RECENT_TICKETS_ACTION,
  RECEIVE_RECENT_TICKETS_SUCCESS,
  RECEIVE_RECENT_TICKETS_FAILURE
} from '../sagas/recentTicketsSaga'

export const initialState = {
  isFetching: false,
  errors: [],
  tickets: [],
  refreshNeeded: false,
  pagination: {},
  statusCount: {},
  num_flagged: 0
}

export default function recentTicketsReducer (reduxState = initialState, action) {
  const { type, payload } = action
  const statusCount = {
    open: 0,
    en_route: 0,
    completed: 0,
    cancelled: 0
  }
  let numFlagged = 0
  switch (type) {
    case REQUEST_RECENT_TICKETS_ACTION:
      return {
        ...reduxState,
        isFetching: true,
        errors: []
      }
    case RECEIVE_RECENT_TICKETS_FAILURE:
      return {
        ...reduxState,
        isFetching: false,
        errors: payload.errors
      }
    case RECEIVE_RECENT_TICKETS_SUCCESS:
      payload.tickets.map((ticket) => {
        ++statusCount[ticket.status]
        if (ticket.flagged) {
          ++numFlagged
        }
        return numFlagged
      })

      return {
        ...reduxState,
        isFetching: false,
        refreshNeeded: false,
        tickets: payload.tickets,
        pagination: payload.pagination,
        statusCount,
        num_flagged: numFlagged
      }
    default:
      return reduxState
  }
}
