import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { useQueryClient } from 'react-query'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import ticketTypesQuery from '../../graphql/queries/ticketTypes.js'
import PageTitle from '../../components/page-title'
import USER_ACTIONS from '../../store/user/userActions'
import useMutation from '../../hooks/useMutation'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import notify from '../../utilities/notify'
import updateHaulerMutation from '../../graphql/mutations/updateHaulerDetails'
import updateTicketTypeSmsMutation from '../../graphql/mutations/updateTicketTypeSms'
import SMSEnableButton from '../../components/sms-enable-button'
import SMSTable from '../../components/ticket-types-sms-table'
import QUERY_KEYS from '../../graphql/queryKeys'
import useQuery, { generateQueryKey } from '../../hooks/useQuery'
import styles from './index.module.scss'

export default function SMSSettingsPage () {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const { user, hauler } = useSelector(({ user }) => ({
    user: user.user,
    hauler: user.hauler
  }))

  const [isEnabled, setIsEnabled] = useState(hauler.smsActive)

  const { data: { ticketTypes }, isFetching: isLoadingTicketTypes } = useQuery(
    [QUERY_KEYS.ticketTypes, { haulerId: user.haulerId }],
    ticketTypesQuery,
    {
      enabled: isEnabled,
      onError (error) {
        captureErrorAndNotify(error, 'Failed to retrieve ticket types')
      },
      placeholderData: { ticketTypes: [] }
    }
  )

  const { mutateAsync: updateTicketTypeSms, isLoading: isUpdatingTicketTypeSms } = useMutation(updateTicketTypeSmsMutation, {
    onSuccess (data) {
      // need to update the query because I guess checkboxes in JSX don't checkbox as they should
      queryClient.setQueryData(generateQueryKey([QUERY_KEYS.ticketTypes, { haulerId: user.haulerId }], user.id), oldData => {
        const oldTickets = oldData.ticketTypes
        const updatedIndex = oldTickets.findIndex(ot => ot.id === data.ticketType.id)

        oldTickets[updatedIndex] = { ...oldTickets[updatedIndex], ...data.ticketType }
        return {
          ticketTypes: oldTickets
        }
      })
    },
    onError (error) {
      captureErrorAndNotify(error, 'Unable to activate/deactivate sms selection')
    }
  })

  const { mutate: updateHauler, isLoading } = useMutation(updateHaulerMutation, {
    onSuccess (data) {
      dispatch({ type: USER_ACTIONS.UPDATE_HAULER, payload: { hauler: { smsActive: data.hauler.smsActive } } })
      notify('success', `Successfully ${data.hauler.smsActive ? 'Enabled' : 'Disabled'} SMS.`)
    },
    onError (error) {
      captureErrorAndNotify(error, 'Failed to Update Hauler Settings')
    }
  })

  function handleUpdate (isEnabledAtTimeOfClick) {
    setIsEnabled(!isEnabledAtTimeOfClick)
    updateHauler({ haulerId: user.haulerId, haulerFields: { smsActive: !isEnabledAtTimeOfClick } })
  }

  function handleSmsChange (e, ticketTypeId) {
    const step = {
      stepName: e.currentTarget.name,
      active: e.currentTarget.checked
    }
    updateTicketTypeSms({
      ticketTypeId,
      steps: [step]
    })
  }

  return (
    <NormalLayoutContainer showBackLink>
      <PageTitle>
        Customer SMS
        <div className={styles.subtitle}>
          Global setup for SMS Communication to Customers.
        </div>
      </PageTitle>
      <div className={cn('dis-panel dis-panel-body')}>
        <SMSEnableButton
          smsEnabled={isEnabled}
          handleUpdate={handleUpdate}
          isLoading={isLoading}
          step={'HAULER'}
        />
        <p className={cn(styles.smsNote)}>
          Note: Enabling or disabling SMS will affect all Accounts and Jobs. However, customer opt-outs will still be respected.
        </p>
      </div>
      { (isEnabled && !isLoadingTicketTypes) && (
        <div className='dis-panel dis-panel-body'>
          <SMSTable
            ticketTypes={ticketTypes}
            handleChange={handleSmsChange}
            isLoading={isUpdatingTicketTypeSms}
            showTicketTypeName={true}
          />
        </div>
      )}
    </NormalLayoutContainer>
  )
}
