import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styles from './modal.module.css'
import classNames from 'classnames'

const modalRoot = document.getElementById('modal')

const Modal = ({
  children,
  isOpen = false,
  closeModal = () => {
  },
  className = null
}) => {
  if (!modalRoot) {
    throw new Error('Place container with id "modal" next to your root container')
  }

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
      return
    }

    document.body.style.overflow = 'visible'
  }, [isOpen])

  return isOpen
    ? ReactDOM.createPortal(
    // TODO 8-15-2021 remove these eslint-disables and make the modal more accessible.
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div className={styles.background} onClick={closeModal}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className={classNames(styles.modal, className)}
        // Prevent the modal content click events from bubbling up and causing it to close.
          onClick={e => e.stopPropagation()}>
          {children}
        </div>
      </div>,
      modalRoot
    )
    : null
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  className: PropTypes.string
}

export default Modal
