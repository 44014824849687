import { loader } from 'graphql.macro'

export default function clientImportRows ({ dataLoaders, queryKey }) {
  const [, variables] = queryKey
  const schema = loader('./clientImportRows.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(schema, {
    status: variables.status,
    clientImportId: variables.clientImportId,
    first: variables.first,
    before: variables.before,
    after: variables.after,
    last: variables.last
  })
}
