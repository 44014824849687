import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import PageTitle from '../../components/page-title'
import notify from '../../utilities/notify'
import QUERY_KEYS from '../../graphql/queryKeys'
import { TICKET_BILLING_STATUSES } from '../../utilities/constants'
import styles from './index.module.scss'
import CreateTypeForm from '../../components/create-type-form'
import FormExplanation from '../shared/FormExplanation'
import ExistingResourceOrTicketTypeList from '../../components/ExistingResourceOrTicketTypeList'
import useMutation from '../../hooks/useMutation'
import useQuery from '../../hooks/useQuery'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import { ROUTES } from '../../routes'
import createTicketTypeMutation from '../../graphql/mutations/createTicketType'
import ticketTypesQuery from '../../graphql/queries/ticketTypes'

export default function CreateTicketType () {
  const history = useHistory()
  const [resErrors, setResErrors] = useState({
    name: false,
    shortCode: false
  })

  const { user } = useSelector(({ user: { user } }) => ({ user }))

  const { mutate: createTicketType, isLoading: createTicketTypeLoading } = useMutation(createTicketTypeMutation, {
    onSuccess () {
      notify('success', 'Created new ticket type successfully!')
      history.push(ROUTES.ticketTypes)
    },
    onError (error) {
      const messages = error.response?.errors[0]?.message.split(',')
      const checkErrorMessages = (msg) => {
        return messages.findIndex(errMsg => errMsg.toLowerCase().trim() === msg)
      }
      const shortCodeErrorIndex = checkErrorMessages('short code has already been taken')
      const nameErrorIndex = checkErrorMessages('name has already been taken')
      if (shortCodeErrorIndex > -1 || nameErrorIndex > -1) {
        setResErrors({
          name: nameErrorIndex > -1 ? messages[nameErrorIndex] : '',
          shortCode: shortCodeErrorIndex > -1 ? messages[shortCodeErrorIndex] : ''
        })
      } else {
        captureErrorAndNotify(error, 'Unable to create ticket type')
      }
    }
  })

  const { data, isFetching } = useQuery(
    [QUERY_KEYS.ticketTypes, { haulerId: user.haulerId }],
    ticketTypesQuery,
    {
      placeholderData: { ticketTypes: [] },
      onError (error) {
        captureErrorAndNotify(error, 'Error retrieving ticket types list')
      }
    })

  function handleFormSubmit (input) {
    createTicketType(input)
  }

  return (
    <div>
      <NormalLayoutContainer showBackLink>
        <PageTitle>
          <div className={styles.pageTitle}>
            <div>Create a New Ticket Type</div>
            <Link className='dis-btn dis-btn-blank dis-btn-lg' to={ROUTES.ticketTypes}>
              Cancel
            </Link>
          </div>
        </PageTitle>

        <div className='dis-form-page-container'>
          <div className='dis-form-container-left'>
            <CreateTypeForm
              billingStatuses={TICKET_BILLING_STATUSES}
              resErrors={resErrors}
              isFetching={isFetching || createTicketTypeLoading}
              onSubmit={handleFormSubmit}
              type='Ticket'
            />
          </div>
          <div className='dis-form-container-right'>
            <FormExplanation
              showRule={false}
              messages={[
                'Ticket Types are the different actions you can take on your assets',
                'For a dumpster you may want ticket types like "Drop off", "Swap Out", or "Final Pickup"',
                'The short code is used when this ticket type is displayed on the map'
              ]}
            />
            <hr />
            {data && <ExistingResourceOrTicketTypeList listItems={data?.ticketTypes} type='Ticket' />}
          </div>
        </div>

      </NormalLayoutContainer>
    </div>
  )
}
