import { loader } from 'graphql.macro'

export default async function exportResources ({ dataLoaders, variables }) {
  const schema = loader('./exportResources.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      haulerId: variables.haulerId,
      searchFields: variables.searchFields,
      sortColumn: variables.sortColumn,
      sortDirection: variables.sortDirection,
      onlyOnJobs: variables.onlyOnJobs,
      onlyOnSites: variables.onlyOnSites,
      onlyOnUsers: variables.onlyOnUsers
    }
  })
  return response.exportResourcesSearch
}
