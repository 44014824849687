import { useInfiniteQuery as reactQueryUseInfiniteQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { generateQueryKey } from './useQuery'

/**
 * Custom hook that wraps react-query's useInfiniteQuery. We do this for a few reasons:
 * * Allows us to pass common custom data to query functions like dataLoaders
 * * Allows us to set reasonable defaults like only make API requests if we have authentication.
 * * Allows us to be reactive to user changes automatically without boilerplate code in every component
 * * Gives us the flexibility to replace react-query in the future without changing 100 files
 *
 * @param {string|string[]} queryKey - Akin to react-query's query keys https://react-query-v3.tanstack.com/guides/query-keys
 * @param {function} queryFn - Akin to react-query's query functions https://react-query-v3.tanstack.com/guides/query-functions
 * @param {Object} [options = {}] - Akin to react-query options https://react-query-v3.tanstack.com/reference/useQuery#_top
 *  with some additional options.
 */
export default function useInfiniteQuery (queryKey, queryFn, options = {}) {
  const { user, dataLoaders } = useSelector(({ user, dataLoaders }) => ({ user: user.user, dataLoaders }))
  options = setOptionDefaults(options)

  return reactQueryUseInfiniteQuery(
    generateQueryKey(queryKey, user?.id),
    ({ queryKey, pageParam }) => queryFn({
      dataLoaders,
      queryKey,
      pageParam
    }),
    {
      ...options,
      enabled: options.enabled &&
        (options.requireAuth ? dataLoaders.isAuth : true)
    }
  )
}

/**
 * Sets our defaults for our useInfiniteQuery options
 * @param {Object} options
 * @return {Object}
 */
function setOptionDefaults (options) {
  return {
    ...options,
    // Most API requests REQUIRE authentication headers to be sent which is why we default these to true
    requireAuth: options.requireAuth ?? true,
    // Queries should be enabled by default, in other words, execute queries immediately unless told otherwise
    enabled: options.enabled ?? true
  }
}
