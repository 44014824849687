import { loader } from 'graphql.macro'

export default async function updateInvoice ({ dataLoaders, variables }) {
  const schema = loader('./updateInvoice.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      id: variables.id,
      date: variables.date,
      dueDate: variables.dueDate,
      includeTerms: variables.includeTerms,
      message: variables.message,
      paymentTerms: variables.paymentTerms,
      terms: variables.terms,
      billTo: variables.billTo,
      includePayNowLink: variables.includePayNow
    }
  })
  return response.updateInvoice
}
