import {
  REQUEST_CURRENT_HAULER_TEAM_MEMBER_NOTIFICATIONS_ACTION,
  RECEIVE_CURRENT_HAULER_TEAM_MEMBER_NOTIFICATIONS_SUCCESS,
  RECEIVE_CURRENT_HAULER_TEAM_MEMBER_NOTIFICATIONS_FAILURE
} from '../sagas/currentHaulerTeamMemberNotificationsSaga'

const initialState = {
  isFetching: false,
  errors: [],
  notifications: [],
  pagination: {
    current_page: '',
    total_pages: '',
    max_per_page: '',
    total_count: ''
  }
}

export default function currentHaulerTeamMemberNotificationsReducer (reduxState = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case REQUEST_CURRENT_HAULER_TEAM_MEMBER_NOTIFICATIONS_ACTION:
      return {
        ...reduxState,
        isFetching: true,
        errors: []
      }
    case RECEIVE_CURRENT_HAULER_TEAM_MEMBER_NOTIFICATIONS_SUCCESS:
      return {
        ...reduxState,
        isFetching: false,
        notifications: payload.notifications,
        pagination: payload.pagination
      }
    case RECEIVE_CURRENT_HAULER_TEAM_MEMBER_NOTIFICATIONS_FAILURE:
      return {
        ...reduxState,
        isFetching: false,
        errors: payload.errors
      }
    default:
      return reduxState
  }
}
