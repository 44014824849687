import React from 'react'
import { useSelector } from 'react-redux'
import { history } from '../../history'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import { PageTitle } from '../../components/page-title'
import { Spinner } from '../shared/Spinner'
import FormExplanation from '../shared/FormExplanation'
import useMutation from '../../hooks/useMutation'
import useQuery from '../../hooks/useQuery'
import QUERY_KEYS from '../../graphql/queryKeys'
import createAssetMutation from '../../graphql/mutations/createAsset'
import resourceTypesQuery from '../../graphql/queries/resourceTypes'
import { ROUTES } from '../../routes'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import notify from '../../utilities/notify'
import AssetCreateForm from '../../components/asset-create-form'

export default function AssetCreatePage () {
  const { user } = useSelector(({ user }) => ({
    user
  }))

  const { data: { resourceTypes = [] } = {}, isFetching: isFetchingResourceTypes } = useQuery(
    [QUERY_KEYS.resourceTypes, { haulerId: user.hauler.id, status: 'ENABLED' }],
    resourceTypesQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Failed to retrieve resource types')
      }
    }
  )

  const cancelNewResource = () => {
    history.push(ROUTES.assets)
  }

  const { mutate: createAsset, isLoading: createAssetLoading } = useMutation(createAssetMutation, {
    onSuccess () {
      notify('success', 'New Asset Created!')
      cancelNewResource()
    },
    onError (error) {
      const validationError = error?.response?.errors[0]?.message.toLowerCase() === 'validation failed: uid has already been taken'
      captureErrorAndNotify(error, validationError ? 'Uid has already been taken' : 'Failed to create asset')
    }
  })

  const handleFormSubmit = (values) => {
    let variables = {
      uid: values.uid,
      resourceTypeId: values.resourceTypeId,
      placementNote: values.placementNote
    }

    if (values?.latitude && values?.longitude) {
      variables = {
        ...variables,
        location: {
          latitude: values?.latitude,
          longitude: values?.longitude,
          placeId: values.placeId,
          addressLine1: values.addressLine1,
          city: values.city,
          state: values.state,
          zip: values.zip
        }
      }
    }

    createAsset(variables)
  }

  return (
    <div>
      <NormalLayoutContainer showBackLink>
        <div className='container-fluid'>
          <PageTitle title='Create an Asset'>
            <button
              className='btn btn-lg btn-link pull-right push-down-very-small'
              onClick={cancelNewResource}>Cancel
            </button>

          </PageTitle>

          <Spinner isFetching={isFetchingResourceTypes}>
            <div className='row'>
              <div className='col-xs-12 col-sm-12 col-md-7 col-lg-7'>
                <AssetCreateForm isFetching={createAssetLoading} resourceTypes={resourceTypes} onSubmit={handleFormSubmit} />;
              </div>
              <div className='col-xs-12 col-sm-12 col-md-4 col-md-offset-1 col-lg-4 col-lg-offset-1'>
                <div className='row'>
                  <FormExplanation
                    messages={[
                      'Assets are the rentable items your company owns. You can assign assets to jobs when they are onsite',
                      `Each asset must be of a specific asset type. For example, "Container - 20 Yard" may be a asset type and "726" may be the 
                        asset ID of a specific 20 yard container.`
                    ]}
                  />
                </div>
              </div>
            </div>
          </Spinner>
        </div>
      </NormalLayoutContainer>
    </div>
  )
}
