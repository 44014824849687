import React from 'react'
import { PowerBIEmbed } from 'powerbi-client-react'
import powerBI from 'powerbi-client'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import styles from './ReportPage.module.css'
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function ReportPage () {
  const { reports, reportToken } = useSelector(({ reports: { reports, reportToken } }) => ({
    reports,
    reportToken
  }))
  const { id } = useParams()
  const report = reports.find(report => report.id === id)

  return (
    <NormalLayoutContainer>
      <div className={styles.navigation}>
        <div className={styles.navigationLeft}>
          <Link to='/reports'>
            <i className={`${styles.backIcon} material-icons`}>west</i>
          </Link>
          <h1 className={styles.reportTitle}>{report.name}</h1>
        </div>
        <div className='btn-group'>
          <Link to='/reports' className={`${styles.navigationButton} btn btn-sm btn-primary`}>
            Switch Reports
          </Link>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className={`${styles.navigationButton} btn btn-primary btn-sm dropdown-toggle hidden-xs hidden-sm drop-down`} data-toggle='dropdown'>
            <span className='caret' />
          </a>
          <ul className='dropdown-menu'>
            <li>
              {reports.map(report => (
                <Link key={report.id} to={`/reports/${report.id}`}>
                  {report.name}
                </Link>
              ))}
            </li>
          </ul>
        </div>
      </div>
      <PowerBIEmbed
        embedConfig={{
          type: 'report',
          id,
          embedUrl: report.embedURL,
          accessToken: reportToken,
          tokenType: powerBI.models.TokenType.Embed,
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: false
              }
            }
          }
        }}

        cssClassName={styles.report}
      />
    </NormalLayoutContainer>
  )
}
