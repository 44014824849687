import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ObjectParam, StringParam, NumberParam, BooleanParam, useQueryParams } from 'use-query-params'
import resourceSearchQuery from '../graphql/queries/resourcesSearch'
import QUERY_KEYS from '../graphql/queryKeys'
import NormalLayoutContainer from './shared/NormalLayoutContainer'
import { PageTitle } from '../components/page-title'
import { Spinner } from './shared/Spinner'
import ResourcesTableSearchForm from '../components/resources-search/ResourcesTableSearchForm'
import ResourcesTable from '../components/resources-search/ResourcesTable'
import Modal from '../components/modal/Modal'
import ImportModal from '../components/import/ImportModal'
import { resourcesSortColumnEnumMap } from '../graphql/enums'
import { captureErrorAndNotify } from '../utilities/errorHandlers'
import { ROUTES } from '../routes'
import resourceTypesQuery from '../graphql/queries/resourceTypes'
import notify from '../utilities/notify'
import exportResourcesMutation from '../graphql/mutations/exportResources'
import useQuery from '../hooks/useQuery'
import useMutation from '../hooks/useMutation'

const pageSize = 25

export default function ResourcesSearchPage () {
  const [fakeCount, setFakeCount] = useState(null)
  // const [isImportModalOpen, setIsImportModalOpen] = useState(false)
  const { user } = useSelector(({ user }) => ({ user: user.user }))

  const [queryParams, setQueryParams] = useQueryParams({
    sort: ObjectParam,
    pager: ObjectParam,
    uid: StringParam,
    relatedSearch: StringParam,
    relatedType: StringParam,
    maxIdleDays: NumberParam,
    minIdleDays: NumberParam,
    resourceTypeId: StringParam,
    addressSearch: StringParam,
    importModalOpen: BooleanParam
  })

  const { data: resourcesSearchData, isFetching: isFetchingResources } = useQuery([
    QUERY_KEYS.resourcesSearch,
    {
      haulerId: user.haulerId,
      ...queryParams,
      first: queryParams.pager?.direction === undefined || queryParams.pager?.direction === 'after' ? pageSize : undefined,
      last: queryParams.pager?.direction === 'before' ? pageSize : undefined,
      after: queryParams.pager?.direction === 'after' ? queryParams.pager?.cursor : undefined,
      before: queryParams.pager?.direction === 'before' ? queryParams.pager?.cursor : undefined
    }
  ],
  resourceSearchQuery,
  {
    enabled: Boolean(queryParams.sort),
    onError (error) {
      captureErrorAndNotify(error, 'Error fetching assets')
    }
  })

  const { data: { resourceTypes } } = useQuery(
    [QUERY_KEYS.resourceTypes, { haulerId: user.haulerId, status: 'ENABLED' }],
    resourceTypesQuery,
    {
      onError () {
        notify('error', 'Unable to retrieve resource types list')
      },
      placeholderData: { resourceTypes: [] }
    })

  const { mutate: exportResources, isLoading: isExportingResources } = useMutation(exportResourcesMutation, {
    onSuccess (data) {
      const email = data?.userEmail
      window.alert(`Export Complete! The export will be emailed to ${email} with a link to download.`)
    },
    onError (error) {
      captureErrorAndNotify(error, 'Error exporting assets')
    }
  })

  function handleExportResources () {
    exportResources({
      haulerId: user.haulerId,
      searchFields: parseSearchFieldsForExport(queryParams),
      sortColumn: resourcesSortColumnEnumMap[queryParams.sort.column],
      sortDirection: queryParams.sort.direction.toUpperCase(),
      onlyOnJobs: queryParams.relatedType === 'job',
      onlyOnSites: queryParams.relatedType === 'site',
      onlyOnUsers: queryParams.relatedType === 'user'
    })
  }

  function onPageRequest (direction, cursor) {
    setQueryParams({ pager: { cursor, direction } })

    if (!fakeCount) return
    if (direction === 'before') {
      setFakeCount(prevFakeCount => ({ start: prevFakeCount.start - pageSize, end: prevFakeCount.end - pageSize }))
    }
    if (direction === 'after') {
      setFakeCount(prevFakeCount => ({ start: prevFakeCount.start + pageSize, end: prevFakeCount.end + pageSize }))
    }
  }

  function onSortChange (newSortColumn) {
    let newSortDirection = 'desc'
    if (newSortColumn === queryParams.sort.column) {
      newSortDirection = queryParams.sort.direction === 'asc' ? 'desc' : 'asc'
    }

    setFakeCount(null)
    setQueryParams({ sort: { column: newSortColumn, direction: newSortDirection } })
  }

  function handleSearch (values) {
    setFakeCount(null)
    setQueryParams({
      uid: values.uid,
      relatedType: values.relatedType,
      minIdleDays: values.minIdleDays,
      maxIdleDays: values.maxIdleDays,
      relatedSearch: values.relatedSearch,
      resourceTypeId: values.resourceTypeId,
      addressSearch: values.addressSearch
    })
  }

  const parseSearchFieldsForExport = (searchFields) => {
    let parsedFields = { ...searchFields }
    if (parsedFields.relatedType === 'job') {
      parsedFields.jobCustomId = parsedFields.relatedSearch
    } else if (parsedFields.relatedType === 'site') {
      parsedFields.siteName = parsedFields.relatedSearch
    } else if (parsedFields.relatedType === 'user') {
      parsedFields.userName = parsedFields.relatedSearch
    } else {
      parsedFields = {
        ...parsedFields,
        jobCustomId: parsedFields.relatedSearch,
        siteName: parsedFields.relatedSearch,
        userName: parsedFields.relatedSearch,
        accountName: parsedFields.relatedSearch
      }
    }
    delete parsedFields.relatedSearch
    delete parsedFields.relatedType
    delete parsedFields.pager
    delete parsedFields.sort
    return parsedFields
  }

  useEffect(function setDefaultQueryParams () {
    const defaultQueryParams = {}
    if (!queryParams.sort) {
      defaultQueryParams.sort = { column: 'lastMoved', direction: 'desc' }
    }
    setQueryParams(defaultQueryParams)
  }, [queryParams, setQueryParams])

  // useEffect(function toggleFakePagination () {
  //   if (!assets || fetching || fakeCount !== null) return
  //   setFakeCount({ start: 1, end: Math.min(pageSize, assets?.totalCount) })
  // }, [assets, fetching, fakeCount, setFakeCount])

  return (
    <>
      <Modal isOpen={queryParams.importModalOpen || false} className='w-full'>
        <ImportModal handleClose={() => setQueryParams({ importModalOpen: false }) } pluralImportName='assets' />
      </Modal>

      <NormalLayoutContainer>
        <div className='container-fluid'>
          <PageTitle title='Assets'>
            <div className='row container-fluid'>
              <Link to='/hauler/resources/new' className='btn btn-lg btn-primary pull-right push-down-super-small space-left'>
                <span className='pull-left'>New Asset</span>
                <i className='material-icons pull-right thumbster-button-icon'>add</i>
              </Link>

              <button
                type='button'
                className='btn btn-lg pull-right push-down-super-small space-left'
                onClick={() => setQueryParams({ importModalOpen: true })}>
                <span className=' pull-left'>Import Assets</span>
                <i className='material-icons pull-right thumbster-button-icon'>publish</i>
              </button>

              <Link to={ROUTES.resourceCharts} className='btn btn-lg btn-default pull-right push-down-super-small space-left'>
                <span className='pull-left'>Charts</span>
              </Link>
            </div>
          </PageTitle>

          <div className='row panel panel-default push-down-very-small'>
            <div className='container-fluid push-down-super-small'>
              <ResourcesTableSearchForm
                onSubmit={handleSearch}
                resourceTypes={resourceTypes}
                initialValues={queryParams}
                handleExportResources={handleExportResources}
                resourcesExporting={isExportingResources}
              />
            </div>
          </div>

          {isFetchingResources
            ? <Spinner isFetching />
            : (
              <div className='row'>
                <div className='panel panel-default'>
                  <div className='panel-body'>
                    {queryParams.sort && (
                      <ResourcesTable
                        assets={resourcesSearchData.assets}
                        sort={queryParams.sort}
                        onSortChange={onSortChange}
                        onPageRequest={onPageRequest}
                      />
                    )}
                  </div>
                </div>
              </div>
              )
          }
        </div>
      </NormalLayoutContainer>
    </>
  )
}
