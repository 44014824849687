import { loader } from 'graphql.macro'

export default async function exportPaymentToQuickbooks ({ dataLoaders, variables }) {
  const schema = loader('./exportPaymentToQuickbooks.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      invoiceId: variables.invoiceId,
      transactionId: variables.transactionId,
      dispatcherPaymentId: variables.dispatcherPaymentId
    }
  })
  return response.exportPaymentToQuickbooks
}
