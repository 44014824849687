import { put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import BASE_URI from '../../../../utilities/BASE_URI'
import { handleError } from '../../../../utilities/handleError'
import { getHeaders } from '../../../../utilities/getHeaders'

export const REQUEST_CURRENT_RESOURCE_ACTIVITIES_ACTIONS = 'REQUEST_CURRENT_RESOURCE_ACTIVITIES_ACTIONS'
export const RECEIVE_CURRENT_RESOURCE_ACTIVITIES_SUCCESS = 'RECEIVE_CURRENT_RESOURCE_ACTIVITIES_SUCCESS'
export const RECEIVE_CURRENT_RESOURCE_ACTIVITIES_FAILURE = 'RECEIVE_CURRENT_RESOURCE_ACTIVITIES_FAILURE'

function * requestCurrentResourceActivities (data) {
  try {
    const { id, page } = data.payload
    const response = yield axios.get(`${BASE_URI}/hauler/activities?entity=resource&page=${page}&id=${id}`, getHeaders())
    yield put({ type: RECEIVE_CURRENT_RESOURCE_ACTIVITIES_SUCCESS, payload: response.data })
  } catch (e) {
    const errors = handleError(e)
    yield put({ type: RECEIVE_CURRENT_RESOURCE_ACTIVITIES_FAILURE, payload: { errors } })
  }
}

export default function * currentResourceActivities () {
  yield takeLatest(REQUEST_CURRENT_RESOURCE_ACTIVITIES_ACTIONS, requestCurrentResourceActivities)
}
