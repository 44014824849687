import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, Link } from 'react-router-dom'
import dayjs from '../utilities/dayjs'
import WarningIcon from '../pages/shared/WarningIcon'
import TicketStatusLabel from './TicketStatusLabel'
import { ROUTES } from '../routes'

export default function RecentTickets ({ tickets, job }) {
  return (
    <div className='row panel panel-default'>
      <div className='panel-heading'>
        <div className='container-fluid'>

          <div>
            <h4 className='pull-left'>
              Last 5 Tickets
              &nbsp;
              <Link to={`/hauler/tickets/search?jobCustomId=${job.customId}`}>
                &nbsp;<small className='text-primary link'>(Search)</small>
              </Link>
            </h4>
          </div>
        </div>
      </div>
      <div className='panel-body table-responsive'>
        <table className='table table-striped'>
          <thead>
            <tr>
              <th width='3%' />
              <th width='5%'>ID</th>
              <th width='10%'>Status</th>
              <th width='15%'>Date</th>
              <th width='20%'>Ticket Type</th>
              <th width='20%'>Asset Type</th>
              <th width='15%'>View Details</th>
            </tr>
          </thead>
          <tbody>
            {tickets.length === 0
              ? (
                <tr>
                  <td colSpan={7}>
                    <h4 className='text-center'>No Tickets</h4>
                  </td>
                </tr>
                )
              : tickets.map((ticket) => {
                const ticketPath = generatePath(ROUTES.ticket, { id: ticket.id })
                return (
                  <tr key={ticket.id}>
                    <td>
                      {ticket.flagged ? <WarningIcon /> : null}
                    </td>
                    <td>
                      <Link to={ticketPath}>{ticket.customId}</Link>
                    </td>
                    <td>
                      <Link to={ticketPath}>
                        <TicketStatusLabel
                          status={ticket.status}
                          hasActiveException={ticket.hasActiveException}
                          exceptionMessage={ticket.exceptionMessage}
                        />
                      </Link>
                    </td>
                    <td>
                      <div>{dayjs(ticket.date).format('MMM D, YYYY')}</div>
                    </td>
                    <td>
                      <Link to={ROUTES.ticketTypes}>{ticket.ticketType.name}</Link>
                    </td>
                    <td>
                      <Link to={ROUTES.resourceTypes}>{ticket.resourceType.name}</Link>
                    </td>
                    <td>
                      <Link to={ticketPath}>
                        <i className='material-icons medium-icon'>info</i>
                      </Link>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

RecentTickets.propTypes = {
  tickets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    hasActiveException: PropTypes.bool.isRequired,
    exceptionMessage: PropTypes.string,
    ticketType: PropTypes.shape({
      name: PropTypes.string.isRequired
    }).isRequired,
    resourceType: PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  })).isRequired,
  job: PropTypes.shape({
    customId: PropTypes.string.isRequired
  }).isRequired
}
