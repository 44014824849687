import React, { useMemo } from 'react'
import cn from 'classnames'
import { generatePath } from 'react-router-dom'
import { ROUTES } from '../../routes'
import ButtonLinkOrNotification from '../button-link-or-notification'
import styles from './index.module.scss'
import PropTypes from 'prop-types'
import InvoiceTotals from '../invoice-totals'
import InvoiceLineItemsTable from '../invoice-line-items-table'
import useMutation from '../../hooks/useMutation'
import createCustomerInvoice from '../../graphql/mutations/createCustomerInvoice'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import * as InlineSpinner from 'react-spinkit'

export default function InvoicePreview ({
  invoice,
  showQboButton,
  showDiscountAndTax,
  showActionColumn = false,
  showLineItemTotals = false,
  editRoute
}) {
  const hasInvoiceTickets = invoice.tickets?.length > 0

  const showMultiTicketTaxDisclaimer = useMemo(() => {
    if (invoice.tickets == null) return false
    const firstTicket = invoice.tickets[0]
    if (firstTicket == null || firstTicket.job == null) return false
    return invoice.tickets.some(ticket => ticket.job.id !== firstTicket.job.id)
  }, [invoice])

  const { mutate: printInvoice, isLoading: isPrintingInvoice } = useMutation(createCustomerInvoice, {
    onSuccess ({ link }) {
      return window.open(link, '_blank', 'noopener,noreferrer')
    },
    onError (error) {
      return captureErrorAndNotify(error, 'Error generating invoice PDF.', 6)
    }
  })

  function handlePreviewInvoiceClick () {
    printInvoice({ invoiceID: invoice.uuid })
  }

  return (
    <div className={cn(styles.invoice, 'dis-panel')}>
      <header>
        <div className={styles.titleContainer}>
          <h3 className={styles.title}>{`Invoice #${invoice?.dispatcherInvoiceNumber}`}</h3>
          {isPrintingInvoice
            ? (<InlineSpinner className={styles.printLoader} name='circle' fadeIn='none' />)
            : (
              <button
                type='button'
                onClick={handlePreviewInvoiceClick}
                className={styles.printBtn}>
                <i className='material-icons'>print</i> <span className={styles.printText}>Print Invoice</span>
              </button>
              )
          }
        </div>
        <div className={styles.bill}>
          <h4 className={styles.billTitle}>Bill To</h4>
          <address className={styles.billAddress}>
            {invoice.client.name}
            <br />
            {addressify([invoice.client.addressline1, invoice.client.addressline2])}
            <br />
            {addressify([invoice.client.city, invoice.client.state, invoice.client.zip])}
          </address>
        </div>
      </header>
      <InvoiceLineItemsTable
        invoice={invoice}
        showDiscountAndTax={showDiscountAndTax}
        showActionColumn={showActionColumn}
        showLineItemTotals={showLineItemTotals}
        editRoute={editRoute}
      />
      {hasInvoiceTickets &&
        <InvoiceTotals
          invoice={invoice}
          showDiscountAndTax={showDiscountAndTax}
          multiTicketTaxDisclaimer={showMultiTicketTaxDisclaimer}
        />
      }
      {showQboButton && invoice?.id && (
        <ButtonLinkOrNotification
          linkClasses={cn('dis-btn dis-btn-primary-lt dis-btn-lg', styles.closeInQboBtn, styles.closeInQboBtnLink, {
            'dis-disabled-link': !hasInvoiceTickets
          })}
          linkHref={generatePath(ROUTES.invoicesExport, { id: invoice.id })}
          linkText='Close Out Invoice in QBO'
          notificationText='Account has not been exported to Quickbooks.'
          notificationType='error'
          dontExport={!invoice.client.existsInQbo && invoice.client.didPromptClientExportSelection}
        />
      )}
    </div>
  )
}

InvoicePreview.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    dispatcherInvoiceNumber: PropTypes.string.isRequired,
    client: PropTypes.shape({
      name: PropTypes.string,
      didPromptClientExportSelection: PropTypes.bool.isRequired,
      existsInQbo: PropTypes.bool.isRequired,
      addressline1: PropTypes.string,
      addressline2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string
    }).isRequired,
    tickets: PropTypes.arrayOf(PropTypes.shape({
      job: PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    })),
    uuid: PropTypes.string.isRequired
  }).isRequired,
  showQboButton: PropTypes.bool.isRequired,
  showDiscountAndTax: PropTypes.bool.isRequired,
  showActionColumn: PropTypes.bool,
  showLineItemTotals: PropTypes.bool,
  editRoute: PropTypes.string
}

function addressify (x) {
  return x.filter(x => x != null && x !== '').join(', ')
}
