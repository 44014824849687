import { put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import BASE_URI from '../../../../utilities/BASE_URI'
import { handleError } from '../../../../utilities/handleError'
import { getHeaders } from '../../../../utilities/getHeaders'

export const REQUEST_ALL_CLIENTS_ACTION = 'REQUEST_ALL_CLIENTS_ACTION'
export const RECEIVE_ALL_CLIENTS_SUCCESS = 'RECEIVE_ALL_CLIENTS_SUCCESS'
export const RECEIVE_ALL_CLIENTS_FAILURE = 'RECEIVE_ALL_CLIENTS_FAILURE'

function * requestAllClients (data) {
  try {
    const response = yield axios.get(`${BASE_URI}/hauler/clients/all`, getHeaders())
    yield put({ type: RECEIVE_ALL_CLIENTS_SUCCESS, payload: { clients: response.data } })
  } catch (e) {
    if (e.response && e.response.status === 401) {
      localStorage.removeItem('auth')
    }
    const errors = handleError(e)
    yield put({ type: RECEIVE_ALL_CLIENTS_FAILURE, payload: { errors } })
  }
}

export default function * allClientsSaga () {
  yield takeLatest(REQUEST_ALL_CLIENTS_ACTION, requestAllClients)
}
