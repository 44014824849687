import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import { TextInput } from './TextInput'
import { composeValidators, required, validEmail, validPassword } from '../utilities/commonValidators'
import { FORM_ERROR } from 'final-form'

export default function LoginForm ({ onSubmit, disabled = false, showImpersonate = false }) {
  function handleSubmit (data) {
    return onSubmit(data)
      .catch(error => ({ [FORM_ERROR]: error.message }))
  }

  return (
    <Form onSubmit={handleSubmit}>
      {({
        handleSubmit,
        pristine,
        submitting,
        submitSucceeded,
        hasValidationErrors,
        hasSubmitErrors,
        modifiedSinceLastSubmit
      }) => (
        <form className='w-full md:w-4/5 mx-auto' onSubmit={handleSubmit}>
          <div className='text-center'>
            <Field
              name='email'
              type='email'
              component={TextInput}
              placeholder='Email'
              autoComplete='username'
              validate={composeValidators(required, validEmail)}
            />
            <Field
              name='password'
              type='password'
              component={TextInput}
              placeholder='Password'
              autoComplete='current-password'
              validate={composeValidators(required, validPassword)}
            />
            {showImpersonate && (
              <Field
                name='impersonateEmail'
                type='email'
                component={TextInput}
                placeholder="Enter Other User's Email"
                validate={composeValidators(required, validPassword)}
              />
            )}
            <button
              type='submit'
              className='btn btn-primary push-down-very-small'
              disabled={hasValidationErrors || (hasSubmitErrors && !modifiedSinceLastSubmit) ||
                pristine || submitting || submitSucceeded || disabled}>Enter
            </button>
          </div>
        </form>
      )}
    </Form>
  )
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showImpersonate: PropTypes.bool
}
