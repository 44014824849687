import React from 'react'
import PropTypes from 'prop-types'
import Spinner from 'react-spinkit'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import classNames from 'classnames'
import inputStyles from './inputs.module.scss'
import styles from './calendarInput.module.scss'

export default function CalendarInput ({
  value = null,
  disabled = false,
  loading,
  onChange,
  labelName,
  minDate,
  labelText
}) {
  return (loading
    ? <Spinner name='circle' className={classNames(inputStyles.spinner, inputStyles.centered)} fadeIn='none' />
    : (
      <>
        <label className={inputStyles.hidden} htmlFor={labelName}>{labelText}</label>
        <DatePicker
          id={labelName}
          className={styles.calendar}
          minDate={minDate}
          popperClassName={styles.popper}
          disabled={disabled}
          selected={value}
          onChange={onChange}
          dateFormat='MM/dd/yyyy'
          popperPlacement='left'
          popperModifiers={{
            preventOverflow: {
              enabled: true
            }
          }}
        />
      </>
      ))
}

CalendarInput.propTypes = {
  value: PropTypes.instanceOf(Date),
  disabled: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  labelName: PropTypes.string,
  minDate: PropTypes.instanceOf(Date).isRequired,
  labelText: PropTypes.string.isRequired
}
