import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import NormalLayoutContainer from '../../../../shared/NormalLayoutContainer'
import { PageTitle } from '../../../../../components/page-title'
import Error from '../../../../shared/Error'
import { Spinner } from '../../../../shared/Spinner'
import FormExplanation from '../../../../shared/FormExplanation'
import SiteTypeStatusLabel from '../../../../shared/SiteTypeStatusLabel'
import {
  REQUEST_SITE_TYPES_ENABLE_ACTION,
  CLEAR_SITE_TYPES_ENABLE_ERRORS,
  REQUEST_SITE_TYPES_DISABLE_ACTION,
  CLEAR_SITE_TYPES_DISABLE_ERRORS
} from '../../sagas/siteTypesSaga'
import {
  REQUEST_CURRENT_SITE_TYPE_ACTION,
  REQUEST_UPDATE_CURRENT_SITE_TYPE_ACTION,
  CLEAR_CURRENT_SITE_TYPE_ERRORS
} from '../../sagas/currentSiteTypeSaga'
import EditSiteTypeForm from './components/EditSiteTypeForm'

// eslint-disable-next-line camelcase
export default function HaulerSiteTypes_IdEditContainer () {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()
  const [formValues, setFormValues] = useState({
    name: '',
    shortCode: ''
  })

  const { currentSiteType, siteTypesDisable, siteTypesEnable } =
    useSelector(({ currentSiteType, siteTypesDisable, siteTypesEnable }) => ({
      currentSiteType, siteTypesDisable, siteTypesEnable
    }))

  const cancelEditSiteType = () => {
    history.push('/hauler/site-types')
  }

  const clearCurrentSiteTypeErrors = () => {
    dispatch({ type: CLEAR_CURRENT_SITE_TYPE_ERRORS, payload: {} })
  }

  const clearSiteTypesEnableErrors = () => {
    dispatch({ type: CLEAR_SITE_TYPES_ENABLE_ERRORS, payload: {} })
  }

  const clearSiteTypesDisableErrors = () => {
    dispatch({ type: CLEAR_SITE_TYPES_DISABLE_ERRORS, payload: {} })
  }

  const enableSiteType = () => {
    dispatch({ type: REQUEST_SITE_TYPES_ENABLE_ACTION, payload: id })
  }

  const disableSiteType = () => {
    dispatch({ type: REQUEST_SITE_TYPES_DISABLE_ACTION, payload: id })
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    dispatch({
      type: REQUEST_UPDATE_CURRENT_SITE_TYPE_ACTION,
      payload: {
        params: {
          name: formValues.name,
          short_code: formValues.shortCode,
          id
        }
      }
    })
  }

  useEffect(function onInitialRender () {
    dispatch({ type: REQUEST_CURRENT_SITE_TYPE_ACTION, payload: id })
  }, [dispatch, id])

  useEffect(() => {
    if (currentSiteType?.siteType.name && currentSiteType?.siteType.short_code) {
      setFormValues({
        name: currentSiteType.siteType.name,
        shortCode: currentSiteType.siteType.short_code
      })
    }
  }, [currentSiteType.siteType.name, currentSiteType.siteType.short_code])

  return (
    <div>
      <NormalLayoutContainer showBackLink>
        <div className='container-fluid'>
          <PageTitle title='Edit Existing Site Type'>
            <button
              className='btn btn-link pull-right push-down-super-small'
              onClick={cancelEditSiteType}>Cancel
            </button>
          </PageTitle>

          <Spinner isFetching={currentSiteType.isFetching}>
            <div>
              <div className='row'>
                <div className='col-xs-12 col-sm-12 col-md-7 col-lg-7'>

                  <h4>Change Status</h4>
                  <div className='row panel panel-default padding-10px'>
                    <div className='font-size-1-5em inline-block'>
                      Current Status:
                      <span className='space-left'>
                        <SiteTypeStatusLabel status={currentSiteType.siteType.status} />
                      </span>
                    </div>

                    <ReactTooltip />
                    {/* Enable/Disable Button */}
                    {(() => {
                      if (currentSiteType.siteType.status === 'enabled') {
                        const disallowDisable = currentSiteType.siteType.sites_count && currentSiteType.siteType.sites_count > 0
                        return (<div
                          className='inline-block pull-right margin-top-0-5-pct'
                          data-tip={disallowDisable ? "Can't disable with attached sites" : null}>
                          <button
                            className='btn btn-danger btn-sm'
                            onClick={() => disableSiteType()}
                            disabled={disallowDisable}>
                            <strong>Disable</strong>
                          </button>
                        </div>)
                      } else if (currentSiteType.siteType.status === 'disabled') {
                        return (<button
                          className='btn btn-primary btn-sm inline-block pull-right margin-top-0-5-pct'
                          onClick={() => enableSiteType()}>
                          Enable
                        </button>)
                      }
                      return null
                    })()}
                  </div>

                  <h4>Change Name and Short Code</h4>
                  <EditSiteTypeForm
                    onSubmit={handleFormSubmit}
                    isFetching={currentSiteType.isFetching}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    resErrors={currentSiteType.errors}
                    siteType={currentSiteType.siteType}
                    clearErrors={clearCurrentSiteTypeErrors}
                  />
                </div>
                <div className='col-xs-12 col-sm-12 col-md-4 col-md-offset-1 col-lg-4 col-lg-offset-1'>
                  <div className='row'>
                    <FormExplanation
                      messages={[
                        'You can edit the visible name of an existing Site Types on this page.',
                        'Site Types are meant to be types of your preset locations, like Container Yards, Transfer Stations, and Repair Shops.',
                        'Note that editing the name of a asset type here changes the name of that Site Type across the system.'
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Spinner>

        </div>
      </NormalLayoutContainer>
      <Error errors={siteTypesEnable.errors} clearErrors={clearSiteTypesEnableErrors} />
      <Error errors={siteTypesDisable.errors} clearErrors={clearSiteTypesDisableErrors} />
    </div>
  )
}
