import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

export default class UserStatusLabel extends Component {
  static labelClasses (status) {
    let classes = 'label label-pill'
    if (status === 'ACTIVE') {
      classes += ' label-success'
    } else if (status === 'INACTIVE') {
      classes += ' label-danger'
    }
    return classes
  }

  render () {
    const { deleted_at: deletedAt, linkPath } = this.props
    const status = deletedAt ? 'INACTIVE' : 'ACTIVE'
    if (!status) {
      return null
    }
    const label = <span className={UserStatusLabel.labelClasses(status)}> {status} </span>
    if (linkPath) {
      return <Link to={linkPath}>{label}</Link>
    }
    return label
  }
}

UserStatusLabel.propTypes = {
  deleted_at: PropTypes.any,
  linkPath: PropTypes.string
}
