import React from 'react'
import { Field } from 'react-final-form'
import FormGroupAdapter from '../form-group-adaptor'
import styles from './index.module.scss'
import cn from 'classnames'

export default function PaymentForm () {
  return (
    <div className={styles.receiptSection}>
      <div>
        <Field
          type='checkbox'
          name='sendPaymentReceipt'
          component={FormGroupAdapter}
        />
        <label className={styles.checkBoxLabel} htmlFor='sendPaymentReceipt'>Automatically send payment receipt to customer</label>
      </div>

      <div>
        <Field
          type='checkbox'
          name='sendPaymentLineItems'
          component={FormGroupAdapter}
        />
        <label className={styles.checkBoxLabel} htmlFor='sendPaymentLineItems'>Show line item fees to customer</label>
      </div>

      <div className='form-group push-down-super-small'>
        <Field type='text' name='paymentEmailSubject'>
          {({ input, meta }) => {
            const hasError = (meta) => {
              return meta.touched && meta.error && !meta.active
            }
            return (
              <div className={cn({ 'has-error': hasError(meta) })}>
                <label className='control-label' htmlFor='paymentEmailSubject'>Subject Line</label>
                <div className={styles.subjectLine}>
                  <FormGroupAdapter input={input} />
                  <div className={styles.subjectLineSuffix}>- Invoice #[XXXXXX]</div>
                </div>
                {hasError(meta) && <h6>{meta.error}</h6>}
              </div>
            )
          }}
        </Field>
      </div>

      <div className='form-group'>
        <label className='control-label' htmlFor='paymentEmailMessage'>Email Message (Optional)</label>
        <Field
          type='textArea'
          name='paymentEmailMessage'
          component={FormGroupAdapter}
        />
      </div>
    </div>
  )
}

PaymentForm.propTypes = {}
