import { loader } from 'graphql.macro'
import { intercomConnection } from '../../intercomConnection'

export default async function updateClient ({ dataLoaders, variables }) {
  const schema = loader('./updateClient.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      haulerId: variables.haulerId,
      clientFields: variables.clientFields,
      clientId: variables.clientId
    }
  })
  intercomConnection.trackEvent('client_updated', {
    client_id: response.updateClient.client.id,
    client_custom_id: response.updateClient.client.customId,
    client_name: response.updateClient.client.name
  })
  return response.updateClient
}
