import React, { useMemo, useEffect } from 'react'
import PropType from 'prop-types'
import { generatePath, Link } from 'react-router-dom'
import { ROUTES } from '../../routes'
import Select from '../Select'
import Modal from '../../components/modal/Modal'
import QBOFeeTypeDescriptionModal from '../../components/qbo-fee-type-description-modal'
import { Spinner } from '../../pages/shared/Spinner'

export default function ExportFeeTypesToQBO ({
  qboFeeTypes,
  unexportedFeeTypes,
  onSubmit, values,
  setValues,
  closeDescriptionsModal,
  handleContinue,
  descriptionCheckIndex,
  mismatchedDescriptions,
  setMismatchedDescriptions,
  isFetching
}) {
  useEffect(function onInitialRender () {
    const initialValues = unexportedFeeTypes.reduce((accumulator, feeType) => {
      accumulator[feeType.feeTypeId] = { action: 'existing', existingId: null, name: feeType.name }
      return accumulator
    }, {})
    setValues(initialValues)
  }, [setValues, unexportedFeeTypes])
  const feeTypeOptions = useMemo(() => qboFeeTypes.map(feeType => ({
    value: feeType.id,
    label: feeType.name
  })), [qboFeeTypes])

  function checked (id, action) {
    return values[id].action === action
  }

  function changeAction (id, value) {
    setValues({ ...values, [id]: { ...values[id], action: value } })
  }

  function changeExistingId (id, existingId) {
    setValues({ ...values, [id]: { ...values[id], existingId } })
  }

  function handleSubmit (event) {
    event.preventDefault()
    onSubmit(values)
  }

  return (
    <>
      <Modal isOpen={mismatchedDescriptions?.length > 0} closeModal={closeDescriptionsModal}>
        <QBOFeeTypeDescriptionModal
          cancel={closeDescriptionsModal}
          handleContinue={handleContinue}
          index={descriptionCheckIndex}
          mismatchedDescriptions={mismatchedDescriptions}
          setMismatchedDescriptions={setMismatchedDescriptions}
        />
      </Modal>
      <Spinner isFetching={isFetching}>
        <div className='bg-white p-8 rounded border border-gray-light mt-12 shadow'>
          <h2 className='text-orange text-5xl text-center'>Action Required: Fee Type Not Found</h2>
          <hr />
          <form onSubmit={handleSubmit}>
            <ul>
              {
                values && Object.keys(values).map(feeTypeId => {
                  const feeType = values[feeTypeId]
                  return (
                    (<li key={feeTypeId}>
                      <p className='mt-12 mb-4 text-2xl'>
                        <Link to={generatePath(ROUTES.editFeeType, { id: feeTypeId })}>{feeType.name}</Link> could not
                        be found in QuickBooks. Choose an Option below to continue.
                      </p>
                      <div className='flex items-center'>
                        <label className='block flex items-center text-md m-0'>
                          <input
                            type='radio'
                            name={`exportFeeType-${feeTypeId}`}
                            value='existing'
                            onChange={e => changeAction(feeTypeId, e.currentTarget.value)}
                            checked={checked(feeTypeId, 'existing')}
                          />
                          <span className='ml-2'>Use an existing product in QuickBooks:</span>
                        </label>
                        <Select
                          className='w-64 ml-4'
                          options={feeTypeOptions}
                          isDisabled={checked(feeTypeId, 'new')}
                          onChange={data => changeExistingId(feeTypeId, data?.value)}
                        />
                      </div>
                      <label className='block mt-2 flex items-center text-md'>
                        <input
                          type='radio'
                          name={`exportFeeType-${feeTypeId}`}
                          value='new'
                          onChange={e => changeAction(feeTypeId, e.currentTarget.value)}
                          checked={checked(feeTypeId, 'new')}
                        />
                        <span className='ml-2'>Create a new product in QuickBooks</span>
                      </label>
                    </li>)
                  )
                })
              }
            </ul>
            <button className='uppercase text-white bg-green hover:bg-green-10-dark rounded shadow py-2 px-8 mt-4'>Continue</button>
          </form>
        </div>
      </Spinner>
    </>
  )
}

ExportFeeTypesToQBO.propTypes = {
  unexportedFeeTypes: PropType.arrayOf(PropType.shape({
    feeTypeId: PropType.string.isRequired,
    name: PropType.string,
    qboItemId: PropType.string
  })).isRequired,
  qboFeeTypes: PropType.arrayOf(PropType.shape({
    id: PropType.string.isRequired,
    name: PropType.string
  })).isRequired,
  onSubmit: PropType.func.isRequired,
  values: PropType.array,
  setValues: PropType.func.isRequired,
  closeDescriptionsModal: PropType.func.isRequired,
  handleContinue: PropType.func.isRequired,
  descriptionCheckIndex: PropType.number.isRequired,
  mismatchedDescriptions: PropType.array,
  setMismatchedDescriptions: PropType.func.isRequired,
  isFetching: PropType.bool.isRequired
}
