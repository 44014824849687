import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { gql } from 'graphql-request'
import { reduce, values } from 'kyanite'
import { GRAPHQL_AVAILABILITY_DELIVERY_DAY } from '../constants'
import { applyAvailabilityUpdate, availabilitiesProps } from '../utils'
import CheckboxInput from '../inputs/CheckboxInput'
import Tooltip from '../tooltip'
import styles from './availabilityTableHeader.module.scss'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

const columnTitles = ['Same Day', 'Next Day', 'Days Out', 'Next Available', 'Saturday', 'Sunday']
export default function AvailabilityTableHeaders ({
  availabilities,
  onChange,
  updating,
  onUpdating,
  isDisabled,
  haulerId
}) {
  const { graphQLClient } = useSelector(({ dataLoaders }) => ({ graphQLClient: dataLoaders.dispatcher.graphQLClient }))

  const availabilityValues = useMemo(() => {
    return reduce((val, acc) => {
      if (val === 'aggregate') return acc
      return acc.concat(availabilities[val])
    }, [], Object.keys(availabilities))
  }, [availabilities])

  function applyAllActive (deliveryDay, field) {
    onUpdating(deliveryDay)
    const isActive = !availabilities.aggregate[field]
    const updates = availabilityValues.map(availability => `
      ${availability.deliveryType}_${availability.id}: updateAvailabilityActiveState(input: { 
      haulerId: ${haulerId},
      id: "${availability.id}",
      deliveryType: ${availability.deliveryType},
      deliveryDay: ${deliveryDay},
      isActive: ${isActive} }) {
        availability {
          ...availabilityProperties
          ... on SizedCurrentAvailability {
            sizeId
            size {
              id
              name
            }
          }
        }
      }
    `)
    const mutation = gql`
      fragment availabilityProperties on Availability {
        id
        deliveryType
        sameDayActive
        sameDayCutoffTime
        sameDayCutoffTime
        nextDayActive
        nextDayCutoffTime
        saturdayActive
        saturdayCutoffTime
        sundayActive
        sundayCutoffTime
        nextAvailableService
        deliveryDate
        daysOut
        daysOutCutoffTime
        mustCall
      }
      mutation ApplyAllSameDayActive {
        ${updates}
      }
    `
    graphQLClient.request(mutation)
      .then(data => {
        let newAvailabilities = { ...availabilities }
        values(data).forEach(({ availability: updatedAvailability }) => {
          newAvailabilities = applyAvailabilityUpdate(newAvailabilities, updatedAvailability)
        })
        newAvailabilities.aggregate = {
          ...newAvailabilities.aggregate,
          [field]: isActive
        }
        onChange(newAvailabilities)
        onUpdating(null)
      })
      .catch(error => {
        console.error(error)
        onUpdating(null)
      }) // TODO actually handle error
  }

  return (
    <>
      <thead>
        <tr className={styles.titleRow}>
          <th />
          <th className={styles.mustCallHeader}>
            <span>
              <span className={styles.noWrap}>Must Call for</span> Availability
              <Tooltip
                name='Must Call Details'
                trigger={<i className={classNames('material-icons', styles.mustCallIcon)}>help</i>}
                position='Right'>
                <div className={styles.tooltipContent}>
                  <div className={styles.tooltipDetails}>
                    Marking a size or service as must call signals that a rep has to call to find out when your next available inventory or
                    service is. This feature is primarily for when you are low or out of inventory.
                  </div>
                  <div className={styles.tooltipDetailNote}>
                    <span className={styles.tooltipDetailNoteWarning}>
                      Note:
                    </span>
                    <span>
                      Having this feature set to must call might result in fewer orders so only use it when necessary.
                    </span>
                  </div>
                </div>
              </Tooltip>
            </span>
          </th>
          {columnTitles.map((title, index) => {
            return (
              <th key={index}>
                {title}
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        <tr className={styles.titleRowApplyAll}>
          <td>Apply All</td>
          <td>
            {/* <CheckboxInput */}
            {/*  size={'small'} */}
            {/*  checked={availabilities.aggregate.mustCall} */}
            {/*  disabled={isDisabled} */}
            {/*  loading={false} */}
            {/*  labelName='applyAllMustCall' */}
            {/*  labelText='Apply All Must Call' */}
            {/*  onChange={() => applyAll(!availabilities.aggregate.mustCall, 'mustCall', */}
            {/*    GRAPHQL_AVAILABILITY_DELIVERY_DAY.MUST_CALL, 'mustCall')} */}
            {/* /> */}
          </td>
          <td>
            <CheckboxInput
              size={'small'}
              checked={availabilities.aggregate.sameDayActive}
              disabled={isDisabled}
              loading={updating === GRAPHQL_AVAILABILITY_DELIVERY_DAY.SAME_DAY}
              labelName='applyAllSameDayActive'
              labelText='Apply All Same Day Active'
              onChange={() => applyAllActive(GRAPHQL_AVAILABILITY_DELIVERY_DAY.SAME_DAY, 'sameDayActive')}
            />
          </td>
          <td>
            <CheckboxInput
              size={'small'}
              checked={availabilities.aggregate.nextDayActive}
              disabled={isDisabled}
              loading={updating === GRAPHQL_AVAILABILITY_DELIVERY_DAY.NEXT_DAY}
              labelName='applyAllNextDayActive'
              labelText='Apply All Next Day Active'
              onChange={() => applyAllActive(GRAPHQL_AVAILABILITY_DELIVERY_DAY.NEXT_DAY, 'nextDayActive')}
            />
          </td>
          <td className={styles.titleRowDaysOut}>
            {/* <NumberInput */}
            {/*  value={availabilities.aggregate.daysOut} */}
            {/*  loading={false} */}
            {/*  disabled={isDisabled} */}
            {/*  labelName='applyAllDaysOut' */}
            {/*  labelText='Apply All Days Out' */}
            {/*  onChange={value => applyAll(value > 1 ? value : null, 'daysOut', '', 'daysOut')} */}
            {/*  min={2} */}
            {/* /> */}
          </td>
          <td>
            {/* <CalendarInput */}
            {/*  value={applyAllNextAvailable} */}
            {/*  minDate={new Date()} */}
            {/*  loading={false} */}
            {/*  disabled={isDisabled} */}
            {/*  labelName='applyAllNextDeliverableDate' */}
            {/*  labelText='Apply All Next Available Service' */}
            {/*  onChange={value => applyAll(value === null ? null : dayjs(value).format('YYYY-MM-DD'), 'nextAvailable', */}
            {/*    '', 'nextAvailableService')} */}
            {/* /> */}
          </td>
          <td>
            <CheckboxInput
              size={'small'}
              checked={availabilities.aggregate.saturdayActive}
              loading={updating === GRAPHQL_AVAILABILITY_DELIVERY_DAY.SATURDAY}
              disabled={isDisabled}
              labelName='applyAllSaturdayActive'
              labelText='Apply All Saturday Active'
              onChange={() => applyAllActive(GRAPHQL_AVAILABILITY_DELIVERY_DAY.SATURDAY, 'saturdayActive')}
            />
          </td>
          <td>
            <CheckboxInput
              size={'small'}
              checked={availabilities.aggregate.sundayActive}
              loading={updating === GRAPHQL_AVAILABILITY_DELIVERY_DAY.SUNDAY}
              disabled={isDisabled}
              labelName='applyAllSundayActive'
              labelText='Apply All Sunday Active'
              onChange={() => applyAllActive(GRAPHQL_AVAILABILITY_DELIVERY_DAY.SUNDAY, 'sundayActive')}
            />
          </td>
        </tr>
      </tbody>
    </>
  )
}

AvailabilityTableHeaders.propTypes = {
  availabilities: availabilitiesProps.isRequired,
  haulerId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  updating: PropTypes.string,
  onUpdating: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired
}
