import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { commaDeliminate } from '../../utilities/stringUtilities'
import { Field } from 'react-final-form'
import styles from './index.module.scss'
import cn from 'classnames'
import FormGroupAdapter from '../form-group-adaptor'
export default function TicketFee ({
  form,
  onDestroy,
  ticketFee,
  value,
  index,
  formatValue,
  updateDiscountAndTax,
  ticketFeeTotal
}) {
  const [showDescription, setshowDescription] = useState(false)

  const updateTicketFeeTotalFromQuantity = (value, ticketFeeIndex, index, form) => {
    const formattedValue = formatValue(value)
    const formState = form.getState()

    form.change(`${ticketFeeIndex}.quantity`, formattedValue)
    if (formState.values.ticketFees[index].amount) {
      form.change(`${ticketFeeIndex}.total`, formattedValue * formState.values.ticketFees[index].amount)
    }
    updateDiscountAndTax(form, ticketFeeTotal)
  }

  const updateTicketFeeTotalFromAmount = (value, ticketFeeIndex, index, form) => {
    const formState = form.getState()
    form.change(`${ticketFeeIndex}.amount`, value)
    if (formState.values.ticketFees[index].quantity) {
      form.change(`${ticketFeeIndex}.total`, value * formState.values.ticketFees[index].quantity)
    }
    updateDiscountAndTax(form, ticketFeeTotal)
  }

  const truncateDescription = (description) => {
    if (!description) {
      return ''
    }
    if (description.length > 100) {
      return description.substring(0, 100) + '...'
    }
    return description
  }

  return (
    <div className={styles.ticketFeeItem}>
      <div className={styles.ticketFeeFormSection}>
        <div className={styles.feeTypeBtnDescription}>
          <button
            type='button'
            className={styles.removeFeeButton}
            onClick={() => onDestroy(form, index, ticketFeeTotal)}>
            <i className={`material-icons ${styles.removeFeeIcon}`}>
              close
            </i>
          </button>
          <span className={styles.feeTypeName}>{value.feeType.name}</span>
        </div>

        <div className={styles.ticketFeeQtyPrice}>
          <label className={`control-label ${styles.feesInput}`} htmlFor={`${ticketFee}.quantity`}>
            Quantity
            <Field
              name={`${ticketFee}.quantity`}
              min={0}
              max={999999.999}
              step='.001'
              type='number'
              component={FormGroupAdapter}
              inputOnChange={(e) => updateTicketFeeTotalFromQuantity(e.currentTarget.value, ticketFee, index, form)}
            />
          </label>
          <label className={`control-label ${styles.feesInput}`} htmlFor={`${ticketFee}.amount`}>
            Price
            <Field
              name={`${ticketFee}.amount`}
              min={0}
              max={999999.99}
              step='0.01'
              type='number'
              component={FormGroupAdapter}
              inputOnChange={(e) => updateTicketFeeTotalFromAmount(e.currentTarget.value, ticketFee, index, form)}
            />
          </label>
          <span className={styles.feeTotal}>
            ${commaDeliminate(value?.total)}
          </span>
        </div>
      </div>

      <div className={cn({
        [styles.ticketFeeDescription]: !showDescription,
        [styles.editingDescription]: showDescription
      })}>
        {value.description?.length > 0 && !showDescription && <div className={styles.ticketFeDescriptionContainer}>
          <span>{truncateDescription(value.description)}</span>
          <button type='button' className={styles.editDescriptionButton} onClick={() => setshowDescription(!showDescription)}>
            Edit
          </button>
        </div>}
        {!value.description && !showDescription && (
          <button type='button' className={styles.displayFormButton} onClick={() => setshowDescription(!showDescription)}>
            + Description
          </button>
        )}
        {showDescription &&
          <>
            <button type='button' className={styles.displayFormButton} onClick={() => setshowDescription(!showDescription)}>
              - Description
            </button>
            <div className={styles.descriptionContainer}>
              <Field name={`${ticketFee}.description`}>
                {({ input }) => (
                  <>
                    <label className='control-label hidden' htmlFor='description'>Description</label>
                    <textarea
                      {...input}
                      type='text'
                      className='form-control'
                      maxLength='4000'
                    />
                  </>
                )}
              </Field>
              <button
                type='button'
                className={styles.clearDescription}
                onClick={() => form.mutators.clearDescription(ticketFee)}>
                Clear
              </button>
            </div>
          </>
        }
      </div>
    </div>
  )
}

TicketFee.propTypes = {
  form: PropTypes.object,
  index: PropTypes.number,
  onDestroy: PropTypes.func.isRequired,
  ticketFee: PropTypes.string,
  value: PropTypes.object,
  formatValue: PropTypes.func.isRequired,
  updateDiscountAndTax: PropTypes.func.isRequired,
  ticketFeeTotal: PropTypes.number
}
