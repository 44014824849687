import { intercomConnection } from '../../intercomConnection'

export default async function destroyClient ({ dataLoaders, variables }) {
  const response = dataLoaders.thumbster.axios.delete(`/hauler/clients/${variables.id}`)
  intercomConnection.trackEvent('client_destroyed', {
    client_id: variables.id,
    client_custom_id: variables.customId,
    client_name: variables.name
  })

  return response.data
}
