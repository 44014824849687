import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class ActivityIcon extends Component {
  constructor (props) {
    super(props)

    this.selectIcon = (key) => {
      const actions = {
        'client.create': 'person_add',
        'client.update': 'change_history',
        'job.create': 'create',
        'job.update': 'change_history',
        'job.resource_added': 'add_circle',
        'job.resource_removed': 'remove_circle',
        'resource.created': 'create',
        'resource.location_changed': 'change_history',
        'resource.removed_from_job': 'remove_circle',
        'resource.removed_from_site': 'remove_circle',
        'resource.updated': 'change_history',
        'resource.change_history': 'change_history',
        'resource.set_at_job': 'add_circle',
        'resource.set_at_site': 'add_circle',
        'ticket.add_comment': 'insert_comment',
        'ticket.add_image': 'add_a_photo',
        'ticket.cancelled': 'cancel',
        'ticket.completed': 'done',
        'ticket.create': 'create',
        'ticket.delete_image': 'exposure',
        'ticket.driver_changed': 'transfer_within_a_station',
        'ticket.flagged': 'flag',
        'ticket.invoiced': 'done',
        'ticket.opened': 'assignment',
        'ticket.rescheduled': 'alarm',
        'ticket.site_added': 'add_circle',
        'ticket.site_removed': 'remove_circle',
        'ticket.started': 'forward',
        'ticket.unflagged': 'flag',
        'ticket.updated': 'change_history'
      }
      return actions[key]
    }
  }

  render () {
    const iconName = this.props.iconName
    const imageName = this.selectIcon(iconName)
    return (
      <i className='material-icons pull-right activity-color'>{imageName}</i>
    )
  }
}

ActivityIcon.propTypes = {
  iconName: PropTypes.string
}
