import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { alertReducers } from './alert/reducers'
import allClientsReducer from '../pages/hauler/clients/reducers/allClientsReducer'
import batchTicketsDeleteReducer from '../pages/hauler/tickets/reducers/batchTicketsDeleteReducer'
import currentHaulerTeamMemberNotificationsReducer from '../pages/hauler/team/reducers/currentHaulerTeamMemberNotificationsReducer'
import currentJobActivitiesReducer from '../pages/hauler/jobs/reducers/currentJobActivitiesReducer'
import currentHaulerTeamMemberReducer from '../pages/hauler/team/reducers/currentHaulerTeamMemberReducer'
import currentJobReducer from '../pages/hauler/jobs/reducers/currentJobReducer'
import currentResourceActivitiesReducer from '../pages/hauler/resources/reducers/currentResourceActivitiesReducer'
import currentSiteTypeReducer from '../pages/hauler/site-types/reducers/currentSiteTypeReducer'
import currentTicketReducer from '../pages/hauler/tickets/reducers/currentTicketReducer'
import haulerTeamMemberActivateReducer from '../pages/hauler/team/reducers/haulerTeamMemberActivateReducer'
import haulerTeamMemberDeactivateReducer from '../pages/hauler/team/reducers/haulerTeamMemberDeactivateReducer'
import haulerTeamReducer from '../pages/hauler/team/reducers/haulerTeamReducer'
import newRequestReducer from './new-requests/reducers/newRequestReducer'
import recentReducer from '../pages/recent/reducers/recentReducer'
import recentTicketsReducer from '../pages/recent/reducers/recentTicketsReducer'
import siteTypesReducer from '../pages/hauler/site-types/reducers/siteTypesReducer'
import siteTypesCreateReducer from '../pages/hauler/site-types/reducers/siteTypesCreateReducer'
import siteTypesDisableReducer from '../pages/hauler/site-types/reducers/siteTypesDisableReducer'
import siteTypesEnableReducer from '../pages/hauler/site-types/reducers/siteTypesEnableReducer'
import ticketAssignmentReducer from '../pages/hauler/tickets/reducers/ticketAssignmentReducer'
import ticketTypesReducer from '../pages/hauler/ticket-types/reducers/ticketTypesReducer'
import reportsReducer from '../pages/reports/reducers/reportsReducer'
import userReducer from './user/userReducer'
import pusherReducer from './pusher/pusherReducers'
import dataLoadersReducer from './data-loaders/dataLoadersReducer'

export const appReducer = combineReducers({
  form: formReducer,
  alert: alertReducers,
  allClients: allClientsReducer,
  batchTicketsDelete: batchTicketsDeleteReducer,
  currentHaulerTeamMember: currentHaulerTeamMemberReducer,
  currentHaulerTeamMemberNotifications: currentHaulerTeamMemberNotificationsReducer,
  currentJob: currentJobReducer,
  currentJobActivities: currentJobActivitiesReducer,
  currentResourceActivities: currentResourceActivitiesReducer,
  currentSiteType: currentSiteTypeReducer,
  currentTicket: currentTicketReducer,
  haulerTeam: haulerTeamReducer,
  haulerTeamMemberActivate: haulerTeamMemberActivateReducer,
  haulerTeamMemberDeactivate: haulerTeamMemberDeactivateReducer,
  newRequests: newRequestReducer,
  recent: recentReducer,
  recentTickets: recentTicketsReducer,
  reports: reportsReducer,
  siteTypes: siteTypesReducer,
  siteTypesCreate: siteTypesCreateReducer,
  siteTypesDisable: siteTypesDisableReducer,
  siteTypesEnable: siteTypesEnableReducer,
  ticketAssignment: ticketAssignmentReducer,
  ticketTypes: ticketTypesReducer,

  // Put new reducers below here! The goal is to faze out most if not all other global state that was pre Dispatcher.
  // Thumbster used global state for literally everything which is not a practice we are going to continue to follow.
  user: userReducer,
  dataLoaders: dataLoadersReducer,
  pusher: pusherReducer
})

export const rootReducer = (state, action) => {
  return appReducer(state, action)
}
