import PropTypes from 'prop-types'
import React from 'react'
import cn from 'classnames'
import classNamePropTypes from '../utilities/classNamePropTypes'

export function TextareaInput ({
  input,
  label,
  placeholder,
  maxLength,
  meta: {
    touched,
    error
  },
  disabled = false,
  className,
  textAreaClassName
}) {
  const formGroupClasses = [className, 'form-group']
  if (touched && error) {
    formGroupClasses.push('has-error')
  }
  return (
    <div className={cn(formGroupClasses)}>
      {label && <label className='control-label'>{label}</label>}
      <textarea
        disabled={disabled}
        className={cn(textAreaClassName, 'form-control')}
        autoComplete='off'
        placeholder={placeholder}
        maxLength={maxLength}
        {...input}
      />
      {(touched && error) && <h5>{error}</h5>}
    </div>
  )
}

TextareaInput.propTypes = {
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  maxLength: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  }),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  className: classNamePropTypes,
  textAreaClassName: classNamePropTypes
}
