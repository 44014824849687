import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ObjectParam, StringParam, NumberParam, useQueryParams } from 'use-query-params'
import ticketSearchQuery from '../../graphql/queries/ticketsSearch'
import QUERY_KEYS from '../../graphql/queryKeys'
import { action } from '../../store/store'
import dayjs from '../../utilities/dayjs'
import styles from './index.module.scss'
import cn from 'classnames'
import {
  REQUEST_TICKET_TYPES_ACTION,
  CLEAR_TICKET_TYPES_ERRORS
} from '../hauler/ticket-types/sagas/ticketTypesSaga'
import { REQUEST_ALL_CLIENTS_ACTION } from '../hauler/clients/sagas/allClientsSaga'
import { REQUEST_HAULER_TEAM_ACTION } from '../hauler/team/sagas/haulerTeamSaga'
import { REQUEST_BATCH_TICKETS_DELETE_ACTION } from '../hauler/tickets/sagas/batchTicketsDeleteSaga'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import { TICKET_BILLING_STATUSES } from '../../utilities/constants'
import { PageTitle } from '../../components/page-title'
import Error from '../shared/Error'
import { Spinner } from '../shared/Spinner'
import TicketsTableSearchForm from '../../components/tickets-search/TicketsTableSearchForm'
import TicketsTable from '../../components/tickets-search/TicketsTable'
import notify from '../../utilities/notify'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import { ticketsSortColumnEnumMap } from '../../graphql/enums'
import { ROUTES } from '../../routes'
import useQuery from '../../hooks/useQuery'
import useMutation from '../../hooks/useMutation'
import exportTickets from '../../graphql/mutations/exportTickets'
import resourceTypesQuery from '../../graphql/queries/resourceTypes'

const pageSize = 25
const yesterday = dayjs().add(-1, 'day').format('YYYY-MM-DD')

export default function TicketsSearchPage () {
  const history = useHistory()
  const [fakeCount, setFakeCount] = useState(null)
  const [ticketsExporting, setTicketsExporting] = useState(false)
  const { allClients, ticketTypes, haulerTeam, user } = useSelector((
    {
      allClients,
      ticketTypes,
      haulerTeam,
      user: { user }
    }
  ) => ({
    allClients,
    ticketTypes,
    haulerTeam,
    user
  }))

  const [queryParams, setQueryParams] = useQueryParams({
    sort: ObjectParam,
    pager: ObjectParam,
    clientId: NumberParam,
    customId: StringParam,
    dispatchSearch: StringParam,
    driverId: StringParam,
    flagged: StringParam,
    hasActiveException: StringParam,
    jobCustomId: StringParam,
    maxDate: StringParam,
    minDate: StringParam,
    resourceTypeId: StringParam,
    status: StringParam,
    ticketTypeId: StringParam,
    billingStatus: StringParam,
    city: StringParam,
    zipCode: StringParam,
    weightTicketNumber: StringParam
  })

  const searchParams = {
    clientId: queryParams.clientId,
    customId: queryParams.customId,
    dispatchSearch: queryParams.dispatchSearch,
    driverId: queryParams.driverId,
    flagged: queryParams.flagged,
    hasActiveException: queryParams.hasActiveException,
    jobCustomId: queryParams.jobCustomId,
    maxDate: queryParams.maxDate,
    minDate: queryParams.minDate,
    resourceTypeId: queryParams.resourceTypeId,
    status: queryParams.status,
    ticketTypeId: queryParams.ticketTypeId,
    billingStatus: queryParams.billingStatus,
    city: queryParams.city,
    zipCode: queryParams.zipCode,
    weightTicketNumber: queryParams.weightTicketNumber
  }

  const { data, isFetching } = useQuery([
    QUERY_KEYS.ticketsSearch,
    user.haulerId,
    25,
    queryParams.sort,
    Object.fromEntries(Object.entries(searchParams).filter(([_, v]) => v)),
    queryParams.pager
  ],
  ticketSearchQuery,
  {
    enabled: Boolean(queryParams.sort),
    onError () {
      notify('error', 'Error fetching tickets')
    }
  })

  const { data: overdueTickets } = useQuery([
    QUERY_KEYS.ticketsSearch,
    user.haulerId,
    25,
    { column: 'date', direction: 'desc' },
    { status: 'open', maxDate: yesterday }
  ],
  ticketSearchQuery,
  {
    enabled: Boolean(user.haulerId),
    onError () {
      notify('error', 'Error fetching overdue tickets')
    }
  })

  const { data: { resourceTypes = [] } = {}, isFetching: isFetchingResourceTypes } = useQuery(
    [QUERY_KEYS.resourceTypes, { haulerId: user.haulerId, status: 'ENABLED' }],
    resourceTypesQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Failed to retrieve resource types')
      }
    }
  )

  const { mutate: exportTicketsMutation } = useMutation(exportTickets, {
    onSuccess (data) {
      const email = data?.userEmail
      window.alert(`Export Complete!  The export will be emailed to ${email} with a link to download.`)
    },
    onError () {
      notify('error', 'Error exporting tickets')
    },
    onSettled () {
      setTicketsExporting(false)
    }
  })

  function handleExportTickets () {
    if (ticketsExporting) {
      return
    }
    setTicketsExporting(true)
    return exportTicketsMutation({
      haulerId: user.haulerId,
      searchFields: searchParams,
      sortColumn: ticketsSortColumnEnumMap[queryParams.sort.column],
      sortDirection: queryParams.sort.direction.toUpperCase()
    })
  }

  function onPageRequest (direction, cursor) {
    setQueryParams({ pager: { cursor, direction, pageSize } })

    if (!fakeCount) return
    if (direction === 'before') {
      setFakeCount(prevFakeCount => ({ start: prevFakeCount.start - pageSize, end: prevFakeCount.end - pageSize }))
    }
    if (direction === 'after') {
      setFakeCount(prevFakeCount => ({ start: prevFakeCount.start + pageSize, end: prevFakeCount.end + pageSize }))
    }
  }

  function onSortChange (newSortColumn) {
    let newSortDirection = 'desc'
    if (newSortColumn === queryParams.sort.column) {
      newSortDirection = queryParams.sort.direction === 'asc' ? 'desc' : 'asc'
    }

    setFakeCount(null)
    setQueryParams({
      sort: { column: newSortColumn, direction: newSortDirection }
    })
  }

  const clearFormValues = () => {
    setFakeCount(null)
    setQueryParams({}, 'replace')
  }

  const handleTicketsSearch = (values) => {
    setFakeCount(null)
    setQueryParams({ ...nonEmptyValues(values), sort: queryParams.sort }, 'replace')
  }

  const viewOverdueTickets = () => {
    setQueryParams({ column: 'date', direction: 'desc', status: 'open', maxDate: yesterday })
  }

  const onBatchDeleteCallback = function * onBatchDeleteCallback () {
    yield setQueryParams({ column: 'date', direction: 'desc', status: 'open', maxDate: yesterday })
  }

  const onBatchDelete = (ids) => {
    // eslint-disable-next-line
    const confirmed = confirm(`Are you sure you want to delete ${ids.length} tickets? This CANNOT BE UNDONE.`);
    if (confirmed) {
      action(REQUEST_BATCH_TICKETS_DELETE_ACTION, {
        ids: ids.join(','),
        cb: onBatchDeleteCallback
      })
    }
  }

  const onBatchEdit = (ids) => {
    history.push(`${ROUTES.bulkEditTicketsPage}?ids=${ids.join(',')}`)
  }

  useEffect(function onInitialRender () {
    action(REQUEST_ALL_CLIENTS_ACTION)
    action(REQUEST_HAULER_TEAM_ACTION)
    action(REQUEST_TICKET_TYPES_ACTION, {})
  }, [user])

  useEffect(function setDefaultQueryParams () {
    const defaultQueryParams = {}
    if (!queryParams.sort) {
      defaultQueryParams.sort = { column: 'date', direction: 'desc' }
    }
    setQueryParams(defaultQueryParams, 'replaceIn')
  }, [queryParams, setQueryParams])

  useEffect(function toggleFakePagination () {
    if (!data || isFetching || fakeCount !== null) return
    setFakeCount({ start: 1, end: Math.min(pageSize, data?.ticketsSearch.totalCount) })
  }, [data, isFetching, fakeCount, setFakeCount])

  const fetching = allClients.isFetching || ticketTypes.isFetching || isFetchingResourceTypes || haulerTeam.isFetching

  return (
    <div>
      <NormalLayoutContainer>
        <div className='container-fluid'>
          <div className={styles.titleContainer}>
            <PageTitle title='Search Tickets' />
            {overdueTickets?.ticketsSearch?.totalCount > 0 && <button
              onClick={viewOverdueTickets}
              className={cn('btn btn-primary-lt', styles.overdueTicketBtn)}>
              Overdue Tickets
            </button>}
          </div>

          <Spinner isFetching={fetching}>
            <div className='row'>
              <div className='panel panel-default'>
                <div className='panel-body'>
                  <TicketsTableSearchForm
                    clients={allClients.clients}
                    ticketTypes={ticketTypes.ticketTypesArray}
                    resourceTypes={resourceTypes}
                    billingStatuses={TICKET_BILLING_STATUSES}
                    team={haulerTeam.team}
                    handleSubmit={handleTicketsSearch}
                    clearFormValues={clearFormValues}
                    initialFormVals={searchParams}
                    handleExportTickets={handleExportTickets}
                    ticketsExporting={ticketsExporting}
                  />
                </div>
              </div>
            </div>
          </Spinner>

          <Spinner isFetching={isFetching}>
            <div className='row push-down-super-small'>
              <div className='panel panel-default'>
                <div className='panel-body'>
                  {data?.ticketsSearch && queryParams.sort &&
                    <TicketsTable
                      tickets={data?.ticketsSearch}
                      sort={queryParams.sort}
                      onPageRequest={onPageRequest}
                      onSortChange={onSortChange}
                      fakeCount={fakeCount}
                      onBatchDelete={onBatchDelete}
                      onBatchEdit={onBatchEdit}
                    />}
                </div>
              </div>
            </div>
          </Spinner>

        </div>
      </NormalLayoutContainer>

      <Error errors={ticketTypes.errors} clearErrors={() => action(CLEAR_TICKET_TYPES_ERRORS, {})} />
    </div>
  )
}

function nonEmptyValues (values) {
  return Object.keys(values).reduce((acc, key) => {
    const value = values[key]
    if (value === '' || value == null) return acc
    return { ...acc, [key]: value }
  }, {})
}
