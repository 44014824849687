import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import cn from 'classnames'
import styles from './index.module.scss'
import FormGroupAdapter from '../form-group-adaptor'
import ReactTooltip from 'react-tooltip'

export default function DiscountAndTaxFormFields ({
  remove,
  handleOnChangeAmount,
  handleOnChangePercent,
  handleOnChangeTaxCode,
  ticketFeeTotal,
  type,
  form,
  show,
  isConnectedToQB,
  usingAST,
  taxCodes = []
}) {
  const qboTax = isConnectedToQB && !usingAST && type === 'tax'

  return (
    <div className={cn(show ? styles.discountTaxFormContainer : styles.discountTaxFormContainerNoMargin)}>
      <>
        {show && <button onClick={remove} type='button'>
          <i
            className={cn('material-icons', styles.removeDiscountTaxIcon, {
              [styles.qboRemoveTax]: qboTax
            })}>
            close
          </i>
        </button>}
        <Field
          name='discountAndTax.qboTaxCodeId'>
          {({ input, meta }) => (
            show && qboTax && (<div className={cn('form-group', styles.taxCodeSelect, styles.feesInput)}>
              <ReactTooltip className='text-center' html={true} id='non-ast-tooltip' />
              <label htmlFor='discountAndTax.qboTaxCodeId' className='control-label'>
                Tax Rate
                <i
                  className={cn('material-icons', styles.taxCodeToolTip, styles.taxCodeToolTipNonAST)}
                  data-tip='This dropdown contains the tax rates you have setup in QuickBooks.
                  <br/> Please select the correct tax rate to use for the location of this job.'
                  data-for='non-ast-tooltip'>
                  help
                </i>
              </label>
              <select
                {...input}
                className='form-control'
                onChange={(e) => {
                  input.onChange(e)
                  handleOnChangeTaxCode && handleOnChangeTaxCode(e.currentTarget.value, ticketFeeTotal, form, taxCodes)
                }}>
                <option key='placeholder' value=''></option>
                {taxCodes.map((tr) =>
                  (
                    <option
                      value={tr.id}
                      key={tr.id}>
                      {tr.name}
                    </option>
                  )
                )}
              </select>
              {meta.error && <span className={styles.errorText}>{meta.error}</span>}
            </div>)
          )}
        </Field>
        {!qboTax && show &&
          <div className={styles.astToolTipContainer}>
            <div className={cn(styles.discountTaxFormTitle, styles.discountTitleQB)}>{type}</div>
            <ReactTooltip className='text-center' html={true} id='ast-tooltip' />
            {usingAST && <i
              className={cn('material-icons', styles.taxCodeToolTip, styles.taxCodeToolTipAST)}
              data-tip='Enter the correct tax rate to use for the location of this job.
              <br/> Please note that when synced to QuickBooks this tax amount will be attempted to apply to the correct tax agency.
              <br/> Please check to ensure its accuracy in QuickBooks.'
              data-for='ast-tooltip'>
              help
            </i>}
          </div>
        }
        <div className={cn(styles.discountAndTaxInput, styles.feesInput, {
          [styles.qboTaxInput]: qboTax && show
        })}>
          {show && <>
            <label className='control-label hidden' htmlFor={`${type}Percent`}>Percent</label>
            <div className={styles.discountPercent}>%</div>
          </>}
          <Field
            name={`discountAndTax.${type}Percent`}
            disabled={qboTax && !usingAST}
            min={0}
            max={99.999}
            type='number'
            className={styles.disabledTax}
            placeholder='0.0'
            step='.001'
            show={show}
            component={FormGroupAdapter}
            inputOnChange={(e) => handleOnChangePercent(e.currentTarget.value, ticketFeeTotal, form)}
          />
        </div>
        <div className={cn(styles.discountAndTaxInput, styles.feesInput, {
          [styles.qboTaxInput]: qboTax && show
        })}>
          {show && <>
            <label className='control-label hidden' htmlFor={`${type}Amount`}>Dollar Amount</label>
            <div className={styles.discountPercent}>$</div>
          </>}
          <Field
            name={`discountAndTax.${type}Amount`}
            min={0}
            max={999999.99}
            type='number'
            placeholder='0.0'
            step='0.01'
            show={show}
            component={FormGroupAdapter}
            inputOnChange={(e) => handleOnChangeAmount(e.currentTarget.value, ticketFeeTotal, form, isConnectedToQB, usingAST)}
          />
        </div>
      </>
    </div>
  )
}

DiscountAndTaxFormFields.propTypes = {
  remove: PropTypes.func.isRequired,
  handleOnChangeAmount: PropTypes.func.isRequired,
  handleOnChangePercent: PropTypes.func.isRequired,
  handleOnChangeTaxCode: PropTypes.func,
  ticketFeeTotal: PropTypes.number,
  type: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  isConnectedToQB: PropTypes.bool,
  usingAST: PropTypes.bool,
  taxCodes: PropTypes.array
}
