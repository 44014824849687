import React from 'react'
import PropTypes from 'prop-types'
import { underscoreToCapital } from '../../../../../../utilities/stringUtilities'

export default function HaulerTeamMemberDisplay (props) {
  const {
    team_member: {
      first_name: firstName,
      last_name: lastName,
      email,
      pretty_primary_phone: prettyPrimaryPhone,
      role,
      track_location: trackLocation,
      deleted_at: deletedAt
    }
  } = props

  return (
    <div className='row'>
      <div className='row'>
        <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
          <ul className='list-group'>
            <div className='list-group-item'>
              <i className='material-icons large-icon pull-right'>touch_app</i>
              <div className='list-group-item-heading'>Status</div>
              <div className='list-group-item-text'>{deletedAt ? 'Inactive' : 'Active'}</div>
            </div>
            <div className='list-group-item'>
              <i className='material-icons large-icon pull-right'>verified_user</i>
              <div className='list-group-item-heading'>Role</div>
              <div className='list-group-item-text'>{underscoreToCapital(role)}</div>
            </div>
            <div className='list-group-item'>
              <i className='material-icons large-icon pull-right'>location_on</i>
              <div className='list-group-item-heading'>Show User Icon</div>
              <div className='list-group-item-text'>{trackLocation ? 'Yes' : 'No'}</div>
            </div>
          </ul>
        </div>
        <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
          <div className='list-group-item'>
            <i className='material-icons large-icon pull-right'>person</i>
            <div className='list-group-item-heading'>First Name</div>
            <div className='list-group-item-text'>{firstName}</div>
          </div>

          {/* Last Name */}
          <div className='list-group-item'>
            <i className='material-icons large-icon pull-right'>person</i>
            <div className='list-group-item-heading'>Last Name</div>
            <div className='list-group-item-text'>{lastName}</div>
          </div>

          {/* Email */}
          <div className='list-group-item'>
            <i className='material-icons large-icon pull-right'>email</i>
            <div className='list-group-item-heading'>Email</div>
            <div className='list-group-item-text'>{email}</div>
          </div>

          {/* Phone */}
          <div className='list-group-item'>
            <i className='material-icons large-icon pull-right'>phone</i>
            <div className='list-group-item-heading'>Phone</div>
            <div className='list-group-item-text'>{prettyPrimaryPhone}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

HaulerTeamMemberDisplay.propTypes = {
  team_member: PropTypes.any.isRequired
}
