import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

export class TicketFlag extends Component {
  static iconClasses (flagged) {
    let classes = 'material-icons'
    if (flagged) {
      classes += ' text-danger'
    }
    return classes
  }

  render () {
    const { flagged, linkPath } = this.props
    const classes = TicketFlag.iconClasses(flagged)
    const flag = <i className={classes}>flag</i>
    if (linkPath) {
      return <Link to={linkPath}>{flag}</Link>
    }
    return flag
  }
}

TicketFlag.propTypes = {
  flagged: PropTypes.bool.isRequired,
  linkPath: PropTypes.string
}
