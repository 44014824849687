import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'

export default function FormGroupAdapter ({
  input,
  inputOnChange,
  show = true,
  disabled = false,
  className,
  step = 1,
  min = 0,
  max = 999999.99
}) {
  if (input.type === 'number') {
    input = { ...input, ...{ step, min, max } }
  }
  const inputProps = {
    ...input,
    onChange: e => {
      input.onChange(e)
      inputOnChange && inputOnChange(e)
    }
  }
  return show
    ? (
      <>
        {input?.type === 'textArea'
          ? (<textarea disabled={disabled} className='form-control' {...inputProps} />)
          : (<input disabled={disabled} className={cn(className, input?.type === 'checkbox' ? '' : 'form-control')} {...inputProps} />)}
      </>
      )
    : null
}

FormGroupAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  inputOnChange: PropTypes.func,
  show: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.string
}
