import React from 'react'
import PropTypes from 'prop-types'
import { Link, generatePath } from 'react-router-dom'
import { ROUTES } from '../../routes'
import styles from './index.module.scss'
import QBOExportStatus from '../qbo-wastepay-export/QBOExportStatus'
import ReceiptActions from '../receipt-actions'
import ConfirmationTransactionTable from './ConfirmationTransactionTable'
import { commaDeliminate } from '../../utilities/stringUtilities'

export default function TransactionConfirmation ({
  invoice,
  retryQboExport,
  transactions,
  receiptEmails,
  provider
}) {
  const latestTransaction = transactions?.[0]
  return (
    <>
      <div className='dis-panel'>
        <div className={styles.panelBody}>
          <div className={styles.paymentDetails}>
            <h3 className={styles.paymentSuccess}>{latestTransaction.transactionType} Successful</h3>
            <h4 className={styles.paymentTotal}>${commaDeliminate(latestTransaction.amount)}</h4>
            <QBOExportStatus
              invoice={invoice}
              retry={retryQboExport}
              transactions={transactions}
            />
          </div>
          <hr className={styles.tableSeparator} />
          <ConfirmationTransactionTable invoice={invoice} transactions={transactions} />
          <div className={styles.receiptContainer}>
            {receiptEmails != null && <p className={styles.emails}>A receipt has been sent for the most recent {(latestTransaction.transactionType).toLowerCase()} to {receiptEmails.join(', ')}.</p>}
            <div className={styles.receiptBtnContainer}>
              <ReceiptActions
                invoiceId={invoice.id}
                transactionId={latestTransaction.id}
                client={invoice.client}
                receiptProvider={provider}
                transactionType={latestTransaction.transactionType.toLowerCase() === 'payment' ? 'SALE' : 'REFUND'}
                buttons={[
                  {
                    action: 'email',
                    text: receiptEmails ? 'Send Another Receipt' : 'Send Receipt',
                    icon: true
                  },
                  {
                    action: 'print',
                    text: 'Print/Save Receipt',
                    icon: true
                  }
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.backToTicketBtnContainer}>
        <BackToInvoiceButton invoiceId={invoice.id} />
      </div>
    </>
  )
}

TransactionConfirmation.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    tickets: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string
    })),
    client: PropTypes.object.isRequired,
    qboUrl: PropTypes.string,
    qboInvoiceExportStatus: PropTypes.oneOf(['SUCCESS', 'ERROR']),
    dispatcherPayments: PropTypes.arrayOf(PropTypes.shape({
      qboPaymentExportStatus: PropTypes.oneOf(['SUCCESS', 'ERROR']),
      qboPaymentUrl: PropTypes.string
    }))
  }).isRequired,
  retryQboExport: PropTypes.func.isRequired,
  transactions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    transactionType: PropTypes.string,
    qboLink: PropTypes.string,
    qboExportStatus: PropTypes.oneOf(['SUCCESS', 'ERROR'])
  })).isRequired,
  receiptEmails: PropTypes.arrayOf(PropTypes.string),
  provider: PropTypes.string.isRequired
}

function BackToInvoiceButton ({ invoiceId }) {
  return (
    <div className={styles.backToTicketBtnContainer}>
      <Link to={generatePath(ROUTES.invoice, { id: invoiceId })} className='dis-btn dis-btn-sm dis-btn-gray'>
        Back To Invoice
      </Link>
    </div>
  )
}

BackToInvoiceButton.propTypes = {
  invoiceId: PropTypes.string.isRequired
}
