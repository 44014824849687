import React from 'react'
import { useSelector } from 'react-redux'
import { provinces, states } from '../../utilities/locationUtilities'
import { Field } from 'react-final-form'

const dividerOption = { divider: '---------------------------------------' }

export default function StateSelect () {
  const { hauler } = useSelector(({ user: { hauler } }) => ({ hauler }))

  function determineOptions () {
    if (hauler.country === 'CA') {
      return { ...provinces, ...dividerOption, ...states }
    }
    return { ...states, ...dividerOption, ...provinces }
  }

  return (
    <Field name='state'>
      {({ input }) => (
        <div>
          <label className='control-label' htmlFor='state'>State</label>
          <select
            className='form-control'
            {...input}>
            <option key='placeholder' value=''></option>
            {Object.entries(determineOptions()).map(([key, value], index) => (<option
              disabled={key === 'divider'}
              key={key}
              value={key}>
              {key === 'divider' ? value : `${value} (${key})`}
            </option>))
            }
          </select>
        </div>
      )}
    </Field>
  )
}
