import React from 'react'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import classNames from 'classnames'
import styles from './ticket-sites.module.scss'
import RenderAutoSuggestField from '../../../pages/shared/formRenderers/RenderAutoSuggestField'
import { commaDeliminate, truncateText } from '../../../utilities/stringUtilities'

export default function TicketSiteCreateForm ({ onSubmit, sites, disabled = false }) {
  function handleSubmit (data, form) {
    form.reset()
    return onSubmit(data)
  }

  function sitesRenderizer (sites) {
    return sites.map(site => {
      let siteName = site.name

      if (site.cost) {
        siteName = `${truncateText(siteName, 25)} - $${commaDeliminate(site.cost)}`
      }
      return {
        id: site.id,
        value: site.name,
        name: siteName
      }
    })
  }

  return (
    <Form onSubmit={handleSubmit} validate={validate}>
      {({ handleSubmit, pristine, invalid, submitting }) => (
        <form className={classNames(styles.ticketSitesForm)} onSubmit={handleSubmit}>
          <Field
            label='Select a Site'
            name='siteId'
            options={sites}
            labelKey='name'
            fieldClass={classNames(styles.selectSiteDropdown)}
            field='id'
            component={RenderAutoSuggestField}
            renderFn={sitesRenderizer}
          />

          <button
            type='submit'
            className={classNames('dis-btn dis-btn-sm dis-btn-success', styles.ticketSitesButton)}
            disabled={pristine || invalid || submitting || disabled}>
            Add
          </button>
        </form>
      )}
    </Form>
  )
}

TicketSiteCreateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  sites: PropTypes.array.isRequired,
  disabled: PropTypes.bool
}

function validate (values) {
  const errors = {}

  if (!values.siteId) {
    errors.siteId = 'Required'
  }

  return errors
}
