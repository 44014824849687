import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Switch from './Switch'
import DriverListSortableList from './DriverListSortableList'
import sortFirstThenLastName from '../utilities/sortUtilities'

export default function DriverListSortContainer ({ users, onClose, onSave }) {
  const [viewHidden, setViewHidden] = useState(false)
  const [userList, setUserList] = useState([...users].sort((a, b) => a.isHidden - b.isHidden))

  function sortAlphabetically () {
    setUserList([...userList].sort(sortFirstThenLastName).sort((a, b) => a.isHidden - b.isHidden))
  }

  return (
    <div className='driver-sort bg-gray-light p-8 flex flex-row flex-wrap font-medium'>
      <div className='driver-sort_title w-full'>Driver List Options</div>
      <div className='w-full flex justify-between my-2'>
        <button
          onClick={sortAlphabetically}
          className='uppercase hover:bg-gray-light-10-dark py-1 px-2 -mx-2'>
          Sort A - Z
        </button>
        <div className='flex flex-row flex-nowrap'>
          <div className='relative top-1'>
            <Switch
              size='small'
              checked={viewHidden}
              onChange={() => setViewHidden(!viewHidden)}
            />
          </div>
          <div className='ml-4'>View Hidden</div>
        </div>
      </div>
      <div className='w-full flex justify-end'>
        <div>Hide From Dispatch</div>
      </div>
      <div className='w-full flex justify-center'>
        <DriverListSortableList
          userList={userList}
          setUserList={setUserList}
          viewHidden={viewHidden}
        />
      </div>
      <div className='w-full flex justify-end'>
        <button
          className='uppercase hover:bg-grey-10-dark px-3 py-1 hover:bg-gray-light-10-dark'
          onClick={() => onClose()}>
          Close
        </button>
        <button
          className='uppercase bg-green hover:bg-green-10-dark rounded shadow py-1 px-3 ml-2'
          onClick={() => {
            onClose()
            onSave(userList.map((user, index) => ({
              isHidden: user.isHidden,
              userId: user.id,
              order: index
            })))
          }}>
          <span>Save</span>
          <i className='material-icons pull-right relative -top-1 ml-2'>check</i>
        </button>
      </div>
    </div>
  )
}

DriverListSortContainer.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    isHidden: PropTypes.bool.isRequired
  })).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}
