export const validate = (values) => {
  const errors = {}

  /* First Name */
  if (!values.siteTypeId) {
    errors.site_type_id = 'Required'
  }

  if (!values.name) {
    errors.name = 'Required'
  } else if (values.name.length > 30) {
    errors.name = 'Site Name must be 30 characters or fewer.'
  }

  if (values.note && values.note.length > 250) {
    errors.note = 'Site Notes must be less than 250 characters'
  }

  if (!values.latitude) {
    errors.latitude = 'Required'
  }

  if (values.addressline1 && !values.addressline1.trim().length) {
    errors.addressline1 = 'Required'
  }

  return errors
}
