import React from 'react'
import PropTypes from 'prop-types'

export default function DestroyTicketButton ({ onClick, ticket: { id } }) {
  return (
    <button
      className='dis-btn dis-btn-blank dis-btn-sm'
      onClick={() => {
      // eslint-disable-next-line no-restricted-globals
        const result = confirm(
          'THIS CANNOT BE UNDONE! Deleting a ticket will remove any record ' +
        'that it existed including its images.')
        if (result) {
          onClick(id)
        }
      }}>
      Destroy Ticket
      <i className='material-icons dis-btn-icon'>delete</i>
    </button>
  )
}

DestroyTicketButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  ticket: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired
}
