import React from 'react'
import PropTypes from 'prop-types'
export default function NewRequestButtons ({
  index,
  request,
  toggleDeclineForm,
  isRequestBeforeToday,
  handleConfirm,
  toggleDetails
}) {
  return (
    <div className='flex flex-row ml-8 mt-2'>
      {!isRequestBeforeToday &&
        <button
          onClick={() => {
            handleConfirm(index, request.tickets.map(ticket => ticket.id), true)
            toggleDetails(false)
          }}
          className='button button_primary mr-4 py-1'>
          {`Confirm ${request.tickets.length > 1 ? `(${request.tickets.length})` : ''}`}
        </button>}
      <button
        className='button button_white py-1'
        onClick={() => toggleDeclineForm(true)}>
        Unable To Confirm
      </button>
    </div>
  )
}

NewRequestButtons.propTypes = {
  index: PropTypes.number.isRequired,
  request: PropTypes.object.isRequired,
  toggleDeclineForm: PropTypes.func.isRequired,
  isRequestBeforeToday: PropTypes.bool.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  toggleDetails: PropTypes.func.isRequired
}
