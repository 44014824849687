import React from 'react'
import cn from 'classnames'
import styles from './index.module.scss'
import PropTypes from 'prop-types'

export default function CardImage ({
  type,
  size
}) {
  const determineImageSrc = () => {
    switch (type) {
      case 'AMEX':
        return 'cc-amex-115'
      case 'DISCOVER':
        return 'cc-discover-115'
      case 'MASTERCARD':
        return 'cc-master-115'
      case 'VISA':
        return 'cc-visa-115'
      default:
        return ''
    }
  }
  return (
    <img className={cn({ [styles.cardLogoSmall]: size === 'small' })}
      src={`https://www.dispatcher.com/images/${determineImageSrc()}.png`}
      alt={`${type.toLowerCase()} logo`}
    />
  )
}

CardImage.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired
}
