import { loader } from 'graphql.macro'

export default function activitiesPages ({ dataLoaders, queryKey }) {
  const [, variables] = queryKey
  const assetSchema = loader('./activitiesPages.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(assetSchema, {
    entity: variables.entity,
    id: variables.id,
    page: variables.page
  })
}
