import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import CardImage from '../cc-card-image'
import styles from './index.module.scss'

export default function CCListItem ({
  card,
  isFinalCard,
  handleSetPrimaryCard,
  isUpdating,
  handleSelectForRemoval,
  handleSetNickname
}) {
  const [showEditNickname, setShowEditNickname] = useState(false)

  async function handleNicknameSubmit (e) {
    e.preventDefault()
    const form = e.target
    try {
      await handleSetNickname(card.id, form.elements.nickname.value)
    } finally {
      setShowEditNickname(false)
    }
  }

  return (
    <div key={card.id} className={cn(styles.manageCardListItem, {
      [styles.lastManageCardListItem]: isFinalCard
    })}>
      <CardImage type={card.cardBrand} size='small' />
      <div className={styles.manageCardListContainer}>
        { showEditNickname
          ? (
            <form onSubmit={handleNicknameSubmit} className={cn(styles.nicknameWrapper)}>
              <label>
                Edit nickname for card {card.cardNumber} | {`Exp. ${card.cardExpirationMonth.toString().padStart(2, '0')}/${card.cardExpirationYear.toString().slice(-2)}`}
                <input
                  type='text'
                  name='nickname'
                  maxLength={30}
                  defaultValue={card.nickname}
                  className='form-control'
                />
              </label>
              <div className={cn(styles.nicknameButtonWrapper)}>
                <button
                  disabled={isUpdating}
                  type='submit'
                  className={cn('dis-btn dis-btn-sm dis-btn-primary')}>
                  Submit
                </button>
                <button
                  disabled={isUpdating}
                  type='button'
                  className={cn('dis-btn dis-btn-blank dis-btn-sm')}
                  onClick={() => setShowEditNickname(false)}>
                  Cancel
                </button>
              </div>
            </form>
            )
          : (
            <>
              <div className={cn(styles.manageCardListActions)}>
                <div>
                  {card.cardNumber}
                  <span className={styles.cardDetailDivider}>|</span>
                  {`Exp. ${card.cardExpirationMonth.toString().padStart(2, '0')}/${card.cardExpirationYear.toString().slice(-2)}`}
                </div>
                {card.nickname && (
                  <div>
                    Nickname: {card.nickname}
                  </div>
                )}
              </div>
              <div className={styles.manageCardListActions}>
                {card.isPrimary
                  ? <div className={styles.defaultCardBadge}>Default Card</div>
                  : (
                    <button
                      disabled={isUpdating}
                      onClick={() => handleSetPrimaryCard(card)}
                      className='dis-btn dis-btn-link'>
                      Make Default
                    </button>
                    )}
                <button
                  onClick={() => setShowEditNickname(true)}
                  className={cn('dis-btn dis-btn-link')}>
                  Add/Edit Nickname
                </button>
                <button
                  disabled={isUpdating}
                  onClick={() => handleSelectForRemoval(card)}
                  className={cn(styles.removeCardBtn, 'dis-btn dis-btn-link')}>
                  Delete Card
                </button>
              </div>
            </>
            )}
      </div>
    </div>
  )
}

CCListItem.propTypes = {
  isFinalCard: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  handleSetPrimaryCard: PropTypes.func.isRequired,
  handleSelectForRemoval: PropTypes.func.isRequired,
  handleSetNickname: PropTypes.func.isRequired,
  card: PropTypes.shape(PropTypes.shape({
    id: PropTypes.string.isRequired,
    cardBrand: PropTypes.string.isRequired,
    cardExpirationMonth: PropTypes.number.isRequired,
    cardExpirationYear: PropTypes.number.isRequired,
    nickname: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]).isRequired,
    isPrimary: PropTypes.bool.isRequired
  })).isRequired
}
