import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
// import ReactTooltip from 'react-tooltip'
export default function NewRequestTicket ({
  ticket
}) {
  function removeStyleTags (attr) {
    if (attr) {
      return attr.replaceAll(/style="[^"]*"/gi, '')
    }
    return null
  }
  const utc = require('dayjs/plugin/utc')
  dayjs.extend(utc)

  return (
    <div className='py-6 px-8 border-b border-gray-light'>
      {/* <ReactTooltip multiline={true} className='new-request-tooltip' eventOff='click'/> */}
      <div className='flex flex-row flex-nowrap justify-end'>
        <div className='mr-auto font-medium font-size-1-25em'>{ticket.assetTypeName}</div>
        {/* <i className='material-icons text-gray-md-dark font-size-1-25em my-auto px-2 cursor-pointer'>photo_camera</i> */}
        {/* <i
          data-tip={'Call EnRoute <br> Name | 555-555-5555'}
          className='material-icons text-gray-md-dark font-size-1-25em relative top-2 px-2 cursor-pointer'>
          phone
        </i> */}
        {ticket.requestedStartTime && ticket.requestedEndTime &&
          <>
            <i className='material-icons text-gray-md-dark font-size-1-25em my-auto px-2'>schedule</i>
            <div className='text-gray-md-dark my-auto text-2xl'>
              {`${dayjs.utc(ticket.requestedStartTime).format('h:mm a')} - ${dayjs.utc(ticket.requestedEndTime).format('h:mm a')}`}
            </div>
          </>}
      </div>
      <div className='text-gray'dangerouslySetInnerHTML={{ __html: removeStyleTags(ticket.serviceNotes) }} />
      {ticket.externalBillingNotes && <div className='text-gray'>PO Number {ticket.externalBillingNotes}</div>}
    </div>
  )
}

NewRequestTicket.propTypes = {
  ticket: PropTypes.object.isRequired
}
