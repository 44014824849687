import { loader } from 'graphql.macro'

export default async function updateSite ({ dataLoaders, variables }) {
  const updateSiteSchema = loader('./updateSite.graphql')
  const siteFields = {
    name: variables.name,
    siteTypeId: variables.siteTypeId,
    latitude: variables.latitude,
    longitude: variables.longitude,
    placeId: variables.placeId,
    addressline1: variables.addressline1,
    addressline2: variables.adderssline2,
    city: variables.city,
    state: variables.state,
    zip: variables.zip,
    country: variables.country,
    note: variables.note,
    cost: variables.cost === '' || variables.cost === null ? null : variables.cost
  }
  const response = await dataLoaders.dispatcher.graphQLClient.request(
    updateSiteSchema,
    { input: { siteFields, siteId: variables.id } }
  )
  return response.updateSite
}
