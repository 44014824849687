import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Typeahead } from 'react-bootstrap-typeahead'
import find from 'lodash/find'

export default function RenderAutoSuggestField ({
  field,
  input,
  label,
  labelKey,
  meta,
  fieldClass = '',
  onInputChange,
  options,
  placeholder,
  reinitField,
  renderFn
}) {
  const typeaheadRef = useRef(null)

  useEffect(() => {
    if (typeaheadRef === null || typeaheadRef.current === null) {
      return
    }

    if ((meta.pristine && !reinitField) || !input.value) {
      typeaheadRef.current.clear()
    }
  })

  function selected () {
    return find(options, o => o[field] === input.value)
  }

  let formGroupClasses = `form-group ${fieldClass ?? ''}`
  if (meta.touched && meta.error) {
    formGroupClasses += ' has-error'
  }

  const selectedValue = selected()
  return (
    <div className={formGroupClasses}>
      {label && <label className='control-label'>{label}</label>}
      <Typeahead
        id={`typeahead-${field}`}
        ref={typeaheadRef}
        selected={selectedValue ? [selected()] : []}
        placeholder={placeholder}
        onInputChange={onInputChange}
        onChange={(value) => {
          const val = value[0] ? value[0][field] : null
          input.onChange(val)
        }}
        labelKey={labelKey}
        options={typeof renderFn === 'function' ? renderFn(options) : options}
      />
      {(meta.touched && meta.error) && <h5>{meta.error}</h5>}
    </div>
  )
}

RenderAutoSuggestField.propTypes = {
  field: PropTypes.string,
  input: PropTypes.any,
  label: PropTypes.string,
  labelKey: PropTypes.string,
  fieldClass: PropTypes.string,
  meta: PropTypes.shape({
    pristine: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.string
  }),
  onInputChange: PropTypes.func,
  options: PropTypes.any,
  placeholder: PropTypes.string,
  reinitField: PropTypes.bool,
  renderFn: PropTypes.func
}
