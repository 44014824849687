import { loader } from 'graphql.macro'

export default function wastepayReceiptByTransaction ({ dataLoaders, variables }) {
  const {
    transactionId,
    sendTo = [],
    action
  } = variables
  let schema

  if (action.toUpperCase() === 'PRINT') {
    schema = loader('./wastepayReceiptByTransactionPDF.graphql')
    return dataLoaders.dispatcher.graphQLClient.request(schema, {
      transactionId
    })
  }

  if (action.toUpperCase() === 'EMAIL') {
    schema = loader('./wastepayReceiptByTransactionEmail.graphql')
    return dataLoaders.dispatcher.graphQLClient.request(schema, {
      transactionId,
      sendTo
    })
  }
}
