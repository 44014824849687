import React, { useState, useEffect } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import { generatePath } from 'react-router-dom'
import { ROUTES } from '../../../routes'
import JobSearchFormComponent from '../../job-search/job-search-form'
import JobSearchTableSelectComponent from '../../job-search/job-search-table-select'
import { prettyAddress } from '../../../utilities/locationUtilities'
import { formatPhoneNumber } from '../../../utilities/stringUtilities'
import getHeaders from '../../../utilities/getHeaders'
import BASE_URI from '../../../utilities/BASE_URI'
import notify from '../../../utilities/notify'
import FixRequestCreateJob from './create-job'
import FixRequestContactWindow from './contact-window'
import styles from './fix-request.module.scss'

export default function FixRequest ({
  request,
  clientID = null,
  initialFixStep = null,
  closeModal,
  requestIndex,
  updateRequest,
  clientName = ''
}) {
  const [fixStep, setFixStep] = useState(initialFixStep) // ['address invalid', "create job", "select job"], -1 === close modal
  const [searchResults, setSearchResults] = useState([])
  const [selectedJobID, setSelectedJobID] = useState(null)
  const [showJobSearch, setShowJobSearch] = useState(false)
  const geocoder = new google.maps.Geocoder()

  useEffect(() => {
    if (selectedJobID !== null) {
      const serviceIds = request.tickets.map(ticket => ticket.id)
      updateRequest(requestIndex, serviceIds, true, null, null, clientID, selectedJobID)
      closeModal()
    }
  }, [requestIndex, closeModal, selectedJobID, request.tickets, clientID, updateRequest])

  function cancelFix () {
    setFixStep(-1)
  }

  /**
   * If no jobs are found by the results search call, the results table will need something to show. This will be that something
   * @returns React.Component
   */
  function NoJobsFoundDiv () {
    return (<>
      <p>
        There are no jobs that match the account and contact of the new request.
      </p>
      <button className='dis-btn dis-btn-sm dis-btn-primary' type='button' onClick={() => setFixStep(1)}>
        Create New Job <i className='material-icons dis-btn-icon'>add_circle</i>
      </button>
    </>)
  }

  /**
   * Determine if the search form is to be hidden or in plain sight
   */
  function toggleShowHideSearchForm () {
    setShowJobSearch(!showJobSearch)
  }

  function postNewJob ({ results }) {
    return axios.post(
      `${BASE_URI}/hauler/jobs`,
      buildJobCreationRequest(results),
      getHeaders()
    )
  }

  function buildJobCreationRequest (results) {
    const [result] = results
    const lat = result?.geometry?.location?.lat()
    const lng = result?.geometry?.location?.lng()
    const [ticket] = request.tickets
    return {
      pretty_primary_phone: formatPhoneNumber(ticket.primaryPhone) || '',
      email: ticket.email || '',
      first_name: ticket.firstName || '',
      last_name: ticket.lastName || '',
      name: request.accountName,
      notes: ticket.notes || '',
      latitude: lat,
      longitude: lng,
      place_id: result?.place_id,
      addressline1: request.addressline1,
      city: request.city,
      state: request.state,
      zip: request.zip,
      country: ticket.country,
      address_details: request.addressDetails,
      client_id: clientID,
      primary_phone: ticket.primaryPhone || ''
    }
  }

  // https://developers.google.com/maps/documentation/javascript/places-autocomplete#maps_places_queryprediction-javascript
  // https://developers.google.com/maps/documentation/javascript/examples/places-placeid-geocoder
  function getLocationInformation () {
    const addr = prettyAddress({
      addressline1: request.addressline1,
      city: request.city,
      state: request.state,
      zip: request.zip,
      country: request.country
    })
    const displaySuggestions = function (predictions, status) {
      if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
        return notify('error', 'There was an error trying to create the job')
      }

      const [firstPrediction] = predictions
      geocoder
        .geocode({ placeId: firstPrediction.place_id })
        .then(postNewJob)
        .then(({ data }) => {
          setSelectedJobID(data.job.id)
        })
        .catch(err => {
          console.error(err)
        })
    }

    const service = new google.maps.places.AutocompleteService()

    service.getQueryPredictions({ input: addr }, displaySuggestions)
  }

  // few different steps in here.
  // -1) close modal
  //  0) address invalid so pick a job or create a job
  //  - Not part of this yet
  //  1) Create new job
  //  2) Find/Select Job
  if (fixStep === -1) {
    closeModal()
    return null
  }

  if (fixStep === 0) {
    return (
      <section className={styles['fix-request']}>
        Your address is weak
      </section>
    )
  }

  if (fixStep === 1) {
    return (
      <FixRequestCreateJob
        request={request}
        clientID={clientID}
        cancelAction={cancelFix}
        setJobID = {setSelectedJobID}
        clientName={clientName}
      />
    )
  }

  if (fixStep === 2) {
    const clientLink = generatePath(ROUTES.client, { clientId: clientID })

    return (
      <section className={styles['fix-request']}>
        <h3>Add Ticket to an Existing Job?</h3>
        <p className={styles['header-text']}>
          A job(s) with the same address already exists for <a href={clientLink}>{clientName}</a> but has a different contact. Select a job to
          use and Dispatcher will add the ticket(s) to that job and update the contact information.
        </p>
        <FixRequestContactWindow
          request={request}
        />
        <button type='button' onClick={toggleShowHideSearchForm} className='dis-btn dis-btn-link dis-btn-link-no-hover'>
          Search
          {showJobSearch
            ? <i className='material-icons dis-btn-icon'>expand_less</i>
            : <i className={`material-icons dis-btn-icon ${styles['tiny-icon']}`}>expand_more</i>}
        </button>
        <div>
          <JobSearchFormComponent
            initialSearchParams={{
              streetAddress: request?.addressline1,
              clientID,
              status: 'open'
            }}
            hiddenFields = {['jobStatus', 'clientID']}
            setSearchResults = {setSearchResults}
            autoSubmit = {true}
            onlyShowResults = {!showJobSearch}>
            <JobSearchTableSelectComponent
              searchResults = {searchResults}
              cancelAction = {cancelFix}
              setJobID = {setSelectedJobID}
              NoJobsFoundDiv = {NoJobsFoundDiv}
            />
          </JobSearchFormComponent>
        </div>
        { searchResults.length !== 0 &&
          <p className={styles['footer-text']}>
            Don&lsquo;t see the right job?
            <button type='button' className='dis-btn dis-btn-link' onClick={getLocationInformation}>&nbsp;Create New Job</button>
          </p>
        }

      </section>
    )
  }
}

FixRequest.propTypes = {
  clientID: PropTypes.number,
  request: PropTypes.object.isRequired,
  initialFixStep: PropTypes.number,
  closeModal: PropTypes.bool.isRequired,
  requestIndex: PropTypes.number.isRequired,
  updateRequest: PropTypes.func.isRequired,
  clientName: PropTypes.string
}
