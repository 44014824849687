import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import { TextInput } from '../TextInput'
import styles from './index.module.scss'
import normalizePhone from '../../pages/shared/normalizers/normalizePhone'
import cn from 'classnames'
import AutocompleteGoogleMap from '../../pages/shared/locationPickers/AutocompleteGoogleMap'
import { prettyAddress } from '../../utilities/locationUtilities'
import { PrimaryPhoneValidator } from '../../utilities/commonValidators'

const HaulerEditForm = ({ hauler, onSubmit, submitDisabled = false }) => {
  function onLocationChange (form, newLocation) {
    form.batch(() => {
      form.change('latitude', newLocation.latitude)
      form.change('longitude', newLocation.longitude)
      form.change('placeId', newLocation.place_id)
      form.change('addressline1', newLocation.addressline1)
      form.change('city', newLocation.city)
      form.change('state', newLocation.state)
      form.change('zip', newLocation.zip)
      form.change('country', newLocation.country)
    })
  }

  return (
    <div className='dis-panel'>
      <Form onSubmit={onSubmit}
        initialValues={{
          name: hauler.name,
          primaryPhone: hauler.prettyPrimaryPhone,
          latitude: hauler.latitude,
          longitude: hauler.longitude,
          placeId: hauler.placeId,
          addressline1: hauler.addressline1,
          city: hauler.city,
          state: hauler.state,
          zip: hauler.zip,
          country: hauler.country
        }}
        validate={validate}>
        {({ handleSubmit, pristine, invalid, form }) => {
          return (
            <form onSubmit={handleSubmit} className={cn(styles.form, 'dis-panel-body')}>
              <Field
                name='name'
                label={'Your Company\'s Name'}
                component={TextInput}
                maxLength='250'
              />
              <Field
                name='primaryPhone'
                label='Phone Number'
                component={TextInput}
                format={normalizePhone}
              />
              <AutocompleteGoogleMap
                place_id={hauler.placeId}
                latitude={parseFloat(hauler.latitude)}
                longitude={parseFloat(hauler.longitude)}
                initialValue={prettyAddress(hauler)}
                onLocationChange={newLocation => onLocationChange(form, newLocation)}
              />

              {/* Hidden fields still need to be here for react-final-form to consider them in form state i.e. pristine */}
              <Field name='latitude'>{() => null}</Field>
              <Field name='longitude'>{() => null}</Field>
              <Field name='placeId'>{() => null}</Field>
              <Field name='addressline1'>{() => null}</Field>
              <Field name='city'>{() => null}</Field>
              <Field name='state'>{() => null}</Field>
              <Field name='zip'>{() => null}</Field>
              <Field name='country'>{() => null}</Field>

              <div className={styles.actionButtons}>
                <button
                  type='submit'
                  className={cn(styles.saveBtn, 'dis-btn dis-btn-primary dis-btn-lg')}
                  disabled={pristine || invalid || submitDisabled}>
                  Save
                  <i className='material-icons pull-right thumbster-button-icon'>check</i>
                </button>
              </div>
            </form>
          )
        }}
      </Form>
    </div>
  )
}

HaulerEditForm.propTypes = {
  hauler: PropTypes.shape({
    name: PropTypes.string,
    placeId: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    prettyPrimaryPhone: PropTypes.string,
    addressline1: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    country: PropTypes.string
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitDisabled: PropTypes.bool
}

function validate (values) {
  const errors = {}

  if (!values.name) {
    errors.name = 'Required'
  }

  if (!values.primaryPhone) {
    errors.primaryPhone = 'Required'
  }

  if (values.primaryPhone && !PrimaryPhoneValidator(values.primaryPhone)) {
    errors.primaryPhone = 'Invalid Phone Number'
  }

  const requiredAddrFields = [
    'latitude',
    'longitude',
    'placeId',
    'addressline1',
    'city',
    'state',
    'zip',
    'country'
  ]

  requiredAddrFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })

  return errors
}

export default HaulerEditForm
