import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import { commaDeliminate } from '../../utilities/stringUtilities'
import { required, mustBeMoney, validMultipleEmails, composeValidators } from '../../utilities/commonValidators'
import ConnectToWastePay from '../connect-to-wastepay'
import styles from './index.module.scss'
import cn from 'classnames'

export default function RefundForm ({
  accessToken,
  handleSubmit,
  transactionId,
  invoiceData,
  isRefunding,
  transactionAmount,
  hideCheckWastepayConnection,
  refundError,
  invoiceSettingsData,
  paymentMethod
}) {
  const transactionAmountFixed = transactionAmount.toFixed(2)
  const total = useMemo(() => {
    if (!invoiceData?.invoice.tickets) return '0.00'
    return invoiceData?.invoice.tickets[0]?.total ? commaDeliminate(invoiceData.invoice.tickets[0].total) : '0.00'
  }, [invoiceData?.invoice.tickets])

  const defaultEmails = useMemo(() => {
    if (!invoiceData?.invoice) return ''

    const { client, tickets } = invoiceData.invoice
    const emails = new Set()

    emails.add(client.email)

    tickets.forEach(ticket => emails.add(ticket.job?.email))

    return Array.from(emails).filter(email => email != null && email !== '').join(', ')
  }, [invoiceData?.invoice])

  function validateRefundAmount (value) {
    const isMoneyError = mustBeMoney(value)
    if (isMoneyError !== undefined) return isMoneyError

    if (Number(value) > transactionAmount) {
      return `Must be less than $${transactionAmountFixed}`
    }

    return undefined
  }
  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        refundAmount: transactionAmountFixed,
        email: defaultEmails,
        note: '',
        refundType: 'full'
      }}>
      {({ handleSubmit, invalid, values }) => (
        <form className={styles.paymentContainer} onSubmit={handleSubmit}>
          <div className={cn(styles.refundContainer, 'dis-panel ')}>
            <h3 className={cn(styles.sectionTitle)}>
              Refund Amount
            </h3>
            <div className={cn(styles.panelContainer)}>
              <dt>Original Transaction ID</dt>
              <dl>{transactionId}</dl>
              <dt>Original Payment</dt>
              <dl>${total}</dl>
              <dt>Amount Remaining</dt>
              <dl>${transactionAmountFixed}</dl>
              <fieldset>
                <legend>Total Refund Amount</legend>
                <label htmlFor='refundTypeFull'>
                  <Field
                    id='refundTypeFull'
                    name='refundType'
                    component='input'
                    type='radio'
                    value='full'
                  />
                  Full Refund ${transactionAmountFixed}
                </label>
                <label htmlFor='refundTypePartial'>
                  <Field
                    id='refundTypePartial'
                    name='refundType'
                    component='input'
                    type='radio'
                    value='partial'
                  />
                  Partial Refund
                </label>
              </fieldset>
              {values.refundType.toLowerCase() !== 'full' &&
                (
                  <Field name='refundAmount' validate={validateRefundAmount}>
                    {({ input, meta }) => (
                      <div className={cn(styles.refundAmountContainer, { 'has-error': (meta.error && meta.touched) })}>
                        <label className='control-label' htmlFor='refundAmount'>Amount</label>
                        <input
                          {...input}
                          type='text'
                          className='form-control'
                        />
                        {meta.error && meta.touched && <span>{meta.error}</span>}
                      </div>
                    )}
                  </Field>
                )
              }
              {invoiceSettingsData.invoiceSettings.sendRefundReceipt &&
                (
                  <Field name='email' validate={composeValidators(required, validMultipleEmails)}>
                    {({ input, meta }) => (
                      <div className={cn({ 'has-error': (meta.error && meta.touched) })}>
                        <label className='control-label' htmlFor='name'>Email for Receipt (comma seperated)</label>
                        <input
                          {...input}
                          type='email'
                          className='form-control'
                          multiple
                        />
                        {meta.error && meta.touched && <span>{meta.error}</span>}
                      </div>
                    )}
                  </Field>
                )
              }
              {/* commenting out the note because it isn't accepted in wastepay yet but will be at some point. Might as well keep it? */}
              {/* <Field name='note'>
              {({ input }) => (
                <div>
                  <label className='control-label' htmlFor='note'>Note to Customer (Optional)</label>
                  <textarea
                    {...input}
                    className='form-control'
                    maxLength='4000'
                  />
                </div>
              )}
            </Field> */}
            </div>
          </div>
          {hideCheckWastepayConnection
            ? (
              <div className={cn(styles.rightPanel)}>
                <div className='dis-panel'>
                  <h3 className={cn(styles.sectionTitle)}>
                    Invoice Summary
                  </h3>
                  <div className={cn(styles.panelContainer)}>
                    <dt>Account Name</dt>
                    <dl>{invoiceData.invoice.client.name}</dl>
                    <dt>Invoice Number</dt>
                    <dl>#{invoiceData.invoice.dispatcherInvoiceNumber}</dl>
                    <dt>Payment Method</dt>
                    <dl>{paymentMethod}</dl>
                  </div>
                </div>
                <div className='dis-panel'>
                  <h3 className={cn(styles.sectionTitle)}>
                    Refund Summary
                  </h3>
                  <div className={cn(styles.panelContainer)}>
                    <table className={cn(styles.summary)}>
                      <thead>
                        <tr>
                          <th className={cn(styles.summaryHeader)}>Amount to Refund</th>
                          <td className={cn(styles.summaryInfo)}>${displayRefundAmount(values, transactionAmount)}</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>Prior Amount</th>
                          <td>${transactionAmountFixed}</td>
                        </tr>
                        <tr>
                          <th>Amount After Refund</th>
                          <td>${getAmountAfter(values, transactionAmount)}</td>
                        </tr>
                      </tbody>
                    </table>
                    {refundError && (
                      <div className={styles.refundError}>
                        <div><i className='material-icons'>error</i></div>
                        <div>
                          <p className={styles.refundErrorHeader}>Refund Error</p>
                          <p>{ refundError }</p>
                        </div>
                      </div>
                    )}
                    <button type='submit'
                      className='dis-btn dis-btn-lg dis-btn-primary'
                      disabled={invalid || isRefunding || transactionAmount === 0}>
                      <span>SUBMIT REFUND ${displayRefundAmount(values, transactionAmount)}</span>
                      <i className='material-icons dis-btn-icon'>check</i>
                    </button>
                  </div>
                </div>
              </div>
              )
            : <ConnectToWastePay accessToken={accessToken} />
          }
        </form>
      )}
    </Form>
  )
}

RefundForm.propTypes = {
  invoiceData: PropTypes.shape({
    invoice: PropTypes.shape({
      dispatcherInvoiceNumber: PropTypes.string.isRequired,
      tickets: PropTypes.arrayOf(PropTypes.shape({
        total: PropTypes.number.isRequired
      })).isRequired,
      client: PropTypes.shape({
        email: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      })
    })
  }).isRequired,
  transactionAmount: PropTypes.number,
  accessToken: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  transactionId: PropTypes.string.isRequired,
  isRefunding: PropTypes.bool.isRequired,
  hideCheckWastepayConnection: PropTypes.bool.isRequired,
  refundError: PropTypes.string,
  invoiceSettingsData: PropTypes.shape({
    invoiceSettings: PropTypes.shape({
      sendRefundReceipt: PropTypes.bool.isRequired
    }).isRequired
  }).isRequired,
  paymentMethod: PropTypes.string.isRequired
}

function displayRefundAmount (formValues, transactionAmount) {
  if (formValues.refundAmount == null) return ''
  if (formValues.refundType.toLowerCase() === 'full') return Number(transactionAmount || 0).toFixed(2)

  return Number(formValues.refundAmount).toFixed(2)
}

function getAmountAfter (formValues, transactionAmount) {
  const initialAmount = transactionAmount
  const refundAmount = formValues.refundAmount ?? 0
  const refundedAmount = formValues.refundType.toLowerCase() === 'full' ? initialAmount : refundAmount

  return (initialAmount - refundedAmount).toFixed(2)
}
