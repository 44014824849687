import React, { useState } from 'react'
import PropTypes from 'prop-types'
import StageOne from './StageOne'
import StageTwo from './StageTwo'

export default function ImportModal ({ handleClose, pluralImportName }) {
  const [farthestStage, setFarthestStage] = useState(1)

  return (
    <div className='bg-white shadow w-5/6 md:w-1/2 lg:w-2/5 relative px-8'>
      {farthestStage === 1 && <StageOne farthestStage={farthestStage} setFarthestStage={setFarthestStage} pluralImportName={pluralImportName} />}
      {farthestStage === 2 && <StageTwo farthestStage={farthestStage} pluralImportName={pluralImportName} />}
      <div className='text-right p-6'>
        <button
          type='button'
          className='hover:bg-white-10-dark uppercase px-6 py-3 text-2xl rounded-md text-center align-middle'
          onClick={handleClose}>
          Close
        </button>
      </div>
    </div>
  )
}

ImportModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  pluralImportName: PropTypes.string.isRequired
}
