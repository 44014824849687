import React from 'react'

import FormExplanation from '../../../../shared/FormExplanation'

export default function CreatSiteTypeExplanation () {
  const messages = [`Site Types are the types of sites you visit everyday. These can include things like Repair Sites,
   Container Yards, and Transfer Stations. Creating Asset Types let's you create multiple instances of the same type of container.`,
  'A Short Code is a one character nickname for your Site Type to be displayed on the Dispatch Map.']
  return (
    <FormExplanation messages={messages} />
  )
}
