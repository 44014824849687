import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import RenderAutoSuggestField from '../../../../../shared/formRenderers/RenderAutoSuggestField'

const validate = (values) => {
  const errors = {}

  if (!values.id) {
    errors.id = 'Required'
  }

  return errors
}

class JobResourcesSearchForm extends Component {
  render () {
    const { pristine, invalid, resources } = this.props

    return (
      <div className='container-fluid'>
        <form onSubmit={this.props.handleSubmit}>

          <div className='pull-left'>
            <Field
              name='id'
              placeholder='Asset Unique ID'
              options={resources}
              labelKey='uid'
              field='id'
              component={RenderAutoSuggestField}
            />
          </div>

          <button
            type='submit'
            className='btn btn-lg btn-primary pull-right'
            disabled={pristine || invalid}>
            <span className='pull-left'>Add To Job</span>
            <i className='material-icons pull-right thumbster-button-icon'>add_circle</i>
          </button>

        </form>
      </div>
    )
  }
}

// eslint-disable-next-line no-class-assign
JobResourcesSearchForm = reduxForm({
  form: 'jobResourcesSearchForm',
  validate
})(JobResourcesSearchForm)

JobResourcesSearchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  resources: PropTypes.array.isRequired
}

export default connect()(JobResourcesSearchForm)
