import React from 'react'
import { useSelector } from 'react-redux'
import useQuery from '../../hooks/useQuery'
import QUERY_KEYS from '../../graphql/queryKeys'
import sitesQuery from '../../graphql/queries/sites'
import { generatePath, Link } from 'react-router-dom'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import styles from './index.module.scss'
import cn from 'classnames'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import PageTitle from '../../components/page-title'
import { Spinner } from '../shared/Spinner'
import SitesTable from '../../components/sites-table'
import { ROUTES } from '../../routes'

export default function SitesPage () {
  const { user } = useSelector(({ user: { user } }) => ({ user }))

  const { data: { sites } = [], isLoading } = useQuery(
    [
      QUERY_KEYS.sites,
      user.haulerId
    ],
    sitesQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Failed to retrieve sites')
      }
    }
  )

  return (
    <NormalLayoutContainer>
      <PageTitle>
        <div className='dis-flex-page-title'>
          <div>Sites</div>
          <Link className={cn(styles.newSiteBtn, 'dis-btn dis-btn-lg dis-btn-primary')} to={generatePath(ROUTES.newSite)}>
            New Site
            <i className='material-icons dis-btn-icon'>add</i>
          </Link>
        </div>
      </PageTitle>

      {isLoading
        ? <Spinner isFetching />
        : (
          <div className='dis-panel'>
            <div className='dis-panel-body'>
              <SitesTable
                sites={sites}
              />
            </div>
          </div>
          )}
    </NormalLayoutContainer>
  )
}
