import { loader } from 'graphql.macro'
import { jobsSortColumnEnumMap } from '../enums'

export default function jobSearchQuery ({ dataLoaders, queryKey }) {
  const [, haulerId, first, sort, searchFields = {}, pager = null] = queryKey
  const sortColumnEnum = jobsSortColumnEnumMap[sort.column]

  if (!sortColumnEnum) {
    return Promise.reject(new Error(`Invalid sort.column: ${sort.column}`))
  }

  let graphQLVariables = {
    haulerId,
    first,
    sortColumn: sortColumnEnum,
    sortDirection: sort.direction.toUpperCase(),
    searchFields
  }
  if (pager) {
    const paginatedVariables = {
      before: pager.direction === 'before' ? pager.cursor : null,
      after: pager.direction === 'after' ? pager.cursor : null,
      first: pager.direction === 'after' ? Number(pager.pageSize) : null,
      last: pager.direction === 'before' ? Number(pager.pageSize) : null
    }
    graphQLVariables = { ...graphQLVariables, ...paginatedVariables }
  }

  const schema = loader('./jobsSearch.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(schema, graphQLVariables)
}
