import React from 'react'
import PropTypes from 'prop-types'

export default function UserProfile ({ user }) {
  return (
    <div className='row'>
      <div className='list-group'>
        <div className='list-group-item'>
          <i className='material-icons large-icon pull-right'>person</i>
          <div className='list-group-item-heading'>First Name</div>
          <div className='list-group-item-text'>{user.firstName}</div>
        </div>

        <div className='list-group-item'>
          <i className='material-icons large-icon pull-right'>person</i>
          <div className='list-group-item-heading'>Last Name</div>
          <div className='list-group-item-text'>{user.lastName}</div>
        </div>

        <div className='list-group-item'>
          <i className='material-icons large-icon pull-right'>email</i>
          <div className='list-group-item-heading'>Email</div>
          <div className='list-group-item-text'>{user.email}</div>
        </div>

        <div className='list-group-item'>
          <i className='material-icons large-icon pull-right'>phone</i>
          <div className='list-group-item-heading'>Phone</div>
          <div className='list-group-item-text'>{user.prettyPrimaryPhone}</div>
        </div>
      </div>
    </div>
  )
}

UserProfile.propTypes = {
  user: PropTypes.object.isRequired
}
