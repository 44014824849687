import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment'
import JobResourcesSearchForm from './JobResourcesSearchForm'
import CursorPagination from '../../../../../../components/cursor-pagination'

export default function JobResourcesDisplay ({
  jobCustomId,
  jobResources,
  allResources,
  handleRemoveResourceFromJob,
  handleSubmit,
  onPageRequest,
  fakeCount
}) {
  return (
    <div className='row'>
      <ul className='list-group'>
        <li className='list-group-item thumbster-gray-super-light-bg'>
          <h5>
            Current Assets on Job Site
            <Link to={`/assets?relatedType=JOB_CUSTOM_ID&relatedSearch=${jobCustomId}`}>
              &nbsp;<small className='text-primary link'>(View All)</small>
            </Link>
            <span className='pull-right badge'>{jobResources.length}</span>
          </h5>
        </li>
        {(() => jobResources?.nodes?.map(resource => (
          <li key={resource.id} className='list-group-item'>
            <Link to={`/hauler/resources/${resource.id}`}>
              {resource.uid} ({resource?.type || 'UNKNOWN'})
            </Link>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <i
              className='pull-right material-icons cursor-pointer'
              onClick={() => handleRemoveResourceFromJob(resource.id)}>
              delete
            </i>
            <em className='opacity-5 pull-right'>
              Marked:&nbsp;
              {(() => {
                if (resource.lastMoved) {
                  const parsedMoment = moment(resource.lastMoved)
                  const formattedDate = parsedMoment.format('MMM D, YYYY, h:mm a')
                  const formattedDayCount = parsedMoment.fromNow()
                  return (
                    <span>
                      <strong>
                        {formattedDayCount}
                      </strong>
                      &nbsp;at {formattedDate}
                    </span>
                  )
                }
                return 'N/A'
              })()}
              &nbsp;
              &nbsp;
            </em>
            <div className='clearfix' />
          </li>
        )))()}
        {
          jobResources?.totalCount > 0 && <CursorPagination
            pageInfo={jobResources?.pageInfo}
            totalCount={jobResources?.totalCount}
            onPageRequest={onPageRequest}
            fakeCount={fakeCount}
            isSiteJobResourceDisplay
                                          />
        }
        <li className='list-group-item' style={{ borderTop: '0px' }}>
          <JobResourcesSearchForm
            resources={allResources}
            handleSubmit={handleSubmit}
          />
        </li>
      </ul>
    </div>
  )
}

JobResourcesDisplay.propTypes = {
  allResources: PropTypes.array.isRequired,
  fakeCount: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired
  }),
  handleRemoveResourceFromJob: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  jobCustomId: PropTypes.number,
  jobResources: PropTypes.array.isRequired,
  onPageRequest: PropTypes.func.isRequired
}
