import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { enumToTitleCase } from '../../utilities/stringUtilities'

export default function DefaultBillingStatusInputs ({
  billingStatuses,
  billingStatusManuallyUpdated,
  resourceTypeBillingStatusSelected,
  resourceTypes,
  setResourceTypeBillingStatusSelected,
  setTicketTypeBillingStatusSelected,
  ticketTypeBillingStatusSelected,
  ticketTypes,
  values
}) {
  function billingStatusMessaging (manuallyUpdated, value) {
    if (manuallyUpdated || !value) {
      return ''
    } else if (value === resourceTypeBillingStatusSelected) {
      return 'Using asset type default'
    } else if (value === ticketTypeBillingStatusSelected) {
      return 'Using ticket type default'
    } else {
      return 'Using general default'
    }
  }

  function onTicketTypeChange (val) {
    const selected = ticketTypes.find(type => type.id === val)
    setTicketTypeBillingStatusSelected(selected?.defaultBillingStatus)
    if (val) {
      return resourceTypeBillingStatusSelected || selected.defaultBillingStatus || 'PENDING'
    }
    return null
  }

  function onResourceTypeChange (val) {
    const selected = resourceTypes.find(type => type.id === val)
    setResourceTypeBillingStatusSelected(selected?.defaultBillingStatus)
    if (val) {
      return selected?.defaultBillingStatus || ticketTypeBillingStatusSelected || 'PENDING'
    }
    return null
  }

  return (
    <>
      <Field name='billingStatus' subscription={{}}>
        {(
          { input: { onChange } }
        ) => (
          <OnChange name='ticketTypeId'>
            {() => {
              if (!billingStatusManuallyUpdated) {
                onChange(onTicketTypeChange(values.ticketTypeId))
              }
            }}
          </OnChange>
        )}
      </Field>

      <Field name='billingStatus' subscription={{}}>
        {(
          { input: { onChange } }
        ) => (
          <OnChange name='resourceTypeId'>
            {() => {
              if (!billingStatusManuallyUpdated) {
                onChange(onResourceTypeChange(values.resourceTypeId))
              }
            }}
          </OnChange>
        )}
      </Field>

      <Field name='ticketTypeId'>
        {({ input }) => (
          <div className='form-group'>
            <label htmlFor='ticketTypeId' className='control-label'>Ticket Type</label>
            <select
              {...input}
              type='select'
              className='form-control'>
              <option key='placeholder' value=''></option>
              {ticketTypes.map((tt) =>
                (
                  <option
                    value={tt.id}
                    key={tt.id}>
                    {`${tt.name} (${tt.shortCode})`}
                  </option>
                )
              )}
            </select>
          </div>
        )}
      </Field>

      <Field name='resourceTypeId'>
        {({ input }) => (
          <div className='form-group'>
            <label htmlFor='resourceTypeId' className='control-label'>Asset Type</label>
            <select
              {...input}
              type='select'
              className='form-control'>
              <option key='placeholder' value=''></option>
              {resourceTypes.map((rt) =>
                (
                  <option
                    value={rt.id}
                    key={rt.id}>
                    {`${rt.name} (${rt.shortCode})`}
                  </option>
                )
              )}
            </select>
          </div>
        )}
      </Field>

      <Field name='billingStatus'>
        {({ input }) => (
          <div className='form-group'>
            <label htmlFor='billingStatus' className='control-label'>Billing Status</label>
            <select
              {...input}
              type='select'
              className='form-control'>
              <option key='placeholder' value=''></option>
              {Object.keys(billingStatuses).map((opt) =>
                (
                  <option
                    value={opt}
                    key={opt}>
                    {enumToTitleCase(opt)}
                  </option>
                )
              )}
            </select>
          </div>
        )}
      </Field>
      <h6><em>{billingStatusMessaging(billingStatusManuallyUpdated, values.billingStatus)}</em></h6>
    </>
  )
}

DefaultBillingStatusInputs.propTypes = {
  billingStatuses: PropTypes.object.isRequired,
  billingStatusManuallyUpdated: PropTypes.bool,
  resourceTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    shortCode: PropTypes.string.isRequired
  })).isRequired,
  resourceTypeBillingStatusSelected: PropTypes.string,
  setResourceTypeBillingStatusSelected: PropTypes.func,
  setTicketTypeBillingStatusSelected: PropTypes.func,
  ticketTypeBillingStatusSelected: PropTypes.string,
  ticketTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    shortCode: PropTypes.string.isRequired
  })).isRequired,
  values: PropTypes.object
}
