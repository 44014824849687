
import {
  REQUEST_SITE_TYPE_CREATE_ACTION,
  RECEIVE_SITE_TYPE_CREATE_SUCCESS,
  RECEIVE_SITE_TYPE_CREATE_FAILURE,
  CLEAR_SITE_TYPE_CREATE_ERRORS
} from '../sagas/siteTypesSaga'

export const initialState = {
  isFetching: false,
  errors: []
}

const siteTypesCreateReducer = (reduxState = initialState, action) => {
  const { payload, type } = action
  switch (type) {
    case REQUEST_SITE_TYPE_CREATE_ACTION:
      return {
        ...reduxState,
        isFetching: true,
        errors: []
      }

    case RECEIVE_SITE_TYPE_CREATE_SUCCESS:
      return {
        ...reduxState,
        isFetching: false,
        errors: []
      }

    case RECEIVE_SITE_TYPE_CREATE_FAILURE:
      return {
        ...reduxState,
        isFetching: false,
        errors: payload.errors
      }

    case CLEAR_SITE_TYPE_CREATE_ERRORS:
      return {
        ...reduxState,
        isFetching: false,
        errors: []
      }

    default:
      return reduxState
  }
}

export default siteTypesCreateReducer
