import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import dayjs from '../../utilities/dayjs'
import { alphabeticalSort } from '../../utilities/arrayUtilities'
import { enumToTitleCase } from '../../utilities/stringUtilities'
import { Typeahead } from 'react-bootstrap-typeahead'
import DatePicker from 'react-datepicker'
import Spinner from 'react-spinkit'
import { determineIfRequiredActionsPresent } from './utils'

const initialVals = {
  clientId: '',
  customId: '',
  dispatchSearch: '',
  driverId: '',
  flagged: '',
  hasActiveException: '',
  jobCustomId: '',
  maxDate: '',
  minDate: '',
  resourceTypeId: '',
  status: '',
  ticketTypeId: '',
  billingStatus: ''
}
export default function TicketsTableSearchForm ({
  clearFormValues,
  clients,
  handleSubmit,
  resourceTypes,
  team,
  ticketTypes,
  initialFormVals = initialVals,
  billingStatuses = [],
  handleExportTickets,
  ticketsExporting
}) {
  const typeahead = useRef(null)
  const [formValues, setFormValues] = useState(initialFormVals)
  const activeTicketTypes = ticketTypes.filter(tt => tt.status === 'enabled')
  const hasRequiredActions = determineIfRequiredActionsPresent(ticketTypes)

  useEffect(() => {
    if (typeahead?.current && !formValues.clientId) {
      typeahead.current.clear()
    }
  }, [formValues.clientId])

  function handleClear () {
    clearFormValues()
    setFormValues(initialVals)
  }

  function onSubmit (event) {
    event.preventDefault()
    handleSubmit(formValues)
  }

  function formatDate (date) {
    const dateSplit = (date).split('-')
    const dateYear = parseInt(dateSplit[0])
    const dateMonth = parseInt(dateSplit[1] - 1)
    const dateDay = parseInt(dateSplit[2])
    return new Date(dateYear, dateMonth, dateDay).toUTCString()
  }

  function handleOnChange (field, val) {
    setFormValues({ ...formValues, [field]: val })
  }

  return (
    <div className='container-fluid'>
      <form onSubmit={onSubmit} autoComplete='off'>

        <div className='row'>
          <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3 form-group'>
            <label htmlFor='min_date' className='control-label'>Start Date</label>
            <div>
              <DatePicker
                name='min_date'
                dateFormat='MM/dd/yyyy'
                selected={formValues.minDate ? new Date(formatDate(formValues.minDate)) : undefined}
                onChange={(val) => handleOnChange('minDate', val ? dayjs(val).format('YYYY-MM-DD') : undefined)}
                fixedHeight
              />
            </div>
          </div>

          <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3 form-group'>
            <label htmlFor='max_date' className='control-label'>End Date</label>
            <div>
              <DatePicker
                name='max_date'
                dateFormat='MM/dd/yyyy'
                selected={formValues.maxDate ? new Date(formatDate(formValues.maxDate)) : undefined}
                onChange={(val) => handleOnChange('maxDate', val ? dayjs(val).format('YYYY-MM-DD') : undefined)}
                fixedHeight
              />
            </div>
          </div>

          <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3 form-group'>
            <label htmlFor='custom_id' className='control-label'>Ticket ID</label>
            <input
              name='custom_id'
              type='text'
              className='form-control'
              value={formValues.customId || ''}
              onChange={(e) => handleOnChange('customId', e.target.value)}
            />
          </div>

          <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3 form-group'>
            <label htmlFor='job_custom_id' className='control-label'>Job ID</label>
            <input
              name='job_custom_id'
              type='text'
              className='form-control'
              value={formValues.jobCustomId || ''}
              onChange={(e) => handleOnChange('jobCustomId', e.target.value)}
            />
          </div>
        </div>

        <div className='row'>

          <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3 form-group'>
            <label htmlFor='client_id' className='control-label'>Account Name</label>
            <Typeahead
              ref={typeahead}
              name='client_id'
              id='typeahead-id'
              defaultSelected={clients.filter(client => client.id === formValues.clientId)}
              onChange={(value) => {
                handleOnChange('clientId', value[0] ? value[0].id : null)
              }}
              labelKey='name'
              options={clients}
            />
          </div>

          <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3 form-group'>
            <label htmlFor='resource_type_id' className='control-label'>Asset Type</label>
            <select
              name='resource_type_id'
              className='form-control'
              value={formValues.resourceTypeId}
              onChange={(e) => handleOnChange('resourceTypeId', e.currentTarget.value)}>
              <option key='placeholder' value=''></option>
              {resourceTypes.map(opt =>
                <option key={opt.id} value={opt.id}>{`${opt.name} (${opt.shortCode})`}</option>)}
            </select>
          </div>

          <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3'>
            <label htmlFor='ticket_type_id' className='control-label'>Ticket Type</label>
            <select
              name='ticket_type_id'
              className='form-control'
              value={formValues.ticketTypeId}
              onChange={(e) => handleOnChange('ticketTypeId', e.currentTarget.value)}>
              <option key='placeholder' value=''></option>
              {activeTicketTypes.map(opt =>
                <option key={opt.id} value={opt.id}>{`${opt.name} (${opt.short_code})`}</option>)}
            </select>
          </div>

          <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3 form-group'>
            <label htmlFor='driver_id' className='control-label'>Driver Name</label>
            <select
              name='driver_id'
              className='form-control'
              value={formValues.driverId}
              onChange={(e) => handleOnChange('driverId', e.currentTarget.value)}>
              <option key='placeholder' value=''></option>
              {team.sort((a, b) => alphabeticalSort(`${a.first_name} ${a.last_name}`, `${b.first_name} ${b.last_name}`)).map(opt =>
                <option key={opt.id} value={opt.id}>{`${opt.first_name} ${opt.last_name}`}</option>)}
            </select>
          </div>
        </div>

        <div className='row'>

          <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3 form-group'>
            <label htmlFor='status' className='control-label'>Status</label>
            <select
              name='status'
              className='form-control'
              value={formValues.status}
              onChange={(e) => handleOnChange('status', e.currentTarget.value)}>
              <option key='placeholder' value=''></option>
              {Object.entries({
                active: '- All Active -',
                open: '  Open',
                en_route: '  En Route',
                terminal: '- All Closed -',
                cancelled: '  Cancelled',
                completed: '  Completed'
              }).map(([key, value]) =>
                <option key={key} value={key}>{value}</option>)}
            </select>
          </div>

          <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3 form-group'>
            <label htmlFor='flagged' className='control-label'>Flagged</label>
            <select
              name='flagged'
              className='form-control'
              value={formValues.flagged}
              onChange={(e) => handleOnChange('flagged', e.currentTarget.value)}>
              <option key='placeholder' value=''></option>
              {Object.entries({
                true: 'Flagged',
                false: 'Not Flagged'
              }).map(([key, value]) =>
                <option key={key} value={key}>{value}</option>)}
            </select>
          </div>

          <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3 form-group'>
            <label htmlFor='billing status' className='control-label'>Billing Status</label>
            <select
              name='billing status'
              className='form-control'
              value={formValues.billingStatus}
              onChange={(e) => handleOnChange('billingStatus', e.currentTarget.value)}>
              <option key='placeholder' value=''></option>
              {Object.keys(billingStatuses).map((opt) =>
                (
                  <option
                    value={opt}
                    key={opt}>
                    {enumToTitleCase(opt)}
                  </option>
                )
              )}
            </select>
          </div>

          {hasRequiredActions && (
            <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3 form-group'>
              <label htmlFor='has_active_exception' className='control-label'>Exception</label>
              <select
                name='has_active_exception'
                className='form-control'
                value={formValues.hasActiveException}
                onChange={(e) => handleOnChange('hasActiveException', e.currentTarget.value)}>
                <option key='placeholder' value=''></option>
                {Object.entries({
                  true: 'Completed With Exception',
                  false: 'No Exception'
                }).map(([key, value]) =>
                  <option key={key} value={key}>{value}</option>)}
              </select>
            </div>
          )}

          <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3 form-group'>
            <label htmlFor='dispatch_search' className='control-label'>Street Address</label>
            <input
              name='dispatch_search'
              type='text'
              className='form-control'
              value={formValues.dispatchSearch || ''}
              onChange={(e) => handleOnChange('dispatchSearch', e.target.value)}
            />
          </div>

          <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3 form-group'>
            <label htmlFor='city' className='control-label'>City</label>
            <input
              name='city'
              type='text'
              className='form-control'
              value={formValues.city || ''}
              onChange={(e) => handleOnChange('city', e.target.value)}
            />
          </div>

          <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3 form-group'>
            <label htmlFor='zip_code' className='control-label'>Postal Code</label>
            <input
              name='zip_code'
              type='text'
              className='form-control'
              value={formValues.zipCode || ''}
              onChange={(e) => handleOnChange('zipCode', e.target.value)}
            />
          </div>

          <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3 form-group'>
            <label htmlFor='weight_ticket_number' className='control-label'>Weight Ticket Number</label>
            <input
              name='weight_ticket_number'
              type='text'
              className='form-control'
              value={formValues.weightTicketNumber || ''}
              onChange={(e) => handleOnChange('weightTicketNumber', e.target.value)}
            />
          </div>

        </div>

        <div className='row'>
          <button
            type='button'
            tabIndex={-1}
            onClick={handleExportTickets}
            className='btn btn-primary-dk push-down-very-small pull-left'
            style={{ marginLeft: 20, width: 115 }}>
            {ticketsExporting
              ? (<Spinner name='circle' className='mx-auto' color='white' fadeIn='none' />)
              : (
                <>
                  <span className='pull-left'>Export</span>
                  <i className='material-icons pull-right space-left'>get_app</i>
                </>)}
          </button>

          <button
            type='button'
            tabIndex={-1}
            onClick={handleClear}
            className='btn btn-default push-down-very-small pull-right'
            style={{ marginLeft: 20 }}>
            <span className='pull-left'>Clear</span>
            <i className='material-icons pull-right space-left'>clear</i>
          </button>

          <button
            type='submit'
            className='btn btn-success push-down-very-small pull-right'>
            <span className='pull-left'>Search</span>
            <i className='material-icons pull-right space-left'>search</i>
          </button>
        </div>
      </form>
    </div>
  )
}

TicketsTableSearchForm.propTypes = {
  clearFormValues: PropTypes.func,
  clients: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resourceTypes: PropTypes.array.isRequired,
  team: PropTypes.array.isRequired,
  ticketTypes: PropTypes.array.isRequired,
  initialFormVals: PropTypes.object,
  billingStatuses: PropTypes.object.isRequired,
  handleExportTickets: PropTypes.func.isRequired,
  ticketsExporting: PropTypes.bool.isRequired
}
