import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'react-bootstrap/lib/Tooltip'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'

export default class TooltipWrapper extends Component {
  render () {
    const { condition, text } = this.props

    // Just return children if condition is falsey
    if (!condition) {
      return this.props.children
    }

    const tooltip = (
      <Tooltip id='tooltip'>{text}</Tooltip>
    )
    return (<OverlayTrigger placement='top' overlay={tooltip}>
      {this.props.children}
    </OverlayTrigger>)
  }
}

TooltipWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  condition: PropTypes.bool,
  text: PropTypes.string
}
