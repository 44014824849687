import React from 'react'

export default function HaulerTeamMemberEditExplanation () {
  return (
    <div className='row'>
      <h5 className='text-center'> HELP </h5>
      <hr />
      <h6>This is where you can edit an existing user.</h6>
      <hr />
      <h6>
        {'Roles are important in the system. If you select "Driver" as the role, then he or she cannot '}
        {'log in to the web interface. That\'s the website you\'re using right now. However, they can use the '}
        {'mobile app to fulfill tickets. Admins can log into the web interface and act as drivers.'}
      </h6>
      <hr />
      <h6>To reset their password, have that user go to the sign in page and reset their password.</h6>
    </div>
  )
}
