export function getHeaders () {
  try {
    if (localStorage.getItem('auth')) {
      return {
        withCredentials: true,
        headers: {
          Authorization: JSON.parse(localStorage.getItem('auth')).access_token || ''
          // /* eslint-disable */ SourceVersion: __SOURCE_VERSION__
        }
      }
    }
    return {}
  } catch (error) {
    console.error(error)
    if (error.code === 22) {
      alert('Your browser does not support localStorage. This is a common issue if you\'re in Private Mode')
    }
    return {}
  }
}

export default getHeaders
