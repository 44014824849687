import { loader } from 'graphql.macro'

export default function feeTypeQuickbooksItem ({ dataLoaders, queryKey }) {
  const [, variables] = queryKey
  const schema = loader('./feeTypeQuickbooksItem.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(schema, {
    id: variables.id,
    haulerId: variables.haulerId
  })
}
