import { put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import { history } from '../../../../history'
import BASE_URI from '../../../../utilities/BASE_URI'
import { handleError } from '../../../../utilities/handleError'
import { getHeaders } from '../../../../utilities/getHeaders'
import { intercomConnection } from '../../../../intercomConnection'
import notify from '../../../../utilities/notify'

export const REQUEST_CURRENT_TICKET_ACTION = 'REQUEST_CURRENT_TICKET_ACTION'
export const RECEIVE_CURRENT_TICKET_SUCCESS = 'RECEIVE_CURRENT_TICKET_SUCCESS'
export const RECEIVE_CURRENT_TICKET_FAILURE = 'RECEIVE_CURRENT_TICKET_FAILURE'

export const REQUEST_RESCHEDULE_CURRENT_TICKET_ACTION = 'REQUEST_RESCHEDULE_CURRENT_TICKET_ACTION'
export const RECEIVE_RESCHEDULE_CURRENT_TICKET_SUCCESS = 'RECEIVE_RESCHEDULE_CURRENT_TICKET_SUCCESS'
export const RECEIVE_RESCHEDULE_CURRENT_TICKET_FAILURE = 'RECEIVE_UPDATE_CURRENT_TICKET_FAILURE'

function * requestCurrentTicket (data) {
  try {
    const { id, cb } = data.payload
    const response = yield axios.get(
      `${BASE_URI}/hauler/tickets/${id}`,
      getHeaders()
    )
    yield put({ type: RECEIVE_CURRENT_TICKET_SUCCESS, payload: response.data })
    if (cb) yield * cb(response.data.ticket.job_id)
  } catch (e) {
    const errors = handleError(e)
    yield put({ type: RECEIVE_CURRENT_TICKET_FAILURE, payload: { errors } })
  }
}

function * requestRescheduleCurrentTicket (data) {
  try {
    const { id, params } = data.payload
    const response = yield axios.post(
      `${BASE_URI}/hauler/tickets/${id}/actions/reschedule`,
      params,
      getHeaders()
    )
    yield put({ type: RECEIVE_RESCHEDULE_CURRENT_TICKET_SUCCESS, payload: response.data })
    yield intercomConnection.trackEvent('ticket_rescheduled', {})
    yield notify('success', 'Ticket Rescheduled Successfully')
    yield history.goBack()
  } catch (e) {
    const errors = handleError(e)
    yield put({ type: RECEIVE_RESCHEDULE_CURRENT_TICKET_FAILURE, payload: { errors } })
  }
}

export default function * currentTicketSaga () {
  yield takeLatest(REQUEST_CURRENT_TICKET_ACTION, requestCurrentTicket)
  yield takeLatest(REQUEST_RESCHEDULE_CURRENT_TICKET_ACTION, requestRescheduleCurrentTicket)
}
