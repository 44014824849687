import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import classNamePropTypes from '../utilities/classNamePropTypes'

export function CheckboxInput ({
  input,
  label,
  meta: {
    touched,
    error
  },
  disabled,
  className
}) {
  const formGroupClasses = [className, 'form-group']
  if (touched && error) {
    formGroupClasses.push('has-error')
  }
  return (
    <div className={cn(formGroupClasses)}>
      <input
        disabled={disabled}
        className='pull-left'
        type='checkbox'
        id={input.name}
        {...input}
      /> <label htmlFor={input.name}>{label}</label>
    </div>
  )
}

CheckboxInput.propTypes = {
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  }),
  disabled: PropTypes.bool,
  className: classNamePropTypes
}
