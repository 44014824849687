import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, Link } from 'react-router-dom'
import NoResults from '../../pages/shared/NoResults'
import NotesCell from './NotesCell'
import { ObjectParam, useQueryParams } from 'use-query-params'
import useQuery from '../../hooks/useQuery'
import QUERY_KEYS from '../../graphql/queryKeys'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import { Spinner } from '../../pages/shared/Spinner'
import resourceImportRowsQuery from '../../graphql/queries/resourceImportRows'
import CursorPagination from '../cursor-pagination'
import { ROUTES } from '../../routes'

export default function CompletedResourceImportRowsTable ({ importId }) {
  const [queryParams, setQueryParams] = useQueryParams({ pager: ObjectParam })

  const { data: resourceImportRowsData, isLoading } = useQuery(
    [QUERY_KEYS.resourceImportRows, {
      resourceImportId: importId,
      status: 'IMPORTED',
      first: queryParams.pager?.direction === undefined || queryParams.pager?.direction === 'after' ? 10 : undefined,
      last: queryParams.pager?.direction === 'before' ? 10 : undefined,
      after: queryParams.pager?.direction === 'after' ? queryParams.pager?.cursor : undefined,
      before: queryParams.pager?.direction === 'before' ? queryParams.pager?.cursor : undefined
    }],
    resourceImportRowsQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Failed to retrieve data')
      },
      // We don't usually consider data to not be stale, BUT in this scenario this query gets loaded from a few components within a short time span
      // AND this data never changes. Once an import starts, its rows do not change.
      staleTime: 5 * 1000 * 60
    })

  if (isLoading) {
    return <Spinner isFetching />
  }

  function handlePageRequest (direction, cursor) {
    setQueryParams({ pager: { direction, cursor } })
  }

  return (
    <div className='panel panel-default row'>
      <div className='panel-body'>
        <div className='table-responsive'>
          <table className='table table-striped'>
            <thead>
              <tr>
                <th width='33%'>Asset ID</th>
                <th width='33%'>Placement Note</th>
                <th width='33%'>Asset Type</th>
              </tr>
            </thead>
            <tbody>
              {resourceImportRowsData.resourceImportRows.nodes.length === 0 && <tr><td colSpan='7'><NoResults /></td></tr>}
              {resourceImportRowsData.resourceImportRows.nodes.map(data => {
                const path = generatePath(ROUTES.resource, { id: data.resource.id })
                return (
                  <tr key={data.id}>
                    <td><Link to={path}>{data.resource.uid}</Link></td>
                    <td><NotesCell notes={data.placementNote} /></td>
                    <td>{data.resourceTypeName}</td>
                    <td><Link to={path} className='btn btn-sm btn-primary-dk'>Details</Link></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <CursorPagination
            onPageRequest={handlePageRequest}
            pageInfo={resourceImportRowsData.resourceImportRows.pageInfo}
            totalCount={resourceImportRowsData.resourceImportRows.totalCount}
          />
        </div>
      </div>
    </div>
  )
}

CompletedResourceImportRowsTable.propTypes = {
  importId: PropTypes.string.isRequired
}
