import { loader } from 'graphql.macro'

export default async function saveUnassignedTicketsSortPreference ({ dataLoaders, variables }) {
  const schema = loader('./saveUnassignedTicketsSortPreference.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      userId: variables.userId,
      preferences: variables.preferences
    }
  })
  return response.saveUnassignedTicketsSortPreference
}
