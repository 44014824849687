import React from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import { formatDollarAmount } from '../../utilities/stringUtilities'

export default function InvoiceTotals ({
  invoice,
  showDiscountAndTax,
  multiTicketTaxDisclaimer
}) {
  return (<>
    <div className={styles.totalContainer}>
      {showDiscountAndTax && (<>
        <div>
          <div className={styles.totalLine}>Discount</div>
          <div className={styles.totalLine}>({formatDollarAmount(invoice?.discountTotal)})</div>
        </div>
        <div>
          <div className={styles.totalLine}>Subtotal</div>
          <div className={styles.totalLine}>{formatDollarAmount(invoice?.subtotal)}</div>
        </div>
        <div>
          <div className={styles.totalLineTax}>{ `${multiTicketTaxDisclaimer && invoice?.taxTotal ? '* ' : ''}Tax`}</div>
          <div className={styles.totalLineTax}>{formatDollarAmount(invoice?.taxTotal)}</div>
        </div>
      </>)}
      <div>
        <div className={styles.total}>Total</div>
        <div className={styles.total}>${formatDollarAmount(invoice?.total)}</div>
      </div>
      <div>
        <div className={styles.outstandingBalance}>Outstanding Balance</div>
        <div className={styles.outstandingBalance}>${formatDollarAmount(invoice?.outstandingBalance)}</div>
      </div>
    </div>
    {multiTicketTaxDisclaimer && Boolean(invoice?.taxTotal) && <div className={styles.qboTaxDisclaimer}>
      * This invoice has tickets from different jobs. Please ensure tax is properly allocated in Quickbooks.
    </div>}
  </>)
}

InvoiceTotals.propTypes = {
  invoice: PropTypes.object.isRequired,
  showDiscountAndTax: PropTypes.bool.isRequired,
  multiTicketTaxDisclaimer: PropTypes.bool.isRequired
}
