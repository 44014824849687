import React, { createRef, useState, useEffect, useMemo } from 'react'
import notify from '../../utilities/notify'
import styles from './ticketPage.module.scss'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import activitiesQuery from '../../graphql/queries/activities'
import QUERY_KEYS from '../../graphql/queryKeys'
import { useParams, useLocation, generatePath, useHistory } from 'react-router-dom'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import PageTitle from '../../components/page-title'
import TicketStatusLabel from '../../components/ticket-display/ticket-status-label'
import NotificationsReadOnlyList from '../../components/notifications-read-only'
import TicketStatusButtons from '../../components/ticket-display/buttons/TicketStatusButtons'
import TicketImages from '../../components/ticket-display/ticket-images'
import DestroyTicketButton from '../../components/ticket-display/buttons/destroy-ticket'
import TicketActivitiesDisplay from '../../components/ticket-display/ticket-activities'
import TicketDetails from '../../components/ticket-display/ticket-details'
import TicketJobDetails from '../../components/ticket-display/ticket-job-details'
import TicketAccountContainer from '../../components/ticket-display/ticket-account'
import TicketFeesInvoicing from '../../components/ticket-fees-invoicing'
import TicketSitesDisplay from '../../components/ticket-display/ticket-sites'
import useQuery, { generateQueryKey } from '../../hooks/useQuery'
import useMutation from '../../hooks/useMutation'
import feeTypesQuery from '../../graphql/queries/feeTypes'
import ticketQuery from '../../graphql/queries/ticket'
import sitesQuery from '../../graphql/queries/sites'
import ticketNotificationsQuery from '../../graphql/queries/ticketNotifications'
import quickbooksTaxCodeRatesQuery from '../../graphql/queries/quickbooksTaxCodeRates'
import updateTicketStatusMutation from '../../graphql/mutations/updateTicketStatus'
import updateTicketFlaggedMutation from '../../graphql/mutations/updateTicketFlagged'
import clearExceptionFromTicketMutation from '../../graphql/mutations/clearExceptionFromTicket'
import restoreExceptionForTicketMutation from '../../graphql/mutations/restoreExceptionForTicket'
import updateTicketBillingStatusMutation from '../../graphql/mutations/updateTicketBillingStatus'
import removeSiteFromTicketMutation from '../../graphql/mutations/removeSiteFromTicket'
import addImageToTicketMutation from '../../graphql/mutations/addImageToTicket'
import removeImageFromTicketMutation from '../../graphql/mutations/removeImageFromTicket'
import createTicketActivityMutation from '../../graphql/mutations/createTicketActivity'
import destroyTicketMutation from '../../graphql/mutations/destroyTicket'
import addSiteToTicketMutation from '../../graphql/mutations/addSiteToTicket'
import createInvoiceMutation from '../../graphql/mutations/createInvoiceV2'
import { Spinner } from '../shared/Spinner'
import { useQueryClient } from 'react-query'
import { ROUTES } from '../../routes'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'

const pageSize = 25

export default function TicketPage () {
  const location = useLocation()
  const history = useHistory()
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const ticketIdRef = createRef()
  const queryClient = useQueryClient()
  const [scrolled, setScrolled] = useState(false)
  const { id } = useParams()
  const [commentsOnly, setCommentsOnly] = useState(true)
  const [pager, setPager] = useState(null)
  const [fakeCount, setFakeCount] = useState(null)
  const [ticketHasNewData, setTicketHasNewData] = useState(false)
  const [notificationsPage, setNotificationsPage] = useState(1)
  const { hauler, user, haulerChannel } = useSelector(({ user: { hauler, user }, pusher: { haulerChannel } }) => ({
    hauler,
    user,
    haulerChannel
  }))

  const { data: { ticket }, isFetching: isFetchingTicket, isError: isTicketError } = useQuery(
    [QUERY_KEYS.ticket, id],
    ticketQuery, {
      onError (error) {
        captureErrorAndNotify(error, 'Error loading ticket')
      },
      placeholderData: { ticket: {} }
    })

  const { data: { activities: ticketActivities }, isFetching: isLoadingTicketActivities, refetch: refetchTicketActivities } = useQuery(
    [QUERY_KEYS.activities, 'TICKET', id, commentsOnly ? 'ticket.add_comment' : '', pager],
    activitiesQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Error fetching ticket activities')
      },
      placeholderData: { activities: {} }
    })

  const { data: { quickbooksTaxCodeRates }, isFetching: isFetchingQboTaxCodeRates } = useQuery([
    QUERY_KEYS.quickBooksTaxCodes,
    hauler.id
  ],
  quickbooksTaxCodeRatesQuery,
  {
    enabled: hauler.quickbooks.isConnected,
    onError (error) {
      captureErrorAndNotify(error, 'Unable to fetch Quickbooks tax rates list')
    },
    initialData: { quickbooksTaxCodeRates: {} }
  })

  const { data: feeTypesData, isFetching: isFetchingFeeTypes } = useQuery(
    [QUERY_KEYS.feeTypes, 'ENABLED'],
    feeTypesQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Error fetching fee types')
      },
      placeholderData: { feeTypes: {} }
    }
  )

  const { data: sitesData, isFetching: isFetchingSites } = useQuery([
    QUERY_KEYS.sites,
    user.haulerId
  ], sitesQuery, {
    onError (error) {
      captureErrorAndNotify(error, 'Error fetching sites')
    }
  })

  const { data: notificationsData, isLoading: isLoadingNotifications } = useQuery(
    [QUERY_KEYS.ticketNotifications, id, notificationsPage],
    ticketNotificationsQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Error loading notifications')
      }
    })

  const { mutate: updateTicketStatus, isLoading: isUpdatingTicketStatus } = useMutation(updateTicketStatusMutation, {
    onSuccess ({ status }, { ticketId }) {
      queryClient.setQueryData(generateQueryKey([QUERY_KEYS.ticket, ticketId], user.id), (oldData) => ({
        ...oldData,
        ticket: { ...oldData.ticket, status }
      }))
      switch (status) {
        case 'en_route':
          notify('success', 'Ticket started successfully!')
          break
        case 'open':
          notify('success', 'Ticket opened successfully!')
          break
        case 'completed':
          notify('success', 'Ticket completed successfully!')
          break
        case 'cancelled':
          notify('success', 'Ticket cancelled successfully!')
          break
        default:
          notify('success', 'Ticket updated')
      }
      refetchTicketActivities()
    },
    onError (error, { status }) {
      switch (status) {
        case 'en_route':
          captureErrorAndNotify(error, 'Error starting ticket')
          break
        case 'open':
          captureErrorAndNotify(error, 'Error opening ticket')
          break
        case 'completed':
          captureErrorAndNotify(error, 'Error completing ticket')
          break
        case 'cancelled':
          captureErrorAndNotify(error, 'Error cancelling ticket')
          break
        default:
          captureErrorAndNotify(error, 'Server error')
      }
    }
  })

  const { mutate: updateTicketFlagged } = useMutation(updateTicketFlaggedMutation, {
    onSuccess ({ ticket: newTicketData }, { ticketId }) {
      queryClient.setQueryData(generateQueryKey([QUERY_KEYS.ticket, ticketId], user.id), (oldData) => ({
        ...oldData,
        ticket: { ...oldData.ticket, flagged: newTicketData.flagged }
      }))
      notify('success', 'Flag status changed!')
      refetchTicketActivities()
    },
    onError (error) {
      captureErrorAndNotify(error, 'Error flagging ticket')
    }
  })

  const { mutate: clearExceptionFromTicket } = useMutation(clearExceptionFromTicketMutation, {
    onSuccess ({ ticket: newTicketData }, { ticketId }) {
      queryClient.setQueryData(generateQueryKey([QUERY_KEYS.ticket, ticketId], user.id), oldData => ({
        ...oldData,
        ticket: { ...oldData.ticket, ...newTicketData }
      }))
      notify('success', 'Site added')
      refetchTicketActivities()
    },
    onError (error) {
      captureErrorAndNotify(error, 'Error clearing exception')
    }
  })

  const { mutate: restoreExceptionForTicket } = useMutation(restoreExceptionForTicketMutation, {
    onSuccess ({ ticket: newTicketData }, { ticketId }) {
      queryClient.setQueryData(generateQueryKey([QUERY_KEYS.ticket, ticketId], user.id), (oldData) => ({
        ...oldData,
        ticket: { ...oldData.ticket, ...newTicketData }
      }))
      refetchTicketActivities()
    },
    onError (error) {
      captureErrorAndNotify(error, 'Error restoring exception')
    }
  })

  const { mutate: updateTicketBillingStatus } = useMutation(updateTicketBillingStatusMutation, {
    onSuccess ({ status }, { ticketId }) {
      queryClient.setQueryData(generateQueryKey([QUERY_KEYS.ticket, ticketId], user.id), (oldData) => ({
        ...oldData,
        ticket: { ...oldData.ticket, billingStatus: status }
      }))
    },
    onError (error) {
      captureErrorAndNotify(error, 'Error updating billing status')
    }
  })

  const { mutate: createInvoice } = useMutation(createInvoiceMutation, {
    onSuccess ({ invoice }) {
      notify('success', 'Invoice Created Successfully')
      if (hauler.quickbooks.isConnected && !hauler.isWastepayApproved) {
        history.push(generatePath(ROUTES.invoicesExport, { id: invoice?.id }))
      } else {
        history.push(`${generatePath(ROUTES.invoicePayment, { id: invoice?.id })}`)
      }
    },
    onError (error) {
      captureErrorAndNotify(error, 'Unable to create invoice')
    }
  })

  const { mutate: addSiteToTicket, isLoading: isAddingSiteToTicket } = useMutation(addSiteToTicketMutation, {
    onSuccess ({ ticket: newTicketData }, { ticketId }) {
      queryClient.setQueryData(generateQueryKey([QUERY_KEYS.ticket, ticketId], user.id), (oldData) => ({
        ...oldData,
        ticket: { ...oldData.ticket, ...newTicketData }
      }))
      notify('success', 'Site added')
      refetchTicketActivities()
    },
    onError (error) {
      captureErrorAndNotify(error, 'Failed to add site to ticket')
    }
  })

  const { mutate: removeSiteFromTicket, isLoading: isRemovingSiteFromTicket } = useMutation(removeSiteFromTicketMutation,
    {
      onSuccess ({ ticket: newTicketData }, { ticketId }) {
        queryClient.setQueryData(generateQueryKey([QUERY_KEYS.ticket, ticketId], user.id), (oldData) => ({
          ...oldData,
          ticket: { ...oldData.ticket, ...newTicketData }
        }))
        notify('success', 'Site removed')
        refetchTicketActivities()
      },
      onError (error) {
        captureErrorAndNotify(error, 'Error removing site')
      }
    })

  const { mutate: destroyTicket, isDestroyingTicket } = useMutation(destroyTicketMutation, {
    onSuccess () {
      notify('success', 'Ticket destroyed successfully')
      history.replace(ROUTES.ticketSearch)
    },
    onError (error) {
      captureErrorAndNotify(error, 'Failed to delete ticket')
    }
  })

  const { mutate: addImageToTicket } = useMutation(addImageToTicketMutation, {
    onSuccess ({ ticket: newTicketData }, { ticketId }) {
      queryClient.setQueryData(generateQueryKey([QUERY_KEYS.ticket, ticketId], user.id), (oldData) => ({
        ...oldData,
        ticket: { ...oldData.ticket, ...newTicketData }
      }))
      notify('success', 'Ticket image added')
      refetchTicketActivities()
    },
    onError (error) {
      captureErrorAndNotify(error, 'Failed to add ticket image')
    }
  })

  const { mutate: removeImageFromTicket } = useMutation(removeImageFromTicketMutation, {
    onSuccess ({ ticket: newTicketData }) {
      queryClient.setQueryData(generateQueryKey([QUERY_KEYS.ticket, id], user.id), (oldData) => ({
        ...oldData,
        ticket: { ...oldData.ticket, ...newTicketData }
      }))
      notify('success', 'Ticket image removed')
      refetchTicketActivities()
    },
    onError (error) {
      captureErrorAndNotify(error, 'Failed to remove ticket image')
    }
  })

  const { mutateAsync: createTicketActivity } = useMutation(createTicketActivityMutation, {
    onSuccess () {
      refetchTicketActivities()
    },
    onError (error) {
      captureErrorAndNotify(error, 'Failed to add activity')
    }
  })

  useEffect(function toggleFakePagination () {
    if (ticketActivities?.totalCount === undefined || fakeCount !== null) return
    setFakeCount({ start: 1, end: Math.min(pageSize, ticketActivities.totalCount) })
  }, [ticketActivities, fakeCount])

  useEffect(() => {
    const shouldScroll = queryParams.get('scrollTo') &&
      !isFetchingTicket &&
      !scrolled &&
      ticketIdRef?.current

    if (shouldScroll && ticketIdRef && ticketIdRef.current) {
      ticketIdRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      setScrolled(true)
    }
  }, [scrolled, isFetchingTicket, queryParams, ticketIdRef])

  useEffect(function listenToPusher () {
    if (!haulerChannel) return

    function handleExternalTicketChange ({ ticket: changedTicket }) {
      if (changedTicket.id === parseInt(id)) {
        // FIXME this does work as intended, BUT this feature does not appear to currently work in staging. Setting
        //  this to true means when the current user changes status it will show they have new data which is obviously not
        //  what we want. We only want to set this to true if an different user changes the ticket. Sadly the data
        //  we are currently getting from the websocket doesn't include a "updatedBy" so this kind of check isn't
        //  currently possible.
        // setTicketHasNewData(true)
      }
    }

    haulerChannel.bind('change-ticket', handleExternalTicketChange)

    return function cleanUpPusher () {
      setTicketHasNewData(false)
      if (!haulerChannel) return
      haulerChannel.unbind('change-ticket', handleExternalTicketChange)
    }
  }, [haulerChannel, id])

  function handleTicketActivitiesPageRequest (direction, cursor) {
    if (!fakeCount) return
    setPager({ cursor, direction, pageSize })
    if (direction === 'before') {
      setFakeCount(prevFakeCount => ({ start: prevFakeCount.start - pageSize, end: prevFakeCount.end - pageSize }))
    }
    if (direction === 'after') {
      setFakeCount(prevFakeCount => ({ start: prevFakeCount.start + pageSize, end: prevFakeCount.end + pageSize }))
    }
  }

  function openCloudinaryWidget () {
    const widget = cloudinary.createUploadWidget(
      {
        cloud_name: 'thumbster',
        upload_preset: 'fyfl9eso',
        resource_type: 'image',
        multiple: false,
        theme: 'white',
        tags: [`ticket_${ticket.id}`, process.env.NODE_ENV],
        context: {
          environment: process.env.NODE_ENV,
          client_id: ticket.clientId,
          ticket_id: ticket.id,
          hauler_id: ticket.haulerId
        }
      },
      (error, result) => {
        if (error) {
          if (error.kind && (error.kind === 'error') && error.message) alert(error.message)
          return
        }
        if (result?.info?.files) {
          const publicId = result.info.files?.[0]?.uploadInfo?.public_id
          if (publicId) {
            // Cloudinary automatically backs up uploaded images into our Archive class GCP bucket. We want to keep track of this date to figure out which images are deleted from Cloudinary (>2 years)
            const archivedAt = dayjs()
            addImageToTicket({ ticketId: id, publicId, archivedAt })
          }
        }
      })
    widget.open()
  }

  return (
    <NormalLayoutContainer showBackLink showNewData={ticketHasNewData}>
      {
        isFetchingTicket || isDestroyingTicket || isTicketError
          ? <Spinner isFetching />
          : (
            <div className={styles.layoutContainer}>
              <div className={styles.pageTitle}>
                <PageTitle>Ticket #{ticket.customId} Detail</PageTitle>
                <h4 className={styles.statusContainer}>
                  Current Status:
                  <span className={styles.statusLabel}>
                    <TicketStatusLabel hasActiveException={ticket.hasActiveException} status={ticket.status} />
                  </span>
                </h4>
              </div>
              <TicketStatusButtons
                ticket={ticket}
                onClick={(ticketId, status) => updateTicketStatus({ ticketId, status })}
                disabled={isUpdatingTicketStatus}
              />
              <div className={styles.ticketContainer}>
                <div className={styles.ticketDisplayContainer}>
                  <div className={styles.containerLeft}>
                    <TicketDetails
                      metric={hauler.metric}
                      ticket={ticket}
                      onToggleFlagged={(ticketId, flagged) => updateTicketFlagged({ ticketId, flag: flagged })}
                      onClearException={ticketId => clearExceptionFromTicket({ ticketId })}
                      onRestoreException={ticketId => restoreExceptionForTicket({ ticketId })}
                    />
                    <div id='fees' ref={ticketIdRef}>
                      {isFetchingFeeTypes
                        ? <Spinner isFetching />
                        : (
                          <TicketFeesInvoicing
                            ticket={ticket}
                            onBillingStatusChange={(ticketId, billingStatus) => updateTicketBillingStatus({ ticketId, billingStatus })}
                            qboTaxCodeRates={quickbooksTaxCodeRates}
                            feeTypes={feeTypesData.feeTypes}
                            isFetching={isFetchingFeeTypes || isFetchingQboTaxCodeRates}
                            onCreateInvoice={(clientId, haulerId, ticketIds) => createInvoice({ clientId, haulerId, ticketIds })}
                            returnTo={location?.state?.returnTo}
                          />
                          )
                      }
                    </div>
                  </div>
                  <div className={styles.containerRight}>
                    <TicketAccountContainer account={ticket.account} />
                    <TicketJobDetails job={ticket.job} />

                    {isFetchingSites
                      ? <Spinner isFetching />
                      : (
                        <TicketSitesDisplay
                          onSubmit={({ siteId }) => addSiteToTicket({ ticketId: id, siteId })}
                          onDestroy={({ siteId }) => removeSiteFromTicket({ ticketId: id, siteId })}
                          sites={sitesData.sites}
                          ticketSites={ticket.sites}
                          ticketSiteCosts={ticket.ticketSiteCosts}
                          disabled={isAddingSiteToTicket || isRemovingSiteFromTicket}
                        />
                        )
                    }
                  </div>
                </div>
              </div>

              <TicketImages
                images={ticket.images}
                onImageDelete={publicId => removeImageFromTicket({ publicId })}
                onImageAdd={openCloudinaryWidget}
                ticketCustomId={ticket.customId}
              />

              {isLoadingTicketActivities
                ? <Spinner isFetching />
                : (
                  <TicketActivitiesDisplay
                    activities={ticketActivities.nodes}
                    totalCount={ticketActivities.totalCount}
                    pageInfo={ticketActivities.pageInfo}
                    fakeCount={fakeCount}
                    onPageRequest={handleTicketActivitiesPageRequest}
                    onSubmit={data => createTicketActivity({ id, ...data })}
                    commentsOnly={commentsOnly}
                    setCommentsOnly={setCommentsOnly}
                  />
                  )
              }

              {isLoadingNotifications
                ? <Spinner isFetching />
                : (
                  <NotificationsReadOnlyList
                    title={`Notifications about Ticket #${ticket.customId}`}
                    notifications={notificationsData.notifications}
                    pagination={notificationsData.pagination}
                    showReceiver
                    onPageRequest={page => setNotificationsPage(page)}
                    includeLink={false}
                  />
                  )
              }

              <div className={styles.destroyTicketContainer}>
                <DestroyTicketButton
                  onClick={id => destroyTicket({ id })}
                  ticket={ticket}
                />
              </div>
            </div>
            )
      }
    </NormalLayoutContainer>
  )
}
