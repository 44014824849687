import React, { useMemo } from 'react'
import { generatePath, Link, useHistory, useLocation } from 'react-router-dom'
import NormalLayoutContainer from './shared/NormalLayoutContainer'
import { PageTitle } from '../components/page-title'
import FormExplanation from './shared/FormExplanation'
import notify from '../utilities/notify'
import NewJobForm from '../components/new-job-form'
import useMutation from '../hooks/useMutation'
import createJob from '../graphql/mutations/createJob'
import { ROUTES } from '../routes'
import useQuery from '../hooks/useQuery'
import QUERY_KEYS from '../graphql/queryKeys'
import clientQuery from '../graphql/queries/clientQuery'
import { Spinner } from './shared/Spinner'
import { useSelector } from 'react-redux'

export default function NewJobPage () {
  const location = useLocation()
  const history = useHistory()
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const clientId = queryParams.get('client_id')
  const { hauler } = useSelector(({ user: { hauler } }) => ({ hauler }))

  const { data: { client }, isFetching: isFetchingClient } = useQuery(
    [QUERY_KEYS.client, clientId],
    clientQuery,
    {
      initialData: { client: {} },
      enabled: clientId !== null,
      onError () {
        notify('error', 'Error loading account')
      }
    }
  )

  const { mutate: createJobMutation, isLoading: isCreatingJob } = useMutation(createJob, {
    onSuccess ({ job }, variables) {
      const { navToTicket = false } = variables
      const path = navToTicket ? `/hauler/tickets/new?job_id=${job.id}` : `/hauler/clients/${clientId}`
      notify('success', 'New Job Created Successfully')
      history.push(path)
    },
    onError () {
      notify('error', 'Failed to create job')
    }
  })

  function handleSubmit (newJob) {
    createJobMutation({
      ...newJob,
      latitude: parseFloat(newJob.latitude),
      longitude: parseFloat(newJob.longitude),
      addressline1: newJob.displayAddress || newJob.addressline1,
      clientId
    })
  }

  return (
    <div>
      <NormalLayoutContainer>
        <div className='container-fluid'>
          <PageTitle title='Create a New Job'>
            <Link
              className='btn btn-link pull-right push-down-super-small'
              to={generatePath(ROUTES.client, { clientId })}>
              Cancel
            </Link>
          </PageTitle>

          <div className='row'>
            <div className='col-xs-12 col-sm-12 col-md-7 col-lg-7'>
              {
                isFetchingClient
                  ? <Spinner isFetching />
                  : <NewJobForm
                      onSubmit={handleSubmit}
                      disabled={isCreatingJob}
                      initialValues={client}
                      defaultCoordinates={{ latitude: hauler.latitude, longitude: hauler.longitude }}
                    />
              }
            </div>
            <div className='col-xs-12 col-sm-12 col-md-4 col-md-offset-1 col-lg-4 col-lg-offset-1'>
              <div className='row'>
                <FormExplanation
                  messages={[
                    'Creating Jobs allows you to create a location where a specific action will take place.',
                    'Jobs can either be open or closed and can be manually edited to reflect the appropriate status of each job.',
                    'If you\'d like to do a CSV Upload for Job Creation, please contact us directly at support@dispatcher.com.'
                  ]}
                />
              </div>
            </div>
          </div>

        </div>
      </NormalLayoutContainer>
    </div>
  )
}
