import React from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import ErrorRows from '../error-rows'
import ResourceErrorRows from '../error-rows/ResourceErrorRows'

export default function CompletedView ({
  importDetails,
  pluralImportName,
  completedImportRowsTableComponent: CompletedImportRowsTable,
  returnTo
}) {
  return (
    <>
      <div className='flex justify-between mt-20'>
        <div>
          <div className='text-6xl'>
            {importDetails.recordsCreated} <span className={styles.capitalize}>{pluralImportName}</span> Have Been Created
          </div>
          <div className='text-2xl mt-4'>
            Below is a list of <span className={styles.lowercase}>{pluralImportName}</span> that have been added to Dispatcher.
          </div>
        </div>
        <div className='flex flex-col justify-end'>
          <Link to={returnTo}>
            <button className='btn btn-primary btn-lg'>
              <span className='font-medium'>GO TO ALL {pluralImportName}</span>
            </button>
          </Link>
        </div>
      </div>
      <hr />
      {
        pluralImportName === 'accounts'
          ? <ErrorRows importId={importDetails.id} pluralImportName={pluralImportName} />
          : <ResourceErrorRows importId={importDetails.id} pluralImportName={pluralImportName} />
      }
      <CompletedImportRowsTable importId={importDetails.id} />
    </>
  )
}

CompletedView.propTypes = {
  importDetails: PropTypes.shape({
    id: PropTypes.string.isRequired,
    recordsCreated: PropTypes.number.isRequired
  }).isRequired,
  pluralImportName: PropTypes.string.isRequired,
  completedImportRowsTableComponent: PropTypes.elementType.isRequired,
  returnTo: PropTypes.string.isRequired
}
