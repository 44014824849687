import React, { useMemo, useEffect, useState } from 'react'
import { OverlayTrigger } from 'react-bootstrap'
import Tooltip from 'react-bootstrap/lib/Tooltip'
import { Field, Form } from 'react-final-form'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { validateBillingAddress, formatJobAddressComponents } from './../utils'
import { US_STATES_ABBR, CA_PROVINCE_ABBR } from '../../../utilities/constants'
import wastepayCreateCardMutation from '../../../graphql/mutations/wastepayCreateCard'
import useMutation from '../../../hooks/useMutation'
import { captureErrorAndNotify } from '../../../utilities/errorHandlers'
import ElavonLightboxForm from '../../elavon-lightbox-form'
import styles from './index.module.scss'

export default function NewCardModal ({
  invoice,
  close,
  billingAddress,
  hauler,
  setNewCard,
  refetchCardList,
  setCardError
}) {
  const [formStep, setFormStep] = useState('billing')
  const [cardBillingAddress, setCardBillingAddress] = useState(billingAddress)

  const { mutateAsync: wastepayCreateCard, isLoading: isCreatingCard } = useMutation(wastepayCreateCardMutation, {
    async onSuccess ({ card: newCard }) {
      const cardData = {
        ...newCard,
        ...{
          isUnsavedCard: false,
          billingAddress
        }
      }
      await refetchCardList()
      setNewCard(cardData)
    },
    onError (error) {
      captureErrorAndNotify(error, 'Error saving credit card')
      close()
    }
  })

  function handleBillingAddressSubmit (values) {
    setCardBillingAddress(values)
    setFormStep('card')
  }

  async function handleCardSubmission (cardDetails, isSaveCard) {
    if (isSaveCard) {
      wastepayCreateCard(
        {
          ...cardDetails,
          ...{
            clientId: invoice.client.id,
            countryCode: hauler.country
          }
        })
    } else {
      setNewCard({ ...cardDetails, isUnsavedCard: true })
      close()
    }
  }

  function uncheckBillingAddressSameAsDelivery (args, state, tools) {
    const [name, value] = args
    tools.changeValue(state, 'billingAddressSameAsDelivery', () => false)
    tools.changeValue(state, name, () => value)
  }

  return (
    <div className={styles.newCardsModal}>
      <header className={cn(styles.billingAddressHeader)}>
        <h4>Add New Card</h4>
        <button
          className={cn(styles.closeModalBtn, 'dis-btn dis-btn-no-shadow')}
          type='button'
          onClick={close}>
          <i className={cn(styles.closeModalIcon, 'material-icons dis-btn-icon')}>close</i>
        </button>
      </header>
      { formStep === 'billing'
        ? (
          <Form
            onSubmit={handleBillingAddressSubmit}
            initialValues={billingAddress}
            mutators={{ uncheckBillingAddressSameAsDelivery }}
            invoice={invoice}
            validate={validateBillingAddress}
            component={BillingAddressForm}
            hauler={hauler}
            close={close}
          />
          )
        : (
          <ElavonLightboxForm
            handleSubmit={handleCardSubmission}
            billingAddress={cardBillingAddress}
            close={close}
            client={invoice.client}
            setNewCard={setNewCard}
            isCreatingCard={isCreatingCard}
            setCardError={setCardError}
            allowAddNickname={true}
          />
          )
      }
    </div>
  )
}

NewCardModal.propTypes = {
  invoice: PropTypes.shape({
    client: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      billingDetails: PropTypes.string
    }).isRequired,
    tickets: PropTypes.array.isRequired
  }).isRequired,
  billingAddress: PropTypes.shape({
    streetAddress: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string
  }),
  close: PropTypes.func.isRequired,
  hauler: PropTypes.shape({
    country: PropTypes.string.isRequired
  }).isRequired,
  setNewCard: PropTypes.func.isRequired,
  refetchCardList: PropTypes.func.isRequired,
  setCardError: PropTypes.func.isRequired
}

function BillingAddressForm ({
  handleSubmit,
  form,
  values,
  initialValues = {},
  invalid,
  submitting,
  close,
  invoice,
  hauler
}) {
  const job = invoice?.tickets?.[0]?.job
  const isUS = hauler.country.toUpperCase() === 'US'
  const stateList = isUS ? US_STATES_ABBR : CA_PROVINCE_ABBR

  useEffect(function setJobAddress () {
    if (!values.billingAddressSameAsDelivery) return
    form.batch(() => {
      form.change('streetAddress', formatJobAddressComponents(values.billingAddressSameAsDelivery ? job?.addressline1 : initialValues.streetAddress))
      form.change('city', formatJobAddressComponents(values.billingAddressSameAsDelivery ? job?.city : initialValues.zip))
      form.change('state', formatJobAddressComponents(values.billingAddressSameAsDelivery ? job?.state : initialValues.state))
      form.change('zip', formatJobAddressComponents(values.billingAddressSameAsDelivery ? job?.zip : initialValues.zip))
    })
  }, [
    form,
    job?.addressline1,
    initialValues.streetAddress,
    job?.city,
    initialValues.city,
    job?.state,
    initialValues.state,
    job?.zip,
    initialValues.zip,
    values.billingAddressSameAsDelivery
  ])

  const hideSameAsDeliveryCheckBox = useMemo(() => {
    if (invoice?.tickets?.length <= 1) return false
    const ticketJobIdList = invoice?.tickets?.map(tckt => tckt.job.id) || []
    return new Set(Array.from(ticketJobIdList)).size > 1
  }, [invoice?.tickets])

  function handleAddressChange (e) {
    form.mutators.uncheckBillingAddressSameAsDelivery(e.target.name, e.target.value)
  }

  const hasError = meta => meta.touched && meta.error && !meta.active

  return (
    <form onSubmit={handleSubmit} autoComplete='off'>
      <section className={cn(styles.billingAddressHeader)}>
        <p className={cn(styles.billingAddressTitle)}>
          Step 1: Billing Address
          <span>
            {invoice.client?.billingDetails?.length > 0 &&
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip id='dis-tooltip'>{invoice.client.billingDetails}</Tooltip>}>
                <i className={cn('material-icons', styles.billingDetailsIcon)}>speaker_notes</i>
              </OverlayTrigger>
              }
          </span>
        </p>
        <div>
          <Field name='billingAddressSameAsDelivery' type='checkbox'>
            {({ input }) => (
              !hideSameAsDeliveryCheckBox && (
                <label className={styles.deliveryInput}>
                  <input {...input} />
                  Same As Delivery
                </label>
              )
            )}
          </Field>
        </div>
      </section>
      <Field name='streetAddress'>
        {({ input, meta }) => (
          <div className={cn({ 'has-error': hasError(meta) }, styles.fullWidthInput)}>
            <label>
              Street Address
              <input
                {...input}
                className='form-control'
                type='text'
                onChange={handleAddressChange}
              />
            </label>
            {hasError(meta) && <h6>{meta.error}</h6>}
          </div>
        )}
      </Field>
      <Field name='city'>
        {({ input, meta }) => (
          <div className={cn(styles.fullWidthInput, { 'has-error': hasError(meta) })}>
            <label>
              City
              <input
                {...input}
                className='form-control'
                type='text'
                onChange={handleAddressChange}
              />
            </label>
            {hasError(meta) && <h6>{meta.error}</h6>}
          </div>
        )}
      </Field>
      <div className={cn(styles.inlineFieldsContainer)}>
        <Field name='state'>
          {({ input, meta }) => (
            <div className={cn({ 'has-error': hasError(meta) }, styles.inlineField)}>
              <label>
                {isUS ? 'State' : 'Province'}
                <select
                  {...input}
                  className='form-control'
                  onChange={handleAddressChange}>
                  <option />
                  { stateList.map((v, ind) => (
                    <option value={v} key={ind}>{v}</option>
                  ))}
                </select>
              </label>
              {hasError(meta) && <h6>{meta.error}</h6>}
            </div>
          )}
        </Field>
        <Field name='zip'>
          {({ input, meta }) => (
            <div className={cn({ 'has-error': hasError(meta) }, styles.inlineField)}>
              <label>
                {isUS ? 'Zip' : 'Postal Code'}
                <input
                  {...input}
                  className='form-control'
                  type='text'
                  onChange={handleAddressChange}
                />
              </label>
              {hasError(meta) && <h6>{meta.error}</h6>}
            </div>
          )}
        </Field>
      </div>
      <div className={cn(styles.inlineFieldsContainer, styles.spaceAround)}>
        <button onClick={close} type='button' className={cn('dis-btn dis-btn-blank dis-btn-lg')}>
          Cancel
        </button>
        <button disabled={invalid || submitting} type='submit' className={cn('dis-btn dis-btn-primary dis-btn-lg')}>
          Continue to Card Details
        </button>
      </div>
    </form>
  )
}

BillingAddressForm.propTypes = {
  /**
     * https://final-form.org/docs/react-final-form/types/FormRenderProps#handlesubmit
     */
  handleSubmit: PropTypes.func.isRequired,
  /**
     * https://final-form.org/docs/react-final-form/types/FormRenderProps#form
     */
  form: PropTypes.shape({
    /**
       * https://final-form.org/docs/final-form/types/FormApi#batch
       */
    batch: PropTypes.func.isRequired,
    /**
       * https://final-form.org/docs/final-form/types/FormApi#change
       */
    change: PropTypes.func.isRequired,
    /**
       * https://final-form.org/docs/final-form/types/FormApi#reset
       */
    reset: PropTypes.func.isRequired,
    mutators: PropTypes.shape({
      uncheckBillingAddressSameAsDelivery: PropTypes.func.isRequired
    }).isRequired
  }).isRequired,
  /**
     * https://final-form.org/docs/final-form/types/FormState#values
     */
  values: PropTypes.shape({
    // saleCC: PropTypes.string,
    billingAddressSameAsDelivery: PropTypes.bool,
    streetAddress: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string
  }).isRequired,
  /**
     * https://final-form.org/docs/final-form/types/FormState#invalid
     */
  invalid: PropTypes.bool.isRequired,
  /**
     * https://final-form.org/docs/final-form/types/FormState#submitting
     */
  submitting: PropTypes.bool.isRequired,
  /**
     * https://final-form.org/docs/final-form/types/FormState#initialvalues
     */
  initialValues: PropTypes.shape({
    billingAddressSameAsDelivery: PropTypes.bool,
    streetAddress: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string
  }),
  invoice: PropTypes.shape({
    total: PropTypes.number,
    outstandingBalance: PropTypes.number,
    client: PropTypes.shape({
      billingDetails: PropTypes.string
    }).isRequired,
    tickets: PropTypes.arrayOf(PropTypes.shape({
      job: PropTypes.shape({
        id: PropTypes.string.isRequired,
        addressline1: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string
      })
    }))
  }).isRequired,
  close: PropTypes.func.isRequired,
  errorMessage: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  hauler: PropTypes.shape({
    country: PropTypes.string.isRequired
  }).isRequired
}
