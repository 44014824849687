import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import notify from '../../utilities/notify'
import QUERY_KEYS from '../../graphql/queryKeys'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import { PageTitle } from '../../components/page-title'
import Switch from '../../components/Switch'
import { Spinner } from '../shared/Spinner'
import FeeTypeList from '../../components/fee-type/FeeTypeList'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../routes'
import styles from './index.module.scss'
import useQuery from '../../hooks/useQuery'
import feeTypesQuery from '../../graphql/queries/feeTypes'

export default function FeeTypesPage () {
  const [feeTypeStatus, setFeeTypeStatus] = useState('ENABLED')
  const { data: { feeTypes }, isFetching: isFeeTypesFetching, refetch: refetchFeeTypes } = useQuery(
    [QUERY_KEYS.feeTypes, feeTypeStatus],
    feeTypesQuery,
    {
      onError () {
        notify('error', 'Error fetching fee types')
      },
      initialData: { feeTypes: [] }
    }
  )

  useEffect(function callRefetch () {
    if (feeTypeStatus) {
      refetchFeeTypes({ status: feeTypeStatus })
    } else {
      refetchFeeTypes()
    }
  }, [feeTypeStatus, refetchFeeTypes])

  function handleShowDisabledToggle (isChecked) {
    setFeeTypeStatus(isChecked ? null : 'ENABLED')
  }

  return (
    <NormalLayoutContainer>
      <div className='container-fluid'>
        <PageTitle title='Fee Types' className='dis-flex-page-title'>
          <div className='dis-flex-page-title'>
            <div className={cn(styles.toggleSwitchWrapper, 'dis-flex-page-title')}>
              <Switch
                size='small'
                checked={feeTypeStatus === null}
                onChange={handleShowDisabledToggle}
              />
              <div className={cn(styles.toggleLabel)}>Show Disabled</div>
            </div>

            <Link to={ROUTES.newFeeType} className='btn btn-lg btn-primary pull-right push-down-super-small'>
              <span className='pull-left hidden-xs'>New Fee Type</span>
              <i className='material-icons pull-right thumbster-button-icon no-margin-bs-xs'>add_circle</i>
            </Link>
          </div>
        </PageTitle>
        {
          isFeeTypesFetching
            ? <Spinner isFetching />
            : <FeeTypeList feeTypes={feeTypes} />
        }
      </div>
    </NormalLayoutContainer>
  )
}
