import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { HorizontalBar } from 'react-chartjs-2'
import { determineTicketsByTicketType, determineData, options } from './TicketsPerTicketTypeGraphUtils'

export default function TicketsPerTicketTypeGraph ({ tickets }) {
  const history = useHistory()

  const ticketsByTicketType = determineTicketsByTicketType(tickets)
  const handleElementsClick = useCallback(
    (element) => {
      if (element[0]) {
        const key = Object.keys(ticketsByTicketType)[element[0]._index]
        const ticketTypeId = ticketsByTicketType[key].ticket_type.id
        const today = moment().format('YYYY-MM-DD')
        history.push(`/hauler/tickets/search?ticketTypeId=${ticketTypeId}&minDate=${today}&maxDate=${today}`)
      }
    },
    [history, ticketsByTicketType]
  )

  const keys = Object.keys(ticketsByTicketType)
  const data = determineData(keys, ticketsByTicketType)

  return (
    <div className='text-center'>
      <div className='push-down-very-small'>
        <HorizontalBar
          data={data}
          options={options}
          width={1000}
          height={500}
          onElementsClick={handleElementsClick}
        />
      </div>
    </div>
  )
}

TicketsPerTicketTypeGraph.propTypes = {
  tickets: PropTypes.array
}
