import React from 'react'
import PropTypes from 'prop-types'
import Spinner from 'react-spinkit'
import styles from './index.module.scss'
import { Field, Form } from 'react-final-form'
import Modal from '../../components/modal/Modal'
import cn from 'classnames'

export default function SelectWPCustomerModal ({
  closeModal,
  isOpen,
  wastepayCustomers,
  handleSubmit,
  submitting,
  clientId,
  resetCustomers
}) {
  return (
    <Modal
      closeModal={closeModal}
      isOpen={isOpen}
      className={cn('dis-modal-wrapper')}>
      <div className={cn('dis-modal-inner')}>
        <header className={cn(styles.modalHeader)}>
          <h3>Select WastePay Customer</h3>
          <button
            className={cn(styles.closeModalBtn, 'dis-btn dis-btn-no-shadow')}
            type='button'
            onClick={resetCustomers}>
            <i className={cn('material-icons dis-btn-icon')}>close</i>
          </button>
        </header>
        <p>At least 1 possible match has been found in WastePay for this account. Please select the correct WastePay Customer to connect the account to or connect to a new WastePay Customer.</p>
        <SelectWPCustomerForm
          wastepayCustomers= {wastepayCustomers}
          handleSubmit={handleSubmit}
          submitting={submitting}
          clientId = {clientId}
        />
      </div>
    </Modal>
  )
}

SelectWPCustomerModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  wastepayCustomers: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleExport: PropTypes.func.isRequired,
  resetCustomers: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

function SelectWPCustomerForm ({
  wastepayCustomers,
  handleSubmit,
  submitting,
  clientId
}) {
  function handleFormSubmission (values) {
    const { wastepayCustomer } = values
    const submissionArgs = {}
    if (wastepayCustomer === 'create-new') {
      submissionArgs.forceCreate = true
    } else {
      submissionArgs.wpCustomerId = wastepayCustomer
    }

    return handleSubmit(clientId, submissionArgs)
  }

  return (
    <Form
      className={cn()}
      onSubmit={handleFormSubmission}
      component={InternalForm}
      isLoading={submitting}
      wastepayCustomers={wastepayCustomers}
      initialValues={{
        wastepayCustomer: 'create-new'
      }}
    />
  )
}

SelectWPCustomerForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  wastepayCustomers: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

function InternalForm ({ handleSubmit, wastepayCustomers, isLoading }) {
  return (
    <form onSubmit={handleSubmit} className={cn()}>
      <div className={styles.customerOptions}>
        <label htmlFor='createnew'>
          <Field
            name='wastepayCustomer'
            component='input'
            type='radio'
            value='create-new'
            id='createnew'
          />
          <p>
            Create new WastePay Customer
          </p>
        </label>
        {
          wastepayCustomers.map((customer, index) => {
            const companyNameLine = [customer.companyName]
              .filter(v => v !== '')
              .join(' ')
            const contactLine = [customer.firstName, customer.lastName]
              .filter(v => v !== '')
              .join(' ')
            const emailLine = [customer.email]
              .filter(v => v !== '')
              .join(' ')
            const addressLine = [customer.address1, customer.address2, customer.city, customer.state, customer.zip]
              .filter(v => v !== '')
              .join(' ')

            return (
              <label htmlFor={`customer-${index}`} key={`customer-${index}`}>
                <Field
                  name='wastepayCustomer'
                  component='input'
                  type='radio'
                  value={customer.id}
                  id={`customer-${index}`}
                />
                <div>
                  { companyNameLine.length > 0 &&
                    <p>{companyNameLine}</p>
                  }
                  { contactLine.length > 0 &&
                    <p>{contactLine}</p>
                  }
                  { emailLine.length > 0 &&
                    <p>{emailLine}</p>
                  }
                  { addressLine.length > 0 &&
                    <p>{addressLine}</p>
                  }
                </div>
              </label>
            )
          })
        }
      </div>

      <div className={styles.submitButton}>
        <button
          type='submit'
          className={cn('dis-btn dis-btn-primary dis-btn-lg')}
          disabled={isLoading}>
          {isLoading ? <Spinner name='circle' fadeIn='none' /> : 'Submit' }
        </button>
      </div>
    </form>
  )
}

InternalForm.propTypes = {
  /**
   * https://final-form.org/docs/react-final-form/types/FormRenderProps#form
   */
  /**
   * https://final-form.org/docs/react-final-form/types/FormRenderProps#handlesubmit
   */
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  wastepayCustomers: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  )
}
