import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import notify from '../../utilities/notify'

export default function ButtonLinkOrNotification ({
  linkClasses,
  linkHref,
  icon,
  iconClasses = null,
  dontExport,
  linkText,
  notificationText = '',
  notificationType = 'info',
  notificationDuration = 6
}) {
  function handleBtnNotify () {
    notify(notificationType, notificationText, notificationDuration)
  }

  if (dontExport) {
    return (
      <button
        onClick={handleBtnNotify}
        type='button'
        className={cn(linkClasses)}>
        { icon
          ? (
            <>
              <span>{linkText}</span>
              <i className={cn(['material-icons', iconClasses])}>{icon}</i>
            </>
            )
          : linkText
        }
      </button>
    )
  }
  return (
    <Link
      to={linkHref}
      className={cn(linkClasses)}>
      { icon
        ? (
          <>
            <span>{linkText}</span>
            <i className={cn(['material-icons', iconClasses])}>{icon}</i>
          </>
          )
        : linkText
      }
    </Link>
  )
}

ButtonLinkOrNotification.propTypes = {
  linkClasses: PropTypes.string,
  linkHref: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconClasses: PropTypes.string,
  dontExport: PropTypes.bool.isRequired,
  notificationText: PropTypes.string,
  notificationType: PropTypes.oneOf(['info', 'error']),
  notificationDuration: PropTypes.number
}
