import { stripSpecialCharacters } from './stringUtilities'

export function generateCardToken (sessionToken, cardData) {
  return new Promise((resolve, reject) => {
    window.ConvergeEmbeddedPayment.pay(
      {
        ssl_txn_auth_token: sessionToken,
        ssl_card_number: cardData.cardNumber,
        ssl_exp_date: cardData.expiration,
        ssl_cvv2cvc2: cardData.cvv,
        ssl_avs_address: stripSpecialCharacters(cardData.address1),
        ssl_avs_zip: cardData.zip,
        ssl_city: cardData.city,
        ssl_state: cardData.state,
        ssl_get_token: 'y',
        ssl_add_token: 'y',
        ssl_verify: 'y',
        ssl_transaction_type: 'ccgettoken'
      },
      {
        onError (error) {
          reject(error)
        },
        onDeclined (response) {
          reject(response)
        },
        onApproval (response) {
          resolve(response)
        }
      }
    )
  })
}

export function cardBrandToEnum (brand) {
  switch (brand) {
    case 'MC':
      return 'MASTERCARD'
    case 'DISC':
      return 'DISCOVER'
    default:
      return brand
  }
}

export function parseExpirationToMonthAndYear (date) {
  return {
    month: parseInt(date.substring(0, 2)),
    year: parseInt(`20${date.substring(2)}`)
  }
}
