import PropTypes from 'prop-types'
import dayjs from '../../utilities/dayjs'
import { AVAILABILITY_GROUP, GRAPHQL_AVAILABILITY_DELIVERY_TYPE } from './constants'
import { filter } from 'kyanite'

/**
 * Unfortunately there is not great way to parse times without dates in JS.
 * We only care about the time here so use today as the date and give us back the time.
 *
 * @param time
 * @returns {string}
 */
export function formatTime (time) {
  if (time === '00:00:00Z') {
    return '-:--'
  }
  const today = dayjs()
  return dayjs.utc(`${today.get('y')}-${today.get('M')}-${today.get('D')} ${time}`, 'YYYY-M-D HH:mm:ss').format('h:mm a')
}

export function determineNextAvailableService (availability) {
  if (!availability.mustCall) {
    if (availability.nextAvailableService) {
      return dayjs(availability.nextAvailableService).toDate()
    }

    if (
      availability.daysOut === null &&
      availability.sameDayActive === false &&
      availability.nextDayActive === false
    ) {
      return dayjs(availability.deliveryDate).toDate()
    }
  }

  return null
}

export function applyAvailabilityUpdate (availabilities, updatedAvailability) {
  const newAvailabilities = { ...availabilities }
  if (updatedAvailability.deliveryType === GRAPHQL_AVAILABILITY_DELIVERY_TYPE.NEW_DELIVERY) {
    const newDeliveryKey = AVAILABILITY_GROUP[GRAPHQL_AVAILABILITY_DELIVERY_TYPE.NEW_DELIVERY]
    newAvailabilities[newDeliveryKey] = [
      ...filter(newDelivery => newDelivery.id !== updatedAvailability.id, newAvailabilities[newDeliveryKey]),
      updatedAvailability
    ]
    return newAvailabilities
  }

  newAvailabilities[AVAILABILITY_GROUP[updatedAvailability.deliveryType]] = updatedAvailability
  return newAvailabilities
}

export const currentAvailabilityProps = {
  id: PropTypes.string.isRequired,
  deliveryType: PropTypes.oneOf(Object.values(GRAPHQL_AVAILABILITY_DELIVERY_TYPE)).isRequired,
  sameDayActive: PropTypes.bool.isRequired,
  sameDayCutoffTime: PropTypes.string.isRequired,
  nextDayActive: PropTypes.bool.isRequired,
  nextDayCutoffTime: PropTypes.string.isRequired,
  saturdayActive: PropTypes.bool.isRequired,
  saturdayCutoffTime: PropTypes.string.isRequired,
  sundayActive: PropTypes.bool.isRequired,
  sundayCutoffTime: PropTypes.string.isRequired,
  nextAvailableService: PropTypes.string,
  deliveryDate: PropTypes.string.isRequired,
  daysOut: PropTypes.number,
  daysOutCutoffTime: PropTypes.string.isRequired
}

export const sizedAvailabilityProps = {
  ...currentAvailabilityProps,
  sizeId: PropTypes.string.isRequired,
  size: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
}

export const aggregateProps = PropTypes.shape({
  sameDayActive: PropTypes.bool.isRequired,
  nextDayActive: PropTypes.bool.isRequired,
  saturdayActive: PropTypes.bool.isRequired,
  sundayActive: PropTypes.bool.isRequired,
  nextAvailableService: PropTypes.string,
  daysOut: PropTypes.number,
  mustCall: PropTypes.bool.isRequired
})

export const availabilitiesProps = PropTypes.shape({
  aggregate: aggregateProps.isRequired,
  newDeliveries: PropTypes.arrayOf(PropTypes.shape(sizedAvailabilityProps)).isRequired,
  emptyReturn: PropTypes.shape(currentAvailabilityProps).isRequired,
  pickUp: PropTypes.shape(currentAvailabilityProps).isRequired
})
