import React, { useState } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
export default function CreateSiteTypeForm ({
  onSubmit,
  isFetching,
  resErrors,
  formValues,
  setFormValues,
  clearErrors
}) {
  const [formError, setFormError] = useState({
    name: false,
    shortCode: false
  })

  const checkErrorMessages = (msg) => {
    return resErrors.findIndex(errMsg => errMsg.toLowerCase().trim() === msg)
  }
  const shortCodeErrorIndex = checkErrorMessages('short code has already been taken')
  const nameErrorIndex = checkErrorMessages('name has already been taken')

  function handleOnChange (val, field) {
    const updatedSiteType = { ...formValues, ...{ [field]: val } }
    setFormValues(updatedSiteType)
    if (resErrors.length) {
      clearErrors()
    }
  }

  function handleOnBlur (field) {
    const updatedErrors = { ...formError, ...{ [field]: formValues[field].length === 0 } }
    setFormError(updatedErrors)
  }

  return (
    <div className='row panel panel-default'>
      <div className='container-fluid push-down-super-small'>
        <form onSubmit={onSubmit}>

          <div className={cn({
            'has-error': formError.name || nameErrorIndex > -1
          })}>
            <label className='control-label' htmlFor='name'>Site Type Name</label>
            <input
              type='text'
              className='form-control'
              name='name'
              maxLength='25'
              onBlur={() => handleOnBlur('name')}
              onChange={(e) => handleOnChange(e.target.value, 'name')}
            />
            {formError.name && <h6>Required</h6> }
            {nameErrorIndex > -1 && <h6>{resErrors[nameErrorIndex]}</h6> }
          </div>

          <div className={cn({
            'has-error': formError.shortCode || shortCodeErrorIndex > -1
          })}>
            <label className='control-label' htmlFor='short code'>Site Type Short Code (2 Characters)</label>
            <input
              type='text'
              className='form-control'
              name='short code'
              maxLength='2'
              onChange={(e) => handleOnChange(e.target.value, 'shortCode')}
              onBlur={() => handleOnBlur('shortCode')}
            />
            {formError.shortCode && <h6>Required</h6> }
            {shortCodeErrorIndex > -1 && <h6>{resErrors[shortCodeErrorIndex]}</h6> }
          </div>

          <button
            type='submit'
            className='btn btn-lg btn-primary push-down-super-small pull-right space-left'
            disabled={isFetching || !formValues.name?.length || !formValues.shortCode?.length || shortCodeErrorIndex > -1 || nameErrorIndex > -1}>
            <span className='pull-left'>Save New Site Type</span>
            <i className='material-icons pull-right thumbster-button-icon'>add_circle</i>
          </button>

          <br /><br /><br /><br /><br />
        </form>
      </div>
    </div>
  )
}

CreateSiteTypeForm.propTypes = {
  isFetching: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  resErrors: PropTypes.any,
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired
}
