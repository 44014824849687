import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'
import Spinner from 'react-spinkit'
import { Field, Form } from 'react-final-form'

export default function ResourcesSearchForm ({
  onSubmit,
  resourceTypes,
  initialValues = {},
  handleExportResources,
  resourcesExporting
}) {
  function clear (form) {
    form.restart({})
    form.submit()
  }

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} autoComplete='off' className={styles.formContainer}>

          <div className={styles.formRow}>
            <label htmlFor='uid' className={cn('control-label', styles.quarterWidthLabel)}>
              Asset ID
              <Field id='uid' name='uid' type='text' component='input' className='form-control' />
            </label>

            <label htmlFor='relatedType' className={cn('control-label', styles.quarterWidthLabel)}>
              Search by Related Type
              <Field id='relatedType' name='relatedType' className='form-control' component='select'>
                <option value=''></option>
                <option value='JOB_CUSTOM_ID'>Job</option>
                <option value='SITE_NAME'>Site</option>
                <option value='USER_NAME'>Driver</option>
              </Field>
            </label>

            <label htmlFor='relatedSearch' className={cn('control-label', styles.halfWidthLabel)}>
              Search Related
              <Field
                id='relatedSearch'
                name='relatedSearch'
                type='text'
                className='form-control'
                placeholder='Search by job id, account name, site name, or driver name'
                component='input'
              />
            </label>
          </div>

          <div className={styles.formRow}>
            <label htmlFor='minIdleDays' className={cn('control-label', styles.quarterWidthLabel)}>
              Idle At Least (Days)
              <Field id='minIdleDays' name='minIdleDays' className='form-control' type='number' min={0} component='input' />
            </label>

            <label htmlFor='maxIdleDays' className={cn('control-label', styles.quarterWidthLabel)}>
              Idle At Most (Days)
              <Field id='maxIdleDays' name='maxIdleDays' className='form-control' type='number' min={0} component='input' />
            </label>

            <label htmlFor='resource_type_id' className={cn('control-label', styles.quarterWidthLabel)}>
              Search Assets By Type
              <Field id='resourceTypeId' name='resourceTypeId' className='form-control' component='select'>
                <option value=''></option>
                {resourceTypes.map(opt => <option key={opt.id} value={opt.id}>{`${opt.name} (${opt.shortCode})`}</option>)}
              </Field>
            </label>

            <label htmlFor='addressSearch' className={cn('control-label', styles.quarterWidthLabel)}>
              Street address
              <Field id='addressSearch' name='addressSearch' component='input' type='text' className='form-control' />
            </label>
          </div>

          <div className={styles.buttonRow}>
            <button
              type='button'
              className='dis-btn dis-btn-primary-dk dis-btn-sm'
              onClick={handleExportResources}
              style={{ width: 115 }}>
              {resourcesExporting
                ? (<Spinner name='circle' className='mx-auto' color='white' fadeIn='none' />)
                : (
                  <>
                    <span className='pull-left'>Export</span>
                    <i className='material-icons dis-btn-icon'>get_app</i>
                  </>
                  )
              }
            </button>
            <div>
              <button type='button' className={cn('dis-btn dis-btn-default dis-btn-sm', styles.clearBtn)} onClick={() => clear(form)}>
                <span>Clear</span>
                <i className='material-icons dis-btn-icon'>clear</i>
              </button>
              <button type='submit' className='dis-btn dis-btn-success dis-btn-sm'>
                <span>Search</span>
                <i className='material-icons dis-btn-icon'>search</i>
              </button>
            </div>
          </div>
        </form>
      )}
    </Form>
  )
}

ResourcesSearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  resourceTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    shortCode: PropTypes.string.isRequired
  })).isRequired,
  handleExportResources: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  resourcesExporting: PropTypes.bool.isRequired
}
