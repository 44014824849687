import { loader } from 'graphql.macro'
import { intercomConnection } from '../../intercomConnection'

export default async function assignTicketToUser ({ dataLoaders, variables }) {
  const schema = loader('./updateTicketById.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, { input: variables })

  intercomConnection.trackEvent('ticket_assigned', {})

  return response.updateTicketById
}
