import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, useLocation } from 'react-router-dom'

export default function RedirectQueryString ({ from, to, exact = true }) {
  const location = useLocation()
  return (
    <Redirect
      from={from}
      to={location.search ? `${to}${location.search}` : to}
      exact={exact}
    />
  )
}

RedirectQueryString.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  exact: PropTypes.bool
}
