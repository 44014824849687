import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Spinner from 'react-spinkit'
import styles from './index.module.scss'
import QUERY_KEYS from '../../graphql/queryKeys'
import wastepayGetCustomerCardsQuery from '../../graphql/queries/wastePayGetCustomerCards'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import useQuery from '../../hooks/useQuery'
import { leadingZero, buildCardName } from '../../utilities/stringUtilities'
import Modal from '../modal/Modal'
import ManageCcCardModal from '../manage-cc-card-modal'

export default function ClientWastePayCards ({
  clientId,
  clientName,
  hauler
}) {
  const [showManageCardsModal, setShowManageCardsModal] = useState(false)

  const { data: { wastepayGetCustomerCards: clientCards } = {}, isFetching: cardListFetching, refetch: refetchCardList } = useQuery(
    [QUERY_KEYS.wastepayCustomerCardList, { clientId }],
    wastepayGetCustomerCardsQuery,
    {
      enabled: true,
      onError (err) {
        captureErrorAndNotify(err)
      },
      placeholderData: { wastepayGetCustomerCards: [] }
    })

  function handleEditClick () {
    setShowManageCardsModal(true)
  }

  function handleCloseModal () {
    setShowManageCardsModal(false)
  }

  return (cardListFetching
    ? (
      <Spinner name='circle' className={cn(styles.qboListItem, styles.cardSpinner)} fadeIn='none' />
      )
    : (
      <>
        <Modal
          className={styles.manageCardsModalContainer}
          isOpen={showManageCardsModal}
          closeModal={handleCloseModal}>
          <ManageCcCardModal
            clientName={clientName}
            clientId={clientId}
            cardList={clientCards}
            close={handleCloseModal}
            countryCode={hauler.country}
            allowAddCard={true}
            refetchCardList={refetchCardList}
          />
        </Modal>
        <div className={cn(styles.qboListItem, 'dis-list-item')}>
          <div>
            <p className={styles.detailType}>Payment Methods</p>
            { clientCards.map((cc, ind) => (
              <p
                key={ind}
                className={cn(styles.cardListItem, styles.detailsInfo)}>
                {buildCardName(cc)} | Exp {leadingZero(cc.cardExpirationMonth)}/{leadingZero(cc.cardExpirationYear)}
              </p>
            ))}
          </div>
          <button
            onClick={handleEditClick}
            className={cn(styles.qboBtn, 'dis-btn dis-btn-link')}>
            Add/Edit Cards
          </button>
        </div>
      </>
      ))
}

ClientWastePayCards.propTypes = {
  clientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  clientName: PropTypes.string.isRequired,
  hauler: PropTypes.shape({
    country: PropTypes.string.isRequired
  })
}
