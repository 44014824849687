export const NEW_PUSHER_CLIENT_DATA = 'NEW_PUSHER_CLIENT_DATA'
export const NEW_PUSHER_JOB_DATA = 'NEW_PUSHER_JOB_DATA'
export const NEW_PUSHER_TICKET_DATA = 'NEW_PUSHER_TICKET_DATA'
export const NEW_PUSHER_USER_DATA = 'NEW_PUSHER_USER_DATA'
export const NEW_PUSHER_RESOURCE_DATA = 'NEW_PUSHER_ACTIVITY_DATA'
export const NEW_PUSHER_SERVICE_REQUEST_DATA = 'NEW_PUSHER_SERVICE_REQUEST_DATA'

export const PUSHER_CONNECTED = 'PUSHER_CONNECTED'
export const PUSHER_DISCONNECTED = 'PUSHER_DISCONNECTED'

const PUSHER_ACTIONS = {
  SET_PUSHER: 'PUSHER/SET_PUSHER',
  SET_CHANNELS: 'PUSHER/SET_CHANNELS'
}

export default PUSHER_ACTIONS
