import React from 'react'
import PropTypes from 'prop-types'

export default function ExistingSiteTypeList ({ siteTypes }) {
  if (!siteTypes || siteTypes.length === 0) return null
  return (<div>
    <h4>Existing Site Types</h4>
    <ul className='list-group'>
      {
        siteTypes.map(rt => (
          <li key={rt.id} className='list-group-item'>
            {rt.name}
          </li>
        ))
      }
    </ul>
  </div>)
}

ExistingSiteTypeList.propTypes = {
  siteTypes: PropTypes.array.isRequired
}
