import React, { useEffect } from 'react'
import { Spinner } from './shared/Spinner'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ROUTES } from '../routes'
import useMutation from '../hooks/useMutation'
import disconnectQuickbooksMutation from '../graphql/mutations/disconnectQuickbooks'
import { captureErrorAndNotify } from '../utilities/errorHandlers'
import USER_ACTIONS from '../store/user/userActions'

export default function QuickbooksDisconnectPage () {
  const history = useHistory()
  const dispatch = useDispatch()
  const { user } = useSelector(({ user }) => ({ user: user.user }))

  const { mutate: disconnectQuickbooks } = useMutation(disconnectQuickbooksMutation, {
    onSuccess () {
      dispatch({ type: USER_ACTIONS.UPDATE_HAULER, payload: { hauler: { quickbooks: { isConnected: false } } } })
      history.push(ROUTES.quickbooksSettings)
    },
    onError (error) {
      captureErrorAndNotify(error, 'Failed to disconnect QuickBooks')
      history.push(ROUTES.quickbooksSettings)
    }
  })

  useEffect(function onInitialRender () {
    disconnectQuickbooks({ haulerId: user.haulerId })
  }, [user.haulerId, disconnectQuickbooks])

  return <Spinner isFetching />
}
