import { loader } from 'graphql.macro'

export default async function completeResourceImport ({ dataLoaders, variables }) {
  const schema = loader('./completeResourceImport.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      resourceImportId: variables.resourceImportId
    }
  })
}
