import React from 'react'
import { Link } from 'react-router-dom'
import NormalLayoutContainer from './shared/NormalLayoutContainer'
import ResourceImportsTable from '../components/import/ResourceImportsTable'
import { ObjectParam, useQueryParams } from 'use-query-params'
import useQuery from '../hooks/useQuery'
import QUERY_KEYS from '../graphql/queryKeys'
import { captureErrorAndNotify } from '../utilities/errorHandlers'
import { Spinner } from './shared/Spinner'
import { ROUTES } from '../routes'
import CursorPagination from '../components/cursor-pagination'
import resourceImportsQuery from '../graphql/queries/resourceImports'

const pageSize = 50

export default function ResourceImportsPage () {
  const [queryParams, setQueryParams] = useQueryParams({ pager: ObjectParam })

  const { data: { resourceImports }, isFetching: isFetchingResourceImports } = useQuery(
    [QUERY_KEYS.resourceImports, {
      first: queryParams.pager?.direction === undefined || queryParams.pager?.direction === 'after' ? pageSize : undefined,
      last: queryParams.pager?.direction === 'before' ? pageSize : undefined,
      after: queryParams.pager?.direction === 'after' ? queryParams.pager?.cursor : undefined,
      before: queryParams.pager?.direction === 'before' ? queryParams.pager?.cursor : undefined
    }],
    resourceImportsQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Failed to retrieve data')
      },
      placeholderData: { resourceImports: {} }
    })

  function handlePageRequest (direction, cursor) {
    setQueryParams({ pager: { direction, cursor } })
  }

  return (
    <NormalLayoutContainer>
      {isFetchingResourceImports
        ? <Spinner isFetching />
        : (<>
          <div className='flex justify-between items-end'>
            <h3 className='mt-20'>Previous Uploads and Imports</h3>
            <Link to={`${ROUTES.resources}?importModalOpen=1`}>
              <button className='btn btn-lg btn-primary'>
                <span className='hidden-xs pull-left'>New Import</span>
                <i className='material-icons pull-right thumbster-button-icon no-margin-bs-xs'>publish</i>
              </button>
            </Link>
          </div>

          <div className='panel panel-default row mt-12'>
            <div className='panel-body'>
              <ResourceImportsTable resourceImports={resourceImports.nodes} />
              <CursorPagination
                onPageRequest={handlePageRequest}
                pageInfo={resourceImports.pageInfo}
                totalCount={resourceImports.totalCount}
              />
            </div>
          </div>
        </>)
      }
    </NormalLayoutContainer>
  )
}
