import React, { useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import cloudinary from 'cloudinary-core/cloudinary-core-shrinkwrap'
import PropTypes from 'prop-types'
import cn from 'classnames'
import * as InlineSpinner from 'react-spinkit'
import { Field, Form } from 'react-final-form'
import { Spinner } from '../shared/Spinner.js'
import createCustomerInvoice from '../../graphql/mutations/createCustomerInvoice'
import wastepayChargeCardMutation from '../../graphql/mutations/wastepayChargeUnsavedCardUnauthenticated'
import invoiceByUUIDQuery from '../../graphql/queries/invoiceByUUID'
import QUERY_KEYS from '../../graphql/queryKeys'
import useMutation from '../../hooks/useMutation'
import useNonAuthQuery from '../../hooks/useNonAuthQuery'
import styles from './index.module.scss'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import { US_STATES_ABBR, CA_PROVINCE_ABBR } from '../../utilities/constants'
import dayjs from '../../utilities/dayjs'
import { commaDeliminate } from '../../utilities/stringUtilities.js'
import { validate } from './utils.js'
import ElavonLightboxForm from '../../components/elavon-lightbox-form'
import { cardBrandToEnum } from '../../utilities/card'

export default function CustomerInvoicePage () {
  const { id: uuid } = useParams()
  const [cl] = useState(cloudinary.Cloudinary.new({ cloud_name: 'thumbster' }))
  const [showCardInput, setShowCardInput] = useState(false)

  const { data: invoiceData, isLoading } = useNonAuthQuery(
    [QUERY_KEYS.invoiceByUUID, uuid],
    uuid,
    invoiceByUUIDQuery,
    {
      enabled: true,
      onError (error) {
        captureErrorAndNotify(error, 'Failed to retrieve invoice data')
      }
    })

  const { mutate: printInvoice, isLoading: isPrintingInvoice } = useMutation(createCustomerInvoice, {
    onSuccess ({ link }) {
      return window.open(link, '_blank', 'noopener,noreferrer')
    },
    onError (error) {
      return captureErrorAndNotify(error, 'Error generating invoice PDF.', 6)
    }
  })

  const dueStatus = useMemo(() => {
    if (!invoiceData || !invoiceData?.invoiceByUuid?.invoice?.paymentTerms) {
      return 'Due Upon Receipt'
    }

    if (['DUE_UPON_RECEIPT', 'SPECIAL_TERMS'].includes(invoiceData.invoiceByUuid.invoice.paymentTerms)) {
      return 'Due Upon Receipt'
    }

    return `Due on ${dayjs(invoiceData.invoiceByUuid.invoice.dueDate).format('MMMM D, YYYY')}`
  }, [invoiceData])

  const image = useMemo(() => {
    if (!cl.url || !invoiceData) return null

    const src = cl.url(invoiceData.invoiceByUuid.invoiceSettings.logoPublicId, {
      flags: 'attachment',
      crop: 'fit',
      fetch_format: 'jpg',
      quality: 'auto'
    })

    if (!src) return null

    return ({
      src
    })
  }, [cl, invoiceData])

  function handlePreviewInvoiceClick () {
    printInvoice({ invoiceID: uuid })
  }

  function handleSubmit (values) {
    setShowCardInput(true)
  }

  function reset () {
    setShowCardInput(false)
  }

  function showStuff () {
    if (invoiceData.invoiceByUuid.invoice.total <= 0) {
      return (
        <ErrorPanelComponent
          errorMessage='Invoice link no longer valid.'
          invoiceSettings={invoiceData.invoiceByUuid.invoiceSettings}
        />
      )
    }

    if (invoiceData.invoiceByUuid.invoice.status === 'PAID' || invoiceData.invoiceByUuid.invoice.outstandingBalance === 0) {
      return (
        <SuccessPanelComponent
          message={`Invoice ${invoiceData.invoiceByUuid.invoice.dispatcherInvoiceNumber} Already Paid`}
          invoice={invoiceData.invoiceByUuid.invoice}
        />
      )
    }

    if (invoiceData.invoiceByUuid.invoice.status !== 'OPEN') {
      return (
        <ErrorPanelComponent
          errorMessage='Invoice link no longer valid.'
          invoiceSettings={invoiceData.invoiceByUuid.invoiceSettings}
        />
      )
    }

    return (
      <>
        <div className={cn('dis-panel dis-panel-body', styles.pageSection)}>
          <h3>
            Summary
          </h3>
          <div className={cn(styles.summaryRow)}>
            <p>
              Invoice #{invoiceData.invoiceByUuid.invoice.dispatcherInvoiceNumber}
              { isPrintingInvoice
                ? (
                  <InlineSpinner
                    className={cn(styles.spinner, styles.buttonPadding)}
                    name='circle'
                    fadeIn='none'
                  />
                  )
                : (
                  <button
                    className={cn('dis-btn-link', styles.buttonPadding)}
                    type='button'
                    onClick={handlePreviewInvoiceClick}>
                    View Details
                  </button>
                  )
                }
            </p>
            <p>
              Amount due: ${commaDeliminate(invoiceData.invoiceByUuid.invoice.outstandingBalance.toFixed(2))}
            </p>
          </div>
          <div className={cn(styles.summaryRow)}>
            <p>
              Issued on {
                  invoiceData.invoiceByUuid.invoice.date
                    ? dayjs(invoiceData.invoiceByUuid.invoice.date).format('MMM D, YYYY')
                    : dayjs(invoiceData.invoiceByUuid.invoice.createdAt).format('MMM D, YYYY')
                }
            </p>
            <p>
              {invoiceData.invoiceByUuid.invoice.dueDate && dueStatus}
            </p>
          </div>
        </div>
        { (invoiceData.invoiceByUuid.invoice.customerCanPay && invoiceData.invoiceByUuid.invoice.includePayNowLink) && (
          <Form
            onSubmit={handleSubmit}
            component={InternalForm}
            invoice={invoiceData.invoiceByUuid.invoice}
            invoiceSettings={invoiceData.invoiceByUuid.invoiceSettings}
            validate={validate}
            resetStatus={reset}
            showCardInput={showCardInput}
            uuid={uuid}
          />
        )}
      </>
    )
  }

  return (
    isLoading
      ? (<Spinner isFetching />)
      : (
        <>
          <header className={cn('dis-flex-page-title', styles.headerPadding)}>
            <h1 className={cn(styles.headerText)}>
              {invoiceData.invoiceByUuid.invoiceSettings.companyName}
            </h1>
            { image && (
              <img
                className={styles.logoImg} src={image.src}
                alt={`${invoiceData.invoiceByUuid.invoiceSettings.companyName} Logo`}
              />
            )}
          </header>
          <section className={cn(styles.pageWrapper)}>
            {showStuff()}
            <div className={cn(styles.wpImgWrapper)}>
              <img src='https://www.dispatcher.com/images/WP-Secure-Payments.svg' alt='Waste Pay secure payments logo' />
            </div>
          </section>
        </>
        )

  )
}

function InternalForm ({
  handleSubmit,
  form,
  submitting,
  values,
  invoice,
  invalid,
  invoiceSettings,
  showCardInput,
  resetStatus,
  uuid
}) {
  const [cardError, setCardError] = useState([])
  const [paymentHandled, setPaymentHandled] = useState(false)
  const [transactionNumber, setTransactionNumber] = useState(null)

  // get this from the invoice data at some point. WastePay isn't in Canada yet, so not going to sweat it
  const isUS = true
  const stateList = isUS ? US_STATES_ABBR : CA_PROVINCE_ABBR

  function hasError (meta) {
    return meta.touched && meta.error && !meta.active
  }

  const { mutate: chargeCard } = useMutation(wastepayChargeCardMutation, {
    onSuccess ({ transactionId }) {
      setTransactionNumber(transactionId)
    },
    onError () {
      setCardError(['error'])
    },
    onSettled () {
      setPaymentHandled(true)
    }
  })

  function formatEmailList (formValues) {
    return [{
      email: formValues.email,
      name: `${formValues.firstName} ${formValues.lastName}`
    }]
  }

  async function handleCardProcessing (data) {
    setCardError([])

    return chargeCard({
      amount: invoice?.outstandingBalance,
      invoiceUuid: uuid,
      description: data.description, // the heck is this?
      sendTo: formatEmailList(data.billingAddress),
      addressLine1: data.billingAddress.streetAddress,
      city: data.billingAddress.city,
      state: data.billingAddress.state,
      zip: data.billingAddress.zip,
      countryCode: isUS ? 'US' : 'CA',
      cardBrand: cardBrandToEnum(data.cardShortDescription),
      cardLastFour: data.cardLastFour,
      expirationMonth: data.cardExpirationMonth,
      expirationYear: data.cardExpirationYear,
      cardType: data.cardType,
      cardToken: data.token
    })
  }

  if (paymentHandled && !cardError.length) {
    return (
      <SuccessPanelComponent
        transactionNumber={transactionNumber}
        invoice={invoice}
      />
    )
  }
  if (paymentHandled && cardError.length) {
    return (
      <ErrorPanelComponent
        errorMessage='Error Processing Payment'
        invoiceSettings={invoiceSettings}
      />
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <section className={cn('dis-panel dis-panel-body', styles.pageSection)}>
        <h3>
          Pay Invoice
        </h3>
        <h4>
          Contact Information
        </h4>
        <div className={cn(styles.formRow)}>
          <Field name='firstName'>
            {({ input, meta }) => (
              <div className={cn({ 'has-error': hasError(meta) }, styles.halfWidthInput)}>
                <label>
                  First Name
                  <input
                    {...input}
                    className='form-control'
                    type='text'
                  />
                </label>
                {hasError(meta) && <h6>{meta.error}</h6>}
              </div>
            )}
          </Field>
          <Field name='lastName'>
            {({ input, meta }) => (
              <div className={cn({ 'has-error': hasError(meta) }, styles.halfWidthInput)}>
                <label>
                  Last Name
                  <input
                    {...input}
                    className='form-control'
                    type='text'
                  />
                </label>
                {hasError(meta) && <h6>{meta.error}</h6>}
              </div>
            )}
          </Field>
        </div>
        <div className={cn(styles.formRow)}>
          <Field name='email'>
            {({ input, meta }) => (
              <div className={cn({ 'has-error': hasError(meta) }, styles.fullWidthInput)}>
                <label>
                  Email for Receipt
                  <input
                    {...input}
                    className='form-control'
                    type='text'
                  />
                </label>
                {hasError(meta) && <h6>{meta.error}</h6>}
              </div>
            )}
          </Field>
        </div>
        <h4>
          Billing Information
        </h4>
        <div className={cn(styles.formRow)}>
          <Field name='streetAddress'>
            {({ input, meta }) => (
              <div className={cn({ 'has-error': hasError(meta) }, styles.fullWidthInput)}>
                <label>
                  Street Address
                  <input
                    {...input}
                    className='form-control'
                    type='text'
                  />
                </label>
                {hasError(meta) && <h6>{meta.error}</h6>}
              </div>
            )}
          </Field>
        </div>
        <div className={cn(styles.formRow, styles.formRowThird)}>
          <Field name='city'>
            {({ input, meta }) => (
              <div className={cn({ 'has-error': hasError(meta) })}>
                <label>
                  City
                  <input
                    {...input}
                    className='form-control'
                    type='text'
                  />
                </label>
                {hasError(meta) && <h6>{meta.error}</h6>}
              </div>
            )}
          </Field>
          <Field name='state'>
            {({ input, meta }) => (
              <div className={cn({ 'has-error': hasError(meta) })}>
                <label>
                  {isUS ? 'State' : 'Province'}
                  <select
                    {...input}
                    className='form-control'>
                    <option />
                    { stateList.map((v, ind) => (
                      <option value={v} key={ind}>{v}</option>
                    ))}
                  </select>
                </label>
                {hasError(meta) && <h6>{meta.error}</h6>}
              </div>
            )}
          </Field>
          <Field name='zip'>
            {({ input, meta }) => (
              <div className={cn({ 'has-error': hasError(meta) })}>
                <label>
                  {isUS ? 'Zip' : 'Postal'} Code
                  <input
                    {...input}
                    className='form-control'
                    type='text'
                  />
                </label>
                {hasError(meta) && <h6>{meta.error}</h6>}
              </div>
            )}
          </Field>
        </div>
      </section>

      <section className={cn('dis-panel dis-panel-body', styles.pageSection)}>
        <h4 className={cn(styles.isTop, styles.headerInline)}>
          Card Details
          <img src='https://www.dispatcher.com/images/credit-card-logos.png' alt='Credit Card Logos' className={styles.ccImg} />
        </h4>
        {!showCardInput
          ? (
            <button disabled={invalid || submitting} type='submit' className={cn('dis-btn dis-btn-primary dis-btn-lg', styles.submitBtn)}>
              Add Payment Method
            </button>
            )
          : (
            <ElavonLightboxForm
              handleSubmit={handleCardProcessing}
              billingAddress={values}
              client={{
                id: invoice.clientId
              }}
              isCreatingCard={false}
              setCardError={setCardError}
              allowCreateCard={false}
              uuid={uuid}
              hideCards={true}
              buttonText='PAY INVOICE'
            />
            )
        }
      </section>
    </form>
  )
}

InternalForm.propTypes = {
  /**
   * https://final-form.org/docs/react-final-form/types/FormRenderProps
   */
  handleSubmit: PropTypes.func.isRequired,
  /**
   * https://final-form.org/docs/react-final-form/types/FormRenderProps
   */
  form: PropTypes.shape({
    /**
     * https://final-form.org/docs/final-form/types/FormApi#change
     */
    getState: PropTypes.any
  }),
  /**
   * https://final-form.org/docs/final-form/types/FormState#submitting
   */
  invalid: PropTypes.bool.isRequired,
  /**
     * https://final-form.org/docs/final-form/types/FormState#submitting
     */
  submitting: PropTypes.bool.isRequired,
  /**
     * https://final-form.org/docs/final-form/types/FormState#initialvalues
     */
  values: PropTypes.object.isRequired,
  showCardInput: PropTypes.bool.isRequired,
  invoice: PropTypes.shape({
    dispatcherInvoiceNumber: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    clientId: PropTypes.string.isRequired,
    outstandingBalance: PropTypes.number.isRequired
  }),
  invoiceSettings: PropTypes.shape({
    companyName: PropTypes.string.isRequired,
    prettyPhone: PropTypes.string.isRequired
  }),
  resetStatus: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired
}

export function ErrorPanelComponent ({
  errorMessage,
  invoiceSettings
}) {
  return (<section className={cn('dis-panel dis-panel-body', styles.pageSection, styles.paymentErrorWrapper)}>
    <i className={cn(styles.paymentStatusCheck, styles.errorIcon, 'material-icons dis-btn-icon')}>error</i>
    <h3>
      {errorMessage}
    </h3>
    { invoiceSettings && (
      <p>
        Please contact {invoiceSettings.companyName} at {invoiceSettings.prettyPhone}.
      </p>
    )}
  </section>)
}

ErrorPanelComponent.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  invoiceSettings: PropTypes.shape({
    companyName: PropTypes.string.isRequired,
    prettyPhone: PropTypes.string.isRequired
  })
}

export function SuccessPanelComponent ({
  transactionNumber = '',
  message = 'Payment Successful',
  invoice
}) {
  return (
    <section className={cn('dis-panel dis-panel-body', styles.pageSection, styles.paymentSuccessWrapper)}>
      <i className={cn(styles.paymentStatusCheck, 'material-icons dis-btn-icon')}>check_circle</i>
      <h3>
        {message}
      </h3>
      <p>
        Amount Paid: ${commaDeliminate(invoice.total.toFixed(2))}
      </p>
      { transactionNumber &&
        (
          <p>
            Transaction Number: {transactionNumber}
          </p>
        )
      }
    </section>
  )
}

SuccessPanelComponent.propTypes = {
  transactionNumber: PropTypes.string,
  message: PropTypes.string,
  invoice: PropTypes.shape({
    total: PropTypes.number.isRequired,
    dispatcherInvoiceNumber: PropTypes.string.isRequired
  }).isRequired
}
