import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import NewRequestTicket from './NewRequestTicket'
export default function NewRequestDetails ({
  toggleDetails,
  request,
  buttonView
}) {
  return (
    <div className='bg-white h-full w-full absolute top-0 left-0 z-50 overflow-y-auto'>
      <div className='bg-gray-light font-size-1-5em p-4'>
        <button
          className='mr-2'
          onClick={() => toggleDetails(false)}>
          <i className='material-icons relative top-2'>arrow_back</i>
        </button>
        <span>{`${request.ticketTypeName} (${request.tickets.length}) on `}</span>
        <span className='font-medium'>{dayjs(request.serviceDate).format('M/D/YYYY')}</span>
      </div>
      <div>
        <div className='flex flex-row flex-wrap px-8 mt-4'>
          <div className='w-full flex text-gray'>
            Account
          </div>
          <div className='w-full flex leading-6 mb-4'>
            {request.accountName}
          </div>
          <div className='w-full text-gray'>Address</div>
          <div className='w-full leading-6 mb-4'>
            <span>{request.addressline1}, {request.city}, {request.state} {request.zip}</span>
            {request.addressDetails && <span>{`(${request.addressDetails})`}</span>}
          </div>
        </div>
      </div>
      <div className='mt-8 pr-8'>
        {buttonView()}
      </div>
      <div className='text-center font-medium font-size-1-5em pt-6 pb-4 border-b border-gray-light'>
        {`Tickets (${request.tickets.length})`}
      </div>
      {request.tickets.map(ticket => <NewRequestTicket key={ticket.id} ticket={ticket} />)}
    </div>
  )
}

NewRequestDetails.propTypes = {
  request: PropTypes.object.isRequired,
  toggleDetails: PropTypes.func.isRequired,
  buttonView: PropTypes.func.isRequired
}
