class IntercomConnection {
  constructor () {
    // Yeah, so intercom doesn't have an API to tell us whether it's loaded
    this.booted = false
  }

  intercomOptions (userId, haulerId, haulerName, userName) {
    return {
      user_id: userId,
      name: userName,
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      company: {
        id: haulerId,
        name: haulerName
      }
    }
  }

  connectToIntercom (userId, haulerId, haulerName) {
    window.Intercom('boot', this.intercomOptions(userId, haulerId, haulerName))
    this.booted = true
  }

  disconnectFromIntercom () {
    window.Intercom('shutdown')
    this.booted = false
  }

  triggerIntercom () {
    window.Intercom('showNewMessage')
  }

  setUserId (userId, haulerId, haulerName, userName) {
    if (this.booted) {
      window.Intercom('update', this.intercomOptions(userId, haulerId, haulerName, userName))
    } else {
      this.connectToIntercom(userId, haulerId, haulerName, userName)
    }
  }

  trackEvent (name, params) {
    window.Intercom('trackEvent', name, params)
  }
}

export const intercomConnection = new IntercomConnection()
