export const baseCounts = {
  open: 0,
  en_route: 0,
  completed: 0,
  cancelled: 0
}

export const options = {
  maintainAspectRatio: true,
  tooltips: {
    mode: 'y-axis'
  },
  scales: {
    yAxes: [
      {
        stacked: true
      }
    ],
    xAxes: [
      {
        ticks: { stepSize: 1, min: 0 },
        stacked: true
      }
    ]
  }
}

export const determineTicketsByTicketType = (tickets) => (
  tickets.reduce((acc, ticket) => {
    const ticketType = ticket.ticket_type.name
    if (!acc[ticketType]) {
      acc[ticketType] = {
        ...baseCounts,
        ticket_type: ticket.ticket_type
      }
    }
    acc[ticketType][ticket.status]++
    return acc
  }, {})
)

export const determineData = (keys, ticketsByTicketType) => (
  {
    labels: keys,
    datasets: [
      {
        label: 'Open',
        data: keys.map((key) => ticketsByTicketType[key].open),
        backgroundColor: '#33ca80',
        borderColor: '#33ca80',
        borderWidth: 0
      },
      {
        label: 'En Route',
        data: keys.map((key) => ticketsByTicketType[key].en_route),
        backgroundColor: '#FF8F00',
        borderColor: '#FF8F00',
        borderWidth: 0
      },
      {
        label: 'Completed',
        data: keys.map((key) => ticketsByTicketType[key].completed),
        backgroundColor: '#131514',
        borderColor: '#131514',
        borderWidth: 0
      },
      {
        label: 'Cancelled',
        data: keys.map((key) => ticketsByTicketType[key].cancelled),
        backgroundColor: '#f93b59',
        borderColor: '#f93b59',
        borderWidth: 0
      }
    ]
  }
)
