import { commaDeliminate } from '../../utilities/stringUtilities'

export const calculateSubtotalOnUpdate = (ticketFeeTotal, discountMethod, discountAmount, updatedDiscountAmount) => {
  if (discountMethod === 'PERCENT_ENTERED') {
    return ticketFeeTotal - updatedDiscountAmount
  } else if (discountMethod === 'AMOUNT_ENTERED') {
    return ticketFeeTotal - discountAmount
  }

  return ticketFeeTotal
}

export const updateDiscountAndTax = (form, ticketFeeTotal) => {
  const state = form.getState()
  const values = state.values
  const { discountAndTax: { discountAmount, discountPercent, discountMethod, taxAmount, taxPercent, taxMethod } } = values
  const updatedDiscountAmount = calculateAmount(ticketFeeTotal, discountPercent)
  const updatedDiscountPercent = calculatePercent(discountAmount, ticketFeeTotal)
  const subtotal = calculateSubtotalOnUpdate(ticketFeeTotal, discountMethod, discountAmount, updatedDiscountAmount)

  form.batch(() => {
    if (discountAmount && discountPercent) {
      if (discountMethod === 'PERCENT_ENTERED') {
        form.change('discountAndTax.discountAmount', updatedDiscountAmount)
      } else if (discountMethod === 'AMOUNT_ENTERED') {
        form.change('discountAndTax.discountPercent', updatedDiscountPercent)
      }
    }

    if (taxAmount && taxPercent) {
      const updatedTaxAmount = calculateAmount(subtotal, taxPercent)
      if (taxMethod === 'PERCENT_ENTERED') {
        form.change('discountAndTax.taxAmount', updatedTaxAmount)
      } else if (taxMethod === 'AMOUNT_ENTERED') {
        const updatedTaxPercent = calculatePercent(taxAmount, subtotal)
        form.change('discountAndTax.taxPercent', updatedTaxPercent)
      }
    }
  })
}

export const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 3,
  maximumFractionDigits: 3
})

export const formatValue = (value) => {
  if (value && value >= 0) {
    return value
  }
  return null
}

const calculatePercent = (value, total) => {
  return formatter.format((value / total) * 100)
}

export const calculateAmount = (value, total) => {
  return parseFloat(total * (value / 100)).toFixed(2)
}

export const removeDiscount = (form) => {
  form.batch(() => {
    form.change('discountAndTax.discountAmount', null)
    form.change('discountAndTax.discountPercent', null)
    form.change('discountAndTax.discountMethod', null)
    form.change('showDiscountForm', false)
  })
}

export const removeTax = (form) => {
  form.batch(() => {
    form.change('discountAndTax.taxAmount', null)
    form.change('discountAndTax.taxPercent', null)
    form.change('discountAndTax.taxMethod', null)
    form.change('discountAndTax.qboTaxCodeId', null)
    form.change('showTaxForm', false)
  })
}

export const handleOnChangeDiscountAmount = (value, ticketFeeTotal, form) => {
  const formattedValue = formatValue(value)
  const discountPercent = calculatePercent(formattedValue, ticketFeeTotal)
  form.batch(() => {
    form.change('discountAndTax.discountAmount', formattedValue)
    form.change('discountAndTax.discountPercent', discountPercent)
    form.change('discountAndTax.discountMethod', 'AMOUNT_ENTERED')
  })
  updateDiscountAndTax(form, ticketFeeTotal)
}

export const handleOnChangeDiscountPercent = (value, ticketFeeTotal, form) => {
  const formattedValue = formatValue(value)
  const discountAmount = calculateAmount(ticketFeeTotal, formattedValue)

  form.batch(() => {
    form.change('discountAndTax.discountPercent', formattedValue)
    form.change('discountAndTax.discountAmount', commaDeliminate(discountAmount))
    form.change('discountAndTax.discountMethod', 'PERCENT_ENTERED')
  })
  updateDiscountAndTax(form, ticketFeeTotal)
}

export const handleOnChangeTaxAmount = (value, ticketFeeTotal, form, isConnectedToQB, usingAST) => {
  const discountTotal = ticketFeeTotal - form.getState().values.discountAndTax.discountAmount
  const formattedValue = formatValue(value)
  const taxPercent = calculatePercent(formattedValue, discountTotal)

  form.batch(() => {
    form.change('discountAndTax.taxAmount', formattedValue)
    if (!isConnectedToQB || (isConnectedToQB && usingAST)) {
      form.change('discountAndTax.taxPercent', taxPercent)
      form.change('discountAndTax.taxMethod', 'AMOUNT_ENTERED')
    }
  })
}

export const handleOnChangeTaxPercent = (value, ticketFeeTotal, form) => {
  const discountTotal = ticketFeeTotal - (form?.getState()?.values?.discountAndTax.discountAmount || 0)
  const formattedValue = formatValue(value)
  const taxAmount = calculateAmount(discountTotal, formattedValue)
  form.batch(() => {
    form.change('discountAndTax.taxPercent', formattedValue)
    form.change('discountAndTax.taxAmount', commaDeliminate(taxAmount))
    form.change('discountAndTax.taxMethod', 'PERCENT_ENTERED')
  })
}

export const handleOnChangeTaxCode = (value, total, form, taxCodes) => {
  const taxCodeValue = taxCodes.find(rate => rate.id === value)
  form.batch(() => {
    form.change('discountAndTax.qboTaxCodeId', value)
    handleOnChangeTaxPercent(taxCodeValue?.value, total, form)
  })
}

export const formatDiscountAndTaxValues = (amount, percent, method) => {
  const formattedAmount = (!percent || parseFloat(percent) <= 0) ? null : parseFloat(amount)
  const formattedPercent = (!amount || parseFloat(amount) <= 0) ? null : parseFloat(percent)
  const formattedMethod = (!formattedAmount || !formattedPercent) ? null : method
  return ({
    formattedAmount,
    formattedPercent,
    formattedMethod
  })
}
