import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { stateToAbbreviation, buildAddressComponents } from '../../../utilities/locationUtilities'

const countryToAbbr = {
  'United States': 'US',
  Canada: 'CA'
}

export default function AutocompleteAddressInput ({
  initialValue,
  onLocationChange,
  inputId,
  input,
  placeholder = ''
}) {
  const [autocomplete, setAutocomplete] = useState(null)
  const autocompleteRef = useRef(null)

  useEffect(function onInitialRender () {
    if (autocompleteRef && autocompleteRef.current && !autocomplete) {
      const newAutocomplete = new google.maps.places.Autocomplete(autocompleteRef.current, {
        componentRestrictions: {
          country: ['us', 'ca']
        }
      })
      setAutocomplete(newAutocomplete)

      newAutocomplete.addListener('place_changed', () => {
        const place = newAutocomplete.getPlace()
        const addrComponents = buildAddressComponents(place)
        const streetAddress = `${addrComponents.street_number || ''} ${addrComponents.route || ''}`
        onLocationChange({
          place_id: place.place_id,
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
          addressline1: streetAddress,
          city: addrComponents.locality ||
            addrComponents.sublocality_level_1 ||
            addrComponents.administrative_area_level_3 ||
            addrComponents.neighborhood,
          state: stateToAbbreviation(addrComponents.administrative_area_level_1),
          zip: addrComponents.postal_code || addrComponents.postal_code_prefix,
          country: countryToAbbr[addrComponents.country]
        })
        autocompleteRef.current.value = streetAddress
      })
    }
  }, [autocomplete, onLocationChange])

  delete input.defaultValue
  delete input.value

  return (
    <input
      ref={autocompleteRef}
      id={inputId}
      className='form-control'
      type='text'
      placeholder={placeholder}
      defaultValue={initialValue}
      autoComplete='off'
      {...input}
    />
  )
}

AutocompleteAddressInput.propTypes = {
  initialValue: PropTypes.string,
  onLocationChange: PropTypes.func.isRequired,
  inputId: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string
}
