export const DECLINE_MAP = {
  INVENTORY: 'Inventory',
  SCHEDULING: 'Scheduling',
  SERVICE_AREA: 'Service Area',
  MORE_INFO_REQUIRED: 'More Info Required',
  OTHER_ISSUE: 'Other'
}

export const DECLINE_VALUES = Object.entries(DECLINE_MAP).map(([key, value]) => ({
  value: key,
  label: value
}))

export const STATUSES = {
  PENDING_RESOLUTION: 'Pending Resolution',
  ORDER_CONFIRMED: 'Confirmed',
  UNCONFIRMED: 'Unconfirmed'
}
