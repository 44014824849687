import { put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import moment from 'moment'
import BASE_URI from '../../../utilities/BASE_URI'
import { handleError } from '../../../utilities/handleError'
import { getHeaders } from '../../../utilities/getHeaders'

export const REQUEST_RECENT_TICKETS_ACTION = 'REQUEST_RECENT_TICKETS_ACTION'
export const RECEIVE_RECENT_TICKETS_SUCCESS = 'RECEIVE_RECENT_TICKETS_SUCCESS'
export const RECEIVE_RECENT_TICKETS_FAILURE = 'RECEIVE_RECENT_TICKETS_FAILURE'

function * requestRecentTickets () {
  try {
    const today = moment().format('YYYY-MM-DD')
    const params = Object.assign(
      getHeaders(),
      {
        params: {
          min_date: today,
          max_date: today,
          page: 1,
          per_page: 100000
        }
      }
    )
    const response = yield axios.get(`${BASE_URI}/hauler/tickets`, params)
    yield put({ type: RECEIVE_RECENT_TICKETS_SUCCESS, payload: response.data })
  } catch (e) {
    const errors = handleError(e)
    yield put({ type: RECEIVE_RECENT_TICKETS_FAILURE, payload: { errors } })
  }
}

export default function * recentTicketsSaga () {
  yield takeLatest(REQUEST_RECENT_TICKETS_ACTION, requestRecentTickets)
}
