
import {
  REQUEST_CURRENT_HAULER_TEAM_MEMBER_ACTION,
  RECEIVE_CURRENT_HAULER_TEAM_MEMBER_SUCCESS,
  RECEIVE_CURRENT_HAULER_TEAM_MEMBER_FAILURE,
  REQUEST_UPDATE_CURRENT_HAULER_TEAM_MEMBER_ACTION,
  RECEIVE_UPDATE_CURRENT_HAULER_TEAM_MEMBER_SUCCESS,
  RECEIVE_UPDATE_CURRENT_HAULER_TEAM_MEMBER_FAILURE
} from '../sagas/currentHaulerTeamMemberSaga'

import {
  RECEIVE_HAULER_TEAM_MEMBER_DEACTIVATE_SUCCESS,
  RECEIVE_HAULER_TEAM_MEMBER_ACTIVATE_SUCCESS
} from '../sagas/haulerTeamMemberActivateDeactivateSaga'

const initialState = {
  isFetching: false,
  id: '',
  first_name: '',
  last_name: '',
  email: '',
  pretty_primary_phone: '',
  role: '',
  hauler_id: '',
  deleted_at: '',
  errors: []
}

const currentHaulerTeamMemberReducer = (state = initialState, action) => {
  const { payload, type } = action
  switch (type) {
    case REQUEST_CURRENT_HAULER_TEAM_MEMBER_ACTION:
    case REQUEST_UPDATE_CURRENT_HAULER_TEAM_MEMBER_ACTION:
      return {
        ...state,
        isFetching: true,
        errors: []
      }
    case RECEIVE_CURRENT_HAULER_TEAM_MEMBER_SUCCESS:
    case RECEIVE_UPDATE_CURRENT_HAULER_TEAM_MEMBER_SUCCESS:
    case RECEIVE_HAULER_TEAM_MEMBER_DEACTIVATE_SUCCESS:
    case RECEIVE_HAULER_TEAM_MEMBER_ACTIVATE_SUCCESS:
      return {
        ...state,
        ...payload.user,
        isFetching: false,
        errors: []
      }
    case RECEIVE_CURRENT_HAULER_TEAM_MEMBER_FAILURE:
    case RECEIVE_UPDATE_CURRENT_HAULER_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: payload.errors
      }

    default:
      return state
  }
}

export default currentHaulerTeamMemberReducer
