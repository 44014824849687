import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './availabilityTable.module.scss'
import classNames from 'classnames'
import { loader } from 'graphql.macro'
import dayjs from '../../utilities/dayjs'
import {
  DAYS_OUT,
  GRAPHQL_AVAILABILITY_DELIVERY_DAY,
  NEXT_AVAILABLE_SERVICE,
  MUST_CALL
} from './constants'
import { sort, map, addIndex } from 'kyanite'
import {
  determineNextAvailableService,
  formatTime, availabilitiesProps, applyAvailabilityUpdate
} from './utils'
import AvailabilityTableHeaders from './table-header/AvailabilityTableHeaders'
import CheckboxInput from './inputs/CheckboxInput'
import NumberInput from './inputs/NumberInput'
import CalendarInput from './inputs/CalendarInput'
import { useSelector } from 'react-redux'

const mapWithIndex = addIndex(map)

export function AvailabilityTable ({ availabilities, onChange, haulerId, onError = () => {} }) {
  const [updating, setUpdating] = useState(null)
  const [applyAllUpdating, setApplyAllUpdating] = useState(null)
  const { graphQLClient } = useSelector(({ dataLoaders: { dispatcher: { graphQLClient } } }) => ({ graphQLClient }))

  const formattedAvailabilities = useMemo(() => {
    if (!availabilities) return

    const newDeliveriesWithTitle = map(newDelivery => ({
      ...newDelivery,
      title: newDelivery.size.name
    }), availabilities.newDeliveries)
    const newDeliveriesSortedBySize = sort((a, b) => {
      const aTitle = Number(a.title.split(' ')[0])
      const bTitle = Number(b.title.split(' ')[0])
      return aTitle - bTitle
    }, newDeliveriesWithTitle)
    return {
      newDeliveries: newDeliveriesSortedBySize,
      emptyReturn: [{ ...availabilities.emptyReturn, title: 'Empty & Return' }],
      pickUp: [{ ...availabilities.pickUp, title: 'Pick Up' }],
      aggregate: availabilities.aggregate
    }
  }, [availabilities])

  function updateAvailabilityActiveState (availability, deliveryDay, isActive) {
    setUpdating({ availability, type: deliveryDay })

    const updateAvailabilityActiveStateMutation = loader('../../graphql/mutations/updateAvailabilityActiveState.graphql')
    graphQLClient.request(updateAvailabilityActiveStateMutation, {
      input: {
        deliveryType: availability.deliveryType,
        id: availability.id,
        deliveryDay,
        isActive,
        haulerId
      }
    })
      .then(({ updateAvailabilityActiveState: { availability: updatedAvailability } }) => {
        onChange(applyAvailabilityUpdate(availabilities, updatedAvailability))
        setUpdating(null)
      })
      .catch(error => {
        setUpdating(null)
        onError(error)
      })
  }

  function updateAvailabilityNextAvailableService (availability, nextAvailableService) {
    setUpdating({ availability, type: NEXT_AVAILABLE_SERVICE })

    const updateAvailabilityNextAvailableServiceMutation = loader('../../graphql/mutations/updateAvailabilityNextAvailableService.graphql')
    graphQLClient.request(updateAvailabilityNextAvailableServiceMutation, {
      input: {
        deliveryType: availability.deliveryType,
        id: availability.id,
        nextAvailableService,
        haulerId
      }
    })
      .then(({ updateAvailabilityNextAvailableService: { availability: updatedAvailability } }) => {
        onChange(applyAvailabilityUpdate(availabilities, updatedAvailability))
        setUpdating(null)
      })
      .catch(error => {
        setUpdating(null)
        onError(error)
      })
  }

  function updateAvailabilityDaysOut (availability, daysOut) {
    setUpdating({ availability, type: DAYS_OUT })
    const updateAvailabilityDaysOutMutation = loader('../../graphql/mutations/updateAvailabilityDaysOut.graphql')
    graphQLClient.request(updateAvailabilityDaysOutMutation, {
      input: {
        deliveryType: availability.deliveryType,
        id: availability.id,
        daysOut,
        haulerId
      }
    })
      .then(({ updateAvailabilityDaysOut: { availability: updatedAvailability } }) => {
        onChange(applyAvailabilityUpdate(availabilities, updatedAvailability))
        setUpdating(null)
      })
      .catch(error => {
        setUpdating(null)
        onError(error)
      })
  }

  function updateAvailabilityMustCall (availability, mustCall) {
    setUpdating({ availability, type: MUST_CALL })
    const updateAvailabilityMustCallMutation = loader('../../graphql/mutations/updateAvailabilityMustCall.graphql')
    graphQLClient.request(updateAvailabilityMustCallMutation, {
      input: {
        deliveryType: availability.deliveryType,
        id: availability.id,
        mustCall,
        haulerId
      }
    })
      .then(({ updateAvailabilityMustCall: { availability: updatedAvailability } }) => {
        onChange(applyAvailabilityUpdate(availabilities, updatedAvailability))
        setUpdating(null)
      })
      .catch(error => {
        setUpdating(null)
        onError(error)
      })
  }

  const isDisabled = Boolean(updating || applyAllUpdating)
  function renderAvailabilityType (availabilityType, type) {
    if (type === 'aggregate') return null

    const cutoffTimes = [
      '',
      availabilityType[0] ? formatTime(availabilityType[0].sameDayCutoffTime) : '',
      availabilityType[0] ? formatTime(availabilityType[0].nextDayCutoffTime) : '',
      availabilityType[0] ? formatTime(availabilityType[0].daysOutCutoffTime) : '',
      '-:--',
      availabilityType[0] ? formatTime(availabilityType[0].saturdayCutoffTime) : '',
      availabilityType[0] ? formatTime(availabilityType[0].sundayCutoffTime) : ''
    ]

    return (
      <React.Fragment key={type}>
        {/*
        FIXME multiple <thead/> elements in a table is invalid HTML, leaving it for now as its out of scope for availability.
        https://html.spec.whatwg.org/multipage/tables.html#the-table-element
        */}
        <thead>
          <tr className={styles.header}>
            <th className={styles.borderRight}>Cutoff Time</th>
            {mapWithIndex((cutoffTime, index) => {
              return (
                <th
                  key={index + type}
                  className={classNames({
                    [styles.borderRight]: index === 0 || index === 4
                  })}>
                  {cutoffTime}
                </th>
              )
            }, cutoffTimes)}
          </tr>
        </thead>
        <tbody>
          {map(availability => (
            <tr className={styles.dataRow} key={availability.id}>
              <td className={classNames(styles.borderRight, styles.firstCol)}>
                {availability.title}
              </td>
              <td className={styles.borderRight}>
                <CheckboxInput
                  checked={availability.mustCall}
                  disabled={isDisabled}
                  loading={Boolean(updating &&
                    updating.type === MUST_CALL &&
                    updating.availability.id === availability.id)}
                  labelName={`${availability.title}mustCall`}
                  labelText={`${availability.title} Must Call`}
                  customCheckedIconName='phone'
                  customCheckedClassName={styles.mustCallCheckbox}
                  customCheckedIconClassName={styles.checkedMustCallIcon}
                  customUncheckedIconName='phone_disabled'
                  customUncheckedClassName={styles.uncheckedMustCallCheckbox}
                  customUncheckedIconClassName={styles.uncheckedMustCallIcon}
                  onChange={() => updateAvailabilityMustCall(availability, !availability.mustCall)}
                />
              </td>
              <td>
                <CheckboxInput
                  checked={availability.sameDayActive}
                  disabled={isDisabled}
                  loading={Boolean((updating &&
                  updating.type === GRAPHQL_AVAILABILITY_DELIVERY_DAY.SAME_DAY &&
                  updating.availability.id === availability.id) || applyAllUpdating === GRAPHQL_AVAILABILITY_DELIVERY_DAY.SAME_DAY)}
                  labelName={`${availability.title}sameDayActive`}
                  labelText={`${availability.title} Same Day Active`}
                  onChange={() => updateAvailabilityActiveState(
                    availability,
                    GRAPHQL_AVAILABILITY_DELIVERY_DAY.SAME_DAY,
                    !availability.sameDayActive
                  )}
                />
              </td>
              <td>
                <CheckboxInput
                  checked={availability.nextDayActive}
                  disabled={isDisabled}
                  loading={Boolean((updating &&
                  updating.type === GRAPHQL_AVAILABILITY_DELIVERY_DAY.NEXT_DAY &&
                  updating.availability.id === availability.id) || applyAllUpdating === GRAPHQL_AVAILABILITY_DELIVERY_DAY.NEXT_DAY)}
                  labelName={`${availability.title}nextDayActive`}
                  labelText={`${availability.title} Next Day Active`}
                  onChange={() => updateAvailabilityActiveState(
                    availability,
                    GRAPHQL_AVAILABILITY_DELIVERY_DAY.NEXT_DAY,
                    !availability.nextDayActive
                  )}
                />
              </td>
              <td className={classNames(styles.daysOut, styles.middleColumn)}>
                <NumberInput
                  value={availability.daysOut}
                  disabled={isDisabled}
                  loading={Boolean(updating &&
                  updating.type === DAYS_OUT &&
                  updating.availability.id === availability.id)}
                  labelName={`${availability.title}daysOut`}
                  labelText={`${availability.title} Days Out`}
                  onChange={value => updateAvailabilityDaysOut(availability, value > 1 ? value : null)}
                  min={2}
                />
              </td>
              <td className={classNames(styles.borderRight, styles.middleColumn)}>
                <CalendarInput
                  value={determineNextAvailableService(availability)}
                  disabled={isDisabled}
                  minDate={new Date()}
                  loading={Boolean(updating &&
                  updating.type === NEXT_AVAILABLE_SERVICE &&
                  updating.availability.id === availability.id)}
                  labelName={`${availability.title}nextAvailableService`}
                  labelText={`${availability.title} Next Available Service`}
                  onChange={value => updateAvailabilityNextAvailableService(
                    availability,
                    value === null ? null : dayjs(value).format('YYYY-MM-DD')
                  )}
                />
              </td>
              <td>
                <CheckboxInput
                  checked={availability.saturdayActive}
                  disabled={isDisabled}
                  loading={Boolean((updating &&
                  updating.type === GRAPHQL_AVAILABILITY_DELIVERY_DAY.SATURDAY &&
                  updating.availability.id === availability.id) || applyAllUpdating === GRAPHQL_AVAILABILITY_DELIVERY_DAY.SATURDAY)}
                  labelName={`${availability.title}saturdayActive`}
                  labelText={`${availability.title} Saturday Active`}
                  onChange={() => updateAvailabilityActiveState(
                    availability,
                    GRAPHQL_AVAILABILITY_DELIVERY_DAY.SATURDAY,
                    !availability.saturdayActive
                  )}
                />
              </td>
              <td>
                <CheckboxInput
                  checked={availability.sundayActive}
                  disabled={isDisabled}
                  loading={Boolean((updating &&
                  updating.type === GRAPHQL_AVAILABILITY_DELIVERY_DAY.SUNDAY &&
                  updating.availability.id === availability.id) || applyAllUpdating === GRAPHQL_AVAILABILITY_DELIVERY_DAY.SUNDAY)}
                  labelName={`${availability.title}sundyActive`}
                  labelText={`${availability.title} Sunday Active`}
                  onChange={() => updateAvailabilityActiveState(
                    availability,
                    GRAPHQL_AVAILABILITY_DELIVERY_DAY.SUNDAY,
                    !availability.sundayActive
                  )}
                />
              </td>
            </tr>
          ), availabilityType)}
        </tbody>
      </React.Fragment>
    )
  }
  return (
    <table className={styles.table}>
      <AvailabilityTableHeaders
        availabilities={availabilities}
        onChange={onChange}
        updating={applyAllUpdating}
        onUpdating={setApplyAllUpdating}
        haulerId={haulerId}
        isDisabled={isDisabled}
      />
      {map(
        type => renderAvailabilityType(formattedAvailabilities[type], type),
        Object.keys(formattedAvailabilities)
      )}
    </table>
  )
}

AvailabilityTable.propTypes = {
  availabilities: availabilitiesProps.isRequired,
  onChange: PropTypes.func.isRequired,
  haulerId: PropTypes.string.isRequired,
  onError: PropTypes.func
}
