/* eslint-disable max-len */

import { b64EncodeUnicode } from '../utilities/base64'

const buildSvgFromPath = (path, fillColor = 'black') => ({
  url: `data:image/svg+xml;charset=UTF-8;base64,${b64EncodeUnicode(path)}`,
  fillColor,
  fillOpacity: 1,
  strokeColor: 'black',
  strokeWeight: 1,
  scale: 1
})

const entityMap = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;',
  '/': '&#x2F;'
}

function escapeHtml (source) {
  return String(source).replace(/[&<>"'/]/g, s => entityMap[s])
}

export const place = (color = 'black', driverInitials = '', hovered = false, ttShortCode, rtShortCode) => {
  const width = hovered ? 50 : 40
  const height = hovered ? 60 : 50
  const path = `
    <svg
     xmlns="http://www.w3.org/2000/svg" version="1.1"
     width="${width}" height="${height}" viewBox="0 0 30 40">
    <path
       style="fill:${color};fill-opacity:1;stroke:#000000;stroke-width:0.5;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
       d="m 3.36225,0.34301 23,0 c 1.662,0 3,1.338 3,3 l 0,22 c 0,1.662 -1.338,3 -10,3 l -3.5,0 c -2.990515,11.902679 -3.666916,11.864088 -7,0 l -3.5,0 c -1.662,0 -3,-1.338 -3,-3 l 0,-22 c 0,-1.662 1.338,-3 3,-3 z"
       />
    <text text-anchor="middle" font-size="8.5"
       style="font-family: Arial, sans-serif;font-weight:bold;text-align:center;"
       fill="#fff" transform="translate(15.5 10)">
      ${escapeHtml(ttShortCode)}-${escapeHtml(rtShortCode)}
    </text>
    <text text-anchor="middle" font-size="8.5"
       style="font-family: Arial, sans-serif;text-align:center;"
       fill="#fff" transform="translate(15.5 23)">
      (${escapeHtml(driverInitials)})
    </text>
  </svg>
  `

  return buildSvgFromPath(
    path,
    color
  )
}

export const filter = (color = 'black', num = 1, hovered = false) => {
  const width = hovered ? 50 : 40
  const height = hovered ? 60 : 50
  const path = `<svg
     xmlns="http://www.w3.org/2000/svg" version="1.1"
     width="${width}" height="${height}" viewBox="0 0 30 25">
    <path
       style="fill:${color};fill-opacity:1;stroke:#000000;stroke-width:0.5;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
       d="m 3.36225,0.34301 23,0 c 1.662,0 3,1.338 3,3 l 0,14 c 0,1.662 -1.338,3 -10,3 l -3.5,0 c -2.990515,11.902679 -3.666916,11.864088 -7,0 l -3.5,0 c -1.662,0 -3,-1.338 -3,-3 l 0,-14 c 0,-1.662 1.338,-3 3,-3 z"
       />
    <text text-anchor="middle" font-size="9"
       style="font-family: Arial, sans-serif;font-weight:bold;text-align:center;"
       fill="#fff" transform="translate(15.5 14.5)">+${num}</text>
  </svg>`
  return buildSvgFromPath(
    path,
    color
  )
}

export const personPinCircle = (color = 'black', hovered = false) => {
  const fillColor = hovered ? '#333333' : color
  const path = `
    <svg fill="${fillColor}" height="44" viewBox="0 0 24 24" width="44" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <path d="M0 0h24v24H0V0z" id="a"/>
      </defs>
      <clipPath id="b">
        <use overflow="visible" xlink:href="#a"/>
      </clipPath>
      // eslint-disable-next-line max-len
      <path clip-path="url(#b)" d="M12 2C8.14 2 5 5.14 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.86-3.14-7-7-7zm0 2c1.1 0 2 .9 2 2 0 1.11-.9 2-2 2s-2-.89-2-2c0-1.1.9-2 2-2zm0 10c-1.67 0-3.14-.85-4-2.15.02-1.32 2.67-2.05 4-2.05s3.98.73 4 2.05c-.86 1.3-2.33 2.15-4 2.15z"/>
    </svg>
  `

  return buildSvgFromPath(
    path,
    color
  )
}

export const resourceRectangle = (shortCode = '  ', color = '#5F0F40') => {
  const path = `
    <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10">
      <rect width="15" height="10" />

      <text text-anchor="middle" font-size="8"
         style="font-family: Arial, sans-serif;font-weight:bold;text-align:center;"
         fill="#fff" transform="translate(7.5 8.5)">
         ${escapeHtml(shortCode)}
      </text>
    </svg>
  `

  return buildSvgFromPath(
    path,
    color
  )
}

export const siteCircle = ({ short_code: shortCode = '', color = '#434a54' }) => {
  const path = `
    <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12">
      <ellipse cx="9" cy="6" rx="9" ry="6"/>

      <text text-anchor="middle" font-size="8"
         style="font-family: Arial, sans-serif;font-weight:bold;text-align:center;"
         fill="#fff" transform="translate(8.75 8.75)">
         ${escapeHtml(shortCode)}
      </text>
    </svg>
  `

  return buildSvgFromPath(
    path,
    color
  )
}
