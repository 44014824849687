import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import fromPairs from 'lodash/fromPairs'
import map from 'lodash/map'
import { TextInput } from '../TextInput'
import AutocompleteGoogleMap from '../../pages/shared/locationPickers/AutocompleteGoogleMap'
import { SelectInput } from '../SelectInput'
import { validate } from './utils'

export default function AssetCreateForm ({
  isFetching,
  onSubmit,
  resourceTypes
}) {
  function onLocationChange (form, newLocation) {
    form.batch(() => {
      form.change('latitude', newLocation.latitude)
      form.change('longitude', newLocation.longitude)
      form.change('placeId', newLocation.place_id)
      form.change('addressLine1', newLocation.addressline1)
      form.change('city', newLocation.city)
      form.change('state', newLocation.state)
      form.change('zip', newLocation.zip)
      form.change('country', newLocation.country)
    })
  }

  return (
    <Form onSubmit={onSubmit} validate={validate}>
      {({
        form,
        handleSubmit,
        pristine,
        valid
      }) => (
        <div className='row panel panel-default'>
          <div className='container-fluid push-down-super-small'>
            <form onSubmit={handleSubmit}>
              <div className='row'>
                <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                  <Field
                    name='uid'
                    label='Asset ID (a unique ID you use specifically for this asset)'
                    component={TextInput}
                    maxLength='30'
                  />
                </div>
                <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                  <Field
                    name='resourceTypeId'
                    component={SelectInput}
                    label='Asset Type'
                    options={fromPairs(map(resourceTypes, rt => [rt.id, rt.name]))}
                  />
                </div>
                <div className='col-xs-12'>
                  <Field
                    name='placementNote'
                    component={TextInput}
                    label='Placement Note'
                    maxLength='250'
                  />
                  {/* Hidden fields still need to be here for react-final-form to consider them in form state i.e. pristine */}
                  <Field name='latitude'>{() => null}</Field>
                  <Field name='longitude'>{() => null}</Field>
                  <Field name='placeId'>{() => null}</Field>
                  <Field name='addressLine1'>{() => null}</Field>
                  <Field name='city'>{() => null}</Field>
                  <Field name='state'>{() => null}</Field>
                  <Field name='zip'>{() => null}</Field>
                  <Field name='country'>{() => null}</Field>
                </div>
              </div>

              <div className='push-down-very-small'>
                <AutocompleteGoogleMap
                  placeholder="Enter Asset's Current Location"
                  onLocationChange={newLocation => onLocationChange(form, newLocation)}
                />
              </div>
              <button
                type='submit'
                className='btn btn-lg btn-primary push-down-super-small pull-right'
                disabled={pristine || !valid || isFetching}>
                <span className='pull-left'>Save New Asset</span>
                <i className='material-icons pull-right thumbster-button-icon'>check</i>
              </button>
            </form>
          </div>
          <br />
        </div>
      )}
    </Form>
  )
}

AssetCreateForm.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  resourceTypes: PropTypes.array
}
