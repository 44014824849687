import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, Link } from 'react-router-dom'
import cn from 'classnames'
import styles from './index.module.scss'
import moment from 'moment'
import Tooltip from 'react-bootstrap/lib/Tooltip'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'
import { prettyAddress } from '../../utilities/locationUtilities'
import CursorPagination from '../cursor-pagination'
import { ROUTES } from '../../routes'

function TableHeader ({ label, columnName, onSortChange, sort }) {
  const currentlySorted = sort.column === columnName

  let nextSortDirection
  if (currentlySorted) {
    nextSortDirection = sort.direction.toLowerCase() === 'asc' ? 'desc' : 'asc'
  } else {
    nextSortDirection = 'desc'
  }

  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
  return (<div className='cursor-pointer' onClick={() => onSortChange(columnName, nextSortDirection)}>
    {label}
    &nbsp;
    {(() => {
      if (currentlySorted) {
        if (sort.direction.toLowerCase() === 'asc') {
          return <span className='pull-right'>&uarr;</span>
        }
        return <span className='pull-right'>&darr;</span>
      }
      return null
    })()}
  </div>)
}

TableHeader.propTypes = {
  label: PropTypes.string.isRequired,
  columnName: PropTypes.string.isRequired,
  sort: PropTypes.shape({
    column: PropTypes.oneOf(['uid', 'jobCustomId',
      'resourceTypeName', 'siteName', 'streetAddress', 'minIdleDays', 'minIdleDays', 'lastMoved', '']).isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired
  }).isRequired,
  onSortChange: PropTypes.func.isRequired
}

export default function ResourcesTable ({
  onPageRequest,
  assets,
  sort,
  onSortChange,
  fakeCount
}) {
  return (
    <div className='row col-xs-12 table-responsive'>
      <table className='table table-striped'>
        <thead>
          <tr>
            <th width='10%'>
              <TableHeader
                label='ID'
                columnName='uid'
                sort={sort}
                onSortChange={onSortChange}
              />
            </th>
            <th width='5%'>Asset Notes</th>
            <th width='15%'>
              <TableHeader
                label='Asset Type'
                columnName='resourceTypeName'
                onSortChange={onSortChange}
                sort={sort}
              />
            </th>
            <th width='11%'>Related</th>
            <th width='13%'>Related Account</th>
            <th width='20%'>Current Location</th>
            <th width='15%'>
              <TableHeader
                label='Set At Current Location'
                columnName='lastMoved'
                onSortChange={onSortChange}
                sort={sort}
              />
            </th>
            <th width='11%'>Action</th>
          </tr>
        </thead>
        <tbody>
          {(() => assets.nodes.map(asset => {
            const resourcePath = `/hauler/resources/${asset.id}`

            return (
              <tr key={asset.id}>
                <td>
                  <strong>
                    <Link to={resourcePath}>{asset.uid}</Link>
                  </strong>
                </td>

                <td>
                  {(() => {
                    if (asset.placementNote) {
                      const tooltip = (
                        <Tooltip id='tooltip'>{asset.placementNote}</Tooltip>
                      )
                      return (<OverlayTrigger placement='top' overlay={tooltip}>
                        <i className='material-icons'>speaker_notes</i>
                      </OverlayTrigger>)
                    }
                    return null
                  })()}
                </td>

                <td>
                  {asset.type}
                </td>

                <td>
                  {(() => {
                    if (asset?.job?.id) {
                      return (<Link to={generatePath(ROUTES.job, { id: asset?.job?.id })}>
                        {`Job - ${asset?.job?.customId}`}
                      </Link>)
                    } else if (asset?.site?.id) {
                      return (<Link to={generatePath(ROUTES.site, { id: asset?.site?.id })}>
                        {`Site - ${asset?.site?.name}`}
                      </Link>)
                    } else if (asset?.user?.id) {
                      return (<Link to={generatePath(ROUTES.teamMember, { id: asset?.user?.id })}>
                        {`Driver - ${asset?.user?.firstName} ${asset?.user?.lastName}`}
                      </Link>)
                    }
                    return 'None'
                  })()}
                </td>

                <td>
                  <div>
                    {(() => {
                      if (asset?.client?.name) {
                        return (<Link to={generatePath(ROUTES.client, { clientId: asset.client.id })}>
                          {asset?.client?.name}
                        </Link>)
                      }
                      return null
                    })()}
                  </div>
                </td>

                <td>
                  <div>
                    {(() => {
                      if (asset?.currentLocation?.addressLine1) {
                        return prettyAddress(asset?.currentLocation)
                      } else if (asset?.user?.id) {
                        return 'Currently on Truck'
                      } else {
                        return 'None'
                      }
                    })()}
                  </div>
                </td>

                <td>
                  {(() => {
                    if (asset.lastMoved) {
                      const parsedMoment = moment(asset.lastMoved)
                      const formattedDate = parsedMoment.format('MMM D, YYYY, h:mm a')
                      const formattedDayCount = parsedMoment.fromNow()
                      return (<div>
                        <strong>
                          {formattedDayCount}
                        </strong>
                        <br />
                        <em>
                          {formattedDate}
                        </em>
                      </div>)
                    }
                    return 'None'
                  })()}
                </td>

                <td>
                  <div className={cn(styles.btnGroup, 'btn-group')}>
                    <Link to={resourcePath} className='btn btn-sm btn-primary-dk'>
                      Details
                    </Link>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a className='btn btn-primary-dk btn-sm dropdown-toggle hidden-xs hidden-sm drop-down' data-toggle='dropdown'>
                      <span className='caret' />
                    </a>
                    <ul className='dropdown-menu'>
                      <li>
                        <Link to={`/hauler/resources/${asset.id}/edit`}>
                          <span>Edit Asset</span>
                          <i className='material-icons'>edit</i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            )
          }))()}
        </tbody>
      </table>
      {assets.totalCount > 0 && <CursorPagination
        pageInfo={assets.pageInfo}
        totalCount={assets.totalCount}
        onPageRequest={onPageRequest}
        fakeCount={fakeCount}
                                />}
    </div>
  )
}

ResourcesTable.propTypes = {
  onPageRequest: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  assets: PropTypes.object.isRequired,
  sort: PropTypes.shape({
    column: PropTypes.oneOf(['uid', 'jobCustomId',
      'resourceTypeName', 'siteName', 'streetAddress', 'minIdleDays', 'minIdleDays', 'lastMoved', '']).isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired
  }).isRequired,
  fakeCount: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired
  })
}
