import React from 'react'

export default function UnsupportedBrowserPage () {
  return (
    <div className='center__all--large push-down-medium'>
      <h4>Dispatcher.com does not support your internet browser. <br />Please install one of the browsers below to
        continue.</h4>
      <div className='row push-down-medium'>
        <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3 text-center'>
          <a href='https://www.google.com/chrome/browser/desktop/index.html' target='_blank' rel='noopener noreferrer'>
            <img src='./chrome_128x128.png' alt='Google Chrome' />
          </a>
        </div>
        <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3 text-center'>
          <a href='https://www.mozilla.org/en-US/firefox/new/' target='_blank' rel='noopener noreferrer'>
            <img src='./firefox_128x128.png' alt='Mozilla Firefox' />
          </a>
        </div>
        <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3 text-center'>
          <a href='http://www.apple.com/safari/' target='_blank' rel='noopener noreferrer'>
            <img src='./safari_128x128.png' alt='Apple Safari' />
          </a>
        </div>
        <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3 text-center'>
          <a href='https://www.microsoft.com/en-us/windows/microsoft-edge' target='_blank' rel='noopener noreferrer'>
            <img src='./edge_128x128.png' alt='Microsoft Edge' />
          </a>
        </div>
      </div>
      <br /><br /><br />
    </div>
  )
}
