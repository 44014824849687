import React from 'react'
import PropTypes from 'prop-types'
import FeeTypeListItem from './FeeTypeListItem'

export default function FeeTypeList ({ feeTypes }) {
  return (
    <div className='row panel panel-default'>
      {feeTypes?.length === 0 && (
        <h4 className='text-center'>No Fee Types</h4>
      )}
      {
        feeTypes?.map(feeType => (
          <FeeTypeListItem
            key={feeType.id}
            feeType={feeType}
          />
        ))
      }
    </div>
  )
}

FeeTypeList.propTypes = {
  feeTypes: PropTypes.array
}
