import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'

export default function RemoveTicketModal ({ ticketId, onClose, onSave, isLoading }) {
  return (
    <div className={cn('dis-panel', styles.removeTicketContainer)}>
      <h3>Remove Ticket?</h3>
      <p className={cn(styles.removeTicketText)}>
        Are you sure you want to remove this ticket from the invoice?
      </p>
      <div className={cn(styles.removeTicketButtonWrapper)}>
        <button
          className='dis-btn dis-btn-lg dis-btn-blank'
          onClick={onClose}>
          No, Cancel
        </button>
        <button
          className='dis-btn dis-btn-lg dis-btn-danger'
          disabled={isLoading}
          onClick={() => onSave(ticketId)}>
          Yes, Remove Ticket
        </button>
      </div>
      <footer className={cn(styles.removeTicketsFooter)}>Once removed, the ticket can be added to a different invoice</footer>
    </div>
  )
}

RemoveTicketModal.propTypes = {
  ticketId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isLoading: PropTypes.func.isRequired
}
