import React from 'react'
import FormExplanation from '../../pages/shared/FormExplanation'

export default function CreateFeeTypeExplanation () {
  const messages = [
    'Fee Types are the different kinds of Line Items that you charge for',
    'The Default Amount is the usual amount that you charge for a particular Line Item'
  ]

  return (
    <FormExplanation messages={messages} />
  )
}
