import { intercomConnection } from '../../intercomConnection'

export default async function updateHauler ({ dataLoaders, variables }) {
  const response = await dataLoaders.thumbster.axios.patch(
    '/hauler/hauler',
    {
      name: variables.name,
      primary_phone: variables.primaryPhone,
      latitude: variables.latitude,
      longitude: variables.longitude,
      place_id: variables.placeId,
      addressline1: variables.addressline1,
      city: variables.city,
      state: variables.state,
      zip: variables.zip,
      country: variables.country
    }
  )
  intercomConnection.trackEvent('hauler_updated')

  // Map Thumbster snake case to our apps expected camelCase, this will be fixed when this endpoint moves to our
  // GraphQL API
  return {
    addressline1: response.data.hauler.addressline1,
    addressline2: response.data.hauler.addressline2,
    city: response.data.hauler.city,
    country: response.data.hauler.country,
    createdAt: response.data.hauler.created_at,
    id: response.data.hauler.id,
    metric: response.data.hauler.metric,
    name: response.data.hauler.name,
    placeId: response.data.hauler.place_id,
    prettyPrimaryPhone: response.data.hauler.pretty_primary_phone,
    requiredActionsAllowed: response.data.hauler.required_actions_allowed,
    isWastepayEnabled: response.data.hauler.is_wastepay_enabled,
    isWastepayApproved: response.data.hauler.is_wastepay_approved,
    state: response.data.hauler.state,
    zip: response.data.hauler.zip,
    latitude: response.data.hauler.latitude,
    longitude: response.data.hauler.longitude
  }
}
