import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import notify from '../utilities/notify'
import { ROUTES } from '../routes'
import createFeeTypeMutation from '../graphql/mutations/createFeeType'
import NormalLayoutContainer from './shared/NormalLayoutContainer'
import PageTitle from '../components/page-title'
import FeeTypeForm from '../components/fee-type/FeeTypeForm'
import CreateFeeTypeExplanation from '../components/fee-type/CreateFeeTypeExplanation'
import useMutation from '../hooks/useMutation'
import { parseUSDToNumber } from '../utilities/parsers'

export default function NewFeeTypePage () {
  const history = useHistory()
  const { user } = useSelector(({ user: { user } }) => ({ user }))
  const { mutateAsync: createFeeType, isLoading: isCreatingFeeType } = useMutation(createFeeTypeMutation, {
    onSuccess () {
      history.push(ROUTES.feeTypes)
      notify('success', 'Fee Type created successfully')
    },
    onError () {
      notify('error', 'Failed to create fee type')
    }
  })

  function handleFormSubmit (data) {
    return createFeeType({
      name: data.name,
      haulerId: user.haulerId,
      defaultAmount: parseFloat(parseUSDToNumber(data.defaultAmount)),
      description: data.description
    })
  }

  return (
    <NormalLayoutContainer showBackLink>
      <PageTitle className='dis-flex-page-title'>
        <div>Create a New Fee Type</div>
        <Link className='dis-btn dis-btn-blank dis-btn-lg' to={ROUTES.feeTypes}>Cancel</Link>
      </PageTitle>
      <div className='dis-form-page-container'>
        <div className='dis-form-container-left'>
          <div className='dis-panel'>
            <div className='dis-panel-body'>
              <FeeTypeForm onSubmit={handleFormSubmit} disabled={isCreatingFeeType} />
            </div>
          </div>
        </div>
        <div className='dis-form-container-right'>
          <CreateFeeTypeExplanation />
          <hr />
        </div>
      </div>

    </NormalLayoutContainer>
  )
}
