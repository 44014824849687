import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { decodeJWT } from '../../../../shared/decodeJWT'
import NormalLayoutContainer from '../../../../shared/NormalLayoutContainer'
import { PageTitle } from '../../../../../components/page-title'
import { Spinner } from '../../../../shared/Spinner'
import NotificationsReadOnlyList from '../../../../../components/notifications-read-only'
import {
  REQUEST_CURRENT_HAULER_TEAM_MEMBER_ACTION
} from '../../sagas/currentHaulerTeamMemberSaga'
import {
  REQUEST_HAULER_TEAM_MEMBER_ACTIVATE_ACTION,
  REQUEST_HAULER_TEAM_MEMBER_DEACTIVATE_ACTION
} from '../../sagas/haulerTeamMemberActivateDeactivateSaga'
import { REQUEST_CURRENT_HAULER_TEAM_MEMBER_NOTIFICATIONS_ACTION } from '../../sagas/currentHaulerTeamMemberNotificationsSaga'
import HaulerTeamMemberDisplay from './components/HaulerTeamMemberDisplay'
import { useHistory, useParams } from 'react-router-dom'

// eslint-disable-next-line camelcase
export default function HaulerTeam_IdContainer () {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()

  const { currentHaulerTeamMember, currentHaulerTeamMemberNotifications } =
    useSelector(({ currentHaulerTeamMember, currentHaulerTeamMemberNotifications }) => ({
      currentHaulerTeamMember,
      currentHaulerTeamMemberNotifications
    }))

  const handleEditTeamMemberProfile = useCallback(() => {
    history.push(`/hauler/team/${id}/edit`)
  }, [history, id])

  const onPageRequest = useCallback((page = 1) => {
    dispatch({
      type: REQUEST_CURRENT_HAULER_TEAM_MEMBER_NOTIFICATIONS_ACTION,
      payload: { id, page }
    })
  }, [dispatch, id])

  const activateHaulerTeamMember = useCallback(() => {
    dispatch({ type: REQUEST_HAULER_TEAM_MEMBER_ACTIVATE_ACTION, payload: id })
  }, [dispatch, id])

  const deactivateHaulerTeamMember = useCallback(() => {
    dispatch({ type: REQUEST_HAULER_TEAM_MEMBER_DEACTIVATE_ACTION, payload: id })
  }, [dispatch, id])

  useEffect(() => {
    dispatch({ type: REQUEST_CURRENT_HAULER_TEAM_MEMBER_ACTION, payload: id })
    dispatch({
      type: REQUEST_CURRENT_HAULER_TEAM_MEMBER_NOTIFICATIONS_ACTION,
      payload: { id, page: 1 }
    })
  }, [dispatch, id])

  const decoded = decodeJWT()
  const selfUser = decoded && decoded.u && currentHaulerTeamMember && currentHaulerTeamMember.id === decoded.u

  return (
    <div>
      <NormalLayoutContainer showBackLink>
        <div className='container-fluid'>
          <PageTitle title={`${currentHaulerTeamMember.first_name} ${currentHaulerTeamMember.last_name}`}>
            <button
              className='btn btn-lg btn-primary-dk pull-right push-down-super-small space-left'
              onClick={handleEditTeamMemberProfile}>
              <span className='pull-left'>Edit Profile</span>
              <i className='material-icons pull-right thumbster-button-icon'>mode_edit</i>
            </button>

            {(() => {
              if (selfUser) return null
              if (currentHaulerTeamMember.deleted_at) {
                return (<button
                  className='btn btn-lg btn-primary pull-right push-down-super-small space-left'
                  onClick={activateHaulerTeamMember}>
                  <span className='pull-left'>Activate User</span>
                  <i className='material-icons pull-right thumbster-button-icon'>check</i>
                </button>)
              }
              return (<button
                className='btn btn-lg btn-danger pull-right push-down-super-small'
                onClick={deactivateHaulerTeamMember}>
                <span className='pull-left'>Deactivate User</span>
                <i className='material-icons pull-right thumbster-button-icon'>block</i>
              </button>)
            })()}

            <button
              className='btn btn-lg btn-link pull-right push-down-super-small space-right'
              onClick={() => history.push('/hauler/team/new/after-add-team-member')}>
              Onboarding Instructions
            </button>
          </PageTitle>
          <br />

          <Spinner isFetching={currentHaulerTeamMember.isFetching}>
            <HaulerTeamMemberDisplay team_member={currentHaulerTeamMember} />
          </Spinner>

          <Spinner isFetching={currentHaulerTeamMemberNotifications.isFetching}>
            <div className='push-down-very-small row'>
              <NotificationsReadOnlyList
                title={`Notifications sent to ${currentHaulerTeamMember.first_name}`}
                notifications={currentHaulerTeamMemberNotifications.notifications}
                pagination={currentHaulerTeamMemberNotifications.pagination}
                onPageRequest={onPageRequest}
                includeLink
              />
            </div>
          </Spinner>
        </div>
      </NormalLayoutContainer>
    </div>
  )
}
