import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, Link } from 'react-router-dom'
import NoResults from '../../pages/shared/NoResults'
import NotesCell from './NotesCell'
import { prettyAddress } from '../../utilities/locationUtilities'
import { ObjectParam, useQueryParams } from 'use-query-params'
import useQuery from '../../hooks/useQuery'
import QUERY_KEYS from '../../graphql/queryKeys'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import { Spinner } from '../../pages/shared/Spinner'
import clientImportRowsQuery from '../../graphql/queries/clientImportRows'
import CursorPagination from '../cursor-pagination'
import { ROUTES } from '../../routes'

export default function CompletedClientImportRowsTable ({ importId }) {
  const [queryParams, setQueryParams] = useQueryParams({ pager: ObjectParam })

  const { data: clientImportRowsData, isLoading } = useQuery(
    [QUERY_KEYS.clientImportRows, {
      clientImportId: importId,
      status: 'IMPORTED',
      first: queryParams.pager?.direction === undefined || queryParams.pager?.direction === 'after' ? 10 : undefined,
      last: queryParams.pager?.direction === 'before' ? 10 : undefined,
      after: queryParams.pager?.direction === 'after' ? queryParams.pager?.cursor : undefined,
      before: queryParams.pager?.direction === 'before' ? queryParams.pager?.cursor : undefined
    }],
    clientImportRowsQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Failed to retrieve data')
      },
      // We don't usually consider data to not be stale, BUT in this scenario this query gets loaded from a few components within a short time span
      // AND this data never changes. Once an import starts, its rows do not change.
      staleTime: 5 * 1000 * 60
    })

  if (isLoading) {
    return <Spinner isFetching />
  }

  function handlePageRequest (direction, cursor) {
    setQueryParams({ pager: { direction, cursor } })
  }

  return (
    <div className='panel panel-default row'>
      <div className='panel-body'>
        <div className='table-responsive'>
          <table className='table table-striped'>
            <thead>
              <tr>
                <th width='5%'>ID</th>
                <th width='10%'>Notes</th>
                <th width='15%'>Name</th>
                <th width='10%'>Contact Name</th>
                <th width='15%'>Billing Address</th>
                <th width='10%'>Email</th>
                <th width='10%'>Phone</th>
                <th width='10%'>Billing Details</th>
                <th width='10%'>Action</th>
              </tr>
            </thead>
            <tbody>
              {clientImportRowsData.clientImportRows.nodes.length === 0 && <tr><td colSpan='7'><NoResults /></td></tr>}
              {clientImportRowsData.clientImportRows.nodes.map(data => {
                const path = generatePath(ROUTES.client, { clientId: data.client.id })
                return (
                  <tr key={data.id}>
                    <td><Link to={path}>{data.client.customId}</Link></td>
                    <td><NotesCell notes={data.notes} /></td>
                    <td>{data.name}</td>
                    <td>{(data.firstName || data.lastName) ? (data.firstName + ' ' + data.lastName) : 'None'}</td>
                    <td>{data.billingAddress}</td>
                    <td>{data.email}</td>
                    <td>{data.primaryPhone}</td>
                    <td>{prettyAddress(data)}</td>
                    <td><NotesCell notes={data.billingDetails} /></td>
                    <td><Link to={path} className='btn btn-sm btn-primary-dk'>Details</Link></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <CursorPagination
            onPageRequest={handlePageRequest}
            pageInfo={clientImportRowsData.clientImportRows.pageInfo}
            totalCount={clientImportRowsData.clientImportRows.totalCount}
          />
        </div>
      </div>
    </div>
  )
}

CompletedClientImportRowsTable.propTypes = {
  importId: PropTypes.string.isRequired
}
