import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { generatePath, Link, useHistory, useLocation } from 'react-router-dom'
import QUERY_KEYS from '../../graphql/queryKeys'
import ticketTypesQuery from '../../graphql/queries/ticketTypes.js'
import resourceTypesQuery from '../../graphql/queries/resourceTypes.js'
import usersQuery from '../../graphql/queries/users.js'
import { TICKET_BILLING_STATUSES } from '../../utilities/constants'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import PageTitle from '../../components/page-title'
import { Spinner } from '../shared/Spinner'
import FormExplanation from '../shared/FormExplanation'
import {
  REQUEST_TICKET_TYPES_ACTION
} from '../hauler/ticket-types/sagas/ticketTypesSaga'
import BulkTicketsCreateForm from '../../components/bulk-tickets-create-form'
import notify from '../../utilities/notify'
import styles from './index.module.scss'
import useMutation from '../../hooks/useMutation'
import useQuery from '../../hooks/useQuery'
import bulkCreateTicketsMutation from '../../graphql/mutations/bulkCreateTickets'
import { ROUTES } from '../../routes'

export default function BulkCreateTicketsPage () {
  const [ticketTypeBillingStatusSelected, setTicketTypeBillingStatusSelected] = useState(null)
  const [resourceTypeBillingStatusSelected, setResourceTypeBillingStatusSelected] = useState(null)
  const location = useLocation().search
  const history = useHistory()
  const jobId = new URLSearchParams(location).get('job_id')
  const dispatch = useDispatch()

  const { user, hauler } = useSelector(({ user: { user, hauler } }) => ({ user, hauler }))

  const { mutate: createTickets, isLoading: createTicketsLoading } = useMutation(bulkCreateTicketsMutation, {
    onSuccess () {
      notify('success', 'Bulk Ticket Creation Started')
      history.goBack()
    },
    onError () {
      notify('error', 'Error Bulk Creating Tickets')
    }
  })

  const { data: users, isFetching: userListFetching } = useQuery([
    QUERY_KEYS.users,
    user.haulerId,
    true,
    null,
    null,
    hauler.showDriversOnly ? 'hauler_driver' : null
  ],
  usersQuery,
  {
    enabled: Boolean(user.haulerId),
    onError () {
      notify('error', 'Unable to retrieve user list')
    },
    placeholderData: { users: { nodes: [] } }
  })

  const { data: ticketTypes, isFetching: ticketTypesFetching } = useQuery(
    [QUERY_KEYS.ticketTypes, { haulerId: user.haulerId, status: 'ENABLED' }],
    ticketTypesQuery,
    {
      enabled: Boolean(user.haulerId),
      onError () {
        notify('error', 'Unable to retrieve ticket types list')
      },
      placeholderData: { ticketTypes: [] }
    })

  const { data: resourceTypes, isFetching: resourceTypesFetching } = useQuery(
    [QUERY_KEYS.resourceTypes, { haulerId: user.haulerId, status: 'ENABLED' }],
    resourceTypesQuery,
    {
      onError () {
        notify('error', 'Unable to retrieve resource types list')
      },
      placeholderData: { resourceTypes: [] }
    })

  const handleFormSubmit = (values) => {
    batchCreateTickets(values)
  }

  const batchCreateTickets = (values) => {
    const days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']
    const ruleDaysOfTheWeek = []
    Object.keys(values).map(key => {
      if (days.includes(key)) {
        ruleDaysOfTheWeek.push(key)
        delete values[key]
      }
      return null
    })
    const {
      resourceTypeId, ticketTypeId, driverId, comment, billingNotes, billingStatus, requestedStartTime, requestedEndTime,
      ruleInterval: interval, quantity, ...rules
    } = values
    const { ruleStartDate, ruleEndDate, ruleInterval, ruleFrequency, quantity: qty, ...ticketParams } = values
    createTickets({
      haulerId: user.haulerId,
      ticketParams: { ...ticketParams, jobId },
      rules: { ...rules, ruleDaysOfTheWeek, ruleInterval: parseInt(interval) },
      quantity: parseInt(values.quantity)
    })
  }
  useEffect(function onInitialRender () {
    dispatch({ type: REQUEST_TICKET_TYPES_ACTION, payload: {} })
  }, [dispatch])

  const isFetching = ticketTypesFetching || resourceTypesFetching

  return (
    <NormalLayoutContainer showBackLink>
      <PageTitle>
        <div className={styles.pageTitle}>
          <div>Bulk Create Tickets</div>
          <Link className='dis-btn dis-btn-blank dis-btn-lg' to={generatePath(ROUTES.job, { id: jobId })}>
            Cancel
          </Link>
        </div>
      </PageTitle>

      <Spinner isFetching={isFetching || userListFetching}>
        <div className={styles.bulkCreateContainer}>
          <div className={styles.bulkCreateFormContainer}>
            <BulkTicketsCreateForm
              users={users?.users?.nodes}
              isFetching={createTicketsLoading}
              onSubmit={handleFormSubmit}
              resourceTypes={resourceTypes?.resourceTypes}
              ticketTypes={ticketTypes?.ticketTypes}
              billingStatuses={TICKET_BILLING_STATUSES}
              ticketTypeBillingStatusSelected={ticketTypeBillingStatusSelected}
              setTicketTypeBillingStatusSelected={setTicketTypeBillingStatusSelected}
              resourceTypeBillingStatusSelected={resourceTypeBillingStatusSelected}
              setResourceTypeBillingStatusSelected={setResourceTypeBillingStatusSelected}
            />
          </div>
          <div className={styles.bulkCreateHelpContainer}>
            <FormExplanation
              messages={[
                'Bulk ticket creation will create several tickets on a schedule.'
              ]}
            />
          </div>
        </div>
      </Spinner>
    </NormalLayoutContainer>
  )
}
