import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Tooltip from 'react-bootstrap/lib/Tooltip'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'
import { stringToTime } from '../../utilities/stringUtilities'
import CursorPagination from '../cursor-pagination'
import NoResults from '../../pages/shared/NoResults'
import TicketStatusLabel from '../TicketStatusLabel'

function TableHeader ({ label, columnName, onSortChange, sort }) {
  const currentlySorted = sort.column === columnName

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className='cursor-pointer' onClick={() => onSortChange(columnName)}>
      {label}
      {currentlySorted && (
        <span className='pull-right'>{ sort.direction === 'asc' ? <>&uarr;</> : <span className='pull-right'>&darr;</span> }</span>
      )}
    </div>
  )
}

TableHeader.propTypes = {
  label: PropTypes.string.isRequired,
  columnName: PropTypes.string.isRequired,
  sort: PropTypes.shape({
    column: PropTypes.oneOf(['date', 'ticketTypeName', 'resourceTypeName',
      'jobCustomId', 'address']).isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired
  }).isRequired,
  onSortChange: PropTypes.func.isRequired
}

export default function InvoiceableTicketsTable ({
  onSortChange,
  sort,
  tickets,
  onPageRequest,
  selectTicket,
  selectedTickets,
  fakeCount
}) {
  const enhancedTickets = tickets.nodes.map((ticket) => {
    const newTicket = ticket
    newTicket.selected = Boolean(selectedTickets.find(t => t.id === newTicket.id))
    return newTicket
  })

  return (<div className='row'>
    <div className='col-xs-12'>
      <table className='table table-repsonsive table-striped'>
        <thead>
          <tr>
            <th width='5%'>Invoice</th>
            <th width='4%'>ID</th>
            <th width='8%'>
              <TableHeader
                label='Status'
                columnName='status'
                sort={sort}
                onSortChange={onSortChange}
              />
            </th>
            <th width='10%'>
              <TableHeader
                label='Date'
                columnName='date'
                sort={sort}
                onSortChange={onSortChange}
              />
            </th>
            <th width='8%'>Billing Notes</th>
            <th width='10%'>
              <TableHeader
                label='Ticket Type'
                columnName='ticketTypeName'
                sort={sort}
                onSortChange={onSortChange}
              />
            </th>
            <th width='10%'>
              <TableHeader
                label='Asset Type'
                columnName='resourceTypeName'
                sort={sort}
                onSortChange={onSortChange}
              />
            </th>
            <th width='5%'>
              <TableHeader
                label='Job ID'
                columnName='jobCustomId'
                sort={sort}
                onSortChange={onSortChange}
              />
            </th>
            <th width='15%'>
              <TableHeader
                label='Address'
                columnName='address'
                sort={sort}
                onSortChange={onSortChange}
              />
            </th>
            <th width='8%'>
              <TableHeader
                label='Weight (tons)'
                columnName='weight'
                sort={sort}
                onSortChange={onSortChange}
              />
            </th>
            <th width='10%'>Ticket Fees Count</th>
            <th width='10%'>Action</th>
          </tr>
        </thead>
        <tbody>

          {enhancedTickets.length === 0 && <tr>
            <td colSpan={12}><NoResults /></td>
          </tr>}

          {enhancedTickets.map((ticket) => {
            const { job, ticketType, resourceType } = ticket
            const ticketPath = `/hauler/tickets/${ticket.id}`
            return (
              <tr key={ticket.id}>
                <td>
                  <input type='checkbox' onChange={() => selectTicket(ticket)} checked={ticket.selected} />
                </td>
                <td>
                  <Link to={ticketPath}>{ticket.customId}</Link>
                </td>
                <td>
                  <Link to={ticketPath}>
                    <TicketStatusLabel
                      status={ticket.status}
                      hasActiveException={ticket.hasActiveException}
                      exceptionMessage={ticket.exceptionMessage}
                    />
                  </Link>
                </td>
                <td>
                  <div>
                    {moment(ticket.date).format('MMM D, YYYY')}
                    <br />
                    <small>
                      {
                        ticket.requestedStartTime
                          ? `${stringToTime(ticket.requestedStartTime)}-${stringToTime(ticket.requestedEndTime)}`
                          : null
                      }
                    </small>
                  </div>
                </td>
                <td>
                  {ticket.billingNotes &&
                    <OverlayTrigger
                      placement='top'
                      overlay={(<Tooltip id='tooltip'>
                        {ticket.billingNotes}
                      </Tooltip>)}>
                      <i className='material-icons'>speaker_notes</i>
                    </OverlayTrigger>
                  }
                </td>
                <td>
                  {ticketType.name} ({ticketType.shortCode})
                </td>
                <td>
                  {resourceType.name} ({resourceType.shortCode})
                </td>
                <td>
                  <Link to={`/hauler/jobs/${job.id}`}>{job.customId}</Link>
                </td>
                <td>
                  <div>{job.location.addressLine1} {job.addressDetails ? `(${job.addressDetails})` : null}</div>
                </td>
                <td>
                  <div>{ticket.weight ? (ticket.weight / 2000).toFixed(2) : 'N/A'}</div>
                </td>
                <td>
                  {(ticket.ticketFeesCount === 0 || !ticket.ticketFeesCount)
                    ? <div className='text-danger'>None</div>
                    : <div>{ticket.ticketFeesCount}</div>}
                </td>
                <td>
                  <div className='btn-group'>
                    <Link
                      to={ticketPath}
                      className='btn btn-sm btn-primary-dk whitespace-nowrap'>
                      View
                    </Link>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <button className='btn btn-primary-dk btn-sm dropdown-toggle hidden-xs hidden-sm whitespace-nowrap drop-down' data-toggle='dropdown'>
                      <span className='caret' />
                    </button>
                    <ul className='dropdown-menu'>
                      <li>
                        <Link to={`${ticketPath}?scrollTo=fees`}>
                          <span>Edit Ticket Fees</span>
                          <div className='clearfix' />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {tickets.nodes.length > 0 && <CursorPagination
        pageInfo={tickets.pageInfo}
        totalCount={tickets.totalCount}
        onPageRequest={onPageRequest}
        fakeCount={fakeCount}
                                   />}
    </div>
  </div>)
}

InvoiceableTicketsTable.propTypes = {
  onSortChange: PropTypes.func.isRequired,
  tickets: PropTypes.object.isRequired,
  onPageRequest: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    column: PropTypes.oneOf(['date', 'ticketTypeName', 'resourceTypeName',
      'jobCustomId', 'address']).isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired
  }).isRequired,
  selectTicket: PropTypes.func.isRequired,
  selectedTickets: PropTypes.array.isRequired,
  fakeCount: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired
  })
}
