import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { generatePath, Link, useHistory } from 'react-router-dom'
import { Field, Form, FormSpy } from 'react-final-form'
import { createForm } from 'final-form'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import cn from 'classnames'
import { ROUTES } from '../../routes'
import Spinner from 'react-spinkit'
import useConstant from '../../hooks/useConstant'
import styles from './index.module.scss'
import { commaDeliminate } from '../../utilities/stringUtilities'
import {
  handleOnChangeDiscountAmount,
  handleOnChangeTaxAmount,
  handleOnChangeTaxCode,
  handleOnChangeDiscountPercent,
  handleOnChangeTaxPercent,
  removeDiscount,
  removeTax,
  updateDiscountAndTax
} from '../discount-and-tax-form-fields/utils'
import DiscountAndTaxFormFields from '../discount-and-tax-form-fields'
import FeeTypeSelect from './FeeTypeSelect'
import Modal from '../modal/Modal'
import TicketFee from './TicketFee'
import TicketFormUnsavedModal from './TicketFormUnsavedModal'
import { validate } from './utils'
export default function TicketFeeForm ({
  feeTypes,
  hasInvoiceDiscount,
  hasInvoiceTax,
  initialValues,
  isFetching,
  showCreateInvoice,
  showDiscountAndTax,
  onSubmit,
  qboInvoiced,
  ticket,
  isEditingInvoicedFees,
  setIsEditingInvoicedFees,
  isConnectedToQB,
  usingAST,
  taxCodes = [],
  returnTo,
  unblockRef

}) {
  const history = useHistory()
  const [formModalOpen, setFormModalOpen] = useState(false)
  const [currentPath, setCurrentPath] = useState('')

  const EditInvoiceLink = () => {
    return (<Link to={generatePath(ROUTES.invoiceEdit, { id: ticket?.invoice?.id })}>
      &nbsp;invoice
    </Link>)
  }

  const feeTotal = (ticketFees) => {
    if (ticketFees.length > 0) {
      const amounts = ticketFees?.map(ticketFee => (
        ticketFee.total
      ))
      return amounts.reduce((a, b) => (a + b), 0)
    }
    return 0
  }

  const formatValue = (value) => {
    if (value && value >= 0) {
      return value
    }
    return null
  }

  const addTicketFee = (args) => {
    if (args[0]?.value) {
      const feeTypeId = args[0].value
      const form = args[1]
      const ticketFeeTotal = args[2]
      const feeType = feeTypes.find(feeType => feeType.id === feeTypeId)

      form.batch(() => {
        form.mutators.push('ticketFees', {
          feeType: {
            name: feeType.name,
            id: feeType.id
          },
          quantity: 1,
          amount: feeType.defaultAmount,
          total: feeType.defaultAmount,
          description: feeType.description,
          id: null
        })
        form.change('feeTypeId', null)
      })

      updateDiscountAndTax(form, ticketFeeTotal)
    }
  }

  function handleCreateInvoice (form) {
    form.batch(() => {
      form.change('pristine', form.getState().pristine)
      form.change('afterSubmit', 'createInvoice')
    })
  }

  function handleSaveFees (form) {
    form.batch(() => {
      form.change('pristine', form.getState().pristine)
      form.change('afterSubmit', 'saveFees')
    })
  }

  function saveCreateText () {
    return returnTo
      ? 'Save & Return to Invoice'
      : isEditingInvoicedFees ? 'Save & Open Invoice' : 'Save & Create Invoice'
  }

  const onDestroyTicketFee = (form, index, ticketFeeTotal) => {
    const formState = form.getState()
    const deletedTicketFees = formState.values.deletedTicketFees
    const ticketFeeId = formState.values.ticketFees[index].id

    if (ticketFeeId) {
      form.change('deletedTicketFees', [...[ticketFeeId], ...deletedTicketFees])
    }

    form.mutators.remove('ticketFees', index)

    if (formState.values.ticketFees.length <= 1) {
      removeDiscount(form)
      removeTax(form)
    } else {
      updateDiscountAndTax(form, ticketFeeTotal)
    }
  }

  function clearDescription (args, state) {
    const [ticketFeeIndex] = args
    state.fields[`${ticketFeeIndex}.description`].change(null)
  }

  const ticketFeeDiscountTaxForm = useConstant(() => createForm({
    initialValues,
    onSubmit,
    validate,
    mutators: {
      addTicketFee,
      clearDescription,
      ...arrayMutators
    }
  }))

  useEffect(() => {
    unblockRef.current = history.block((location) => {
      if (!ticketFeeDiscountTaxForm?.getState()?.pristine) {
        setFormModalOpen(true)
        setCurrentPath(location.pathname)
        return false
      }
      return true
    })
    return function cleanup () {
      unblockRef.current && unblockRef.current()
    }
  }, [history, currentPath, ticketFeeDiscountTaxForm, unblockRef])

  return (
    <Form
      form={ticketFeeDiscountTaxForm}>
      {({ handleSubmit, onChange, valid, pristine, form, values }) => {
        const ticketFeeTotal = feeTotal(values.ticketFees)
        return (
          <>
            <Modal isOpen={formModalOpen} className={styles.formModalContainer}>
              <TicketFormUnsavedModal
                currentPath={currentPath}
                form={form}
                setFormModalOpen={setFormModalOpen}
                valid={valid}
                unblockRef={unblockRef}
              />
            </Modal>
            <form onSubmit={handleSubmit} onChange={onChange}>
              <div className='dis-panel dis-panel-body'>
                <div className={styles.feeTypeSelect}>
                  <Field name='feeTypeId'>
                    {({ input }) => (
                      <>
                        <label htmlFor='feeTypeId' className='control-label'>Select a Fee Type</label>
                        <FeeTypeSelect
                          form={form}
                          handleSelect={form.mutators.addTicketFee}
                          options={feeTypes}
                          input={input}
                          ticketFeeTotal={ticketFeeTotal}
                        />
                      </>
                    )}
                  </Field>
                </div>
                <div className={styles.ticketFeeItemContainer}>
                  <FieldArray name='ticketFees'>
                    {({ fields }) => {
                      const ticketFeeValue = fields.value
                      return fields.map((ticketFee, idx) => (
                        <TicketFee
                          key={idx}
                          ticket={ticket}
                          ticketFee={ticketFee}
                          onDestroy={onDestroyTicketFee}
                          ticketFeeTotal={ticketFeeTotal}
                          form={form}
                          value={ticketFeeValue[idx]}
                          index={idx}
                          formatValue={formatValue}
                          updateDiscountAndTax={updateDiscountAndTax}
                        />
                      ))
                    }
                    }
                  </FieldArray>
                </div>
              </div>

              <FormSpy subscription={{ values: true }}>
                {({ values }) => {
                  const discountAmount = values.discountAndTax.discountAmount ? values.discountAndTax.discountAmount : 0
                  const taxAmount = values.discountAndTax.taxAmount ? values.discountAndTax.taxAmount : 0
                  const total = (ticketFeeTotal - parseFloat(discountAmount)) + parseFloat(taxAmount)
                  const hasTicketFees = values.ticketFees.length > 0
                  return (
                    <>
                      <div className={styles.discountAndTaxesContainer}>
                        {hasInvoiceDiscount
                          ? (<div className={styles.invoiceMessaging}>
                            A discount has been added at the invoice level, so discounts can only be updated on the
                            <EditInvoiceLink />.
                          </div>)
                          : (<> {!values.showDiscountForm && hasTicketFees && showDiscountAndTax && <button
                              type='button'
                              onClick={() => form.change('showDiscountForm', true)}
                              className={`${styles.displayFormButton} ${styles.discountAndTaxesButton}`}>
                            + Add Discount
                          </button> }
                            <DiscountAndTaxFormFields
                              handleOnChangeAmount={handleOnChangeDiscountAmount}
                              handleOnChangePercent={handleOnChangeDiscountPercent}
                              remove={() => removeDiscount(form)}
                              ticketFeeTotal={ticketFeeTotal}
                              type='discount'
                              form={form}
                              show={values.showDiscountForm}
                              isConnectedToQB={isConnectedToQB}
                            /></>)}
                        {hasTicketFees && showDiscountAndTax && <div className={cn(styles.totalContainer, styles.subtotal, {
                          [styles.showDiscountAndTaxTotal]: showDiscountAndTax
                        })}>
                          <div>Subtotal</div>
                          <div>
                            ${commaDeliminate(ticketFeeTotal - parseFloat(discountAmount))}
                          </div>
                        </div>}
                        {hasInvoiceTax
                          ? (<div className={styles.invoiceMessaging}>
                            A tax has been added at the invoice level, so taxes can only be updated on the
                            <EditInvoiceLink />.
                          </div>)
                          : (<> {!values.showTaxForm && hasTicketFees && showDiscountAndTax && <button
                              type='button'
                              onClick={() => form.change('showTaxForm', true)}
                              className={cn(styles.displayFormButton, styles.discountAndTaxesButton)}>
                            + Add Tax
                          </button>}
                            <DiscountAndTaxFormFields
                              handleOnChangeAmount={handleOnChangeTaxAmount}
                              handleOnChangePercent={handleOnChangeTaxPercent}
                              handleOnChangeTaxCode={handleOnChangeTaxCode}
                              remove={() => removeTax(form)}
                              ticketFeeTotal={ticketFeeTotal}
                              type='tax'
                              form={form}
                              show={values.showTaxForm}
                              isConnectedToQB={isConnectedToQB}
                              usingAST={usingAST}
                              taxCodes={taxCodes}
                            /></>
                            )}
                        {hasTicketFees && <div className={cn(styles.totalContainer, styles.total, {
                          [styles.showDiscountAndTaxTotal]: showDiscountAndTax
                        })}>
                          <div>Total</div>
                          <div>
                            ${commaDeliminate(total <= 0 ? 0 : total)}
                          </div>
                        </div>}
                      </div>

                      {!qboInvoiced && <div className={styles.containerBottom}>
                        {isEditingInvoicedFees
                          ? <button
                              type='button'
                              className='dis-btn dis-btn-lg dis-btn-no-shadow'
                              onClick={() => setIsEditingInvoicedFees(false)}>
                            Cancel
                          </button>
                          : <button
                              disabled={isFetching || pristine}
                              type='submit'
                              className={cn('dis-btn dis-btn-lg dis-btn-no-shadow', {
                                [styles.formButton]: showCreateInvoice,
                                'dis-btn-primary': !showCreateInvoice
                              })}
                              onClick={() => handleSaveFees(form)}>
                            {isFetching ? <Spinner name='circle' /> : 'Save Fees'}
                          </button>}
                        {showCreateInvoice &&
                          <button
                            disabled={isFetching || !valid}
                            type='submit'
                            className={cn(styles.createInvoiceBtn, 'dis-btn dis-btn-primary dis-btn-lg')}
                            onClick={() => handleCreateInvoice(form)}>
                            {isFetching ? <Spinner name='circle' /> : saveCreateText()}
                          </button>}
                      </div>}
                    </>
                  )
                }}
              </FormSpy>
            </form>
          </>
        )
      }}
    </Form>
  )
}

TicketFeeForm.propTypes = {
  feeTypes: PropTypes.array,
  hasInvoiceDiscount: PropTypes.bool.isRequired,
  hasInvoiceTax: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    discountAndTax: PropTypes.object,
    newTicketFees: PropTypes.array,
    ticketFees: PropTypes.array
  }).isRequired,
  showCreateInvoice: PropTypes.bool.isRequired,
  showDiscountAndTax: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  qboInvoiced: PropTypes.bool,
  ticket: PropTypes.object,
  isEditingInvoicedFees: PropTypes.bool.isRequired,
  setIsEditingInvoicedFees: PropTypes.func.isRequired,
  isConnectedToQB: PropTypes.bool.isRequired,
  usingAST: PropTypes.bool,
  taxCodes: PropTypes.array,
  returnTo: PropTypes.string,
  unblockRef: PropTypes.object.isRequired
}
