import { loader } from 'graphql.macro'

export default function clientJobs ({ dataLoaders, queryKey }) {
  const [, variables] = queryKey

  if (!variables.sortColumn) {
    return Promise.reject(new Error(`Invalid sort.column: ${variables.sortColumn}`))
  }

  let graphQLVariables = {
    haulerId: variables.haulerId,
    searchFields: {
      clientId: variables.searchFields.clientId,
      status: variables.searchFields.status
    },
    first: variables.first,
    sortColumn: variables.sortColumn,
    sortDirection: variables.sortDirection
  }

  if (variables.pager) {
    const paginatedVariables = {
      before: variables.pager.direction === 'before' ? variables.pager.cursor : null,
      after: variables.pager.direction === 'after' ? variables.pager.cursor : null,
      first: variables.pager.direction === 'after' ? Number(variables.pager.pageSize) : null,
      last: variables.pager.direction === 'before' ? Number(variables.pager.pageSize) : null
    }
    graphQLVariables = { ...graphQLVariables, ...paginatedVariables }
  }

  const schema = loader('./jobsSearch.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(schema, graphQLVariables)
}
