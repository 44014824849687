import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Error from '../../../shared/Error'
import NormalLayoutContainer from '../../../shared/NormalLayoutContainer'
import { PageTitle } from '../../../../components/page-title'
import { Spinner } from '../../../shared/Spinner'
import SiteTypesList from './components/SiteTypesList'
import { action } from '../../../../store/store'
import {
  REQUEST_SITE_TYPES_ACTION,
  CLEAR_SITE_TYPES_ERRORS
} from '../sagas/siteTypesSaga'

export default function HaulerSiteTypesContainer () {
  const history = useHistory()
  const { siteTypes } = useSelector(({ siteTypes }) => ({ siteTypes }))

  const onEdit = (id) => {
    history.push(`/hauler/site-types/${id}/edit`)
  }

  const addNewSiteType = () => {
    history.push('/hauler/site-types/new')
  }

  const clearSiteTypesErrors = () => {
    action(CLEAR_SITE_TYPES_ERRORS, {})
  }

  useEffect(() => {
    action(REQUEST_SITE_TYPES_ACTION, {})
  }, [])

  return (
    <div>
      <NormalLayoutContainer>
        <div className='container-fluid'>
          <PageTitle title='Site Types'>
            <button
              className='btn btn-lg btn-primary pull-right push-down-super-small'
              onClick={addNewSiteType}>
              <span className='pull-left hidden-xs'>New Site Type</span>
              <i className='material-icons pull-right thumbster-button-icon no-margin-bs-xs'>add_circle</i>
            </button>
          </PageTitle>

          <Spinner isFetching={siteTypes.isFetching}>
            <div>
              <SiteTypesList
                siteTypes={siteTypes.siteTypesArray}
                onEdit={onEdit}
              />
            </div>
          </Spinner>
        </div>
      </NormalLayoutContainer>

      <Error errors={siteTypes.errors} clearErrors={clearSiteTypesErrors} />
    </div>
  )
}
