import { loader } from 'graphql.macro'

export default async function sendCustomerInvoice ({ dataLoaders, variables }) {
  const schema = loader('./sendCustomerInvoice.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      invoiceId: variables.invoiceId,
      sendTo: (variables.sendTo ?? '').split(',').map(email => ({ email }))
    }
  })
  return response.sendCustomerInvoice
}
