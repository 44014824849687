import React from 'react'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import fromPairs from 'lodash/fromPairs'
import map from 'lodash/map'
import { TextInput } from '../TextInput'
import { DatePickerInput } from '../DatePickerInput'
import { SelectInput } from '../SelectInput'

export default function InvoiceableTicketsSearchForm ({ onSubmit, resourceTypes, ticketTypes, initialValues, disabled = false }) {
  function handleClear (form) {
    form.reset({})
    form.submit()
  }

  return (
    <div className='container-fluid'>
      <Form onSubmit={onSubmit} initialValues={initialValues}>
        {({ handleSubmit, submitting, form }) => (
          <form onSubmit={handleSubmit} autoComplete='off'>
            <div className='row'>
              <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3'>
                <Field
                  label='Start Date'
                  name='minDate'
                  component={DatePickerInput}
                />
              </div>

              <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3'>
                <Field
                  label='End Date'
                  name='maxDate'
                  component={DatePickerInput}
                />
              </div>

              <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3'>
                <Field
                  name='resourceTypeId'
                  component={SelectInput}
                  label='Asset Type'
                  options={fromPairs(map(resourceTypes, rt => [rt.id, `${rt.name} (${rt.shortCode})`]))}
                />
              </div>

              <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3'>
                <Field
                  name='ticketTypeId'
                  component={SelectInput}
                  label='Ticket Type'
                  options={fromPairs(map(ticketTypes, rt => [rt.id, `${rt.name} (${rt.shortCode})`]))}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3'>
                <Field
                  name='status'
                  component={SelectInput}
                  label='Status'
                  options={{
                    active: '- All Active -',
                    open: '  Open',
                    en_route: '  En Route',
                    terminal: '- All Closed -',
                    cancelled: '  Cancelled',
                    completed: '  Completed'
                  }}
                />
              </div>

              <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3'>
                <Field
                  name='dispatchSearch'
                  component={TextInput}
                  label='Street Address'
                />
              </div>

              <button
                type='button'
                tabIndex={-1}
                onClick={() => handleClear(form)}
                className='btn btn-default push-down-very-small pull-right'
                style={{ marginLeft: 20 }}>
                <span className='pull-left'>Clear</span>
                <i className='material-icons pull-right space-left'>clear</i>
              </button>

              <button
                type='submit'
                disabled={submitting || disabled}
                className='btn btn-success push-down-very-small pull-right'>
                <span className='pull-left'>Search</span>
                <i className='material-icons pull-right space-left'>search</i>
              </button>
            </div>
          </form>
        )}
      </Form>
    </div>
  )
}

InvoiceableTicketsSearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  resourceTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    shortCode: PropTypes.string.isRequired
  })).isRequired,
  ticketTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    shortCode: PropTypes.string.isRequired
  })).isRequired,
  initialValues: PropTypes.shape({
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    resourceTypeId: PropTypes.string,
    ticketTypeId: PropTypes.string,
    status: PropTypes.string,
    dispatchSearch: PropTypes.string
  }),
  disabled: PropTypes.bool
}
