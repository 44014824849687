import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const ProgressBar = ({ fileName, onCancel, progress }) => {
  const uploadActive = progress < 100
  return (
    <div className='mt-2 flex flex-row items-end'>
      <div className='flex flex-col w-full mr-2'>
        <div className='flex justify-start'>
          {
            uploadActive
              ? <i className='material-icons text-xl text-gray-med-dark opacity-80 animate-spin'>autorenew</i>
              : <i className='material-icons text-xl text-green'>check_circle_outline</i>
          }
          <div className='ml-1' style={{ fontSize: '1rem' }}>{fileName}</div>
        </div>
        <div className='border-gray h-4 bg-gray-light flex-grow' style={{ borderWidth: 1, borderRadius: '0.25rem' }}>
          <div
            className={cn('bg-green h-full transition-all duration-1000', { 'animate-pulse': uploadActive })}
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>
      <button
        onClick={onCancel}
        className='flex justify-end flex-row -mb-1 disabled:cursor-not-allowed'>
        <i className='material-icons text-xl text-gray-med-dark opacity-80'>cancel</i>
      </button>
    </div>
  )
}

ProgressBar.propTypes = {
  fileName: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  progress: PropTypes.number.isRequired
}
export default ProgressBar
