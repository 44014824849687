import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../../routes'
import NormalLayoutContainer from '../../../shared/NormalLayoutContainer'
import { PageTitle } from '../../../../components/page-title'
import { Spinner } from '../../../shared/Spinner'
import ResourceTypesList from './components/ResourceTypesList'
import { useSelector } from 'react-redux'
import useQuery from '../../../../hooks/useQuery'
import QUERY_KEYS from '../../../../graphql/queryKeys'
import resourceTypesQuery from '../../../../graphql/queries/resourceTypes'
import { captureErrorAndNotify } from '../../../../utilities/errorHandlers'

export default function HaulerResourceTypesContainer () {
  const { user } = useSelector(({ user }) => ({ user: user.user }))

  const { data: { resourceTypes }, isFetching: isFetchingResourceTypes } = useQuery(
    [QUERY_KEYS.resourceTypes, { haulerId: user.haulerId, includeCounts: true, includeExtra: true }],
    resourceTypesQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Failed to retrieve resource types')
      },
      placeholderData: { resourceTypes: {} }
    }
  )

  return (
    <div>
      <NormalLayoutContainer>
        <div className='container-fluid'>
          <PageTitle title='Asset Types'>
            <Link className='dis-btn dis-btn-lg dis-btn-primary push-down-super-small' to={ROUTES.createResourceType}>
              New Asset Type
              <i className='material-icons pull-right thumbster-button-icon no-margin-bs-xs'>add_circle</i>
            </Link>
          </PageTitle>

          {isFetchingResourceTypes
            ? <Spinner isFetching />
            : (
              <div>
                <ResourceTypesList resourceTypes={resourceTypes} />
              </div>
              )
          }
        </div>
      </NormalLayoutContainer>
    </div>
  )
}

HaulerResourceTypesContainer.propTypes = {
  resourceTypes: PropTypes.shape({
    errors: PropTypes.array.isRequired,
    resourceTypesArray: PropTypes.array
  })
}
