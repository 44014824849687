import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

export function GridRow ({ ticketType, striped, handleChange }) {
  const commonClasses = cn('flex items-center p-8', striped && 'bg-gray-light bg-opacity-30')
  return (
    <div className='contents'>
      <div className={commonClasses}>
        <div className='text-2xl ml-4'>{ticketType.name} ({ticketType.shortCode})</div>
      </div>
      <div className={cn(commonClasses, 'justify-center')}>
        <input
          aria-label='Require Adding an Asset'
          type='checkbox'
          className='h-5 w-5 text-gray-600'
          checked={ticketType.requireAddAsset}
          onChange={() => handleChange({
            ticketTypeId: ticketType.id,
            requireAddAsset: !ticketType.requireAddAsset
          })}
        />
      </div>
      <div className={cn(commonClasses, 'justify-center')}>
        <input
          aria-label='Require Removing an Asset'
          type='checkbox'
          className='h-5 w-5 text-gray-600'
          checked={ticketType.requireRemoveAsset}
          onChange={() => handleChange({
            ticketTypeId: ticketType.id,
            requireRemoveAsset: !ticketType.requireRemoveAsset
          })}
        />
      </div>
      <div className={cn(commonClasses, 'justify-center')}>
        <input
          aria-label='Require Entering a Weight'
          type='checkbox'
          className='h-5 w-5 text-gray-600'
          checked={ticketType.requireEnterWeight}
          onChange={() => handleChange({
            ticketTypeId: ticketType.id,
            requireEnterWeight: !ticketType.requireEnterWeight
          })}
        />
      </div>
      <div className={cn(commonClasses, 'justify-center')}>
        <input
          aria-label='Require Adding an Image'
          type='checkbox'
          className='h-5 w-5 text-gray-600'
          checked={ticketType.requireAddImage}
          onChange={() => handleChange({
            ticketTypeId: ticketType.id,
            requireAddImage: !ticketType.requireAddImage
          })}
        />
      </div>
    </div>
  )
}

GridRow.propTypes = {
  handleChange: PropTypes.func.isRequired,
  striped: PropTypes.bool.isRequired,
  ticketType: PropTypes.object.isRequired
}
