import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import LoginForm from '../components/LoginForm'
import ForgotPasswordButton from '../components/ForgotPasswordButton'
import MobileAppLinks from './shared/MobileAppLinks'
import { ActionText } from './shared/ActionText'
import Alert from './shared/Alert'
import Logo from '../components/Logo'
import useMutation from '../hooks/useMutation'
import loginUserMutation from '../graphql/mutations/loginUser'
import { ROUTES } from '../routes'
import USER_ACTIONS from '../store/user/userActions'
import jwtDecode from 'jwt-decode'
import notify from '../utilities/notify'
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params'
import { captureErrorAndNotify } from '../utilities/errorHandlers'

export default function LoginPage () {
  const dispatch = useDispatch()
  const history = useHistory()
  const { isAuthenticated } = useSelector(({ user: { isAuthenticated } }) => ({
    isAuthenticated
  }))
  const [queryParams] = useQueryParams({
    redirectTo: StringParam,
    showImpersonate: BooleanParam
  })

  const { mutateAsync: loginUser } = useMutation(loginUserMutation, {
    onSuccess ({ accessToken, refreshToken }) {
      window.localStorage.setItem('auth', JSON.stringify({ access_token: accessToken, refresh_token: refreshToken }))
      const decodedJwt = jwtDecode(accessToken)
      dispatch({
        type: USER_ACTIONS.SET_JWT,
        payload: {
          jwt: decodedJwt,
          accessToken,
          refreshToken
        }
      })
    },
    onError (error) {
      if (error.response.status === 401) {
        notify('error', 'Invalid username or password. Please try again.')
      }

      // This really only happens during impersonation when the provided email address does not exist.
      if (error.response.status === 404) {
        notify('error', 'User to impersonate does not exist')
      }

      captureErrorAndNotify(error, 'Server error')
    }
  })

  useEffect(function redirectAfterLogin () {
    if (!isAuthenticated) return
    queryParams.redirectTo ? history.push(queryParams.redirectTo) : history.push(ROUTES.home)
  }, [history, isAuthenticated, queryParams.redirectTo])

  if (isAuthenticated) return null

  return (
    <div className='w-4/5 md:w-1/3 h-screen mx-auto mt-10'>
      <Logo />
      <ActionText text='Log in to use Dispatcher' />
      <LoginForm
        onSubmit={loginUser}
        showImpersonate={Boolean(queryParams.showImpersonate)}
      />
      <ForgotPasswordButton />

      <MobileAppLinks />

      <div className='text-center mt-10'>
        <a className='btn btn-link' href='https://www.dispatcher.com/request-a-demo' rel='noreferrer nofollow'>No login? Request a Demo!</a>
      </div>
      <div className='text-center mt-5'>
        Need help? Email us at <a href='mailto:support@dispatcher.com'>support@dispatcher.com</a>
      </div>
      <Alert />
    </div>
  )
}

LoginPage.propTypes = {
}
