import React from 'react'
import PropTypes from 'prop-types'
import { resourceRectangle, siteCircle } from './icons'

export default function ExtraViewsContainer ({ onToggleResources, onToggleSites, resourcesChecked, sitesChecked }) {
  return (
    <div className='panel panel-default extra-views-container'>
      <div className='container-fluid'>
        <div className='form-group mt-6'>
          <input className='dispatch-extra-views-checkbox' type='checkbox' onChange={onToggleResources} checked={resourcesChecked} />
          Assets
          <img className='inline h-6 ml-1.5 w-10 mb-0.5' alt='assets' src={resourceRectangle().url} />
        </div>

        <div className='form-group mt-4'>
          <input type='checkbox' className='dispatch-extra-views-checkbox' onChange={onToggleSites} checked={sitesChecked} />
          Sites
          <img className='inline h-6 ml-1.5 w-10 mb-0.5' alt='sites' src={siteCircle({}).url} />
        </div>

      </div>
    </div>
  )
}

ExtraViewsContainer.propTypes = {
  onToggleResources: PropTypes.func.isRequired,
  onToggleSites: PropTypes.func.isRequired,
  resourcesChecked: PropTypes.bool.isRequired,
  sitesChecked: PropTypes.bool.isRequired
}
