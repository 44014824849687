import { loader } from 'graphql.macro'

export default async function removeTicketsFromInvoice ({ dataLoaders, variables }) {
  const schema = loader('./removeTicketsFromInvoice.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      invoiceId: variables.invoiceID,
      tickets: variables.tickets
    }
  })
  return response.removeTicketsFromInvoice
}
