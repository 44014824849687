import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import classNames from 'classnames'

export default function CursorPagination ({
  totalCount,
  pageSize,
  pageInfo,
  setSearchFor,
  resetPagination
}) {
  const [pageNumber, setPageNumber] = useState(1)
  const [pageCounter, setPageCounter] = useState(`1 to ${pageSize}`)

  useEffect(() => {
    const topCount = pageNumber * pageSize
    const bottomCount = topCount - pageSize + 1

    setPageCounter(`${bottomCount} to ${topCount > totalCount ? totalCount : topCount}`)
  }, [pageNumber, pageSize, totalCount])

  useEffect(() => {
    if (resetPagination) {
      setPageNumber(1)
    }
  }, [resetPagination])

  function handleButton (direction, cursor) {
    const { hasPreviousPage, hasNextPage } = pageInfo

    if ((direction === -1 && !hasPreviousPage) || (direction === 1 && !hasNextPage)) {
      return
    }

    setPageNumber(pageNumber + Number(direction))

    if (direction === 1) {
      return setSearchFor({
        cursor,
        direction: 'after'
      })
    }

    if (direction === -1) {
      return setSearchFor({
        cursor,
        direction: 'before'
      })
    }
  }

  return (
    <div className={classNames(styles.pagination, { [styles.hidden]: !pageInfo })}>
      <button
        className={classNames(styles.button, {
          [`${styles.enabledButton}`]: pageInfo?.hasPreviousPage,
          [`${styles.disabledButton}`]: !pageInfo?.hasPreviousPage
        })}
        type='button'
        disabled={!pageInfo?.hasPreviousPage}
        onClick={() => handleButton(-1, pageInfo.startCursor)}>
        &larr; Previous
      </button>

      <div className={styles.results}>
        {<>Displaying Results {pageCounter}</>}
        <strong>
          Total Results: {totalCount}
        </strong>
      </div>

      <button
        className={classNames(styles.button, {
          [`${styles.enabledButton}`]: pageInfo?.hasNextPage,
          [`${styles.disabledButton}`]: !pageInfo?.hasNextPage
        })}
        type='button'
        disabled={!pageInfo?.hasNextPage}
        onClick={() => handleButton(1, pageInfo.endCursor)}>
        Next &rarr;
      </button>
    </div>
  )
}

CursorPagination.propTypes = {
  totalCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageInfo: PropTypes.shape({
    endCursor: PropTypes.string,
    hasNextPage: PropTypes.bool.isRequired,
    hasPreviousPage: PropTypes.bool.isRequired,
    startCursor: PropTypes.string
  }).isRequired,
  setSearchFor: PropTypes.func.isRequired,
  resetPagination: PropTypes.bool.isRequired
}
