import { loader } from 'graphql.macro'

export default async function destroySite ({ dataLoaders, variables }) {
  const schema = loader('./destroySite.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      id: variables.id
    }
  })
  return response.destroySite
}
