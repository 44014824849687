import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CsvExportExplainer from './CsvExportExplainer'

export default function ErrorMessage ({ errors }) {
  const [expanded, setExpanded] = useState(false)
  return errors.map((error) => {
    const invalidExtension = error.code === 'invalid-extension'
    const toggleExpanded = () => setExpanded(!expanded)
    return (
      <div
        key={`${error.code}${error.message}`}
        className='bg-red bg-opacity-20 border-red p-1 mt-4'
        style={{ borderWidth: 1 }}>
        <div className='flex justify-start items-center'>
          <i className='material-icons text-3xl text-red ml-2 mr-2'>error</i>
          <div className='opacity-100 flex justify-start'>
            {error.message}
            {
              invalidExtension &&
                <div
                  tabIndex='0'
                  role='link'
                  onKeyPress={toggleExpanded}
                  onClick={toggleExpanded}
                  className='text-blue font-medium flex justify-start items-center cursor-pointer'>
                &nbsp;How to create a .csv
                  <i className='material-icons text-2xl ml-2 mr-2'>double_arrow</i>
                </div>
            }
          </div>
        </div>
        {expanded && <CsvExportExplainer />}
      </div>
    )
  })
}

ErrorMessage.propTypes = {
  errors: PropTypes.string.isRequired
}
