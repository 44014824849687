import React, { useEffect, useState } from 'react'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import PageTitle from '../../components/page-title'
import styles from './index.module.css'
import cn from 'classnames'
import { Spinner } from '../shared/Spinner'
import ClientTableSearchForm from '../../components/clients-table-search-form'
import { ObjectParam, StringParam, useQueryParams } from 'use-query-params'
import QUERY_KEYS from '../../graphql/queryKeys'
import clientsQuery from '../../graphql/queries/clients'
import { useSelector } from 'react-redux'
import InvoiceableAccountsTable from '../../components/invoice/InvoiceableAccountsTable'
import useQuery from '../../hooks/useQuery'

const pageSize = 25

export default function InvoiceNewClientPage () {
  const [fakeCount, setFakeCount] = useState(null)
  const { user } = useSelector(({ user }) => ({ user: user.user }))

  const [queryParams, setQueryParams] = useQueryParams({
    sort: ObjectParam,
    generalSearch: StringParam,
    pager: ObjectParam,
    ticketStatusSelect: StringParam
  })

  const { data: { clients }, isFetching } = useQuery([
    QUERY_KEYS.clients,
    user.haulerId,
    queryParams.sort,
    queryParams.generalSearch || null,
    queryParams.pager,
    1,
    queryParams.ticketStatusSelect || null
  ],
  clientsQuery,
  {
    enabled: Boolean(queryParams.sort),
    placeholderData: { clients: undefined }
  })

  // Always make sure we have the bare minimum query parameters by setting reasonable defaults if not already set.
  useEffect(function setDefaultQueryParams () {
    const defaultQueryParams = {}
    if (!queryParams.sort) {
      defaultQueryParams.sort = { column: 'customId', direction: 'desc' }
    }
    if (!queryParams.generalSearch) {
      defaultQueryParams.generalSearch = ''
    }
    if (!queryParams.ticketStatusSelect) {
      defaultQueryParams.ticketStatusSelect = ''
    }
    setQueryParams(defaultQueryParams)
  }, [queryParams, setQueryParams])

  // We are using cursor based pagination which means we don't have the concept of pages, but we can simulate pages
  // in certain scenarios like when the user starts at the beginning and goes forward. If the user starts in the middle
  // of a paginated set then we have no hope in providing this pseudo page based pagination. This is NOT a great solution
  // but should handle making our cursor based pagination match the look and feel of the old pagination as much as possible.
  // Ideally we would have a load more or an infinite scroll type feature instead of pages.
  useEffect(function toggleFakePagination () {
    if (!clients || isFetching || fakeCount !== null) return
    setFakeCount({ start: 1, end: Math.min(pageSize, clients.totalCount) })
  }, [clients, isFetching, fakeCount, setFakeCount])

  function handleSearch ({ generalSearch, ticketStatusSelect = null }) {
    setFakeCount(null)
    setQueryParams({ generalSearch, ticketStatusSelect })
  }

  function handlePageRequest (direction, cursor) {
    setQueryParams({ pager: { cursor, direction, pageSize } })

    if (!fakeCount) return
    if (direction === 'before') {
      setFakeCount(prevFakeCount => ({ start: prevFakeCount.start - pageSize, end: prevFakeCount.end - pageSize }))
    }
    if (direction === 'after') {
      setFakeCount(prevFakeCount => ({ start: prevFakeCount.start + pageSize, end: prevFakeCount.end + pageSize }))
    }
  }

  function handleSortChange (newSortColumn) {
    let newSortDirection = 'desc'
    if (newSortColumn === queryParams.sort.column) {
      newSortDirection = queryParams.sort.direction === 'asc' ? 'desc' : 'asc'
    }

    setFakeCount(null)
    setQueryParams({ sort: { column: newSortColumn, direction: newSortDirection } })
  }

  function handleClearSearch () {
    setFakeCount(null)
    setQueryParams({}, 'replace')
  }

  return (
    <NormalLayoutContainer showBackLink>
      <PageTitle>New Invoice: Select an Account</PageTitle>
      <div className={cn(styles.searchForm, 'dis-panel')}>
        <div className='dis-panel-body'>
          <ClientTableSearchForm
            onSubmit={handleSearch}
            onClear={handleClearSearch}
            initialValues={{ generalSearch: queryParams.generalSearch }}
            disabled={isFetching}
            hideButtons={{ export: true }}
            showTicketStatusSelect={true}
          />
        </div>
      </div>
      {isFetching || !queryParams.sort
        ? <Spinner isFetching />
        : (
          <div className='dis-panel'>
            <div className='dis-panel-body'>
              <InvoiceableAccountsTable
                clients={clients}
                sort={queryParams.sort}
                onPageRequest={handlePageRequest}
                onSortChange={handleSortChange}
                fakeCount={fakeCount}
              />
            </div>
          </div>
          )
      }
    </NormalLayoutContainer>
  )
}
