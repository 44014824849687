import React, { useMemo } from 'react'
import { generatePath, useParams, Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ROUTES } from '../../routes'
import cn from 'classnames'
import styles from './index.module.scss'
import useMutation from '../../hooks/useMutation'
import useQuery from '../../hooks/useQuery'
import notify from '../../utilities/notify'
import updateInvoiceDiscountAndTax from '../../graphql/mutations/updateInvoiceDiscountAndTax'
import { formatDiscountAndTaxValues } from '../../components/discount-and-tax-form-fields/utils'
import invoiceQuery from '../../graphql/queries/invoice'
import quickbooksTaxCodeRatesQuery from '../../graphql/queries/quickbooksTaxCodeRates'
import invoiceSettingsQuery from '../../graphql/queries/invoiceSettings'
import QUERY_KEYS from '../../graphql/queryKeys'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import InvoiceDiscountTaxForm from '../../components/invoice-discount-tax-form'
import InvoiceLineItemsTable from '../../components/invoice-line-items-table'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import { Spinner } from '../shared/Spinner'
import PageTitle from '../../components/page-title'
import { moneyUnformatted } from '../../utilities/stringUtilities'

export default function InvoiceEditPage () {
  const { id } = useParams()
  const { hauler } = useSelector(({ user }) => ({ hauler: user.hauler }))
  const history = useHistory()

  const { data: { invoice } = {}, isFetching, refetch: refetchInvoice } = useQuery([QUERY_KEYS.invoice, id], invoiceQuery, {
    onError (error) {
      captureErrorAndNotify(error, 'Error fetching invoice')
    },
    placeholderData: { invoice: {} }
  })

  const hasInvoiceTickets = useMemo(() => {
    return invoice?.tickets?.length > 0
  }, [invoice])

  const { data: { quickbooksTaxCodeRates }, isFetching: isFetchingQboTaxCodeRates } = useQuery([
    QUERY_KEYS.quickBooksTaxCodes,
    hauler.id
  ],
  quickbooksTaxCodeRatesQuery,
  {
    enabled: hauler.quickbooks.isConnected,
    onError (error) {
      captureErrorAndNotify(error, 'Unable to fetch Quickbooks tax rates list')
    },
    initialData: { quickbooksTaxCodeRates: {} }
  })

  const { data: invoiceSettingsData, isFetching: isFetchingInvoiceSettings } = useQuery([
    QUERY_KEYS.invoiceSettings,
    hauler.id
  ],
  invoiceSettingsQuery,
  {
    onError: (error) => captureErrorAndNotify(error, 'Error Fetching Invoice Settings')
  })

  const { mutate: updateInvoiceDiscountAndTaxMutation, isLoading: isUpdatingInvoice } = useMutation(updateInvoiceDiscountAndTax, {
    onSuccess () {
      notify('success', 'Invoice Updated Successfully')
      handleNavigation()
    },
    onError (error) {
      captureErrorAndNotify(error, 'Failed To Update Invoice')
    }
  })

  function handleNavigation () {
    if (invoiceSettingsData?.invoiceSettings.sendInvoicesFrom === 'DISPATCHER' || hauler.isWastepayConnected) {
      history.push(`${generatePath(ROUTES.invoicePayment, { id })}`)
    } else if (hauler.quickbooks.isConnected) {
      history.push(generatePath(ROUTES.invoicesExport, { id }))
    }
  }

  function handleSubmit (vals, form) {
    if (form?.getState()?.pristine) {
      handleNavigation()
    } else {
      const { discountAndTax } = vals
      const formattedDiscountValues = formatDiscountAndTaxValues(
        discountAndTax.discountAmount,
        discountAndTax.discountPercent,
        discountAndTax.discountMethod
      )
      const formattedTaxValues = formatDiscountAndTaxValues(discountAndTax.taxAmount, discountAndTax.taxPercent, discountAndTax.taxMethod)
      updateInvoiceDiscountAndTaxMutation({
        haulerId: hauler.id,
        invoiceId: id,
        discountAndTax: {
          discountAmount: formattedDiscountValues.formattedAmount,
          discountPercent: formattedDiscountValues.formattedPercent,
          discountMethod: formattedDiscountValues.formattedMethod,
          taxAmount: formattedTaxValues.formattedAmount,
          taxPercent: formattedTaxValues.formattedPercent,
          taxMethod: formattedTaxValues.formattedMethod,
          qboTaxCodeId: discountAndTax.qboTaxCodeId
        }
      })
    }
  }

  return (
    <NormalLayoutContainer showBackLink>
      {isFetching || isFetchingQboTaxCodeRates || isFetchingInvoiceSettings
        ? <Spinner isFetching />
        : (
          <>
            <PageTitle>Invoice #{invoice?.dispatcherInvoiceNumber}</PageTitle>
            <div className={cn(styles.invoiceContainer, 'dis-panel')}>
              <h4 className={styles.invoiceNumber}>Invoice #{invoice?.dispatcherInvoiceNumber}</h4>
              <div>{invoice?.client?.name}</div>
              <InvoiceLineItemsTable
                invoice={invoice}
                showActionColumn={true}
                showLineItemTotals={true}
                showDiscountAndTax={hauler.country === 'US'}
                editRoute='editInvoice'
                refetchInvoice={refetchInvoice}
              />
              {!hasInvoiceTickets && <div className='dis-btn dis-btn-lg dis-btn-primary'>
                <Link
                  className={styles.addTicketLink}
                  to={{
                    pathname: `${generatePath(ROUTES.invoiceEditAddTickets, { invoiceId: invoice.id })}`
                  }}>
                  Add Ticket
                </Link>
                <i className='material-icons dis-btn-icon'>add_circle</i>
              </div>}
              <div className={cn(styles.lowerContainer)}>
                {hasInvoiceTickets && <div>
                  <Link
                    className={cn('dis-btn dis-btn-lg dis-btn-blank', styles.addTicket)}
                    to={{
                      pathname: `${generatePath(ROUTES.invoiceEditAddTickets, { invoiceId: invoice.id })}`
                    }}>
                    <i className='material-icons dis-btn-icon'>add_circle</i>
                    Add Ticket
                  </Link>
                </div>}
                <InvoiceDiscountTaxForm
                  handleSubmit={handleSubmit}
                  hasTicketDiscount={invoice?.tickets?.some(tckt => tckt?.discountAndTax?.discountAmount)}
                  hasTicketTax={invoice?.tickets?.some(tckt => tckt?.discountAndTax?.taxAmount)}
                  hasInvoiceTickets={hasInvoiceTickets}
                  isConnectedToQB={hauler.quickbooks.isConnected}
                  isLoading={isUpdatingInvoice}
                  showDiscountAndTax={hauler.country === 'US'}
                  taxCodes={quickbooksTaxCodeRates?.taxCodes}
                  usingAST={hauler.quickbooks.isConnected && quickbooksTaxCodeRates?.usingAST}
                  ticketFeeTotal={invoice.ticketFeeTotal}
                  initialValues={{
                    discountAndTax: {
                      discountAmount: moneyUnformatted(invoice?.discountTotal) || moneyUnformatted(invoice?.discountAndTax?.discountAmount),
                      discountPercent: invoice?.discountAndTax?.discountPercent || 0,
                      discountMethod: invoice?.discountAndTax?.discountMethod,
                      taxAmount: moneyUnformatted(invoice?.taxTotal) || moneyUnformatted(invoice?.discountAndTax?.taxAmount),
                      taxPercent: invoice?.discountAndTax?.taxPercent || 0,
                      taxMethod: invoice?.discountAndTax?.taxMethod,
                      qboTaxCodeId: invoice?.discountAndTax?.qboTaxCodeId
                    },
                    showDiscountForm: Boolean(invoice?.discountAndTax?.discountAmount || invoice?.discountAndTax?.discountPercent),
                    showTaxForm: Boolean(invoice?.discountAndTax?.taxAmount || invoice?.discountAndTax?.taxPercent),
                    qboNonAST: hauler.quickbooks.isConnected && !quickbooksTaxCodeRates?.usingAST
                  }}
                />
              </div>
            </div>
          </>
          )}
    </NormalLayoutContainer>
  )
}
