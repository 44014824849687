import { EmailValidator, PrimaryPhoneValidator } from '../../utilities/commonValidators'

export const validate = (values) => {
  const errors = {}

  if (!values.name) {
    errors.name = 'Required'
  }
  if (values.name && values.name.length > 100) {
    errors.name = 'Account Name must be less than 100 characters'
  }

  if (values.first_name && values.first_name.length > 100) {
    errors.first_name = 'First Name must be less than 100 characters'
  }

  if (values.last_name && values.last_name.length > 100) {
    errors.last_name = 'Last Name must be less than 100 characters'
  }

  if (values.email && !EmailValidator.test(values.email)) {
    errors.email = 'Invalid email'
  }

  if (values.primary_phone && !PrimaryPhoneValidator(values.primary_phone)) {
    errors.primary_phone = 'Invalid Phone Number'
  }

  return errors
}
