import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import InventoryWidgetTable from './InventoryWidgetTable'
import { gql } from 'graphql-request'

export default function InventoryWidget () {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [siteTypes, setSiteTypes] = useState([])
  const [assetTypes, setAssetTypes] = useState([])
  const [inventory, setInventory] = useState([])
  const [userPreferences, setUserPreferences] = useState(null)
  const { user, graphQLClient } = useSelector(({ user, dataLoaders }) => ({
    user: user.user,
    graphQLClient: dataLoaders.dispatcher.graphQLClient
  }))

  useEffect(function onInitialRender () {
    if (isOpen) {
      const query = gql`
        query AssetInventory($haulerId: ID!, $userId: ID!) {
          assetTypes(haulerId: $haulerId) {
            id
            shortCode
          }
          siteTypes(haulerId: $haulerId) {
            id
            name
            shortCode
          }
          assetInventory(haulerId: $haulerId, userId: $userId) {
            sites {
              siteType {
                id
                name
                shortCode
              }
              id
              yard
              assetTypes {
                id
                shortCode
                count
              }
            }
            userPreferences {
              preferredAssetTypes
              preferredSiteTypes
            }
          }
        }
      `
      graphQLClient.request(query, { haulerId: user.haulerId, userId: user.id })
        .then(({ assetTypes, siteTypes, assetInventory }) => {
          setSiteTypes(siteTypes)
          setInventory(assetInventory.sites)
          setAssetTypes(assetTypes)
          setUserPreferences(assetInventory.userPreferences)
        })
    }
  }, [user.haulerId, user.id, dispatch, isOpen, graphQLClient])

  return (
    <div className='inventory-widget'>
      <div className={classNames('inventory-widget__controls', {
        'inventory-widget__controls__open': isOpen
      })}>
        <i
          className={classNames('inventory-widget__controls_arrow material-icons', {
            'inventory-widget__controls_arrow__open': isOpen
          })}
          role='button'
          onClick={() => setIsOpen(currentIsOpen => !currentIsOpen)}
          aria-hidden='true'>
          double_arrow
        </i>
      </div>
      {
        isOpen
          ? <div className='inventory-widget__content'>
            <InventoryWidgetTable inventory={inventory} siteTypes={siteTypes} assetTypes={assetTypes} userPreferences={userPreferences} />
          </div>
          : <div className='inventory-widget__title'>
            <div className='inventory-widget__title_text'
              role='button'
              aria-hidden='true'
              onClick={() => setIsOpen(currentIsOpen => !currentIsOpen)}>
              Asset Inventory
            </div>
          </div>
      }
    </div>
  )
}
