import React from 'react'
import { Form, Field } from 'react-final-form'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './edit-resource-type.module.scss'
import { enumToTitleCase } from '../../utilities/stringUtilities'

const required = value => (value ? undefined : 'Required')
export default function EditResourceTypeForm ({
  initialValues = {},
  onSubmit,
  isFetching = false,
  resErrors,
  billingStatuses
}) {
  return (
    <div className='dis-panel'>
      <div className='dis-panel-body'>
        <Form onSubmit={onSubmit} initialValues={initialValues}>
          {({ handleSubmit, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <div className='font-size-1-5em'>Change Name and Short Code</div>
              <div className={styles.nameShortCode}>
                <Field name='name' validate={required}>
                  {({ input, meta }) => (
                    <div className={classNames(styles.textInput, {
                      'has-error': meta.error || (!meta.dirtySinceLastSubmit && Boolean(resErrors.name.length))
                    })}>
                      <label className='control-label' htmlFor='name'>Asset Type Name</label>
                      <input
                        {...input}
                        type='text'
                        className='form-control'
                        maxLength='15'
                      />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                      {!meta.dirtySinceLastSubmit && Boolean(resErrors.name.length) && <h6>{resErrors.name}</h6> }
                    </div>
                  )}
                </Field>
                <Field name='shortCode' validate={required}>
                  {({ input, meta }) => (
                    <div className={classNames(styles.textInput, {
                      'has-error': meta.error || (!meta.dirtySinceLastSubmit && Boolean(resErrors.shortCode.length))
                    })}>
                      <label className='control-label' htmlFor='short code'>Asset Type Short Code</label>
                      <input
                        {...input}
                        type='text'
                        className='form-control'
                        maxLength='2'
                      />
                      {meta.error && meta.touched && <h6>{meta.error}</h6>}
                      {!meta.dirtySinceLastSubmit && Boolean(resErrors.shortCode.length) && <h6>{resErrors.shortCode}</h6>}
                    </div>
                  )}
                </Field>
              </div>
              <h6 className={styles.editMessaging}><em>Updates to the name and short code will be automatically reflected in all existing
                tickets of this type.</em></h6>

              <div className='font-size-1-5em'>Change Settings</div>

              <Field name='defaultBillingStatus'>
                {({ input }) => (
                  <>
                    <label htmlFor='billing status' className='control-label'>Default Billing Status</label>
                    <select
                      {...input}
                      type='select'
                      className='form-control'>
                      <option key='placeholder' value=''></option>
                      {Object.keys(billingStatuses).map((opt) =>
                        (
                          <option
                            value={opt}
                            key={opt}>
                            {enumToTitleCase(opt)}
                          </option>
                        )
                      )}
                    </select>
                  </>
                )}
              </Field>
              <h6 className={styles.editMessaging}>
                <em>Updates to settings will be reflected only on tickets created after changes are saved.</em>
              </h6>

              <div className={styles.buttonContainer}>
                <button
                  type='submit'
                  className='dis-btn dis-btn-primary dis-btn-lg'
                  disabled={isFetching || pristine || !values.name || !values.shortCode}>
                  <span className='pull-left'>Save Changes</span>
                  <i className='material-icons pull-right thumbster-button-icon'>check</i>
                </button>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  )
}

EditResourceTypeForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  resErrors: PropTypes.object,
  billingStatuses: PropTypes.object.isRequired
}
