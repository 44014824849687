import { loader } from 'graphql.macro'
import dayjs from '../../utilities/dayjs'

export default async function importQuickbooksCustomers ({ dataLoaders, variables }) {
  const schema = loader('./importQuickbooksCustomers.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      haulerId: variables.haulerId,
      excludeInactiveCustomers: variables.excludeInactiveCustomers,
      excludeSubCustomers: variables.excludeSubCustomers,
      includeCustomerTypes: variables.includeCustomerTypes,
      invoiceDates: variables.invoiceDates === undefined
        ? undefined
        : {
            fromDate: dayjs(variables.invoiceDates.fromDate).format('YYYY-MM-DD'),
            toDate: dayjs(variables.invoiceDates.toDate).format('YYYY-MM-DD')
          }
    }
  })
  return response.importQuickbooksCustomers
}
