import React from 'react'
import PropTypes from 'prop-types'

export function Spinner ({ isFetching, children }) {
  if (isFetching) {
    return (
      <div className='thumbster-spinner-showbox'>
        <div className='thumbster-spinner-loader'>
          <svg
            className='thumbster-spinner-circular'
            viewBox='25 25 50 50'>
            <circle
              className='thumbster-spinner-path'
              cx='50'
              cy='50'
              r='20'
              fill='none'
              strokeWidth='2'
              strokeMiterlimit='10'
            />
          </svg>
        </div>
      </div>
    )
  }

  return children
}

Spinner.propTypes = {
  children: PropTypes.node,
  isFetching: PropTypes.bool.isRequired
}
