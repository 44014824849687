import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { generatePath, Link, useParams } from 'react-router-dom'
import { ROUTES } from '../../routes'
import styles from './index.module.scss'
import cn from 'classnames'
import exportInvoiceToQbo from '../../graphql/mutations/exportInvoiceToQbo'
import wastepayChargeSavedCardMutation from '../../graphql/mutations/wastepayChargeSavedCard'
import wastepayChargeUnsavedCardMutation from '../../graphql/mutations/wastepayChargeUnsavedCard'
import updateInvoiceMutation from '../../graphql/mutations/updateInvoice'
import sendCustomerInvoiceMutation from '../../graphql/mutations/sendCustomerInvoice'
import wastepayGetCustomerCardsQuery from '../../graphql/queries/wastePayGetCustomerCards'
import QUERY_KEYS from '../../graphql/queryKeys'
import { Spinner } from '../shared/Spinner'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import PageTitle from '../../components/page-title'
import InvoicePreview from '../../components/invoice-preview'
import PaymentDetails from '../../components/payment-details'
import QboWastepayExport from '../../components/qbo-wastepay-export'
import TransactionConfirmation from '../../components/transaction-confirmation'
import useQuery, { generateQueryKey } from '../../hooks/useQuery'
import invoicePaymentDetailsQuery from '../../graphql/queries/invoicePaymentDetails'
import useMutation from '../../hooks/useMutation'
import { captureError, captureErrorAndNotify, handleQBOInvoiceExportError } from '../../utilities/errorHandlers'
import { useQueryClient } from 'react-query'
import notify from '../../utilities/notify'
import PaymentConfirmationTransactionTable from '../../components/transaction-confirmation/ConfirmationTransactionTable'
import invoiceSettingsQuery from '../../graphql/queries/invoiceSettings'
import { BooleanParam, useQueryParams } from 'use-query-params'
import { cardBrandToEnum } from '../../utilities/card'
import Accordion from '../../components/accordion'
import SendInvoiceForm from '../../components/send-invoice-form'
import dayjs from '../../utilities/dayjs'
import createDispatcherPaymentMutation from '../../graphql/mutations/createDispatcherPayment'
import exportAllPaymentsToQuickbooks from '../../graphql/mutations/exportAllPaymentsToQuickbooks'
import { formatPaymentsForQBOExportConfirmation } from '../../utilities/invoiceUtilities'
import QboExportInvoiceSuccess from '../../components/qbo-wastepay-export/QBOExportInvoiceSuccess'

export default function InvoicePaymentPage () {
  const queryClient = useQueryClient()
  const { id } = useParams()
  const { hauler, user } = useSelector(({ user }) => ({
    hauler: user.hauler,
    user: user.user,
    accessToken: user.accessToken
  }))
  const [queryParams] = useQueryParams({
    exportAttempted: BooleanParam
  })
  const [transactionDetails, setTransactionDetails] = useState(null)
  const [cardError, setCardError] = useState([])
  const [newCard, setNewCard] = useState(null)
  const [paymentEmails, setPaymentEmails] = useState(null)
  const [paymentProvider, setPaymentProvider] = useState(null)

  const { data: invoiceData, isFetching: isFetchingInvoice } = useQuery(
    [QUERY_KEYS.invoicePaymentDetails, id],
    invoicePaymentDetailsQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Error fetching invoice details')
      }
    })

  const { data: { wastepayGetCustomerCards: clientCards } = {}, isFetching: cardListFetching, isError: cardQueryError, refetch: refetchCardList } = useQuery(
    [QUERY_KEYS.wastepayCustomerCardList, { clientId: invoiceData?.invoice.client.id }],
    wastepayGetCustomerCardsQuery,
    {
      enabled: Boolean(invoiceData?.invoice?.client?.wastepayCustomerId),
      onError (err) {
        captureError(err)
      },
      placeholderData: { wastepayGetCustomerCards: [] }
    })

  const { data: invoiceSettingsData, isFetching: isFetchingInvoiceSettings } = useQuery([
    QUERY_KEYS.invoiceSettings,
    hauler.id,
    true
  ],
  invoiceSettingsQuery,
  {
    onError: (error) => captureErrorAndNotify(error, 'Error Fetching Invoice Settings')
  })

  const { mutateAsync: exportToQBO, isLoading: exportToQBOLoading, isSuccess: isQboExportSuccess } = useMutation(exportInvoiceToQbo, {
    onSuccess ({ invoice: newInvoiceData }) {
      queryClient.setQueryData(generateQueryKey([QUERY_KEYS.invoicePaymentDetails, id], user.id), (oldData) => ({
        ...oldData,
        invoice: { ...oldData.invoice, ...newInvoiceData }
      }))
    },
    onError: handleQBOInvoiceExportError
  })

  const { mutateAsync: wastepayChargeSavedCard, isLoading: isChargingSavedCard } = useMutation(wastepayChargeSavedCardMutation, {
    onSuccess: onChargeCardSuccess,
    onError: onChargeCardError,
    onSettled: onChargeCardSettled
  })

  const { mutateAsync: wastepayChargeUnsavedCard, isLoading: isChargingUnsavedCard } = useMutation(wastepayChargeUnsavedCardMutation, {
    onSuccess: onChargeCardSuccess,
    onError: onChargeCardError,
    onSettled: onChargeCardSettled
  })

  const { mutate: createDispatcherPayment, isLoading: createDispatcherPaymentLoading } = useMutation(createDispatcherPaymentMutation, {
    onSuccess ({ dispatcherPayment: transaction, invoice: newInvoiceData }) {
      queryClient.setQueryData(generateQueryKey([QUERY_KEYS.invoicePaymentDetails, id], user.id), (oldData) => {
        return {
          ...oldData,
          invoice: { ...oldData.invoice, ...newInvoiceData }
        }
      })
      setPaymentProvider('DISPATCHER')
      setTransactionDetails({
        id: transaction.id,
        date: transaction.paymentDate,
        amount: transaction.paymentAmount,
        qbo: {
          link: transaction.qboPaymentUrl,
          exportStatus: transaction.qboPaymentExportStatus
        },
        transactionType: 'sale'
      })
      notify('success', 'Payment successful')
    },
    onError (error) {
      captureErrorAndNotify(error, 'Error saving payment')
    }
  })

  const { mutate: retryPaymentsExport } = useMutation(exportAllPaymentsToQuickbooks, {
    onSuccess ({ invoice: newInvoiceData }) {
      queryClient.setQueryData(generateQueryKey([QUERY_KEYS.invoicePaymentDetails, id], user.id), (oldData) => ({
        ...oldData,
        invoice: { ...oldData.invoice, ...{ dispatcherPayments: newInvoiceData.dispatcherPayments }, ...{ wastepaySales: newInvoiceData.wastepaySales } }
      }))
      notify('success', 'Payments export successful')
    },
    onError (error) {
      captureErrorAndNotify(error, 'Error exporting payments to Quickbooks')
    }
  })

  const { mutateAsync: updateInvoice } = useMutation(updateInvoiceMutation, {
    onSuccess ({ invoice: newInvoiceData }, { action }) {
      const queryKey = generateQueryKey([QUERY_KEYS.invoicePaymentDetails, id], user.id)
      queryClient.setQueryData(queryKey, (oldData) => ({
        ...oldData,
        invoice: {
          ...(oldData?.invoice ?? {}),
          ...newInvoiceData
        }
      }))

      if (action !== 'send') {
        notify('success', 'Invoice saved')
      }
    },
    onError (error) {
      captureErrorAndNotify(error, 'Error saving invoice')
    }
  })

  const { mutateAsync: sendCustomerInvoice } = useMutation(sendCustomerInvoiceMutation, {
    onSuccess () {
      notify('success', 'Invoice saved and sent')
    },
    onError (error) {
      captureErrorAndNotify(error, 'Error sending invoice')
    }
  })

  const pageTitle = useMemo(() => {
    if (isFetchingInvoice) return null

    const customIdsMap = invoiceData?.invoice?.tickets?.map(ticket => ticket.customId) || []
    return `${transactionDetails?.id
      ? 'Credit Card Payment'
      : 'Invoice'} for Ticket${invoiceData?.invoice?.tickets?.length > 1 ? 's' : ''} #${customIdsMap?.join(', #')}`
  }, [invoiceData?.invoice, transactionDetails, isFetchingInvoice])

  const actionsRequired = useMemo(() => {
    if (!invoiceData?.invoice?.id) return
    const unexportedFeeTypes = invoiceData?.invoice?.tickets?.reduce((accumulator, ticket) => {
      accumulator = [...accumulator, ...ticket.ticketFees.filter(feeType => feeType.qboItemId === null)]
      return accumulator
    }, [])

    return {
      qboClientExportNeeded: hauler.quickbooks.isConnected && !invoiceData?.invoice.client.existsInQbo,
      feeTypesExportNeeded: hauler.quickbooks.isConnected && unexportedFeeTypes?.length > 0,
      wpClientExportNeeded: hauler.isWastepayConnected && !invoiceData?.invoice.client.wastepayCustomerId
    }
  }, [invoiceData?.invoice, hauler])

  const emailList = useMemo(() => {
    let emailArray = invoiceData?.invoice?.tickets?.flatMap(ticket => [ticket?.job?.email, ticket?.job?.secondaryEmail])
    if (invoiceData?.invoice?.client?.email) {
      emailArray?.push(invoiceData?.invoice?.client.email)
    }
    emailArray = [...new Set(emailArray)]
    return emailArray.filter(email => email != null && email !== '').join(', ')
  }, [invoiceData?.invoice?.tickets, invoiceData?.invoice?.client.email])

  function formatEmailList (emails) {
    return emails.split(',').map(email => ({
      email,
      name: invoiceData?.invoice.client.name
    }))
  }

  function handlePayment (data) {
    switch (data.formType?.toLowerCase()) {
      case 'card': {
        return handleCcPayment(data)
      }
      case 'cash/check': {
        return handleCashCheckPayment(data)
      }
      default:
        captureError(new Error(`Unhandled form type: ${data.formType}`))
    }
  }

  async function handleCcPayment (data) {
    const paymentEmails = data.email.split(/[\s,]+/)
    setCardError([])
    setPaymentEmails(paymentEmails)

    if (data.isUnsavedCard) {
      return chargeUnsavedCard(data)
    }

    return chargeSavedCard({ ...data, savedCard: data.saleCC })
  }

  function handleCashCheckPayment (data) {
    return createDispatcherPayment({
      invoiceId: id,
      ...data
    })
  }

  function handleRetryPaymentExport () {
    return retryPaymentsExport({ id })
  }

  function chargeSavedCard (data) {
    return wastepayChargeSavedCard({
      amount: invoiceData?.invoice?.outstandingBalance,
      cardId: data.saleCC,
      description: data.description,
      invoiceId: id,
      sendTo: formatEmailList(data.email)
    })
  }

  async function chargeUnsavedCard (data) {
    return wastepayChargeUnsavedCard({
      amount: invoiceData?.invoice?.outstandingBalance,
      invoiceId: invoiceData?.invoice.id,
      description: data.description, // the heck is this?
      sendTo: formatEmailList(data.email),
      addressLine1: data.streetAddress,
      city: data.city,
      state: data.state,
      zip: data.zip,
      countryCode: hauler.country,
      // all of this below will come from the lightbox return
      cardBrand: cardBrandToEnum(data.cardShortDescription),
      cardLastFour: data.saleCC.slice(-4),
      expirationMonth: data.cardExpirationMonth,
      expirationYear: data.cardExpirationYear,
      cardType: data.cardType,
      cardToken: data.token
    })
  }

  function onChargeCardSuccess ({ transaction, invoice: newInvoiceData }) {
    const invoiceQueryKey = generateQueryKey([QUERY_KEYS.invoicePaymentDetails, id], user.id)
    queryClient.setQueryData(invoiceQueryKey, (oldData) => ({
      ...oldData,
      invoice: { ...oldData.invoice, ...newInvoiceData }
    }))

    setTransactionDetails(transaction)
  }

  function onChargeCardError (mutationError) {
    const { response } = mutationError
    const [error] = response.errors

    setCardError(error.message.split(' || '))
  }

  function onChargeCardSettled () {
    setNewCard(null)
  }

  async function handleSendInvoice (data) {
    if (data.action === 'save') {
      return updateInvoice({
        id,
        ...data
      })
    }

    if (data.action === 'send') {
      await updateInvoice({
        id,
        ...data
      })
      return sendCustomerInvoice({ invoiceId: id, sendTo: data.billTo })
    }

    captureError(new Error(`Unhandled save invoice action: ${data.action}`))
  }

  function handleExportToQbo () {
    return exportToQBO({ id })
  }

  const sendFromOther = invoiceSettingsData?.invoiceSettings?.sendInvoicesFrom === 'NONE_OTHER'
  const sendFromQbo = invoiceSettingsData?.invoiceSettings?.sendInvoicesFrom === 'QUICKBOOKS'
  const sendFromDispatcher = invoiceSettingsData?.invoiceSettings?.sendInvoicesFrom === 'DISPATCHER'
  const wasExportedToQbo = isQboExportSuccess || invoiceData?.invoice.status === 'CLOSED_IN_QBO'

  return (
    <NormalLayoutContainer showBackLink>
      <PageTitle>
        <div className='dis-page-title-flex'>
          <div>{pageTitle}</div>
          {transactionDetails && hauler.quickbooks.isConnected && !invoiceData?.invoice.qboInvoiced && !invoiceData?.invoice.qboUrl &&
            <button
              type='button'
              className={cn('dis-btn dis-btn-lg dis-btn-primary', styles.exportToQBOBtn)}
              onClick={handleExportToQbo}>
              Export Invoice To QBO
            </button>}
        </div>
      </PageTitle>

      {isFetchingInvoice || exportToQBOLoading || isFetchingInvoiceSettings
        ? <Spinner isFetching />
        : !queryParams.exportAttempted && (actionsRequired?.qboClientExportNeeded || actionsRequired?.wpClientExportNeeded || actionsRequired?.feeTypesExportNeeded)
            ? (
              <QboWastepayExport
                invoice={invoiceData?.invoice}
                invoiceQueryKey={[QUERY_KEYS.invoicePaymentDetails, id]}
                actionsRequired={actionsRequired}
                isWastepayConnected={hauler.isWastepayConnected}
              />)
            : transactionDetails
              ? <TransactionConfirmation
                  invoice={invoiceData?.invoice}
                  transactions={formatPaymentsForQBOExportConfirmation(invoiceData?.invoice, transactionDetails)}
                  retryQboExport={handleRetryPaymentExport}
                  transactionsTable={PaymentConfirmationTransactionTable}
                  provider={paymentProvider}
                  receiptEmails={invoiceSettingsData?.invoiceSettings?.sendPaymentReceipt ? paymentEmails : undefined}
                />
              : invoiceData?.invoice && (
                <>
                  {wasExportedToQbo && (
                    <QboExportInvoiceSuccess invoice={invoiceData?.invoice} />
                  )}

                  {!wasExportedToQbo && (
                    <div className={styles.invoice}>
                      <div className={styles.previewContainer}>
                        <InvoicePreview
                          invoice={invoiceData.invoice}
                          showQboButton={hauler.quickbooks.isConnected}
                          showDiscountAndTax={hauler.country === 'US'}
                          showActionColumn={false}
                          showLineItemTotals={false}
                        />
                        {invoiceData.invoice.status === 'OPEN' && <Link to={generatePath(ROUTES.invoiceEdit, { id: invoiceData.invoice.id })}
                          className={cn(styles.editInvoiceBtn, 'dis-btn dis-btn-lg dis-btn-white')}>
                          <span>Edit Invoice</span>
                          <i className={cn(styles.editInvoiceIcon, 'material-icons dis-btn-icon')}>edit</i>
                        </Link>}
                      </div>
                      {cardListFetching
                        ? <Spinner isFetching />
                        : (
                          <div className={styles.actions}>
                            {!sendFromOther && (
                              <Accordion title='Send Your Invoice'>
                                {sendFromDispatcher && (
                                  <SendInvoiceForm
                                    onSubmit={handleSendInvoice}
                                    paymentTerms={invoiceSettingsData?.paymentTerms ?? []}
                                    isWastepayConnected={hauler.isWastepayConnected}
                                    invoice={invoiceData.invoice}
                                    initialValues={{
                                      date: invoiceData.invoice.date ?? dayjs().format('YYYY-MM-DD'),
                                      dueDate: invoiceData.invoice?.dueDate,
                                      billTo: invoiceData.invoice?.billTo ?? emailList,
                                      message: invoiceData.invoice?.message,
                                      paymentTerms: invoiceData.invoice?.paymentTerms ?? invoiceSettingsData?.invoiceSettings.defaultPaymentTerms,
                                      includeTerms: invoiceData.invoice?.includeTerms ?? true,
                                      terms: invoiceData.invoice?.terms ?? invoiceSettingsData?.invoiceSettings?.defaultTerms,
                                      includePayNow: invoiceData.invoice?.includePayNowLink ?? invoiceSettingsData?.invoiceSettings?.includePayNowLink
                                    }}
                                    className={styles.sendInvoice}
                                  />
                                )}
                                {sendFromQbo && (
                                  <div className={styles.closeOutQbo}>
                                    <p>Export your open invoice to send and close it out via Quickbooks.&nbsp;
                                      <span className={styles.bold}>
                                        Note, once exported, you will not be able to add a payment to it in Dispatcher.
                                      </span>
                                    </p>
                                    <button
                                      type='button'
                                      className={cn(styles.closeOutQboBtn, 'dis-btn dis-btn-lg dis-btn-primary')}
                                      onClick={handleExportToQbo}>
                                      Close Out Invoice
                                    </button>
                                  </div>
                                )}
                              </Accordion>
                            )}
                            { (hauler.isWastepayConnected || sendFromDispatcher) &&
                              (
                                <Accordion className={{ [styles.payAccordion]: !sendFromOther }} title='Add a Payment' initiallyOpen={true}>
                                  <PaymentDetails
                                    onSubmit={handlePayment}
                                    invoice={invoiceData.invoice}
                                    clientCards={clientCards}
                                    errorMessage={cardError}
                                    hasCardQueryError={cardQueryError}
                                    emailList={emailList}
                                    hauler={hauler}
                                    disabled={isChargingSavedCard || isChargingUnsavedCard}
                                    sendFromDispatcher={sendFromDispatcher}
                                    isWastepayConnected={hauler.isWastepayConnected}
                                    dispatcherPaymentSubmitting={createDispatcherPaymentLoading}
                                    newCard={newCard}
                                    setNewCard={setNewCard}
                                    refetchCardList={refetchCardList}
                                    setCardError={setCardError}
                                  />
                                </Accordion>
                              )
                            }

                          </div>
                          )
                  }
                    </div>
                  )}
                </>
              )
      }
    </NormalLayoutContainer>
  )
}
