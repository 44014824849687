import { loader } from 'graphql.macro'

export default async function restoreExceptionForTicket ({ dataLoaders, variables }) {
  const schema = loader('./restoreExceptionForTicket.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      ticketId: variables.ticketId
    }
  })
  return response.restoreExceptionForTicket
}
