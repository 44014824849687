import React, { useEffect, useState } from 'react'
import { gql } from 'graphql-request'
import notify from '../../utilities/notify'
import ProMappingTable from '../../components/pro/ProMappingTable'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import { useSelector } from 'react-redux'

function updateStandardTicketTypes (graphQLClient, formattedInput) {
  const mutation = gql`
  mutation UpdateTicketTypesStandardType($input: UpdateTicketTypesStandardInput!) {
    updateTicketTypesStandardType(input: $input) {
      ticketTypes {
        standardTicketTypeId
        name
        id
      }
    }
  }
`
  return graphQLClient.request(mutation, { input: { ticketTypesStandardType: formattedInput } })
}

function getStandardTicketTypes (graphQLClient, haulerId) {
  const standardTicketTypesQuery = gql`
  query StandardTicketTypes($haulerId: ID!) {
    standardTicketTypes(haulerId: $haulerId) {
      name
      id
      description
    }
  }
`
  return graphQLClient.request(standardTicketTypesQuery, { haulerId })
}

function getTicketTypes (graphQLClient, haulerId) {
  const ticketTypeQuery = gql`
  query TicketTypes($haulerId: ID!) {
    ticketTypes(haulerId: $haulerId) {
      name
      id
      standardTicketTypeId
      status
    }
  }
`
  return graphQLClient.request(ticketTypeQuery, { haulerId })
}

function getPartnership (graphQLClient, haulerId) {
  const partnershipQuery = gql`
  query Partnership($haulerId: ID!) {
    partnership(haulerId: $haulerId) {
      clientCreationStrategy
      client {
        name
      }
    }
  }
`
  return graphQLClient.request(partnershipQuery, { haulerId })
}

export default function ProMapTickets () {
  const [ticketOptions, setTicketOptions] = useState([])
  const [standardTickets, setStandardTickets] = useState([])
  const [clientName, setClientName] = useState(false)
  const [tableValues, setTableValues] = useState([])
  const { user, graphQLClient } = useSelector(({ user, dataLoaders }) => ({
    user: user.user,
    graphQLClient: dataLoaders.dispatcher.graphQLClient
  }))

  useEffect(function onInitialRender () {
    getTicketTypes(graphQLClient, user.haulerId)
      .then(({ ticketTypes }) => {
        const enabledTicketTypes = ticketTypes.filter(ticket => ticket.status === 'enabled')
        setTicketOptions(enabledTicketTypes)
      })
      .catch(() => notify('error', 'There was a problem retrieving ticket types'))

    getStandardTicketTypes(graphQLClient, user.haulerId)
      .then(({ standardTicketTypes }) => {
        setStandardTickets(standardTicketTypes)
      })
      .catch(() => notify('error', 'There was a problem retrieving standard ticket types'))

    getPartnership(graphQLClient, user.haulerId)
      .then(({ partnership }) => {
        if (partnership[0].clientCreationStrategy === '1client') {
          setClientName(partnership[0].client.name)
        } else {
          setClientName('Standard Brands')
        }
      })
      .catch(() => notify('error', 'There was a problem retrieving partnership'))
  }, [user.haulerId, graphQLClient])

  function saveTicketMapping () {
    const formattedInput = tableValues.filter(val => val.changed && val.mappedValue !== val.initialValue).map(val => ({
      standardType: val.mappedValue ? val.id : null,
      ticketTypeId: val.mappedValue || val.initialValue
    }))

    updateStandardTicketTypes(graphQLClient, formattedInput)
      .then((data) => {
        setTicketOptions(data.updateTicketTypesStandardType.ticketTypes)
        notify('success', 'Ticket Type settings saved')
      })
      .catch(() => notify('error', 'Failed to update ticket type settings'))
  }

  useEffect(() => {
    const filteredOptions = ticketOptions.filter(asst => asst.standardTicketTypeId)
    const tableVals = standardTickets.map(ticket => {
      const mappedValue = filteredOptions.find(opt => opt.standardTicketTypeId === ticket.id)
      return ({
        name: ticket.name,
        description: ticket.description,
        id: ticket.id,
        error: false,
        changed: false,
        initialValue: mappedValue?.id || null,
        mappedValue: mappedValue?.id || null
      })
    })
    setTableValues(tableVals)
  }, [ticketOptions, standardTickets])

  return (
    <NormalLayoutContainer showBackLink>
      <h3>Ticket Type Settings</h3>
      <p className='text-2xl'>Choose one of your ticket types from the dropdowns below to connect it to each possible order type
        sent by {clientName}. A ticket type can only be connected to one order type.
      </p>
      {tableValues.length > 0 &&
        <ProMappingTable
          title='Ticket'
          standardTypes={standardTickets}
          options={ticketOptions}
          handleSave={saveTicketMapping}
          tableValues={tableValues}
          setTableValues={setTableValues}
          colTitle={clientName + ' Order Types'}
          standardField='standardTicketTypeId'
          showAllStandardTypes={true}
        />}
    </NormalLayoutContainer>
  )
}
