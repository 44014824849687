import { loader } from 'graphql.macro'

export default function resourceAndTicketTypes ({ dataLoaders, queryKey }) {
  const [, variables] = queryKey
  const schema = loader('./resourceAndTicketTypes.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(schema, {
    haulerId: variables.haulerId,
    status: variables.status
  })
}
