import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { generatePath, useHistory } from 'react-router-dom'
import { ROUTES } from '../../routes'
import { enumToTitleCase } from '../../utilities/stringUtilities'
import dayjs from '../../utilities/dayjs'
import notify from '../../utilities/notify'
import QUERY_KEYS from '../../graphql/queryKeys'
import useQuery from '../../hooks/useQuery'
import cn from 'classnames'
import updateTicketFeesTaxDiscountMutation from '../../graphql/mutations/updateTicketFeesTaxDiscount'
import invoiceSettingsQuery from '../../graphql/queries/invoiceSettings'
import { formatDiscountAndTaxValues } from '../discount-and-tax-form-fields/utils'
import styles from './index.module.scss'
import { TICKET_BILLING_STATUSES } from '../../utilities/constants'
import { Spinner } from '../../pages/shared/Spinner'
import TicketFeeForm from './TicketFeeForm'
import InvoiceDetails from './InvoiceDetails'
import TicketInvoiceTable from './TicketInvoiceTable'
import useMutation from '../../hooks/useMutation'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'

export default function TicketFeesInvoicing ({
  ticket,
  feeTypes,
  qboTaxCodeRates,
  isFetching,
  onBillingStatusChange,
  onCreateInvoice,
  returnTo
}) {
  const history = useHistory()
  const unblockRef = useRef()
  const [isEditingInvoicedFees, setIsEditingInvoicedFees] = useState(false)
  const billingStatusInvoiced = ticket.billingStatus === 'INVOICED' || ticket.billingStatus === 'PAID'
  const invoiced = ticket.invoice?.id !== undefined
  const hasInvoiceDiscount = ticket?.invoice?.discountAndTax?.discountAmount
  const hasInvoiceTax = ticket?.invoice?.discountAndTax?.taxAmount
  const qboInvoiced = ticket.invoice?.qboInvoiced
  const { ticketFees, discountAndTax } = ticket
  const { hauler } = useSelector(({ user }) => ({ hauler: user.hauler }))
  const showDiscountAndTax = hauler.country === 'US'
  const usingAST = hauler.quickbooks.isConnected && qboTaxCodeRates.usingAST

  useEffect(() => {
    if (returnTo) {
      setIsEditingInvoicedFees(true)
    }
  }, [returnTo])

  const { data: invoiceSettingsData, isFetching: isFetchingInvoiceSettings } = useQuery([
    QUERY_KEYS.invoiceSettings,
    hauler.id
  ],
  invoiceSettingsQuery,
  {
    onError: (error) => captureErrorAndNotify(error, 'Error Fetching Invoice Settings')
  })

  const { mutate: updateTicketFeesTaxDiscount, isLoading: updateTicketFeesTaxDiscountLoading } = useMutation(updateTicketFeesTaxDiscountMutation, {
    onSuccess (data, variables) {
      if (unblockRef) {
        unblockRef.current()
      }
      handleFormActions(variables.afterSubmit, variables.route, data.ticket, variables.reset)
      const showInvoiceCreationSuccess = hauler.canCreateInvoice && !ticket?.invoice?.id
      if (!showInvoiceCreationSuccess || variables?.afterSubmit === 'saveFees') {
        notify('success', 'Ticket Fees Updated')
      }
    },
    onError (error) {
      captureErrorAndNotify(error, 'Error adding ticket fees')
    }
  })

  function handleFormActions (afterSubmit, route, updatedTicket, reset) {
    switch (afterSubmit) {
      case 'saveFees':
        if (updatedTicket && reset) {
          reinitializeFormAfterSubmit(updatedTicket, reset)
        }
        break
      case 'createInvoice':
        if (!ticket?.invoice?.id) {
          handleCreateInvoice()
        } else if ((returnTo === 'exportInvoice') || (ticket?.invoice?.ticketsCount > 1 && hauler.quickbooks.isConnected && !returnTo)) {
          history.push({
            pathname: `${generatePath(ROUTES.invoicesExport, { id: ticket.invoice.id })}`,
            state: { multiTicket: true }
          })
        } else if (returnTo === 'editInvoice') {
          history.push(history.push(`${generatePath(ROUTES.invoiceEdit, { id: ticket.invoice.id })}`))
        } else if (hauler.canCreateInvoice) {
          history.push(`${generatePath(ROUTES.invoicePayment, { id: ticket.invoice.id })}`)
        } else if (hauler.quickbooks.isConnected) {
          history.push(generatePath(ROUTES.invoicesExport, { id: ticket.invoice.id }))
        }
        break
      case 'navigateToRoute':
        /* TO DO: There is an issue with react-router-dom that is disallowing the page to render correctly after unblocking navigation when using history.push and the custom modal */
        window.location.href = route
        break
      default:
        if (updatedTicket && reset) {
          reinitializeFormAfterSubmit(updatedTicket, reset)
        }
    }
  }

  function reinitializeFormAfterSubmit (updatedTicket, reset) {
    reset({
      ticketFees: updatedTicket?.ticketFees,
      discountAndTax: {
        discountAmount: updatedTicket?.discountAndTax?.discountAmount ? parseFloat(updatedTicket?.discountAndTax?.discountAmount).toFixed(2) : null,
        discountPercent: updatedTicket?.discountAndTax?.discountPercent,
        discountMethod: updatedTicket?.discountAndTax?.discountMethod,
        taxAmount: updatedTicket?.discountAndTax?.taxAmount ? parseFloat(updatedTicket?.discountAndTax?.taxAmount).toFixed(2) : null,
        taxPercent: updatedTicket?.discountAndTax?.taxPercent,
        taxMethod: updatedTicket?.discountAndTax?.taxMethod,
        qboTaxCodeId: updatedTicket?.discountAndTax?.qboTaxCodeId
      },
      deletedTicketFees: [],
      showDiscountForm: Boolean(updatedTicket?.discountAndTax?.discountAmount || updatedTicket?.discountAndTax?.discountPercent),
      showTaxForm: Boolean(updatedTicket?.discountAndTax?.taxAmount || updatedTicket?.discountAndTax?.taxPercent),
      pristine: true,
      qboNonAST: hauler.quickbooks.isConnected && !usingAST
    })
  }

  function handleCreateInvoice () {
    onCreateInvoice(ticket.account.id, hauler.id, [ticket.id])
  }

  function handleFormSubmit (vals, form) {
    const { discountAndTax, ticketFees, deletedTicketFees, afterSubmit, route, pristine } = vals
    if (pristine) {
      handleFormActions(afterSubmit, route)
    } else {
      const [updatedTicketFees, newTicketFees] = ticketFees.map(ticketFee => ({
        amount: parseFloat(ticketFee.amount),
        ticketFeeId: ticketFee.id,
        description: ticketFee.description,
        quantity: Number(ticketFee.quantity),
        feeTypeId: ticketFee.feeType.id
      })).reduce((result, tf) => {
        result[tf.ticketFeeId ? 0 : 1].push(tf)
        return result
      },
      [[], []])

      const formattedDiscountValues = formatDiscountAndTaxValues(
        discountAndTax.discountAmount,
        discountAndTax.discountPercent,
        discountAndTax.discountMethod
      )
      const formattedTaxValues = formatDiscountAndTaxValues(discountAndTax.taxAmount, discountAndTax.taxPercent, discountAndTax.taxMethod)

      updateTicketFeesTaxDiscount({
        ticketId: ticket.id,
        haulerId: hauler.id,
        newTicketFees,
        updatedTicketFees,
        deletedTicketFees,
        discountAndTax: {
          discountAmount: formattedDiscountValues.formattedAmount,
          discountPercent: formattedDiscountValues.formattedPercent,
          discountMethod: formattedDiscountValues.formattedMethod,
          taxAmount: formattedTaxValues.formattedAmount,
          taxPercent: formattedTaxValues.formattedPercent,
          taxMethod: formattedTaxValues.formattedMethod,
          qboTaxCodeId: discountAndTax.qboTaxCodeId
        },
        afterSubmit,
        route,
        reset: form.reset
      })
    }
  }

  function filteredBillingStatusList () {
    if (invoiced) {
      return { INVOICED: 'INVOICED', PAID: 'PAID' }
    }
    return TICKET_BILLING_STATUSES
  }

  return (
    <div className='dis-panel'>
      <div className={cn('dis-panel-header', styles.feeHeading)}>
        <h5>Ticket Fees and Invoicing</h5>
        <div className={styles.selectWrapper}>
          <i className={cn('material-icons', styles.selectArrow)}>arrow_drop_down</i>
          <select
            className={styles.billingStatusSelect}
            name='ticket billing status'
            value={ticket.billingStatus}
            onChange={(e) => onBillingStatusChange(ticket.id, e.currentTarget.value)}>
            {Object.keys(filteredBillingStatusList()).map((opt) =>
              (
                <option
                  value={opt}
                  key={opt}>
                  {enumToTitleCase(opt)}
                </option>
              )
            )}
          </select>
        </div>
      </div>
      {!invoiced && billingStatusInvoiced
        ? (
          <div className={styles.textCenter}>
            <h5 className={styles.warningText}>
              Ticket has been invoiced. Ticket Fees cannot be changed.
            </h5>
          </div>
          )
        : (
          <div className='dis-panel-body'>
            {
            isFetching || isFetchingInvoiceSettings
              ? <Spinner isFetching />
              : (<>
                {isEditingInvoicedFees && ticket?.invoice?.id &&
                  <div>
                    <div className={styles.invoiceDetailTotal}>{`Invoice #${ticket?.invoice?.dispatcherInvoiceNumber}`}</div>
                    <div className={styles.editingInvoiceDetails}>
                      <div className={styles.invoiceDetailsStatus}>
                        <span className={styles.invoiceDetail}>Status:</span>
                        {enumToTitleCase(ticket?.invoice?.status)}
                      </div>
                      <div>
                        <span className={styles.invoiceDetail}>Create Date:</span>
                        {dayjs(ticket?.invoice?.createdAt).format('MMM D, YYYY')}
                      </div>
                    </div>
                  </div>
                }
                {(!invoiced || isEditingInvoicedFees) &&
                  <TicketFeeForm
                    feeTypes={feeTypes}
                    initialValues={{
                      ticketFees: ticketFees.map(tf => {
                        tf.amount = parseFloat(tf.amount).toFixed(2)
                        return tf
                      }),
                      discountAndTax: {
                        discountAmount: discountAndTax?.discountAmount ? parseFloat(discountAndTax?.discountAmount).toFixed(2) : null,
                        discountPercent: discountAndTax?.discountPercent,
                        discountMethod: discountAndTax?.discountMethod,
                        taxAmount: discountAndTax?.taxAmount ? parseFloat(discountAndTax?.taxAmount).toFixed(2) : null,
                        taxPercent: discountAndTax?.taxPercent,
                        taxMethod: discountAndTax?.taxMethod,
                        qboTaxCodeId: discountAndTax?.qboTaxCodeId

                      },
                      deletedTicketFees: [],
                      showDiscountForm: Boolean(discountAndTax?.discountAmount || discountAndTax?.discountPercent),
                      showTaxForm: Boolean(discountAndTax?.taxAmount || discountAndTax?.taxPercent),
                      pristine: true,
                      qboNonAST: hauler.quickbooks.isConnected && !usingAST
                    }}
                    hasInvoiceDiscount={hasInvoiceDiscount}
                    hasInvoiceTax={hasInvoiceTax}
                    showCreateInvoice={hauler.canCreateInvoice}
                    showDiscountAndTax={showDiscountAndTax}
                    isFetching={updateTicketFeesTaxDiscountLoading}
                    onSubmit={handleFormSubmit}
                    qboInvoiced={qboInvoiced}
                    ticket={ticket}
                    isEditingInvoicedFees={isEditingInvoicedFees}
                    setIsEditingInvoicedFees={setIsEditingInvoicedFees}
                    isConnectedToQB={hauler.quickbooks.isConnected}
                    usingAST={usingAST}
                    taxCodes={qboTaxCodeRates.taxCodes}
                    returnTo={returnTo}
                    unblockRef={unblockRef}
                  />
                }
                {invoiced && !isEditingInvoicedFees && <div className={styles.invoiceView}>
                  <div className={styles.invoiceTotals}>
                    <TicketInvoiceTable
                      ticket={ticket}
                      discountAndTax={discountAndTax}
                      ticketFees={ticketFees}
                      showQBOMessaging={qboInvoiced}
                    />
                  </div>
                  <InvoiceDetails
                    invoice={ticket?.invoice}
                    qboInvoiced={qboInvoiced}
                    setShowEditing={setIsEditingInvoicedFees}
                    isWastepayConnected={hauler.isWastepayConnected}
                    invoicer={invoiceSettingsData?.invoiceSettings.sendInvoicesFrom}
                    total={ticket?.total}
                    isConnectedToQB={hauler.quickbooks.isConnected}
                  />
                </div>
                }
                {hauler.isWastepayEnabled && !hauler.isWastepayApproved && (
                  <div className={styles.wastepayAd}>
                    <div className={styles.newBadge}>New!</div>
                    <div className={styles.wastepayAdTitle}>Credit Card Processing</div>
                    <div>With the new WastePay integration, charge a credit card right from here!</div>
                    <a
                      href='https://www.dispatcher.com/payments-demo'
                      target='_blank noopener noreferrer'
                      className={styles.wastepayLink}>
                      Learn More
                    </a>
                  </div>
                )}
              </>)
          }
          </div>
          )}
    </div>
  )
}

TicketFeesInvoicing.propTypes = {
  ticket: PropTypes.object.isRequired,
  onBillingStatusChange: PropTypes.func.isRequired,
  feeTypes: PropTypes.array.isRequired,
  qboTaxCodeRates: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onCreateInvoice: PropTypes.func.isRequired,
  returnTo: PropTypes.string
}
