import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, Link } from 'react-router-dom'
import JobStatusLabel from './JobStatusLabel'
import CursorPagination from './cursor-pagination'
import { prettyAddressWithDetails } from '../utilities/locationUtilities'
import { ROUTES } from '../routes'

export default function ClientJobsTable ({ jobs, title, onPageRequest, client, fakeCount }) {
  return (
    <div className='row panel panel-default'>
      <div className='panel-heading'>
        <div className='container-fluid'>
          {title === 'Open Jobs' && (
            <div>
              <h4 className='pull-left'>
                {title}
                {` - ${client.openJobsCount} jobs`}
                &nbsp;
                <Link to={`${ROUTES.jobs}?clientId=${client.id}&status=open`}>
                  <small className='text-primary link'>(View All Open Jobs)</small>
                </Link>
              </h4>
              <Link to={`${ROUTES.newJob}?client_id=${client.id}`} className='btn btn-lg btn-primary pull-right'>
                <span className='pull-left hidden-xs'>Create New Job</span>
                <i className='material-icons pull-right thumbster-button-icon no-margin-bs-xs'>add_circle</i>
              </Link>
            </div>
          )}
          {title === 'Closed Jobs' && (
            <div>
              <h4 className='pull-left'>
                {title}
                {` - ${client.closedJobsCount} jobs`}
                &nbsp;
                <Link to={`/jobs?clientId=${client.id}&status=closed`}>
                  <small className='text-primary link'>(View All Closed Jobs)</small>
                </Link>
              </h4>
            </div>
          )}
        </div>
      </div>
      <div className='panel-body table-responsive'>
        <table className='table table-striped'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Status</th>
              <th>Address</th>
              <th>Open Tickets</th>
              <th>Closed Tickets</th>
              <th>View Details</th>
            </tr>
          </thead>
          <tbody>
            {jobs.nodes.length === 0
              ? (
                <tr>
                  <td colSpan={6}>
                    <h4 className='text-center'>No Jobs</h4>
                  </td>
                </tr>
                )
              : jobs.nodes.map(job => {
                const jobPath = generatePath(ROUTES.job, { id: job.id })
                return (
                  <tr key={job.id}>
                    <td>
                      <Link to={jobPath}>
                        {job.customId}
                      </Link>
                    </td>
                    <td>
                      <div>
                        <Link to={jobPath}>
                          <JobStatusLabel status={job.status} />
                        </Link>
                      </div>
                    </td>
                    <td>
                      <div>{prettyAddressWithDetails(job)}</div>
                    </td>
                    <td>
                      {job.activeTicketsCount === 0
                        ? 'None'
                        : (
                          <Link to={`${ROUTES.ticketSearch}?jobCustomId=${job.customId}&status=active`}>
                            {job.activeTicketsCount}
                          </Link>
                          )
                      }
                    </td>
                    <td>
                      {job.terminalTicketsCount === 0
                        ? 'None'
                        : (
                          <Link to={`${ROUTES.ticketSearch}?jobCustomId=${job.customId}&status=terminal`}>
                            {job.terminalTicketsCount}
                          </Link>
                          )
                      }
                    </td>
                    <td>
                      <Link to={jobPath}>
                        <i className='material-icons medium-icon'>info</i>
                      </Link>
                    </td>
                  </tr>
                )
              })
          }
          </tbody>
        </table>
        {jobs.totalCount > 0 && <CursorPagination
          pageInfo={jobs.pageInfo}
          totalCount={jobs.totalCount}
          onPageRequest={onPageRequest}
          fakeCount={fakeCount}
                                />}
      </div>
    </div>
  )
}

ClientJobsTable.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.string.isRequired,
    openJobsCount: PropTypes.number.isRequired,
    closedJobsCount: PropTypes.number.isRequired
  }).isRequired,
  jobs: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.object).isRequired,
    pageInfo: PropTypes.shape({
      endCursor: PropTypes.string,
      hasNextPage: PropTypes.bool.isRequired,
      hasPreviousPage: PropTypes.bool.isRequired,
      startCursor: PropTypes.string
    }).isRequired,
    totalCount: PropTypes.number.isRequired
  }).isRequired,
  onPageRequest: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  fakeCount: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired
  })
}
