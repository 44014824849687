import React from 'react'
import PropTypes from 'prop-types'
import { Pie } from 'react-chartjs-2'
import { generateData, getElementAtEvent, OPTIONS } from './TicketsPieChartUtils'

export default function TicketsPieChart ({ tickets: { pagination, statusCount } }) {
  const data = generateData(statusCount)
  return (
    <div className='text-center'>
      <h3>{`Today's Tickets (${pagination.total_count})`}</h3>
      {
        pagination.total_count === 0 && (
          <div className='push-down-large'>
            <h2>0 Tickets</h2>
          </div>
        )
      }
      { pagination.total_count > 0 && (
        <div className='push-down-small'>
          <Pie data={data} options={OPTIONS} onElementsClick={getElementAtEvent} />
        </div>
      )}
    </div>
  )
}

TicketsPieChart.propTypes = {
  tickets: PropTypes.object.isRequired
}
