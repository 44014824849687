import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import cn from 'classnames'
import styles from './index.module.scss'
import { TextInput } from '../TextInput'
import normalizePhone from '../../pages/shared/normalizers/normalizePhone'
import normalizePositiveNumber from '../../pages/shared/normalizers/normalizePositiveNumber'
import { validate } from './utils'
import AutocompleteAddressInput from '../maps/autocomplete-address-input'
import StateSelect from '../state-select'
import { TextareaInput } from '../TextareaInput'

const allowedAddressFields = ['addressline1', 'city', 'state', 'zip', 'country']

const setAddressFields = (args, state, { changeValue }) => {
  Object.fromEntries(
    Object.entries(args[0]).filter(
      ([key, val]) => {
        if (allowedAddressFields.includes(key)) {
          return changeValue(state, key, () => val)
        }
        return null
      }
    )
  )
}
export default function ClientCreateForm ({
  onSubmit,
  isFetching
}) {
  return (
    <div className='dis-panel'>
      <div className='dis-panel-body'>
        <Form onSubmit={onSubmit}
          validate={values => validate(values)}
          mutators={{ setAddressFields }}>
          {({ handleSubmit, pristine, valid, form }) => {
            const onLocationChange = (place) => {
              form.mutators.setAddressFields(place)
            }
            return (
              <form onSubmit={handleSubmit}>
                <Field
                  name='name'
                  label='Account Name'
                  component={TextInput}
                  maxLength='70'
                />

                <Field
                  name='notes'
                  multiLine
                  rows='6'
                  label='Account Notes'
                  component={TextareaInput}
                  maxLength='30000'
                />

                <div className={cn('font-size-1-5em', styles.inputSectionTitle)}>Contact</div>

                <div className={styles.inputContainer}>
                  <Field
                    name='firstName'
                    component={TextInput}
                    label='First Name'
                    maxLength='20'
                  />
                  <Field
                    name='lastName'
                    component={TextInput}
                    label='Last Name'
                    maxLength='20'
                  />
                </div>

                <div className={styles.inputContainer}>
                  <Field
                    name='email'
                    component={TextInput}
                    label='Contact Email'
                    maxLength='64'
                  />
                  <div className={styles.phoneContainer}>
                    <Field
                      name='primaryPhone'
                      component={TextInput}
                      label='Phone'
                      parse={normalizePhone}
                    />
                    <Field
                      name='primaryPhoneExtension'
                      component={TextInput}
                      label='Ext.'
                      type='number'
                      parse={normalizePositiveNumber}
                    />
                  </div>
                </div>

                <div className={cn('font-size-1-5em', styles.inputSectionTitle)}>Billing</div>

                <div className={styles.inputContainer}>
                  <Field name='addressline1'>
                    {({ input }) => (
                      <div>
                        <label className='control-label' htmlFor='addressline1'>Address</label>
                        <AutocompleteAddressInput
                          initialValue=''
                          onLocationChange={onLocationChange}
                          inputId='clientCreateAutocomplete'
                          input={{
                            onBlur: () => {
                              input.onBlur()
                            },
                            onFocus: () => {
                              input.onFocus()
                            },
                            value: input.value,
                            name: input.name,
                            onChange: val => {
                              input.onChange(val)
                            }
                          }}
                        />
                      </div>
                    )}
                  </Field>
                  <Field
                    name='addressline2'
                    component={TextInput}
                    label='Apt, Suite, etc.'
                  />
                </div>

                <div className={styles.inputContainer}>
                  <Field
                    name='city'
                    component={TextInput}
                    label='City'
                  />
                  <div className={styles.stateZipContainer}>
                    <StateSelect />
                    <Field
                      name='zip'
                      component={TextInput}
                      label='Postal Code'
                    />
                  </div>
                </div>

                <Field
                  name='billingDetails'
                  component={TextareaInput}
                  label='Billing Details'
                />

                <div className={styles.buttonContainer}>
                  <button
                    type='submit'
                    className='dis-btn dis-btn-blank dis-btn-lg'
                    disabled={pristine || isFetching || !valid}
                    onClick={() => form.change('navToJob', false)}>
                    Save Account Only
                  </button>
                  <button
                    type='submit'
                    className='dis-btn dis-btn-primary dis-btn-lg'
                    disabled={pristine || isFetching || !valid}
                    onClick={() => form.change('navToJob', true)}>
                    Save Account and Enter Job
                    <i className='material-icons dis-btn-icon'>add_circle</i>
                  </button>
                </div>
              </form>
            )
          }}
        </Form>
      </div>
    </div>
  )
}

ClientCreateForm.propTypes = {
  isFetching: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
}
