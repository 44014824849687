import React from 'react'

export default function HaulerProfileExplanation () {
  return (
    <div className='row'>
      <h5 className='text-center'> HELP </h5>
      <hr />
      <h6>{'You can update your company\'s details on this page.'}</h6>
      <hr />
      <h6>Note that the address you provide here will be the area of the map displayed on the Dispatch screen</h6>
    </div>
  )
}
