import { makeGraphQLClient } from '../utilities/graphqlClient'

export const ticketsSortColumnEnumMap = {
  id: 'ID',
  customId: 'CUSTOM_ID',
  flagged: 'FLAGGED',
  createdAt: 'CREATED_AT',
  updatedAt: 'UPDATED_AT',
  ticketTypeName: 'TICKET_TYPE_NAME',
  resourceTypeName: 'RESOURCE_TYPE_NAME',
  clientName: 'CLIENT_NAME',
  clientCustomId: 'CLIENT_CUSTOM_ID',
  address: 'ADDRESS',
  weight: 'WEIGHT',
  weightTicketNumber: 'WEIGHT_TICKET_NUMBER',
  driverName: 'DRIVER_NAME',
  position: 'POSITION',
  jobId: 'JOB_ID',
  jobCustomId: 'JOB_CUSTOM_ID',
  date: 'DATE',
  status: 'STATUS',
  billingStatus: 'BILLING_STATUS'
}
export const jobsSortColumnEnumMap = {
  id: 'ID',
  customId: 'CUSTOM_ID',
  status: 'STATUS',
  address: 'ADDRESSLINE1',
  clientName: 'CLIENT_NAME',
  openTicket: 'ACTIVE_TICKETS_COUNT',
  closedTicket: 'TERMINAL_TICKETS_COUNT'
}

export const resourcesSortColumnEnumMap = {
  id: 'ID',
  uid: 'UID',
  createdAt: 'CREATED_AT',
  lastMoved: 'LAST_MOVED',
  resourceTypeName: 'RESOURCE_TYPE_NAME',
  address: 'ADDRESS',
  position: 'POSITION',
  jobId: 'JOB_ID',
  jobCustomId: 'JOB_CUSTOM_ID',
  siteId: 'SITE_ID'
}

export const clientsSortColumnEnumMap = {
  customId: 'CUSTOM_ID',
  notes: 'NOTES',
  name: 'NAME',
  lastName: 'LAST_NAME',
  primaryPhone: 'PRIMARY_PHONE',
  openJobsCount: 'OPEN_JOBS_COUNT',
  closedJobsCount: 'CLOSED_JOBS_COUNT',
  invoiceableTicketsCount: 'INVOICEABLE_TICKETS_COUNT'
}

export const gqlMutation = (data) => {
  const { input, schema } = data
  const graphQLClient = makeGraphQLClient()
  return graphQLClient.request(schema, { input })
}
