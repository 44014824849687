import {
  CLEAR_ALERT_MESSAGE,
  SET_ALERT_MESSAGE
} from './actions'

const initialState = {
  message: ''
}

export const alertReducers = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ALERT_MESSAGE:
      return initialState
    case SET_ALERT_MESSAGE:
      return {
        message: action.message
      }
    default:
      return state
  }
}
