import { loader } from 'graphql.macro'

export default async function wastepaySetPrimaryCard ({ dataLoaders, variables }) {
  const schema = loader('./wastepaySetPrimaryCard.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      clientId: variables.clientId,
      cardId: variables.cardId
    }
  })
  return response.wastepaySetPrimaryCard
}
