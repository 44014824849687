import { loader } from 'graphql.macro'

export default async function updateResourceType ({ dataLoaders, variables }) {
  const schema = loader('./updateResourceType.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      defaultBillingStatus: variables.defaultBillingStatus,
      name: variables.name,
      resourceTypeId: variables.resourceTypeId,
      shortCode: variables.shortCode,
      status: variables.status
    }
  })
  return response.updateResourceType
}
