import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.css'
import { useSelector, useDispatch } from 'react-redux'
import { loader } from 'graphql.macro'
import {
  NEW_PUSHER_SERVICE_REQUEST_DATA
} from '../../store/pusher/pusherActions'
import LocationDetailsContainer from '../LocationDetailsContainer'
import DispatchUnassignedTicketsContainer from '../DispatchUnassignedTicketsContainer'
import DispatchUserTickets from '../DispatchUserTickets'
import DriverListSortContainer from '../DriverListSortContainer'
import AssignTicketDialog from '../AssignTicketDialog'
import Modal from '../modal/Modal'
import { decodeJWT } from '../../pages/shared/decodeJWT'
import NewRequestsContainer from '../new-requests/NewRequestsContainer'
import sortFirstThenLastName from '../../utilities/sortUtilities'
import { RECEIVE_NEW_REQUESTS_COUNT } from '../../store/new-requests/reducers/newRequestReducer'

export default function DispatchTicketsList ({
  users,
  driverSortPreferences,
  onSaveDriverSortPreferences,
  unassignedTicketsSortPreference = {
    sortPreference: 'REQUESTED_TIME',
    sortFlagged: false,
    hideCancelled: false
  },
  onSaveUnassignedTicketsSortPreference,
  tickets,
  onUpdateTicketFlag,
  dispatchDate,
  onAssignTicketToUser,
  onReorderTickets,
  onTicketHoverStart,
  onTicketHoverEnd,
  onUserHoverStart,
  onUserHoverEnd,
  locationDetailTickets,
  onLocationDetailChange,
  onTicketPanelChange,
  ticketPanelOpen
}) {
  const dispatch = useDispatch()
  const { hauler, graphQLClient, haulerChannel, newRequestsRefreshNeeded } = useSelector(({ user, dataLoaders, pusher, newRequests }) => ({
    hauler: user.hauler,
    graphQLClient: dataLoaders.dispatcher.graphQLClient,
    haulerChannel: pusher.haulerChannel,
    newRequestsRefreshNeeded: newRequests.newRequestsRefreshNeeded
  }))
  const [toggleOptionsModal, setToggleOptionsModal] = useState(false)
  const [assignToTicket, setAssignToTicket] = useState(null)
  const [newRequests, setNewRequests] = useState([])
  const [newRequestsPanelOpen, setNewRequestsPanelOpen] = useState(false)
  const [newRequestsUpdating, setNewRequestsUpdating] = useState(false)
  const jwt = decodeJWT()

  const driverAssignmentList = useMemo(() => {
    if (hauler.showDriversOnly) {
      return [...users].filter(usr => usr.role === 'hauler_driver').sort(sortFirstThenLastName)
    }

    return [...users].sort(sortFirstThenLastName)
  }, [users, hauler])

  const userMap = useMemo(() => {
    const userMap = users.reduce((acc, user) => {
      const sortPreference = driverSortPreferences?.find(preference => preference.userId === user.id)
      return {
        ...acc,
        users: {
          ...acc.users,
          [user.id]: {
            ...user,
            isHidden: sortPreference ? sortPreference.isHidden : false,
            order: sortPreference ? sortPreference.order : null,
            tickets: []
          }
        }
      }
    }, { unassigned: [], users: {} })

    tickets.forEach(ticket => {
      if (!ticket.driverId) {
        userMap.unassigned = [...userMap.unassigned, ticket]
        return
      }

      const user = userMap.users[ticket.driverId]
      // This is probably an error, it means we have a ticket with a driverId but that driverId doesn't match any of our known users
      if (!user) return

      userMap.users[ticket.driverId].tickets = [...userMap.users[ticket.driverId].tickets, ticket]
    })

    return userMap
  }, [users, tickets, driverSortPreferences])

  const sortedDriverList = useMemo(() => {
    const userMapList = Object.values(userMap.users)
    return userMapList.sort((a, b) => {
      if (a.order === null && b.order === null) return parseInt(a.id) - parseInt(b.id)
      const aOrder = a.order || 0
      const bOrder = b.order || 0
      return aOrder - bOrder
    })
  }, [userMap])

  const sortedDriverListWithoutHidden = useMemo(() => {
    return sortedDriverList.filter(user => !user.isHidden)
  }, [sortedDriverList])

  function handleAssignTicketToUser (ticketId, reassignment = false, unassign) {
    if (unassign) {
      onAssignTicketToUser({ id: ticketId, driverId: null })
      return
    }

    setAssignToTicket({ ticketId, reassignment })
  }

  const unassignedTickets = useMemo(() => {
    const unassignedTickets = unassignedTicketsSortPreference.hideCancelled
      ? userMap.unassigned.filter(ticket => ticket.status !== 'cancelled')
      : userMap.unassigned

    const unassignedTicketsSortField = (ticket) => {
      switch (unassignedTicketsSortPreference.sortPreference) {
        case 'REQUESTED_TIME':
          return ticket.requestedStartTime
        case 'TICKET_TYPE':
          return ticket.ticketType.name
        case 'ASSET_TYPE':
          return ticket.resourceType.name
        case 'POSTAL_CODE':
          return ticket.job.zip
        default:
          return ticket.requestedStartTime
      }
    }

    return unassignedTickets.sort((a, b) => {
      if (unassignedTicketsSortPreference.sortFlagged) {
        if (a.flagged < b.flagged) {
          return 1
        }
        if (a.flagged > b.flagged) {
          return -1
        }
      }
      return (
        (unassignedTicketsSortField(a) === null) - (unassignedTicketsSortField(b) === null) ||
        +(unassignedTicketsSortField(a) > unassignedTicketsSortField(b)) ||
        +(unassignedTicketsSortField(a) === unassignedTicketsSortField(b)) - 1) ||
      (+(a.id > b.id) || +(a.id === b.id) - 1)
    })
  }, [unassignedTicketsSortPreference, userMap])

  useEffect(() => {
    if (hauler.proBrokerAccess) {
      const retrieveUnconfirmedServices = loader('../../graphql/queries/unconfirmedServices.graphql')
      graphQLClient.request(retrieveUnconfirmedServices, { haulerId: jwt.hauler_id })
        .then(({ unconfirmedServices }) => {
          setNewRequests(unconfirmedServices.services)
          setNewRequestsPanelOpen(unconfirmedServices.services.length > 0)
          dispatch({ type: RECEIVE_NEW_REQUESTS_COUNT, payload: { count: unconfirmedServices.count } })
        })
        .catch(error => {
          console.error('Error retrieving unconfirmed services:', error)
        })
    }
  }, [dispatch, jwt.u, jwt.hauler_id, hauler.proBrokerAccess, graphQLClient])

  useEffect(() => {
    if (hauler.proBrokerAccess && newRequestsRefreshNeeded && !newRequestsUpdating) {
      setNewRequestsUpdating(true)
      dispatch({ type: NEW_PUSHER_SERVICE_REQUEST_DATA, payload: { newRequestsRefreshNeeded: false } })
      const retrieveUnconfirmedServices = loader('../../graphql/queries/unconfirmedServices.graphql')
      graphQLClient.request(retrieveUnconfirmedServices, { haulerId: jwt.hauler_id })
        .then(({ unconfirmedServices }) => {
          const services = [...unconfirmedServices.services]
          newRequests.map((req, index) => {
            if (req.tickets[0].status === 'ORDER_CONFIRMED') {
              services.splice(index, 0, req)
            }
            return null
          })
          setNewRequests(services)
          setNewRequestsPanelOpen(services.length > 0)
          dispatch({ type: RECEIVE_NEW_REQUESTS_COUNT, payload: { count: unconfirmedServices.count } })
        })
        .catch(error => {
          console.error('Error retrieving unconfirmed services:', error)
        })
        .finally(() => setNewRequestsUpdating(false))
    }
  }, [hauler.proBrokerAccess, newRequestsRefreshNeeded, dispatch, jwt, newRequests, newRequestsUpdating, graphQLClient])

  useEffect(function listenToNewRequests () {
    if (!haulerChannel) return

    const eventName = 'new_service_request'
    function handleNewRequests (data) {
      dispatch({ type: NEW_PUSHER_SERVICE_REQUEST_DATA, payload: { newRequestsRefreshNeeded: true, newRequestsCountRefreshNeeded: true, data } })
    }
    haulerChannel.bind(eventName, handleNewRequests)

    return function cleanupListenToNewRequests () {
      haulerChannel.unbind(eventName, handleNewRequests)
    }
  }, [haulerChannel, dispatch])

  useEffect(function listenToNewRequests () {
    if (!haulerChannel) return

    const eventName = 'delete_service_request'
    function handleNewRequests (data) {
      dispatch({ type: NEW_PUSHER_SERVICE_REQUEST_DATA, payload: { newRequestsRefreshNeeded: true, data } })
    }
    haulerChannel.bind(eventName, handleNewRequests)

    return function cleanupListenToNewRequests () {
      haulerChannel.unbind(eventName, handleNewRequests)
    }
  }, [haulerChannel, dispatch])

  function handleDialogCancel () {
    setAssignToTicket(null)
  }

  function handleDialogSave (values) {
    onAssignTicketToUser({ id: values?.ticketId, driverId: values?.driverId === '- Unassigned -' ? null : values?.driverId })
    setAssignToTicket(null)
  }

  return (<>
    <Modal isOpen={toggleOptionsModal}>
      <DriverListSortContainer
        onClose={() => setToggleOptionsModal(false)}
        onSave={onSaveDriverSortPreferences}
        users={sortedDriverList}
      />
    </Modal>
    <Modal className={styles.modal} isOpen={assignToTicket !== null}>
      <AssignTicketDialog
        team={driverAssignmentList}
        onCancel={handleDialogCancel}
        onSave={handleDialogSave}
        assignToTicket={assignToTicket}
      />
    </Modal>
    {
      locationDetailTickets
        ? (
          <LocationDetailsContainer
            dispatchDate={dispatchDate}
            onClose={() => onLocationDetailChange({ latitude: null, longitude: null })}
            tickets={locationDetailTickets}
            users={users}
            onAssignTicketToUser={handleAssignTicketToUser}
            onUpdateTicketFlag={onUpdateTicketFlag}
          />
          )
        : (
          <div className='rounded shadow mb-0 bg-white -mx-6'>
            <div id='dispatch-tickets-list'>
              {(hauler.proSettings || hauler.proBrokerAccess) &&
                <NewRequestsContainer
                  requests={newRequests}
                  setNewRequests={setNewRequests}
                  panelOpen={newRequestsPanelOpen}
                  setPanelOpen={setNewRequestsPanelOpen}
                />}
              <div className='bg-gray flex flex-row flex-nowrap py-3 px-6 justify-between'>
                <div className='text-white'>Driver List</div>
                <button
                  onClick={() => setToggleOptionsModal(true)}
                  className='text-black bg-white rounded shadow-md px-3'>
                  OPTIONS
                </button>
              </div>
              <div>
                <DispatchUnassignedTicketsContainer
                  onUpdateTicketFlag={onUpdateTicketFlag}
                  isPanelOpen={ticketPanelOpen === 'unassigned'}
                  onTogglePanel={onTicketPanelChange}
                  unassignedTicketsSortPreference={unassignedTicketsSortPreference}
                  onSaveUnassignedTicketsSortPreference={onSaveUnassignedTicketsSortPreference}
                  onAssignTicketToUser={handleAssignTicketToUser}
                  tickets={unassignedTickets}
                  onTicketHoverStart={onTicketHoverStart}
                  onTicketHoverEnd={onTicketHoverEnd}
                  onUserHoverStart={onUserHoverStart}
                  onUserHoverEnd={onUserHoverEnd}
                />
              </div>
              {sortedDriverListWithoutHidden.map(user => (
                <div key={user.id} className='no-margin-bottom'>
                  <DispatchUserTickets
                    user={user}
                    onAssignTicketToUser={handleAssignTicketToUser}
                    onUpdateTicketFlag={onUpdateTicketFlag}
                    isPanelOpen={ticketPanelOpen === `user:${user.id}`}
                    onTogglePanel={onTicketPanelChange}
                    onReorderTickets={onReorderTickets}
                    onTicketHoverStart={onTicketHoverStart}
                    onTicketHoverEnd={onTicketHoverEnd}
                    onUserHoverStart={onUserHoverStart}
                    onUserHoverEnd={onUserHoverEnd}
                  />
                </div>
              ))}
            </div>
          </div>
          )
    }
  </>)
}

DispatchTicketsList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string
  })).isRequired,
  driverSortPreferences: PropTypes.arrayOf(PropTypes.shape({
    order: PropTypes.number.isRequired,
    isHidden: PropTypes.bool.isRequired,
    userId: PropTypes.string.isRequired
  })).isRequired,
  onSaveDriverSortPreferences: PropTypes.func.isRequired,
  unassignedTicketsSortPreference: PropTypes.shape({
    sortPreference: PropTypes.oneOf(['ASSET_TYPE', 'POSTAL_CODE', 'REQUESTED_TIME', 'TICKET_TYPE']),
    sortFlagged: PropTypes.bool.isRequired,
    hideCancelled: PropTypes.bool.isRequired
  }),
  onSaveUnassignedTicketsSortPreference: PropTypes.func.isRequired,
  tickets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    // This is currently a number from the Dispatcher API, should probably be a string instead
    driverId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    requestedStartTime: PropTypes.string,
    flagged: PropTypes.bool.isRequired,
    resourceType: PropTypes.shape({
      name: PropTypes.string
    }),
    ticketType: PropTypes.shape({
      name: PropTypes.string
    }),
    job: PropTypes.shape({
      zip: PropTypes.string
    })
  })).isRequired,
  onUpdateTicketFlag: PropTypes.func.isRequired,
  dispatchDate: PropTypes.string.isRequired,
  dispatchTickets: PropTypes.arrayOf(PropTypes.object),
  onAssignTicketToUser: PropTypes.func.isRequired,
  onReorderTickets: PropTypes.func.isRequired,
  onTicketHoverStart: PropTypes.func.isRequired,
  onTicketHoverEnd: PropTypes.func.isRequired,
  onUserHoverStart: PropTypes.func.isRequired,
  onUserHoverEnd: PropTypes.func.isRequired,
  locationDetailTickets: PropTypes.arrayOf(PropTypes.object),
  onLocationDetailChange: PropTypes.func.isRequired,
  onTicketPanelChange: PropTypes.func.isRequired,
  ticketPanelOpen: PropTypes.string
}
