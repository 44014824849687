import notify from './notify'

export const handleError = (error, notificationMessage = 'Something went wrong. Please refresh and try again.') => {
  // eslint-disable-next-line no-console
  if (error.response && error.response.status && error.response.status === 401) {
    notify('error', 'Invalid username or password: Please check the username and password you entered and try to sign in again.')
    return [{ code: 'Authorization Failed', title: 'Authorization Error. Please refresh the page and sign in again.' }]
  }
  if (error.status && error.status === 426) {
    setTimeout(() => (window.location.reload()), 10)
    return [{ code: 'Out of Date', title: 'Your Thumbster version is out of date. Please refresh the page.' }]
  }
  if (error.response && error.response.data.errors) {
    const errorList = error.response.data.errors.map((error) => error.title)
    notify('error', errorList.join('. '), 5)
    return error.response.data.errors
  } else if (error.response && error.response.data.error) {
    notify('error', error.response.data.error, 5)
    return [error.response.data.error]
  }
  notify('error', notificationMessage, 5)
  return [{ code: 0, title: notificationMessage }]
}

export default handleError
