import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'

export default function AddTicketToInvoiceConfirmationModal ({
  onCancel,
  onConfirm,
  hasInvoiceDiscount,
  hasInvoiceTax,
  isSaving = false
}) {
  const [modalText, setModalText] = useState('')

  useEffect(() => {
    if (hasInvoiceDiscount && hasInvoiceTax) {
      setModalText('tax and discount')
    } else if (hasInvoiceDiscount) {
      setModalText('discount')
    } else if (hasInvoiceTax) {
      setModalText('tax')
    }
  }, [hasInvoiceDiscount, hasInvoiceTax])

  return (
    <div className={cn('dis-panel', styles.addTicketContainer)}>
      <i className={cn('material-icons-outlined', styles.warningIcon)}>error_outline</i>
      <p className={cn(styles.addTicketText)}>
        One or more selected tickets have a {modalText}. Adding them will <strong>clear the invoice level {modalText}</strong> that has been set. Do you still want to add the tickets to the invoice?
      </p>
      <div className={cn(styles.addTicketButtonWrapper)}>
        <button
          className='dis-btn dis-btn-lg dis-btn-blank'
          onClick={onCancel}>
          No, Cancel
        </button>
        <button
          className='dis-btn dis-btn-lg dis-btn-primary'
          onClick={onConfirm}
          disabled={isSaving}>
          Yes, Add to Invoice
        </button>
      </div>
      <footer className={cn(styles.addTicketsFooter)}>Removing invoice level tax and/or discount is irreversible.</footer>
    </div>
  )
}

AddTicketToInvoiceConfirmationModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  hasInvoiceDiscount: PropTypes.bool.isRequired,
  hasInvoiceTax: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired
}
