import React, { useState, useMemo } from 'react'
import bowser from 'bowser'
import styles from './ticket-images.module.scss'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import cloudinary from 'cloudinary-core/cloudinary-core-shrinkwrap'
import classNames from 'classnames'

export default function TicketImages ({ images, onImageAdd, onImageDelete, ticketCustomId }) {
  const downloadable = bowser.name === 'Chrome' || bowser.name === 'Edge'
  const [cl] = useState(cloudinary.Cloudinary.new({ cloud_name: 'thumbster' }))
  const [lightboxImage, setLightboxImage] = useState(null)

  const ticketImages = useMemo(() => {
    if (!cl.url) return

    return images.map(image => {
      if (image?.isArchived) {
        return {
          downloadUrl: null,
          id: image.id,
          public_id: null,
          src: null,
          isArchived: image?.isArchived
        }
      }

      return {
        downloadUrl: cl.url(image.publicId, {
          flags: 'attachment',
          crop: 'fit',
          fetch_format: 'jpg',
          quality: 'auto'
        }),
        id: image.id,
        public_id: image.publicId,
        src: cl.url(image.publicId, {
          flags: 'attachment',
          crop: 'fit',
          fetch_format: 'jpg',
          quality: 'auto'
        }),
        isArchived: image?.isArchived
      }
    })
  }, [cl, images])

  return (
    <div className='dis-panel'>
      <div className='dis-panel-header'>
        <h5>Ticket Images</h5>
      </div>
      <div className={classNames('dis-panel-body', styles.ticketImagesPanel)}>
        {lightboxImage &&
          <button className={styles.lightbox} onClick={() => setLightboxImage(null)}>
            <img className={styles.lightboxImg} src={lightboxImage.src} alt='Attached' />
            {downloadable &&
              <a className='dis-btn dis-btn-sm dis-btn-success' href={lightboxImage.downloadUrl} download>
                Download
                <i className='material-icons dis-btn-icon'>file_download</i>
              </a>
            }
            <button
              className={classNames('dis-btn dis-btn-sm dis-btn-danger', styles.deleteImageButton)}
              onClick={() => onImageDelete(lightboxImage.public_id)}>
              Remove Image
              <i className='material-icons dis-btn-icon'>clear</i>
            </button>
          </button>
        }

        {ticketImages?.length === 0 && <div className={styles.noImages}>No images attached.</div>}

        <button className={classNames('dis-btn dis-btn-lg dis-btn-primary', styles.addImageButton)}
          onClick={onImageAdd}>
          Add Ticket Image
          <i className='material-icons dis-btn-icon'>add_circle</i>
        </button>
        <div className={styles.imagesContainer}>
          {ticketImages?.map(image => (
            image?.isArchived
              ? (<div key={image.id} className={classNames('dis-panel', styles.ticketImagePanel, styles.ticketArchivedPanel)}>
                <h6 className={styles.archivedImageTitle}>This image has been archived.</h6>
                <h6 className={styles.archivedImageInstructions}>
                  Images that are more than 2 years old are placed in the archive. Contact our support team with the ticketID #{ticketCustomId} if you require access to it.
                </h6>
              </div>)
              : (<div key={image.id} className={classNames('dis-panel', styles.ticketImagePanel)}>
                <button
                  className={styles.ticketImageWrapper}
                  onClick={() => setLightboxImage(image)}>
                  <img
                    className={styles.ticketImage} src={image.src}
                    alt='Thumbster Ticket'
                    data-tip='Right Click to Save'
                  />
                  <ReactTooltip />
                </button>
                <div className={styles.buttonContainer}>
                  {downloadable &&
                    <a className='dis-btn dis-btn-xs dis-btn-success' href={image.downloadUrl} download>
                      <i className='material-icons'>file_download</i>
                    </a>
                  }
                  <button
                    className={classNames('dis-btn dis-btn-xs dis-btn-danger', styles.deleteImageButton)}
                    onClick={() => onImageDelete(image.public_id)}>
                    <i className='material-icons'>clear</i>
                  </button>
                </div>
              </div>)
          ))}
        </div>
      </div>
    </div>
  )
}
TicketImages.propTypes = {
  images: PropTypes.array.isRequired,
  onImageAdd: PropTypes.func.isRequired,
  onImageDelete: PropTypes.func.isRequired,
  ticketCustomId: PropTypes.string.isRequired
}
