import { applyMiddleware, createStore, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { rootReducer } from './rootReducer'
import rootSaga from './rootSaga'

const sagaMiddleware = createSagaMiddleware()

// https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup
export const configure = (initialState = {}) => {
  const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    })
    : compose

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(sagaMiddleware)
    )
  )

  sagaMiddleware.run(rootSaga)

  window.thumbster_redux = store

  if (module.hot) {
    (module).hot.accept(rootReducer, () => {
      // eslint-disable-next-line global-require
      const nextReducer = require('./rootReducer').rootReducer
      store.replaceReducer(nextReducer)
    })
  }
  return store
}
