export const validate = (values) => {
  const errors = {}

  if (!values.uid) {
    errors.uid = 'Required'
  } else if (values.uid.length > 100) {
    errors.uid = 'Asset Name must be less than 100 characters'
  }

  if (!values.resourceTypeId) {
    errors.resourceTypeId = 'Required'
  }

  return errors
}
