import axios from 'axios'
import { GraphQLClient } from 'graphql-request'
import USER_ACTIONS from '../user/userActions'

const initialState = {
  dispatcher: {
    // GraphQL client to use to access the Dispatcher API
    graphQLClient: makeDispatcherGraphQLClient()
  },
  thumbster: {
    // Axios client to use to access the Thumbster API
    axios: makeThumbsterAxios()
  },
  // Do the data loaders currently have authentication tokens to use
  isAuth: false
}

/*
 * Data fetching/loading utilities specific to a user. These make requests using the current users credentials.
 * This is stored in global state because we do some setup work to these tools like automatically set
 * the correct authentication headers, and it would be painful to manually do that in every component that fetches data.
 */
const dataLoadersReducer = (state = initialState, action) => {
  const { payload, type } = action
  switch (type) {
    case USER_ACTIONS.SET_JWT: {
      return {
        ...state,
        dispatcher: {
          graphQLClient: makeDispatcherGraphQLClient(payload.accessToken)
        },
        thumbster: {
          axios: makeThumbsterAxios(payload.accessToken)
        },
        isAuth: Boolean(payload.accessToken)
      }
    }
    case USER_ACTIONS.CLEAR_USER: {
      return {
        ...state,
        dispatcher: {
          graphQLClient: makeDispatcherGraphQLClient()
        },
        thumbster: {
          axios: makeThumbsterAxios()
        },
        isAuth: false
      }
    }
    default:
      return state
  }
}

export default dataLoadersReducer

function makeThumbsterAxios (accessToken) {
  const options = {
    baseURL: `${process.env.REACT_APP_API_URL}/v1`,
    headers: {}
  }
  if (accessToken) {
    options.headers = {
      ...options.headers,
      // NOTE: This SHOULD be "Authorization: Bearer ${accessToken}" per Authorization header spec, but Thumbster did
      // not handle this correctly.
      Authorization: accessToken
    }
  }

  return axios.create(options)
}

function makeDispatcherGraphQLClient (accessToken) {
  const options = { headers: {} }

  if (accessToken) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${accessToken}`
    }
  }

  return new GraphQLClient(`${process.env.REACT_APP_NEW_API_URL}/graphql`, options)
}
