import React, { useState, useEffect, createRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import DispatchTicketDisplay from './dispatch-ticket-display'
import UnassignedTicketsSort from './unassigned-tickets-sort'

export default function DispatchUnassignedTicketsContainer ({
  onUpdateTicketFlag,
  isPanelOpen,
  onTogglePanel,
  unassignedTicketsSortPreference,
  onSaveUnassignedTicketsSortPreference,
  tickets,
  onAssignTicketToUser,
  onTicketHoverStart,
  onTicketHoverEnd,
  onUserHoverStart,
  onUserHoverEnd
}) {
  const [ticketListMaxHeight, setTicketListMaxHeight] = useState('0px')
  const unassignedTicketsRef = createRef()

  useEffect(() => {
    if (unassignedTicketsRef && unassignedTicketsRef.current) {
      setTicketListMaxHeight(isPanelOpen && tickets.length ? `${unassignedTicketsRef.current.scrollHeight}px` : '0px')
    }
  }, [isPanelOpen, tickets, unassignedTicketsRef])

  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className='p-6 border border-gray-light-10-dark -mb-1 unassigned-item-display cursor-pointer'
        onClick={() => {
          if (!tickets.length) return
          onTogglePanel('unassigned')
        }}>
        <div
          className='-mx-6 px-6 -mt-4'
          onMouseEnter={() => onUserHoverStart('unassigned')}
          onMouseLeave={onUserHoverEnd}>
          <span className='float-left badge badge-white w-1/12 mr-6'>{tickets.length}</span>
          <div className='font-size-1-5em'>Unassigned Tickets</div>
        </div>
      </div>
      <div
        className={classNames('m-0 p-0 dispatch-tickets-panel', {
          'dispatch-tickets-panel_open': isPanelOpen,
          'dispatch-tickets-panel_closed': !isPanelOpen
        })}
        ref={unassignedTicketsRef}
        style={{ maxHeight: ticketListMaxHeight }}>
        <UnassignedTicketsSort
          unassignedTicketsSortPreference={unassignedTicketsSortPreference}
          onSaveUnassignedTicketsSortPreference={onSaveUnassignedTicketsSortPreference}
        />
        {tickets.map(ticket => (
          <div
            className='py-2 px-8 -mb-1 border-b border-gray-light'
            key={ticket.id}
            onMouseEnter={() => onTicketHoverStart(ticket.id)}
            onMouseLeave={onTicketHoverEnd}>
            <DispatchTicketDisplay
              ticket={ticket}
              onAssignTicketToUser={onAssignTicketToUser}
              onUpdateTicketFlag={onUpdateTicketFlag}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

DispatchUnassignedTicketsContainer.propTypes = {
  onUpdateTicketFlag: PropTypes.func.isRequired,
  isPanelOpen: PropTypes.bool.isRequired,
  onTogglePanel: PropTypes.func.isRequired,
  unassignedTicketsSortPreference: PropTypes.object.isRequired,
  onSaveUnassignedTicketsSortPreference: PropTypes.func.isRequired,
  tickets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired
  })).isRequired,
  onAssignTicketToUser: PropTypes.func.isRequired,
  onTicketHoverStart: PropTypes.func.isRequired,
  onTicketHoverEnd: PropTypes.func.isRequired,
  onUserHoverStart: PropTypes.func.isRequired,
  onUserHoverEnd: PropTypes.func.isRequired
}
