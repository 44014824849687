import { loader } from 'graphql.macro'

// could take this and make 1 function, pass in the type of template as an arg and go from there.
// saves from having 2 functions that do the same thing just call different templates. Then don't be a goober
// and make sure graphql files are updated mostly the same or whatever idk.
export function jobsTableQuery ({ queryKey, dataLoaders }) {
  const [, haulerId, sortColumn = 'CUSTOM_ID', sortDirection = 'DESC', searchFields, pager = null] = queryKey

  let graphQLVariables = {
    haulerId,
    sortColumn,
    sortDirection,
    searchFields
  }

  if (pager && typeof pager === 'object') {
    if (!pager?.pageSize) {
      pager.pageSize = 10
    }

    const paginatedVariables = {
      before: pager.direction === 'before' ? pager.cursor : null,
      after: pager.direction === 'after' ? pager.cursor : null,
      first: pager.direction === 'after' ? Number(pager.pageSize) : null,
      last: pager.direction === 'before' ? Number(pager.pageSize) : null
    }
    graphQLVariables = { ...graphQLVariables, ...paginatedVariables }
  }

  const jobsQueryGraphql = loader('./jobs-search-table.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(jobsQueryGraphql, graphQLVariables)
}

export function jobsQuery ({ queryKey, dataLoaders }) {
  const [, haulerId, sortColumn = 'custom_id', sortDirection = 'DESC', searchFields, pager = null] = queryKey

  let graphQLVariables = {
    haulerId,
    sortColumn,
    sortDirection,
    searchFields
  }

  if (pager) {
    const paginatedVariables = {
      before: pager.direction === 'before' ? pager.cursor : null,
      after: pager.direction === 'after' ? pager.cursor : null,
      first: pager.direction === 'after' ? Number(pager.pageSize) : null,
      last: pager.direction === 'before' ? Number(pager.pageSize) : null
    }
    graphQLVariables = { ...graphQLVariables, ...paginatedVariables }
  }

  const jobsQueryGraphql = loader('./jobs.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(jobsQueryGraphql, graphQLVariables)
}
