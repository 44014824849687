import { loader } from 'graphql.macro'

export default async function exportClients ({ dataLoaders, variables }) {
  const schema = loader('./exportClients.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      haulerId: variables.haulerId,
      sortColumn: variables.sortColumn,
      sortDirection: variables.sortDirection,
      searchTerm: variables.searchTerm
    }
  })
  return response.exportClients
}
