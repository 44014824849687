import React from 'react'
import { ANDROID_APP_LINK, IOS_APP_LINK } from '../../utilities/appStoreLinks'

export default function MobileAppLinks () {
  return (
    <div className='flex flex-col md:flex-row mt-10 items-center justify-around w-full md:w-4/5 mx-auto'>
      <a href={IOS_APP_LINK} target='_blank' rel='noreferrer'>
        <img
          className='login__app-icons'
          src='https://www.dispatcher.com/images/app-store.png'
          alt='Get it on the App Store'
        />
      </a>
      <a href={ANDROID_APP_LINK} target='_blank' rel='noreferrer'>
        <img
          className='login__app-icons mt-4 md:mt-0'
          src='https://www.dispatcher.com/images/google-play.png'
          alt='Get it on Google Play'
        />
      </a>
    </div>
  )
}
