import { loader } from 'graphql.macro'

export default async function saveDriverSortPreferences ({ dataLoaders, variables }) {
  const schema = loader('./saveDriverSortPreferences.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      ownerId: variables.ownerId,
      driverSortPreferences: variables.driverSortPreferences
    }
  })
  return response.saveDriverSortPreferences
}
