import { put, takeLatest } from 'redux-saga/effects'
import { loader } from 'graphql.macro'
import { handleError } from '../../../utilities/handleError'
import { makeGraphQLClient } from '../../../utilities/graphqlClient'

export const REQUEST_RECENT_ACTIVITY_ACTION = 'REQUEST_RECENT_ACTIVITY_ACTION'
export const RECEIVE_RECENT_ACTIVITY_SUCCESS = 'RECEIVE_RECENT_ACTIVITY_SUCCESS'
export const RECEIVE_RECENT_ACTIVITY_FAILURE = 'RECEIVE_RECENT_ACTIVITY_FAILURE'

function * requestRecent (data) {
  try {
    const { page } = data.payload
    const graphQLClient = makeGraphQLClient()
    const activitiesQuery = loader('../../../graphql/queries/activitiesPages.graphql')
    const response = yield graphQLClient.request(activitiesQuery, {
      page
    })
    yield put({ type: RECEIVE_RECENT_ACTIVITY_SUCCESS, payload: response.activitiesPages })
  } catch (e) {
    const errors = handleError(e)
    yield put({ type: RECEIVE_RECENT_ACTIVITY_FAILURE, payload: { errors } })
  }
}

export default function * recentSaga () {
  yield takeLatest(REQUEST_RECENT_ACTIVITY_ACTION, requestRecent)
}
