import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, Link } from 'react-router-dom'
import moment from 'moment'
import CursorPagination from '../../../../../../components/cursor-pagination'
import NoResults from '../../../../../shared/NoResults'
import { ROUTES } from '../../../../../../routes'
import InvoiceStatusLabel from '../../../../../../components/invoice/InvoiceStatusLabel'
import { commaDeliminate } from '../../../../../../utilities/stringUtilities'
import OpenStatus from '../../../../../../components/invoice/action/open-status'
import PaidStatus from '../../../../../../components/invoice/action/PaidStatus'
import ClosedInQboStatus from '../../../../../../components/invoice/action/ClosedInQboStatus'

export default function ClientInvoicesTable ({
  invoices,
  onPageRequest,
  fakeCount,
  hauler
}) {
  return (
    <div className='row panel panel-default'>
      <div className='panel-heading'>
        <div className='container-fluid'>
          <h4 className='pull-left'>Account Invoices</h4>
        </div>
      </div>
      <div className='panel-body table-responsive'>
        <div className='row'>
          <div className='col-xs-12'>
            <table className='table table-striped'>
              <thead>
                <tr>
                  <th width='12%'>Invoice No.</th>
                  <th width='12%'>Created Date</th>
                  <th width='12%'>Account</th>
                  <th width='12%'>Status</th>
                  {hauler.quickbooks.isConnected && <th width='12%'>QBO Sync</th>}
                  <th width='12%'>Line Items</th>
                  <th width='12%'>Amount</th>
                  <th width='12%'>Action</th>
                </tr>
              </thead>
              <tbody>
                {invoices.nodes.length === 0
                  ? (
                    <tr>
                      <td colSpan={8}><NoResults /></td>
                    </tr>
                    )
                  : invoices.nodes.map((invoice) => (
                    <tr key={invoice.id}>
                      <td>
                        <Link to={generatePath(ROUTES.invoice, { id: invoice.id })}>
                          {invoice.invoiceNumber}
                        </Link>
                      </td>
                      <td>
                        {moment(invoice.createdAt).format('ll')}
                      </td>
                      <td>
                        <Link to={`/hauler/clients/${invoice.client.id}`}>{invoice.client.name}</Link>
                      </td>
                      <td>
                        <InvoiceStatusLabel>{invoice.status}</InvoiceStatusLabel>
                      </td>
                      {hauler.quickbooks.isConnected && <td>
                        {invoice.qboSync}
                      </td>}
                      <td>
                        {invoice.ticketsCount}
                      </td>
                      <td>
                        ${commaDeliminate(invoice.total)}
                      </td>
                      <td>
                        <div className='btn-group'>
                          <Link to={generatePath(ROUTES.invoice, { id: invoice.id })} className='btn btn-sm btn-primary-dk'>
                            Details
                          </Link>
                          <button className='btn btn-primary-dk btn-sm dropdown-toggle hidden-xs hidden-sm drop-down' data-toggle='dropdown'>
                            <span className='caret' />
                          </button>
                          {invoice.status.toUpperCase() === 'PAID' && hauler.quickbooks.isConnected && <PaidStatus invoice={invoice} />}
                          {invoice.status.toUpperCase() === 'OPEN' && <OpenStatus invoice={invoice} hauler={hauler} />}
                          {invoice.status.toUpperCase() === 'CLOSED_IN_QBO' && hauler.quickbooks.isConnected && <ClosedInQboStatus invoice={invoice} />}
                        </div>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            {invoices.nodes.length > 0 &&
              <CursorPagination
                pageInfo={invoices.pageInfo}
                totalCount={invoices.totalCount}
                onPageRequest={onPageRequest}
                fakeCount={fakeCount}
              />}
          </div>
        </div>
      </div>
    </div>
  )
}

ClientInvoicesTable.propTypes = {
  invoices: PropTypes.shape({
    nodes: PropTypes.array.isRequired,
    pageInfo: PropTypes.object.isRequired,
    totalCount: PropTypes.number.isRequired
  }),
  onPageRequest: PropTypes.func.isRequired,
  fakeCount: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired
  }),
  hauler: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isWastepayApproved: PropTypes.bool.isRequired,
    quickbooks: PropTypes.shape({
      isConnected: PropTypes.bool.isRequired
    }).isRequired
  }).isRequired
}
