import { loader } from 'graphql.macro'

export default async function wastepaySetCardNickname ({ dataLoaders, variables }) {
  const schema = loader('./wastepaySetCardNickname.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      clientId: variables.clientId,
      cardId: variables.cardId,
      nickname: variables.nickname
    }
  })

  return response.wastepaySetCardNickname
}
