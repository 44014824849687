import allClientsSaga from '../pages/hauler/clients/sagas/allClientsSaga'
import batchTicketsDeleteSaga from '../pages/hauler/tickets/sagas/batchTicketsDeleteSaga'
import currentHaulerTeamMemberNotificationsSaga
  from '../pages/hauler/team/sagas/currentHaulerTeamMemberNotificationsSaga'
import currentHaulerTeamMemberSaga from '../pages/hauler/team/sagas/currentHaulerTeamMemberSaga'
import currentJobActivitiesSaga from '../pages/hauler/jobs/sagas/currentJobActivitiesSaga'
import currentJobSaga from '../pages/hauler/jobs/sagas/currentJobSaga'
import currentResourceActivitiesSaga from '../pages/hauler/resources/sagas/currentResourceActivitiesSaga'
import currentSiteTypeSaga from '../pages/hauler/site-types/sagas/currentSiteTypeSaga'
import currentTicketSaga from '../pages/hauler/tickets/sagas/currentTicketSaga'
import haulerTeamSaga from '../pages/hauler/team/sagas/haulerTeamSaga'
import haulerTeamMemberActivateDeactivateSaga from '../pages/hauler/team/sagas/haulerTeamMemberActivateDeactivateSaga'
import recentSaga from '../pages/recent/sagas/recentSaga'
import recentTicketsSaga from '../pages/recent/sagas/recentTicketsSaga'
import siteTypesSaga from '../pages/hauler/site-types/sagas/siteTypesSaga'
import ticketTypesSaga from '../pages/hauler/ticket-types/sagas/ticketTypesSaga'

export default function * rootSaga () {
  yield [
    allClientsSaga(),
    batchTicketsDeleteSaga(),
    currentHaulerTeamMemberNotificationsSaga(),
    currentHaulerTeamMemberSaga(),
    currentJobActivitiesSaga(),
    currentJobSaga(),
    currentResourceActivitiesSaga(),
    currentSiteTypeSaga(),
    currentTicketSaga(),
    haulerTeamSaga(),
    haulerTeamMemberActivateDeactivateSaga(),
    recentSaga(),
    recentTicketsSaga(),
    siteTypesSaga(),
    ticketTypesSaga()
  ]
}
