import React from 'react'
import NormalLayoutContainer from './../shared/NormalLayoutContainer'

export default function UserManual () {
  return (
    <NormalLayoutContainer>
      <h1 className='text-6xl mt-16'>Dispatcher User Manual</h1>
      <p className='font-size-1-25em mt-8 mb-12'>Click the button below to view and download the user manual.
        This guide is intended to help you get the most out of Dispatcher including general site navigation, account setup,
        best practices and more. </p>
      <a
        href='https://www.dispatcher.com/documents/dispatcher-user-manual-1.0.pdf'
        target='_blank'
        rel='noreferrer nofollow'
        className='uppercase bg-green py-3 px-4 rounded font-size-1-25em font-medium text-black hover:bg-green-10-dark hover:no-underline
         hover:text-black shadow-md'>
        Download The Manual
      </a>
    </NormalLayoutContainer>
  )
}
