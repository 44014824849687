import { NEW_PUSHER_SERVICE_REQUEST_DATA } from '../../pusher/pusherActions'

export const RECEIVE_NEW_REQUESTS_COUNT = 'RECEIVE_NEW_REQUESTS_COUNT'

const initialState = {
  newRequestsCountRefreshNeeded: false,
  newRequestsRefreshNeeded: false,
  count: 0
}

export default function newRequestsReducer (reduxState = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case NEW_PUSHER_SERVICE_REQUEST_DATA:
      return {
        ...reduxState,
        newRequestsRefreshNeeded: payload.newRequestsRefreshNeeded,
        newRequestsCountRefreshNeeded: payload.newRequestsCountRefreshNeeded
      }
    case RECEIVE_NEW_REQUESTS_COUNT:
      return {
        ...reduxState,
        count: payload.count
      }
    default:
      return {
        ...reduxState
      }
  }
}
