import React from 'react'
import PropTypes from 'prop-types'

export default function PauseTicketButton ({ onClick, ticket, disabled = false }) {
  const status = ticket.status
  if (!status) return null
  const closed = status === 'completed' || status === 'cancelled'
  const enRoute = status === 'en_route'
  const notEnRouteOrClosed = !(enRoute || closed)
  if (notEnRouteOrClosed) return null
  return (
    <button
      type='button'
      className='dis-btn dis-btn-alert dis-btn-lg'
      onClick={onClick}
      disabled={disabled}>
      {enRoute ? 'Pause' : 'Open'} Ticket
      <i className='material-icons dis-btn-icon'>{enRoute ? 'pause' : 'lock_open'}</i>
    </button>
  )
}

PauseTicketButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  ticket: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string
  }).isRequired,
  disabled: PropTypes.bool
}
