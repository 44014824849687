import { loader } from 'graphql.macro'

export default function wastepayReceiptPreview ({ dataLoaders, queryKey }) {
  const [, input] = queryKey
  const {
    companyName,
    companyAddress,
    companyPhone,
    emailMessage,
    transactionType,
    showLineItems
  } = input
  const previewQuery = loader('./wastepayReceiptPreview.graphql')
  const params = {
    companyName,
    companyAddress,
    companyPhone,
    emailMessage,
    transactionType,
    showLineItems
  }
  return dataLoaders.dispatcher.graphQLClient.request(previewQuery, params)
}
