import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { AvailabilityTable } from './AvailabilityTable'
import Spinner from 'react-spinkit'
import styles from './availability.module.scss'
import { loader } from 'graphql.macro'
import { useSelector } from 'react-redux'
import { availabilitiesProps } from './utils'

export default function Availability () {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [availabilities, setAvailabilities] = useState(null)
  const { hauler, graphQLClient } = useSelector(({ user, dataLoaders }) => ({
    hauler: user.hauler,
    graphQLClient: dataLoaders.dispatcher.graphQLClient
  }))

  useEffect(() => {
    setLoading(true)
    setError(null)
    const availabilityQuery = loader('../../graphql/queries/availability.graphql')
    graphQLClient.request(availabilityQuery, { haulerId: hauler.id })
      .then(({ haulerAvailabilities }) => setAvailabilities(haulerAvailabilities))
      .catch(error => setError(error))
      .finally(() => setLoading(false))
  }, [hauler.id, graphQLClient])

  return (
    <section className={styles.availability}>
      {
        error
          ? <Error error={error} />
          : (
            <Table
              loading={loading}
              availabilities={availabilities}
              onChange={availabilities => setAvailabilities(availabilities)}
              onError={setError}
              haulerId={hauler.id}
            />
            )
      }

    </section>
  )
}

function Error () {
  return (
    <div className={styles.error}>
      <i className={classNames('material-icons', styles.errorIcon)}>error</i>
      <p>There was a problem with availability.</p>
    </div>
  )
}

function Table ({ loading, availabilities, onChange, haulerId, onError }) {
  return (
    <>
      <h2 className={styles.header}>Manage Availability</h2>
      <div className={styles.tableWrapper}>
        {
          loading
            ? <Spinner name='circle' className={styles.spinner} fadeIn='none' />
            : <AvailabilityTable
                haulerId={haulerId}
                availabilities={availabilities}
                onChange={onChange}
                onError={onError}
              />
        }
      </div>
    </>
  )
}
Table.propTypes = {
  loading: PropTypes.bool.isRequired,
  availabilities: availabilitiesProps,
  onChange: PropTypes.func.isRequired,
  haulerId: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired
}
