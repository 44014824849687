import React, { useState } from 'react'
import styles from './index.module.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'
import CardImage from '../cc-card-image'
import CardDetails from './cc-list-item'
import useMutation from '../../hooks/useMutation'
import { Field, Form } from 'react-final-form'
import wastepaySetPrimaryCardMutation from '../../graphql/mutations/wastepaySetPrimaryCard'
import wastepayCreateCardMutation from '../../graphql/mutations/wastepayCreateCard'
import wastepaySetCardNicknameMutation from '../../graphql/mutations/wastepaySetCardNickname'
import ElavonLightboxForm from '../../components/elavon-lightbox-form'
// import { cardBrandToEnum } from '../../utilities/card'
import { US_STATES_ABBR, CA_PROVINCE_ABBR } from '../../utilities/constants'
import { validate } from './utils'
import { generateQueryKey } from '../../hooks/useQuery'
import QUERY_KEYS from '../../graphql/queryKeys'
import notify from '../../utilities/notify'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import wastepayDeleteCardMutation from '../../graphql/mutations/wastepayDeleteCard'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'

export default function ManageCcCardModal ({
  clientName,
  clientId,
  cardList,
  close,
  allowAddCard = false,
  countryCode,
  refetchCardList = () => {}
}) {
  const { user } = useSelector(({ user }) => ({ user: user.user }))
  const [cardToRemove, setCardToRemove] = useState(null)
  const [showCardInput, setShowCardInput] = useState(false)
  const [addCard, setAddCard] = useState(false)
  const queryClient = useQueryClient()

  const { mutateAsync: wastepaySetPrimaryCard, isLoading: isSettingPrimaryCard } = useMutation(
    wastepaySetPrimaryCardMutation,
    {
      onSuccess ({ card: newCardData }) {
        const queryKey = generateQueryKey([QUERY_KEYS.wastepayCustomerCardList, { clientId }], user.id)
        queryClient.setQueryData(queryKey, oldData => ({
          ...oldData,
          wastepayGetCustomerCards: oldData.wastepayGetCustomerCards.map(card => {
            if (card.id !== newCardData.id) {
              return { ...card, isPrimary: false }
            }
            return { ...card, ...newCardData }
          })
        }))
        notify('success', `Successfully set ${newCardData.cardBrand} ${newCardData.cardNumber.slice(-4)} as primary card`)
      },
      onError (error) {
        captureErrorAndNotify(error, 'Error setting primary card')
      }
    }
  )

  const { mutateAsync: wastepayDeleteCard, isLoading: isDeletingCard } = useMutation(wastepayDeleteCardMutation, {
    onSuccess ({ card: deletedCard }) {
      const queryKey = generateQueryKey([QUERY_KEYS.wastepayCustomerCardList, { clientId }], user.id)
      queryClient.setQueryData(queryKey, (oldData) => ({
        ...oldData,
        wastepayGetCustomerCards: (oldData.wastepayGetCustomerCards || []).filter(card => card.id !== deletedCard.id)
      }))
    },
    onError (error) {
      captureErrorAndNotify(error, 'Failed to delete card')
    }
  })

  const { mutateAsync: wastepayCreateCard, isLoading: isCreatingCard } = useMutation(wastepayCreateCardMutation, {
    async onSuccess () {
      await refetchCardList()
      cancelAddCard()
    },
    onError (error) {
      captureErrorAndNotify(error, 'Error saving credit card')
      close()
    }
  })

  const { mutateAsync: wastepaySetCardNickname, isLoading: isUpdatingNickname } = useMutation(wastepaySetCardNicknameMutation, {
    async onSuccess () {
      notify('success', 'Nickname successfully saved')
      await refetchCardList()
    },
    onError (error) {
      captureErrorAndNotify(error, 'Error saving nickname to card')
    }
  })

  function handleSetPrimaryCard (card) {
    return wastepaySetPrimaryCard({ cardId: card.id, clientId })
  }

  function handleSetNickname (cardId, nickname) {
    return wastepaySetCardNickname({
      clientId,
      cardId,
      nickname
    })
  }

  function handleAddCardClick () {
    setAddCard(true)
  }

  function cancelAddCard () {
    setShowCardInput(false)
    setAddCard(false)
  }

  async function handleRemoveCard () {
    if (cardToRemove == null) return
    const data = await wastepayDeleteCard({ cardId: cardToRemove.id, clientId })
    close()
    return data
  }

  function handleSelectForRemoval (card) {
    setCardToRemove(card)
  }

  if (cardToRemove !== null) {
    return (
      <div className={styles.manageCardsModal}>
        <h4 className={styles.manageCardListTitle}>
          {`Delete Card Ending in ${cardToRemove.cardNumber.slice(-4)}`}
        </h4>
        <div className={styles.removeCardContainer}>
          <CardImage type={cardToRemove.cardBrand} size='large' />
          <div className={styles.removeCardDetailsContainer}>
            {cardToRemove.cardNumber}
            <span className={styles.cardDetailDivider}>|</span>
            {`Exp. ${cardToRemove.cardExpirationMonth.toString().padStart(2, '0')}/${cardToRemove.cardExpirationYear.toString().slice(-2)}`}
            {cardToRemove.isPrimary && <div className={styles.defaultCardBadge}>Default Card</div>}
          </div>
        </div>
        <div className={styles.removeCardButtonContainer}>
          <button
            disabled={isDeletingCard}
            className={cn(styles.cancelBtn, 'dis-btn dis-btn-white dis-btn-sm dis-btn-no-shadow')}
            onClick={() => handleSelectForRemoval(null)}>
            Cancel
          </button>
          <button
            disabled={isDeletingCard}
            className={cn(styles.deleteBtn, 'dis-btn dis-btn-primary dis-btn-sm')}
            onClick={handleRemoveCard}>
            Delete
          </button>
        </div>
      </div>
    )
  }

  function handleBillingAddressSubmit () {
    setShowCardInput(true)
  }

  function handleCardProcessing (cardDetails) {
    wastepayCreateCard(
      {
        ...cardDetails,
        ...{
          clientId,
          countryCode
        }
      })
  }

  if (addCard) {
    return (
      <div className={styles.manageCardsModal}>
        <Form
          onSubmit={handleBillingAddressSubmit}
          component={AddCardForm}
          clientId={clientId}
          validate={validate}
          showCardInput={showCardInput}
          isUS={countryCode === 'US'}
          clientName={clientName}
          close={close}
          handleCardProcessing={handleCardProcessing}
          isCreatingCard={isCreatingCard}
        />
      </div>
    )
  }

  return (
    <div className={styles.manageCardsModal}>
      <h4 className={styles.manageCardListTitle}>{`Manage Cards for ${clientName}`}</h4>
      {cardList.map((card, idx) => (
        <CardDetails
          key={idx}
          isFinalCard={idx === cardList.length - 1}
          isUpdating={isDeletingCard || isUpdatingNickname || isSettingPrimaryCard}
          handleSetPrimaryCard={handleSetPrimaryCard}
          handleSelectForRemoval={handleSelectForRemoval}
          handleSetNickname={handleSetNickname}
          card={card}
        />
      ))}
      <div className={styles.manageCardsActionWrapper}>
        {allowAddCard && (
          <button
            className={cn(styles.closeModalBtn, 'dis-btn dis-btn-lg dis-btn-primary')}
            type='button'
            onClick={handleAddCardClick}>
            Add Card
            <i className={cn(styles.closeModalIcon, 'material-icons dis-btn-icon')}>add_circle</i>
          </button>
        )}
        <button
          className={cn(styles.closeModalBtn, 'dis-btn dis-btn-no-shadow')}
          type='button'
          onClick={close}>
          <i className={cn(styles.closeModalIcon, 'material-icons dis-btn-icon')}>close</i>
          Close
        </button>
      </div>
    </div>
  )
}

ManageCcCardModal.propTypes = {
  clientName: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  cardList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    cardBrand: PropTypes.string.isRequired,
    cardExpirationMonth: PropTypes.number.isRequired,
    cardExpirationYear: PropTypes.number.isRequired,
    isPrimary: PropTypes.bool.isRequired
  })).isRequired,
  close: PropTypes.func.isRequired,
  refetchCardList: PropTypes.func,
  allowAddCard: PropTypes.bool,
  countryCode: PropTypes.string.isRequired
}

function AddCardForm ({
  handleSubmit,
  submitting,
  values,
  clientName,
  close,
  showCardInput,
  clientId,
  invalid,
  isUS,
  handleCardProcessing,
  isCreatingCard = false
}) {
  const [, setCardError] = useState([])
  const stateList = isUS ? US_STATES_ABBR : CA_PROVINCE_ABBR

  function hasError (meta) {
    return meta.touched && meta.error && !meta.active
  }

  return (
    <form onSubmit={handleSubmit}>
      {!showCardInput
        ? (
          <section className={cn(styles.addCardSection)}>
            <h3>
              Add Card for {clientName}
            </h3>
            <h4>
              Billing Address
            </h4>
            <div className={cn(styles.formRow)}>
              <Field name='streetAddress'>
                {({ input, meta }) => (
                  <div className={cn({ 'has-error': hasError(meta) }, styles.fullWidthInput)}>
                    <label>
                      Street Address
                      <input
                        {...input}
                        className='form-control'
                        type='text'
                      />
                    </label>
                    {hasError(meta) && <h6>{meta.error}</h6>}
                  </div>
                )}
              </Field>
            </div>
            <div className={cn(styles.formRow, styles.formRowThird)}>
              <Field name='city'>
                {({ input, meta }) => (
                  <div className={cn({ 'has-error': hasError(meta) })}>
                    <label>
                      City
                      <input
                        {...input}
                        className='form-control'
                        type='text'
                      />
                    </label>
                    {hasError(meta) && <h6>{meta.error}</h6>}
                  </div>
                )}
              </Field>
              <Field name='state'>
                {({ input, meta }) => (
                  <div className={cn({ 'has-error': hasError(meta) })}>
                    <label>
                      {isUS ? 'State' : 'Province'}
                      <select
                        {...input}
                        className='form-control'>
                        <option />
                        { stateList.map((v, ind) => (
                          <option value={v} key={ind}>{v}</option>
                        ))}
                      </select>
                    </label>
                    {hasError(meta) && <h6>{meta.error}</h6>}
                  </div>
                )}
              </Field>
              <Field name='zip'>
                {({ input, meta }) => (
                  <div className={cn({ 'has-error': hasError(meta) })}>
                    <label>
                      {isUS ? 'Zip' : 'Postal'} Code
                      <input
                        {...input}
                        className='form-control'
                        type='text'
                      />
                    </label>
                    {hasError(meta) && <h6>{meta.error}</h6>}
                  </div>
                )}
              </Field>
            </div>
            <div className={styles.manageCardsActionWrapper}>
              <button
                className={cn(styles.closeModalBtn, 'dis-btn dis-btn-no-shadow')}
                type='button'
                onClick={close}>
                <i className={cn(styles.closeModalIcon, 'material-icons dis-btn-icon')}>close</i>
                Cancel
              </button>
              <button
                className={cn(styles.closeModalBtn, 'dis-btn dis-btn-lg dis-btn-primary')}
                disabled={invalid || submitting}
                type='submit'>
                Add Card
                <i className={cn(styles.closeModalIcon, 'material-icons dis-btn-icon')}>add_circle</i>
              </button>
            </div>
          </section>
          )
        : (
          <section>
            <ElavonLightboxForm
              handleSubmit={handleCardProcessing}
              billingAddress={values}
              allowAddNickname={true}
              client={{
                id: clientId
              }}
              hideCards={true}
              isCreatingCard={isCreatingCard}
              setCardError={setCardError}
              buttonText='ADD CARD'
              allowCreateCard={false}
            />
          </section>
          )}
    </form>
  )
}

AddCardForm.propTypes = {
  /**
   * https://final-form.org/docs/react-final-form/types/FormRenderProps
   */
  handleSubmit: PropTypes.func.isRequired,
  /**
   * https://final-form.org/docs/final-form/types/FormState#submitting
   */
  invalid: PropTypes.bool.isRequired,
  /**
    * https://final-form.org/docs/final-form/types/FormState#submitting
    */
  submitting: PropTypes.bool.isRequired,
  /**
   * https://final-form.org/docs/final-form/types/FormState#initialvalues
   */
  close: PropTypes.func.isRequired,
  handleCardProcessing: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  showCardInput: PropTypes.bool.isRequired,
  isUS: PropTypes.bool.isRequired,
  isCreatingCard: PropTypes.bool,
  clientId: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired
}
