const normalizePositiveNumber = (value) => {
  if (!value) {
    return value
  }

  const parsed = parseInt(value, 10)

  if (parsed < 0) {
    return null
  }
  return parsed
}

export default normalizePositiveNumber
