import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import sortBy from 'lodash/sortBy'
import { generatePath, Link } from 'react-router-dom'
import styles from './index.module.scss'
import ButtonLinkOrNotification from '../../button-link-or-notification'
import dayjs from '../../../utilities/dayjs'
import { commaDeliminate, enumToTitleCase } from '../../../utilities/stringUtilities'
import { formatTransactionMethod } from '../../../utilities/invoiceUtilities'
import ReceiptActions from '../../receipt-actions'
import { ROUTES } from '../../../routes'

export default function InvoiceTransactionsTable ({
  hasInvoiceTickets,
  invoice,
  paymentPath,
  transactions = [],
  dispatcherTransactions = [],
  onQboExport,
  invoicer,
  isWPError = false,
  isDispError = false,
  isQBOSynced,
  isWastepayConnected = false
}) {
  // This may be temporary, but I don't believe this table was meant to show failed transactions -> Correct, it's not supposed to -> Yes.
  const successTransactions = useMemo(function filterOutBadTransactions () {
    function mapDispatcherPaymentTransactions (txn) {
      return {
        id: txn.id,
        transactionType: 'SALE',
        type: 'Sale',
        method: formatTransactionMethod(txn),
        qboID: txn.qboPaymentId,
        qboLink: txn.qboPaymentUrl,
        date: dayjs(txn.paymentDate).format('MMM D, YYYY'),
        amount: txn.paymentAmount,
        provider: 'DISPATCHER',
        remainingAmount: txn.refundableAmount,
        refundRoute: generatePath(ROUTES.dispatcherRefund, { invoiceId: invoice.id, paymentId: txn.id })
      }
    }

    function mapDispatcherRefundTransactions (txn) {
      return {
        id: txn.id,
        transactionType: 'RETURN',
        type: 'Return',
        method: formatTransactionMethod(txn.dispatcherPayment),
        qboID: txn.qboRefundId,
        qboLink: txn.qboRefundUrl,
        date: dayjs(txn.createdAt).format('MMM D, YYYY'),
        amount: txn.refundAmount,
        provider: 'DISPATCHER',
        remainingAmount: 0,
        refundRoute: ''
      }
    }

    function mapWastepayTransactions (txn) {
      return {
        id: txn.id,
        transactionType: txn.transactionType,
        type: enumToTitleCase(txn.transactionType),
        method: `${txn.nickname || txn.cardBrand}${txn.cardNumber.slice(-5)}`,
        qboID: txn.qbo.id,
        qboLink: txn.qbo.link,
        date: dayjs(txn.transactionDate).format('MMM D, YYYY'),
        amount: txn.amount,
        provider: 'WASTEPAY',
        remainingAmount: txn.remainingAmount,
        refundRoute: generatePath(ROUTES.wastepayRefund, { invoiceId: invoice.id, transactionId: txn.id })
      }
    }
    const dispatcherPaymentsList = dispatcherTransactions?.payments?.map(mapDispatcherPaymentTransactions) ?? []
    const dispatcherRefundsList = dispatcherTransactions?.refunds?.map(mapDispatcherRefundTransactions) ?? []
    const transactionList = transactions
      .filter(transaction => transaction?.status?.toLowerCase() === 'complete')
      .map(mapWastepayTransactions)
    return sortBy([...transactionList, ...dispatcherPaymentsList, ...dispatcherRefundsList], ['date']).reverse()
  }, [transactions, dispatcherTransactions, invoice.id])

  return (
    <table className='table table-responsive table-striped'>
      <thead>
        <tr>
          <th width='10%'>Transaction ID</th>
          <th width='10%'>Type</th>
          <th width={isQBOSynced ? '15%' : '35%'}>Method</th>
          {isQBOSynced
            ? <th width='20%'>QuickBooks Sync Status</th>
            : null
          }
          <th width='10%'>Date</th>
          <th width='10%'>Amount</th>
          <th width='25%'>Actions</th>
        </tr>
      </thead>
      <tbody>
        {invoice.status === 'CLOSED_IN_QBO'
          ? (
            <tr className={styles.qboTxnMessage}>
              <td colSpan={isQBOSynced ? 8 : 7} className={styles.transactionTableMessage}>
                Any payments must be applied in QuickBooks and will not be reflected back in Dispatcher.
              </td>
            </tr>
            )
          : (
              (!transactions.length && !dispatcherTransactions.payments?.length)
                ? (
                  <tr className={styles.qboTxnMessage}>
                    <td colSpan={isQBOSynced ? 7 : 6}>
                      <div className={styles.noPaymentsMessage}>No payments have been applied to this invoice.</div>
                      {(isWastepayConnected || invoicer === 'DISPATCHER') && (
                        <ButtonLinkOrNotification
                          linkClasses={cn('dis-btn dis-btn-lg dis-btn-primary', {
                            'dis-disabled-link': !hasInvoiceTickets
                          })}
                          linkHref={paymentPath}
                          linkText='COLLECT PAYMENT'
                          notificationText='Account has not been exported to WastePay.'
                          notificationType='error'
                          dontExport={isWastepayConnected && !invoice.client.wastepayCustomerId && invoice.client.didPromptClientExportSelection}
                        />
                      )}
                    </td>
                  </tr>)
                : successTransactions.map((txn) => (
                  <tr key={txn.id}>
                    <td>
                      {txn.id}
                    </td>
                    <td>
                      {txn.type}
                    </td>
                    <td>
                      {txn.method}
                    </td>
                    { isQBOSynced
                      ? (<td>
                        {txn.qboID ? 'Success' : 'Not Synced'} | {txn.qboID
                          ? <a className='dis-btn dis-btn-link' href={txn.qboLink}>View in QBO</a>
                          : <button className='dis-btn dis-btn-link' onClick={() => onQboExport(invoice, txn)}>Export to QBO</button>
                        }
                      </td>)
                      : null
                    }
                    <td>
                      {txn.date}
                    </td>
                    <td>
                      ${commaDeliminate(txn.amount)}
                    </td>
                    <td>
                      <div className={cn(styles.buttonWrapper)}>
                        <ReceiptActions
                          transactionId={txn.id}
                          receiptProvider={txn.provider}
                          client={invoice.client}
                          invoiceId={invoice.id}
                          parentClass={styles.printBtn}
                          spinnerClass={styles.printBtnSpinner}
                          buttons={transactionActions()}
                          transactionType={txn.transactionType}
                        />
                        {txn.transactionType.toLowerCase() === 'sale' && txn.remainingAmount > 0 && (
                          <Link to={txn.refundRoute}
                            className='btn btn-sm btn-danger'>
                            Refund
                          </Link>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
            )}
        {isWPError && (
          <tr>
            <td
              colSpan={7}
              className={cn(styles.transactionError)}>
              An error occurred trying to load your credit card transactions.
            </td>
          </tr>
        )}
        {isDispError && (
          <tr>
            <td
              colSpan={7}
              className={cn(styles.transactionError)}>
              An error occurred trying to load your transactions.
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

InvoiceTransactionsTable.propTypes = {
  hasInvoiceTickets: PropTypes.bool.isRequired,
  invoice: PropTypes.shape({
    status: PropTypes.string.isRequired,
    client: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    tickets: PropTypes.array.isRequired
  }).isRequired,
  paymentPath: PropTypes.string.isRequired,
  dispatcherTransactions: PropTypes.shape({
    payments: PropTypes.arrayOf(PropTypes.shape({
      checkNumber: PropTypes.string,
      id: PropTypes.string.isRequired,
      notes: PropTypes.string,
      paymentAmount: PropTypes.number.isRequired,
      paymentDate: PropTypes.string.isRequired,
      paymentMethod: PropTypes.oneOf(['CASH', 'CHECK', 'OTHER'])
    })),
    refunds: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      refundAmount: PropTypes.number.isRequired,
      dispatcherTransaction: PropTypes.shape({
        checkNumber: PropTypes.string,
        id: PropTypes.string.isRequired,
        notes: PropTypes.string,
        paymentAmount: PropTypes.number.isRequired,
        paymentDate: PropTypes.string.isRequired,
        paymentMethod: PropTypes.oneOf(['CASH', 'CHECK', 'OTHER'])
      })
    }))
  }),
  transactions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    approvalCode: PropTypes.string,
    cardNumber: PropTypes.string,
    transactionType: PropTypes.string,
    qbo: PropTypes.shape({
      id: PropTypes.string,
      link: PropTypes.string
    })
  })),
  onQboExport: PropTypes.func.isRequired,
  isWPError: PropTypes.bool,
  isDispError: PropTypes.bool,
  isQBOSynced: PropTypes.bool.isRequired,
  isWastepayConnected: PropTypes.bool,
  invoicer: PropTypes.string.isRequired
}

function transactionActions () {
  return [
    {
      action: 'print',
      icon: false,
      text: 'Print/Save Receipt',
      btnClasses: cn(styles.printBtn, 'dis-btn dis-btn-primary-dk dis-btn-sm')
    }
  ]
}
