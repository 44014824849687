import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import cn from 'classnames'
import classNamePropTypes from '../../utilities/classNamePropTypes'

/**
 * Accordion component to show/hide content upon clicking.
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {string} props.title
 * @param {boolean} props.initiallyOpen
 * @param {*} [props.className]
 * @return {JSX.Element}
 */
export default function Accordion ({ children, title, initiallyOpen = false, className }) {
  const [open, setOpen] = useState(initiallyOpen)

  function toggleOpen () {
    setOpen(v => !v)
  }

  return (
    <div className={cn([className, styles.panel, { [styles.open]: open }])}>
      <button onClick={toggleOpen} type='button' className={styles.title}>
        <span>{title}</span>
        <i className={cn([styles.icon, 'material-icons'])}>{open ? 'expand_less' : 'expand_more'}</i>
      </button>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  )
}

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  initiallyOpen: PropTypes.bool,
  className: classNamePropTypes
}
