import { intercomConnection } from '../../intercomConnection'
import { loader } from 'graphql.macro'

export default async function createAsset ({ dataLoaders, variables }) {
  const schema = loader('./createAsset.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      uid: variables.uid,
      resourceTypeId: variables.resourceTypeId,
      placementNote: variables.placementNote,
      location: variables.location
    }
  })
  intercomConnection.trackEvent('resource_created', {})
  return response.createAsset
}
