import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { components } from 'react-select'
import Select from '../Select'

export default function InventoryWidgetSelect ({
  className,
  options,
  placeholder,
  onChange,
  disabled,
  value,
  defaultValue
}) {
  const DropdownIndicator = props => (
    <components.DropdownIndicator {...props}>
      <i className={classNames('material-icons', {
        'text-gray': disabled,
        'text-black': !disabled
      })}>
        arrow_drop_down
      </i>
    </components.DropdownIndicator>
  )

  const MultiValue = props => (
    <components.MultiValue {...props}>
      {props.data.shortName || props.data.label}
    </components.MultiValue>
  )

  MultiValue.propTypes = {
    data: PropTypes.object
  }

  const Option = props => {
    return (
      <components.Option {...props}>
        <input type='checkbox' key={props.value} checked={props.isSelected} />
        <label className='whitespace-nowrap'>{props.label}</label>
      </components.Option>
    )
  }

  Option.propTypes = {
    isSelected: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.any
  }

  const styles = {
    control: (provided) => ({
      ...provided,
      border: 'none',
      backgroundColor: 'transparent',
      borderRadius: 0,
      borderBottom: '1px solid #6a7789',
      minHeight: 25,
      height: 25
    }),
    input: (provided) => ({
      ...provided,
      '&input:focus': {
        outline: 'none'
      }
    }),
    multiValueRemove: () => ({
      position: 'relative',
      top: -3,
      cursor: 'pointer'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0,
      cursor: 'pointer'
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    multiValueLabel: () => ({
      fontSize: 12,
      position: 'relative',
      top: -2
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: 'white',
      borderRadius: 7,
      padding: '0 .25rem',
      height: 16,
      position: 'relative',
      top: -5
    }),
    menu: (provided) => ({
      ...provided,
      minWidth: 200,
      position: 'absolute',
      right: 0,
      top: -1,
      boxShadow: '0 1px 8px 0 rgb(0 0 0 / 54%)',
      zIndex: 100,
      width: 'auto'
    }),
    group: () => ({
      padding: 0
    }),
    option: () => ({
      padding: '0px 12px',
      fontSize: 14,
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap'
    }),
    placeholder: (provided) => ({
      ...provided,
      display: 'none'
    }),
    groupHeading: (provided) => ({
      ...provided,
      textTransform: 'none',
      fontSize: 14,
      color: '#434a54',
      textAlign: 'left'
    })
  }

  return (
    <Select
      className={classNames(className)}
      placeholder={placeholder}
      styles={styles}
      isMulti
      options={options}
      components={{ DropdownIndicator, MultiValue, Option }}
      onChange={onChange}
      removeSelected={false}
      isClearable={false}
      isDisabled={disabled}
      closeMenuOnSelect={false}
      defaultValue={defaultValue}
      value={value}
    />
  )
}

InventoryWidgetSelect.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.array,
  disabled: PropTypes.bool,
  value: PropTypes.array.isRequired
}
