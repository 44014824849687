import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'
import { generatePath, Link } from 'react-router-dom'
import { ROUTES } from '../../../routes'
import dayjs from '../../../utilities/dayjs'
import { commaDeliminate, formatDollarAmount, stringToTime } from '../../../utilities/stringUtilities'

export default function InvoiceLineItemsTable ({
  hasInvoiceTickets,
  invoice,
  qboInvoiced
}) {
  return (
    <table className={cn('table table-responsive table-striped', styles.invoiceLineItemTable)}>
      <thead>
        <tr>
          <th width='15%'>Ticket ID</th>
          <th width='15%'>Date</th>
          <th width='20%'>Ticket Type</th>
          <th width='25%'>Address</th>
          <th width='30%'>Charges</th>
        </tr>
      </thead>
      <tbody>
        {hasInvoiceTickets
          ? <>
            {invoice?.tickets?.map((ticket) => (
              <tr key={ticket.id}>
                <td>
                  <Link to={generatePath(ROUTES.ticket, { id: ticket.id })}>{ticket.customId}</Link>
                </td>
                <td>
                  <div>
                    {dayjs(ticket.date).format('MMM D, YYYY')}
                    <br />
                    <small>
                      {
                        ticket.requestedStartTime
                          ? `${stringToTime(ticket.requestedStartTime)}-${stringToTime(ticket.requestedEndTime)}`
                          : null
                      }
                    </small>
                  </div>
                </td>
                <td>
                  {ticket.ticketType.name} ({ticket.ticketType.shortCode})
                </td>
                <td>
                  <div>{ticket.job.location?.addressLine1}</div>
                </td>
                <td>
                  {!ticket.ticketFees.length
                    ? <div className='text-danger'>No Ticket Fees</div>
                    : (
                      <table className={cn(styles.feeTypeTable, styles.subTable)}>
                        <thead>
                          <tr>
                            <th width='60%'>Fee Type</th>
                            <th width='15%'>Qty</th>
                            <th width='25%' className='text-right'>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                          ticket.ticketFees.map((ticketFee, index, arr) => {
                            const lastIndex = index === arr.length - 1
                            return (
                              (
                                <tr key={index}>
                                  <td className={cn({
                                    [styles.lastTicketFee]: lastIndex
                                  })}>
                                    {ticketFee.name}
                                  </td>
                                  <td className={cn({
                                    [styles.lastTicketFee]: lastIndex
                                  })}>
                                    {ticketFee?.quantity}
                                  </td>
                                  <td className={cn('text-right', {
                                    [styles.lastTicketFee]: lastIndex
                                  })}>
                                    ${commaDeliminate(ticketFee.amountCents * ticketFee.quantity / 100)}
                                  </td>
                                </tr>
                              )
                            )
                          })
                        }
                          <tr>
                            <td colSpan={2}><strong>Ticket Total</strong></td>
                            <td className='text-right'>
                              ${formatDollarAmount(ticket?.ticketFeeTotal)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      )
                  }
                </td>
              </tr>
            )
            )}
            <tr>
              <td className={styles.totalCell} colSpan='4' />
              <td className={styles.totalCell}>
                <table className={cn(styles.totalTable, styles.subTable)}>
                  <tbody>
                    <tr>
                      <td className={styles.totalCell}>Discount</td>
                      <td className={cn('text-right', styles.totalCell)}>({commaDeliminate(invoice?.discountTotal)})</td>
                    </tr>
                    <tr>
                      <td className={styles.totalCell}>Subtotal</td>
                      <td className={cn('text-right', styles.totalCell)}>{commaDeliminate(invoice?.subtotal)}</td>
                    </tr>
                    <tr>
                      <td className={styles.totalCell}>Tax</td>
                      <td className={cn('text-right', styles.totalCell)}>{commaDeliminate(invoice?.taxTotal)}</td>
                    </tr>
                    <tr>
                      <td className={cn(styles.totalFontMedium, styles.totalCell, styles.totalCellBorderTop)}>Total</td>
                      <td className={cn('text-right', styles.totalFontMedium, styles.totalCell, styles.totalCellBorderTop)}>
                        ${commaDeliminate(invoice?.total)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </>
          : <tr className={styles.noTicketsMessage}>
            <td className={styles.noTicketsMessageCell} colSpan='5'>There are no tickets on this invoice.</td>
          </tr>}
      </tbody>
      {qboInvoiced && <tr className={styles.qboFeeMessaging}>
        <td colSpan={6}>* Fees and totals may have changed in QBO and will not be reflected here.</td>
      </tr>}
    </table>
  )
}

InvoiceLineItemsTable.propTypes = {
  hasInvoiceTickets: PropTypes.bool.isRequired,
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    tickets: PropTypes.arrayOf(PropTypes.object),
    total: PropTypes.number.isRequired,
    subtotal: PropTypes.number.isRequired,
    discountTotal: PropTypes.number.isRequired,
    taxTotal: PropTypes.number.isRequired
  }).isRequired,
  qboInvoiced: PropTypes.bool.isRequired
}
