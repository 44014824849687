import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

export default function ParsingFailedView ({ errorMessage, returnTo }) {
  return (
    <div className='text-center my-20'>
      <i className='material-icons text-8xl text-red opacity-90'>warning_amber</i>
      <h4 className='text-5xl text-red my-10'>Oops, looks like something went wrong...</h4>
      <p className='text-3xl text-red font-medium'>{errorMessage}</p>

      <Link to={returnTo} className='btn btn-lg text-black my-12'>
        <span className='font-medium'>Return to Previous</span>
      </Link>
    </div>
  )
}

ParsingFailedView.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  returnTo: PropTypes.string.isRequired
}
