import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import notify from '../../utilities/notify'
import styles from './index.module.scss'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import PageTitle from '../../components/page-title'
import FormExplanation from '../shared/FormExplanation'
import ClientCreateForm from '../../components/client-create-form'
import { ROUTES } from '../../routes'
import useMutation from '../../hooks/useMutation'
import createClientMutation from '../../graphql/mutations/createClient'

export default function ClientCreatePage () {
  const history = useHistory()
  const { user } = useSelector(({ user: { user } }) => ({ user }))

  const { mutate: createClient, isLoading } = useMutation(createClientMutation, {
    onSuccess ({ client }, variables) {
      const path = variables.navToJob ? `/hauler/jobs/new?client_id=${client.id}` : `/hauler/clients/${client.id}`
      history.push(path)
      notify('success', 'Account Created Successfully')
    },
    onError () {
      notify('error', 'Failed to Create Account')
    }
  })

  function handleFormSubmit (vals) {
    const { navToJob, ...clientFields } = vals
    if (clientFields.primaryPhoneExtension) {
      clientFields.primaryPhoneExtension = parseInt(clientFields.primaryPhoneExtension)
    }
    createClient({ haulerId: user.haulerId, clientFields, navToJob })
  }

  return (
    <NormalLayoutContainer>
      <PageTitle>
        <div className={styles.pageTitle}>
          <div>New Account</div>
          <Link className='dis-btn dis-btn-blank dis-btn-lg' to={ROUTES.clients}>
            Cancel
          </Link>
        </div>
      </PageTitle>

      <div className='dis-form-page-container'>
        <div className='dis-form-container-left'>
          <ClientCreateForm onSubmit={handleFormSubmit} isFetching={isLoading} />
        </div>
        <div className='dis-form-container-right'>
          <FormExplanation
            messages={[
              'A company account is where all customer information can be stored.',
              'You can list accounts by company names, by contact names or both.'
            ]}
          />
        </div>
      </div>
    </NormalLayoutContainer>
  )
}
