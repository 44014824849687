import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import cn from 'classnames'
import normalizePhone from '../../pages/shared/normalizers/normalizePhone'
import { validate } from './utils'
import { enumToTitleCase } from '../../utilities/stringUtilities'
import PageTitle from '../../components/page-title'
import { TextInput } from '../TextInput'
import Tabs from '../tabs'
import InvoiceForm from './InvoiceForm'
import RefundForm from './RefundForm'
import PaymentForm from './PaymentForm'
import { Spinner } from '../../pages/shared/Spinner'
import WastepaySettingsImage from '../../components/wastepay-settings-image'
import styles from './index.module.scss'
import { OnChange } from 'react-final-form-listeners'

export default function InvoiceSettingsForm ({
  accessToken,
  disconnectWastepayLoading,
  handleDeleteLogo,
  handleDisconnectWastepay,
  handleUpload,
  hauler,
  onSubmit,
  logoPublicId,
  isFetching,
  initialValues,
  previewInputs,
  paymentTerms,
  setPreviewInputs,
  receiptPreviewFetching,
  currentTab,
  setCurrentTab
}) {
  const wastepayURL = process.env.REACT_APP_ENV === 'production' ? 'https://app.wastepay.com/' : 'https://dev-app.wastepay.com/'

  function previewButton (form, handleSendFromDispatcherChange = false, handleResetToPayment = false) {
    const formValues = form.getState()?.values
    const isPayment = currentTab.toLowerCase() === 'payment' || handleResetToPayment
    const isInvoice = currentTab.toLowerCase() === 'invoice' || handleSendFromDispatcherChange
    let emailMessage = ''
    let subject = ''
    let showLineItems = false

    if (isInvoice) {
      emailMessage = formValues.invoiceEmailMessage || null
      subject = formValues.invoiceEmailSubject || ''
    } else if (isPayment) {
      emailMessage = formValues.paymentEmailMessage || null
      subject = formValues.paymentEmailSubject || ''
      showLineItems = formValues.sendPaymentLineItems || false
    } else {
      emailMessage = formValues.refundEmailMessage || null
      subject = formValues.refundEmailSubject || ''
    }

    const inputs = {
      companyName: formValues.companyName || '',
      companyAddress: formValues.companyAddress || '',
      companyPhone: formValues.companyPhone || '',
      transactionType: isInvoice ? 'INVOICE' : isPayment ? 'SALE' : 'REFUND',
      sendAsName: formValues.sendAsName || '',
      subject: `${subject} - Invoice #[XXXXX]`,
      fetch: true,
      showLineItems,
      emailMessage
    }
    setPreviewInputs(inputs)
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      className={cn(styles.settingsForm)}
      validate={values => validate(values)}>
      {({ handleSubmit, invalid, pristine, form, values }) => {
        const showTemplateSection = hauler.isWastepayConnected || values.sendInvoicesFrom === 'DISPATCHER'
        return (
          <form autoComplete='off' onSubmit={handleSubmit}>
            <div className='dis-page-title-flex'>
              <PageTitle>
                Invoice Settings
              </PageTitle>
              <button
                type='submit'
                className={cn('dis-btn dis-btn-lg dis-btn-primary', styles.submitBtnTop)}
                disabled={pristine || invalid || isFetching}>
                Save Changes
                <i className='material-icons dis-btn-icon'>check</i>
              </button>
            </div>
            {isFetching
              ? <Spinner isFetching />
              : (
                <>
                  <div className={cn('dis-panel', styles.formContainer)}>
                    <h4 className={styles.formHeader}> Invoicing Options </h4>
                    <div className={cn(styles.sectionTitle, styles.sendInvoicesTitle)}>Send Invoices From</div>
                    <div className={styles.sublabel}>Choose the system you want to use to send open invoices to customers.</div>
                    <label htmlFor='dispatcher' className={styles.radioLabel}>
                      <Field id='dispatcher' name='sendInvoicesFrom' type='radio' value='DISPATCHER' component='input' />
                      <span className={styles.radioBtn}>Dispatcher</span>
                      <div className={styles.radioBtnDescription}>Send open invoices from Dispatcher with customizable Terms and Conditions language</div>
                    </label>
                    <label htmlFor='quickbooks' className={styles.radioLabel}>
                      <Field id='quickbooks' name='sendInvoicesFrom' type='radio' value='QUICKBOOKS' component='input' />
                      <span className={styles.radioBtn}>Quickbooks</span>
                      <div className={styles.radioBtnDescription}>Export open invoices to QuickBooks Online and choose when to tell QuickBooks to send your invoices</div>
                    </label>
                    <label htmlFor='noneOther' className={styles.radioLabel}>
                      <Field id='noneOther' name='sendInvoicesFrom' type='radio' value='NONE_OTHER' component='input' />
                      <span className={styles.radioBtn}>None/Other</span>
                      <div className={styles.radioBtnDescription}>Choose this option if you do not want to send open invoices to customers</div>
                    </label>
                    <OnChange name='sendInvoicesFrom'>
                      {(val, prevVal) => {
                        if (val === 'DISPATCHER') {
                          setCurrentTab('Invoice')
                          previewButton(form, true)
                        } else {
                          setCurrentTab('Payment')
                          previewButton(form, false, true)
                        }
                      }}
                    </OnChange>

                    <div className={styles.optionsSection}>
                      {values.sendInvoicesFrom === 'DISPATCHER' && <>
                        <h4 className={styles.formHeader}>Invoice Terms</h4>
                        <Field name='defaultPaymentTerms'>
                          {({ input }) => (
                            <div className='form-group'>
                              <label htmlFor='defaultPaymentTerms' className={cn('control-label', styles.sectionTitle)}>Default Payment Terms</label>
                              <div className={styles.sublabel}>
                                Choose the default payment terms you want to use for new tickets created. You can update the payment terms for each ticket as needed.
                              </div>
                              <select
                                {...input}
                                className={cn('form-control', styles.paymentTermsSelect)}>
                                <option key='placeholder' value=''></option>
                                {paymentTerms?.map((pt) =>
                                  (
                                    <option
                                      value={pt.value}
                                      key={pt.value}>
                                      {enumToTitleCase(pt.value)}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          )}
                        </Field>
                        <Field name='defaultTerms'>
                          {({ input }) => (
                            <label className={styles.defaultTerms}>
                              <div className={styles.sectionTitle}>
                                Default Terms and Conditions
                              </div>
                              <div className={styles.sublabel}>
                                This is an explanation of terms and conditions and how they can be edited per invoice
                              </div>
                              <textarea
                                {...input}
                                className='form-control'
                              />
                            </label>
                          )}
                        </Field></>}
                    </div>
                    <div className={styles.optionsSection}>
                      {hauler.isWastepayEnabled && hauler.country === 'US' && <h4 className={styles.formHeader}> Payment Options </h4>}
                      {hauler.isWastepayEnabled && !hauler.isWastepayApproved && (
                        <>
                          <div className={styles.connection}>
                            <div>
                              <div className={styles.sectionTitle500}>Credit Card Processing</div>
                              <div className={styles.sublabel}>
                                The WastePay integration gives you a fast and easy way to accept payments from your customers all within Dispatcher.
                              </div>
                            </div>
                            <a
                              href='https://www.dispatcher.com/payments-demo'
                              target='_blank noopener noreferrer'
                              className={cn(styles.learnMoreBtn, 'dis-btn dis-btn-lg dis-btn-primary-dk')}>
                              Learn More
                            </a>
                          </div>
                          <hr />
                        </>
                      )}
                      <div className={styles.wpFormSection}>
                        {hauler.isWastepayApproved && <div className={styles.connection}>
                          <div>
                            <div className={styles.sectionTitle500}>Credit Card Processing</div>
                            {hauler.isWastepayConnected && <div>You are connected to WastePay</div>}
                          </div>
                          {hauler.isWastepayConnected
                            ? (<div>
                              <a href={wastepayURL} target='_blank' rel='noreferrer' className={cn(styles.wpLink, 'dis-btn dis-btn-lg dis-btn-primary-dk')}>
                                Open WastePay
                              </a>
                              <button
                                className={cn('dis-btn dis-btn-lg dis-btn-white')}
                                onClick={handleDisconnectWastepay}
                                disabled={disconnectWastepayLoading}>
                                Disconnect WastePay
                              </button>
                            </div>)
                            : (<a href={`${process.env.REACT_APP_NEW_API_URL}/wastepay/oauth2/connect?authorization=${accessToken}`} target='_blank' rel='noreferrer'
                                className={cn(styles.wpConnectLink, 'dis-btn dis-btn-lg dis-btn-primary-lt')}>
                              CONNECT TO WASTEPAY
                            </a>
                              )}
                        </div>}
                        {showTemplateSection && <>
                          <div className={styles.templateSection}>
                            <h4 className={styles.formHeader}>Templates</h4>
                            <div className={styles.invoicePreview}>Invoice Preview</div>
                            <div className={cn('dis-panel', styles.receiptExampleContainer)}>
                              <div>
                                <span className={styles.settingsDisplayMd}>From:</span>
                                {` ${previewInputs.sendAsName || ''} | receipts@dispatcher.com`}
                              </div>
                              <div>
                                <span className={styles.settingsDisplayMd}>Subject:</span>
                                {
                                  previewInputs.transactionType === 'Refund'
                                    ? ` ${previewInputs.subject || 'Refund - Invoice #[XXXXXX]'}`
                                    : ` ${previewInputs.subject || 'Order Confirmation - Invoice #[XXXXXX]'}`
                                }
                              </div>
                            </div>
                            <div className={cn('dis-panel', styles.receiptExampleContainer, styles.receiptExampleContainerFull)}>
                              <iframe scrolling='no' title='email-preview' id='emailPreview' className={cn(styles['preview-iframe'])} />
                            </div>

                          </div>
                          <div className={styles.formSection}>
                            <>
                              <WastepaySettingsImage
                                publicId={logoPublicId}
                                handleUpload={handleUpload}
                                handleDelete={handleDeleteLogo}
                              />
                              <span className={styles.sectionTitleContainer}>
                                <span className={styles.sectionTitle500}>Company Billing Info</span>
                                <span className={styles.sectionTitleSecondary}>(as displayed on receipt)</span>
                              </span>
                              <Field
                                name='sendAsName'
                                label='Send As Name'
                                component={TextInput}
                              />
                              <div className={cn(styles.namePhoneSection)}>
                                <Field
                                  name='companyName'
                                  label='Company Name'
                                  className={cn(styles.companyName)}
                                  component={TextInput}
                                />
                                <Field
                                  name='companyPhone'
                                  label='Company Phone'
                                  className={cn(styles.companyPhone)}
                                  component={TextInput}
                                  parse={normalizePhone}
                                />
                              </div>
                              <Field
                                name='companyAddress'
                                label='Company Address'
                                component={TextInput}
                              />

                              <div className={cn(styles.sectionTitle500, styles.emailReceiptTitle)}>Email Receipt Options</div>
                              {/* state updates are handled internally in the tabs component and this ensures they reset when the send from options change */}
                              {values.sendInvoicesFrom === 'DISPATCHER' && <SendInvoiceTabsFromDispatcher handleUpdateTab={setCurrentTab} hauler={hauler} />}
                              {values.sendInvoicesFrom === 'QUICKBOOKS' && <SendInvoiceTabs handleUpdateTab={setCurrentTab} />}
                              {values.sendInvoicesFrom === 'NONE_OTHER' && <SendInvoiceTabs handleUpdateTab={setCurrentTab} />}
                            </>
                          </div>
                        </>}
                      </div>
                    </div>
                    {showTemplateSection && <div className={styles.previewButtonContainer}>
                      <button
                        type='button'
                        onClick={() => previewButton(form)}
                        className='dis-btn dis-btn-lg dis-btn-blank'
                        disabled={receiptPreviewFetching || isFetching}>
                        Preview Changes
                      </button>
                    </div>}
                    <hr />
                    <div className={styles.saveButtonContainer}>
                      <button
                        type='submit'
                        className='dis-btn dis-btn-lg dis-btn-primary'
                        disabled={pristine || invalid || isFetching}>
                        Save Changes
                        <i className='material-icons dis-btn-icon'>check</i>
                      </button>
                    </div>
                  </div>
                </>
                )}
          </form>
        )
      }}
    </Form>
  )
}

InvoiceSettingsForm.propTypes = {
  accessToken: PropTypes.string.isRequired,
  disconnectWastepayLoading: PropTypes.bool.isRequired,
  handleDeleteLogo: PropTypes.func.isRequired,
  handleDisconnectWastepay: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired,
  hauler: PropTypes.shape({
    country: PropTypes.string.isRequired,
    isWastepayApproved: PropTypes.bool.isRequired,
    isWastepayEnabled: PropTypes.bool.isRequired,
    isWastepayConnected: PropTypes.bool.isRequired
  }),
  isFetching: PropTypes.bool,
  logoPublicId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  paymentTerms: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired
  })),
  previewInputs: PropTypes.object.isRequired,
  setPreviewInputs: PropTypes.func.isRequired,
  receiptPreviewFetching: PropTypes.bool.isRequired,
  currentTab: PropTypes.string.isRequired,
  setCurrentTab: PropTypes.func.isRequired
}

function SendInvoiceTabsFromDispatcher ({ handleUpdateTab, hauler }) {
  const tabs = [
    {
      name: 'Invoice',
      content: <InvoiceForm hauler={hauler} />
    },
    {
      name: 'Payment',
      content: <PaymentForm />
    },
    {
      name: 'Refund',
      content: <RefundForm />
    }
  ]
  return (<Tabs tabs={tabs} handleUpdateTab={handleUpdateTab} />)
}

SendInvoiceTabsFromDispatcher.propTypes = {
  handleUpdateTab: PropTypes.func,
  hauler: PropTypes.shape({
    isWastepayConnected: PropTypes.bool.isRequired
  }).isRequired
}

function SendInvoiceTabs ({ handleUpdateTab }) {
  const tabs = [
    {
      name: 'Payment',
      content: <PaymentForm />
    },
    {
      name: 'Refund',
      content: <RefundForm />
    }
  ]
  return (<Tabs tabs={tabs} handleUpdateTab={handleUpdateTab} />)
}

SendInvoiceTabs.propTypes = {
  handleUpdateTab: PropTypes.func
}
