import moment from 'moment'
import { history } from '../../../history'
import { underscoreToCapital } from '../../../utilities/stringUtilities'

export const OPTIONS = {
  cutoutPercentage: 20,
  legend: {
    position: 'right'
  },
  tooltips: {
    enabled: false
  }
}

const goToTicketsView = (elementIndex) => {
  let status
  const today = moment().format('YYYY-MM-DD')
  let params = `minDate=${today}&maxDate=${today}`
  switch (elementIndex) {
    case 0:
      status = 'open'
      break
    case 1:
      status = 'en_route'
      break
    case 2:
      status = 'completed'
      break
    case 4:
      status = 'cancelled'
      break
    default:
      status = ''
      break
  }
  params += `&status=${status}`
  history.push(`/hauler/tickets/search?${params}`)
}

export const getElementAtEvent = (element) => {
  if (element[0]) {
    // eslint-disable-next-line no-underscore-dangle
    goToTicketsView(element[0]._index)
  }
}

const generateDataLabels = (statusCount) => Object.keys(statusCount).map(key => `${underscoreToCapital(key)} - ${statusCount[key]} `)
const generateStatuses = (statusCount) => Object.keys(statusCount).map(key => statusCount[key])

export const generateData = (statusCount) => ({
  labels: generateDataLabels(statusCount),
  datasets: [{
    data: generateStatuses(statusCount),
    backgroundColor: [
      '#33CA80', // open
      '#FF8F00', // en route
      '#131514', // completed
      '#f93b59' // cancelled
    ],
    hoverBackgroundColor: [
      '#33CA8099', // open
      '#FF8F0099', // en route
      '#13151499', // completed
      '#f93b5999' // cancelled
    ]
  }]
})
