import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../routes'
import cn from 'classnames'
import styles from './index.module.scss'

export default function InvoiceSettingsDisplay ({ hauler, accessToken }) {
  return (
    <div className='list-group-item'>
      <div className='flex justify-between flex-row'>
        <div className='mr-16'>
          <div className='font-bold text-3xl my-2'>Invoice Settings</div>
          <div className='text-2xl my-2'>
            This feature allows you to create and send invoices to your customer. Couple it with WastePay for a full billing suite.
          </div>
        </div>

        <div className='h-12 flex self-center'>
          <Link
            to={ROUTES.invoiceSettings}
            className={cn(styles.btnLink, 'dis-btn dis-btn-sm dis-btn-primary-lt')}>
            <span className={styles.mdText}>INVOICE SETTINGS</span>
          </Link>
        </div>
      </div>
    </div>
  )
}

InvoiceSettingsDisplay.propTypes = {
  hauler: PropTypes.shape({
    isWastepayApproved: PropTypes.bool.isRequired,
    isWastepayConnected: PropTypes.bool.isRequired,
    isWastepayEnabled: PropTypes.bool.isRequired
  }).isRequired,
  accessToken: PropTypes.string.isRequired
}
