import React from 'react'
import PropTypes from 'prop-types'
import styles from './ticket-status-label.module.scss'
import cn from 'classnames'
import { underscoreToCapital } from '../../../utilities/stringUtilities'

export default function TicketStatusLabel ({ hasActiveException, status }) {
  return (
    <div
      className={cn(
        'dis-label dis-label-pill',
        (status === 'open') && 'dis-label-success',
        (status === 'en_route') && 'dis-label-alert',
        (status === 'cancelled') && 'dis-label-warning',
        (status === 'completed') && 'dis-label-default-dk'
      )}>
      {underscoreToCapital(status.toUpperCase())}
      {hasActiveException && (status === 'completed') && (<>
        <span> W/ EXCEPTION</span>
        <i className={cn('material-icons', styles.exceptionIcon)}>error</i>
      </>)}
    </div>
  )
}

TicketStatusLabel.propTypes = {
  hasActiveException: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired
}
