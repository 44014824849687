import React, { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ActionText } from './shared/ActionText'
import Logo from '../components/Logo'
import NewPasswordForm from '../components/NewPasswordForm'
import finalizePasswordReset from '../graphql/mutations/finalizePasswordReset'
import notify from '../utilities/notify'
import { Spinner } from './shared/Spinner'
import useMutation from '../hooks/useMutation'

export default function FinishPasswordResetPage () {
  const location = useLocation()
  const history = useHistory()

  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location.search])

  const { mutate: changePassword, isLoading } = useMutation(finalizePasswordReset, {
    onSuccess () {
      notify('success', 'Password updated')
      history.push('/login')
    },
    onError () {
      notify('error', 'Failed to change password')
    }
  })

  function handlePasswordReset ({ password, passwordConfirmation }) {
    changePassword({ token: queryParams.get('reset_password_token'), password, passwordConfirmation })
  }

  if (isLoading) {
    return <Spinner isFetching />
  }

  return (
    <div className='container-fluid push-down-very-small'>
      <Logo />
      <ActionText text='Enter your new password' />
      <NewPasswordForm onSubmit={handlePasswordReset} />
    </div>
  )
}
