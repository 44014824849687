import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import NotAuthorizedPage from '../pages/NotAuthorizedPage'

export default function AuthenticatedRoute ({ path, component, exact = true }) {
  const { user, hauler, accessToken, jwt } = useSelector(({ user: { user, hauler, accessToken, jwt } }) => ({
    user,
    hauler,
    accessToken,
    jwt
  }))
  const location = useLocation()

  // A bit of a sanity check, but we should NEVER allow someone into an authenticated route without all of this information
  if (!user || !hauler || !accessToken || !jwt) {
    return (
      <Redirect
        exact={exact}
        to={`/login?redirectTo=${encodeURIComponent(location.pathname)}`}
        from={location.pathname}
      />
    )
  }

  if (user.role !== 'hauler_admin') {
    return <NotAuthorizedPage checkRole />
  }

  if (location.pathname.startsWith('/tickets/payment') && !hauler.isWastepayApproved) {
    return <NotAuthorizedPage />
  }

  if (location.pathname.startsWith('/pro') && !hauler.proSettings) {
    return <NotAuthorizedPage />
  }

  return (
    <Route exact={exact} path={path} component={component} />
  )
}

AuthenticatedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  exact: PropTypes.bool
}
