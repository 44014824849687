import { put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import BASE_URI from '../../../../utilities/BASE_URI'
import { handleError } from '../../../../utilities/handleError'
import { getHeaders } from '../../../../utilities/getHeaders'

export const REQUEST_HAULER_TEAM_ACTION = 'REQUEST_HAULER_TEAM_ACTION'
export const RECEIVE_HAULER_TEAM_SUCCESS = 'RECEIVE_HAULER_TEAM_SUCCESS'
export const RECEIVE_HAULER_TEAM_FAILURE = 'RECEIVE_HAULER_TEAM_FAILURE'

export const RECEIVE_CREATE_HAULER_TEAM_MEMBER_SUCCESS = 'RECEIVE_CREATE_HAULER_TEAM_MEMBER_SUCCESS'
export const RECEIVE_CREATE_HAULER_TEAM_MEMBER_FAILURE = 'RECEIVE_CREATE_HAULER_TEAM_MEMBER_FAILURE'

function * requestHaulerTeam () {
  try {
    const response = yield axios.get(`${BASE_URI}/hauler/users?show_deactivated=true`, getHeaders())
    yield put({ type: RECEIVE_HAULER_TEAM_SUCCESS, payload: response.data })
  } catch (e) {
    const errors = handleError(e)
    yield put({ type: RECEIVE_HAULER_TEAM_FAILURE, payload: { errors } })
  }
}

export default function * haulerTeamSaga () {
  yield takeLatest(REQUEST_HAULER_TEAM_ACTION, requestHaulerTeam)
}
