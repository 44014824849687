import PropTypes from 'prop-types'

/*
These are the acceptable values for the className library we are using. This utility is to just make typing prop types
easier. https://www.npmjs.com/package/classnames
*/

export default PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.objectOf(PropTypes.bool),
  PropTypes.arrayOf(PropTypes.string)
])
