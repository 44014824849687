import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'
import dayjs from '../../utilities/dayjs'
import { commaDeliminate } from '../../utilities/stringUtilities'
import InvoiceStatusLabel from '../invoice/InvoiceStatusLabel'

export default function ConfirmationTransactionTable ({
  invoice,
  transactions
}) {
  return (
    <table className={cn('table table-striped', styles.tableContainer)}>
      <thead>
        <tr>
          <th width='15%'>Transaction Date</th>
          <th width='15%'>Invoice Number</th>
          <th width='15%'>Transaction ID</th>
          <th width='15%'>Account</th>
          <th width='15%'>Status</th>
          <th width='15%'>Amount</th>
        </tr>
      </thead>
      <tbody>
        {transactions.map(transaction => {
          return (
            <tr key={transaction.id}>
              <td>{dayjs(transaction.transactionDate).format('MMM D, YYYY')}</td>
              <td>{invoice?.dispatcherInvoiceNumber}</td>
              <td>{transaction.id}</td>
              <td>{invoice?.client?.name}</td>
              <td><InvoiceStatusLabel>PAID</InvoiceStatusLabel></td>
              <td>${commaDeliminate(transaction.amount)}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
ConfirmationTransactionTable.propTypes = {
  invoice: PropTypes.shape({
    dispatcherInvoiceNumber: PropTypes.string.isRequired,
    client: PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  }).isRequired,
  transactions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired
  })).isRequired
}
