import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { history } from '../../../../../history'
import NormalLayoutContainer from '../../../../shared/NormalLayoutContainer'
import { PageTitle } from '../../../../../components/page-title'
import Error from '../../../../shared/Error'
import { Spinner } from '../../../../shared/Spinner'
import FormExplanation from '../../../../shared/FormExplanation'
import { action } from '../../../../../store/store'
import {
  REQUEST_CURRENT_TICKET_ACTION,
  REQUEST_RESCHEDULE_CURRENT_TICKET_ACTION
} from '../../sagas/currentTicketSaga'
import CurrentTicketRescheduleForm from './components/CurrentTicketRescheduleForm'

class HaulerTicketsReschedulePage extends Component {
  constructor (props) {
    super(props)

    this.cancelRescheduleTicket = () => {
      history.goBack()
    }

    this.handleFormSubmit = (e) => {
      e.preventDefault()
      const { values } = this.props.form
      values.id = this.props.match.params.id
      this.rescheduleCurrentTicket(this.props.match.params.id, values)
    }

    this.rescheduleCurrentTicket = (id, params) => {
      action(REQUEST_RESCHEDULE_CURRENT_TICKET_ACTION, { id, params })
    }

    this.retrieveCurrentTicket = (id) => {
      action(REQUEST_CURRENT_TICKET_ACTION, { id })
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.retrieveCurrentTicket(this.props.match.params.id)
  }

  render () {
    const { currentTicket: { errors, isFetching } } = this.props

    return (
      <div>
        <NormalLayoutContainer showBackLink>
          <div className='container-fluid'>
            <PageTitle title='Reschedule a Company Ticket'>
              <button
                className='btn btn-lg btn-link pull-right push-down-very-small'
                onClick={this.cancelRescheduleTicket}>Cancel
              </button>
            </PageTitle>

            <Spinner isFetching={isFetching}>

              <div className='row'>
                <div className='col-xs-12 col-sm-12 col-md-7 col-lg-7'>

                  <CurrentTicketRescheduleForm
                    isFetching={isFetching}
                    onSubmit={this.handleFormSubmit}
                  />

                </div>
                <div className='col-xs-12 col-sm-12 col-md-4 col-md-offset-1 col-lg-4 col-lg-offset-1'>
                  <div className='row'>
                    <FormExplanation
                      messages={[
                        'Please reschedule this ticket for your desired completion date.',
                        'This change will take place at the ticket level as well as the dispatch level.'
                      ]}
                    />
                  </div>
                </div>
              </div>
            </Spinner>
          </div>
        </NormalLayoutContainer>
        <Error errors={errors} clearErrors={() => {}} />
      </div>
    )
  }
}

HaulerTicketsReschedulePage.propTypes = {
  form: PropTypes.any,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    }).isRequired
  }).isRequired,
  currentTicket: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    errors: PropTypes.array.isRequired
  })
}

const mapStateToProps = state => ({
  form: state.form.currentTicketRescheduleForm,
  currentTicket: state.currentTicket
})

export default connect(mapStateToProps)(HaulerTicketsReschedulePage)
