import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export default function ProMappingTable ({
  title,
  options,
  handleSave,
  tableValues,
  setTableValues,
  colTitle,
  viewMore,
  setViewMore,
  showAllStandardTypes
}) {
  const indefiniteArticleTitle = title === 'Asset' ? `an ${title}` : `a ${title}`
  const disabled = tableValues.filter(val => val.changed && val.initialValue !== val.id).length === 0 ||
    tableValues.filter(val => val.error).length

  function updateTableValues (mappedValue, index) {
    const tableVals = [...tableValues]
    const indexError = tableVals.filter(val => val.mappedValue && (val.mappedValue === mappedValue)).length >= 1
    tableVals[index] = {
      ...tableVals[index],
      ...{ mappedValue, changed: true, error: indexError }
    }

    setTableValues(tableVals)

    const prevVal = tableValues[index].mappedValue
    const previousErrors = tableVals.filter(val => val.mappedValue === prevVal)

    if (previousErrors.length <= 1) {
      previousErrors.map(errVal => {
        const tableIndex = tableVals.findIndex(val => val.mappedValue === errVal.mappedValue)
        tableVals[tableIndex].error = false
        return setTableValues(tableVals)
      })
    }
  }

  return (
    <div className='bg-white shadow-md p-8 flex flex-wrap'>
      <table className='w-full'>
        <thead>
          <tr className='border-b'>
            <th className='text-2xl w-1/2 pb-2'>{colTitle}</th>
            <th className='text-2xl'>{`Your ${title} Types`}</th>
          </tr>
        </thead>
        <tbody>
          {tableValues.map((type, index) => {
            const showError = tableValues[index].error
            const lastIndex = index === tableValues.length - 1
            return (
              <tr key={index} className={classNames('even:bg-gray-lighter', {
                'border-b border-gray-light': !lastIndex
              })}>
                <td className='p-3'>
                  <div className='text-2xl'>{type.name}</div>
                  {type.description && <div>{type.description}</div>}
                </td>
                <td className='p-3'>
                  <label htmlFor={`${title} options`} className='hidden'>{`${title} options list`}</label>
                  <div className={classNames('w-1/2', { 'has-error': showError })}>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                    <select
                      name={`${title} options`}
                      className={classNames('form-control !text-2xl', {
                        '!text-red border-b border-red': showError
                      })}
                      value={type.mappedValue || ''}
                      onChange={(e) => {
                        const value = e.currentTarget.value || null
                        updateTableValues(value, index)
                      }}>
                      <option value=''>{`Select ${indefiniteArticleTitle} Type`}</option>
                      {options.map((opt, index) => {
                        return (
                          <option key={index} value={opt.id}>{opt.name}</option>
                        )
                      })}
                    </select>
                    {showError &&
                      <div className='text-red'>
                        {`${indefiniteArticleTitle} type can only be selected once`}
                      </div>
                    }
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {!showAllStandardTypes &&
        <button
          className='text-blue'
          onClick={() => setViewMore(!viewMore)}>
          <span className='text-2xl'>{`Show ${viewMore ? 'less' : 'more'} sizes`}</span>
          <i className='material-icons text-3xl relative top-2'>{ viewMore ? 'expand_less' : 'expand_more'}</i>
        </button>}
      <button
        className={classNames('uppercase bg-green hover:bg-green-10-dark py-2 px-4 rounded text-2xl font-medium shadow-md ml-auto mt-8', {
          'opacity-50': disabled
        })}
        onClick={() => {
          if (!disabled) {
            handleSave()
          }
        }}>
        <span className='relative -top-1'>Save Changes</span>
        <i className='material-icons ml-6 relative top-1'>check</i>
      </button>
    </div>
  )
}

ProMappingTable.propTypes = {
  options: PropTypes.array.isRequired,
  handleSave: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  tableValues: PropTypes.array.isRequired,
  setTableValues: PropTypes.func.isRequired,
  colTitle: PropTypes.string.isRequired,
  viewMore: PropTypes.bool,
  setViewMore: PropTypes.func,
  showAllStandardTypes: PropTypes.bool.isRequired
}
