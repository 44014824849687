import React from 'react'
import PropTypes from 'prop-types'
import ParsingView from './import/ParsingView'
import ParsingFailedView from './import/ParsingFailedView'
import ValidatedView from './import/validated-view'
import CreatingRecordsView from './import/creating-records-view'
import CompletedView from './import/completed-view'

export default function ImportDetails ({
  pluralImportName,
  details,
  onConfirm,
  disabled,
  listPath,
  validatedImportRowsTableComponent,
  completedImportRowsTableComponent
}) {
  switch (details.status) {
    case 'NEW':
    case 'PARSING':
      return <ParsingView />
    case 'PARSING_FAILED':
      return <ParsingFailedView errorMessage={details.errorMessage} returnTo={listPath} />
    case 'VALIDATED':
      return (
        <ValidatedView
          importDetails={details}
          onConfirm={onConfirm}
          disabled={disabled}
          pluralImportName={pluralImportName}
          importPath={`${listPath}?openImportModal=1`}
          validatedImportRowsTableComponent={validatedImportRowsTableComponent}
        />
      )
    case 'CREATING_RECORDS':
      return <CreatingRecordsView pluralImportName={pluralImportName} />
    case 'COMPLETED':
      return (
        <CompletedView
          importDetails={details}
          pluralImportName={pluralImportName}
          completedImportRowsTableComponent={completedImportRowsTableComponent}
          returnTo={listPath}
        />
      )
    default:
      throw new Error(`Unhandled import status ${details.status}`)
  }
}

ImportDetails.propTypes = {
  pluralImportName: PropTypes.string.isRequired,
  details: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.oneOf(['NEW', 'PARSING', 'PARSING_FAILED', 'VALIDATED', 'CREATING_RECORDS', 'COMPLETED']),
    validRowsCount: PropTypes.number.isRequired,
    invalidRowsCount: PropTypes.number.isRequired,
    recordsCreated: PropTypes.number.isRequired,
    errorMessage: PropTypes.string
  }).isRequired,
  onConfirm: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  listPath: PropTypes.string.isRequired,
  validatedImportRowsTableComponent: PropTypes.elementType.isRequired,
  completedImportRowsTableComponent: PropTypes.elementType.isRequired
}
