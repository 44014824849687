import React from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import updateClientMutation from '../../graphql/mutations/updateClient'
import notify from '../../utilities/notify'
import styles from './index.module.scss'
import clientsQuery from '../../graphql/queries/clients'
import QUERY_KEYS from '../../graphql/queryKeys'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import PageTitle from '../../components/page-title'
import { Spinner } from '../shared/Spinner'
import FormExplanation from '../shared/FormExplanation'
import ClientEditForm from '../../components/client-edit-form'
import useMutation from '../../hooks/useMutation'
import useQuery from '../../hooks/useQuery'
import { ROUTES } from '../../routes'

const emptyValues = {
  billingDetails: null,
  notes: null,
  primaryPhone: null,
  primaryPhoneExtension: null,
  firstName: null,
  lastName: null,
  email: null,
  addressline1: null,
  addressline2: null,
  city: null,
  state: null,
  zip: null
}

export default function ClientEditPage () {
  const { clientId } = useParams()
  const history = useHistory()
  const { user } = useSelector(({ user: { user } }) => ({ user }))

  const { mutate: updateClient, isUpdatingClient } = useMutation(updateClientMutation, {
    onSuccess () {
      history.push(generatePath(ROUTES.client, { clientId }))
      notify('success', 'Account Update Successfully')
    },
    onError () {
      notify('error', 'Failed to Update Account')
    }
  })

  const { data, isFetching } = useQuery([
    QUERY_KEYS.clients,
    user.haulerId,
    { column: 'name', direction: 'DESC' },
    null,
    null,
    null,
    null,
    clientId
  ],
  clientsQuery,
  {
    enabled: Boolean(user.haulerId) && Boolean(clientId),
    placeholderData: { clients: { nodes: [] } }
  })

  const handleCancelEditClient = () => {
    history.goBack()
  }

  const client = data.clients.nodes[0]

  function handleSubmit (clientFields) {
    clientFields.primaryPhoneExtension = parseInt(clientFields.primaryPhoneExtension)
    updateClient({ haulerId: user.haulerId, clientFields: { ...emptyValues, ...clientFields }, clientId })
  }

  return (
    <NormalLayoutContainer showBackLink>
      <PageTitle>
        <div className={styles.pageTitle}>
          <div>Edit Account Profile</div>
          <button
            className='dis-btn dis-btn-blank dis-btn-lg'
            onClick={handleCancelEditClient}>Cancel
          </button>
        </div>
      </PageTitle>
      <Spinner isFetching={isFetching || isUpdatingClient}>
        <div className='dis-form-page-container'>
          <div className='dis-form-container-left'>
            {data?.clients?.nodes?.length > 0 &&
              <ClientEditForm
                onSubmit={handleSubmit}
                initialValues={{
                  name: client.name,
                  notes: client.notes,
                  firstName: client.firstName,
                  lastName: client.lastName,
                  primaryPhone: client.prettyPrimaryPhone,
                  primaryPhoneExtension: client.primaryPhoneExtension,
                  email: client.email,
                  addressline1: client.addressline1,
                  addressline2: client.addressline2,
                  city: client.city,
                  state: client.state,
                  zip: client.zip,
                  billingDetails: client.billingDetails
                }}
              />
            }
          </div>
          <div className='dis-form-container-right'>
            <FormExplanation
              messages={[
                'A company account is where all customer information can be stored.',
                'You can list accounts by company names, by contact names or both.'
              ]}
            />
          </div>
        </div>
      </Spinner>
    </NormalLayoutContainer>
  )
}
