import React, { useState, useEffect } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import FixRequestContactWindow from './contact-window'
import AutocompleteGoogleMap from '../../../pages/shared/locationPickers/AutocompleteGoogleMap'
import LatLngSearchGoogleMap from '../../maps/lat-lng-map/LatLngSearchGoogleMap'
import JobSearchTableSelectComponent from '../../job-search/job-search-table-select'
import QUERY_KEYS from '../../../graphql/queryKeys'
import { useSelector } from 'react-redux'
import { jobsTableQuery } from '../../../graphql/queries/jobs'
import { prettyAddress } from '../../../utilities/locationUtilities'
import notify from '../../../utilities/notify'
import BASE_URI from '../../../utilities/BASE_URI'
import { formatPhoneNumber } from '../../../utilities/stringUtilities'
import getHeaders from '../../../utilities/getHeaders'
import styles from './fix-request.module.scss'
import useQuery from '../../../hooks/useQuery'

export default function FixRequestCreateJob ({
  request,
  clientID,
  cancelAction,
  setJobID,
  clientName = ''
}) {
  const [searchType, setSearchType] = useState('address')
  const [canSubmit, setCanSubmit] = useState(false)
  const [jobResults, setJobResults] = useState([])
  const [jobAddressDetails, setJobAddressDetails] = useState(request.addressDetails)
  const [mapProvidedAddressDetails, setMapProvidedAddressDetails] = useState({
    addressFields: {
      latitude: '',
      longitude: '',
      place_id: '',
      addressline1: request.addressline1,
      city: request.city,
      state: request.state,
      zip: request.zip,
      country: request.country
    }
  })

  const { user } = useSelector(({ user: { user } }) => ({ user }))

  const {
    refetch: refetchJobs
  } = useQuery([
    QUERY_KEYS.jobsSearch,
    user.haulerId,
    'CUSTOM_ID',
    'DESC',
    {
      streetAddress: mapProvidedAddressDetails.addressFields.addressline1,
      clientId: clientID,
      status: 'open'
    },
    {
      // On this step, we won't be using pagination under the assumption there won't be more than 10 jobs that match exactly this address
      // and have different contacts.
      // Will revisit if this is indeed ever an issue.
      pageSize: 10,
      cursor: null,
      direction: 'after'
    }
  ],
  jobsTableQuery,
  {
    enabled: false,
    onError () {
      // what do we want it to do when an error? Stop them all around from moving on?
      notify('error', 'Unable to retrieve jobs')
    },
    keepPreviousData: true,
    onSuccess: ({ jobs }) => {
      const { nodes } = jobs
      setJobResults(nodes)
      if (!nodes.length) {
        postNewJob()
          .then(({ data }) => {
            setJobID(data.job.id)
          })
          .catch(e => {
            console.error(e)
          })
      }
    }
  })

  useEffect(() => {
    setCanSubmit(mapProvidedAddressDetails.addressFields.latitude !== null && mapProvidedAddressDetails.addressFields.latitude !== '')
  }, [mapProvidedAddressDetails.addressFields.latitude])

  function postNewJob () {
    return axios.post(
      `${BASE_URI}/hauler/jobs`,
      buildJobCreationRequest(),
      getHeaders()
    )
  }

  function buildJobCreationRequest () {
    const { addressFields } = mapProvidedAddressDetails
    const [ticket] = request.tickets
    return {
      pretty_primary_phone: formatPhoneNumber(ticket.primaryPhone) || '',
      email: ticket.email || '',
      first_name: ticket.firstName || '',
      last_name: ticket.lastName || '',
      name: request.accountName,
      notes: ticket.notes || '',
      latitude: addressFields.latitude,
      longitude: addressFields.longitude,
      place_id: addressFields.place_id,
      addressline1: addressFields.addressline1,
      city: addressFields.city,
      state: addressFields.state,
      zip: addressFields.zip,
      country: addressFields.country,
      address_details: jobAddressDetails,
      client_id: clientID,
      primary_phone: ticket.primaryPhone || ''
    }
  }

  function onLocationChange (deets) {
    setMapProvidedAddressDetails({
      addressFields: {
        latitude: deets.latitude,
        longitude: deets.longitude,
        place_id: deets.place_id,
        addressline1: deets.addressline1,
        city: deets.city,
        state: deets.state,
        zip: deets.zip,
        country: deets.country
      }
    })
  }

  function submitForm (e) {
    e.preventDefault()
    setJobAddressDetails()
    refetchJobs()
  }

  function handleAddressDetails (e) {
    setJobAddressDetails(e.target.value)
  }

  function handleLocationChange (e) {
    setMapProvidedAddressDetails({
      addressFields: {
        latitude: '',
        longitude: '',
        place_id: '',
        addressline1: '',
        city: '',
        state: '',
        zip: '',
        country: ''
      }
    })
    setSearchType(e.target.value)
  }

  if (jobResults.length) {
    return (
      <div className={styles['fix-request']}>
        <h3>Job Already Exists With This Address</h3>
        <p>
          There is already a job(s) in {clientName} with this address. Would you like to add confirmed ticket(s) to an existing job or still
          create a new job?
        </p>
        <JobSearchTableSelectComponent
          searchResults = {jobResults}
          cancelAction = {cancelAction}
          setJobID = {setJobID}
        />
        <button className={`dis-btn dis-btn-link ${styles['center-footer-btn']}`} onClick={postNewJob}>Continue With New Job</button>
      </div>
    )
  }

  const { addressFields } = mapProvidedAddressDetails

  return (
    <form onSubmit={submitForm} className={styles['fix-request']}>
      <h3>Create a Job to Use for New Request</h3>
      <FixRequestContactWindow
        request={request}
      />
      <div className={styles['location-selector']}>
        <label>
          <input checked={searchType === 'address'} type='radio' name='location-type' value='address' onChange={handleLocationChange} />Address
        </label>
        <label>
          <input checked={searchType === 'custom'} type='radio' name='location-type' value='custom' onChange={handleLocationChange} />Custom Location
        </label>
      </div>
      {searchType === 'address'
        ? (
          <AutocompleteGoogleMap
            place_id={addressFields.place_id}
            latitude={parseFloat(addressFields.latitude)}
            longitude={parseFloat(addressFields.longitude)}
            initialValue={prettyAddress(addressFields)}
            mapClass='static-google-map-jobs'
            placeholder='Address for this Job'
            externalInput={true}
            onLocationChange={onLocationChange}
          />
          )
        : (
          <LatLngSearchGoogleMap
            mapClass='static-google-map-jobs'
            mapId='newJobFormMapLatLng'
            latitude={parseFloat(addressFields.latitude)}
            longitude={parseFloat(addressFields.longitude)}
            address={prettyAddress(addressFields)}
            onLocationChange={onLocationChange}
          />
          )}
      <label className={`${styles['address-details-label']}`}>
        Address Details (suite number, etc.)
        <input defaultValue={jobAddressDetails} onBlur={handleAddressDetails} className='form-control' type='text'
          autoComplete='off' maxLength='30' name='address_details'
        />
      </label>
      <div className={styles['button-wrapper']}>
        <button className='dis-btn dis-btn-sm dis-btn-primary' disabled={!canSubmit} type='submit'>
          Create New Job <i className='material-icons dis-btn-icon'>add_circle</i>
        </button>
        <button className='dis-btn dis-btn-sm dis-btn-blank dis-btn-blank-dk-bg' onClick={cancelAction}>
          CANCEL
        </button>
      </div>
    </form>
  )
}

FixRequestCreateJob.propTypes = {
  clientID: PropTypes.number,
  request: PropTypes.object.isRequired,
  cancelAction: PropTypes.func.isRequired,
  setJobID: PropTypes.func.isRequired,
  clientName: PropTypes.string
}
