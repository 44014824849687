import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, generatePath } from 'react-router-dom'
import PropTypes from 'prop-types'
import QUERY_KEYS from '../../graphql/queryKeys'
import exportClientToWastepayMutation from '../../graphql/mutations/exportClientToWastepay.js'
import associateFeeTypesToQuickbooks from '../../graphql/mutations/associateFeeTypesToQuickbooks.js'
import exportClientToQboWastepay from '../../graphql/mutations/exportClientToQboWastepay.js'
import quickbooksFeeTypesQuery from '../../graphql/queries/quickbooksFeeTypes'
import { Spinner } from '../../pages/shared/Spinner'
import ExportFeeTypesToQBO from '../../components/fee-type/ExportFeeTypesToQBO'
import SelectWPCustomerModal from '../../components/select-wp-customer-modal'
import AccountVerifyErrorDisplay from '../../components/invoice/AccountVerifyErrorDisplay'
import notify from '../../utilities/notify'
import { useSelector } from 'react-redux'
import { ROUTES } from '../../routes.js'
import styles from './index.module.scss'
import useMutation from '../../hooks/useMutation'
import useQuery, { generateQueryKey } from '../../hooks/useQuery'
import { useQueryClient } from 'react-query'
export default function QboWastepayExport ({
  invoice,
  invoiceQueryKey,
  actionsRequired,
  isWastepayConnected
}) {
  const history = useHistory()
  const queryClient = useQueryClient()
  const [mismatchedDescriptions, setMismatchedDescriptions] = useState(null)
  const [descriptionCheckIndex, setDescriptionCheckIndex] = useState(0)
  const [formValues, setFormValues] = useState(null)
  const [showSelectCustomerModal, setShowSelectCustomerModal] = useState(false)
  const [wastepayCustomers, setWastepayCustomers] = useState([])

  const { user, hauler } = useSelector(({ user }) => ({ user: user.user, hauler: user.hauler }))

  const { mutate: exportClientToWastePay, isLoading: isExportingClientToWastePay } = useMutation(exportClientToWastepayMutation, {
    onSuccess (data) {
      const { client, wastepayCustomers } = data
      if (wastepayCustomers != null) {
        return setWastepayCustomers(wastepayCustomers)
      }
      setWastepayCustomers([])
      notify('success', `Account ${invoice?.client?.name || ''} was exported to selected systems.`)
      queryClient.setQueryData(generateQueryKey(invoiceQueryKey, user.id), (oldData) => ({
        ...oldData,
        invoice: {
          ...oldData.invoice,
          client: {
            ...oldData.invoice.client,
            wastepayCustomerId: client.wastepayCustomerId
          }
        }
      }))
      history.push(`${generatePath(ROUTES.invoicePayment, { id: invoice.id })}?exportAttempted=1`)
    },
    onError () {
      notify('error', 'There was a problem exporting this account')
    }
  })
  const { mutate: exportClient, isLoading: exportClientLoading } = useMutation(exportClientToQboWastepay, {
    onSuccess (data) {
      const wastepaySet = data.exportClientToWastepay?.wastepayCustomers === null
      let wastepayCustomerID
      let existsInQbo
      queryClient.setQueryData(generateQueryKey(invoiceQueryKey, user.id), (oldData) => {
        wastepayCustomerID = wastepaySet && isWastepayConnected && actionsRequired?.wpClientExportNeeded
          ? data?.exportClientToWastepay?.client?.wastepayCustomerId
          : oldData?.invoice?.client?.wastepayCustomerId
        existsInQbo = actionsRequired?.qboClientExportNeeded
          ? Boolean(data?.exportClientToQuickbooks?.client?.qboCustomerId)
          : oldData?.invoice?.client?.existsInQbo
        return ({
          ...oldData,
          invoice: {
            ...oldData.invoice,
            client: {
              ...oldData.invoice.client,
              existsInQbo,
              wastepayCustomerId: wastepayCustomerID,
              didPromptClientExportSelection: data?.updateClient?.client?.didPromptClientExportSelection
            }
          }
        })
      })

      if (!wastepaySet && isWastepayConnected && actionsRequired?.wpClientExportNeeded) {
        setWastepayCustomers(data.exportClientToWastepay?.wastepayCustomers ?? [])
        return
      }
      notify('success', `Account ${invoice?.client?.name || ''} was exported to selected systems.`)

      moveAlong(existsInQbo, wastepayCustomerID)
    },
    onError () {
      notify('error', 'There was a problem exporting this account')
    }
  })
  const { mutate: associateFeeTypes, isLoading: associateFeeTypesLoading } = useMutation(associateFeeTypesToQuickbooks)
  const ticketIds = useMemo(() => invoice?.tickets?.map(ticket => ticket.id), [invoice?.tickets])

  const exportClientToQBO = useCallback(function exportClientToQBO ({ exportToQuickBooks, exportToWastepay, dontAskAgain }) {
    exportClient({
      id: invoice?.client.id,
      haulerId: hauler.id,
      exportToQuickBooks,
      exportToWastepay,
      dontAskAgain
    })
  }, [exportClient, invoice, hauler.id])

  const { data: qboFeeTypes, isFetching: qboFeeTypesFetching } = useQuery([
    QUERY_KEYS.quickbooksFeeTypes,
    user.haulerId
  ],
  quickbooksFeeTypesQuery,
  {
    enabled: Boolean(hauler.quickbooks.isConnected),
    onError: () => notify('error', 'Unable to fetch Quickbooks fees list'),
    placeholderData: { quickbooksFeeTypes: [] }
  })

  const unexportedFeeTypes = useMemo(() => {
    return invoice?.tickets?.reduce((accumulator, ticket) => {
      accumulator = [...accumulator, ...ticket.ticketFees.filter(feeType => feeType.qboItemId === null)]
      return accumulator
    }, [])
  }, [invoice])

  const moveAlong = useCallback((existsInQbo = null, wastepayCustomerID = null) => {
    const hasWP = wastepayCustomerID ? true : Boolean(invoice?.client?.wastepayCustomerId)
    const hasQBO = existsInQbo !== null ? existsInQbo : invoice?.client?.existsInQbo
    const invoiceID = invoice?.id

    if (hasQBO && actionsRequired.feeTypesExportNeeded) {
      return
    }

    if (hasWP) {
      history.push(`${generatePath(ROUTES.invoicePayment, { id: invoiceID })}?exportAttempted=1`)
    } else {
      history.push(`${generatePath(ROUTES.invoice, { id: invoiceID })}?exportAttempted=1`)
    }
  }, [invoice, history, actionsRequired])

  const handleFeeTypeExport = useCallback((feeTypes) => {
    let newFeeTypes = []
    let existingFeeTypes = []
    Object.keys(feeTypes).forEach(feeTypeId => {
      if (feeTypes[feeTypeId].action === 'new') {
        newFeeTypes = [...newFeeTypes, feeTypeId]
      }
      if (feeTypes[feeTypeId].action === 'existing') {
        existingFeeTypes = [
          ...existingFeeTypes,
          { feeTypeId, quickbooksId: feeTypes[feeTypeId].existingId, mismatchedDescriptionAction: feeTypes[feeTypeId].mismatchedDescriptionAction }
        ]
      }
    })

    associateFeeTypes({ input: { haulerId: user.haulerId, newFeeTypes, existingFeeTypes, ticketIds } }, {
      onSuccess: ({ associateFeeTypesToQuickbooks }) => {
        const updatedDescriptions = associateFeeTypesToQuickbooks?.mismatchedDescriptions.map((desc) => {
          desc.mismatchedDescriptionAction = null
          return desc
        })
        setMismatchedDescriptions(updatedDescriptions)
        if (!updatedDescriptions.length) {
          notify('success', 'Fee Types were exported to QuickBooks')
          queryClient.setQueryData(generateQueryKey(invoiceQueryKey, user.id), (oldData) => ({
            ...oldData,
            invoice: { ...oldData.invoice, tickets: [...associateFeeTypesToQuickbooks.tickets] }
          }))
        }
      },
      onError () {
        notify('error', 'Failed to export fee types to QuickBooks')
      }
    })
  }, [associateFeeTypes, user, ticketIds, invoiceQueryKey, queryClient])

  useEffect(function toggleCustomerSelectionModal () {
    setShowSelectCustomerModal(wastepayCustomers.length !== 0)
  }, [wastepayCustomers, setShowSelectCustomerModal])

  useEffect(function autoExportClientToQBO () {
    if (
      !hauler.quickbooks.isConnected ||
      !hauler.quickbooks.shouldAutoImportCustomers ||
      invoice?.client.existsInQbo === true
    ) return

    exportClientToQBO()
  }, [hauler.quickbooks.shouldAutoImportCustomers, hauler.quickbooks.isConnected, exportClientToQBO, invoice])

  useEffect(function autoExportQuickbooksFeeTypes () {
    if (!hauler.quickbooks.isConnected) {
      return
    }
    if (!hauler.quickbooks.shouldAutoImportFeeTypes) return
    if (unexportedFeeTypes.length === 0) return

    const feeTypes = unexportedFeeTypes.reduce((accumulator, feeType) => {
      accumulator[feeType.feeTypeId] = { action: 'new' }
      return accumulator
    }, {})

    handleFeeTypeExport(feeTypes)
  }, [hauler.quickbooks.isConnected, hauler.quickbooks.shouldAutoImportFeeTypes, history, unexportedFeeTypes, handleFeeTypeExport])

  function handleExportToWP (clientId, { wpCustomerId = null, forceCreate = null } = {}) {
    const exportArguments = {
      id: clientId
    }
    if (wpCustomerId != null) {
      exportArguments.customerId = wpCustomerId
    }
    if (forceCreate != null) {
      exportArguments.forceCreate = true
    }
    exportClientToWastePay(exportArguments)
  }

  function closeDescriptionsModal () {
    setMismatchedDescriptions(null)
    setDescriptionCheckIndex(0)
  }

  function handleMismatchedFeeTypesContinue (e) {
    e.preventDefault()
    if (descriptionCheckIndex !== mismatchedDescriptions.length - 1) {
      setDescriptionCheckIndex(descriptionCheckIndex + 1)
    } else {
      Object.keys(formValues).forEach(feeTypeId => {
        const description = mismatchedDescriptions.find(desc => desc.feeTypeId.toString() === feeTypeId.toString())
        formValues[feeTypeId].mismatchedDescriptionAction = description?.mismatchedDescriptionAction ? description.mismatchedDescriptionAction : null
      })
      setMismatchedDescriptions(null)
      handleFeeTypeExport(formValues)
    }
  }

  const manualExportNeededOrMoveAlong = useMemo(() => {
    if (actionsRequired?.feeTypesExportNeeded && invoice.client.existsInQbo) {
      return false
    }

    if (invoice?.client.didPromptClientExportSelection) {
      moveAlong()
      return false
    }

    if ((!hauler.quickbooks.shouldAutoImportCustomers && actionsRequired?.qboClientExportNeeded) || actionsRequired?.wpClientExportNeeded) {
      return true
    } else {
      moveAlong()
      return false
    }
  }, [actionsRequired, hauler, invoice, moveAlong])

  return (
    <>
      <SelectWPCustomerModal
        closeModal={() => setWastepayCustomers([])}
        isOpen={showSelectCustomerModal}
        wastepayCustomers= {wastepayCustomers}
        handleSubmit={handleExportToWP}
        submitting={isExportingClientToWastePay}
        clientId = {invoice.client.id}
        resetCustomers={() => setWastepayCustomers([])}
      />
      <Spinner isFetching={qboFeeTypesFetching || exportClientLoading}>
        {
          manualExportNeededOrMoveAlong
            ? (
              <AccountVerifyErrorDisplay
                className={styles.accountVerifyError}
                client={invoice?.client}
                onExport={exportClientToQBO}
                qboClientExportNeeded={actionsRequired?.qboClientExportNeeded}
                wpClientExportNeeded={actionsRequired?.wpClientExportNeeded}
              />)
            : (actionsRequired?.feeTypesExportNeeded &&
              (
                <ExportFeeTypesToQBO
                  onSubmit={handleFeeTypeExport}
                  qboFeeTypes={qboFeeTypes?.quickbooksFeeTypes}
                  unexportedFeeTypes={unexportedFeeTypes}
                  values={formValues}
                  setValues={setFormValues}
                  closeDescriptionsModal={closeDescriptionsModal}
                  handleContinue={handleMismatchedFeeTypesContinue}
                  descriptionCheckIndex={descriptionCheckIndex}
                  mismatchedDescriptions={mismatchedDescriptions}
                  setMismatchedDescriptions={setMismatchedDescriptions}
                  isFetching={associateFeeTypesLoading}
                />)
              )
        }
      </Spinner>
    </>
  )
}

QboWastepayExport.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    client: PropTypes.object.isRequired,
    tickets: PropTypes.array.isRequired
  }).isRequired,
  invoiceQueryKey: PropTypes.array.isRequired,
  actionsRequired: PropTypes.shape({
    qboClientExportNeeded: PropTypes.bool.isRequired,
    feeTypesExportNeeded: PropTypes.bool.isRequired,
    wpClientExportNeeded: PropTypes.bool.isRequired
  }).isRequired,
  isWastepayConnected: PropTypes.bool.isRequired
}
