import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import styles from './index.module.scss'
import cn from 'classnames'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import PageTitle from '../../components/page-title'
import HaulerProfileDisplay from '../../components/hauler-profile/profile'
import HaulerRequiredActionsDisplay from '../../components/hauler-profile/required-actions'
import HaulerQuickbooksDisplay from '../../components/hauler-profile/quickbooks'
import InvoiceSettingsDisplay from '../../components/hauler-profile/InvoiceSettingsDisplay'
import HaulerDriverAssignmentSettings from '../../components/hauler-profile/HaulerDriverAssignmentSettings'
import HaulerSMS from '../../components/hauler-profile/HaulerSMS'
import { useDispatch, useSelector } from 'react-redux'
import useQuery from '../../hooks/useQuery'
import QUERY_KEYS from '../../graphql/queryKeys'
import haulerQuery from '../../graphql/queries/hauler'
import { Spinner } from '../shared/Spinner'
import USER_ACTIONS from '../../store/user/userActions'

export default function HaulerProfilePage () {
  const dispatch = useDispatch()
  const { hauler, accessToken } = useSelector(({ user: { hauler, accessToken } }) => ({ hauler, accessToken }))
  const { data: freshHaulerData, isFetching } = useQuery(
    [QUERY_KEYS.hauler],
    haulerQuery
  )

  useEffect(function onHaulerDataChange () {
    if (!freshHaulerData) return
    dispatch({ type: USER_ACTIONS.UPDATE_HAULER, payload: { hauler: freshHaulerData } })
  }, [dispatch, freshHaulerData])

  return (
    <NormalLayoutContainer>
      {
        isFetching
          ? <Spinner isFetching />
          : (<div className={styles.haulerProfileContainer}>
            <PageTitle>
              <div className={styles.header}>
                <div>Company Settings</div>
                <Link
                  className={cn(styles.editBtn, 'dis-btn dis-btn-lg dis-btn-primary')}
                  to='/hauler/edit'>
                  Edit Profile
                  <i className='material-icons dis-btn-icon'>mode_edit</i>
                </Link>
              </div>
            </PageTitle>
            <HaulerProfileDisplay hauler={hauler} />
            {hauler.requiredActionsAllowed && (<>
              <h2 className={styles.secondaryHeader}>Mobile App Settings</h2>
              <HaulerRequiredActionsDisplay hauler={hauler} />
            </>)}
            <h2 className={styles.secondaryHeader}>Web App Settings</h2>
            <HaulerDriverAssignmentSettings />
            <HaulerSMS />
            <h2 className={styles.secondaryHeader}>Invoice and Billing Settings</h2>
            <InvoiceSettingsDisplay hauler={hauler} accessToken={accessToken} />
            <HaulerQuickbooksDisplay />
          </div>)
      }
    </NormalLayoutContainer>
  )
}
