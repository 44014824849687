import React from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import useQuery from '../../../hooks/useQuery'
import QUERY_KEYS from '../../../graphql/queryKeys'
import resourceImportRowsQuery from '../../../graphql/queries/resourceImportRows'
import { captureErrorAndNotify } from '../../../utilities/errorHandlers'
import { Spinner } from '../../../pages/shared/Spinner'

export default function ResourceErrorRows ({ importId, pluralImportName }) {
  const { data: resourceImportRowsData, isLoading } = useQuery(
    [QUERY_KEYS.resourceImportRows, {
      resourceImportId: importId,
      status: 'IMPORT_ERROR',
      first: 10
    }],
    resourceImportRowsQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Failed to retrieve data')
      },
      // We don't usually consider data to not be stale, BUT in this scenario this query gets loaded from a few components within a short time span
      // AND this data never changes. Once an import starts, its rows do not change.
      staleTime: 5 * 1000 * 60
    })

  if (isLoading) {
    return <Spinner isFetching />
  }

  return (
    <div className='my-8'>
      {resourceImportRowsData.resourceImportRows.nodes.map((row) => (
        <div className='flex justify-start items-center p-2' key={row.id} style={{ backgroundColor: '#ffe3e7' }}>
          <i className='material-icons text-red opacity-80 text-3xl mr-4'>info</i>
          <span className='font-medium'>
            <span className={styles.capitalize}>{pluralImportName}</span> {row.uid} failed to be created.&nbsp;
            {row.importError}
          </span>
        </div>
      ))}
    </div>
  )
}

ResourceErrorRows.propTypes = {
  importId: PropTypes.string.isRequired,
  pluralImportName: PropTypes.string.isRequired
}
