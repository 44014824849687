import React from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import classNames from 'classnames'

export default function CursorPagination ({ pageInfo, onPageRequest, totalCount, fakeCount, isSiteJobResourceDisplay }) {
  return (
    <div className={classNames(styles.pagination, { [styles.hidden]: !pageInfo, [styles.siteJobResourceDisplay]: isSiteJobResourceDisplay })}>
      <button
        className={classNames(styles.button, {
          [`${styles.enabledButton}`]: pageInfo?.hasPreviousPage,
          [`${styles.disabledButton}`]: !pageInfo?.hasPreviousPage
        })}
        type='button'
        disabled={!pageInfo?.hasPreviousPage}
        onClick={() => onPageRequest('before', pageInfo.startCursor)}>
        &larr; Previous
      </button>

      <div className={styles.results}>
        {fakeCount && <>Displaying Results {fakeCount.start} to {fakeCount.end}</>}
        <strong>
          Total Results: {totalCount}
        </strong>
      </div>

      <button
        className={classNames(styles.button, {
          [`${styles.enabledButton}`]: pageInfo?.hasNextPage,
          [`${styles.disabledButton}`]: !pageInfo?.hasNextPage
        })}
        type='button'
        disabled={!pageInfo?.hasNextPage}
        onClick={() => onPageRequest('after', pageInfo.endCursor)}>
        Next &rarr;
      </button>
    </div>
  )
}

CursorPagination.propTypes = {
  onPageRequest: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  pageInfo: PropTypes.shape({
    endCursor: PropTypes.string,
    hasNextPage: PropTypes.bool.isRequired,
    hasPreviousPage: PropTypes.bool.isRequired,
    startCursor: PropTypes.string
  }).isRequired,
  // We want to show fake page numbers to emulate page based pagination to make our users feel good, but we are using
  // cursor based pagination and faking page based pagination only works in certain situations.
  fakeCount: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired
  }),
  isSiteJobResourceDisplay: PropTypes.bool
}
