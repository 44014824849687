import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, Link } from 'react-router-dom'
import { ROUTES } from '../../../routes'

export default function PaidStatus ({ invoice }) {
  return (
    <ul className='dropdown-menu'>
      <li>
        {invoice.qboSync === 'Synced'
          ? (<a href={invoice.qboUrl} target='_blank noopener noreferrer'>
            <span>View in QBO</span>
            <i className='material-icons'>forward</i>
          </a>)
          : (<Link to={generatePath(ROUTES.invoicesExport, { id: invoice?.id })}>
            <span>Export to QBO</span>
            <i className='material-icons'>forward</i>
          </Link>)}
      </li>
    </ul>
  )
}
PaidStatus.propTypes = {
  invoice: PropTypes.shape({
    qboUrl: PropTypes.string,
    qboSync: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }).isRequired
}
