import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SignUpStepOne from '../components/sign-up/SignUpStepOne'
import SignUpStepTwo from '../components/sign-up/SignUpStepTwo'
import SignUpStepThree from '../components/sign-up/SignUpStepThree'
import Logo from '../components/Logo'
import signUpMutation from '../graphql/mutations/signUp'
import jwtDecode from 'jwt-decode'
import USER_ACTIONS from '../store/user/userActions'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../routes'
import useMutation from '../hooks/useMutation'
import { captureErrorAndNotify } from '../utilities/errorHandlers'
import { onlyNumbers } from '../utilities/stringUtilities'

const minStep = 1
const maxStep = 3

export default function SignUpPage () {
  const dispatch = useDispatch()
  const history = useHistory()
  const [step, setStep] = useState(minStep)
  const [signUpData, setSignUpData] = useState({})

  const { mutateAsync: signUp } = useMutation(signUpMutation, {
    onSuccess ({ accessToken, refreshToken }) {
      window.localStorage.setItem('auth', JSON.stringify({ access_token: accessToken, refresh_token: refreshToken }))
      const decodedJwt = jwtDecode(accessToken)
      dispatch({
        type: USER_ACTIONS.SET_JWT,
        payload: {
          jwt: decodedJwt,
          accessToken,
          refreshToken
        }
      })
      history.push(ROUTES.userProfile)
    },
    onError (error) {
      captureErrorAndNotify(error, 'Failed to sign up')
    }
  })

  function handleNext (newSignUpData) {
    if (step >= maxStep) return
    setSignUpData(currentSignUpData => ({ ...currentSignUpData, ...newSignUpData }))
    setStep(currentStep => currentStep + 1)
  }

  function handlePrev (newSignUpData) {
    if (step <= minStep) return
    setSignUpData(currentSignUpData => ({ ...currentSignUpData, ...newSignUpData }))
    setStep(currentStep => currentStep - 1)
  }

  function handleSubmit (newSignUpData) {
    const finalData = { ...signUpData, ...newSignUpData }
    return signUp({
      haulerAttributes: {
        name: finalData.name,
        primaryPhone: `+1${onlyNumbers(finalData.companyPrimaryPhone)}`,
        latitude: finalData.latitude,
        longitude: finalData.longitude,
        placeId: finalData.placeId,
        addressline1: finalData.addressline1,
        city: finalData.city,
        state: finalData.state,
        zip: finalData.zip,
        country: finalData.country
      },
      userAttributes: {
        firstName: finalData.firstName,
        lastName: finalData.lastName,
        email: finalData.email,
        primaryPhone: `+1${onlyNumbers(finalData.primaryPhone)}`,
        password: finalData.password,
        passwordConfirmation: finalData.passwordConfirmation
      }
    })
  }

  return (
    <div className='container-fluid center__all--large'>
      <div className='row'>
        <div className='col-xs-12 col-sm-12 col-md-8 col-md-offset-2 col-lg-8 col-lg-offset-2'>
          <div className='mt-8'>
            <Logo />
          </div>
          <div className='row container-fluid push-down-small'>
            <SignUpStep step={step} onNext={handleNext} onPrev={handlePrev} signUpData={signUpData} onSubmit={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  )
}

SignUpPage.propTypes = {}

function SignUpStep ({ step, onNext, onPrev, signUpData, onSubmit }) {
  switch (step) {
    case 1:
      return <SignUpStepOne onNext={onNext} signUpData={signUpData} />
    case 2:
      return <SignUpStepTwo onNext={onNext} onPrev={onPrev} signUpData={signUpData} />
    case 3:
      return <SignUpStepThree onSubmit={onSubmit} onPrev={onPrev} signUpData={signUpData} />
    default:
      return null
  }
}

SignUpStep.propTypes = {
  step: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  signUpData: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
}
