import { PrimaryPhoneValidator } from '../../utilities/commonValidators'

export const validate = (values) => {
  const errors = {}

  if (!values.sendAsName) {
    errors.sendAsName = 'Required'
  }

  if (!values.companyPhone) {
    errors.companyPhone = 'Required'
  } else if (!PrimaryPhoneValidator(values.companyPhone)) {
    errors.companyPhone = 'Invalid Phone Number'
  }

  if (!values.companyName) {
    errors.companyName = 'Required'
  }

  if (!values.companyAddress) {
    errors.companyAddress = 'Required'
  }

  if (!values.paymentEmailSubject) {
    errors.paymentEmailSubject = 'Required'
  }

  if (!values.refundEmailSubject) {
    errors.refundEmailSubject = 'Required'
  }

  if (!values.invoiceEmailSubject) {
    errors.invoiceEmailSubject = 'Required'
  }

  return errors
}
