import React from 'react'
import PropTypes from 'prop-types'
import ResourceType from './ResourceType'

export default function ResourceTypesList ({ resourceTypes }) {
  return (
    <div className='row panel panel-default'>
      {resourceTypes.length === 0 && (<h4 className='text-center'>No Asset Types</h4>)}
      {resourceTypes.map(resourceType => (
        <ResourceType key={resourceType.id} resourceType={resourceType} />
      ))}
    </div>
  )
}

ResourceTypesList.propTypes = {
  resourceTypes: PropTypes.array.isRequired
}
