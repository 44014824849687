import {
  RECEIVE_ALL_CLIENTS_FAILURE,
  RECEIVE_ALL_CLIENTS_SUCCESS,
  REQUEST_ALL_CLIENTS_ACTION
} from '../sagas/allClientsSaga'

export const initialState = {
  clients: [],
  errors: [],
  isFetching: false
}

export default function allClientsReducer (reduxState = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case REQUEST_ALL_CLIENTS_ACTION:
      return {
        ...reduxState,
        isFetching: true,
        errors: []
      }
    case RECEIVE_ALL_CLIENTS_FAILURE:
      return {
        ...reduxState,
        isFetching: false,
        errors: payload.errors
      }
    case RECEIVE_ALL_CLIENTS_SUCCESS:
      return {
        ...reduxState,
        isFetching: false,
        clients: payload.clients
      }
    default:
      return reduxState
  }
}
