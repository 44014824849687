import { loader } from 'graphql.macro'

export default async function addAssetToSite ({ dataLoaders, variables }) {
  const schema = loader('./addAssetToSite.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      assetId: variables.assetId,
      siteId: variables.siteId
    }
  })
  return response.addAssetToSite
}
