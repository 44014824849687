import React from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'

export default function CreatingRecordsView ({ pluralImportName }) {
  return (
    <div className='text-center my-20'>
      <i className='material-icons text-8xl text-gray-med-dark opacity-80 animate-spin'>autorenew</i>
      <h4 className='text-center mt-10 mb-32'>Creating <span className={styles.capitalize}>{pluralImportName}</span>...</h4>
    </div>
  )
}

CreatingRecordsView.propTypes = {
  pluralImportName: PropTypes.string.isRequired
}
