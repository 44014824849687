import React, { useState, useMemo } from 'react'
import { generatePath, Link, useLocation, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'
import { BooleanParam, useQueryParams } from 'use-query-params'
import cn from 'classnames'
import { ROUTES } from '../../routes'
import useMutation from '../../hooks/useMutation'
import useQuery, { generateQueryKey } from '../../hooks/useQuery'
import exportInvoiceToQbo from '../../graphql/mutations/exportInvoiceToQbo'
import invoiceQuery from '../../graphql/queries/invoice'
import QUERY_KEYS from '../../graphql/queryKeys'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import { Spinner } from '../shared/Spinner'
import styles from './index.module.scss'
import PageTitle from '../../components/page-title'
import InvoicePreview from '../../components/invoice-preview'
import QboWastepayExport from '../../components/qbo-wastepay-export'
import QboExportInvoiceSuccess from '../../components/qbo-wastepay-export/QBOExportInvoiceSuccess'
import { captureErrorAndNotify, handleQBOInvoiceExportError } from '../../utilities/errorHandlers'
import notify from '../../utilities/notify'
import exportAllPaymentsToQuickbooks from '../../graphql/mutations/exportAllPaymentsToQuickbooks'
import { formatPaymentsForQBOExportConfirmation } from '../../utilities/invoiceUtilities'
import PropTypes from 'prop-types'

export default function QboExportInvoiceConfirm () {
  const { id } = useParams()
  const location = useLocation()
  const multiTicket = location?.state?.multiTicket
  const { hauler, user } = useSelector(({ user }) => ({ hauler: user.hauler, user: user.user }))
  const [queryParams] = useQueryParams({
    exportAttempted: BooleanParam
  })
  const [showConfirmationPage, setShowConfirmationPage] = useState(null)
  const queryClient = useQueryClient()

  const { data: { invoice } = {}, isFetching } = useQuery([QUERY_KEYS.invoice, id], invoiceQuery, {
    onError (error) {
      captureErrorAndNotify(error, 'Error fetching invoice')
    },
    placeholderData: { invoice: {} }
  })

  const hasInvoiceTickets = invoice?.tickets?.length > 0

  const actionsRequired = useMemo(() => {
    if (!invoice?.id) return
    const unexportedFeeTypes = invoice?.tickets?.reduce((accumulator, ticket) => {
      accumulator = [...accumulator, ...ticket.ticketFees.filter(feeType => feeType.qboItemId === null)]
      return accumulator
    }, [])

    return {
      qboClientExportNeeded: hauler.quickbooks.isConnected && !invoice.client.existsInQbo,
      feeTypesExportNeeded: hauler.quickbooks.isConnected && unexportedFeeTypes?.length > 0,
      wpClientExportNeeded: hauler.isWastepayConnected && !invoice.client.wastepayCustomerId
    }
  }, [invoice, hauler])

  const { mutate: exportToQBO, isLoading: exportToQBOLoading } = useMutation(exportInvoiceToQbo, {
    onSuccess ({ invoice }) {
      setShowConfirmationPage(true)
      queryClient.setQueryData(generateQueryKey([QUERY_KEYS.invoice, id], user.id), (oldData) => ({
        ...(oldData ?? {}),
        invoice: {
          ...(oldData?.invoice ?? {}),
          qboUrl: invoice?.qboUrl,
          qboInvoiceExportStatus: invoice?.qboInvoiceExportStatus,
          qboPaymentExportStatus: invoice?.qboPaymentExportStatus,
          qboPaymentUrl: invoice?.qboPaymentUrl,
          wastepaySales: invoice?.wastepaySales,
          dispatcherPayments: invoice?.dispatcherPayments
        }
      }))
    },
    onError: handleQBOInvoiceExportError
  })

  function exportInvoice () {
    exportToQBO({ id })
  }

  const { mutate: retryPaymentsExport } = useMutation(exportAllPaymentsToQuickbooks, {
    onSuccess ({ invoice: newInvoiceData }) {
      queryClient.setQueryData(generateQueryKey([QUERY_KEYS.invoicePaymentDetails, id], user.id), (oldData) => ({
        ...oldData,
        invoice: { ...oldData.invoice, ...{ dispatcherPayments: newInvoiceData.dispatcherPayments }, ...{ wastepaySales: newInvoiceData.wastepaySales } }
      }))
      notify('success', 'Payments export successful')
    },
    onError (error) {
      captureErrorAndNotify(error, 'Error exporting payments to Quickbooks')
    }
  })

  function handleRetryPaymentExport (data) {
    return retryPaymentsExport({ id })
  }

  return (
    <NormalLayoutContainer showBackLink>
      {isFetching || exportToQBOLoading
        ? <Spinner isFetching />
        : (
          <>
            <PageTitle>
              <div className='dis-page-title-flex'>
                <div>{multiTicket
                  ? `Invoice Summary for ${invoice?.client?.name}`
                  : `Export Invoice #${invoice?.dispatcherInvoiceNumber} to Quickbooks Online`}</div>
                {!showConfirmationPage && !actionsRequired?.qboClientExportNeeded && !actionsRequired?.feeTypesExportNeeded && <button
                  className={cn('dis-btn dis-btn-primary dis-btn-lg', styles.exportBtn, {
                    'dis-disabled-link': !hasInvoiceTickets
                  })}
                  type='button'
                  onClick={exportInvoice}>
                  Export to QBO
                </button>}
              </div>
            </PageTitle>
            {
              !queryParams.exportAttempted && (actionsRequired?.qboClientExportNeeded || actionsRequired?.wpClientExportNeeded || actionsRequired?.feeTypesExportNeeded)
                ? (
                  <QboWastepayExport
                    invoice={invoice}
                    actionsRequired={actionsRequired}
                    invoiceQueryKey={[QUERY_KEYS.invoice, id]}
                    isWastepayConnected={hauler.isWastepayConnected}
                  />)
                : (
                    !showConfirmationPage
                      ? (
                        <div className='dis-panel dis-panel-body'>
                          <h3 className={cn(styles.readyForExport, { [styles.readyForExportNoWP]: !hauler.isWastepayConnected })}>
                            Ready For Export
                          </h3>
                          {hauler.isWastepayConnected && <div className={styles.exportDisclaimer}>
                            Once an invoice is synced to QuickBooks you CANNOT edit fees or apply payment within Dispatcher
                          </div>}
                          <InvoicePreview
                            invoice={invoice}
                            showQboButton={false}
                            showDiscountAndTax={hauler.country === 'US'}
                            showActionColumn={multiTicket}
                            showLineItemTotals={multiTicket}
                            editRoute='exportInvoice'
                          />
                        </div>
                        )
                      : (<QboExportInvoiceSuccess
                          invoice={invoice}
                          secondaryButton={multiTicket ? <ConfirmationButton invoiceId={invoice?.id} /> : null}
                          transactions={formatPaymentsForQBOExportConfirmation(invoice)}
                          retry={handleRetryPaymentExport}
                         />)
                  )
            }
          </>
          )}
    </NormalLayoutContainer>
  )
}

function ConfirmationButton ({ invoiceId }) {
  return (
    <Link to={generatePath(ROUTES.invoice, { id: invoiceId })}
      className={cn('dis-btn dis-btn-lg dis-btn-gray', styles.qboConfirmationBtn)}>
      Back To Invoice
    </Link>
  )
}

ConfirmationButton.propTypes = {
  invoiceId: PropTypes.string
}
