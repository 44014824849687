import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonGroup, Button } from 'react-bootstrap'
import { REQUEST_HAULER_TEAM_ACTION } from '../hauler/team/sagas/haulerTeamSaga'
import { REQUEST_RECENT_ACTIVITY_ACTION } from './sagas/recentSaga'
import { REQUEST_RECENT_TICKETS_ACTION } from './sagas/recentTicketsSaga'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import PageTitle from '../../components/page-title'
import { Spinner } from '../shared/Spinner'
import ActivitiesList from './components/ActivitiesList'
import TicketsPieChart from './components/TicketsPieChart'
import TicketsPerDriverGraph from './components/TicketsPerDriverGraph'
import TicketsPerTicketTypeGraph from './components/TicketsPerTicketTypeGraph'
import TicketsPerResourceTypeGraph from './components/TicketsPerResourceTypeGraph'

export default function RecentIndex () {
  const dispatch = useDispatch()
  const { recent, recentTickets } = useSelector(({ recent, recentTickets }) => ({
    recent,
    recentTickets
  }))
  const [ticketsByGraphType, setTicketsByGraphType] = useState('Driver')
  const [activityShouldLoad, setActivityShouldLoad] = useState(false)

  const retrievePertinentData = useCallback(function retrievePertinentData () {
    dispatch({ type: REQUEST_HAULER_TEAM_ACTION })
    dispatch({ type: REQUEST_RECENT_TICKETS_ACTION })
  }, [dispatch])

  useEffect(() => {
    retrievePertinentData()

    const interval = setInterval(() => {
      if (recentTickets.refreshNeeded) {
        retrievePertinentData()
      }
    }, 15000)

    return () => {
      clearInterval(interval)
    }
  }, [dispatch, retrievePertinentData, recentTickets.refreshNeeded])

  function onActivityLoadButtonClick () {
    setActivityShouldLoad(true)
    dispatch({ type: REQUEST_RECENT_ACTIVITY_ACTION, payload: { page: 1 } })
  }

  return (
    <div>
      <NormalLayoutContainer>
        <div className='container-fluid'>
          <PageTitle>Recent Activity</PageTitle>

          <Spinner isFetching={recentTickets.isFetching}>
            <div>
              <div className='row push-down-small'>

                <div className='col-xs-12 col-sm-12 col-md-5 col-lg-5'>
                  <TicketsPieChart tickets={recentTickets} />
                </div>

                <div className='col-xs-12 col-sm-12 col-md-6 col-md-offset-1 col-lg-6 col-lg-offset-1 text-center'>
                  <h3>{"Today's Tickets by"}
                    <ButtonGroup className='space-left'>
                      <Button
                        onClick={() => setTicketsByGraphType('Driver')}
                        className={`${(ticketsByGraphType === 'Driver') ? 'active' : ''}`}>
                        Driver
                      </Button>
                      <Button
                        onClick={() => setTicketsByGraphType('Ticket Type')}
                        className={`${(ticketsByGraphType === 'Ticket Type') ? 'active' : ''}`}>
                        Ticket Type
                      </Button>
                      <Button
                        onClick={() => setTicketsByGraphType('Asset Type')}
                        className={`${(ticketsByGraphType === 'Asset Type') ? 'active' : ''}`}>
                        Asset Type
                      </Button>
                    </ButtonGroup>
                  </h3>
                  {(() => {
                    if (!recentTickets.tickets.length) {
                      return <h4 className='push-down-small'>No Tickets Today</h4>
                    }
                    switch (ticketsByGraphType) {
                      case 'Driver':
                        return <TicketsPerDriverGraph tickets={recentTickets.tickets} />
                      case 'Ticket Type':
                        return <TicketsPerTicketTypeGraph tickets={recentTickets.tickets} />
                      case 'Asset Type':
                        return <TicketsPerResourceTypeGraph tickets={recentTickets.tickets} />
                      default:
                        return null
                    }
                  })()}
                </div>
              </div>
            </div>
          </Spinner>

          <div className='push-down-small'>
            <div>
              <ActivitiesList
                activities={recent.activities}
                pagination={recent.pagination}
                onPageRequest={page => dispatch({ type: REQUEST_RECENT_ACTIVITY_ACTION, payload: { page } })}
                showLoadButton={!activityShouldLoad}
                onLoadButtonClick={onActivityLoadButtonClick}
                isLoading={recent.isFetching}
              />
            </div>
          </div>

        </div>
      </NormalLayoutContainer>
    </div>
  )
}
