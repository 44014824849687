export const BACKGROUND_COLOR_MAPPING = {
  NEW: 'bg-green',
  PARSING: 'bg-green',
  CREATING_RECORDS: 'bg-green',
  PARSING_FAILED: 'bg-red',
  VALIDATED: 'bg-green',
  COMPLETED: 'bg-gray-light'
}

export const DISPLAY_MAPPING = {
  NEW: 'NEW',
  PARSING: 'PARSING',
  CREATING_RECORDS: 'CREATING',
  PARSING_FAILED: 'FAILED',
  VALIDATED: 'VALIDATED',
  COMPLETED: 'COMPLETED'
}
