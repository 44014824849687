import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'
import { StringParam, useQueryParams } from 'use-query-params'

const READY_TAB = 'READY'
const ERRORS_TAB = 'ERRORS'

export const FilePreview = ({ pluralImportName, importDetails, validatedImportRowsTableComponent: ImportRowsTable }) => {
  const [queryParams, setQueryParams] = useQueryParams({ tab: StringParam }, { includeAllParams: true })

  useEffect(function setInitialTab () {
    if (queryParams.tab && [READY_TAB, ERRORS_TAB].includes(queryParams.tab)) return
    setQueryParams({ tab: importDetails.invalidRowsCount === 0 ? READY_TAB : ERRORS_TAB })
  }, [importDetails.invalidRowsCount, queryParams, setQueryParams])

  return (
    <div className='panel panel-default'>
      <div className='panel-heading flex flex-row' style={{ paddingBottom: 0 }}>
        <button
          className={cn('px-8 py-6 font-medium text-2xl', { 'bg-white': queryParams.tab === READY_TAB })}
          onClick={() => setQueryParams({ tab: READY_TAB }, 'replace')}>
          <span className={styles.capitalize}>{pluralImportName}</span> to Be Imported ({importDetails.validRowsCount})
        </button>
        <button
          className={cn('px-8 py-6 font-medium text-2xl', { 'bg-white': queryParams.tab === ERRORS_TAB })}
          onClick={() => setQueryParams({ tab: ERRORS_TAB }, 'replace')}>
          <span className={styles.capitalize}>{pluralImportName}</span> with Errors ({importDetails.invalidRowsCount})
        </button>
      </div>
      <div className='panel-body'>
        {queryParams.tab === READY_TAB && <ImportRowsTable importId={importDetails.id} status='READY_FOR_IMPORT' />}
        {queryParams.tab === ERRORS_TAB && <ImportRowsTable importId={importDetails.id} status='DATA_ERRORS' />}
      </div>
    </div>
  )
}

FilePreview.propTypes = {
  pluralImportName: PropTypes.string.isRequired,
  importDetails: PropTypes.shape({
    id: PropTypes.string.isRequired,
    validRowsCount: PropTypes.number.isRequired,
    invalidRowsCount: PropTypes.number.isRequired
  }).isRequired,
  validatedImportRowsTableComponent: PropTypes.elementType.isRequired
}
