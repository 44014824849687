import React from 'react'
import PropTypes from 'prop-types'

export default function ClosedInQboStatus ({ invoice }) {
  return (
    <ul className='dropdown-menu'>
      <li>
        <a href={invoice.qboUrl} target='_blank noopener noreferrer'>
          <span>View in QBO</span>
          <i className='material-icons'>forward</i>
        </a>
      </li>
    </ul>
  )
}
ClosedInQboStatus.propTypes = {
  invoice: PropTypes.shape({
    qboUrl: PropTypes.string
  }).isRequired
}
