/**
 * All routes ending in "Old" are routes that we are now redirecting to a new route.
 */
export const ROUTES = {
  home: '/',

  dispatch: '/dispatch',

  userProfile: '/user',
  userProfileEdit: '/user/edit',
  changePassword: '/user/change-password',
  forgotPassword: '/initialize-password-reset',
  finishForgotPassword: '/finish-password-reset',
  impersonation: '/impersonation',

  bulkCreateTickets: '/tickets/batch/create',
  bulkCreateTicketsOld: '/hauler/tickets/batch/create',
  bulkEditTicketsPage: '/tickets/batch/edit',
  bulkEditTicketsPageOld: '/hauler/tickets/batch/edit',
  ticketSearch: '/hauler/tickets/search',
  assignTicketOld: '/hauler/tickets/:id/assign',
  assignTicket: '/tickets/:id/assign',
  rescheduleTicket: '/hauler/tickets/:id/reschedule',
  ticket: '/tickets/:id',
  ticketOld: '/hauler/tickets/:id',
  editTicket: '/tickets/:id/edit',
  editTicketOld: '/hauler/tickets/:id/edit',
  createTicket: '/tickets/new',
  createTicketOld: '/hauler/tickets/new',

  newTicketType: '/ticket-types/new',
  editTicketType: '/ticket-types/:id/edit',
  ticketTypes: '/ticket-types',
  ticketTypesOld: '/hauler/ticket-types',

  feeTypes: '/fee-types',
  feeTypesOld: '/hauler/fee-types',
  newFeeType: '/fee-types/new',
  newFeeTypeOld: '/hauler/fee-types/new',
  editFeeType: '/fee-types/:id/edit',
  editFeeTypeOld: '/hauler/fee-types/:id/edit',

  invoices: '/invoices',
  invoicesOld: '/hauler/invoices',
  invoiceEdit: '/invoices/:id/edit',
  invoiceEditAddTickets: '/invoices/:invoiceId/add-tickets',
  invoicesExport: '/invoices/export/:id',
  invoiceNewClient: '/invoices/new/clients',
  invoiceNewClientOld: '/hauler/invoices/new/clients',
  invoiceNewClientsTickets: '/invoices/new/clients/:clientId/tickets',
  invoiceNewClientsTicketsOld: '/hauler/invoices/new/clients/:clientId/tickets',
  invoicePayment: '/invoices/payment/:id',
  wastepayRefund: '/invoices/:invoiceId/transactions/:transactionId',
  dispatcherRefund: '/invoices/:invoiceId/payments/:paymentId',
  invoiceOld: '/hauler/invoices/:id',
  invoice: '/invoices/:id',
  customerInvoice: '/invoice/:id',

  invoiceSettings: '/invoice-settings',

  pro: '/pro',
  proMapAssets: '/pro/settings/assets',
  proMapTickets: '/pro/settings/tickets',

  quickbooksSettings: '/quickbooks/settings',
  quickbooksSettingsOld: '/hauler/quickbooks/settings',
  quickbooksDisconnect: '/quickbooks/disconnect',
  quickbooksDisconnectOld: '/hauler/quickbooks/disconnect',
  quickbooksImport: '/quickbooks/import',
  quickbooksImportOld: '/hauler/quickbooks/import',
  quickbooksImportResult: '/quickbooks/import/:id',
  quickbooksImportResultOld: '/hauler/quickbooks/import/:id',
  wastePaySettings: '/wastepay-settings',
  haulerSettings: '/hauler',
  ticketTypesRequiredActionsOld: '/hauler/ticket-types/required-actions',
  ticketTypesRequiredActions: '/ticket-types/required-actions',

  accounts: '/clients',
  clients: '/clients',
  clientsOld: '/hauler/clients/search',
  client: '/clients/:clientId',
  clientOld: '/hauler/clients/:clientId',
  clientEdit: '/clients/:clientId/edit',
  clientEditOld: '/hauler/clients/:clientId/edit',
  clientCreate: '/clients/new',
  clientCreateOld: '/hauler/clients/new',

  clientImport: '/client-imports/:clientImportId',
  clientImportOld: '/hauler/client_imports/:clientImportId',
  clientImports: '/client-imports',
  clientImportsOld: '/hauler/client_imports',

  site: '/sites/:id',
  siteOld: '/hauler/sites/:id',
  newSite: '/sites/new',
  newSiteOld: '/hauler/sites/new',
  sitesSearch: '/sites',
  sitesSearchOld: '/hauler/sites/search',
  editSite: '/sites/:id/edit',
  editSiteOld: '/hauler/sites/:id/edit',

  jobs: '/jobs',
  jobsOld: '/hauler/jobs/search',
  newJob: '/jobs/new',
  newJobOld: '/hauler/jobs/new',
  job: '/jobs/:id',
  jobOld: '/hauler/jobs/:id',
  editJob: '/jobs/:id/edit',
  editJobOld: '/hauler/jobs/:id/edit',

  haulerTeam: '/hauler/team',
  teamMember: '/hauler/team/:id',
  newTeamMember: '/hauler/team/new',

  createResourceType: '/asset-types/new',
  createResourceTypeOld: '/hauler/resource-types/new',
  editResourceType: '/asset-types/:id/edit',
  editResourceTypeOld: '/hauler/resource-types/:id/edit',
  resourceTypes: '/asset-types',
  resourceTypesOld: '/hauler/resource-types',

  assets: '/assets',
  editResourceOld: '/hauler/resources/:id/edit',
  editResource: '/resources/:id/edit',
  resourceOld: '/hauler/resources/:id',
  resource: '/resources/:id',
  resources: '/assets',
  resourcesOld: '/hauler/resources',
  createResource: '/hauler/resources/new',
  resourceCharts: '/hauler/resources/charts',

  resourceImports: '/resource-imports',
  resourceImportsOld: '/hauler/resource_imports',
  resourceImport: '/resource-imports/:resourceImportId',
  resourceImportOld: '/hauler/resource_imports/:resourceImportId',

  driverAssignmentSettings: '/driver-assignment-settings',
  smsSettings: '/sms-settings'
}
