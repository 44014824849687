import { loader } from 'graphql.macro'

export default async function signUp ({ dataLoaders, variables }) {
  const schema = loader('./signUp.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      userAttributes: variables.userAttributes,
      haulerAttributes: variables.haulerAttributes
    }
  })
  return {
    accessToken: response.register.auth.accessToken,
    refreshToken: response.register.auth.refreshToken
  }
}
