import notify from './notify'

export function captureError (error) {
  console.error(error)
  // TODO send the error to rollbar or sentry or whatever tool we choose
}

export function captureErrorAndNotify (error, message, time = 2) {
  captureError(error)
  notify('error', message, time)
}

export function handleQBOInvoiceExportError (error) {
  if (error.message.search('Hauler is not connected to QuickBooks') !== -1) {
    return captureErrorAndNotify(error, 'Error exporting invoice: Hauler not connected. Please connect hauler to QuickBooks.', 6)
  } else if (error.message.search('not exported to QuickBooks') !== -1) {
    return captureErrorAndNotify(error, 'Error exporting invoice: Account export issue. Please export account to QuickBooks.', 6)
  }
  return captureErrorAndNotify(error, 'Error exporting invoice to Quickbooks.', 6)
}
