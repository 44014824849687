import dayjs from '../../utilities/dayjs'

export const validate = (values) => {
  const errors = {}

  if (!values.ticketTypeId) {
    errors.ticketTypeId = 'Required'
  }

  if (!values.resourceTypeId) {
    errors.resourceType = 'Required'
  }

  if (!values.billingStatus) {
    errors.billingStatus = 'Required'
  }

  if (values.requestedStartTime && !values.requestedEndTime) {
    errors.requestedEndTime = 'Required if Start Time'
  }

  if (values.requestedEndTime && !values.requestedStartTime) {
    errors.requestedStartTime = 'Required if End Time'
  }

  const formattedRequestedStartTime = dayjs(values.requestedStartTime, 'HH:mm')
  const formattedRequestedEndTime = dayjs(values.requestedEndTime, 'HH:mm')
  const invalidTimes =
    values.requestedStartTime &&
    values.requestedEndTime &&
    formattedRequestedEndTime.isBefore(formattedRequestedStartTime)
  const sameTimes =
    values.requestedStartTime &&
    values.requestedEndTime &&
    formattedRequestedEndTime.isSame(formattedRequestedStartTime)

  if (invalidTimes || sameTimes) {
    errors.requestedEndTime = 'End Time must be after Start Time'
    errors.requestedStartTime = 'Start Time must be before End Time'
  }

  return errors
}
