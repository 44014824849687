/*
 * Borrowed from MDN, https://developer.mozilla.org/en-US/docs/Glossary/Base64#solution_1_%E2%80%93_escaping_the_string_before_encoding_it
 * The idea here is that btoa and atob do not handle unicode, so we escape the values first before base64 encoding/decoding
 *
 * NOTE: These are using the deprecated "escape" and "unescape" functions. Despite being deprecated the support for these functions
 * is basically universal for the all the browsers we support. In the future we may have to look for an alternative but this is
 * a "good enough" solution for now.
 */

/**
 * base64 encode a unicode string
 * https://developer.mozilla.org/en-US/docs/Glossary/Base64#solution_1_%E2%80%93_escaping_the_string_before_encoding_it
 * @param str
 * @return {string}
 */
export function b64EncodeUnicode (str) {
  return btoa(unescape(encodeURIComponent(str)))
}

/**
 * Convert a base64 encoded string back into unicode
 * @param str
 * @return {string}
 */
export function unicodeDecodeB64 (str) {
  return decodeURIComponent(escape(window.atob(str)))
}
