import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useQuery from '../../hooks/useQuery'
import useMutation from '../../hooks/useMutation'
import QUERY_KEYS from '../../graphql/queryKeys'
import siteTypesQuery from '../../graphql/queries/siteTypes'
import siteQuery from '../../graphql/queries/site'
import updateSiteMutation from '../../graphql/mutations/updateSite'
import { formatFormValuesForSubmit } from '../../utilities/randomUtilities'
import { prettyAddress } from '../../utilities/locationUtilities'
import notify from '../../utilities/notify'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import PageTitle from '../../components/page-title'
import { Spinner } from '../shared/Spinner'
import FormExplanation from '../shared/FormExplanation'
import SiteEditForm from '../../components/edit-site-form'

export default function EditSitePage () {
  const { id } = useParams()
  const history = useHistory()
  const { user, hauler } = useSelector(({ user: { user, hauler } }) => ({ user, hauler }))

  const cancelEditSite = () => {
    history.goBack()
  }

  const { data: { siteTypes } = [], isLoading: siteTypesFetching } = useQuery(
    [
      QUERY_KEYS.siteTypes,
      user.haulerId
    ],
    siteTypesQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Failed to retrieve site types')
      }
    }
  )

  const { data: { site } = {}, isLoading: siteFetching } = useQuery(
    [
      QUERY_KEYS.site,
      id
    ],
    siteQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Failed to retrieve site')
      }
    }
  )

  const { mutate: updateSite, isLoading: updateSiteLoading } = useMutation(updateSiteMutation, {
    onSuccess () {
      cancelEditSite()
      notify('success', 'Site updated successfully')
    },
    onError (error) {
      captureErrorAndNotify(error, 'Error updating site')
    }
  })

  const handleFormSubmit = (values, form) => {
    const initialValues = form.getState().initialValues
    const updatedSite = formatFormValuesForSubmit(initialValues, values)
    if (updatedSite.cost) updatedSite.cost = parseFloat(values.cost.replace(/,/g, ''))
    updateSite({ ...updatedSite, id })
  }

  return (
    <div>
      <NormalLayoutContainer showBackLink>
        <PageTitle title='Edit a Site'>
          <div className='dis-flex-page-title'>
            <div>Edit a Site</div>
            <button
              className='dis-btn dis-btn-blank dis-btn-lg'
              onClick={cancelEditSite}>Cancel
            </button>
          </div>
        </PageTitle>
        {siteTypesFetching || siteFetching
          ? <Spinner isFetching />
          : (
            <div className='dis-form-page-container'>
              <div className='dis-form-container-left'>
                <SiteEditForm
                  onSubmit={handleFormSubmit}
                  siteTypes={siteTypes}
                  initialValues={{
                    name: site?.name,
                    cost: parseFloat(site?.cost || 0).toFixed(2),
                    siteTypeId: site?.siteType?.id,
                    note: site?.note,
                    prettyAddress: prettyAddress(site?.location),
                    latitude: site?.location?.latitude,
                    longitude: site?.location?.longitude,
                    placeId: site?.location?.placeId,
                    addressline1: site?.location?.addressLine1,
                    addressline2: site?.location?.addressLine2,
                    city: site?.location?.city,
                    state: site?.location?.state,
                    zip: site?.location?.zip
                  }}
                  isFetching={updateSiteLoading}
                  showMetric={hauler.country !== 'US'}
                />
              </div>
              <div className='dis-form-container-right'>
                <FormExplanation
                  messages={[
                    `A site is somewhere drivers visit everyday. They can be Repair Shops, Container Yards, or Transfer Stations. 
                    They are not the Job Sites.`,
                    'Editing this Site will be automatically reflected throughout the rest of your Assets, Dispatch, Jobs, and Tickets.'
                  ]}
                />
              </div>
            </div>
            )
          }
      </NormalLayoutContainer>
    </div>
  )
}
