import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import notify from '../../utilities/notify'
import QUERY_KEYS from '../../graphql/queryKeys'
import Switch from '../../components/Switch'
import { ROUTES } from '../../routes'
import useQuery from '../../hooks/useQuery'
import { underscoreToCapital } from '../../utilities/stringUtilities'
import usersQuery from '../../graphql/queries/users'
import { PageTitle } from '../../components/page-title'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import UserStatusLabel from '../shared/UserStatusLabel'
import { Spinner } from '../shared/Spinner'
import UserRoleIcon from '../shared/UserRoleIcon'

export default function HaulerUsersPage () {
  const sortColumn = 'FULL_NAME'
  const { hauler } = useSelector(({ user }) => ({ hauler: user.hauler }))
  const [hideDeactivated, setHideDeactivated] = useState(true)
  const { data: { users }, isFetching: isUsersFetching, refetch: refetchUsers } = useQuery(
    [QUERY_KEYS.users, hauler.id, hideDeactivated, sortColumn],
    usersQuery,
    {
      onError () {
        notify('error', 'Error Fetching Users List')
      },
      initialData: { users: { nodes: [] } }
    }
  )

  useEffect(function callRefetch () {
    refetchUsers({ haulerId: hauler.id, hideDeactivated, sortColumn })
  }, [hideDeactivated, refetchUsers, hauler.id])

  function handleShowDisabledToggle (isChecked) {
    setHideDeactivated(!isChecked)
  }

  return (
    <NormalLayoutContainer>
      <div className='container-fluid'>
        <PageTitle title='Company Team' className='dis-flex-page-title'>
          <div className='dis-flex-page-title'>
            <div className='dis-toggle-switch-wrapper dis-flex-page-title'>
              <Switch
                size='small'
                checked={!hideDeactivated}
                onChange={handleShowDisabledToggle}
              />
              <div className='dis-toggle-label'>Show Disabled</div>
            </div>

            <Link to={ROUTES.newTeamMember} className='btn btn-lg btn-primary pull-right push-down-super-small'>
              <span className='pull-left hidden-xs'>Add Team Member</span>
              <i className='material-icons pull-right thumbster-button-icon no-margin-bs-xs'>add_circle</i>
            </Link>
          </div>
        </PageTitle>
        {
          isUsersFetching
            ? <Spinner isFetching />
            : (
              <div className='row panel panel-default table-responsive'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>Name</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Role</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(() => users.nodes.map((teamMember) => {
                      const teamMemberPath = `/hauler/team/${teamMember.id}`
                      return (
                        <tr key={teamMember.id}>
                          <td>
                            <Link to={teamMemberPath}>
                              <UserStatusLabel deleted_at={teamMember.deletedAt} />
                            </Link>
                          </td>
                          <td>
                            <Link to={teamMemberPath}>{teamMember.firstName} {teamMember.lastName}</Link>
                          </td>
                          <td>
                            {teamMember.prettyPrimaryPhone}
                          </td>
                          <td>
                            <a href={`mailto:${teamMember.email}`}>
                              {teamMember.email}
                            </a>
                          </td>
                          <td>
                            <div>
                              <div className='pull-left'>
                                <UserRoleIcon role={teamMember.role} />
                              </div>
                              <div className='pull-right hidden-xs hidden-sm'>
                                {underscoreToCapital(teamMember.role === 'hauler_admin' ? 'Admin' : 'Driver')}
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    }))()}
                  </tbody>
                </table>
              </div>
              )
        }
      </div>
    </NormalLayoutContainer>
  )
}
