import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { SORT_VALUES } from './constants'
import styles from './unassignedTicketSort.module.scss'

export default function UnassignedTicketsSort ({
  unassignedTicketsSortPreference,
  onSaveUnassignedTicketsSortPreference
}) {
  function handleSaveUnassignedTicketsSortPreference (newPreference) {
    onSaveUnassignedTicketsSortPreference({ ...unassignedTicketsSortPreference, ...newPreference })
  }

  return (
    <div className={styles.sortContainer}>
      <div className={styles.selectContainer}>
        <label htmlFor='sort by' className='control-label'>Sort By:</label>
        <select name='sort by'
          className={`dis-select ${styles.sortSelect}`}
          value={unassignedTicketsSortPreference.sortPreference}
          onChange={(e) => handleSaveUnassignedTicketsSortPreference({ sortPreference: e.currentTarget.value })}>
          {SORT_VALUES.map((opt) => (
            <option
              value={opt.value}
              key={opt.value}>
              {opt.label}
            </option>
          ))}
        </select>
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div className={styles.sortButton}
        tabIndex={0}
        role='button'
        onClick={() => handleSaveUnassignedTicketsSortPreference({ sortFlagged: !unassignedTicketsSortPreference.sortFlagged })}>
        <span>Flagged</span>
        <i className={classNames(styles.sortIcons, {
          'material-icons': unassignedTicketsSortPreference.sortFlagged,
          'material-icons-outlined': !unassignedTicketsSortPreference.sortFlagged
        })}>
          published
        </i>
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div className={styles.sortButton}
        tabIndex={0}
        role='button'
        onClick={() => handleSaveUnassignedTicketsSortPreference({ hideCancelled: !unassignedTicketsSortPreference.hideCancelled })}>
        <span>Cancelled</span>
        <i className={classNames(styles.sortIcons, {
          'material-icons-outlined': unassignedTicketsSortPreference.hideCancelled,
          'material-icons': !unassignedTicketsSortPreference.hideCancelled
        })}>
          {unassignedTicketsSortPreference.hideCancelled ? 'visibility_off' : 'visibility'}
        </i>
      </div>
    </div>
  )
}

UnassignedTicketsSort.propTypes = {
  unassignedTicketsSortPreference: PropTypes.shape({
    sortPreference: PropTypes.string.isRequired,
    sortFlagged: PropTypes.bool.isRequired,
    hideCancelled: PropTypes.bool.isRequired
  }),
  onSaveUnassignedTicketsSortPreference: PropTypes.func.isRequired
}
