import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'

export default function QBOExportStatus ({ invoice, retry, transactions }) {
  if (invoice.qboInvoiceExportStatus == null) {
    return null
  }
  const isRefund = transactions?.[0]?.transactionType.toLowerCase() === 'refund'
  return (
    <div className={styles.exportStatusContainer}>
      { /* We do not export the invoice with payment refunds however
      this will render a previously exported invoice when auto export invoices is false */}
      {!isRefund && <>
        {invoice.qboInvoiceExportStatus === 'SUCCESS' && (
          <div>
            <i className={cn('material-icons', styles.successIcon, styles.exportStatusIcons)}>check_circle</i>
            Your <strong>invoice</strong> has been exported to Quickbooks Online.
            <a
              href={invoice.qboUrl}
              className={styles.qboLink}
              target='_blank noopener noreferrer'>
              View In QBO
            </a>
          </div>
        )}
        {invoice.qboInvoiceExportStatus === 'ERROR' && (
          <div>
            <i className={cn('material-icons', styles.errorIcon, styles.exportStatusIcons)}>cancel</i>
            Your <strong>invoice</strong> failed to export to Quickbooks Online.
          </div>
        )}
      </>}
      {transactions.map(transaction => {
        return (<div key={transaction.id}>
          {transaction.qboExportStatus === 'SUCCESS' && (
            <div>
              <i className={cn('material-icons', styles.successIcon, styles.exportStatusIcons)}>check_circle</i>
              Your <strong>{transaction.transactionType} for transaction ID {transaction.id}</strong> has been exported to Quickbooks Online.
              {transaction.qboLink != null && (
                <a
                  href={transaction.qboLink}
                  className={styles.qboLink}
                  target='_blank noopener noreferrer'>
                  View In QBO
                </a>
              )}
            </div>
          )}
          {transaction.qboExportStatus === 'ERROR' && (
            <div>
              <i className={cn('material-icons', styles.errorIcon, styles.exportStatusIcons)}>cancel</i>
              <span className={styles.transactionError}>Your <strong>{transaction.transactionType}</strong> failed to export to Quickbooks Online.</span>
              <button
                tabIndex='0'
                className={styles.retryBtn}
                onClick={retry}>
                Retry
              </button>
            </div>
          )}
        </div>)
      })}
    </div>
  )
}

QBOExportStatus.propTypes = {
  invoice: PropTypes.shape({
    qboUrl: PropTypes.string,
    qboInvoiceExportStatus: PropTypes.oneOf(['SUCCESS', 'ERROR'])
  }).isRequired,
  retry: PropTypes.func.isRequired,
  transactions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    transactionType: PropTypes.oneOf(['Payment', 'Refund']),
    qboLink: PropTypes.string,
    qboExportStatus: PropTypes.oneOf(['SUCCESS', 'ERROR'])
  })).isRequired,
  transactionQboExportStatus: PropTypes.oneOf(['SUCCESS', 'ERROR']),
  transactionQboUrl: PropTypes.string
}
