import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Pagination from '../../shared/Pagination'
import ActivityIcon from '../../shared/ActivityIcon'
import ActivityLink from './ActivityLink'
import { Spinner } from '../../shared/Spinner'

export default function ActivitiesList ({
  activities,
  onPageRequest,
  pagination,
  showLoadButton = false,
  onLoadButtonClick = () => {},
  isLoading = false
}) {
  return (
    <div className='row panel panel-default'>
      <div className='panel-heading'>
        <div className='container-fluid'>
          <div>
            <h4>Your Company Activities & Comments</h4>
          </div>
        </div>
      </div>
      <div className='panel-body'>
        <div className='list-group'>
          <Spinner isFetching={isLoading}>
            {
              showLoadButton
                ? (
                  <div className='flex justify-center mt-2 mb-16'>
                    <button
                      type='button'
                      onClick={onLoadButtonClick}
                      className='btn btn-default'
                      disabled={false}>
                      <span className='pull-left'>Show Activities</span>
                      <i className='material-icons pull-right space-left'>get_app</i>
                    </button>
                  </div>
                  )
                : (<>
                  {activities.map(activity => {
                    const comment = activity.key === 'ticket.add_comment'
                    const activityType = `${activity.key.split('.')[0]}s`
                    const listItemClasses = `list-group-item ${comment ? 'highlight-bg' : ''}`
                    return (
                      <li className={listItemClasses} key={activity.id}>
                        <div className='pull-left font-size-1-25em'>
                          <ActivityLink
                            activityType={activityType}
                            activityId={activity.trackableId}>
                            {comment ? `Comment: ${activity.message}` : activity.message}
                          </ActivityLink>
                        </div>
                        <ActivityIcon iconName={activity.key} />
                        <div className='clearfix' />

                        <h6 className='pull-right'>
                          {moment(activity.createdAt).format('dddd, MMMM Do YYYY, h:mm:ss a')}
                        </h6>
                        <h6 className='text-left'>
                          <em>
                            By
                            {activity.ownerType.toUpperCase() === 'USER'
                              ? <Link to={`/hauler/team/${activity.authorId}`}>
                                {` ${activity.author}`}
                              </Link>
                              : <>{` ${activity.author}`}</>
                            }
                          </em>
                        </h6>
                      </li>
                    )
                  })}
                </>)
            }
          </Spinner>
        </div>
      </div>
      {
        !showLoadButton && (
          <div className='panel-footer'>
            <Pagination pagination={pagination} onPageRequest={onPageRequest} />
          </div>
        )
      }
    </div>
  )
}

ActivitiesList.propTypes = {
  activities: PropTypes.array.isRequired,
  onPageRequest: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  showLoadButton: PropTypes.bool,
  onLoadButtonClick: PropTypes.func,
  isLoading: PropTypes.bool
}
