
import React from 'react'

import FormExplanation from '../../../../shared/FormExplanation'

export default function CreateResourceTypeExplanation () {
  const messages = [`Asset Types are the types of things you own like "20 Yard Dumpster" or "40 Yard Dumpster". Creating
          Asset Types let's you create multiple instances of the same type of container.`]
  return (
    <FormExplanation messages={messages} />
  )
}
