import { loader } from 'graphql.macro'

export default function createTicketPage ({ dataLoaders, queryKey }) {
  const [, jobId, haulerId] = queryKey
  const schema = loader('./createTicketPage.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(schema, {
    jobId,
    haulerId
  })
}
