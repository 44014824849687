import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'

import RenderAutoSuggestField from '../../../../../shared/formRenderers/RenderAutoSuggestField'
import { validate } from './SiteResourcesSearchFormUtils'

function SiteResourcesSearchForm ({ handleSubmit, pristine, invalid, resources }) {
  return (
    <div className='container-fluid'>
      <form onSubmit={handleSubmit}>

        <div className='pull-left'>
          <Field
            name='id'
            placeholder='Asset Unique ID'
            options={resources}
            labelKey='uid'
            field='id'
            component={RenderAutoSuggestField}
          />
        </div>

        <button
          type='submit'
          className='btn btn-lg btn-primary pull-right'
          disabled={pristine || invalid}>
          <span className='pull-left'>Add To Site</span>
          <i className='material-icons pull-right thumbster-button-icon'>add_circle</i>
        </button>

      </form>
    </div>
  )
}

SiteResourcesSearchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  resources: PropTypes.array.isRequired
}

// eslint-disable-next-line no-class-assign
export default reduxForm({
  form: 'siteResourcesSearchForm',
  validate
})(SiteResourcesSearchForm)
