import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Typeahead } from 'react-bootstrap-typeahead'

const initialVals = {
  customId: '',
  streetAddress: '',
  city: '',
  zipCode: '',
  clientId: '',
  status: ''
}
export default function JobsTableSearchForm ({
  clearFormValues,
  clients,
  handleSubmit,
  initialFormVals = initialVals
}) {
  const typeahead = useRef(null)
  const [formValues, setFormValues] = useState(initialFormVals)

  useEffect(() => {
    if (typeahead?.current && !formValues.clientId) {
      typeahead.current.clear()
    }
  }, [formValues.clientId])

  function handleClear () {
    clearFormValues()
    setFormValues(initialVals)
  }

  function onSubmit (event) {
    event.preventDefault()
    handleSubmit(formValues)
  }

  function handleOnChange (field, val) {
    setFormValues({ ...formValues, [field]: val })
  }

  return (
    <div className='container-fluid'>
      <form onSubmit={onSubmit} autoComplete='off'>
        <div className='row'>
          <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3 form-group'>
            <label htmlFor='custom_id' className='control-label'>Job ID</label>
            <input
              name='custom_id'
              type='text'
              className='form-control'
              value={formValues.customId || ''}
              onChange={(e) => handleOnChange('customId', e.target.value)}
            />
          </div>
          <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3 form-group'>
            <label htmlFor='street_address' className='control-label'>Street Address</label>
            <input
              name='street_address'
              type='text'
              className='form-control'
              value={formValues.streetAddress || ''}
              onChange={(e) => handleOnChange('streetAddress', e.target.value)}
            />
          </div>
          <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3 form-group'>
            <label htmlFor='city' className='control-label'>City</label>
            <input
              name='city'
              type='text'
              className='form-control'
              value={formValues.city || ''}
              onChange={(e) => handleOnChange('city', e.target.value)}
            />
          </div>
          <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3 form-group'>
            <label htmlFor='zip' className='control-label'>Postal Code</label>
            <input
              name='zip'
              type='text'
              className='form-control'
              value={formValues.zipCode || ''}
              onChange={(e) => handleOnChange('zipCode', e.target.value)}
            />
          </div>

          <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3 form-group'>
            <label htmlFor='client_id' className='control-label'>Account Name</label>
            <Typeahead
              ref={typeahead}
              name='client_id'
              id='typeahead-id'
              defaultSelected={clients.filter(client => client.id === formValues.clientId)}
              onChange={(value) => {
                handleOnChange('clientId', value[0] ? value[0].id : null)
              }}
              labelKey='name'
              options={clients}
            />
          </div>

          <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3 form-group'>
            <label htmlFor='status' className='control-label'>Job Status</label>
            {/* eslint-disable-next-line jsx-a11y/no-onchange */}
            <select
              name='status'
              className='form-control'
              value={formValues.status}
              onChange={(e) => handleOnChange('status', e.currentTarget.value)}>
              <option key='placeholder' value=''></option>
              {Object.entries({
                open: 'Open',
                closed: 'Closed'
              }).map(([key, value]) =>
                <option key={key} value={key}>{value}</option>)}
            </select>
          </div>

        </div>

        <div className='row text-right'>

          <button
            type='button'
            tabIndex={-1}
            onClick={handleClear}
            className='btn btn-default push-down-very-small pull-right'
            style={{ marginLeft: 20 }}>
            <span className='pull-left'>Clear</span>
            <i className='material-icons pull-right space-left'>clear</i>
          </button>

          <button
            type='submit'
            className='btn btn-success push-down-very-small pull-right'>
            <span className='pull-left'>Search</span>
            <i className='material-icons pull-right space-left'>search</i>
          </button>

        </div>

      </form>
    </div>
  )
}

JobsTableSearchForm.propTypes = {
  initialFormVals: PropTypes.object,
  clearFormValues: PropTypes.func,
  clients: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired
}
