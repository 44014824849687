import {
  REQUEST_CURRENT_RESOURCE_ACTIVITIES_ACTIONS,
  RECEIVE_CURRENT_RESOURCE_ACTIVITIES_SUCCESS,
  RECEIVE_CURRENT_RESOURCE_ACTIVITIES_FAILURE
} from '../sagas/currentResourceActivitiesSaga'
import { camelCasedObjectToSnakeCasedObject } from '../../../../utilities/randomUtilities'

const initialState = {
  isFetching: false,
  errors: [],
  activities: [],
  pagination: {}
}

export default function currentResourceActivitiesReducer (reduxState = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case REQUEST_CURRENT_RESOURCE_ACTIVITIES_ACTIONS:
      return {
        ...reduxState,
        isFetching: true,
        errors: []
      }
    case RECEIVE_CURRENT_RESOURCE_ACTIVITIES_SUCCESS:
      return {
        ...reduxState,
        isFetching: false,
        activities: payload.activities,
        pagination: camelCasedObjectToSnakeCasedObject(payload.pagination)
      }
    case RECEIVE_CURRENT_RESOURCE_ACTIVITIES_FAILURE:
      return {
        ...reduxState,
        isFetching: false,
        errors: payload.errors
      }
    default:
      return reduxState
  }
}
