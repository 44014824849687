import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'

export default function BillingStatusIcon ({ billingStatus }) {
  return (
    <>
      {billingStatus !== 'PENDING' &&
        <i className={
        cn(
          styles.billingStatusIcon,
          (billingStatus === 'INVOICED') && 'material-icons-outlined',
          (billingStatus === 'PAID') && 'material-icons',
          (billingStatus === 'NOT_BILLABLE') && 'material-icons'
        )}>{billingStatus === 'NOT_BILLABLE' ? 'close' : 'paid'}</i>
      }
    </>
  )
}

BillingStatusIcon.propTypes = {
  billingStatus: PropTypes.string.isRequired
}
