import {
  REQUEST_HAULER_TEAM_ACTION,
  RECEIVE_HAULER_TEAM_SUCCESS,
  RECEIVE_HAULER_TEAM_FAILURE,
  RECEIVE_CREATE_HAULER_TEAM_MEMBER_SUCCESS,
  RECEIVE_CREATE_HAULER_TEAM_MEMBER_FAILURE
} from '../sagas/haulerTeamSaga'

const initialState = {
  isFetching: false,
  team: [],
  errors: [],
  create: {
    isFetching: false,
    errors: []
  }
}

export default function haulerTeamReducer (reduxState = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case REQUEST_HAULER_TEAM_ACTION:
      return {
        ...reduxState,
        isFetching: true,
        errors: []
      }
    case RECEIVE_HAULER_TEAM_SUCCESS:
      return {
        ...reduxState,
        isFetching: false,
        team: payload
      }
    case RECEIVE_HAULER_TEAM_FAILURE:
      return {
        ...reduxState,
        isFetching: false,
        errors: payload.errors
      }
    case RECEIVE_CREATE_HAULER_TEAM_MEMBER_SUCCESS:
      return {
        ...reduxState,
        create: {
          isFetching: false,
          errors: []
        }
      }
    case RECEIVE_CREATE_HAULER_TEAM_MEMBER_FAILURE:
      return {
        ...reduxState,
        create: {
          isFetching: false,
          errors: payload.errors
        }
      }
    default:
      return reduxState
  }
}
