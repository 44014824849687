import React from 'react'
import { useHistory } from 'react-router-dom'

export default function HaulerQuickbooksDisplay () {
  const history = useHistory()
  return (
    <div className='list-group-item'>
      <div className='flex justify-between flex-row'>
        <div className='mr-16'>
          <div className='font-bold text-3xl my-2'>Quickbooks Online</div>
          <div className='text-2xl my-2'>
            The Quickbooks Online integration lets you keep accounts, tickets,
            and invoices up-to-date and organized.
          </div>
        </div>

        <div className='h-12 flex self-center'>
          <button
            className='btn bg-green-light'
            onClick={() => history.push('/quickbooks/settings')}>
            <span className='font-medium text-2xl'>
              QUICKBOOKS SETTINGS
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}
