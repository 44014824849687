import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class NewDataAvailable extends Component {
  static reload () {
    window.location.reload()
  }

  render () {
    return (
      <div>

        {(() => {
          if (this.props.show) {
            return (
              <div>
                <button className='btn btn-sm btn-info btn-block pull-right' onClick={NewDataAvailable.reload}>
                  New Data is Available. Click to refresh!
                </button>
              </div>
            )
          }
          return null
        })()}

      </div>
    )
  }
}

NewDataAvailable.propTypes = {
  show: PropTypes.bool
}
