import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import fromPairs from 'lodash/fromPairs'
import map from 'lodash/map'
import dayjs from '../../utilities/dayjs'
import DefaultBillingStatusInputs from '../default-billing-status-inputs'
import {
  TextInput
} from '../TextInput'
import Spinner from 'react-spinkit'
import normalizePositiveNumber from '../../pages/shared/normalizers/normalizePositiveNumber'
import styles from './index.module.scss'
import cn from 'classnames'
import { TextareaInput } from '../TextareaInput'
import { DatePickerInput } from '../DatePickerInput'
import { TimeInput } from '../TimeInput'
import { SelectInput } from '../SelectInput'
import { CheckboxInput } from '../CheckboxInput'

const validate = (values) => {
  const errors = {}

  if (values.quantity > 50) {
    errors.quantity = 'Quantity must be 50 or less'
  }

  if (!values.ticketTypeId) {
    errors.ticketTypeId = 'Required'
  }

  if (!values.resourceTypeId) {
    errors.resourceTypeId = 'Required'
  }

  if (values.requestedStartTime && !values.requestedEndTime) {
    errors.requestedEndTime = 'Required if Start Time'
  }

  if (!values.quantity || values.quantity < 1) {
    errors.quantity = 'Quantity Required'
  }

  if (values.requestedEndTime && !values.requestedStartTime) {
    errors.requestedStartTime = 'Required if End Time'
  }

  if (!values.ruleInterval) {
    errors.ruleInterval = 'Required'
  }

  if (!values.ruleStartDate) {
    errors.ruleStartDate = 'Required'
  } else if (values.ruleStartDate > values.ruleEndDate) {
    errors.ruleStartDate = 'Must be before the end date'
  }

  if (!values.ruleEndDate) {
    errors.ruleEndDate = 'Required'
  } else if (values.ruleEndDate < values.ruleStartDate) {
    errors.ruleEndDate = 'Must be after start date'
  }

  if (values.ruleFrequency === 'WEEKLY' &&
    (!values.SUNDAY && !values.MONDAY && !values.TUESDAY && !values.WEDNESDAY &&
    !values.THURSDAY && !values.FRIDAY && !values.SATURDAY)
  ) {
    errors.SUNDAY = 'Must select a day of the week if weekly frequency'
  }

  const formattedRequestedStartTime = dayjs(values.requestedStartTime, 'HH:mm')
  const formattedRequestedEndTime = dayjs(values.requestedEndTime, 'HH:mm')
  const invalidTimes =
    values.requestedStartTime &&
    values.requestedEndTime &&
    formattedRequestedEndTime.isBefore(formattedRequestedStartTime)
  const sameTimes =
    values.requestedStartTime &&
    values.requestedEndTime &&
    formattedRequestedEndTime.isSame(formattedRequestedStartTime)

  if (invalidTimes || sameTimes) {
    errors.requestedEndTime = 'End Time must be after Start Time'
    errors.requestedStartTime = 'Start Time must be before End Time'
  }

  return errors
}
export default function BulkTicketsCreateForm ({
  onSubmit,
  isFetching,
  resourceTypes = [],
  ticketTypes = [],
  users,
  billingStatuses,
  ticketTypeBillingStatusSelected,
  setTicketTypeBillingStatusSelected,
  resourceTypeBillingStatusSelected,
  setResourceTypeBillingStatusSelected
}) {
  const ticketFrequencies = [
    {
      key: 'DAILY',
      readable: 'Days'
    },
    {
      key: 'WEEKLY',
      readable: 'Weeks'
    },
    {
      key: 'MONTHLY',
      readable: 'Months'
    },
    {
      key: 'YEARLY',
      readable: 'Years'
    }
  ]
  const every = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

  const userSelectOptions = () => {
    let options = map(users, u => [u.id, `${u.firstName} ${u.lastName}`])
    options = options.sort()
    options = fromPairs(options)
    return options
  }

  return (
    <Form onSubmit={onSubmit} validate={validate}>
      {({ handleSubmit, form, invalid, pristine, values }) => {
        const formState = form.getState()
        const billingStatusManuallyUpdated = formState.dirtyFields?.billingStatus && formState.visited?.billingStatus
        return (
          <form autoComplete='off' className={cn('dis-panel', styles.formContainer)} onSubmit={handleSubmit}>
            <div className={cn(styles.formRow, styles.rulesInputs)}>
              <Field
                name='ruleInterval'
                label='Every'
                component={SelectInput}
                options={fromPairs(map(every, every => [every, every]))}
              />
              <Field
                name='ruleFrequency'
                label='Frequency'
                component={SelectInput}
                options={fromPairs(map(ticketFrequencies, f => [f.key, f.readable]))}
              />
              <Field
                name='quantity'
                defaultValue='1'
                label='Quantity (max. 50)'
                type='number'
                normalize={normalizePositiveNumber}
                component={TextInput}
              />
            </div>

            <div className={cn(styles.formRow, styles.dateInputs)}>
              <Field
                name='ruleStartDate'
                label='Starting On'
                maxDate={dayjs().add(1, 'year')}
                minDate={dayjs()}
                component={DatePickerInput}
              />
              <Field
                name='ruleEndDate'
                label='Ending On'
                maxDate={dayjs().add(1, 'year')}
                minDate={dayjs()}
                component={DatePickerInput}
              />
            </div>

            {values.ruleFrequency === 'WEEKLY' && (
              <div className={cn(styles.formRow, styles.checkboxDays, 'push-down-super-small')}>
                <Field
                  type='checkbox'
                  disabled={values.ruleFrequency !== 'WEEKLY'}
                  name='SUNDAY'
                  label='Sun.'
                  component={CheckboxInput}
                />
                <Field
                  type='checkbox'
                  disabled={values.ruleFrequency !== 'WEEKLY'}
                  name='MONDAY'
                  label='Mon.'
                  component={CheckboxInput}
                />
                <Field
                  type='checkbox'
                  disabled={values.ruleFrequency !== 'WEEKLY'}
                  name='TUESDAY'
                  label='Tue.'
                  component={CheckboxInput}
                />
                <Field
                  type='checkbox'
                  disabled={values.ruleFrequency !== 'WEEKLY'}
                  name='WEDNESDAY'
                  label='Wed.'
                  component={CheckboxInput}
                />
                <Field
                  type='checkbox'
                  disabled={values.ruleFrequency !== 'WEEKLY'}
                  name='THURSDAY'
                  label='Thur.'
                  component={CheckboxInput}
                />
                <Field
                  type='checkbox'
                  disabled={values.ruleFrequency !== 'WEEKLY'}
                  name='FRIDAY'
                  label='Fri.'
                  component={CheckboxInput}
                />
                <Field
                  disabled={values.ruleFrequency !== 'WEEKLY'}
                  type='checkbox'
                  name='SATURDAY'
                  label='Sat.'
                  component={CheckboxInput}
                />
              </div>
            )
            }
            <div className='push-down-super-small'>
              <DefaultBillingStatusInputs
                billingStatuses={billingStatuses}
                billingStatusManuallyUpdated={billingStatusManuallyUpdated}
                resourceTypeBillingStatusSelected={resourceTypeBillingStatusSelected}
                resourceTypes={resourceTypes}
                setResourceTypeBillingStatusSelected={setResourceTypeBillingStatusSelected}
                setTicketTypeBillingStatusSelected={setTicketTypeBillingStatusSelected}
                ticketTypeBillingStatusSelected={ticketTypeBillingStatusSelected}
                ticketTypes={ticketTypes}
                values={values}
              />
            </div>

            <Field
              name='driverId'
              alphabetize
              component={SelectInput}
              label='Assigned Driver'
              options={userSelectOptions()}
            />

            <Field
              component={TextareaInput}
              label='Comment (Optional)'
              name='comment'
              placeholder='(Comments are for each individual ticket and can be used for placement details, messages for drivers, etc.
              Drivers can view comments on the mobile app.)'
            />

            <Field
              component={TextareaInput}
              label='Billing Notes (Optional)'
              name='billingNotes'
              maxLength='1000'
              placeholder='(Billing Notes can be used to track quoted prices or any other billing information.
              Drivers do not see billing notes on the mobile app.)'
            />

            <div className={cn(styles.formRow, styles.requestedTimeInputs, 'push-down-very-small')}>
              <Field
                name='requestedStartTime'
                label='Customer Requested Start Time'
                component={TimeInput}
              />
              <Field
                name='requestedEndTime'
                label='Customer Requested End Time'
                component={TimeInput}
              />
            </div>
            <div className={cn('dis-panel', styles.messageContainer)}>
              <span className={styles.messageContainerTitle}>Please note:</span>
              <span>It may take some time to create multiple tickets. You will receive a notification when all the tickets have been
                created or if an error occurs.</span>
            </div>
            <div className={cn(styles.buttonContainer, 'push-down-very-small')}>
              <button
                type='submit'
                className={cn('dis-btn dis-btn-lg dis-btn-primary', styles.submitBtn)}
                disabled={pristine || invalid || isFetching}>
                {isFetching
                  ? (<Spinner name='circle' className='mx-auto' color='black' fadeIn='none' />)
                  : (<span>Create Tickets</span>)}
              </button>
            </div>
          </form>
        )
      }}
    </Form>
  )
}

BulkTicketsCreateForm.propTypes = {
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  resourceTypes: PropTypes.any.isRequired,
  ticketTypes: PropTypes.any.isRequired,
  users: PropTypes.array,
  billingStatuses: PropTypes.object.isRequired,
  ticketTypeBillingStatusSelected: PropTypes.string,
  setTicketTypeBillingStatusSelected: PropTypes.func,
  resourceTypeBillingStatusSelected: PropTypes.string,
  setResourceTypeBillingStatusSelected: PropTypes.func
}
