import React from 'react'

export default function QuickbooksImportStarted () {
  return (<>
    <h1 className='text-6xl mt-16'>Import Started</h1>
    <p className='font-bold text-2xl'>This Process might take a few minutes. We will send you an email when the import is complete with a link
      to review the accounts created in Dispatcher.</p>
    <hr />
    <div className='bg-white p-8 rounded border border-gray-light'>
      <h2 className='text-3xl m-0'>Things to Remember</h2>
      <p>In order to make the most out of the QuickBooks Online integration, please keep the following in mind:</p>
      <ol className='list-decimal ml-8 text-gray'>
        <li className='text-2xl'>
          <h3 className='text-2xl my-0 ml-2'>Importing Customers from QuickBooks</h3>
          <p className='text-xl ml-2'>A great first step is importing your QuickBooks customers into Dispatcher. At any point you can import
            additional customers using the import options to pull in customers you’ll use in Dispatcher based on
            invoice date and/or customer type.</p>
        </li>
        <li className='text-2xl'>
          <h3 className='text-2xl my-0 ml-2'>Creating New Accounts in Dispatcher</h3>
          <p className='text-xl ml-2'>From this point on, create new accounts in Dispatcher first and let the integration push the customer
            into QuickBooks for the most efficient way to manage invoices and data.</p>
        </li>
        <li className='text-2xl'>
          <h3 className='text-2xl my-0 ml-2'>Keeping Customers and Fee Types in Sync</h3>
          <p className='text-xl ml-2'>Make sure the customer and fee type sync is turned on to help cut down on dual entry and speed up the
            invoicing process. This feature will automatically create new customers and products in QuickBooks
            during the invoicing process.</p>
        </li>
      </ol>
    </div>
  </>)
}
