import jwtDecode from 'jwt-decode'

export const decodeJWT = () => {
  try {
    if (localStorage.getItem('auth')) {
      return jwtDecode(JSON.parse(window.localStorage.getItem('auth')).access_token)
    }
    return null
  } catch (e) {
    if (e.code === 22) {
      alert("Your browser does not support localStorage. This is a common issue if you're in Private Mode")
    }
  }
  return null
}
