import React, { useEffect, useState, useMemo } from 'react'
import { gql } from 'graphql-request'
import notify from '../../utilities/notify'
import ProMappingTable from '../../components/pro/ProMappingTable'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import { useSelector } from 'react-redux'

function updateStandardAssetTypes (graphQLClient, formattedInput) {
  const mutation = gql`
  mutation UpdateResourceTypesStandardType($input: UpdateResourceTypesStandardInput!) {
    updateResourceTypesStandardType(input: $input) {
      resourceTypes {
        standardResourceTypeId
        name
        id
      }
    }
  }
`
  return graphQLClient.request(mutation, { input: { resourceTypesStandardType: formattedInput } })
}

function getStandardAssetTypes (graphQLClient, haulerId) {
  const standardResourceQuery = gql`
  query StandardResourceTypes($haulerId: ID!) {
    standardResourceTypes(haulerId: $haulerId) {
      name
      id
      description
    }
  }
`
  return graphQLClient.request(standardResourceQuery, { haulerId })
}

function getAssetTypes (graphQLClient, haulerId) {
  const resourceQuery = gql`
  query ResourceTypes($haulerId: ID!) {
    resourceTypes(haulerId: $haulerId) {
      name
      id
      standardResourceTypeId
      status
    }
  }
`
  return graphQLClient.request(resourceQuery, { haulerId })
}

function getPartnership (graphQLClient, haulerId) {
  const partnershipQuery = gql`
  query Partnership($haulerId: ID!) {
    partnership(haulerId: $haulerId) {
      clientCreationStrategy
      client {
        name
      }
    }
  }
`
  return graphQLClient.request(partnershipQuery, { haulerId })
}

export default function ProMapAssets () {
  const [assetOptions, setAssetOptions] = useState([])
  const [standardAssets, setStandardAssets] = useState([])
  const [tableValues, setTableValues] = useState([])
  const [clientName, setClientName] = useState(false)
  const [viewMore, setViewMore] = useState(false)
  const { user, graphQLClient } = useSelector(({ user, dataLoaders }) => ({
    user: user.user,
    graphQLClient: dataLoaders.dispatcher.graphQLClient
  }))

  const filterInitialList = useMemo(() => {
    const initialStandardAssets = ['10 yard', '12 yard', '15 yard', '20 yard', '25 yard', '30 yard', '35 yard', '40 yard', '50 yard']
    return [...tableValues].reduce(([initial, fullList], asset) => {
      return initialStandardAssets.includes(asset.name.toLowerCase()) ? [[...initial, asset], fullList] : [initial, [...fullList, asset]]
    }, [[], []])
  }, [tableValues])

  useEffect(function onInitialRender () {
    getAssetTypes(graphQLClient, user.haulerId)
      .then(({ resourceTypes }) => {
        const enabledResourceTypes = resourceTypes.filter(resource => resource.status === 'enabled')
        setAssetOptions(enabledResourceTypes)
      })
      .catch(() => notify('error', 'There was a problem retrieving asset types'))

    getStandardAssetTypes(graphQLClient, user.haulerId)
      .then(({ standardResourceTypes }) => {
        setStandardAssets(standardResourceTypes)
      })
      .catch(() => notify('error', 'There was a problem retrieving standard asset types'))

    getPartnership(graphQLClient, user.haulerId)
      .then(({ partnership }) => {
        if (partnership[0].clientCreationStrategy === '1client') {
          setClientName(partnership[0].client.name)
        } else {
          setClientName('Standard Brands')
        }
      })
      .catch(() => notify('error', 'There was a problem retrieving partnership'))
  }, [graphQLClient, user.haulerId])

  function saveAssetMapping () {
    const formattedInput = tableValues.filter(val => val.changed && val.mappedValue !== val.initialValue).map(val => ({
      standardType: val.mappedValue ? val.id : null,
      resourceTypeId: val.mappedValue || val.initialValue
    }))

    updateStandardAssetTypes(graphQLClient, formattedInput)
      .then((data) => {
        setAssetOptions(data.updateResourceTypesStandardType.resourceTypes)
        notify('success', 'Asset Type settings saved')
      })
      .catch(() => notify('error', 'Failed to update asset type settings'))
  }

  useEffect(() => {
    const filteredOptions = assetOptions.filter(asst => asst.standardResourceTypeId)
    const tableVals = standardAssets.map(asset => {
      const mappedValue = filteredOptions.find(asst => asst.standardResourceTypeId === asset.id)
      return ({
        name: asset.name,
        description: asset.description,
        id: asset.id,
        error: false,
        changed: false,
        initialValue: mappedValue?.id || null,
        mappedValue: mappedValue?.id || null
      })
    })
    setTableValues(tableVals)
  }, [assetOptions, standardAssets])

  return (
    <NormalLayoutContainer showBackLink>
      <h3>Asset Type Settings</h3>
      <p className='text-2xl'>Choose one of your asset types from the dropdowns
        below to connect it to each product you sell through {clientName}.
        An asset type can only be connected to one product.
      </p>
      {tableValues.length > 0 &&
        <ProMappingTable
          title='Asset'
          options={assetOptions}
          handleSave={saveAssetMapping}
          inputTypeID='resourceTypeId'
          tableValues={viewMore ? [...filterInitialList[0], ...filterInitialList[1]] : filterInitialList[0]}
          setTableValues={setTableValues}
          colTitle={clientName + ' Products'}
          viewMore={viewMore}
          setViewMore={setViewMore}
          showAllStandardTypes={false}
        />}
    </NormalLayoutContainer>
  )
}
