import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ticketQuery from '../../graphql/queries/ticket'
import usersQuery from '../../graphql/queries/users.js'
import assignTicketToUserMutation from '../../graphql/mutations/assignTicketToUser'
import useQuery from '../../hooks/useQuery'
import useMutation from '../../hooks/useMutation'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import QUERY_KEYS from '../../graphql/queryKeys'
import notify from '../../utilities/notify'
import { PageTitle } from '../../components/page-title'
import { Spinner } from '../shared/Spinner'
import FormExplanation from '../shared/FormExplanation'
import TicketAssignmentForm from '../../components/ticket-assignment-form'

export default function TicketAssignmentPage () {
  const { id } = useParams()
  const history = useHistory()
  const { user, hauler } = useSelector(({ user: { user, hauler } }) => ({ user, hauler }))

  const { data: { ticket } = {}, isFetching: ticketFetching } = useQuery(
    [QUERY_KEYS.ticket, id],
    ticketQuery, {
      onError (error) {
        captureErrorAndNotify(error, 'Error loading ticket')
      },
      placeholderData: { ticket: {} }
    })

  const { data: { users } = {}, isFetching: driverListFetching } = useQuery([
    QUERY_KEYS.users,
    user.haulerId,
    true,
    null,
    null,
    hauler.showDriversOnly ? 'hauler_driver' : null
  ],
  usersQuery,
  {
    onError (error) {
      captureErrorAndNotify(error, 'Unable to retrieve user list')
    },
    placeholderData: { users: { nodes: [] } }
  })

  const { mutate: assignTicketToUser, isLoading: assignTicketToUserLoading } = useMutation(assignTicketToUserMutation, {
    onSuccess () {
      history.goBack()
      notify('success', 'Ticket successfully assigned')
    },
    onError (error) {
      captureErrorAndNotify(error, 'Error assigning ticket')
    }
  })

  function handleFormSubmit (values) {
    assignTicketToUser({ id, driverId: values?.driverId })
  }

  return (
    <div>
      <NormalLayoutContainer showBackLink>
        <div className='container-fluid'>
          <PageTitle title='Assign a Company Ticket'>
            <button
              className='btn btn-lg btn-link pull-right push-down-very-small'
              onClick={() => history.goBack()}>Cancel
            </button>
          </PageTitle>

          {ticketFetching || driverListFetching
            ? <Spinner isFetching />
            : (
              <div className='row'>
                <div className='col-xs-12 col-sm-12 col-md-7 col-lg-7'>

                  <TicketAssignmentForm
                    initialValues={{ driverId: ticket?.driverId }}
                    isFetching={assignTicketToUserLoading}
                    onSubmit={handleFormSubmit}
                    users={users?.nodes}
                  />

                </div>
                <div className='col-xs-12 col-sm-12 col-md-4 col-md-offset-1 col-lg-4 col-lg-offset-1'>
                  <div className='row'>
                    <FormExplanation
                      messages={[
                        'Please assign/reassign the ticket to a designated driver.',
                        'Assigning or reassigning this ticket will be reflected on the ticket and on the dispatch screen'
                      ]}
                    />
                  </div>
                </div>
              </div>
              )}
        </div>
      </NormalLayoutContainer>
    </div>
  )
}
