export const SORT_MAP = {
  REQUESTED_TIME: 'Requested Time',
  TICKET_TYPE: 'Ticket Type',
  ASSET_TYPE: 'Asset Type',
  POSTAL_CODE: 'Postal Code'
}

export const SORT_VALUES = Object.entries(SORT_MAP).map(([key, value]) => ({
  value: key,
  label: value
}))
