import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import cn from 'classnames'
import { generatePath, Link } from 'react-router-dom'
import { ROUTES } from '../../routes'
import { INVOICE_STATUSES } from '../../utilities/constants'
import { CcPaymentForm } from '../cc-payment-form'
import CashCheckForm from '../cash-check-payment-form'
import Tabs from '../tabs'
import dayjs from '../../utilities/dayjs'

export default function PaymentDetails ({
  onSubmit,
  invoice,
  clientCards,
  errorMessage = [],
  hasCardQueryError = false,
  emailList = '',
  disabled = false,
  sendFromDispatcher,
  isWastepayConnected,
  dispatcherPaymentSubmitting,
  hauler,
  setNewCard,
  newCard,
  refetchCardList,
  setCardError
}) {
  const cardInitialValue = useMemo(() =>
    clientCards ? clientCards.find(card => card.isPrimary)?.id : null
  , [clientCards])

  function handleCcSubmit (data) {
    let submitData = data
    if (data.saleCC === newCard?.token) {
      submitData = { ...submitData, ...newCard }
      submitData.isUnsavedCard = true
    }

    return onSubmit({ formType: 'CARD', ...submitData })
  }

  function handleCashCheckSubmit (data) {
    return onSubmit({ formType: 'CASH/CHECK', ...data })
  }

  const tabs = [
    {
      name: 'Credit Card',
      content: (
        <CcPaymentForm
          onSubmit={handleCcSubmit}
          invoice={invoice}
          cardList={clientCards}
          hauler={hauler}
          isSolo={false}
          billingAddress={{
            streetAddress: invoice.client.addressline2
              ? `${invoice.client.addressline1} ${invoice.client.addressline2}`
              : invoice.client.addressline1,
            city: invoice.client.city,
            state: invoice.client.state,
            zip: invoice.client.zip
          }}
          initialValues={{
            email: emailList,
            saleCC: cardInitialValue
          }}
          hasCardQueryError={hasCardQueryError}
          errorMessage={errorMessage}
          disabled={disabled}
          newCard={newCard}
          setNewCard={setNewCard}
          refetchCardList={refetchCardList}
          setCardError={setCardError}
        />
      )
    },
    {
      name: 'Cash/Check',
      content: (
        <CashCheckForm
          onSubmit={handleCashCheckSubmit}
          invoice={invoice}
          isSolo={false}
          submitting={dispatcherPaymentSubmitting}
          initialValues={{
            paymentMethod: 'cash',
            paymentDate: dayjs().format('YYYY-MM-DD'),
            paymentAmount: invoice.outstandingBalance == null ? '0' : invoice.outstandingBalance.toString(),
            email: emailList
          }}
        />
      )
    }
  ]

  return (
    <div className={cn(styles.paymentContainer)}>
      {invoice.status === 'PAID'
        ? (<>
          <h4>Invoice is Paid</h4>
          <div className={styles.paidMessaging}>This invoice has already been charged and cannot have another payment added.</div>
        </>)
        : (<>
          {invoice.outstandingBalance <= 0
            ? (<>
              <div className={styles.noFeesMessage}>
                The balance must be greater than $0.00 to process a payment
              </div>
              <Link to={generatePath(ROUTES.invoiceEdit, { id: invoice.id })}
                className='dis-btn dis-btn-gray dis-btn-sm'>
                Edit Fees
              </Link>
            </>)
            : isWastepayConnected && sendFromDispatcher
              ? <Tabs tabs={tabs} handleUpdateTab={() => {}} />
              : isWastepayConnected && !sendFromDispatcher
                ? (
                  <CcPaymentForm
                    onSubmit={handleCcSubmit}
                    invoice={invoice}
                    cardList={clientCards}
                    hauler={hauler}
                    isSolo={true}
                    billingAddress={{
                      streetAddress: invoice.client.addressline2
                        ? `${invoice.client.addressline1} ${invoice.client.addressline2}`
                        : invoice.client.addressline1,
                      city: invoice.client.city,
                      state: invoice.client.state,
                      zip: invoice.client.zip
                    }}
                    initialValues={{
                      email: emailList,
                      saleCC: cardInitialValue
                    }}
                    hasCardQueryError={hasCardQueryError}
                    errorMessage={errorMessage}
                    disabled={disabled}
                    newCard={newCard}
                    setNewCard={setNewCard}
                    refetchCardList={refetchCardList}
                    setCardError={setCardError}
                  />
                  )
                : !isWastepayConnected && sendFromDispatcher
                    ? (<CashCheckForm
                        isSolo={true}
                        invoice={invoice}
                        onSubmit={handleCashCheckSubmit}
                        submitting={dispatcherPaymentSubmitting}
                        initialValues={{
                          paymentMethod: 'cash',
                          paymentDate: dayjs().format('YYYY-MM-DD'),
                          paymentAmount: invoice.outstandingBalance == null ? '0' : invoice.outstandingBalance.toString(),
                          email: emailList
                        }}
                       />)
                    : null
          }
        </>)}
    </div>
  )
}

PaymentDetails.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.oneOf(Object.keys(INVOICE_STATUSES)),
    outstandingBalance: PropTypes.number,
    client: PropTypes.shape({
      addressline1: PropTypes.string,
      addressline2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
      billingDetails: PropTypes.string
    }).isRequired
  }).isRequired,
  clientCards: PropTypes.arrayOf(PropTypes.shape({
    isPrimary: PropTypes.bool.isRequired
  })).isRequired,
  errorMessage: PropTypes.arrayOf(PropTypes.string),
  hasCardQueryError: PropTypes.bool,
  emailList: PropTypes.string,
  sendFromDispatcher: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  isWastepayConnected: PropTypes.bool.isRequired,
  dispatcherPaymentSubmitting: PropTypes.bool.isRequired,
  hauler: PropTypes.shape({
    country: PropTypes.string.isRequired
  }).isRequired,
  setNewCard: PropTypes.func.isRequired,
  newCard: PropTypes.shape({
    cardBrand: PropTypes.string.isRequired,
    token: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    id: PropTypes.number,
    isUnsavedCard: PropTypes.bool
  }),
  refetchCardList: PropTypes.func.isRequired,
  setCardError: PropTypes.func.isRequired
}
