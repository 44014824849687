import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../routes'
import PropTypes from 'prop-types'

export default function ProMappingContainer ({ clientName }) {
  return (
    <div className='bg-white shadow-md p-8 mt-16'>
      <div className='text-4xl mb-4'>Data Integrations</div>
      <div className='flex flex-wrap flex-row mb-2'>
        <div className='font-medium font-size-1-25em w-full'>Ticket Types</div>
        <div className='text-2xl'>{`Connect your Dispatcher ticket types to the order types sent by ${clientName}`}</div>
        <Link
          to={ROUTES.proMapTickets}
          className='ml-auto uppercase bg-green-light py-2 px-4 font-medium text-black
          hover:bg-green-light-10-dark hover:no-underline hover:text-black rounded shadow-md relative -top-6 text-2xl'>
          Set Ticket Types
        </Link>
      </div>
      <div className='flex flex-wrap flex-row'>
        <div className='font-medium font-size-1-25em w-full'>Asset Types</div>
        <div className='text-2xl'>{`Connect your Dispatcher asset types to the products sent by ${clientName}`}</div>
        <Link
          to={ROUTES.proMapAssets}
          className='ml-auto uppercase bg-green-light py-2 px-4 font-medium rounded text-black
          hover:bg-green-light-10-dark hover:no-underline hover:text-black shadow-md relative -top-6 text-2xl'>
          Set Asset Types
        </Link>
      </div>
    </div>
  )
}

ProMappingContainer.propTypes = {
  clientName: PropTypes.string
}
