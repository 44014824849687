import { useEffect, useState, useCallback } from 'react'

export default function useExpand (parentRef) {
  const [isOpened, setIsOpened] = useState(false)

  const toggle = useCallback(() => {
    setIsOpened(state => !state)
  }, [])

  const hide = useCallback(() => {
    setIsOpened(false)
  }, [])

  const show = useCallback(() => {
    setIsOpened(true)
  }, [])

  useEffect(() => {
    const handleClickOutside = event => {
      const ref = parentRef.current
      if (ref && !ref.contains(event.target)) {
        setIsOpened(false)
      }
    }

    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [parentRef])

  return {
    hide,
    isOpened,
    show,
    toggle
  }
}
