import { put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import { history } from '../../../../history'
import BASE_URI from '../../../../utilities/BASE_URI'
import { handleError } from '../../../../utilities/handleError'
import { getHeaders } from '../../../../utilities/getHeaders'
import { intercomConnection } from '../../../../intercomConnection'

export const REQUEST_CURRENT_SITE_TYPE_ACTION = 'REQUEST_CURRENT_SITE_TYPE_ACTION'
export const RECEIVE_CURRENT_SITE_TYPE_SUCCESS = 'RECEIVE_CURRENT_SITE_TYPE_SUCCESS'
export const RECEIVE_CURRENT_SITE_TYPE_FAILURE = 'RECEIVE_CURRENT_SITE_TYPE_FAILURE'

export const REQUEST_UPDATE_CURRENT_SITE_TYPE_ACTION = 'REQUEST_UPDATE_CURRENT_SITE_TYPE_ACTION'
export const RECEIVE_UPDATE_CURRENT_SITE_TYPE_SUCCESS = 'RECEIVE_UPDATE_CURRENT_SITE_TYPE_SUCCESS'
export const RECEIVE_UPDATE_CURRENT_SITE_TYPE_FAILURE = 'RECEIVE_UPDATE_CURRENT_SITE_TYPE_FAILURE'
export const CLEAR_CURRENT_SITE_TYPE_ERRORS = 'CLEAR_CURRENT_SITE_TYPE_ERRORS'

function * requestCurrentSiteType (data) {
  try {
    const id = data.payload
    const response = yield axios.get(
      `${BASE_URI}/hauler/site_types/${id}`,
      getHeaders()
    )
    yield put({ type: RECEIVE_CURRENT_SITE_TYPE_SUCCESS, payload: response.data })
  } catch (e) {
    const error = handleError(e)
    yield put({ type: RECEIVE_CURRENT_SITE_TYPE_FAILURE, payload: error })
  }
}

function * requestUpdateSiteType (data) {
  try {
    const { params } = data.payload
    const response = yield axios.patch(
      `${BASE_URI}/hauler/site_types/${params.id}`, params, getHeaders()
    )
    yield put({ type: RECEIVE_UPDATE_CURRENT_SITE_TYPE_SUCCESS, payload: response.data })
    yield intercomConnection.trackEvent('site_type_updated', {})
    yield history.push('/hauler/site-types')
  } catch (e) {
    const siteTypeErrors = e.response.data.errors
    const errArray = []
    siteTypeErrors.map(err => {
      if (err.title.toLowerCase() === 'short code has already been taken' || err.title.toLowerCase() === 'name has already been taken') {
        errArray.push(err.title)
      }
      return null
    })

    const errors = errArray.length ? errArray : handleError(e)
    yield put({ type: RECEIVE_UPDATE_CURRENT_SITE_TYPE_FAILURE, payload: { errors } })
  }
}

export default function * currentSiteTypeSaga () {
  yield takeLatest(REQUEST_CURRENT_SITE_TYPE_ACTION, requestCurrentSiteType)
  yield takeLatest(REQUEST_UPDATE_CURRENT_SITE_TYPE_ACTION, requestUpdateSiteType)
}
