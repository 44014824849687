import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { generatePath, Link } from 'react-router-dom'
import StatusDisplay from './StatusDisplay'
import NoResults from '../../pages/shared/NoResults'
import { ROUTES } from '../../routes'

export default function ResourceImportsTable ({ resourceImports }) {
  return (
    <div className='table-responsive'>
      <table className='table table-striped'>
        <thead>
          <tr>
            <th width='10%'>Status</th>
            <th width='20%'>Started At</th>
            <th width='20%'>Created By</th>
            <th width='10%'>Total Rows</th>
            <th width='10%'>Valid Rows</th>
            <th width='10%'>Invalid Rows</th>
            <th width='10%'>Action</th>
          </tr>
        </thead>
        <tbody>
          {resourceImports.length === 0 && <tr><td colSpan='7'><NoResults /></td></tr>}
          {resourceImports.map(data => (
            <tr key={data.id}>
              <td className='flex justify-center'>
                <StatusDisplay status={data.status} />
              </td>
              <td>{dayjs(data.createdAt).format('MMM D, YYYY h:mm a')}</td>
              <td>{`${data.creatingUser.firstName} ${data.creatingUser.lastName}`}</td>
              <td>{data.validRowsCount + data.invalidRowsCount}</td>
              <td>{data.validRowsCount}</td>
              <td>{data.invalidRowsCount}</td>
              <td>
                <Link to={generatePath(ROUTES.resourceImport, { resourceImportId: data.id })} className='btn btn-sm btn-primary-dk'>
                  Details
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

ResourceImportsTable.propTypes = {
  resourceImports: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    validRowsCount: PropTypes.number.isRequired,
    invalidRowsCount: PropTypes.number.isRequired,
    creatingUser: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string
    }).isRequired
  })).isRequired
}
