import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import useQuery from '../../hooks/useQuery'
import useMutation from '../../hooks/useMutation'
import QUERY_KEYS from '../../graphql/queryKeys'
import assetQuery from '../../graphql/queries/assetQuery'
import resourceTypesQuery from '../../graphql/queries/resourceTypes'
import updateAssetMutation from '../../graphql/mutations/updateAsset'
import { history } from '../../history'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import { PageTitle } from '../../components/page-title'
import { Spinner } from '../shared/Spinner'
import FormExplanation from '../shared/FormExplanation'
import notify from '../../utilities/notify'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import { prettyAddress } from '../../utilities/locationUtilities'
import AssetEditForm from '../../components/asset-edit-form'

export default function AssetEditPage () {
  const { id } = useParams()
  const [locationValues, setLocationValues] = useState({})
  const { user } = useSelector(({ user }) => ({ user }))

  const { data, isFetching: assetFetching, refetch } = useQuery([
    QUERY_KEYS.asset,
    id
  ],
  assetQuery,
  {
    enabled: Boolean(user.hauler.id),
    onSuccess: ({ asset }) => {
      setLocationValues({ latitude: asset?.currentLocation?.latitude, longitude: asset?.currentLocation?.longitude })
    },
    onError: (error) => handleError(error, 'Error fetching Asset')
  })

  const cancelEditResource = () => {
    history.goBack()
  }

  const { data: { resourceTypes = [] } = {}, isFetching: isFetchingResourceTypes } = useQuery(
    [QUERY_KEYS.resourceTypes, { haulerId: user.hauler.id, status: 'ENABLED' }],
    resourceTypesQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Failed to retrieve resource types')
      }
    }
  )

  const { mutate: updateAsset, isLoading: updateAssetLoading } = useMutation(updateAssetMutation, {
    onSuccess: () => {
      notify('success', 'Asset updated successfully!')
      history.goBack()
    },
    onError: (error) => {
      // custom error handling for now until a better front and back end error handling mechanism is established
      if (error?.response?.errors[0]?.message === 'Validation failed: Uid has already been taken') {
        handleError(error, 'Uid has already been taken')
      } else {
        handleError(error, error?.response?.errors[0]?.message)
      }
      refetch()
    }
  })

  function handleError (error, message) {
    console.error(error)
    notify('error', message)
  }

  const handleFormSubmit = (values) => {
    let variables = {
      id,
      assetUid: values.uid,
      assetTypeId: Number(values.resourceTypeId),
      placementNote: values.placementNote || ''
    }

    if (locationValues?.latitude && locationValues?.longitude) {
      variables = {
        ...variables,
        location: {
          latitude: locationValues?.latitude,
          longitude: locationValues?.longitude
        }
      }
    }

    updateAsset(variables)
  }

  const isFetching = assetFetching || updateAssetLoading
  return (
    <div>
      <NormalLayoutContainer showBackLink>
        <div className='container-fluid'>
          <PageTitle title='Edit an Asset'>
            <button
              className='btn btn-lg btn-link pull-right push-down-very-small'
              onClick={cancelEditResource}>
              Cancel
            </button>
          </PageTitle>

          <Spinner isFetching={isFetchingResourceTypes || isFetching}>

            <div className='row'>
              <div className='col-xs-12 col-sm-12 col-md-7 col-lg-7'>
                <AssetEditForm
                  isFetching={isFetching}
                  onSubmit={handleFormSubmit}
                  resourceTypes={resourceTypes}
                  currentResource={data?.asset}
                  setLocationValues={setLocationValues}
                  initialValues={{
                    city: data?.asset?.job?.id ? null : data?.asset?.currentLocation?.city,
                    country: data?.asset?.job?.id ? null : data?.asset?.currentLocation?.country,
                    initialAddress: data?.asset?.job?.id ? null : prettyAddress(data?.asset?.currentLocation),
                    latitude: data?.asset?.job?.id ? null : data?.asset?.currentLocation?.latitude,
                    longitude: data?.asset?.job?.id ? null : data?.asset?.currentLocation?.longitude,
                    placeId: data?.asset?.job?.id ? null : data?.asset?.currentLocation?.placeId,
                    placementNote: data?.asset?.placementNote,
                    resourceTypeId: data?.asset?.resourceTypeId,
                    state: data?.asset?.job?.id ? null : data?.asset?.currentLocation?.state,
                    uid: data?.asset?.uid,
                    zip: data?.asset?.job?.id ? null : data?.asset?.currentLocation?.zip
                  }}
                />
              </div>
              <div className='col-xs-12 col-sm-12 col-md-4 col-md-offset-1 col-lg-4 col-lg-offset-1'>
                <div className='row'>
                  <FormExplanation
                    messages={[
                      'Assets catalog the inventory of your rentable items',
                      'Each asset must be of a specific asset type. For example, "Container - 20 Yard" may be a asset type and "726" may be the Asset ID of a specific 20 yard container.',
                      'Editing this Asset will be automatically reflected in your ongoing and completed jobs.'
                    ]}
                  />
                </div>
              </div>
            </div>
          </Spinner>

        </div>
      </NormalLayoutContainer>
    </div>
  )
}
