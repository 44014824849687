import React from 'react'
import PropTypes from 'prop-types'
import SiteTypeStatusLabel from '../../../../shared/SiteTypeStatusLabel'

export default function SiteType ({
  siteType: { id, name, status, short_code: shortCode, sites_count: sitesCount },
  onEdit
}) {
  return (
    <div className='list-group-item'>
      <h4 className='list-group-item-heading'>
        <div className='font-size-0-75em inline-block status-pill'>
          <SiteTypeStatusLabel status={status} />
        </div>
        &nbsp;
        {name} ({shortCode})

        <button
          className='btn btn-primary-dk pull-right'
          onClick={() => onEdit(id)}>
          <span className='pull-left hidden-xs'>Edit Site Type</span>
          <i className='material-icons pull-right thumbster-button-icon'>mode_edit</i>
        </button>

        {/* Site Count */}
        <label
          className='pull-right inline-block label bg-gray-light med-dark-gray-text font-size-0-75em margin-top-0-5-pct margin-right-0-5-pct'>
          {`${sitesCount} Sites`}
        </label>

      </h4>
    </div>
  )
}

SiteType.propTypes = {
  onEdit: PropTypes.func.isRequired,
  siteType: PropTypes.object.isRequired
}
