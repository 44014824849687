import { loader } from 'graphql.macro'

export default async function changePassword ({ dataLoaders, variables }) {
  const schema = loader('./changePassword.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      currentPassword: variables.currentPassword,
      password: variables.newPassword,
      passwordConfirmation: variables.passwordConfirmation
    }
  })
  return response.updatePassword
}
