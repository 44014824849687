import React from 'react'
import PropTypes from 'prop-types'
import Spinner from 'react-spinkit'
import classNames from 'classnames'
import styles from './checkboxInput.module.scss'
import inputStyles from './inputs.module.scss'

export default function CheckboxInput ({
  checked,
  disabled = false,
  loading,
  onChange,
  size = 'large',
  labelName,
  labelText,
  customCheckedIconName = null,
  customCheckedIconClassName = null,
  customCheckedClassName = null,
  customUncheckedIconName = null,
  customUncheckedIconClassName = null,
  customUncheckedClassName = null
}) {
  return (loading
    ? <Spinner name='circle' fadeIn='none' className={classNames(inputStyles.spinner, inputStyles.centered)} />
    : (
      <label htmlFor={labelName} className={classNames(styles.checkbox, {
        [styles.checked]: checked && customCheckedClassName === null,
        [styles.unchecked]: !checked && customUncheckedClassName === null,
        [customCheckedClassName]: checked && customCheckedClassName !== null,
        [customUncheckedClassName]: !checked && customUncheckedClassName !== null,
        [styles.small]: size === 'small',
        [styles.large]: size === 'large',
        [styles.disabled]: disabled
      })}>
        <input
          aria-label={labelText}
          id={labelName}
          type='checkbox'
          className={inputStyles.hidden}
          readOnly
          disabled={disabled}
          onChange={onChange}
        />
        <div className={styles.checkIconWrapper}>
          {checked && (
            <i className={classNames('material-icons', customCheckedIconClassName, {
              [styles.checkIcon]: customCheckedIconClassName === null,
              [styles.checkIconSmall]: size === 'small'
            })}>{customCheckedIconName || 'done'}</i>
          )}
          {!checked && customUncheckedIconName && (
            <i className={classNames('material-icons', customUncheckedIconClassName, {
              [styles.checkIcon]: customUncheckedIconClassName === null,
              [styles.checkIconSmall]: size === 'small'
            })}>{customUncheckedIconName}</i>
          )}
        </div>
      </label>
      ))
}

CheckboxInput.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['small', 'large']),
  labelName: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  customCheckedIconName: PropTypes.string,
  customCheckedIconClassName: PropTypes.string,
  customCheckedClassName: PropTypes.string,
  customUncheckedIconName: PropTypes.string,
  customUncheckedIconClassName: PropTypes.string,
  customUncheckedClassName: PropTypes.string
}
