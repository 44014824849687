import { loader } from 'graphql.macro'

export default function dispatcherReceiptByTransaction ({ dataLoaders, variables }) {
  const {
    transactionId,
    saleType,
    sendTo = [],
    action
  } = variables
  let schema

  if (action.toUpperCase() === 'PRINT') {
    schema = loader('./dispatcherReceiptByTransactionPDF.graphql')
    return dataLoaders.dispatcher.graphQLClient.request(schema, {
      transactionId,
      saleType
    })
  }

  if (action.toUpperCase() === 'EMAIL') {
    schema = loader('./dispatcherReceiptByTransactionEmail.graphql')
    return dataLoaders.dispatcher.graphQLClient.request(schema, {
      transactionId,
      saleType,
      sendTo
    })
  }
}
