export const underscoreToCapital = (value) => {
  if (value && value.length > 0) {
    return value.replace(/_/g, ' ')
      .split(' ')
      .map(token => token[0].toUpperCase() + token.substr(1))
      .join(' ')
  }
  return ''
}

export const userInitials = (user) => {
  const firstName = user?.firstName ?? ''
  const lastName = user?.lastName ?? ''
  const formatUser = (name) => name.replace(/[^\x20-\x7E]/g, '')

  const firstInitial = formatUser(firstName).charAt(0).toUpperCase()
  const lastInitials = formatUser(lastName).charAt(0).toUpperCase() + formatUser(lastName).charAt(1)
  return firstInitial + lastInitials
}

export const commaDeliminate = (number) => new Intl.NumberFormat(
  'en-US',
  {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }
).format(number)

// use to format money without a comma for input type number
export const moneyUnformatted = (number) => (
  parseFloat(number).toFixed(2)
)

export const stringToTime = (value) => {
  if (value.length !== 5) return 'None'
  const timeTokens = value.split(':')
  let hours
  let suffix
  if (timeTokens[0] === '00') {
    hours = '12'
    suffix = 'AM'
  } else if (timeTokens[0] === '12') {
    hours = '12'
    suffix = 'PM'
  } else {
    const hours24 = parseInt(timeTokens[0], 10)
    hours = hours24 % 12
    suffix = hours24 >= 12 ? 'PM' : 'AM'
  }
  const minutes = timeTokens[1]
  return `${hours}:${minutes} ${suffix}`
}

export const enumToTitleCase = (str) => {
  return str.toLowerCase().split('_').map(function (item) {
    return item.charAt(0).toUpperCase() + item.substring(1)
  }).join(' ')
}

/**
 * Take a phonenumber of all numbers (no special characters or anything) and run it through a hair down montage
 * @param {str} phoneNumber a 10 digit phone number, no letters
 * @returns {str|null} (###) ###-####
 */
export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return null
  }

  const pn = phoneNumber.replace(/\D/g, '')
  return `(${pn.substring(0, 3)}) ${pn.substring(3, 6)}-${pn.substring(6, 10)}`
}

export function onlyNumbers (str) {
  return str.replace(/\D/g, '')
}

export const formatDollarAmount = (amount) => {
  return commaDeliminate(amount || 0)
}

export function truncateText (str, maxChars = 12) {
  if (str.length <= maxChars) {
    return str
  }

  return `${str.substring(0, maxChars)}...`
}

// taken from Wastepay
export function stripSpecialCharacters (str) {
  return str.replace(/[^a-zA-Z0-9 ]/g, '').trim()
}

export function leadingZero (num) {
  if (num < 10) return `0${num}`

  return `${num}`
}

export function buildCardName ({ cardName, nickname }) {
  if (nickname && nickname !== '') return `${nickname} - ${cardName.split('-')[1]}`

  return cardName
}
