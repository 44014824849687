import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import AuthenticatedRoute from './components/AuthenticatedRoute'
import RedirectQueryString from './components/RedirectQueryString'
import LoginPage from './pages/LoginPage'
import ForgotPasswordPage from './pages/ForgotPasswordPage'
import ImpersonationPage from './pages/ImpersonationPage'
import UserProfilePage from './pages/UserProfilePage'
import UserProfileEditPage from './pages/UserProfileEditPage'
import UserChangePasswordContainer from './pages/UserChangePasswordPage'
import FinishPasswordResetPage from './pages/FinishPasswordResetPage'
import HaulerProfilePage from './pages/hauler-profile-page'
import HaulerEditPage from './pages/HaulerEditPage'
import ClientDetailPage from './pages/client-details-page'
import ClientEditPage from './pages/client-edit-page'
import ClientsPage from './pages/clients-page'
import ClientCreatePage from './pages/client-create-page'
import ClientImportPage from './pages/ClientImportPage'
import ClientImportsPage from './pages/ClientImportsPage'
import DriverAssignmentSettingPage from './pages/driver-assignment-settings-page'
import SMSSettings from './pages/sms-settings-page'
import HaulerTeamIdContainer from './pages/hauler/team/_id/index/HaulerTeam_IdContainer'
import HaulerTeamIdEditContainer from './pages/hauler/team/_id/edit/HaulerTeam_IdEditContainer'
import HaulerTeamNewContainer from './pages/hauler/team/new/HaulerTeamNewContainer'
import HaulerTeamPage from './pages/hauler-team-page'
import TicketTypesPage from './pages/TicketTypesPage'
import CreateSitePage from './pages/create-site-page'
import CreateTicketType from './pages/create-ticket-type'
import EditTicketTypePage from './pages/edit-ticket-type-page'
import HaulerTicketTypesRequiredActionsContainer from './pages/hauler/ticket-types/required-actions/HaulerTicketTypesRequiredActionsContainer'
import HaulerResourceTypesContainer from './pages/hauler/resource-types/index/HaulerResourceTypesContainer'
import CreateResourceType from './pages/create-resource-type'
import EditResourceType from './pages/edit-resource-type'
import EditSitePage from './pages/edit-site-page'
import HaulerResourcesChartsContainer from './pages/hauler/resources/charts/HaulerResourcesChartsContainer'
import AssetCreatePage from './pages/asset-create-page'
import AssetDetailsPage from './pages/asset-details-page'
import AssetEditPage from './pages/asset-edit-page'
import HaulerJobsSearchContainer from './pages/hauler/jobs/search/HaulerJobsSearchContainer'
import NewJobPage from './pages/NewJobPage'
import HaulerJobsIdContainer from './pages/job-page'
import JobEditPage from './pages/JobEditPage'
import TicketsSearchPage from './pages/tickets-search-page'
import CreateTicketPage from './pages/create-ticket-page'
import TicketPage from './pages/ticket-page'
import InvoicePaymentPage from './pages/invoice-payment-page'
import DispatcherRefundPage from './pages/dispatcher-refund-page'
import WastepayRefundPage from './pages/wastepay-refund-page'
import DispatchPage from './pages/DispatchPage'
import NotificationsIndex from './pages/notifications/NotificationsIndex'
import BulkCreateTicketsPage from './pages/bulk-create-tickets-page'
import BulkEditTicketsPage from './pages/bulk-edit-tickets-page'
import EditTicketPage from './pages/edit-ticket-page'
import HaulerTicketsIdRescheduleContainer from './pages/hauler/tickets/_id/reschedule/HaulerTickets_IdRescheduleContainer'
import TicketAssignmentPage from './pages/ticket-assignment-page'
import RecentIndex from './pages/recent/RecentIndex'
import AfterAddTeamMemberContainer from './pages/shared/afterAddTeamMember/AfterAddTeamMemberContainer'
import HaulerSiteTypesIndexContainer from './pages/hauler/site-types/index/HaulerSiteTypesIndexContainer'
import HaulerSiteTypesNewContainer from './pages/hauler/site-types/new/HaulerSiteTypesNewContainer'
import HaulerSiteTypesIdEditContainer from './pages/hauler/site-types/_id/edit/HaulerSiteTypes_IdEditContainer'
import SitesPage from './pages/sites-page'
import SiteDetailsPage from './pages/site-details-page'
import FeeTypesPage from './pages/fee-types-page'
import NewFeeTypePage from './pages/NewFeeTypePage'
import EditFeeTypePage from './pages/edit-fee-type-page'
import InvoicesPage from './pages/invoices-page'
import InvoiceNewClientPage from './pages/invoice-new-client-page'
import InvoiceNewClientTicketsPage from './pages/InvoiceNewClientTicketsPage'
import InvoicePage from './pages/invoice-page'
import InvoiceEditPage from './pages/invoice-edit-page'
import ProFeaturesPage from './pages/pro/ProFeaturesPage'
import ProMapAssets from './pages/pro/ProMapAssets'
import ProMapTickets from './pages/pro/ProMapTickets'
import ReportListPage from './pages/reports/ReportListPage'
import ReportPage from './pages/reports/ReportPage'
import QboExportInvoiceConfirm from './pages/qbo-export-invoice-confirm'
import QuickbooksDisconnectPage from './pages/QuickbooksDisconnectPage'
import QuickbooksImportCompletePage from './pages/QuickbooksImportCompletePage'
import UserManual from './pages/user-manual/UserManual'
import InvoiceSettingsPage from './pages/invoice-settings-page'
import ResourceImportPage from './pages/ResourceImportPage'
import SignUpPage from './pages/SignUpPage'
import ResourceImportsPage from './pages/ResourceImportsPage'
import QuickbooksSettingsPage from './pages/quickbooks-settings-page'
import QuickbooksImportPage from './pages/quickbooks-import-page'
import ResourcesSearchPage from './pages/ResourcesSearchPage'
import CustomerInvoicePage from './pages/client-invoice-page'
import './styles/app.scss'
import { ROUTES } from './routes'

export default function Router () {
  return (
    <Switch>
      <AuthenticatedRoute exact path={ROUTES.home} component={RecentIndex} />
      <AuthenticatedRoute exact path={ROUTES.userProfile} component={UserProfilePage} />
      <AuthenticatedRoute exact path={ROUTES.userProfileEdit} component={UserProfileEditPage} />
      <AuthenticatedRoute exact path={ROUTES.changePassword} component={UserChangePasswordContainer} />
      <AuthenticatedRoute exact path={ROUTES.haulerSettings} component={HaulerProfilePage} />
      <AuthenticatedRoute exact path='/hauler/edit' component={HaulerEditPage} />
      <AuthenticatedRoute exact path={ROUTES.haulerTeam} component={HaulerTeamPage} />
      <AuthenticatedRoute exact path={ROUTES.newTeamMember} component={HaulerTeamNewContainer} />
      <AuthenticatedRoute exact path='/hauler/team/new/after-add-team-member' component={AfterAddTeamMemberContainer} />
      <AuthenticatedRoute exact path='/hauler/team/:id' component={HaulerTeamIdContainer} />
      <AuthenticatedRoute exact path='/hauler/team/:id/edit' component={HaulerTeamIdEditContainer} />

      <Redirect eact from={ROUTES.ticketTypesOld} to={ROUTES.ticketTypes} />
      <AuthenticatedRoute exact path={ROUTES.ticketTypes} component={TicketTypesPage} />

      <AuthenticatedRoute exact path={ROUTES.newTicketType} component={CreateTicketType} />
      <AuthenticatedRoute exact path={ROUTES.editTicketType} component={EditTicketTypePage} />

      <Redirect from={ROUTES.ticketTypesRequiredActionsOld} to={ROUTES.ticketTypesRequiredActions} />
      <AuthenticatedRoute exact path={ROUTES.ticketTypesRequiredActions} component={HaulerTicketTypesRequiredActionsContainer} />

      <Redirect from={ROUTES.resourceTypesOld} to={ROUTES.resourceTypes} />
      <AuthenticatedRoute exact path={ROUTES.resourceTypes} component={HaulerResourceTypesContainer} />

      <Redirect exact from={ROUTES.createResourceTypeOld} to={ROUTES.createResourceType} />
      <AuthenticatedRoute exact path={ROUTES.createResourceType} component={CreateResourceType} />

      <Redirect exact from={ROUTES.editResourceTypeOld} to={ROUTES.editResourceType} />
      <AuthenticatedRoute exact path={ROUTES.editResourceType} component={EditResourceType} />

      <Redirect exact from={ROUTES.clientsOld} to={ROUTES.clients} />
      <AuthenticatedRoute exact path={ROUTES.clients} component={ClientsPage} />

      <Redirect exact from={ROUTES.clientCreateOld} to={ROUTES.clientCreate} />
      <AuthenticatedRoute exact path={ROUTES.clientCreate} component={ClientCreatePage} />

      <Redirect exact from={ROUTES.clientOld} to={ROUTES.client} />
      <AuthenticatedRoute exact path={ROUTES.client} component={ClientDetailPage} />

      <Redirect exact from={ROUTES.clientEditOld} to={ROUTES.clientEdit} />
      <AuthenticatedRoute exact path={ROUTES.clientEdit} component={ClientEditPage} />

      <Redirect exact from={ROUTES.clientImportsOld} to={ROUTES.clientImports} />
      <AuthenticatedRoute exact path={ROUTES.clientImports} component={ClientImportsPage} />

      <Redirect exact from={ROUTES.clientImportOld} to={ROUTES.clientImport} />
      <AuthenticatedRoute exact path={ROUTES.clientImport} component={ClientImportPage} />

      <AuthenticatedRoute exact path={ROUTES.driverAssignmentSettings} component={DriverAssignmentSettingPage} />
      <AuthenticatedRoute exact path={ROUTES.smsSettings} component={SMSSettings} />

      <Redirect exact from={ROUTES.resourceImportsOld} to={ROUTES.resourceImports} />
      <AuthenticatedRoute exact path={ROUTES.resourceImports} component={ResourceImportsPage} />

      <Redirect exact from={ROUTES.resourceImportOld} to={ROUTES.resourceImport} />
      <AuthenticatedRoute exact path={ROUTES.resourceImport} component={ResourceImportPage} />

      <AuthenticatedRoute exact path={ROUTES.resourceCharts} component={HaulerResourcesChartsContainer} />

      <Redirect exact from={ROUTES.resourcesOld} to={ROUTES.resources} />
      <AuthenticatedRoute exact path={ROUTES.resources} component={ResourcesSearchPage} />

      <AuthenticatedRoute exact path={ROUTES.createResource} component={AssetCreatePage} />

      <Redirect exact from={ROUTES.resourceOld} to={ROUTES.resource} />
      <AuthenticatedRoute exact path={ROUTES.resource} component={AssetDetailsPage} />

      <Redirect exact from={ROUTES.editResourceOld} to={ROUTES.editResource} />
      <AuthenticatedRoute exact path={ROUTES.editResource} component={AssetEditPage} />

      <Redirect exact from={ROUTES.jobsOld} to={ROUTES.jobs} />
      <AuthenticatedRoute exact path={ROUTES.jobs} component={HaulerJobsSearchContainer} />

      <RedirectQueryString exact from={ROUTES.newJobOld} to={ROUTES.newJob} />
      <AuthenticatedRoute exact path={ROUTES.newJob} component={NewJobPage} />

      <Redirect exact from={ROUTES.jobOld} to={ROUTES.job} />
      <AuthenticatedRoute exact path={ROUTES.job} component={HaulerJobsIdContainer} />

      <Redirect exact from={ROUTES.editJobOld} to={ROUTES.editJob} />
      <AuthenticatedRoute exact path={ROUTES.editJob} component={JobEditPage} />

      <RedirectQueryString exact from={ROUTES.createTicketOld} to={ROUTES.createTicket} />
      <AuthenticatedRoute exact path={ROUTES.createTicket} component={CreateTicketPage} />

      <AuthenticatedRoute exact path='/hauler/tickets/search' component={TicketsSearchPage} />
      <AuthenticatedRoute exact path='/hauler/tickets/search/overdue' component={TicketsSearchPage} />

      <Redirect exact from={ROUTES.ticketOld} to={ROUTES.ticket} />
      <AuthenticatedRoute exact path={ROUTES.ticket} component={TicketPage} />

      <AuthenticatedRoute exact path={ROUTES.invoicePayment} component={InvoicePaymentPage} />
      <AuthenticatedRoute exact path={ROUTES.wastepayRefund} component={WastepayRefundPage} />
      <AuthenticatedRoute exact path={ROUTES.dispatcherRefund} component={DispatcherRefundPage} />

      <RedirectQueryString exact from={ROUTES.bulkCreateTicketsOld} to={ROUTES.bulkCreateTickets} />
      <AuthenticatedRoute exact path={ROUTES.bulkCreateTickets} component={BulkCreateTicketsPage} />

      <Redirect exact from={ROUTES.bulkEditTicketsPageOld} to={ROUTES.bulkEditTicketsPage} />
      <AuthenticatedRoute exact path={ROUTES.bulkEditTicketsPage} component={BulkEditTicketsPage} />

      <Redirect exact from={ROUTES.editTicketOld} to={ROUTES.editTicket} />
      <AuthenticatedRoute exact path={ROUTES.editTicket} component={EditTicketPage} />

      <AuthenticatedRoute exact path='/hauler/tickets/:id/reschedule' component={HaulerTicketsIdRescheduleContainer} />

      <Redirect exact from={ROUTES.assignTicketOld} to={ROUTES.assignTicket} />
      <AuthenticatedRoute exact path={ROUTES.assignTicket} component={TicketAssignmentPage} />

      <AuthenticatedRoute exact path={ROUTES.dispatch} component={DispatchPage} />
      <AuthenticatedRoute exact path='/notifications' component={NotificationsIndex} />
      <AuthenticatedRoute exact path='/recent' component={RecentIndex} />
      <AuthenticatedRoute exact path='/hauler/site-types' component={HaulerSiteTypesIndexContainer} />
      <AuthenticatedRoute exact path='/hauler/site-types/new' component={HaulerSiteTypesNewContainer} />
      <AuthenticatedRoute exact path='/hauler/site-types/:id/edit' component={HaulerSiteTypesIdEditContainer} />

      <Redirect exact from={ROUTES.newSiteOld} to={ROUTES.newSite} />
      <AuthenticatedRoute exact path={ROUTES.newSite} component={CreateSitePage} />

      <Redirect exact from={ROUTES.sitesSearchOld} to={ROUTES.sitesSearch} />
      <AuthenticatedRoute exact path={ROUTES.sitesSearch} component={SitesPage} />

      <Redirect exact from={ROUTES.editSiteOld} to={ROUTES.editSite} />
      <AuthenticatedRoute exact path={ROUTES.editSite} component={EditSitePage} />

      <Redirect exact from={ROUTES.siteOld} to={ROUTES.site} />
      <AuthenticatedRoute exact path={ROUTES.site} component={SiteDetailsPage} />

      <AuthenticatedRoute exact path={ROUTES.pro} component={ProFeaturesPage} />
      <AuthenticatedRoute exact path={ROUTES.proMapAssets} component={ProMapAssets} />
      <AuthenticatedRoute exact path={ROUTES.proMapTickets} component={ProMapTickets} />

      <Redirect exact from={ROUTES.feeTypesOld} to={ROUTES.feeTypes} />
      <AuthenticatedRoute exact path={ROUTES.feeTypes} component={FeeTypesPage} />

      <Redirect exact from={ROUTES.newFeeTypeOld} to={ROUTES.newFeeType} />
      <AuthenticatedRoute exact path={ROUTES.newFeeType} component={NewFeeTypePage} />

      <Redirect exact from={ROUTES.editFeeTypeOld} to={ROUTES.editFeeType} />
      <AuthenticatedRoute exact path={ROUTES.editFeeType} component={EditFeeTypePage} />

      <Redirect exact from={ROUTES.quickbooksSettingsOld} to={ROUTES.quickbooksSettings} />
      <AuthenticatedRoute exact path={ROUTES.quickbooksSettings} component={QuickbooksSettingsPage} />

      <Redirect exact from={ROUTES.quickbooksDisconnectOld} to={ROUTES.quickbooksDisconnect} />
      <AuthenticatedRoute exact path={ROUTES.quickbooksDisconnect} component={QuickbooksDisconnectPage} />

      <Redirect exact from={ROUTES.quickbooksImportOld} to={ROUTES.quickbooksImport} />
      <AuthenticatedRoute exact path={ROUTES.quickbooksImport} component={QuickbooksImportPage} />

      <Redirect exact from={ROUTES.quickbooksImportResultOld} to={ROUTES.quickbooksImportResult} />
      <AuthenticatedRoute exact path={ROUTES.quickbooksImportResult} component={QuickbooksImportCompletePage} />

      <Redirect exact from={ROUTES.invoicesOld} to={ROUTES.invoices} />
      <AuthenticatedRoute exact path={ROUTES.invoices} component={InvoicesPage} />

      <Redirect exact from={ROUTES.invoiceNewClientOld} to={ROUTES.invoiceNewClientOld} />
      <AuthenticatedRoute exact path={ROUTES.invoiceNewClient} component={InvoiceNewClientPage} />

      <Redirect exact from={ROUTES.invoiceNewClientsTicketsOld} to={ROUTES.invoiceNewClientsTickets} />
      <AuthenticatedRoute exact path={ROUTES.invoiceNewClientsTickets} component={InvoiceNewClientTicketsPage} />

      <AuthenticatedRoute exact path={ROUTES.invoiceEditAddTickets} component={InvoiceNewClientTicketsPage} />

      <Redirect exact from={ROUTES.invoiceOld} to={ROUTES.invoice} />
      <AuthenticatedRoute exact path={ROUTES.invoice} component={InvoicePage} />

      <AuthenticatedRoute exact path={ROUTES.invoiceEdit} component={InvoiceEditPage} />

      <AuthenticatedRoute exact path={ROUTES.invoicesExport} component={QboExportInvoiceConfirm} />

      <AuthenticatedRoute exact path='/reports' component={ReportListPage} />
      <AuthenticatedRoute exact path='/reports/:id' component={ReportPage} />

      <AuthenticatedRoute exact path='/docs/usermanual' component={UserManual} />

      <Redirect exact from={ROUTES.wastePaySettings} to={ROUTES.invoiceSettings} />
      <AuthenticatedRoute exact path={ROUTES.invoiceSettings} component={InvoiceSettingsPage} />

      {/* This is where the unauthenticated routes will go */}
      <Route exact path={ROUTES.impersonation} component={ImpersonationPage} />
      <Route exact path={ROUTES.forgotPassword} component={ForgotPasswordPage} />
      <Route exact path={ROUTES.finishForgotPassword} component={FinishPasswordResetPage} />
      <Route exact path={ROUTES.customerInvoice} component={CustomerInvoicePage} />
      <Route exact path='/login' component={LoginPage} />
      <Route exact path='/signup' component={SignUpPage} />

      <Redirect to={ROUTES.home} />
    </Switch>
  )
}
