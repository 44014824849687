import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

export default class UserRoleIcon extends Component {
  buildIcon (role) {
    return <i className='material-icons'>{role === 'hauler_admin' ? 'computer' : 'local_shipping'}</i>
  }

  render () {
    const { role, linkPath } = this.props
    if (!role) { return null }
    if (linkPath) {
      return <Link to={linkPath}>{this.buildIcon(role)}</Link>
    }
    return this.buildIcon(role)
  }
}

UserRoleIcon.propTypes = {
  linkPath: PropTypes.string,
  role: PropTypes.string
}
