import { loader } from 'graphql.macro'

export default function ticketType ({ dataLoaders, queryKey }) {
  const [, haulerId, id] = queryKey
  const schema = loader('./ticketType.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(schema, {
    haulerId,
    id
  })
}
