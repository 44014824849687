import React from 'react'
import PropTypes from 'prop-types'

export default function StartTicketButton ({ onClick, ticket, disabled = false }) {
  return (
    <>
      {ticket.status === 'open' && ticket.driverId
        ? (
          <button
            type='button'
            className='dis-btn dis-btn-lg dis-btn-alert'
            onClick={onClick}
            disabled={disabled}>
            Start Ticket
            <i className='material-icons dis-btn-icon'>touch_app</i>
          </button>)
        : null}
    </>
  )
}

StartTicketButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  ticket: PropTypes.shape({
    driverId: PropTypes.number,
    id: PropTypes.string,
    status: PropTypes.string
  }).isRequired,
  disabled: PropTypes.bool
}
