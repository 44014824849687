import { EmailValidator } from '../../utilities/commonValidators'

export function validate (values) {
  const errors = {}

  if (!values.firstName) {
    errors.firstName = 'Required'
  }
  if (!values.lastName) {
    errors.lastName = 'Required'
  }
  if (!values.email) {
    errors.email = 'Required'
  }

  if (values.email) {
    if (!EmailValidator.test(values.email)) {
      errors.email = 'Invalid Email'
    }
  }

  if (!values.streetAddress) {
    errors.streetAddress = 'Required'
  }

  if (!values.city) {
    errors.city = 'Required'
  }

  if (!values.state) {
    errors.state = 'Required'
  }

  if (!values.zip) {
    errors.zip = 'Required'
  }

  return errors
}
