import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import NormalLayoutContainer from '../../../shared/NormalLayoutContainer'
import { PageTitle } from '../../../../components/page-title'
import { Spinner } from '../../../shared/Spinner'
import createUserMutation from '../../../../graphql/mutations/createUser'
import useMutation from '../../../../hooks/useMutation'
import { captureErrorAndNotify } from '../../../../utilities/errorHandlers'
import CreateHaulerTeamMemberForm from './components/CreateHaulerTeamMemberForm'
import CreateHaulerTeamMemberExplanation from './components/CreateHaulerTeamMemberExplanation'
import { intercomConnection } from '../../../../intercomConnection'

export default function HaulerTeamNewContainer () {
  const history = useHistory()

  const { createHaulerTeamMemberForm, haulerTeam } = useSelector(
    ({ form, haulerTeam }) => ({
      createHaulerTeamMemberForm: form.createHaulerTeamMemberForm,
      haulerTeam
    }))

  const { mutateAsync: createUser, isLoading: isCreatingUser } = useMutation(createUserMutation, {
    async onSuccess () {
      intercomConnection.trackEvent('user_created', {})
      history.push('/hauler/team/new/after-add-team-member')
    },
    onError (error) {
      captureErrorAndNotify(error, 'Error creating user')
    }
  })
  const cancelCreateNewTeamMember = () => {
    // TODO: Route to new Team Member Edit Profile Page
    history.push('/hauler/team/')
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    createUser(createHaulerTeamMemberForm.values)
  }

  return (
    <div>
      <NormalLayoutContainer showBackLink>
        <div className='container-fluid'>
          <PageTitle title='Create a New Team Member'>
            <button
              className='btn btn-link pull-right push-down-super-small'
              onClick={cancelCreateNewTeamMember}>Cancel
            </button>
          </PageTitle>

          <Spinner isFetching={haulerTeam.isFetching}>
            <div className='row'>
              <div className='col-xs-12 col-sm-12 col-md-7 col-lg-7'>
                <CreateHaulerTeamMemberForm
                  isFetching={isCreatingUser}
                  onSubmit={handleFormSubmit}
                />
              </div>
              <div className='col-xs-12 col-md-4 col-md-offset-1'>
                <CreateHaulerTeamMemberExplanation />
              </div>
            </div>
          </Spinner>

        </div>
      </NormalLayoutContainer>
    </div>
  )
}
