import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import { useHistory } from 'react-router-dom'
import { determineData, getElementAtEvent, determineOptions } from './ResourcesAgingGraphUtils'

export default function ResourcesAgingGraph ({ locationAgeCount }) {
  const history = useHistory()

  const handleElementsClick = useCallback(
    (element) => getElementAtEvent(element, history),
    [history]
  )

  const data = determineData(locationAgeCount)
  const options = determineOptions(locationAgeCount.reduce((total, count) => total + count))

  return (
    <div className='text-center'>
      <h3>Days Since Location Change</h3>
      <div className='push-down-very-small'>
        <Bar
          data={data}
          options={options}
          width={1000}
          height={500}
          onElementsClick={handleElementsClick}
        />
      </div>
    </div>
  )
}

ResourcesAgingGraph.propTypes = {
  locationAgeCount: PropTypes.array
}
