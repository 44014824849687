import { loader } from 'graphql.macro'

export default async function disconnectWastepay ({ dataLoaders, variables }) {
  const schema = loader('./disconnectWastepay.graphql')
  const response = dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      haulerId: variables.haulerId
    }
  })
  return response.disconnectWastepay
}
