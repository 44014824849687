import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import NoResults from '../../pages/shared/NoResults'
import NotesCell from './NotesCell'
import { prettyAddress } from '../../utilities/locationUtilities'
import useQuery from '../../hooks/useQuery'
import QUERY_KEYS from '../../graphql/queryKeys'
import clientImportRowsQuery from '../../graphql/queries/clientImportRows'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import CursorPagination from '../cursor-pagination'
import { ObjectParam, useQueryParams } from 'use-query-params'
import { Spinner } from '../../pages/shared/Spinner'

export default function ValidatedClientImportRowsTable ({ importId, status }) {
  const [queryParams, setQueryParams] = useQueryParams({ pager: ObjectParam })

  const { data: clientImportRowsData, isLoading } = useQuery(
    [QUERY_KEYS.clientImportRows, {
      clientImportId: importId,
      status,
      first: queryParams.pager?.direction === undefined || queryParams.pager?.direction === 'after' ? 10 : undefined,
      last: queryParams.pager?.direction === 'before' ? 10 : undefined,
      after: queryParams.pager?.direction === 'after' ? queryParams.pager?.cursor : undefined,
      before: queryParams.pager?.direction === 'before' ? queryParams.pager?.cursor : undefined
    }],
    clientImportRowsQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Failed to retrieve data')
      },
      // We don't usually consider data to not be stale, BUT in this scenario this query gets loaded from a few components within a short time span
      // AND this data never changes. Once an import starts, its rows do not change.
      staleTime: 5 * 1000 * 60
    })

  function handlePageRequest (direction, cursor) {
    setQueryParams({ pager: { direction, cursor } })
  }

  if (isLoading) {
    return <Spinner isFetching />
  }

  return (
    <div className='table-responsive'>
      <table className='table table-striped'>
        <thead>
          <tr>
            <th width='10%'>Status</th>
            <th width='10%'>Account Notes</th>
            <th width='15%'>Account Name</th>
            <th width='10%'>Contact Name</th>
            <th width='15%'>Billing Address</th>
            <th width='10%'>Contact Email</th>
            <th width='10%'>Contact Phone</th>
            <th width='10%'>Billing Details</th>
          </tr>
        </thead>
        <tbody>
          {clientImportRowsData.clientImportRows.nodes.length === 0 && <tr><td colSpan='7'><NoResults /></td></tr>}
          {clientImportRowsData.clientImportRows.nodes.map(data => (
            <React.Fragment key={data.id}>
              <tr>
                <td className='flex justify-center'>
                  <span className={cn('flex justify-center items-center py-2 px-6 bg-opacity-90 rounded-full h-8 font-semibold', {
                    'bg-red text-white': status === 'DATA_ERRORS',
                    'bg-green': status === 'READY_FOR_IMPORT'
                  })}>
                    {status === 'READY_FOR_IMPORT' ? 'SUCCESS' : 'ERROR'}
                  </span>
                </td>
                <td><NotesCell notes={data.notes} /></td>
                <td>{data.name}</td>
                <td>{(data.firstName || data.lastName) ? `${data.firstName} ${data.lastName}` : 'None'}</td>
                <td>{prettyAddress(data)}</td>
                <td>{data.email}</td>
                <td>{data.primaryPhone}</td>
                <td><NotesCell notes={data.billingDetails} /></td>
              </tr>
              {status === 'DATA_ERRORS' && (
                <tr>
                  <td colSpan='8' style={{ backgroundColor: '#ffe3e7' }}>
                    <div className='flex justify-start items-center'>
                      <i className='material-icons text-red opacity-80 text-3xl mr-4'>info</i>
                      <span className='font-medium'>{data.dataError}</span>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <CursorPagination
        onPageRequest={handlePageRequest}
        pageInfo={clientImportRowsData.clientImportRows.pageInfo}
        totalCount={clientImportRowsData.clientImportRows.totalCount}
      />
    </div>
  )
}

ValidatedClientImportRowsTable.propTypes = {
  importId: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['READY_FOR_IMPORT', 'DATA_ERRORS']).isRequired
}
