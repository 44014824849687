import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

export function TextInput ({ input, type, label, placeholder, maxLength, meta: { touched, error }, disabled, className, autoComplete }) {
  const formGroupClasses = ['form-group', className]
  if (touched && error) {
    formGroupClasses.push('has-error')
  }
  return (
    <div className={cn(formGroupClasses)}>
      {label && <label className='control-label'>{label}</label>}
      <input
        disabled={disabled}
        type={type || 'text'}
        className='form-control'
        autoComplete={autoComplete || 'off'}
        maxLength={maxLength}
        placeholder={placeholder}
        {...input}
      />
      {(touched && error) && <h6>{error}</h6> }
    </div>
  )
}

TextInput.propTypes = {
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  maxLength: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  }),
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  autoComplete: PropTypes.string
}
