import React from 'react'
import PropTypes from 'prop-types'
import { arrayMove } from 'react-movable'
import classNames from 'classnames'

export const DriverListSortableItem = ({
  value,
  index,
  isDragged,
  setUserList,
  userList,
  viewHidden
}) => {
  const lastVisibleIndex = userList.findIndex(user => user.isHidden)

  function setHidden (index, value) {
    setUserList(prevValue => {
      prevValue[index].isHidden = !value
      return arrayMove(prevValue, index, value ? lastVisibleIndex || 0 : prevValue.length - 1)
    })
  }

  function moveToTop (index) {
    setUserList(arrayMove(userList, index, 0))
  }

  return (
    <div className={classNames('flex flex-row flex-nowrap bg-white shadow p-2 mb-2 driver-sort_sortable-item', {
      hidden: value.isHidden && !viewHidden
    })}>
      <i
        data-movable-handle
        className={classNames('material-icons', {
          'opacity-50': value.isHidden,
          'cursor-grab': !isDragged,
          'cursor-grabbing': isDragged
        })}>
        drag_handle
      </i>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <i
        className={classNames('material-icons text-base top-2 relative text-blue', {
          hidden: index === 0,
          'opacity-50': value.isHidden
        })}
        tabIndex={0}
        role='button'
        onClick={() => {
          if (!value.isHidden) {
            moveToTop(index)
          }
        }}>
        vertical_align_top
      </i>
      <div className={classNames({
        'ml-4': index === 0,
        'ml-1': index !== 0
      })}>
        {value.firstName} {value.lastName}
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <i className={classNames('material-icons text-3xl relative top-0.5 ml-auto', {
        'opacity-50': value.isHidden
      })}
        role='button'
        tabIndex={0}
        onClick={() => setHidden(index, value.isHidden)}>
        {value.isHidden ? 'visibility_off' : 'visibility'}
      </i>
    </div>
  )
}

DriverListSortableItem.propTypes = {
  value: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    isHidden: PropTypes.bool.isRequired
  }),
  index: PropTypes.number.isRequired,
  isDragged: PropTypes.bool.isRequired,
  setUserList: PropTypes.func.isRequired,
  userList: PropTypes.arrayOf(PropTypes.shape({
    isHidden: PropTypes.bool.isRequired
  })),
  viewHidden: PropTypes.bool.isRequired
}
