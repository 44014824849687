import moment from 'moment'

export const getElementAtEvent = (element, history) => {
  if (element[0]) {
    let params = ''
    switch (element[0]._index) {
      case 0:
        params = 'maxIdleDays=3'
        break
      case 1:
        params = 'maxIdleDays=7&minIdleDays=4'
        break
      case 2:
        params = 'maxIdleDays=14&minIdleDays=8'
        break
      case 3:
        params = 'maxIdleDays=21&minIdleDays=15'
        break
      case 4:
        params = 'maxIdleDays=30&minIdleDays=22'
        break
      case 5:
        params = 'minIdleDays=31'
        break
      case 6:
        break
      default:
        break
    }
    if (params.length) {
      history.push(`/assets?${params}`)
    } else {
      alert('We can\'t filter on these parameters.')
    }
  }
}

export const determineResourcesByAge = (resources) => (
  resources.reduce((acc, resource) => {
    if (!resource.site_id && !resource.job_id && !resource.place_id) {
      acc[6] += 1 // eslint-disable-line
    } else {
      const diff = moment().diff(moment(resource.last_moved), 'days')
      if (diff <= 3) {
        acc[0] += 1 // eslint-disable-line
      } else if (diff <= 7) {
        acc[1] += 1 // eslint-disable-line
      } else if (diff <= 14) {
        acc[2] += 1 // eslint-disable-line
      } else if (diff <= 21) {
        acc[3] += 1 // eslint-disable-line
      } else if (diff <= 30) {
        acc[4] += 1 // eslint-disable-line
      } else {
        acc[5] += 1 // eslint-disable-line
      }
    }
    return acc
  }, [0, 0, 0, 0, 0, 0, 0])
)

export const determineData = (resourcesByAge) => (
  {
    labels: ['3 days', '7 days', '14 days', '21 days', '30 days', '30+ days', 'No Set Location'],
    datasets: [
      {
        label: 'Assets',
        data: resourcesByAge,
        backgroundColor: '#33CA80',
        borderColor: '#F5F5F5',
        borderWidth: 0
      }
    ]
  }
)

export const determineOptions = (totalCount) => (
  {
    scales: {
      yAxes: [
        {
          ticks: { stepSize: parseInt((totalCount / 200), 10) ? (totalCount / 25) : 5 },
          stacked: true
        }
      ]
    },
    maintainAspectRatio: true,
    tooltips: {
      mode: 'x-axis'
    }
  }
)
