import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import TicketStatusLabel from './TicketStatusLabel'
import { useSelector } from 'react-redux'
import dayjs from '../utilities/dayjs'

export default function DispatchInfoBar ({ tickets, date, lastRefreshed }) {
  const { accessToken } = useSelector(({ user: { accessToken } }) => ({ accessToken }))

  const ticketsCount = tickets.length
  const {
    openTickets,
    enRouteTickets,
    completedTickets,
    cancelledTickets,
    flaggedTickets
  } = useMemo(function classifyTickets () {
    let openTickets = 0
    let enRouteTickets = 0
    let completedTickets = 0
    let cancelledTickets = 0
    let flaggedTickets = 0

    tickets.forEach(ticket => {
      switch (ticket.status) {
        case 'open':
          openTickets += 1
          break
        case 'en_route':
          enRouteTickets += 1
          break
        case 'completed':
          completedTickets += 1
          break
        case 'cancelled':
          cancelledTickets += 1
          break
        default:
          break
      }
      if (ticket.flagged) {
        flaggedTickets += 1
      }
    })

    return { openTickets, enRouteTickets, completedTickets, cancelledTickets, flaggedTickets }
  }, [tickets])

  return (
    <div id='dispatch-info-bar-container'
      className='navbar navbar-inverse navbar-fixed-bottom panel panel-default col-md-7 col-md-offset-5 col-lg-9 col-lg-offset-3'>

      <div className='badge text-center thumbster-grey-md-dark-bg'>
        <strong>
          Total
          <br />
          <div className='font-size-1-5em'>
            {ticketsCount}
          </div>
        </strong>
      </div>

      <div className='text-center hidden-xs hidden-sm hidden-md'>
        <TicketStatusLabel status='open' />
        <br />
        {openTickets}
      </div>

      <div className='text-center hidden-xs hidden-sm hidden-md'>
        <TicketStatusLabel status='en_route' />
        <br />
        {enRouteTickets}
      </div>

      <div className='text-center hidden-xs hidden-sm hidden-md'>
        <TicketStatusLabel status='completed' />
        <br />
        {completedTickets}
      </div>

      <div className='text-center hidden-xs hidden-sm hidden-md'>
        <TicketStatusLabel status='cancelled' />
        <br />
        {cancelledTickets}
      </div>

      <div className='badge text-center thumbster-gray-super-light-bg'>
        <i className='material-icons text-danger'>flag</i>
        <br />
        <strong style={{ color: '#2B2C2B' }}>
          {flaggedTickets}
        </strong>
      </div>

      <a href={`${process.env.REACT_APP_NEW_API_URL}/tickets/dispatch_export?date=${date}&authorization=${accessToken}`}
        className='pull-right'>
        Download Today&apos;s Tickets
      </a>

      <div className='text-center'>
        <em>Tickets Last Updated:</em>
        <br />
        {dayjs(lastRefreshed).format('h:mm:ss a')}
      </div>
    </div>
  )
}

DispatchInfoBar.propTypes = {
  date: PropTypes.string.isRequired,
  lastRefreshed: PropTypes.string,
  tickets: PropTypes.array.isRequired
}
