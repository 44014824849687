import { intercomConnection } from '../../intercomConnection'
import getHeaders from '../../utilities/getHeaders'

export default async function updateUser ({ dataLoaders, variables }) {
  const response = await dataLoaders.thumbster.axios.patch(`/hauler/users/${variables.id}`,
    {
      first_name: variables.firstName,
      last_name: variables.lastName,
      email: variables.email,
      primary_phone: variables.prettyPrimaryPhone,
      role: variables.role
    },
    getHeaders()
  )

  intercomConnection.trackEvent('user_updated', {})
  return response.data
}
