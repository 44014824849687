import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import ExtraViewsContainer from './ExtraViewsContainer'
import InventoryWidget from './inventory-widget/InventoryWidget'
import { useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'
import { userInitials, commaDeliminate } from '../utilities/stringUtilities'
import { place, filter, resourceRectangle, personPinCircle, siteCircle } from './icons'
import dayjs from '../utilities/dayjs'
import { generatePath } from 'react-router-dom'
import { ROUTES } from '../routes'

export default function DispatchMap ({
  tickets,
  users,
  resources = [],
  showResources,
  onToggleResources,
  sites = [],
  showSites,
  onToggleSites,
  hoverTicketId,
  hoverUserId,
  onLocationDetailChange
}) {
  const { hauler } = useSelector(({ user }) => ({
    hauler: user.hauler
  }))
  const [map, setMap] = useState()
  const [ticketMarkers, setTicketMarkers] = useState({})
  const [userMarkers, setUserMarkers] = useState({})
  const [resourceMarkers, setResourceMarkers] = useState({})
  const [siteMarkers, setSiteMarkers] = useState({})

  const ticketsPositionMap = useMemo(() => {
    return tickets.reduce((acc, ticket) => {
      if (!ticket.job) return acc

      if (acc[`${ticket.job.latitude},${ticket.job.longitude}`]) {
        acc[`${ticket.job.latitude},${ticket.job.longitude}`].tickets = [...acc[`${ticket.job.latitude},${ticket.job.longitude}`].tickets, ticket]
        acc[`${ticket.job.latitude},${ticket.job.longitude}`].hovered = acc[`${ticket.job.latitude},${ticket.job.longitude}`].hovered || isHovered(hoverTicketId, hoverUserId, ticket)
      } else {
        acc = {
          ...acc,
          [`${ticket.job.latitude},${ticket.job.longitude}`]: {
            latitude: ticket.job.latitude,
            longitude: ticket.job.longitude,
            tickets: [ticket],
            hovered: isHovered(hoverTicketId, hoverUserId, ticket),
            onLocationDetailChange
          }
        }
      }
      return acc
    }, {})
  }, [tickets, hoverTicketId, hoverUserId, onLocationDetailChange])

  const usersPositionMap = useMemo(() => {
    return users.reduce((acc, user) => ({
      ...acc,
      [user.id]: user
    }), {})
  }, [users])

  const resourcesPositionMap = useMemo(() => {
    return resources.reduce((acc, resource) => {
      const placeId = resource.currentLocation.placeId
      if (!placeId) return acc

      if (!acc[placeId]) {
        acc = {
          ...acc,
          [placeId]: {
            latitude: resource.currentLocation.latitude,
            longitude: resource.currentLocation.longitude,
            resources: [],
            shortCode: resource.resourceType.shortCode
          }
        }
      }

      acc[placeId].resources = [...acc[placeId].resources, resource]

      return acc
    }, {})
  }, [resources])

  const sitesPositionMap = useMemo(() => {
    return sites.reduce((acc, site) => {
      const placeId = site.location.placeId
      if (!placeId) return acc

      if (!acc[placeId]) {
        acc = {
          ...acc,
          [placeId]: {
            latitude: site.location.latitude,
            longitude: site.location.longitude,
            sites: [],
            shortCode: site.siteType.shortCode
          }
        }
      }

      acc[placeId].sites = [...acc[placeId].sites, site]

      return acc
    }, {})
  }, [sites])

  useEffect(function setupMap () {
    setMap(new google.maps.Map(document.getElementById('map'), {
      center: { lat: hauler.latitude, lng: hauler.longitude },
      zoom: 9,
      styles: [
        {
          featureType: 'landscape.natural',
          stylers: [
            {
              saturation: -50
            }
          ]
        },
        {
          featureType: 'road.highway',
          stylers: [
            {
              saturation: -35
            }
          ]
        },
        {
          featureType: 'water',
          stylers: [
            {
              saturation: -35
            }
          ]
        }
      ]
    }))
  }, [hauler.latitude, hauler.longitude])

  useEffect(function updateTicketMarkers () {
    updateMarkers({
      positionMap: ticketsPositionMap,
      existingMarkers: ticketMarkers,
      map,
      setMarkersFn: setTicketMarkers,
      shouldMarkerExistFn: shouldTicketMarkerExist,
      shouldUpdateMarkerFn: shouldUpdateTicketMarker,
      createMarkerFn: createTicketMarker,
      updateMarkerFn: updateTicketMarker
    })
  }, [ticketsPositionMap, ticketMarkers, map])

  useEffect(function updateUserMarkers () {
    updateMarkers({
      positionMap: usersPositionMap,
      existingMarkers: userMarkers,
      map,
      setMarkersFn: setUserMarkers,
      shouldMarkerExistFn: shouldUserMarkerExist,
      shouldUpdateMarkerFn: shouldUpdateUserMarker,
      createMarkerFn: createUserMarker,
      updateMarkerFn: updateUserMarker
    })
  }, [usersPositionMap, userMarkers, map])

  useEffect(function updateResourceMarkers () {
    updateMarkers({
      positionMap: showResources ? resourcesPositionMap : {},
      existingMarkers: resourceMarkers,
      map,
      setMarkersFn: setResourceMarkers,
      shouldMarkerExistFn: shouldResourceMarkerExist,
      shouldUpdateMarkerFn: shouldUpdateResourceMarker,
      createMarkerFn: createResourceMarker,
      updateMarkerFn: updateResourceMarker
    })
  }, [resourcesPositionMap, resourceMarkers, map, showResources])

  useEffect(function updateSiteMarkers () {
    updateMarkers({
      positionMap: showSites ? sitesPositionMap : {},
      existingMarkers: siteMarkers,
      map,
      setMarkersFn: setSiteMarkers,
      shouldMarkerExistFn: shouldSiteMarkerExist,
      shouldUpdateMarkerFn: shouldUpdateSiteMarker,
      createMarkerFn: createSiteMarker,
      updateMarkerFn: updateSiteMarker
    })
  }, [sitesPositionMap, siteMarkers, map, showSites])

  return (
    <div className='row'>
      <ExtraViewsContainer
        onToggleResources={onToggleResources}
        onToggleSites={onToggleSites}
        resourcesChecked={showResources}
        sitesChecked={showSites}
      />
      <InventoryWidget />
      <div id='map' />
    </div>
  )
}

DispatchMap.propTypes = {
  tickets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    driverId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    job: PropTypes.shape({
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired
    })
  })).isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    trackLocation: PropTypes.bool.isRequired,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    lastLocatedAt: PropTypes.string
  })).isRequired,
  resources: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    uid: PropTypes.string,
    jobId: PropTypes.string,
    job: PropTypes.shape({
      customId: PropTypes.number
    }),
    siteId: PropTypes.string,
    site: PropTypes.shape({
      name: PropTypes.string
    }),
    lastMoved: PropTypes.string,
    placementNote: PropTypes.string,
    currentLocation: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      placeId: PropTypes.string
    }),
    resourceType: PropTypes.shape({
      name: PropTypes.string,
      shortCode: PropTypes.string
    })
  })),
  showResources: PropTypes.bool.isRequired,
  onToggleResources: PropTypes.func.isRequired,
  sites: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    note: PropTypes.string,
    siteType: PropTypes.shape({
      shortCode: PropTypes.string.isRequired
    }),
    location: PropTypes.shape({
      placeId: PropTypes.string,
      latitude: PropTypes.number,
      longitude: PropTypes.number
    })
  })),
  showSites: PropTypes.bool.isRequired,
  onToggleSites: PropTypes.func.isRequired,
  hoverTicketId: PropTypes.string,
  hoverUserId: PropTypes.string,
  onLocationDetailChange: PropTypes.func.isRequired
}

function updateMarkers ({
  positionMap,
  existingMarkers,
  map,
  setMarkersFn,
  shouldMarkerExistFn,
  shouldUpdateMarkerFn,
  createMarkerFn,
  updateMarkerFn
}) {
  let markersToChange = []
  let markersToCreate = []
  let noChangeMarkers = []
  let markersToRemove = []

  for (const placeId in positionMap) {
    const positionData = positionMap[placeId]
    const existingMarker = existingMarkers[placeId]

    const shouldMarkerExist = shouldMarkerExistFn({ positionData })
    if (!existingMarker && shouldMarkerExist) {
      markersToCreate = [...markersToCreate, { positionData: { ...positionData, placeId } }]
      continue
    }

    if (existingMarker && !shouldMarkerExist) {
      markersToRemove = [...markersToRemove, existingMarker]
      continue
    }

    if (!existingMarker) continue

    if (shouldUpdateMarkerFn({ marker: existingMarker, positionData })) {
      markersToChange = [...markersToChange, { marker: existingMarker, positionData: { ...positionData, placeId } }]
      continue
    }

    noChangeMarkers = [...noChangeMarkers, existingMarker]
  }

  const existingMarkerValues = Object.values(existingMarkers)
  markersToRemove = [...markersToRemove, ...existingMarkerValues.filter(marker => positionMap[marker.placeId] === undefined)]

  if (markersToChange.length === 0 &&
    markersToCreate.length === 0 &&
    markersToRemove.length === 0) {
    existingMarkerValues.forEach(marker => marker.setMap(map))
    return
  }

  let newMarkers = noChangeMarkers.map(marker => {
    marker.setMap(map)
    return marker
  })

  newMarkers = [...newMarkers, ...markersToCreate.map(({ positionData }) => createMarkerFn({ positionData, map }))]
  newMarkers = [...newMarkers, ...markersToChange.map(({ positionData, marker }) => updateMarkerFn({ positionData, marker, map }))]
  markersToRemove.forEach(marker => marker.setMap(null))

  setMarkersFn(newMarkers.reduce((acc, marker) => {
    return { ...acc, [marker.placeId]: marker }
  }, {}))
}

function shouldTicketMarkerExist ({ positionData }) {
  return positionData.latitude && positionData.longitude
}

function shouldUpdateTicketMarker ({ marker, positionData }) {
  return marker.latitude !== positionData.latitude ||
    marker.longitude !== positionData.longitude ||
    marker.hovered !== positionData.hovered ||
    !isEqual(marker.tickets, positionData.tickets)
}

function createTicketMarker ({ positionData, map }) {
  const image = ticketMarkerImage({ tickets: positionData.tickets, hovered: positionData.hovered })
  const marker = new google.maps.Marker(
    {
      position: { lat: positionData.latitude, lng: positionData.longitude },
      map,
      icon: image,

      // Storing metadata
      latitude: positionData.latitude,
      longitude: positionData.longitude,
      placeId: positionData.placeId,
      hovered: positionData.hovered,
      tickets: positionData.tickets
    })

  marker.addListener('click', () => {
    positionData.onLocationDetailChange({ latitude: positionData.latitude, longitude: positionData.longitude })
  })
  return marker
}

function updateTicketMarker ({ positionData, marker, map }) {
  const image = ticketMarkerImage({ tickets: positionData.tickets, hovered: positionData.hovered })
  marker.setIcon(image)
  marker.setMap(map)
  marker.setPosition(new google.maps.LatLng(positionData.latitude, positionData.longitude))
  marker.latitude = positionData.latitude
  marker.longitude = positionData.longitude
  marker.placeId = positionData.placeId
  marker.hovered = positionData.hovered
  marker.tickets = positionData.tickets
  google.maps.event.clearListeners(marker, 'click')
  marker.addListener('click', () => {
    positionData.onLocationDetailChange({ latitude: positionData.latitude, longitude: positionData.longitude })
  })

  return marker
}

function shouldUserMarkerExist ({ positionData }) {
  return positionData.trackLocation && positionData.latitude && positionData.longitude
}

function shouldUpdateUserMarker ({ marker, positionData }) {
  return marker.latitude !== positionData.latitude ||
    marker.longitude !== positionData.longitude ||
    marker.firstName !== positionData.firstName ||
    marker.lastName !== positionData.lastName
}

function createUserMarker ({ positionData, map }) {
  const image = personPinCircle('#2196F3', false)
  const infoWindow = new google.maps.InfoWindow({
    content: userInfoWindowContent(positionData)
  })
  const marker = new google.maps.Marker({
    position: { lat: positionData.latitude, lng: positionData.longitude },
    map,
    icon: image,
    placeId: positionData.placeId,
    infoWindow,
    latitude: positionData.latitude,
    longitude: positionData.longitude,
    firstName: positionData.firstName,
    lastName: positionData.lastName
  })
  marker.addListener('mouseover', () => {
    infoWindow.open(map, marker)
  })
  marker.addListener('mouseout', () => {
    infoWindow.close()
  })
  return marker
}

function updateUserMarker ({ positionData, marker, map }) {
  marker.setMap(map)
  marker.setPosition(new google.maps.LatLng(positionData.latitude, positionData.longitude))
  marker.infoWindow.setContent(userInfoWindowContent(positionData))
  marker.latitude = positionData.latitude
  marker.placeId = positionData.placeId
  marker.longitude = positionData.longitude
  marker.firstName = positionData.firstName
  marker.lastname = positionData.lastName
  return marker
}

function shouldResourceMarkerExist ({ positionData }) {
  return positionData.latitude && positionData.longitude
}

function shouldUpdateResourceMarker ({ marker, positionData }) {
  return marker.latitude !== positionData.latitude ||
    marker.longitude !== positionData.longitude ||
    marker.shortCode !== positionData.shortCode ||
    !isEqual(marker.resources, positionData.resources)
}

function createResourceMarker ({ positionData, map }) {
  const infoWindow = new google.maps.InfoWindow({
    content: resourceInfoWindowContent(positionData.resources)
  })
  const marker = new google.maps.Marker({
    map,
    position: { lat: positionData.latitude - 0.0001, lng: positionData.longitude },
    icon: resourceRectangle(positionData.shortCode),
    infoWindow,
    infoWindowOpen: false,

    latitude: positionData.latitude,
    longitude: positionData.longitude,
    placeId: positionData.placeId,
    resources: positionData.resources,
    shortCode: positionData.shortCode
  })

  marker.addListener('click', () => {
    if (marker.infoWindowOpen) {
      infoWindow.close()
      marker.infoWindowOpen = false
    } else {
      // infoWindow.setZIndex(this.state.lastZIndex + 1)
      infoWindow.open(map, marker)
      marker.infoWindowOpen = true
    }
  })

  return marker
}

function updateResourceMarker ({ positionData, marker, map }) {
  marker.setMap(map)
  marker.setPosition(new google.maps.LatLng(positionData.latitude, positionData.longitude))
  marker.infoWindow.setContent(resourceInfoWindowContent(positionData.resources))
  marker.latitude = positionData.latitude
  marker.longitude = positionData.longitude
  marker.placeId = positionData.placeId
  marker.resources = positionData.resources
  marker.shortCode = positionData.shortCode
  return marker
}

function shouldSiteMarkerExist ({ positionData }) {
  return positionData.latitude && positionData.longitude
}

function shouldUpdateSiteMarker ({ marker, positionData }) {
  return marker.latitude !== positionData.latitude ||
    marker.longitude !== positionData.longitude ||
    marker.shortCode !== positionData.shortCode ||
    !isEqual(marker.sites, positionData.sites)
}

function createSiteMarker ({ positionData, map }) {
  const infoWindow = new google.maps.InfoWindow({
    content: siteInfoWindowContent(positionData.sites)
  })

  const marker = new google.maps.Marker({
    map,
    position: { lat: positionData.latitude - 0.0001, lng: positionData.longitude },
    icon: siteCircle({ short_code: positionData.shortCode }),
    infoWindow,
    infoWindowOpen: false,

    latitude: positionData.latitude,
    longitude: positionData.longitude,
    placeId: positionData.placeId,
    sites: positionData.sites,
    shortCode: positionData.shortCode
  })

  marker.addListener('click', () => {
    if (marker.infoWindowOpen) {
      infoWindow.close()
      marker.infoWindowOpen = false
    } else {
      infoWindow.open(map, marker)
      marker.infoWindowOpen = true
    }
  })

  return marker
}

function updateSiteMarker ({ positionData, marker, map }) {
  marker.setMap(map)
  marker.setPosition(new google.maps.LatLng(positionData.latitude, positionData.longitude))
  marker.infoWindow.setContent(siteInfoWindowContent(positionData.sites))
  marker.latitude = positionData.latitude
  marker.longitude = positionData.longitude
  marker.placeId = positionData.placeId
  marker.sites = positionData.sites
  marker.shortCode = positionData.shortCode
  return marker
}

function ticketMarkerImage ({ tickets, hovered }) {
  if (tickets.length === 1) {
    const ticket = tickets[0]
    const ttShortCode = (ticket && ticket.ticketType) ? ticket.ticketType.shortCode : '?'
    const rtShortCode = (ticket && ticket.resourceType) ? ticket.resourceType.shortCode : '?'
    const color = ticketStatusColor(ticket.status)
    const driverInitials = ticket.driverAssigned ? userInitials(ticket.driverAssigned) : '---'
    return place(color, driverInitials, hovered, ttShortCode, rtShortCode)
  }

  return filter('#3f51b5', tickets.length, hovered)
}

function ticketStatusColor (status) {
  if (status === 'open') {
    return '#33ca80'
  } else if (status === 'en_route') {
    return '#FF8F00'
  } else if (status === 'completed') {
    return '#131514'
  } else if (status === 'cancelled') {
    return '#F93B59'
  }
  return ''
}

function userInfoWindowContent (positionData) {
  return `
<h6 class="text-center">
  <strong>Driver:</strong>
  ${positionData.firstName} ${positionData.lastName}
</h6>
<h6>
  <strong>Located: </strong>
  ${positionData.lastLocatedAt ? dayjs(positionData.lastLocatedAt).format('MMM DD, h:mma') : 'Unknown'}
</h6>
`
}

function resourceInfoWindowContent (resources) {
  const content = resources.map(resource => {
    const ifJob = resource.jobId
      ? `
        <h6>
          <strong>Job ID:</strong>
          <a href="${generatePath(ROUTES.job, { id: resource.jobId })}" target="_blank noopener noreferrer">${resource.job.customId}</a>
        </h6>
      `
      : ''

    const ifSite = resource.siteId
      ? `
        <h6 class="text-center">
          <strong>Site ID:</strong>
          <a href="${generatePath(ROUTES.site, { id: resource.siteId })}" target="_blank noopener noreferrer">${resource.site.name}</a>
        </h6>
      `
      : ''

    const ifManual = (!resource.siteId && !resource.jobId)
      ? `
        <h6 class="text-center">
          <strong>Location Set Manually</strong>
        </h6>
      `
      : ''

    return `
<h6>
  <strong>Asset:</strong>
  <a href="${generatePath(ROUTES.resource, { id: resource.id })}" target="_blank">${resource.uid}</a> - ${resource.resourceType.name}
</h6>
${ifJob}
${ifSite}
${ifManual}
<h6>
  <strong>Located: </strong>
  ${resource.lastMoved ? dayjs(resource.lastMoved).format('MMM DD, h:mma') : 'Unknown'}
</h6>
<h6>
  <strong>Placement Notes:</strong>
  ${resource.placementNote || 'None'}
</h6>
`
  }).join('<hr>')

  return `<div class="resource-info-window">${content}</div>`
}

function siteInfoWindowContent (sites) {
  const content = sites.map(site => {
    let marker = `
      <h6>
        <strong>Site:</strong>
        <a href="${generatePath(ROUTES.site, { id: site.id })}" target="_blank">${site.name}</a> - ${site.siteType.shortCode}
      </h6>
      <h6>
        <strong>Site Notes:</strong>
        ${site.note || 'None'}
      </h6>
    `
    if (site.cost) {
      marker += `
      <h6>
        <strong>Disposal Cost:</strong>
        $${commaDeliminate(site.cost)}
      </h6>
      `
    }
    return marker
  }).join('<hr>')

  return `<div class="site-info-window">${content}</div>`
}

function isHovered (hoverTicketId, hoverUserId, ticket) {
  return hoverTicketId === ticket.id || (hoverUserId && hoverUserId === ticket.driverId?.toString()) || (!ticket.driverId && hoverUserId === 'unassigned')
}
