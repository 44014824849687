import { loader } from 'graphql.macro'

export default async function completeClientImport ({ dataLoaders, variables }) {
  const schema = loader('./completeClientImport.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      clientImportId: variables.clientImportId
    }
  })
}
