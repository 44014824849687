import PUSHER_ACTIONS, {
  PUSHER_CONNECTED,
  PUSHER_DISCONNECTED
} from './pusherActions'

const initialState = {
  pusher: undefined,
  userChannel: undefined,
  haulerChannel: undefined,

  // Used to know who the current pusher instance is connected for
  userId: undefined,
  haulerId: undefined
}

const pusherReducer = (state = initialState, action) => {
  const { payload, type } = action
  switch (type) {
    case PUSHER_ACTIONS.SET_PUSHER: {
      return {
        ...state,
        pusher: payload.pusher
      }
    }
    case PUSHER_ACTIONS.SET_CHANNELS: {
      return {
        ...state,
        haulerChannel: payload.haulerChannel,
        userChannel: payload.userChannel,
        userId: payload.userId,
        haulerId: payload.haulerId
      }
    }
    case PUSHER_CONNECTED:
      return {
        ...state,
        connected: true
      }
    case PUSHER_DISCONNECTED:
      return {
        ...state,
        connected: false
      }
    default:
      return state
  }
}

export default pusherReducer
