import { loader } from 'graphql.macro'
import { intercomConnection } from '../../intercomConnection'

export default async function reorderTickets ({ dataLoaders, variables }) {
  const schema = loader('./reorderTickets.graphql')
  let tickets = []
  for (const ticketId in variables) {
    tickets = [...tickets, { id: ticketId, ...variables[ticketId] }]
  }
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      ticketIds: tickets.sort((a, b) => a.position - b.position).map(t => t.id)
    }
  })
  intercomConnection.trackEvent('tickets_reordered', {})
  return response.reorderTickets
}
