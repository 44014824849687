import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { BACKGROUND_COLOR_MAPPING, DISPLAY_MAPPING } from './StatusDisplayUtils'

export default function StatusDisplay ({ status }) {
  return (
    <span
      className={cn(
        'flex justify-center items-center py-2 px-6 bg-opacity-90 rounded-full h-8 font-semibold',
        BACKGROUND_COLOR_MAPPING[status]
      )}>
      {DISPLAY_MAPPING[status]}
    </span>
  )
}

StatusDisplay.propTypes = {
  status: PropTypes.string.isRequired
}
