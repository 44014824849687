import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'

export default function Tabs ({ tabs, handleUpdateTab }) {
  const [activeIndex, setActiveIndex] = useState(0)

  function handleTabClick (idx, tab) {
    setActiveIndex(idx)
    handleUpdateTab(tab.name)
  }

  return (<>
    <div className={styles.tabsContainer}>
      {tabs.map((tab, idx) => (
        <button
          type='button'
          key={tab.name}
          className={cn(styles.navTab,
            {
              [styles.activeTab]: activeIndex === idx
            }
          )}
          onClick={() => handleTabClick(idx, tab)}>
          {tab.name}
        </button>
      ))}
    </div>
    {tabs.map((tab, idx) => (
      <div key={tab.name} className={cn({
        [styles.activeTabContent]: activeIndex === idx,
        [styles.inactiveTabContent]: activeIndex !== idx
      })}>
        {tab.content}
      </div>
    ))}
  </>)
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    content: PropTypes.node
  })),
  handleUpdateTab: PropTypes.func.isRequired
}
