import React from 'react'
import PropTypes from 'prop-types'
import Switch from './Switch'
import DatePicker from 'react-datepicker'
import Select from './Select'
import { Form, Field } from 'react-final-form'

export default function QuickbooksImportForm ({ onSubmit, customerTypes }) {
  function handleSubmit (formData) {
    const data = {
      importStatus: formData.importStatus,
      excludeSubCustomers: formData.excludeSubCustomers,
      excludeInactiveCustomers: formData.excludeInactiveCustomers
    }
    if (formData.includeDateRange) {
      data.invoiceDates = { fromDate: formData.fromDate, toDate: formData.toDate }
    }
    if (formData.includeByType) {
      data.includeCustomerTypes = formData.includeTypes.map(type => type.value)
    }

    return onSubmit(data)
  }

  return (
    <Form onSubmit={handleSubmit} initialValues={{
      importStatus: 'skip',
      includeDateRange: false,
      includeByType: false,
      excludeSubCustomers: false,
      excludeInactiveCustomers: false,
      includeTypes: [],
      fromDate: new Date(),
      toDate: new Date()
    }}>
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <label htmlFor='importStatusSkip' className='block mt-10 flex items-center'>
            <Field id='importStatusSkip' name='importStatus' type='radio' value='skip' component='input' />
            <span className='text-3xl ml-2'>Skip Import</span>
          </label>
          <label htmlFor='importStatusAll' className='block mt-4 flex items-center'>
            <Field id='importStatusAll' name='importStatus' type='radio' value='all' component='input' />
            <span className='text-3xl ml-2'>Import All</span>
            <span className='ml-2 text-xl text-gray-md-dark'>Import all QuickBooks customers (including inactive and sub-customers)</span>
          </label>
          <label htmlFor='importStatusCustom' className='block mt-4 flex items-center'>
            <Field id='importStatusCustom' name='importStatus' type='radio' value='custom' component='input' />
            <span className='text-3xl ml-2'>Customize Import</span>
            <span className='ml-2 text-xl text-gray-md-dark'>Import QuickBooks customers based on select options</span>
          </label>
          {values.importStatus === 'custom' && (
            <div className='ml-12'>
              <div className='flex mt-8'>
                <Field name='includeDateRange' component={SwitchAdapter} />
                <div className='ml-2'>
                  <div className='text-2xl'>Include customers with invoices created within a specific date range</div>
                  {values.includeDateRange && (
                    <div className='flex'>
                      <label htmlFor='fromDate' className='flex flex-col mt-4'>
                        From Date
                        <Field id='fromDate' name='fromDate' component={DatePickerAdapter} />
                      </label>
                      <label htmlFor='toDate' className='flex flex-col mt-4 ml-4'>
                        To Date
                        <Field id='toDate' name='toDate' component={DatePickerAdapter} />
                      </label>
                    </div>
                  )
                  }
                </div>
              </div>
              <div className='flex mt-8'>
                <Field name='includeByType' component={SwitchAdapter} />
                <div className='ml-2'>
                  <div className='text-2xl'>Include customers by customer type</div>
                  {values.includeByType && (
                    <Field
                      isMulti
                      name='includeTypes'
                      component={SelectAdapter}
                      className='mt-6 border-0 w-full'
                      options={customerTypes.map(customerType => ({
                        value: customerType.id,
                        label: customerType.name
                      }))}
                      placeholder='Select...'
                    />
                  )}
                </div>
              </div>
              <div className='flex items-center mt-8'>
                <Field name='excludeSubCustomers' component={SwitchAdapter} />
                <div className='ml-2 text-2xl'>Exclude Sub-customers</div>
              </div>
              <div className='flex items-center mt-8'>
                <Field name='excludeInactiveCustomers' component={SwitchAdapter} />
                <span className='ml-2 text-2xl'>Exclude Inactive Customers</span>
              </div>
            </div>
          )}
          <button className='uppercase bg-green py-3 px-10 rounded text-2xl text-black mt-16 font-bold shadow-md'>
            Continue
          </button>
        </form>
      )}
    </Form>
  )
}

QuickbooksImportForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  customerTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired
}

/**
 * Make our Switch component compatible with react-final-form
 */
function SwitchAdapter ({ input: { onChange, value }, ...rest }) {
  return <Switch onChange={onChange} checked={value} {...rest} />
}
SwitchAdapter.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool.isRequired
  }).isRequired
}

/**
 * Make our DatePicker component compatible with react-final-form
 */
function DatePickerAdapter ({ input: { onChange, value }, ...rest }) {
  return <DatePicker onChange={onChange} selected={value} {...rest} />
}
DatePickerAdapter.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
  }).isRequired
}

/**
 * Make our Select component compatible with react-final-form
 */
function SelectAdapter ({ input: { onChange, value }, ...rest }) {
  return <Select defaultValue={value} onChange={onChange} {...rest} />
}
SelectAdapter.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      }))
    ])
  }).isRequired
}
