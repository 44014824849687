import React, { useState, useEffect, useCallback } from 'react'
import { useMutation } from 'react-query'
import { ObjectParam, useQueryParams } from 'use-query-params'
import { loader } from 'graphql.macro'
import QUERY_KEYS from '../../graphql/queryKeys'
import { makeGraphQLClient } from '../../utilities/graphqlClient'
import notificationsQuery from '../../graphql/queries/notifications'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import PageTitle from '../../components/page-title'
import { Spinner } from '../shared/Spinner'
import NotificationsList from '../../components/notifications/NotificationsList'
import { useSelector } from 'react-redux'
import notify from '../../utilities/notify'
import useQuery from '../../hooks/useQuery'

const pageSize = 25

function MarkNotificationsRead (data) {
  const { client, schema, input } = data
  return client.request(schema, { input })
}

export default function NotificationsIndex () {
  const graphQLClient = makeGraphQLClient()
  const markNotificationsReadMutationSchema = loader('../../graphql/mutations/markNotificationsRead.graphql')
  const [fakeCount, setFakeCount] = useState(null)
  const { mutate: markNotificationsReadMutation } = useMutation(MarkNotificationsRead)
  const { user } = useSelector(({ user: { user } }) => ({ user }))

  const [queryParams, setQueryParams] = useQueryParams({
    pager: ObjectParam
  })

  const setQueryParamsReplace = useCallback((params) => {
    setQueryParams(params, 'replace')
  }, [setQueryParams])

  const { data, isFetching } = useQuery([
    QUERY_KEYS.notifications,
    {
      userId: user.id,
      ...queryParams,
      first: queryParams.pager?.direction === undefined || queryParams.pager?.direction === 'after' ? pageSize : undefined,
      last: queryParams.pager?.direction === 'before' ? pageSize : undefined,
      after: queryParams.pager?.direction === 'after' ? queryParams.pager?.cursor : undefined,
      before: queryParams.pager?.direction === 'before' ? queryParams.pager?.cursor : undefined
    }
  ],
  notificationsQuery,
  {
    enabled: Boolean(user.id),
    onError: (error) => handleError(error, 'Error fetching notifications'),
    refetchOnWindowFocus: false
  })

  const onPageRequest = (direction, cursor) => {
    setQueryParamsReplace({ ...queryParams, pager: { cursor, direction, pageSize } })

    if (!fakeCount) return
    if (direction === 'before') {
      setFakeCount(prevFakeCount => ({ start: prevFakeCount.start - pageSize, end: prevFakeCount.end - pageSize }))
    }
    if (direction === 'after') {
      setFakeCount(prevFakeCount => ({ start: prevFakeCount.start + pageSize, end: prevFakeCount.end + pageSize }))
    }
  }

  useEffect(function toggleFakePagination () {
    if (!data || isFetching || fakeCount !== null) return
    setFakeCount({ start: 1, end: Math.min(pageSize, data?.notifications?.totalCount) })
  }, [data, isFetching, fakeCount, setFakeCount])

  const onRead = (notificationIds, read) => {
    markNotificationsReadMutation({
      client: graphQLClient,
      schema: markNotificationsReadMutationSchema,
      input: {
        notificationIds,
        read
      }
    }, {
      onSuccess: ({ markNotificationsRead }) => {
        if (markNotificationsRead.success) {
          window.location.reload()
        }
      }
    }, {
      onError: error => {
        console.error(error)
        handleError(error, 'Error marking notification as read')
      }
    })
  }

  const handleError = (error, message) => {
    console.error(error)
    notify('error', message)
  }

  return (
    <div>
      <NormalLayoutContainer>
        <div className='container-fluid'>
          <PageTitle>Notifications</PageTitle>
          {
            isFetching
              ? <Spinner isFetching />
              : <div>
                {
                  (!data?.notifications?.nodes || data?.notifications?.nodes.length === 0)
                    ? <h3 className='text-center'>No Notifications</h3>
                    : (<NotificationsList
                        notifications={data?.notifications}
                        onRead={onRead}
                        onPageRequest={onPageRequest}
                        fakeCount={fakeCount}
                        totalCount={data?.notifications?.totalCount}
                        pageInfo={data?.notifications?.pageInfo}
                       />
                      )
                }
              </div>
          }
        </div>
      </NormalLayoutContainer>
    </div>
  )
}
