import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import notify from '../../utilities/notify'
import { useParams, useHistory, generatePath, Link } from 'react-router-dom'
import QUERY_KEYS from '../../graphql/queryKeys'
import { TICKET_BILLING_STATUSES } from '../../utilities/constants'
import styles from './index.module.scss'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import PageTitle from '../../components/page-title'
import { Spinner } from '../shared/Spinner'
import FormExplanation from '../shared/FormExplanation'
import EditTicketForm from '../../components/edit-ticket-form'
import useQuery from '../../hooks/useQuery'
import useMutation from '../../hooks/useMutation'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import { formatFormValuesForSubmit } from '../../utilities/randomUtilities'
import updateTicketByIdMutation from '../../graphql/mutations/updateTicketById'
import { ROUTES } from '../../routes'
import editTicketPageQuery from '../../graphql/queries/pages/editTicketPage'

export default function EditTicketPage () {
  const [ticketTypeBillingStatusSelected, setTicketTypeBillingStatusSelected] = useState(null)
  const [resourceTypeBillingStatusSelected, setResourceTypeBillingStatusSelected] = useState(null)
  const history = useHistory()
  const { id } = useParams()
  const { user, hauler } = useSelector(({ user }) => ({ user: user.user, hauler: user.hauler }))

  function filteredBillingStatusList () {
    if (pageData?.ticket.invoice?.id !== undefined) {
      return { INVOICED: 'INVOICED', PAID: 'PAID' }
    }
    return TICKET_BILLING_STATUSES
  }

  const { data: pageData, isFetching: isFetchingPageData } = useQuery(
    [QUERY_KEYS.editTicketPage, { haulerId: user.haulerId, ticketId: id }],
    editTicketPageQuery,
    {
      onSuccess (data) {
        const ticketTypeDefaultBillingStatus = data.ticket.ticketType?.defaultBillingStatus
        const resourceTypeDefaultBillingStatus = data.ticket.resourceType?.defaultBillingStatus
        function handleDefaultOptions (defaultValue) {
          const defaultInList = Object.keys(filteredBillingStatusList()).includes(defaultValue)
          if (!defaultInList) {
            return null
          }
          return defaultValue
        }
        setTicketTypeBillingStatusSelected(handleDefaultOptions(ticketTypeDefaultBillingStatus))
        setResourceTypeBillingStatusSelected(handleDefaultOptions(resourceTypeDefaultBillingStatus))
      },
      onError (error) {
        captureErrorAndNotify(error, 'Error fetching data')
      }
    }
  )

  const { mutate: updateTicket, isLoading: isUpdatingTicket } = useMutation(updateTicketByIdMutation, {
    onSuccess () {
      notify('success', 'Ticket updated successfully!')
      history.push(generatePath(ROUTES.ticket, { id }))
    },
    onError (error) {
      captureErrorAndNotify(error, 'Unable to update ticket')
    }
  })

  const isFetching = isUpdatingTicket || isFetchingPageData

  function handleFormSubmit (values, form) {
    const initialValues = form.getState().initialValues
    const formattedValues = formatFormValuesForSubmit(initialValues, values)
    formattedValues.weight = parseFloat(formattedValues.weight)
    updateTicket({ ...formattedValues, id })
  }

  return (
    <NormalLayoutContainer showBackLink>
      <PageTitle>
        <div className={styles.pageTitle}>
          <div>Edit a Ticket</div>
          <Link className='dis-btn dis-btn-blank dis-btn-lg' to={generatePath(ROUTES.ticket, { id })}>
            Cancel
          </Link>
        </div>
      </PageTitle>
      <div className='dis-form-page-container'>
        <div className='dis-form-container-left'>
          {isFetching
            ? <Spinner isFetching />
            : (
              <EditTicketForm
                isFetching={isFetching}
                metric={hauler.metric}
                onSubmit={handleFormSubmit}
                resourceTypes={pageData.resourceTypes}
                ticketTypes={pageData.ticketTypes}
                billingStatuses={filteredBillingStatusList()}
                ticketTypeBillingStatusSelected={ticketTypeBillingStatusSelected}
                setTicketTypeBillingStatusSelected={setTicketTypeBillingStatusSelected}
                resourceTypeBillingStatusSelected={resourceTypeBillingStatusSelected}
                setResourceTypeBillingStatusSelected={setResourceTypeBillingStatusSelected}
                initialValues={{
                  ticketTypeId: pageData.ticket.ticketType?.id,
                  resourceTypeId: pageData.ticket.resourceType?.id,
                  weight: pageData.ticket.weight,
                  billingNotes: pageData.ticket.billingNotes,
                  requestedStartTime: pageData.ticket.requestedStartTime,
                  requestedEndTime: pageData.ticket.requestedEndTime,
                  billingStatus: pageData.ticket.billingStatus,
                  weightTicketNumber: pageData.ticket.weightTicketNumber
                }}
              />
              )
          }
        </div>
        <div className='dis-form-container-right'>
          <FormExplanation
            messages={[
              'Tickets are the action that takes place on jobs. Here you can make changes to the following:',
              ' - Type of action needed',
              " - Asset type that's needed",
              ' - Enter weight of the ticket'
            ]}
          />
        </div>
      </div>
    </NormalLayoutContainer>
  )
}
