import React from 'react'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import classNames from 'classnames'
import { required } from '../utilities/commonValidators'

export default function AssignTicketDialog ({ onCancel, onSave, team, assignToTicket }) {
  function handleSubmit (values) {
    onSave({ ...assignToTicket, ...values })
  }

  return (
    <div className='bg-white shadow w-4/6 relative md:-top-1/4'>
      <h2 className='p-6'>{assignToTicket.reassignment ? 'Reassign Ticket to a Different Driver' : 'Assign Ticket to Team Member'}</h2>
      <div className='p-6'>
        <Form onSubmit={handleSubmit} validate={validate}>
          {({ handleSubmit, invalid }) => (
            <form onSubmit={handleSubmit}>
              <div className='mb-8'>
                <Field name='driverId'>
                  {({ input, meta }) => (<>
                    <label className={classNames('w-full', { 'text-red': meta.invalid && meta.touched })} htmlFor='driverId'>Select Team Member</label>
                    <select {...input} id='driverId' className='form-control'>
                      <option value={null}></option>
                      <option value={null}>- Unassigned -</option>
                      {team.map(tm => (
                        <option key={tm.id} value={tm.id}>{`${tm.firstName} ${tm.lastName}`}</option>
                      ))}
                    </select>
                    <div className={classNames({ 'border-b border-red border-1 relative bottom-1': meta.invalid && meta.touched })}></div>
                    {meta.invalid && meta.touched && <h5>Required</h5>}
                  </>)}
                </Field>
              </div>
              <div className='mt-2'>
                <h4>Drivers will be notified of this assignment change.</h4>
              </div>
              <div className='flex justify-end'>
                <button type='button'
                  className='dig-btn dis-btn-lg dis-btn-white mr-1'
                  onClick={onCancel}>
                  Close
                </button>
                <button type='submit'
                  className='dis-btn dis-btn-lg dis-btn-primary'
                  disabled={invalid}>
                  Save
                  <i className='material-icons dis-btn-icon'>check</i>
                </button>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  )
}

AssignTicketDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  team: PropTypes.arrayOf(PropTypes.object).isRequired,
  assignToTicket: PropTypes.shape({
    ticketId: PropTypes.string.isRequired,
    reassignment: PropTypes.bool.isRequired
  })
}

function validate (values) {
  return {
    driverId: required(values.driverId)
  }
}
