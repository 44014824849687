import { loader } from 'graphql.macro'

export default function dispatchTickets ({ dataLoaders, queryKey, pageParam = undefined }) {
  const [, variables] = queryKey
  const schema = loader('./dispatchTickets.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(schema, {
    haulerId: variables.haulerId,
    searchFields: {
      minDate: variables.searchFields.date,
      maxDate: variables.searchFields.date,
      addressSearch: variables.searchFields.address,
      resourceTypeId: variables.searchFields.resourceTypeId,
      ticketTypeId: variables.searchFields.ticketTypeId,
      status: variables.searchFields.status,
      driverId: variables.searchFields.driverId
    },
    after: pageParam
  })
}
