import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './new-requests.module.scss'
import { Link, generatePath } from 'react-router-dom'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import NewRequestButtons from './NewRequestButtons'
import NewRequestDeclineForm from './NewRequestDeclineForm'
import NewRequestDetails from './NewRequestDetails'
import { STATUSES } from './constants'
import { ROUTES } from '../../routes'
export default function NewRequestsLineItem ({
  request,
  handleConfirmation,
  formsOpen,
  setFormsOpen,
  index,
  removeRequest
}) {
  const utc = require('dayjs/plugin/utc')
  dayjs.extend(isToday)
  dayjs.extend(utc)

  const [declineForm, setDeclineForm] = useState(false)
  const [detailsOpen, setDetailsOpen] = useState(false)
  const [declineReason, setDeclineReason] = useState('')
  const [declineDetails, setDeclineDetails] = useState('')

  const service = request.tickets[0]
  const unconfirmed = service.status === 'UNCONFIRMED'
  const pending = service.status === 'PENDING_RESOLUTION'
  const confirmed = service.status === 'ORDER_CONFIRMED'
  const isRequestToday = dayjs(request.serviceDate).isToday()
  const isRequestBeforeToday = dayjs(request.serviceDate).isBefore(new Date(), 'day')
  const requestedTime =
  service.requestedStartTime && service.requestedEndTime
    ? `${dayjs.utc(service.requestedStartTime).format('h:mm a')} - ${dayjs.utc(service.requestedEndTime).format('h:mm a')}`
    : ''

  function formatAssetList () {
    const assetArr = []
    request.tickets.map(asset => {
      assetArr.push(asset.assetTypeName)
      return null
    })
    const sizes = assetArr.reduce((acc, curr) => {
      acc[curr] = (acc[curr] || 0) + 1
      return acc
    }, {})

    if (!assetArr.filter(val => val).length) {
      return ''
    }

    const formattedProducts = Object.keys(sizes).map((size, i) => {
      const product = sizes[size] > 1 ? `(${sizes[size]})` : ''
      return ` ${size}${product}${(Object.keys(sizes).length > 1 && !Object.keys(sizes).length === i + 1) ? ',' : ''}`
    })
    return formattedProducts.join(',')
  }

  function toggleDeclineForm (val) {
    setDeclineForm(val)
    setFormsOpen(formsOpen + 1)
  }

  function toggleDetails (val) {
    setDetailsOpen(val)
  }

  function detailsIcon () {
    return {
      ORDER_CONFIRMED: 'check_circle',
      PENDING_RESOLUTION: 'pending',
      UNCONFIRMED: 'info'
    }
  }

  const buttonView = () => {
    if (service.status !== 'UNCONFIRMED') {
      return null
    }
    if (declineForm) {
      return (
        <NewRequestDeclineForm
          index={index}
          handleConfirm={handleConfirmation}
          toggleDeclineForm={toggleDeclineForm}
          declineReason={declineReason}
          setDeclineReason={setDeclineReason}
          declineDetails={declineDetails}
          setDeclineDetails={setDeclineDetails}
          toggleDetails={toggleDetails}
          request={request}
        />
      )
    }
    return (
      <NewRequestButtons
        index={index}
        handleConfirm={handleConfirmation}
        toggleDeclineForm={toggleDeclineForm}
        request={request}
        toggleDetails={toggleDetails}
        isRequestBeforeToday={isRequestBeforeToday}
      />
    )
  }

  return (
    <div className='bg-white p-6 border-b border-gray-light'>
      <div className='flex flex-row flex-nowrap'>
        <button
          onClick={() => toggleDetails(true)}>
          <i
            className={classNames('material-icons font-size-1-5em relative cursor-pointer mr-2', {
              'text-green': confirmed,
              'text-gray': pending
            })}>
            {detailsIcon()[service.status]}
          </i>
        </button>
        <span>
          <span className={styles.newRequestType}> {request.ticketTypeName} - { unconfirmed ? formatAssetList() : STATUSES[service.status] } </span>
          {confirmed && <Link
            className={styles.viewTicketLink}
            to={{
              pathname: request.tickets.length === 1 ? generatePath(ROUTES.ticket, { id: service.createdTickets[0] }) : ROUTES.ticketSearch,
              search: request.tickets.length === 1
                ? ''
                : `?maxDate=${request.serviceDate}&minDate=${request.serviceDate}&jobCustomId=${request.tickets[0].jobId}`
            }}>
            {request.tickets.length === 1 ? 'View Ticket' : 'View Tickets'}
          </Link>}
        </span>
        {confirmed &&
          <button
            className={styles.closeConfirmedButton}
            onClick={() => removeRequest(index)}>
            <i className={classNames('material-icons', styles.closeIcon)}>close</i>
          </button>}
        {unconfirmed && <div className={classNames('ml-auto text-2xl', { 'text-orange font-medium': isRequestToday })}>
          {isRequestToday ? 'Today' : dayjs(request.serviceDate).format('M/D/YYYY')}
        </div>}
      </div>
      <div className='ml-8 text-xl'>
        <span>{request.addressline1} {request.addressline2 || ''}, {request.city}, {request.state} {request.zip}</span>
        {request.addressDetails && <span>{`(${request.addressDetails})`}</span>}
      </div>
      {unconfirmed &&
        <>
          {requestedTime && <div className={classNames('ml-8 my-1', { 'text-orange': isRequestToday })}>
            {`Requested Time: ${request.tickets.length > 1 ? '(See details)' : requestedTime}`}
          </div>}
          {buttonView()}
        </>}
      {detailsOpen && <NewRequestDetails
        request={request}
        toggleDetails={toggleDetails}
        buttonView={buttonView}
                      />}
    </div>
  )
}

NewRequestsLineItem.propTypes = {
  request: PropTypes.object.isRequired,
  handleConfirmation: PropTypes.func.isRequired,
  formsOpen: PropTypes.number.isRequired,
  setFormsOpen: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  removeRequest: PropTypes.func.isRequired
}
