import React, { useEffect, useState } from 'react'
import { gql } from 'graphql-request'
import { Link, useParams } from 'react-router-dom'
import { Spinner } from './shared/Spinner'
import NormalLayoutContainer from './shared/NormalLayoutContainer'
import cn from 'classnames'
import { ROUTES } from '../routes'
import { useSelector } from 'react-redux'

export default function QuickbooksImportCompletePage () {
  const [importedAccounts, setImportedAccounts] = useState(null)
  const { graphQLClient } = useSelector(({ dataLoaders }) => ({ graphQLClient: dataLoaders.dispatcher.graphQLClient }))
  const { id } = useParams()

  useEffect(function onInitialRender () {
    const query = gql`
      query QuickbooksImportBatch($id: ID!) {
        quickbooksImportBatch(id: $id) {
          clients {
            id
            name
            customId
            prettyPrimaryPhone  
            firstName
            lastName
          }
        }
      }
    `
    graphQLClient.request(query, { id })
      .then(({ quickbooksImportBatch }) => setImportedAccounts(quickbooksImportBatch.clients))
  }, [id, graphQLClient])

  if (importedAccounts === null) {
    return <Spinner isFetching />
  }

  return (
    <NormalLayoutContainer>
      <h1 className='text-6xl mt-16'>QuickBooks Import Complete</h1>
      <div className='flex items-center justify-between mt-8'>
        <p className='text-2xl m-0'>Below is a list of accounts that have been added to Dispatcher.</p>
        <Link
          to={ROUTES.quickbooksSettings}
          className={`uppercase bg-green py-3 px-10 rounded text-2xl text-black font-bold hover:bg-green-10-dark
           hover:no-underline hover:text-black whitespace-nowrap shadow-md`}>
          QuickBooks Settings
        </Link>
      </div>
      <hr />
      <table className='bg-white rounded border border-gray-light w-full'>
        <tr>
          <th className='px-8 pb-2 pt-10'>ID</th>
          <th className='px-8 pb-2 pt-10'>Status</th>
          <th className='px-8 pb-2 pt-10'>Name</th>
          <th className='px-8 pb-2 pt-10'>Contact Name</th>
          <th className='px-8 pb-2 pt-10'>Phone</th>
          <th className='px-8 pb-2 pt-10'>Action</th>
        </tr>
        {importedAccounts.map((account, index) => {
          const isLast = importedAccounts.length - 1 === index
          const tdClasses = cn('px-8', { 'py-2': !isLast, 'pt-2': isLast, 'pb-10': isLast })
          return (
            <tr key={account.id}>
              <td className={tdClasses}>
                <Link to={`/hauler/clients/${account.id}`}>
                  {account.customId}
                </Link>
              </td>
              <td className={tdClasses}>
                <div className='bg-green flex justify-center rounded-3xl text-black w-min px-4'>
                  Success
                </div>
              </td>
              <td className={tdClasses}>
                <Link to={`/hauler/clients/${account.id}`}>
                  {account.name}
                </Link>
              </td>
              <td className={tdClasses}>{account.firstName} {account.lastName}</td>
              <td className={tdClasses}>{account.prettyPrimaryPhone}</td>
              <td className={tdClasses}>
                <Link className={`bg-green-dark text-white py-2 px-4 hover:text-white hover:no-underline rounded shadow-md 
                hover:bg-green-dark-10-dark`} to={`/hauler/clients/${account.id}`}>
                  Details
                </Link>
              </td>
            </tr>
          )
        }
        )}
      </table>
    </NormalLayoutContainer>
  )
}
