import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Pagination extends Component {
  render () {
    const {
      pagination: {
        total_pages: totalPages,
        current_page: currentPage,
        total_count: totalCount,
        max_per_page: maxPerPage
      }, onPageRequest
    } = this.props

    if (!totalPages) {
      return <span />
    }

    const allowPrevious = currentPage !== 1
    const allowNext = currentPage !== totalPages
    const firstPage = currentPage === 1
    const finalPage = currentPage === totalPages
    const previousPage = currentPage - 1
    const nextPage = currentPage + 1

    return (<ul className='pager'>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
      <li
        className={allowPrevious ? 'previous cursor-pointer' : 'previous disabled'}
        onClick={() => {
          if (allowPrevious) {
            onPageRequest(previousPage)
          }
        }}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a>&larr; Previous</a>
      </li>
      <li>
        <div className='inline-block'>
          Displaying Results
          &nbsp;
          {firstPage ? 1 : previousPage * maxPerPage}
          &nbsp;
          to
          &nbsp;
          {finalPage ? totalCount : currentPage * maxPerPage}
          <br />
          <strong>Total Results: {totalCount}</strong>
        </div>
      </li>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
      <li
        className={allowNext ? 'next cursor-pointer' : 'next disabled'}
        onClick={() => {
          if (allowNext) {
            onPageRequest(nextPage)
          }
        }}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a>Next &rarr;</a>
      </li>
    </ul>)
  }
}

Pagination.propTypes = {
  onPageRequest: PropTypes.func.isRequired,
  pagination: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      total_pages: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      current_page: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      total_count: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      max_per_page: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ])
    })
  ])
}
