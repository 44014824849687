export const GRAPHQL_AVAILABILITY_DELIVERY_TYPE = {
  NEW_DELIVERY: 'NEW_DELIVERY',
  PICKUP: 'PICKUP',
  EMPTY_RETURN: 'EMPTY_RETURN'
}

export const AVAILABILITY_GROUP = {
  [GRAPHQL_AVAILABILITY_DELIVERY_TYPE.NEW_DELIVERY]: 'newDeliveries',
  [GRAPHQL_AVAILABILITY_DELIVERY_TYPE.PICKUP]: 'pickUp',
  [GRAPHQL_AVAILABILITY_DELIVERY_TYPE.EMPTY_RETURN]: 'emptyReturn'
}

export const GRAPHQL_AVAILABILITY_DELIVERY_DAY = {
  SAME_DAY: 'SAME_DAY',
  NEXT_DAY: 'NEXT_DAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY'
}

export const NEXT_AVAILABLE_SERVICE = 'NEXT_AVAILABLE_SERVICE'
export const DAYS_OUT = 'DAYS_OUT'
export const MUST_CALL = 'MUST_CALL'
