import { loader } from 'graphql.macro'

export default async function createSite ({ dataLoaders, variables }) {
  const createSiteSchema = loader('./createSite.graphql')
  const siteFields = {
    name: variables.name,
    siteTypeId: variables.siteTypeId,
    latitude: variables.latitude,
    longitude: variables.longitude,
    placeId: variables.placeId,
    addressline1: variables.addressline1,
    addressline2: variables.adderssline2,
    city: variables.city,
    state: variables.state,
    zip: variables.zip,
    country: variables.country,
    note: variables.note,
    cost: parseFloat(variables.cost)
  }
  const response = await dataLoaders.dispatcher.graphQLClient.request(
    createSiteSchema,
    { input: { siteFields } }
  )
  return response.createSite
}
