import React, { useState } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'

export default function EditSiteTypeForm ({
  onSubmit,
  isFetching,
  formValues,
  setFormValues,
  resErrors,
  siteType,
  clearErrors
}) {
  const [formError, setFormError] = useState({
    name: false,
    shortCode: false
  })

  const checkErrorMessages = (msg) => {
    return resErrors.findIndex(errMsg => errMsg.toLowerCase().trim() === msg)
  }
  const shortCodeErrorIndex = checkErrorMessages('short code has already been taken')
  const nameErrorIndex = checkErrorMessages('name has already been taken')
  const pristine = siteType.short_code === formValues.shortCode && siteType.name === formValues.name
  const disabled = isFetching ||
    !formValues.name?.length ||
    !formValues.shortCode?.length ||
    shortCodeErrorIndex > -1 ||
    nameErrorIndex > -1 ||
    pristine

  function handleOnChange (val, field) {
    const updatedSiteType = { ...formValues, ...{ [field]: val } }
    setFormValues(updatedSiteType)
    if (resErrors.length) {
      clearErrors()
    }
  }

  function handleOnBlur (field) {
    const updatedErrors = { ...formError, ...{ [field]: formValues[field].length === 0 } }
    setFormError(updatedErrors)
  }

  return (
    <div className='row panel panel-default'>
      <div className='container-fluid push-down-super-small'>
        <form onSubmit={onSubmit}>
          <div className='row'>
            <div className='col-xs-12'>
              <div className={cn({
                'has-error': formError.name || nameErrorIndex > -1
              })}>
                <label className='control-label' htmlFor='name'>Site Type Name</label>
                <input
                  type='text'
                  className='form-control'
                  name='name'
                  maxLength='25'
                  value={formValues.name}
                  onBlur={() => handleOnBlur('name')}
                  onChange={(e) => handleOnChange(e.target.value, 'name')}
                />
                {formError.name && <h6>Required</h6> }
                {nameErrorIndex > -1 && <h6>{resErrors[nameErrorIndex]}</h6> }
              </div>
              <div className={cn({
                'has-error': formError.shortCode || shortCodeErrorIndex > -1
              })}>
                <label className='control-label' htmlFor='short code'>Site Type Short Code</label>
                <input
                  type='text'
                  className='form-control'
                  name='short code'
                  maxLength='2'
                  value={formValues.shortCode}
                  onChange={(e) => handleOnChange(e.target.value, 'shortCode')}
                  onBlur={() => handleOnBlur('shortCode')}
                />
                {formError.shortCode && <h6>Required</h6> }
                {shortCodeErrorIndex > -1 && <h6>{resErrors[shortCodeErrorIndex]}</h6> }
              </div>
              <div className='container-fluid row'>
                <h6><em>Changing this Site Type will be automatically reflected on your existing sites, jobs, tickets, and assets.</em></h6>
              </div>
              <button
                type='submit'
                className='btn btn-primary push-down-very-small pull-right'
                disabled={disabled}>
                <span className='pull-left'>Save Changes</span>
                <i className='material-icons pull-right thumbster-button-icon'>check</i>
              </button>
            </div>
          </div>
        </form>
      </div>
      <br />
    </div>
  )
}

EditSiteTypeForm.propTypes = {
  isFetching: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
  resErrors: PropTypes.any,
  siteType: PropTypes.object.isRequired,
  clearErrors: PropTypes.func.isRequired
}
