import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import styles from './index.module.scss'
import {
  TextInput
} from '../TextInput'
import { enumToTitleCase } from '../../utilities/stringUtilities'
import { TextareaInput } from '../TextareaInput'
import { TimeInput } from '../TimeInput'
import { validate } from './utils'

export default function EditTicketForm ({
  onSubmit,
  ticketTypes,
  resourceTypes,
  metric,
  isFetching,
  billingStatuses,
  initialValues,
  ticketTypeBillingStatusSelected,
  setTicketTypeBillingStatusSelected,
  resourceTypeBillingStatusSelected,
  setResourceTypeBillingStatusSelected
}) {
  function onTicketTypeChange (val, prevVal) {
    const selected = ticketTypes.find(type => type.id === val)
    const previouslySelected = resourceTypes.find(type => type.id === prevVal)
    const selectedInOptions = Object.keys(billingStatuses).includes(selected?.defaultBillingStatus)
    const prevSelectedInOptions = Object.keys(billingStatuses).includes(previouslySelected?.defaultBillingStatus)
    if (val) {
      if (selectedInOptions) {
        setTicketTypeBillingStatusSelected(selected?.defaultBillingStatus)
        return resourceTypeBillingStatusSelected || selected?.defaultBillingStatus || 'PENDING'
      } else { /* handles for case where the ticket type's default billing status is not present in the billing status select options if the ticket has an associated invoice */
        setTicketTypeBillingStatusSelected(null)
        return resourceTypeBillingStatusSelected ||
        (ticketTypeBillingStatusSelected
          ? initialValues.billingStatus
          : prevSelectedInOptions ? previouslySelected?.defaultBillingStatus : initialValues.billingStatus) ||
          initialValues.billingStatus
      }
    }
    return null
  }

  function onResourceTypeChange (val, prevVal) {
    const selected = resourceTypes.find(type => type.id === val)
    const previouslySelected = resourceTypes.find(type => type.id === prevVal)
    const selectedInOptions = Object.keys(billingStatuses).includes(selected?.defaultBillingStatus)
    const prevSelectedInOptions = Object.keys(billingStatuses).includes(previouslySelected?.defaultBillingStatus)
    if (val) {
      if (selectedInOptions) {
        setResourceTypeBillingStatusSelected(selected?.defaultBillingStatus)
        return selected?.defaultBillingStatus || ticketTypeBillingStatusSelected || 'PENDING'
      } else { /* handles for case where the resource type's default billing status is not present in the billing status select options if the ticket has an associated invoice */
        setResourceTypeBillingStatusSelected(null)
        return ticketTypeBillingStatusSelected ||
          (resourceTypeBillingStatusSelected
            ? initialValues.billingStatus
            : prevSelectedInOptions ? previouslySelected?.defaultBillingStatus : initialValues.billingStatus) ||
            initialValues.billingStatus
      }
    }
    return null
  }

  function billingStatusMessaging (manuallyUpdated, value, formState) {
    const notEdited = (initialValues.billingStatus === value) && !formState.dirtyFields?.billingStatus
    if (manuallyUpdated || !value || notEdited) {
      return ''
    } else if (value === resourceTypeBillingStatusSelected) {
      return 'Using asset type default'
    } else if (value === ticketTypeBillingStatusSelected) {
      return 'Using ticket type default'
    } else {
      return 'Using general default'
    }
  }

  return (
    <div className='dis-panel'>
      <div className='dis-panel-body'>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          validate={values => validate(values)}>
          {({ handleSubmit, pristine, form, valid, values }) => {
            const formState = form.getState()
            const billingStatusManuallyUpdated = formState.dirtyFields?.billingStatus && formState.visited?.billingStatus
            return (
              <form onSubmit={handleSubmit}>
                <Field name='billingStatus' subscription={{}}>
                  {(
                    { input: { onChange } }
                  ) => (
                    <OnChange name='ticketTypeId'>
                      {(val, prevVal) => {
                        if (!billingStatusManuallyUpdated) {
                          onChange(onTicketTypeChange(val, prevVal))
                        }
                      }}
                    </OnChange>
                  )}
                </Field>

                <Field name='billingStatus' subscription={{}}>
                  {(
                    { input: { onChange } }
                  ) => (
                    <OnChange name='resourceTypeId'>
                      {(val, prevVal) => {
                        if (!billingStatusManuallyUpdated) {
                          onChange(onResourceTypeChange(val, prevVal))
                        }
                      }}
                    </OnChange>
                  )}
                </Field>

                <Field name='ticketTypeId'>
                  {({ input }) => (
                    <div className='form-group'>
                      <label htmlFor='ticketTypeId' className='control-label'>Ticket Type</label>
                      <select
                        {...input}
                        type='select'
                        className='form-control'>
                        <option key='placeholder' value=''></option>
                        {ticketTypes.map((tt) =>
                          (
                            <option
                              value={tt.id}
                              key={tt.id}>
                              {`${tt.name} (${tt.shortCode})`}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  )}
                </Field>

                <Field name='resourceTypeId'>
                  {({ input }) => (
                    <div className='form-group'>
                      <label htmlFor='resourceTypeId' className='control-label'>Asset Type</label>
                      <select
                        {...input}
                        type='select'
                        className='form-control'>
                        <option key='placeholder' value=''></option>
                        {resourceTypes.map((rt) =>
                          (
                            <option
                              value={rt.id}
                              key={rt.id}>
                              {`${rt.name} (${rt.shortCode})`}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  )}
                </Field>

                <Field name='billingStatus'>
                  {({ input }) => (
                    <div className='form-group'>
                      <label htmlFor='billingStatus' className='control-label'>Billing Status</label>
                      <select
                        {...input}
                        type='select'
                        className='form-control'>
                        <option key='placeholder' value=''></option>
                        {Object.keys(billingStatuses).map((opt) =>
                          (
                            <option
                              value={opt}
                              key={opt}>
                              {enumToTitleCase(opt)}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  )}
                </Field>
                <h6 className={styles.inputMessaging}>
                  <em>{billingStatusMessaging(billingStatusManuallyUpdated, values.billingStatus, formState)}</em>
                </h6>

                <Field
                  name='weight'
                  label={`Weight (${metric ? 'kg' : 'lbs'})`}
                  component={TextInput}
                />

                <Field
                  name='weightTicketNumber'
                  label={'Weight Ticket Number'}
                  component={TextInput}
                />

                <Field
                  component={TextareaInput}
                  label='Billing Notes (Optional)'
                  name='billingNotes'
                  placeholder='(Billing Notes can be used to track quoted prices or any other billing information.
                  Drivers do not see billing notes on the mobile app.)'
                  maxLength='1000'
                />

                <div className={styles.requestedTimes}>
                  <div className={styles.timeInput}>
                    <Field
                      className='pull-left'
                      name='requestedStartTime'
                      label='Requested Time Range'
                      component={TimeInput}
                    />
                  </div>
                  <div className={styles.timeInput}>
                    <Field
                      className='pull-left'
                      name='requestedEndTime'
                      label='Requested Time Range'
                      component={TimeInput}
                    />
                  </div>
                </div>

                <div className={styles.buttonContainer}>
                  <button
                    type='submit'
                    className='dis-btn dis-btn-primary dis-btn-lg'
                    disabled={pristine || isFetching || !valid}>
                    Save Changes
                    <i className='material-icons dis-btn-icon'>check</i>
                  </button>
                </div>

              </form>
            )
          }}
        </Form>
      </div>
      <br />
    </div>
  )
}

EditTicketForm.propTypes = {
  isFetching: PropTypes.bool,
  metric: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  resourceTypes: PropTypes.array.isRequired,
  ticketTypes: PropTypes.array.isRequired,
  billingStatuses: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  ticketTypeBillingStatusSelected: PropTypes.string,
  setTicketTypeBillingStatusSelected: PropTypes.func,
  resourceTypeBillingStatusSelected: PropTypes.string,
  setResourceTypeBillingStatusSelected: PropTypes.func
}
