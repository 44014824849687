import React from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'

export default function ConfirmImportModal ({ onConfirm, onCancel, pluralImportName }) {
  return (
    <div className='bg-white shadow w-5/6 md:w-1/2 lg:w-2/5 relative px-8'>
      <h3 className='text-center'>Are You Sure?</h3>
      <p className='text-center mx-12'>
        There are errors in your import data. The <span className={styles.lowercase}>{pluralImportName}</span> with errors will not be
        created if you proceed without fixing them in your upload file.
      </p>

      <div className='flex justify-center my-12'>
        <button
          type='button'
          className='btn btn-primary mx-4 font-medium'
          onClick={onConfirm}>
          YES, CREATE {pluralImportName}
        </button>

        <button
          type='button'
          className='btn mx-4 font-medium'
          onClick={onCancel}>
          NO, CANCEL
        </button>
      </div>
    </div>
  )
}

ConfirmImportModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  pluralImportName: PropTypes.string.isRequired
}
