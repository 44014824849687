import React from 'react'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import { TextInput } from './TextInput'
import { composeValidators, required, validEmail } from '../utilities/commonValidators'

export default function PasswordResetForm ({ onSubmit, disabled = false }) {
  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, pristine, invalid }) => (
        <form className='w-full md:w-4/5 mx-auto' onSubmit={handleSubmit}>
          <div className='text-center'>
            <Field
              name='email'
              type='email'
              component={TextInput}
              placeholder='Email'
              autoComplete='email'
              validate={composeValidators(required, validEmail)}
            />
            <button
              type='submit'
              className='btn btn-primary push-down-very-small'
              disabled={pristine || invalid || disabled}>Enter
            </button>
          </div>
        </form>
      )}
    </Form>
  )
}

PasswordResetForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}
