import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'
import { useSelector } from 'react-redux'
import Spinner from 'react-spinkit'
import ClientWastePayCards from './ClientWastePayCards'

export default function ClientBillingDetails ({
  client,
  onQboExport,
  onQboUnlink,
  isQboLoading,
  onWPExport,
  onWPUnlink,
  isWPLoading,
  isErrorFetchingQboCustomer,
  qboCustomer
}) {
  const { hauler } = useSelector(({ user }) => ({ hauler: user.hauler }))

  function handleQboExport () {
    onQboExport(client.id)
  }

  function handleQboUnlink () {
    onQboUnlink(client.id)
  }

  function handleWPExport () {
    onWPExport(client.id)
  }

  function handleWPUnlink () {
    onWPUnlink(client.id)
  }

  return (
    <div className='dis-list-group'>
      <div className={cn('dis-list-item dis-list-item-gray', styles.detailsItem)}>
        <div className='dis-list-group-header'>
          <h5>Billing Information</h5>
        </div>
      </div>

      <div className='dis-list-item'>
        <div className={styles.detailType}>Billing Address</div>
        {client.billingAddress.length > 0
          ? (
            <div className={styles.detailsInfo}>
              <div>{`${client.addressline1 ? client.addressline1 : ''} ${client.addressline2 ? client.addressline2 : ''}`}</div>
              <div>
                {`${client.city ? client.city : ''}${client.state ? ',' : ''}  ${client.state ? client.state : ''} ${client.zip ? client.zip : ''}`}
              </div>
            </div>
            )
          : <div className={styles.detailsInfo}>None</div>
        }
      </div>

      <div className='dis-list-item'>
        <div className={styles.detailType}>Billing Details</div>
        <div className={styles.detailsInfo}>{client.billingDetails || 'None'}</div>
      </div>
      <div className={cn(styles.qboListItem, 'dis-list-item')}>
        <div>
          <div className={styles.detailType}>QuickBooks Customer</div>
          <div className={styles.detailsInfo}>
            {isQboLoading
              ? <Spinner name='circle' fadeIn='none' className={styles.qboSpinner} />
              : (<>
                <QboSyncName qboConnected={hauler.quickbooks.isConnected} clientQboCustomerId={client.qboCustomerId} qboCustomer={qboCustomer} />
                <QboError
                  qboConnected={hauler.quickbooks.isConnected}
                  isErrorFetchingQboCustomer={isErrorFetchingQboCustomer}
                  clientQboCustomerId={client.qboCustomerId}
                  qboCustomer={qboCustomer}
                />
              </>)
            }
          </div>
        </div>
        <QboActionButton
          qboConnected={hauler.quickbooks.isConnected}
          isErrorFetchingQboCustomer={isErrorFetchingQboCustomer}
          clientQboCustomerId={client.qboCustomerId}
          isQboLoading={isQboLoading}
          onQboExport={handleQboExport}
          onQboUnlink={handleQboUnlink}
        />
      </div>
      <div className={cn(styles.qboListItem, 'dis-list-item')}>
        <div>
          <div className={styles.detailType}>WastePay Customer</div>
          <div className={styles.detailsInfo}>
            {isWPLoading
              ? <Spinner name='circle' fadeIn='none' className={styles.qboSpinner} />
              : (
                <WastepaySyncName
                  wastepayConnected={hauler.isWastepayConnected}
                  wastepayCustomerId={client.wastepayCustomerId}
                />
                )
              }
          </div>
        </div>
        <WastePayActionButton
          wastepayConnected={hauler.isWastepayConnected}
          wastepayCustomerId={client.wastepayCustomerId}
          onWPExport={handleWPExport}
          onWPUnlink={handleWPUnlink}
          isLoading={isWPLoading}
        />
      </div>
      { (hauler.isWastepayConnected && client.wastepayCustomerId) && (
        <ClientWastePayCards
          clientId={client.id}
          clientName={client.name}
          hauler={hauler}
        />
      )}

    </div>
  )
}

ClientBillingDetails.propTypes = {
  client: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    billingDetails: PropTypes.string,
    billingAddress: PropTypes.string,
    addressline1: PropTypes.string,
    addressline2: PropTypes.string,
    wastepayCustomerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    qboCustomerId: PropTypes.string
  }).isRequired,
  onQboExport: PropTypes.func.isRequired,
  onQboUnlink: PropTypes.func.isRequired,
  isQboLoading: PropTypes.bool.isRequired,
  isErrorFetchingQboCustomer: PropTypes.bool.isRequired,
  onWPExport: PropTypes.func.isRequired,
  onWPUnlink: PropTypes.func.isRequired,
  isWPLoading: PropTypes.bool.isRequired,
  qboCustomer: PropTypes.shape({
    displayName: PropTypes.string.isRequired
  })
}

function QboSyncName ({ qboConnected, clientQboCustomerId, qboCustomer }) {
  if (!qboConnected || !clientQboCustomerId) return 'Not Synced'
  if (!qboCustomer) return 'Unknown'
  return qboCustomer.displayName
}
QboSyncName.propTypes = {
  qboConnected: PropTypes.bool.isRequired,
  clientQboCustomerId: PropTypes.string,
  qboCustomer: PropTypes.shape({
    displayName: PropTypes.string.isRequired
  })
}

function WastepaySyncName ({ wastepayConnected, wastepayCustomerId }) {
  const wastepayURL = process.env.REACT_APP_ENV === 'production' ? 'https://app.wastepay.com/' : 'https://dev-app.wastepay.com/'
  if (!wastepayConnected || !wastepayCustomerId) return 'Not Synced'
  return (<a href={`${wastepayURL}/app/customers/${wastepayCustomerId}`} target='_blank noopener noreferrer'>
    {wastepayCustomerId}
  </a>)
}
WastepaySyncName.propTypes = {
  wastepayConnected: PropTypes.bool.isRequired,
  wastepayCustomerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

function QboError ({ qboConnected, isErrorFetchingQboCustomer, clientQboCustomerId, qboCustomer }) {
  if (!qboConnected || !clientQboCustomerId) return null

  let errorText = null
  if (isErrorFetchingQboCustomer) {
    errorText = 'An error occurred connecting to QuickBooks'
  } else if (!qboCustomer) {
    errorText = 'The link to the QBO customer is no longer valid'
  }

  if (!errorText) return null

  return <span className={styles.errorText}>{errorText}</span>
}
QboError.propTypes = {
  qboConnected: PropTypes.bool.isRequired,
  isErrorFetchingQboCustomer: PropTypes.bool.isRequired,
  clientQboCustomerId: PropTypes.string,
  qboCustomer: PropTypes.shape({
    displayName: PropTypes.string.isRequired
  })
}

function QboActionButton ({ qboConnected, isErrorFetchingQboCustomer, clientQboCustomerId, isQboLoading, onQboExport, onQboUnlink }) {
  if (!qboConnected || isErrorFetchingQboCustomer || isQboLoading) return null

  return (
    <button
      onClick={clientQboCustomerId ? onQboUnlink : onQboExport}
      className={cn(styles.qboBtn, 'dis-btn dis-btn-link')}>
      {clientQboCustomerId ? 'Unlink' : 'Export to QBO'}
    </button>
  )
}
QboActionButton.propTypes = {
  qboConnected: PropTypes.bool.isRequired,
  isErrorFetchingQboCustomer: PropTypes.bool.isRequired,
  clientQboCustomerId: PropTypes.string,
  isQboLoading: PropTypes.bool.isRequired,
  onQboExport: PropTypes.func.isRequired,
  onQboUnlink: PropTypes.func.isRequired
}

function WastePayActionButton ({ wastepayConnected, wastepayCustomerId, onWPExport, onWPUnlink, isLoading }) {
  if (!wastepayConnected || isLoading) return null

  return (
    <button
      onClick={wastepayCustomerId ? onWPUnlink : onWPExport}
      className={cn(styles.qboBtn, 'dis-btn dis-btn-link')}>
      {wastepayCustomerId ? 'Unlink' : 'Export to WastePay'}
    </button>
  )
}
WastePayActionButton.propTypes = {
  wastepayConnected: PropTypes.bool.isRequired,
  wastepayCustomerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onWPExport: PropTypes.func.isRequired,
  onWPUnlink: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}
