import React from 'react'
import { ROUTES } from '../../routes'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import styles from './index.module.scss'

export default function HaulerDriverAssignmentSettings () {
  return (
    <div className={cn(styles.listItem, 'dis-list-item')}>
      <div>
        <div className={styles.title}>Driver Assignment</div>
        <div className={cn(styles.description, styles.mdText)}>
          Set which users should appear in the Driver List Options when assigning tickets.
        </div>
      </div>

      <Link
        to={ROUTES.driverAssignmentSettings}
        className={cn(styles.btnLink, 'dis-btn dis-btn-sm dis-btn-primary-lt')}>
        <span className={styles.mdText}>Set Driver Assignment</span>
      </Link>
    </div>
  )
}
