import React, { useEffect } from 'react'
import NormalLayoutContainer from './shared/NormalLayoutContainer'
import { PageTitle } from '../components/page-title'
import UserProfile from '../components/user-profile/UserProfile'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import useQuery from '../hooks/useQuery'
import QUERY_KEYS from '../graphql/queryKeys'
import userQuery from '../graphql/queries/user'
import USER_ACTIONS from '../store/user/userActions'
import { Spinner } from './shared/Spinner'
import { ROUTES } from '../routes'

export default function UserProfilePage () {
  const dispatch = useDispatch()
  const { user } = useSelector(({ user: { user, accessToken } }) => ({ user, accessToken }))

  // We could just pull the user from cache, but in this specific case its probably best we refresh the user from the server
  const { data: userData, isFetching } = useQuery(
    QUERY_KEYS.user,
    userQuery
  )

  useEffect(function onUserChange () {
    if (!userData) return
    dispatch({ type: USER_ACTIONS.SET_USER, payload: { user: userData.user } })
  }, [dispatch, userData])

  return (
    <div>
      <NormalLayoutContainer>
        <div className='container-fluid'>
          <PageTitle title='User Profile'>
            <Link to={ROUTES.userProfileEdit} className='btn btn-lg btn-primary pull-right push-down-very-small space-left'>
              Edit Profile
            </Link>
            <Link to={ROUTES.changePassword} className='btn btn-lg btn-primary-dk pull-right push-down-very-small'>
              Change Password
            </Link>
          </PageTitle>
          <div className='push-down-super-small'>
            {
              (isFetching || !user)
                ? <Spinner isFetching />
                : <UserProfile user={user} />
            }
          </div>
        </div>
      </NormalLayoutContainer>
    </div>
  )
}
