import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import notie from 'notie'

class Alert extends Component {
  componentDidUpdate () {
    notie.alert(1, this.props.alert.message, 1.5)
  }

  render () {
    return (
      <div />
    )
  }
}

const mapStateToProps = state => ({
  alert: state.alert
})

Alert.propTypes = {
  alert: PropTypes.shape({
    message: PropTypes.string
  })
}

export default connect(mapStateToProps)(Alert)
