import React from 'react'

export default function Logo () {
  return (
    <img
      className='mx-auto'
      src='./Dispatcher-Primary-Vertical.svg'
      alt='Thumbster Connect'
      width='350px'
      height='350px'
    />
  )
}
