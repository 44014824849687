import { put, takeLatest } from 'redux-saga/effects'
import { handleError } from '../../../../utilities/handleError'
import { intercomConnection } from '../../../../intercomConnection'
import { loader } from 'graphql.macro'
import { makeGraphQLClient } from '../../../../utilities/graphqlClient'
import { camelCasedObjectToSnakeCasedObject } from '../../../../utilities/randomUtilities'
import notify from '../../../../utilities/notify'

export const REQUEST_HAULER_TEAM_MEMBER_ACTIVATE_ACTION = 'REQUEST_HAULER_TEAM_MEMBER_ACTIVATE_ACTION'
export const RECEIVE_HAULER_TEAM_MEMBER_ACTIVATE_SUCCESS = 'RECEIVE_HAULER_TEAM_MEMBER_ACTIVATE_SUCCESS'
export const RECEIVE_HAULER_TEAM_MEMBER_ACTIVATE_FAILURE = 'RECEIVE_HAULER_TEAM_MEMBER_ACTIVATE_FAILURE'

export const REQUEST_HAULER_TEAM_MEMBER_DEACTIVATE_ACTION = 'REQUEST_HAULER_TEAM_MEMBER_DEACTIVATE_ACTION'
export const RECEIVE_HAULER_TEAM_MEMBER_DEACTIVATE_SUCCESS = 'RECEIVE_HAULER_TEAM_MEMBER_DEACTIVATE_SUCCESS'
export const RECEIVE_HAULER_TEAM_MEMBER_DEACTIVATE_FAILURE = 'RECEIVE_HAULER_TEAM_MEMBER_DEACTIVATE_FAILURE'

function * requestActivateTeamMember (data) {
  try {
    const userId = data.payload
    const graphQLClient = makeGraphQLClient()
    const activateUserMutation = loader('../../../../graphql/mutations/activateUser.graphql')
    const response = yield graphQLClient.request(activateUserMutation, {
      input: {
        userId,
        status: 'ACTIVATE'
      }
    })
    response.activateUser.user = camelCasedObjectToSnakeCasedObject(response.activateUser.user)
    yield put({ type: RECEIVE_HAULER_TEAM_MEMBER_ACTIVATE_SUCCESS, payload: camelCasedObjectToSnakeCasedObject(response.activateUser) })
    yield intercomConnection.trackEvent('user_marked_active', {})
  } catch (e) {
    const errors = handleError(e)
    yield put({ type: RECEIVE_HAULER_TEAM_MEMBER_ACTIVATE_FAILURE, payload: { errors } })
  }
}

function * requestDeactivateTeamMember (data) {
  try {
    const userId = data.payload
    const graphQLClient = makeGraphQLClient()
    const deactivateUserMutation = loader('../../../../graphql/mutations/deactivateUser.graphql')
    const response = yield graphQLClient.request(deactivateUserMutation, {
      input: {
        userId
      }
    })
    response.deactivateUser.user = camelCasedObjectToSnakeCasedObject(response.deactivateUser.user)
    yield put({ type: RECEIVE_HAULER_TEAM_MEMBER_DEACTIVATE_SUCCESS, payload: camelCasedObjectToSnakeCasedObject(response.deactivateUser) })
    yield intercomConnection.trackEvent('user_marked_inactive', {})
  } catch (e) {
    // TODO: Determine if other graphql sagas should handle errors like this as well (see below for more)
    // Added to the saga itself otherwise the handleError function would need to handle graphql calls and with the other graphql calls (jobs and another one)
    // still through sagas, it felt a little heavy to change those other ones when they don't need a specific error
    const errorMessage = e.response.errors[0].message
    notify('error', errorMessage, 5)
    yield put({ type: RECEIVE_HAULER_TEAM_MEMBER_DEACTIVATE_FAILURE, payload: { errors: [{ code: 0, title: errorMessage }] } })
  }
}

export default function * haulerTeamMemberActivateDeactivateSaga () {
  yield takeLatest(REQUEST_HAULER_TEAM_MEMBER_ACTIVATE_ACTION, requestActivateTeamMember)
  yield takeLatest(REQUEST_HAULER_TEAM_MEMBER_DEACTIVATE_ACTION, requestDeactivateTeamMember)
}
