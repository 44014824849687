import React from 'react'
import { ActionText } from './shared/ActionText'
import Logo from '../components/Logo'
import PasswordResetForm from '../components/PasswordResetForm'
import { Link, useHistory } from 'react-router-dom'
import useMutation from '../hooks/useMutation'
import initializeForgotPassword from '../graphql/mutations/initializeForgotPassword'
import notify from '../utilities/notify'
import { Spinner } from './shared/Spinner'

export default function ForgotPasswordPage () {
  const history = useHistory()
  const { mutate: initializeForgotPasswordMutation, isLoading } = useMutation(initializeForgotPassword, {
    onSuccess () {
      notify('success', 'Check your email for password reset instructions.')
      history.push('/login')
    },
    onError () {
      notify('error', 'Server error')
    }
  })

  function handleSubmit (data) {
    initializeForgotPasswordMutation(data)
  }

  if (isLoading) {
    return <Spinner isFetching />
  }

  return (
    <div className='w-4/5 md:w-2/5 h-screen mx-auto mt-10'>
      <Logo />
      <ActionText text='Reset Password' />
      <PasswordResetForm onSubmit={handleSubmit} />
      <div className='row push-down-very-small'>
        <div className='center__all--small text-left'>
          <Link to='/login' className='btn btn-link'>Back to Login</Link>
        </div>
      </div>
    </div>
  )
}
