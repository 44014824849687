import { put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import BASE_URI from '../../../../utilities/BASE_URI'
import { handleError } from '../../../../utilities/handleError'
import { getHeaders } from '../../../../utilities/getHeaders'

export const REQUEST_TICKET_TYPES_ACTION = 'REQUEST_TICKET_TYPES_ACTION'
export const RECEIVE_TICKET_TYPES_SUCCESS = 'RECEIVE_TICKET_TYPES_SUCCESS'
export const RECEIVE_TICKET_TYPES_FAILURE = 'RECEIVE_TICKET_TYPES_FAILURE'
export const CLEAR_TICKET_TYPES_ERRORS = 'CLEAR_TICKET_TYPES_ERRORS'

function * requestTicketTypes () {
  try {
    const response = yield axios.get(`${BASE_URI}/hauler/ticket_types`, getHeaders())
    yield put({ type: RECEIVE_TICKET_TYPES_SUCCESS, payload: response.data })
  } catch (e) {
    const errors = handleError(e)
    yield put({ type: RECEIVE_TICKET_TYPES_FAILURE, payload: { errors } })
  }
}

export default function * ticketTypeSaga () {
  yield takeLatest(REQUEST_TICKET_TYPES_ACTION, requestTicketTypes)
}
