import React from 'react'
import { Link, generatePath } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ROUTES } from '../../../routes'

export default function TicketAccountContainer ({ account }) {
  return (
    <div className='dis-list-group'>
      <div className='dis-list-item dis-list-item-gray'>
        <div className='dis-list-group-header'>
          <h5>Account Details</h5>
          <Link className='dis-list-group-header-link' to={generatePath(ROUTES.client, { clientId: account.id })}>Go To Account</Link>
        </div>
      </div>
      <div className='dis-list-item'>
        <div className='dis-list-item-header'>Account Name</div>
        <div className='dis-list-item-text'>{account.name}</div>
      </div>
    </div>
  )
}

TicketAccountContainer.propTypes = {
  account: PropTypes.object.isRequired
}
