import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import notify from '../../utilities/notify'
import { ObjectParam, useQueryParams } from 'use-query-params'
import jobQuery from '../../graphql/queries/job'
import ticketSearchQuery from '../../graphql/queries/ticketsSearch'
import resourceSearchQuery from '../../graphql/queries/resourcesSearch'
import QUERY_KEYS from '../../graphql/queryKeys'
import { history } from '../../history'
import { googleMapsLink } from '../../utilities/locationUtilities'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import { PageTitle } from '../../components/page-title'
import JobStatusLabel from '../../components/JobStatusLabel'
import StaticGoogleMap from '../shared/locationPickers/StaticGoogleMap'
import { Spinner } from '../shared/Spinner'
import { action } from '../../store/store'
import styles from './index.module.scss'
import {
  REQUEST_UPDATE_CURRENT_JOB_STATUS_ACTION,
  REQUEST_DELETE_CURRENT_JOB_ACTION
} from '../hauler/jobs/sagas/currentJobSaga'
import { REQUEST_CURRENT_JOB_ACTIVITIES_ACTIONS } from '../hauler/jobs/sagas/currentJobActivitiesSaga'
import { REQUEST_BATCH_TICKETS_DELETE_ACTION } from '../hauler/tickets/sagas/batchTicketsDeleteSaga'
import JobDisplay from '../hauler/jobs/_id/index/components/JobDisplay'
import JobActionButton from '../hauler/jobs/_id/index/components/JobActionButton'
import JobResourcesDisplay from '../hauler/jobs/_id/index/components/JobResourcesDisplay'
import JobTicketsTable from '../hauler/jobs/_id/index/components/JobTicketsTable'
import JobActivitiesDisplay from '../hauler/jobs/_id/index/components/JobActivitiesDisplay'
import { ROUTES } from '../../routes'
import useQuery from '../../hooks/useQuery'
import useMutation from '../../hooks/useMutation'
import updateJob from '../../graphql/mutations/updateJob'
import addAssetToJobMutation from '../../graphql/mutations/addAssetToJob'
import deleteAssetFromJobMutation from '../../graphql/mutations/deleteAssetFromJob'
import SMSEnableButton from '../../components/sms-enable-button'

const pageSize = 10

export default function JobPage () {
  const { id } = useParams()
  const [openTicketsFakeCount, setOpenTicketsFakeCount] = useState(null)
  const [closedTicketsFakeCount, setClosedTicketsFakeCount] = useState(null)
  const [assetsFakeCount, setAssetsFakeCount] = useState(null)
  const [openTicketsPager, setOpenTicketsPager] = useState(null)
  const [closedTicketsPager, setClosedTicketsPager] = useState(null)
  const [assetsPager, setAssetsPager] = useState(null)
  const [isSMSEnabled, setIsSMSEnabled] = useState(null)

  const {
    form: { jobResourcesSearchForm },
    user,
    currentJobActivities
  } = useSelector((
    {
      user,
      form,
      currentJobActivities
    }
  ) => ({
    user: user.user,
    form,
    currentJobActivities
  }))

  const [queryParams, setQueryParams] = useQueryParams({
    sort: ObjectParam,
    pager: ObjectParam
  })

  const handleJobStatusChange = (newStatus) => {
    updateCurrentJobStatus(id, newStatus)
  }

  const { data: currentJobData, isFetching: currentJobFetching, refetch: refetchJob } = useQuery([
    QUERY_KEYS.job,
    id
  ],
  jobQuery,
  {
    onError () {
      notify('error', 'Error fetching job')
    }
  })

  const { data: openTickets, isFetching: openTicketsFetching, refetch: refetchOpenJobs } = useQuery([
    QUERY_KEYS.ticketsSearch,
    user.haulerId,
    10,
    { column: 'date', direction: 'desc' },
    { jobId: id, status: 'active' },
    openTicketsPager
  ],
  ticketSearchQuery,
  {
    enabled: Boolean(id),
    onError: (error) => handleTicketsError(error),
    placeholderData: { ticketsSearch: { nodes: [], totalCount: 0 } },
    keepPreviousData: true
  }
  )

  const { data: closedTickets, isFetching: closedTicketsFetching, refetch: refetchClosedJobs } = useQuery([
    QUERY_KEYS.ticketsSearch,
    user.haulerId,
    10,
    { column: 'date', direction: 'desc' },
    { jobId: id, status: 'terminal' },
    closedTicketsPager
  ],
  ticketSearchQuery,
  {
    enabled: Boolean(user.haulerId) && Boolean(id),
    onError: (error) => handleTicketsError(error),
    placeholderData: { ticketsSearch: { nodes: [], totalCount: 0 } },
    keepPreviousData: true
  }
  )

  const { data: assets, isFetching: assetsFetching, refetch: refetchAssets } = useQuery([
    QUERY_KEYS.assetsOnJob,
    {
      haulerId: user.haulerId,
      sort: queryParams.sort,
      jobId: id,
      assetsPager
    }
  ],
  resourceSearchQuery,
  {
    enabled: Boolean(user.haulerId),
    onError: (error) => handleError(error, 'Error fetching resources'),
    refetchOnWindowFocus: false
  })

  const { data: allAssets, isFetching: allAssetsFetching, refetch: refetchAllAssets } = useQuery([
    QUERY_KEYS.allAssets,
    {
      haulerId: user.haulerId,
      sort: {
        column: 'uid',
        direction: 'asc'
      },
      notJobId: id
    }
  ],
  resourceSearchQuery,
  {
    enabled: Boolean(user.haulerId),
    onError: (error) => handleError(error, 'Error fetching resources'),
    refetchOnWindowFocus: false
  })

  const { mutateAsync: updateSMS, isLoading: isUpdatingSMS } = useMutation(updateJob, {
    onSuccess (data) {
      refetchJob()
      notify('success', `Successfully ${data.job.smsActive ? 'Enabled' : 'Disabled'} SMS.`)
    },
    onError () {
      notify('error', 'Failed to enable/disable SMS on job')
    }
  })

  const { mutate: addAssetToJob, isLoading: addAssetToJobLoading } = useMutation(addAssetToJobMutation, {
    onSuccess: (data) => {
      refetchAssets()
      notify('success', 'Asset Updated Successfully!')
    },
    onError: error => {
      console.error(error)
      notify('error', 'Failed to Update Asset')
    },
    onSettled: refetchAllAssets
  })

  const { mutate: deleteAssetFromJob, isLoading: deleteAssetFromJobLoading } = useMutation(deleteAssetFromJobMutation, {
    onSuccess: (data) => {
      refetchAssets()
      notify('success', 'Asset Successfully Removed from Job!')
    },
    onError: error => {
      console.error(error)
      notify('error', 'Failed to Remove Asset')
    },
    onSettled: refetchAllAssets
  })

  useEffect(function initialSetOfSMSEnabled () {
    if (currentJobData?.job && isSMSEnabled === null) {
      setIsSMSEnabled(currentJobData.job.smsActive)
    }
  }, [currentJobData, isSMSEnabled])

  useEffect(function toggleFakeOpenTicketsPagination () {
    if (!openTickets.ticketsSearch.totalCount || openTicketsFetching || openTicketsFakeCount !== null) return
    setOpenTicketsFakeCount({ start: 1, end: Math.min(pageSize, openTickets.ticketsSearch.totalCount) })
  }, [openTickets.ticketsSearch.totalCount, openTickets, openTicketsFakeCount, setOpenTicketsFakeCount, openTicketsFetching])

  useEffect(function toggleFakeClosedTicketsPagination () {
    if (!closedTickets.ticketsSearch.totalCount || closedTicketsFetching || closedTicketsFakeCount !== null) return
    setClosedTicketsFakeCount({ start: 1, end: Math.min(pageSize, closedTickets.ticketsSearch.totalCount) })
  }, [closedTickets, closedTicketsFakeCount, setClosedTicketsFakeCount, closedTicketsFetching])

  function handleTicketsError () {
    notify('error', 'Failed to retrieve job tickets')
  }

  const handleEditJob = () => {
    history.push(`/hauler/jobs/${id}/edit`)
  }

  const onJobActivitiesPageRequest = (page = 1) => {
    action(REQUEST_CURRENT_JOB_ACTIVITIES_ACTIONS, { id, page })
  }

  const handleNewTicket = () => {
    history.push(`/hauler/tickets/new?job_id=${id}`)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    addAssetToJob({ assetId: jobResourcesSearchForm.values.id, jobId: id })
  }

  const handleRemoveResourceFromJob = (assetId) => {
    deleteAssetFromJob({ assetId: Number(assetId), setOnSelf: false })
  }

  function handleError (error, message) {
    console.error(error)
    notify('error', message)
  }

  const destroyCurrentJob = (job) => {
    action(REQUEST_DELETE_CURRENT_JOB_ACTION, { job })
  }

  const updateCurrentJobStatus = (id, status) => {
    action(REQUEST_UPDATE_CURRENT_JOB_STATUS_ACTION, { id, status })
  }

  const onBatchDeleteCallback = function * onBatchDeleteCallback () {
    yield refetchOpenJobs()
    yield refetchClosedJobs()
  }

  const onBatchDelete = (ids) => {
    // eslint-disable-next-line
    const confirmed = confirm(`Are you sure you want to delete ${ids.length} tickets? This CANNOT BE UNDONE.`);
    if (confirmed) {
      action(REQUEST_BATCH_TICKETS_DELETE_ACTION, {
        ids: ids.join(','),
        cb: onBatchDeleteCallback
      })
    }
  }

  const onBatchEdit = (ids) => {
    history.push(`${ROUTES.bulkEditTicketsPage}?ids=${ids.join(',')}`)
  }

  function onOpenTicketsPageRequest (direction, cursor) {
    setOpenTicketsPager({ cursor, direction, pageSize })

    if (!openTicketsFakeCount) return
    if (direction === 'before') {
      setOpenTicketsFakeCount(prevFakeCount => ({ start: prevFakeCount.start - pageSize, end: prevFakeCount.end - pageSize }))
    }
    if (direction === 'after') {
      setOpenTicketsFakeCount(prevFakeCount => ({ start: prevFakeCount.start + pageSize, end: prevFakeCount.end + pageSize }))
    }
  }

  function onClosedTicketsPageRequest (direction, cursor) {
    setClosedTicketsPager({ cursor, direction, pageSize })

    if (!closedTicketsFakeCount) return
    if (direction === 'before') {
      setClosedTicketsFakeCount(prevFakeCount => ({ start: prevFakeCount.start - pageSize, end: prevFakeCount.end - pageSize }))
    }
    if (direction === 'after') {
      setClosedTicketsFakeCount(prevFakeCount => ({ start: prevFakeCount.start + pageSize, end: prevFakeCount.end + pageSize }))
    }
  }

  function onAssetsPageRequest (direction, cursor) {
    setAssetsPager({ cursor, direction, pageSize })

    if (!closedTicketsFakeCount) return
    if (direction === 'before') {
      setAssetsFakeCount(prevFakeCount => ({ start: prevFakeCount.start - pageSize, end: prevFakeCount.end - pageSize }))
    }
    if (direction === 'after') {
      setAssetsFakeCount(prevFakeCount => ({ start: prevFakeCount.start + pageSize, end: prevFakeCount.end + pageSize }))
    }
  }

  useEffect(() => {
    // retrieveCurrentJob(id)
    action(REQUEST_CURRENT_JOB_ACTIVITIES_ACTIONS, { id, page: 1 })
  }, [id])

  useEffect(function setDefaultQueryParams () {
    const defaultQueryParams = {}
    if (!queryParams.sort) {
      defaultQueryParams.sort = { column: 'lastMoved', direction: 'asc' }
    }
    setQueryParams(defaultQueryParams, 'replaceIn')
  }, [id, assets, queryParams.sort, setQueryParams])

  async function handleSMSUpdate (isEnabledAtTimeOfClick) {
    await updateSMS({
      latitude: parseFloat(currentJobData.job.latitude),
      longitude: parseFloat(currentJobData.job.longitude),
      smsActive: !isEnabledAtTimeOfClick,
      id
    })
    setIsSMSEnabled(!isEnabledAtTimeOfClick)
  }

  return (
    <div>
      <NormalLayoutContainer
        showBackLink>
        <div className='container-fluid'>
          <PageTitle title={`Job #${currentJobData?.job?.customId || ''} for ${currentJobData?.job?.client?.name || ''}`}>

            <div>
              <h4 className='push-down-very-small pull-right'>
                Current Status:
                <span className='space-left'>
                  <JobStatusLabel status={currentJobData?.job?.status} />
                </span>
              </h4>
              <div>
                <div className='pull-right'>
                  <div className={cn(styles.headerButtonWrapper)}>
                    <JobActionButton
                      disabled={currentJobFetching}
                      status={currentJobData?.job?.status}
                      onTouchTap={handleJobStatusChange}
                    />
                    <div>
                      <SMSEnableButton
                        smsEnabled={isSMSEnabled}
                        handleUpdate={handleSMSUpdate}
                        isLoading={currentJobFetching || isUpdatingSMS}
                        step={'JOB'}
                        isParentEnabled={currentJobData?.job.client.smsActive}
                        levelName={currentJobData?.job?.customId}
                      />
                    </div>
                  </div>
                </div>
                <div className='pull-right' />
              </div>

            </div>

          </PageTitle>

          <br />
          {
            currentJobFetching
              ? <Spinner isFetching />
              : <div>
                <div className='row'>
                  <div className='col-xs-12 col-md-6'>
                    <JobDisplay
                      updateSMS={updateSMS}
                      isSMSLoading={isUpdatingSMS}
                      job={currentJobData?.job}
                      handleEdit={handleEditJob}
                    />
                  </div>
                  <div className='col-xs-12 col-md-5 col-md-offset-1'>
                    {
                      assetsFetching || addAssetToJobLoading || deleteAssetFromJobLoading || allAssetsFetching
                        ? <Spinner isFetching />
                        : <div>
                          <JobResourcesDisplay
                            jobCustomId={currentJobData?.job?.customId}
                            jobResources={assets?.assets}
                            allResources={allAssets?.assets?.nodes}
                            handleSubmit={handleSubmit}
                            handleRemoveResourceFromJob={handleRemoveResourceFromJob}
                            onPageRequest={onAssetsPageRequest}
                            fakeCount={assetsFakeCount}
                          />
                        </div>
                    }
                  </div>
                  <div className='col-xs-12'>
                    {
                      currentJobFetching
                        ? <Spinner isFetching />
                        : <div className='list-group row'>
                          <div className='list-group-item thumbster-gray-super-light-bg'>
                            <h5>
                              Job Location
                              &nbsp;
                              <a
                                href={() => googleMapsLink(currentJobData?.job)}
                                target='_blank'
                                rel='noopener noreferrer'>
                                <small className='text-primary link'>(View in Google Maps)</small>
                              </a>
                            </h5>
                          </div>
                          <div className='list-group-item'>
                            <StaticGoogleMap
                              mapClass='static-google-map-jobs'
                              latitude={currentJobData?.job?.latitude}
                              longitude={currentJobData?.job?.longitude}
                              place_id={currentJobData?.job?.placeId}
                              showLatLngInfo={true}
                              addrComponents={currentJobData?.job || ''}
                            />
                          </div>
                        </div>
                    }
                  </div>
                </div>
                <div className='row' id='open-tickets-scroll-container'>
                  <div className='col-xs-12'>
                    <JobTicketsTable
                      title='Open Tickets'
                      handleCreateTicket={handleNewTicket}
                      job={currentJobData?.job}
                      tickets={openTickets?.ticketsSearch}
                      onPageRequest={onOpenTicketsPageRequest}
                      fakeCount={openTicketsFakeCount}
                      onBatchDelete={onBatchDelete}
                      onBatchEdit={onBatchEdit}
                    />
                  </div>
                </div>
                <div className='row' id='terminal-tickets-scroll-container'>
                  <div className='col-xs-12'>
                    <JobTicketsTable
                      title='Closed Tickets'
                      job={currentJobData?.job}
                      tickets={closedTickets?.ticketsSearch}
                      onPageRequest={onClosedTicketsPageRequest}
                      fakeCount={closedTicketsFakeCount}
                      onBatchDelete={onBatchDelete}
                      onBatchEdit={onBatchEdit}
                    />
                  </div>
                </div>
                {
                  currentJobActivities.isFetching
                    ? <Spinner isFetching />
                    : <JobActivitiesDisplay
                        activities={currentJobActivities.activities}
                        pagination={currentJobActivities.pagination}
                        onPageRequest={onJobActivitiesPageRequest}
                      />
                }
                <button
                  className='btn btn-link center-block'
                  onClick={() => {
                    const result = window.confirm(
                      'THIS CANNOT BE UNDONE! Deleting a job will remove any record ' +
                      'that it existed including its tickets.')
                    if (result) {
                      destroyCurrentJob(currentJobData?.job)
                    }
                  }}>
                  <span className='pull-left'>Destroy Job</span>
                  <i className='material-icons pull-right thumbster-button-icon'>delete</i>
                </button>
                <br />
              </div>
          }
        </div>

      </NormalLayoutContainer>
    </div>
  )
}
