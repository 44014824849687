import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import QUERY_KEYS from '../../graphql/queryKeys'
import { Spinner } from '../shared/Spinner'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import PageTitle from '../../components/page-title'
import RefundForm from '../../components/refunds/RefundForm'
import invoicePaymentDetailsQuery from '../../graphql/queries/invoicePaymentDetails'
import dispatcherPaymentQuery from '../../graphql/queries/dispatcherPayment'
import invoiceSettingsQuery from '../../graphql/queries/invoiceSettings'
import createDispatcherRefund from '../../graphql/mutations/createDispatcherRefund'
import exportRefundToQuickbooksMutation from '../../graphql/mutations/exportRefundToQuickbooks'
import useQuery from '../../hooks/useQuery'
import useMutation from '../../hooks/useMutation'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import notify from '../../utilities/notify'
import TransactionConfirmation from '../../components/transaction-confirmation'
import { formatTransactionMethod } from '../../utilities/invoiceUtilities'

export default function DispatcherRefundPage () {
  const { invoiceId, paymentId } = useParams()
  const history = useHistory()
  const [refundError, setRefundError] = useState(null)
  const [refundData, setRefundData] = useState(null)
  const [refundEmails, setRefundEmails] = useState(null)

  const { hauler, accessToken } = useSelector(({ user }) => ({
    hauler: user.hauler,
    user: user.user,
    accessToken: user.accessToken
  }))

  const { data: invoiceSettingsData, isFetching: isFetchingInvoiceSettings } = useQuery([
    QUERY_KEYS.invoiceSettings,
    hauler.id
  ],
  invoiceSettingsQuery,
  {
    onError: (error) => captureErrorAndNotify(error, 'Error Fetching Invoice Settings')
  })

  const { data: invoiceData, isFetching: isFetchingInvoice } = useQuery(
    [QUERY_KEYS.invoicePaymentDetails, invoiceId],
    invoicePaymentDetailsQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Error fetching invoice details')
      }
    })

  const { data: { dispatcherPayment = {} } = {}, isFetching: isFetchingPayment } = useQuery(
    [QUERY_KEYS.dispatcherPayment, { paymentId }],
    dispatcherPaymentQuery,
    {
      onError: (error) => captureErrorAndNotify(error, 'Error fetching payment')
    })

  const { mutate: retryExportRefundToQuickbooks } = useMutation(exportRefundToQuickbooksMutation, {
    onSuccess (data) {
      setRefundData(data.dispatcherRefund)
      notify('success', `Refund for payment ${paymentId} was successfully exported to QuickBooks`)
    },
    onError (error, variables) {
      captureErrorAndNotify(error, `Failed to export refund for payment ${paymentId} to QuickBooks`)
    }
  })

  const pageTitle = useMemo(() => {
    if (isFetchingInvoice) return null

    if (refundData) return `Refund for Invoice #${invoiceData.invoice.dispatcherInvoiceNumber}`
    if (invoiceData?.invoice) return `Issue Refund for Invoice #${invoiceData.invoice.dispatcherInvoiceNumber}`

    return null
  }, [invoiceData?.invoice, isFetchingInvoice, refundData])

  const { mutateAsync: createRefund, isLoading: isRefunding } = useMutation(createDispatcherRefund, {
    onSuccess ({ dispatcherRefund }) {
      setRefundData(dispatcherRefund)
    },
    onError (mutationError) {
      const { response } = mutationError
      const [error] = response.errors
      setRefundError(error.message)
    }
  })

  function handleRetryExport () {
    retryExportRefundToQuickbooks({ invoiceId, dispatcherRefundId: refundData.id })
  }

  function handleSubmit (data) {
    setRefundError(null)
    const input = {
      dispatcherPaymentId: paymentId,
      amountToRefund: Number(data.refundAmount)
    }

    if (invoiceSettingsData.invoiceSettings.sendRefundReceipt) {
      const refundEmails = data.email.split(/[\s,]+/)
      input.sendTo = refundEmails.map(email => ({ email }))
      setRefundEmails(refundEmails)
    }
    createRefund(input)
  }

  function handleCancel () {
    history.goBack()
  }

  return (
    <NormalLayoutContainer showBackLink>
      <PageTitle>
        <div className='dis-page-title-flex'>
          <div>{pageTitle}</div>
          {!refundData && (
            <button
              type='button'
              className='dis-btn dis-btn-lg dis-btn-blank'
              onClick={handleCancel}>
              Cancel
            </button>
          )}
        </div>
      </PageTitle>
      {
        isFetchingInvoice || isFetchingInvoiceSettings || isFetchingPayment
          ? <Spinner isFetching />
          : (<>
            {refundData
              ? invoiceData && (
                <TransactionConfirmation
                  transactionType='Refund'
                  invoice={invoiceData.invoice}
                  retryQboExport={handleRetryExport}
                  transactions={[
                    {
                      id: refundData.id,
                      transactionType: 'Refund',
                      qboLink: refundData.qboRefundUrl,
                      qboExportStatus: refundData.qboExportStatus,
                      amount: refundData.refundAmount,
                      transactionDate: refundData.createdAt
                    }
                  ]}
                  receiptEmails={invoiceSettingsData.invoiceSettings.sendRefundReceipt ? refundEmails : undefined}
                  transactionQboExportStatus={dispatcherPayment.exportStatus}
                  transactionQboUrl={dispatcherPayment.qboUrl}
                />
              )
              : dispatcherPayment && invoiceData &&
                <RefundForm
                  hideCheckWastepayConnection={true}
                  handleSubmit={handleSubmit}
                  invoiceData={invoiceData}
                  invoiceSettingsData={invoiceSettingsData}
                  paymentMethod={formatTransactionMethod(dispatcherPayment)}
                  isRefunding={isRefunding}
                  transactionId={dispatcherPayment.id}
                  transactionAmount={Number(dispatcherPayment?.refundableAmount || 0)}
                  refundError={refundError}
                  accessToken={accessToken}
                />
            }
          </>)
      }
    </NormalLayoutContainer>
  )
}
