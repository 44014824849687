import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from '../../modal/Modal'
import ConfirmImportModal from '../confirm-import-modal'
import { Link } from 'react-router-dom'

export default function ConfirmPanel ({ onConfirm, disabled, pluralImportName, hasError, returnTo }) {
  const [modalOpen, setModalOpen] = useState(false)

  function handleConfirm () {
    if (hasError) {
      setModalOpen(true)
      return
    }
    onConfirm()
  }

  return (
    <>
      <Modal className='w-full' isOpen={modalOpen}>
        <ConfirmImportModal onConfirm={onConfirm} onCancel={() => setModalOpen(false)} pluralImportName={pluralImportName} />
      </Modal>

      <div className='my-12'>
        <h4 className='text-center'>{`Review and Confirm ${pluralImportName.replace(/^./, pluralImportName[0].toUpperCase())} to Finalize Import`}</h4>

        <div className='flex justify-center my-16'>
          <button className='btn btn-primary btn-lg mr-6 font-medium' onClick={handleConfirm} disabled={disabled}>
            Confirm and Create {pluralImportName}
            <i className='material-icons pull-right thumbster-button-icon no-margin-bs-xs'>done</i>
          </button>

          <Link to={returnTo}>
            <button className='btn btn-lg'>
              <span className='font-medium'>Return to Upload</span>
            </button>
          </Link>
        </div>
      </div>
    </>
  )
}

ConfirmPanel.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  pluralImportName: PropTypes.string.isRequired,
  hasError: PropTypes.bool.isRequired,
  returnTo: PropTypes.string.isRequired
}
