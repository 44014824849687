import { loader } from 'graphql.macro'
import { resourcesSortColumnEnumMap } from '../enums'

export default async function resourceSearchQuery ({ dataLoaders, queryKey }) {
  const [, variables] = queryKey
  const sortColumnEnum = variables.sort?.column ? resourcesSortColumnEnumMap[variables.sort.column] : null
  if (!sortColumnEnum) {
    throw new Error(`Invalid sort.column: ${variables.sort?.column || 'undefined'}`)
  }

  const schema = loader('./resourcesSearch.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(schema, {
    haulerId: variables.haulerId,
    sortColumn: sortColumnEnum,
    sortDirection: variables.sort.direction.toUpperCase(),
    searchFields: {
      minIdleDays: Number(variables.minIdleDays) || null,
      maxIdleDays: Number(variables.maxIdleDays) || null,
      accountName: !variables.relatedType ? variables.relatedSearch : null,
      addressSearch: variables.addressSearch,
      city: variables.city,
      jobCustomId: !variables.relatedType || variables.relatedType === 'JOB_CUSTOM_ID' ? variables.relatedSearch : null,
      jobId: variables.jobId,
      resourceTypeId: variables.resourceTypeId,
      siteId: variables.siteId,
      siteName: !variables.relatedType || variables.relatedType === 'SITE_NAME' ? variables.relatedSearch : null,
      uid: variables.uid,
      userId: variables.userId,
      userName: !variables.relatedType || variables.relatedType === 'USER_NAME' ? variables.relatedSearch : null,
      zipCode: variables.zipCode,
      notJobId: variables?.notJobId,
      notSiteId: variables?.notSiteId
    },
    onlyOnJobs: variables.relatedType === 'JOB_CUSTOM_ID',
    onlyOnSites: variables.relatedType === 'SITE_NAME',
    onlyOnUsers: variables.relatedType === 'USER_NAME',
    first: variables.first,
    last: variables.last,
    before: variables.before,
    after: variables.after
  })
}
