import { loader } from 'graphql.macro'

export default function invoiceSettingsQuery ({ dataLoaders, queryKey }) {
  const [, haulerId, includePaymentTerms = false] = queryKey

  const graphQLVariables = {
    haulerId,
    includePaymentTerms
  }

  const invoiceSettingsSchema = loader('./invoiceSettings.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(invoiceSettingsSchema, graphQLVariables)
}
