import { loader } from 'graphql.macro'

export default function wastepayTransactionsQuery ({ dataLoaders, queryKey }) {
  const [, variables] = queryKey
  const wastepayTransactionsSchema = loader('./wastepayTransactions.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(wastepayTransactionsSchema, {
    invoiceId: variables.invoiceId,
    latestPayment: variables.latestPayment
  })
}
