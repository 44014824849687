import React, { useEffect, useState, createRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { arrayMove } from 'react-movable'
import sortBy from 'lodash/sortBy'
import { userInitials } from '../utilities/stringUtilities'
import { DispatchUserSortableTicketsList } from './DispatchUserSortableTicketsList'

export default function DispatchUserTickets ({
  user,
  onUpdateTicketFlag,
  onAssignTicketToUser,
  isPanelOpen,
  onTogglePanel,
  onReorderTickets,
  onTicketHoverStart,
  onTicketHoverEnd,
  onUserHoverStart,
  onUserHoverEnd
}) {
  const [ticketListMaxHeight, setTicketListMaxHeight] = useState('0px')
  const [reordering, setReordering] = useState(false)
  const ticketListRef = createRef()

  const tickets = useMemo(() => {
    return sortBy(user.tickets, ['position', 'id'])
  }, [user.tickets])

  useEffect(() => {
    if (ticketListRef && ticketListRef.current) {
      setTicketListMaxHeight(isPanelOpen && tickets.length ? `${ticketListRef.current.scrollHeight}px` : '0px')
    }
  }, [isPanelOpen, tickets, ticketListRef])

  function handleTogglePanel () {
    if (tickets.length === 0) return
    onTogglePanel(`user:${user.id}`)
  }

  function handleSortEnd (result) {
    setReordering(false)
    if (!result.destination) return
    if (result.source.index === result.destination.index) return

    const movedTickets = arrayMove(tickets, result.source.index, result.destination.index)
    onReorderTickets(movedTickets.reduce((acc, ticket, currentIndex) => {
      return { ...acc, [ticket.id]: { position: currentIndex + 1 } }
    }, {}))
  }

  function handleSortStart () {
    setReordering(true)
  }

  function handleUserHoverStart () {
    onUserHoverStart(user.id)
  }

  return (
    <div className='no-margin-bottom'>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className='p-6 border border-gray-light-10-dark -mb-1 user-item-display cursor-pointer'
        onClick={handleTogglePanel}
        onMouseEnter={handleUserHoverStart}
        onMouseLeave={onUserHoverEnd}>
        <div className='-mx-6'>
          <div className='px-6 mx-auto -mt-4'>
            <span className='float-left badge badge-white w-1/12 mr-6'>{tickets.length}</span>
            <div className='float-left font-size-1-5em text-ellipsis'>
              {user.firstName} {user.lastName}
            </div>
            <span className='inline-block font-size-1-em mt-2 float-right'>
              ({userInitials(user)})
            </span>
          </div>
        </div>
      </div>
      <div
        ref={ticketListRef}
        style={{ maxHeight: ticketListMaxHeight }}
        className={classNames('m-0 p-0 dispatch-tickets-panel no-margin-bottom', {
          'dispatch-tickets-panel_open': isPanelOpen,
          'dispatch-tickets-panel_closed': !isPanelOpen
        })}>
        <DispatchUserSortableTicketsList
          onUpdateTicketFlag={onUpdateTicketFlag}
          onAssignTicketToUser={onAssignTicketToUser}
          tickets={tickets}
          reordering={reordering}
          onSortEnd={handleSortEnd}
          onSortStart={handleSortStart}
          onTicketHoverStart={onTicketHoverStart}
          onTicketHoverEnd={onTicketHoverEnd}
        />
      </div>
    </div>
  )
}

DispatchUserTickets.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    tickets: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      position: PropTypes.number
    })).isRequired
  }).isRequired,
  onAssignTicketToUser: PropTypes.func.isRequired,
  onUpdateTicketFlag: PropTypes.func.isRequired,
  isPanelOpen: PropTypes.bool.isRequired,
  onTogglePanel: PropTypes.func.isRequired,
  onReorderTickets: PropTypes.func.isRequired,
  onTicketHoverStart: PropTypes.func.isRequired,
  onTicketHoverEnd: PropTypes.func.isRequired,
  onUserHoverStart: PropTypes.func.isRequired,
  onUserHoverEnd: PropTypes.func.isRequired
}
