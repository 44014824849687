import React from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import { Form, Field } from 'react-final-form'
import cn from 'classnames'
import { maxStrLength } from '../../utilities/commonValidators'

export default function ClientsTableSearchForm ({
  onSubmit,
  onClear,
  disabled = false,
  initialValues = {},
  hideButtons = {},
  showTicketStatusSelect = false,
  handleExportClients
}) {
  function handleClear (form) {
    form.reset({})
    onClear()
  }

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, form, values }) => (
        <form
          className={
            cn(styles.form, {
              [styles.multipleInputs]: showTicketStatusSelect
            })}
          onSubmit={handleSubmit}>
          {/*
            TODO This form-control is the last bootstrap we have in this component. Unfortunately replacing
            this styling with our own code is just out of scope for the current change.
          */}
          <div className={styles.inputs}>
            <Field name='generalSearch' validate={maxStrLength(100)}>
              {({ input }) => (
                <input
                  {...input}
                  className='form-control'
                  type='search'
                  autoComplete='off'
                  placeholder='Search by Account Names, Account IDs, Contact Names, and Phone Numbers'
                  maxLength={100}
                />
              )}
            </Field>
            {showTicketStatusSelect &&
              <label className='dis-form-label-input-wrapper'>
                Filter by Ticket Status
                <Field name='ticketStatusSelect' component='select'>
                  {({ input }) => (
                    <select
                      {...input}>
                      <option value=''></option>
                      <option value='OPEN'>Open</option>
                      <option value='EN_ROUTE'>En Route</option>
                      <option value='COMPLETED'>Completed</option>
                      <option value='CANCELLED'>Cancelled</option>
                    </select>
                  )}
                </Field>
              </label>
            }
          </div>
          <div className={styles.btns}>
            <button
              type='submit'
              className={cn(styles.btn, 'dis-btn dis-btn-success dis-btn-sm')}
              disabled={disabled}>
              Search
              <i className='material-icons dis-btn-icon'>search</i>
            </button>
            {!hideButtons.clear &&
              <button
                type='button'
                onClick={() => handleClear(form)}
                className={cn(styles.btn, 'dis-btn dis-btn-white dis-btn-sm')}
                disabled={disabled}>
                Clear
                <i className='material-icons dis-btn-icon'>clear</i>
              </button>
            }
            {!hideButtons.export &&
              <button
                type='button'
                onClick={() => handleExportClients(values.generalSearch)}
                className={cn(styles.btn, 'dis-btn dis-btn-primary-dk dis-btn-sm')}
                disabled={disabled}>
                Export
                <i className='material-icons dis-btn-icon'>get_app</i>
              </button>
            }
          </div>
        </form>
      )}
    </Form>
  )
}

ClientsTableSearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  handleExportClients: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  initialValues: PropTypes.shape({
    generalSearch: PropTypes.string
  }),
  hideButtons: PropTypes.shape({
    clear: PropTypes.bool,
    export: PropTypes.bool
  }),
  showTicketStatusSelect: PropTypes.bool
}
