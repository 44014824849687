export const randomAlphabeticString = len => {
  let text = ''
  const possible = 'abcdefghijklmnopqrstuvwxyz'

  for (let i = 0; i < len; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return text
}

export function camelCasedObjectToSnakeCasedObject (objToMap) {
  const objKeys = Object.keys(objToMap)
  const formattedObject = {}
  objKeys.forEach(key => {
    const formattedKey = key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
    formattedObject[formattedKey] = objToMap[key]
  })

  return formattedObject
}

export function snakeCasedObjectToCamelCasedObject (objToMap) {
  const objKeys = Object.keys(objToMap)
  const formattedObject = {}
  objKeys.forEach(key => {
    const formattedKey = key.toLowerCase().replace(/([-_][a-z])/g, letters =>
      letters
        .toUpperCase()
        .replace('-', '')
        .replace('_', '')
    )
    formattedObject[formattedKey] = objToMap[key]
  })
  return formattedObject
}
/* react-final-form does not submit empty form values as outlined here https://github.com/final-form/react-final-form/issues/130
this can cause issues for forms with initial values that need to be cleared.
Instead of having to parse every input for these forms this can be used to format the values */
export function formatFormValuesForSubmit (initialValues, values) {
  return Object.keys(initialValues).reduce((acc, key) => {
    acc[key] = typeof values[key] === 'undefined' ? '' : values[key]
    return acc
  }, {})
}
