import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import PropTypes from 'prop-types'
import { HorizontalBar } from 'react-chartjs-2'
import {
  determineData,
  determineTicketsByUser,
  getElementAtEvent,
  options
} from './TicketsPerDriverGraphUtils'

export default function TicketsPerDriverGraph ({ tickets }) {
  const history = useHistory()

  const ticketsByUser = determineTicketsByUser(tickets)

  const handleClick = useCallback((element) => {
    getElementAtEvent(element, history, ticketsByUser)
  }, [history, ticketsByUser])

  const keys = Object.keys(ticketsByUser)
  const data = determineData(keys, ticketsByUser)

  return (
    <div className='text-center'>
      <div className='push-down-very-small'>
        <HorizontalBar
          data={data}
          options={options}
          width={1000}
          height={500}
          onElementsClick={handleClick}
        />
      </div>
    </div>
  )
}

TicketsPerDriverGraph.propTypes = {
  tickets: PropTypes.array
}
