import React from 'react'

export default function CsvExportExplainer () {
  return (
    <div className='flex justify-center p-4'>
      <div>
        <div className='font-semibold'>How to in Excel</div>
        <ol className='text-lg'>
          <li>
            1. In your Excel spreadsheet, click File
          </li>
          <li>
            2. Click Save As
          </li>
          <li>
            3. Select &quot;CSV&quot; from the &quot;Save as type&quot; drop-down menu
          </li>
          <li>
            4. Click Save
          </li>
        </ol>
      </div>
      <div className='w-0.5 bg-gray-dark bg-opacity-50 mx-12'></div>
      <div>
        <div className='font-semibold'>
          How to in Google Sheets
        </div>
        <ol className='text-lg'>
          <li>
            1. In the top left, click File
          </li>
          <li>
            2. Click Download As
          </li>
          <li>
            3. Select &quot;CSV&quot; from the menu
          </li>
        </ol>
      </div>
    </div>
  )
}
