import { put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import BASE_URI from '../../../../utilities/BASE_URI'
import { handleError } from '../../../../utilities/handleError'
import { getHeaders } from '../../../../utilities/getHeaders'
import notify from '../../../../utilities/notify'
import { intercomConnection } from '../../../../intercomConnection'

export const REQUEST_BATCH_TICKETS_DELETE_ACTION = 'REQUEST_BATCH_TICKETS_DELETE_ACTION'
export const RECEIVE_BATCH_TICKETS_DELETE_SUCCESS = 'RECEIVE_BATCH_TICKETS_DELETE_SUCCESS'
export const RECEIVE_BATCH_TICKETS_DELETE_FAILURE = 'RECEIVE_BATCH_TICKETS_DELETE_FAILURE'

function * requestDeleteTickets (data) {
  try {
    const { ids, cb } = data.payload
    yield axios.delete(
      `${BASE_URI}/hauler/tickets/destroy_all?ids=${ids}`,
      getHeaders()
    )
    yield put({ type: RECEIVE_BATCH_TICKETS_DELETE_SUCCESS })
    intercomConnection.trackEvent('batch_tickets_deleted', { ids })
    notify('success', 'Deleted tickets successfully!')
    yield * cb ? cb() : function * generic () { yield }
  } catch (e) {
    const errors = handleError(e)
    yield put({ type: RECEIVE_BATCH_TICKETS_DELETE_FAILURE, payload: { errors } })
  }
}

export default function * batchTicketsDeleteSaga () {
  yield takeLatest(REQUEST_BATCH_TICKETS_DELETE_ACTION, requestDeleteTickets)
}
