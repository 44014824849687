import { put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import { history } from '../../../../history'
import BASE_URI from '../../../../utilities/BASE_URI'
import { handleError } from '../../../../utilities/handleError'
import { getHeaders } from '../../../../utilities/getHeaders'
import { intercomConnection } from '../../../../intercomConnection'
import notify from '../../../../utilities/notify'

export const REQUEST_SITE_TYPES_ACTION = 'REQUEST_SITE_TYPES_ACTION'
export const RECEIVE_SITE_TYPES_SUCCESS = 'RECEIVE_SITE_TYPES_SUCCESS'
export const RECEIVE_SITE_TYPES_FAILURE = 'RECEIVE_SITE_TYPES_FAILURE'
export const CLEAR_SITE_TYPES_ERRORS = 'CLEAR_SITE_TYPES_ERRORS'

export const REQUEST_SITE_TYPE_CREATE_ACTION = 'REQUEST_SITE_TYPE_CREATE_ACTION'
export const RECEIVE_SITE_TYPE_CREATE_SUCCESS = 'RECEIVE_SITE_TYPE_CREATE_SUCCESS'
export const RECEIVE_SITE_TYPE_CREATE_FAILURE = 'RECEIVE_SITE_TYPE_CREATE_FAILURE'
export const CLEAR_SITE_TYPE_CREATE_ERRORS = 'CLEAR_SITE_TYPE_CREATE_ERRORS'

export const REQUEST_SITE_TYPES_DISABLE_ACTION = 'REQUEST_SITE_TYPES_DISABLE_ACTION'
export const RECEIVE_SITE_TYPES_DISABLE_SUCCESS = 'RECEIVE_SITE_TYPES_DISABLE_SUCCESS'
export const RECEIVE_SITE_TYPES_DISABLE_FAILURE = 'RECEIVE_SITE_TYPES_DISABLE_FAILURE'
export const CLEAR_SITE_TYPES_DISABLE_ERRORS = 'CLEAR_SITE_TYPES_DISABLE_ERRORS'

export const REQUEST_SITE_TYPES_ENABLE_ACTION = 'REQUEST_SITE_TYPES_ENABLE_ACTION'
export const RECEIVE_SITE_TYPES_ENABLE_SUCCESS = 'RECEIVE_SITE_TYPES_ENABLE_SUCCESS'
export const RECEIVE_SITE_TYPES_ENABLE_FAILURE = 'RECEIVE_SITE_TYPES_ENABLE_FAILURE'
export const CLEAR_SITE_TYPES_ENABLE_ERRORS = 'CLEAR_SITE_TYPES_ENABLE_ERRORS'

function * requestSiteTypes () {
  try {
    const response = yield axios.get(
      `${BASE_URI}/hauler/site_types`,
      getHeaders()
    )
    yield put({ type: RECEIVE_SITE_TYPES_SUCCESS, payload: response.data })
  } catch (e) {
    const errors = handleError(e)
    yield put({ type: RECEIVE_SITE_TYPES_FAILURE, payload: { errors } })
  }
}

function * requestCreateSiteType (data) {
  try {
    const { siteType } = data.payload
    const response = yield axios.post(
      `${BASE_URI}/hauler/site_types`,
      siteType,
      getHeaders()
    )
    yield put({ type: RECEIVE_SITE_TYPE_CREATE_SUCCESS, payload: response.data })
    yield history.push('/hauler/site-types')
    yield notify('success', 'Site Type created successfully')
  } catch (e) {
    const siteTypeErrors = e.response.data.errors
    const errArray = []
    siteTypeErrors.map(err => {
      if (err.title.toLowerCase() === 'short code has already been taken' || err.title.toLowerCase() === 'name has already been taken') {
        errArray.push(err.title)
      }
      return null
    })
    const errors = errArray.length ? errArray : handleError(e)
    yield put({ type: RECEIVE_SITE_TYPE_CREATE_FAILURE, payload: { errors } })
  }
}

function * requestDisableSiteType (data) {
  try {
    const id = data.payload
    const response = yield axios.post(
      `${BASE_URI}/hauler/site_types/${id}/disable`,
      {},
      getHeaders()
    )
    yield put({ type: RECEIVE_SITE_TYPES_DISABLE_SUCCESS, payload: response.data })
    yield intercomConnection.trackEvent('site_type_disabled', {})
    yield history.push('/hauler/site-types')
    yield notify('success', 'Site Type successfully disabled')
  } catch (e) {
    const errors = handleError(e)
    yield put({ type: RECEIVE_SITE_TYPES_DISABLE_FAILURE, payload: { errors } })
  }
}

function * requestEnableSiteType (data) {
  try {
    const id = data.payload
    const response = yield axios.post(
      `${BASE_URI}/hauler/site_types/${id}/enable`,
      {},
      getHeaders()
    )
    yield put({ type: RECEIVE_SITE_TYPES_ENABLE_SUCCESS, payload: response.data })
    yield intercomConnection.trackEvent('site_type_enabled', {})
    yield history.push('/hauler/site-types')
    yield notify('success', 'Site Type successfully enabled')
  } catch (e) {
    const errors = handleError(e)
    yield put({ type: RECEIVE_SITE_TYPES_ENABLE_FAILURE, payload: { errors } })
  }
}

export default function * siteTypesSaga () {
  yield takeLatest(REQUEST_SITE_TYPES_ACTION, requestSiteTypes)
  yield takeLatest(REQUEST_SITE_TYPE_CREATE_ACTION, requestCreateSiteType)
  yield takeLatest(REQUEST_SITE_TYPES_DISABLE_ACTION, requestDisableSiteType)
  yield takeLatest(REQUEST_SITE_TYPES_ENABLE_ACTION, requestEnableSiteType)
}
