import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import notie from 'notie'

export default function Error ({ errors, clearErrors }) {
  const previousErrors = useRef()
  useEffect(function updatePreviousErrors () {
    previousErrors.current = errors
  }, [errors])

  useEffect(function onErrorChange () {
    const different = errors !== previousErrors.current
    if (!different) return
    if (!errors || errors.length === 0) return

    let errorText
    if (Array.isArray(errors)) {
      errorText = errors.map(error => error?.title ?? '').join('\n\r')
    }
    errorText = errorText || 'An Unknown Error Ocurred'
    notie(3, errorText, 5)
    clearErrors()
  }, [errors, clearErrors])

  return null
}

Error.propTypes = {
  clearErrors: PropTypes.func,
  errors: PropTypes.any
}
