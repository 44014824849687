import { gql } from 'graphql-request'

export const executeRetrieveTicketTypesQuery = (graphQLClient, haulerId) => {
  const query = gql`
    query {
      ticketTypes(haulerId: ${haulerId}) {
        id
        name
        shortCode
        requireAddImage
        requireAddAsset
        requireRemoveAsset
        requireEnterWeight
      }
    }
  `
  return graphQLClient.request(query)
}

export const executeTicketTypeRequiredActionsMutation = (graphQLClient, params) => {
  const mutation = gql`
    mutation updateTicketTypeRequiredActions($input: UpdateTicketTypeRequiredActionsInput!) {
      updateTicketTypeRequiredActions(input: $input) {
        success
      }
    }
  `
  return graphQLClient.request(mutation, { input: params })
}

export const executeRetrieveHauler = (graphQLClient, haulerId) => {
  const query = gql`
    query {
      hauler(haulerId: ${haulerId}) {
        requireTicketCompletion
      }
    }
  `
  return graphQLClient.request(query)
}

export const executeUpdateHaulerTicketCompletionFlagMutation = (graphQLClient, params) => {
  const mutation = gql`
    mutation UpdateHaulerTicketCompletionFlag($input: UpdateHaulerTicketCompletionFlagInput!) {
      updateHaulerTicketCompletionFlag(input: $input) {
        success
      }
    }
  `
  return graphQLClient.request(mutation, { input: params })
}
