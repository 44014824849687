import React from 'react'
import PropTypes from 'prop-types'
import { store } from './store/store'
import { history } from './history'
import { Provider as ReduxProvider } from 'react-redux'
import { Router as ReactRouter } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

/**
 * Central place to place all of our providers - https://www.patterns.dev/posts/provider-pattern/
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export default function Providers ({ children }) {
  return (
    <>
      {/* ReduxProvider sets up the react-redux NPM package - https://react-redux.js.org/api/provider */ }
      <ReduxProvider store={store}>
        {/* ReactRouter sets up the react-router-dom package - https://v5.reactrouter.com/web/api/Router */}
        <ReactRouter history={history}>
          {/* QueryClientProvider is need to set up the react-query NPM package
          https://tanstack.com/query/v4/docs/reference/QueryClientProvider */}
          <QueryClientProvider client={queryClient}>
            {/* QueryParamProvider is needed to set up the use-query-params NPM package
             https://github.com/pbeshai/use-query-params/tree/master/packages/use-query-params#installation */}
            <QueryParamProvider adapter={ReactRouter5Adapter} options={{ includeAllParams: true, updateType: 'replaceIn' }}>
              {children}
            </QueryParamProvider>
          </QueryClientProvider>
        </ReactRouter>
      </ReduxProvider>
    </>
  )
}

Providers.propTypes = {
  children: PropTypes.node.isRequired
}
