import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, Link } from 'react-router-dom'
import NoResults from '../../pages/shared/NoResults'
import { ROUTES } from '../../routes'
import TableHeader from '../table-header'
import CursorPagination from '../cursor-pagination'
import InvoiceStatusLabel from '../../components/invoice/InvoiceStatusLabel'
import { commaDeliminate } from '../../utilities/stringUtilities'
import OpenStatus from './action/open-status'
import PaidStatus from './action/PaidStatus'
import ClosedInQboStatus from './action/ClosedInQboStatus'
import dayjs from '../../utilities/dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localizedFormat)

export default function InvoicesTable ({
  invoices,
  sort,
  onSortChange,
  onPageRequest,
  fakeCount,
  hauler,
  invoiceProvider
}) {
  return (
    <div className='dis-panel'>
      <div className='row dis-panel-body'>
        <div className='col-xs-12'>
          <table className='table table-responsive table-striped'>
            <thead>
              <tr>
                <th>
                  <TableHeader
                    columnName='invoiceNumber'
                    label='Invoice No.'
                    sort={sort}
                    onSortChange={onSortChange}
                  />
                </th>
                {(hauler.quickbooks.isConnected && !hauler.quickbooks.sendInvoiceNumberToQbo) && (
                  <th>
                    <TableHeader
                      columnName='qboNumber'
                      label='QBO No.'
                      sort={sort}
                      onSortChange={onSortChange}
                    />
                  </th>
                )}
                <th>
                  <TableHeader
                    columnName='accountName'
                    label='Account'
                    sort={sort}
                    onSortChange={onSortChange}
                  />
                </th>
                <th>
                  <TableHeader
                    columnName='createdAt'
                    label='Created At'
                    sort={sort}
                    onSortChange={onSortChange}
                  />
                </th>
                {invoiceProvider.toUpperCase() === 'DISPATCHER' && <th>
                  <TableHeader
                    columnName='dueDate'
                    label='Due Date'
                    sort={sort}
                    onSortChange={onSortChange}
                  />
                </th>}
                <th>
                  <TableHeader
                    columnName='status'
                    label='Status'
                    sort={sort}
                    onSortChange={onSortChange}
                  />
                </th>
                <th>Amount</th>
                {hauler.quickbooks.isConnected && (
                  <th>
                    <TableHeader
                      columnName='qboUploadStatus'
                      label='QBO Sync'
                      sort={sort}
                      onSortChange={onSortChange}
                    />
                  </th>
                )}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {invoices.nodes.length === 0 && <tr><td colSpan={8}><NoResults /></td></tr>}

              {invoices.nodes.map(invoice => {
                const qboDocNumber = invoice.qboDocNumber ?? invoice.qboDocNumberOld
                return (
                  <tr key={invoice.id}>
                    <td>
                      <Link to={generatePath(ROUTES.invoice, { id: invoice.id })}>
                        {invoice.invoiceNumber}
                      </Link>
                    </td>
                    { (hauler.quickbooks.isConnected && !hauler.quickbooks.sendInvoiceNumberToQbo) && (
                      <td>
                        {qboDocNumber
                          ? (<a href={invoice.qboUrl} target='_blank noopener noreferrer'>{qboDocNumber}</a>)
                          : '--'}
                      </td>
                    )}
                    <td>
                      <Link to={`/hauler/clients/${invoice.client.id}`}>{invoice.client.name}</Link>
                    </td>
                    <td>
                      {dayjs(invoice.createdAt).format('ll')}
                    </td>
                    {invoiceProvider.toUpperCase() === 'DISPATCHER' && <td>
                      {!invoice.dueDate ? 'Due upon receipt' : dayjs(invoice.dueDate).format('ll')}
                    </td>}
                    <td>
                      <InvoiceStatusLabel>{invoice.status}</InvoiceStatusLabel>
                    </td>
                    <td>
                      ${commaDeliminate(invoice.total)}
                    </td>
                    {hauler.quickbooks.isConnected && (
                      <td>
                        {invoice.qboSync}
                      </td>
                    )}
                    <td>
                      <div className='btn-group'>
                        <Link to={generatePath(ROUTES.invoice, { id: invoice.id })} className='btn btn-sm btn-primary-dk'>
                          Details
                        </Link>
                        <button className='btn btn-primary-dk btn-sm dropdown-toggle hidden-xs hidden-sm drop-down' data-toggle='dropdown'>
                          <span className='caret' />
                        </button>
                        {invoice.status.toUpperCase() === 'PAID' && hauler.quickbooks.isConnected && <PaidStatus invoice={invoice} />}
                        {invoice.status.toUpperCase() === 'OPEN' && <OpenStatus invoice={invoice} hauler={hauler} />}
                        {invoice.status.toUpperCase() === 'CLOSED_IN_QBO' && hauler.quickbooks.isConnected && <ClosedInQboStatus invoice={invoice} />}
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          {invoices.nodes.length > 0 && (
            <CursorPagination
              pageInfo={invoices.pageInfo}
              totalCount={invoices.totalCount}
              onPageRequest={onPageRequest}
              fakeCount={fakeCount}
            />
          )}
        </div>
      </div>
    </div>
  )
}

InvoicesTable.propTypes = {
  hauler: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isWastepayApproved: PropTypes.bool.isRequired,
    canCreateInvoice: PropTypes.bool.isRequired,
    quickbooks: PropTypes.shape({
      isConnected: PropTypes.bool.isRequired,
      sendInvoiceNumberToQbo: PropTypes.bool.isRequired
    }).isRequired
  }),
  invoices: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      ticketsCount: PropTypes.number.isRequired,
      invoiceNumber: PropTypes.string,
      qboDocNumber: PropTypes.string,
      qboUrl: PropTypes.string,
      status: PropTypes.string.isRequired,
      createdAt: PropTypes.string,
      client: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired,
      total: PropTypes.number,
      qboSync: PropTypes.string
    })).isRequired,
    pageInfo: PropTypes.shape({
      endCursor: PropTypes.string,
      hasNextPage: PropTypes.bool.isRequired,
      hasPreviousPage: PropTypes.bool.isRequired,
      startCursor: PropTypes.string
    }).isRequired,
    totalCount: PropTypes.number.isRequired
  }).isRequired,
  sort: PropTypes.shape({
    column: PropTypes.oneOf(['qboDocNumber', 'createdAt', 'accountName', 'qboUploadStatus', 'status', 'ticketsCount']).isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired
  }).isRequired,
  onPageRequest: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  fakeCount: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired
  }),
  invoiceProvider: PropTypes.string.isRequired
}
