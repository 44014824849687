import React from 'react'
import PropTypes from 'prop-types'
import { Pie } from 'react-chartjs-2'
import {
  determineData,
  determineDataLabels,
  convertLocationsToList,
  options
} from './ResourcesLocationsChartUtils'

export default function ResourcesLocationsChart ({ chartLabels }) {
  const dataLabels = determineDataLabels(chartLabels)
  const locationsCount = convertLocationsToList(chartLabels)
  const data = determineData(dataLabels, locationsCount)

  return (
    <div className='text-center'>
      <h3>Asset Locations (Total {locationsCount.reduce((total, count) => total + count)})</h3>
      <div className='push-down-small'>
        <Pie data={data} options={options} />
      </div>
    </div>
  )
}

ResourcesLocationsChart.propTypes = {
  chartLabels: PropTypes.object
}
