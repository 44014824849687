import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import classNamePropTypes from '../../utilities/classNamePropTypes'
import cn from 'classnames'
import { Field, Form } from 'react-final-form'
import { enumToTitleCase } from '../../utilities/stringUtilities'
import { DatePickerInput } from '../DatePickerInput'
import { TextInput } from '../TextInput'
import { TextareaInput } from '../TextareaInput'
import { CheckboxInput } from '../CheckboxInput'
import dayjs from '../../utilities/dayjs'
import Modal from '../modal/Modal'
import { composeValidators, dateOnOrAfterDay, required } from '../../utilities/commonValidators'

/**
 * Send invoice form component to save or save and send to customer. Make sure to update the initialValues passed into this form after submission
 * as the form will reset.
 * @param {Object} props
 * @param {Object} props.invoice
 * @param {function} props.onSubmit
 * @param {Object} props.paymentTerms
 * @param {Object} props.initialValues
 * @param {*} props.className
 * @return {JSX.Element}
 * @constructor
 */
export default function SendInvoiceForm ({ onSubmit, paymentTerms, invoice, isWastepayConnected, initialValues, className }) {
  async function handleSubmit (data, form) {
    await onSubmit({
      // Default action in case the user don't click a button and instead hits enter to submit the form.
      action: 'send',
      ...data,
      billTo: data.billTo?.split(',')?.map(v => v.trim()).join(',')
    })

    // Its expected initial values will be updated after submit, this helps keep the server state in tune with what the forms values are.
    form.reset()
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      component={InternalForm}
      paymentTerms={paymentTerms}
      invoice={invoice}
      className={className}
      isWastepayConnected={isWastepayConnected}
    />
  )
}
SendInvoiceForm.propTypes = {
  /**
   * https://final-form.org/docs/react-final-form/types/FormProps#onsubmit
   */
  onSubmit: PropTypes.func.isRequired,
  paymentTerms: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired
  })).isRequired,
  invoice: PropTypes.shape({
    dispatcherInvoiceNumber: PropTypes.string
  }),
  /**
   * https://final-form.org/docs/react-final-form/types/FormProps#initialvalues
   */
  initialValues: PropTypes.shape({
    paymentTerms: PropTypes.string,
    date: PropTypes.string,
    dueDate: PropTypes.string,
    billTo: PropTypes.string,
    message: PropTypes.string,
    includeTerms: PropTypes.bool,
    terms: PropTypes.string,
    includePayNow: PropTypes.bool
  }),
  className: classNamePropTypes,
  isWastepayConnected: PropTypes.bool.isRequired
}

/**
 * Non exported form to manage the React Final Form component. This component helps us eliminate the need to manage a bunch of
 * state as we have access to the React Final Form FormProps.
 * @return {JSX.Element}
 * @constructor
 */
function InternalForm ({ handleSubmit, form, submitting, values, paymentTerms, invoice, className, isWastepayConnected }) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const dueDateEditable = useMemo(() => {
    if (values.paymentTerms == null) return false
    return values.paymentTerms === 'SPECIAL_TERMS'
  }, [values.paymentTerms])

  useEffect(function updateInvoiceDate () {
    if (values.date != null && values.date !== '') return
    const today = dayjs().format('YYYY-MM-DD')
    form.change('invoiceDate', today)
  }, [form, values.date])

  useEffect(function updateDueDate () {
    if (!values.date) {
      form.change('dueDate', null)
      return
    }

    const startDate = dayjs(values.date, 'YYYY-MM-DD')
    const newDueDate = calcInvoiceDueDate(startDate, values.paymentTerms)
    if (newDueDate == null) return
    form.change('dueDate', newDueDate.format('YYYY-MM-DD'))
  }, [form, values.paymentTerms, values.date])

  function handleSave () {
    setIsModalOpen(false)
    form.change('action', 'save')
  }

  function handleSend () {
    setIsModalOpen(false)
    form.change('action', 'send')
  }

  function handleEditConditions () {
    setIsModalOpen(true)
  }

  function handleCloseModal () {
    setIsModalOpen(false)
  }

  return (
    <form className={cn(className)} onSubmit={handleSubmit}>
      <div className={styles.row}>
        <Field name='paymentTerms' validate={required}>
          {({ input, meta }) => (
            <div className={cn(styles.rowField, 'form-group', { 'has-error': meta.touched && meta.error })}>
              <label htmlFor='paymentTerms' className='control-label'>Terms</label>
              <select {...input} className='form-control'>
                <option key='placeholder' value=''></option>
                {paymentTerms.map(({ value }) =>
                  (
                    <option
                      value={value}
                      key={value}>
                      {enumToTitleCase(value)}
                    </option>
                  )
                )}
              </select>
              {(meta.touched && meta.error) && <h5>{meta.error}</h5>}
            </div>
          )}
        </Field>
        <Field name='date' component={DatePickerInput} label='Invoice Date' formGroupClasses={styles.rowField} validate={required} />
        <Field
          name='dueDate'
          component={DatePickerInput}
          label='Due Date'
          className={styles.rowField}
          disabled={!dueDateEditable}
          validate={composeValidators(required, dateOnOrAfterDay(values.date, 'Must be on or after Invoice Date'))}
        />
      </div>
      <div className={styles.row}>
        <Field name='billTo' component={TextInput} label='Bill To Email (comma separated)' className={styles.fullWidth} validate={required} />
      </div>
      <div className={styles.row}>
        <Field name='message' component={TextareaInput} label='Message (optional)' className={styles.fullWidth} />
      </div>
      <div className={styles.row}>
        <Field name='includeTerms' component={CheckboxInput} label='Include Terms & Conditions' className={styles.includeTerms} type='checkbox' />
        <button type='button' className={styles.editBtn} onClick={handleEditConditions}>Edit</button>
      </div>
      { isWastepayConnected && (
        <div className={styles.row}>
          <Field name='includePayNow' component={CheckboxInput} label='Include Pay Now Link' type='checkbox' />
        </div>
      )}
      <div className={styles.btns}>
        <button type='submit' onClick={handleSave} className={cn(styles.saveBtn, 'dis-btn dis-btn-lg dis-btn-white')} disabled={submitting}>
          Save
        </button>
        <button type='submit' onClick={handleSend} className={cn(styles.sendBtn, 'dis-btn dis-btn-lg dis-btn-primary')} disabled={submitting}>
          Save & Send to Customer
          <i className='material-icons dis-btn-icon'>send</i>
        </button>
      </div>
      <Modal isOpen={isModalOpen} className={styles.modal}>
        <div>
          <h4>Edit {invoice?.dispatcherInvoiceNumber && `for Invoice #${invoice.dispatcherInvoiceNumber}`}</h4>
          <Field name='terms' component={TextareaInput} label='Terms & Conditions' textAreaClassName={styles.conditionsTextarea} />
          <div className={styles.modalBtns}>
            <button type='button' className='dis-btn dis-btn-lg dis-btn-white' onClick={handleCloseModal}>
              Cancel
            </button>
            <button
              type='submit'
              onClick={handleSave}
              className={cn(styles.modalSaveBtn, 'dis-btn dis-btn-lg dis-btn-primary')}
              disabled={submitting}>
              Save Changes
            </button>
          </div>
        </div>
      </Modal>
    </form>
  )
}

InternalForm.propTypes = {
  /**
   * https://final-form.org/docs/react-final-form/types/FormRenderProps
   */
  handleSubmit: PropTypes.func.isRequired,
  /**
   * https://final-form.org/docs/react-final-form/types/FormRenderProps
   */
  form: PropTypes.shape({
    /**
     * https://final-form.org/docs/final-form/types/FormApi#change
     */
    change: PropTypes.func.isRequired
  }),
  /**
   * https://final-form.org/docs/final-form/types/FormState#submitting
   */
  submitting: PropTypes.bool.isRequired,
  /**
   * https://final-form.org/docs/final-form/types/FormState#values
  */
  values: PropTypes.object.isRequired,
  isWastepayConnected: PropTypes.bool.isRequired,
  paymentTerms: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired
  })).isRequired,
  invoice: PropTypes.shape({
    dispatcherInvoiceNumber: PropTypes.string
  }),
  className: classNamePropTypes
}

/**
 * Determines the invoice due date based on the start date and terms.
 * @param {Object} startDate
 * @param {string} terms
 * @return {Object|undefined}
 */
function calcInvoiceDueDate (startDate, terms) {
  switch (terms) {
    case 'DUE_UPON_RECEIPT': {
      return startDate
    }
    case 'NET_15': {
      return startDate.add(15, 'day')
    }
    case 'NET_30': {
      return startDate.add(30, 'day')
    }
    case 'NET_60': {
      return startDate.add(60, 'day')
    }
    case 'NET_90': {
      return startDate.add(90, 'day')
    }
    default:
  }
}
