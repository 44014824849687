export const SET_REPORTS = 'SET_REPORTS'

export const initialState = {
  reports: [],
  reportToken: null
}

export default function reportsReducer (reduxState = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case SET_REPORTS:
      return {
        ...reduxState,
        reports: payload.reports,
        reportToken: payload.reportToken
      }
    default:
      return reduxState
  }
}
