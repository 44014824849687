import { loader } from 'graphql.macro'

export default function quickbooksResources ({ dataLoaders, queryKey }) {
  const [, variables] = queryKey
  const schema = loader('./quickbooksResources.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(schema, {
    haulerId: variables.haulerId,
    includeCustomerTypes: variables.includeCustomerTypes,
    includeTaxCodes: variables.includeTaxCodes
  })
}
