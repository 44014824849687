import React from 'react'
import styles from './index.module.scss'
import PropTypes from 'prop-types'
import { commaDeliminate, formatDollarAmount } from '../../utilities/stringUtilities'

export default function TicketInvoiceTable ({
  ticket,
  discountAndTax,
  ticketFees,
  showQBOMessaging
}) {
  return (
    <>
      <table className='table table-responsive table-striped'>
        <thead>
          <tr>
            <th width='40%'>Fee Type</th>
            <th width='30%'>Price</th>
            <th width='25%'>Quantity</th>
            <th width='25%'>Total</th>
          </tr>
        </thead>
        <tbody>
          {ticketFees?.length === 0 && <tr><td className={styles.emptyTableMessage} colSpan={5}>No Ticket Fees</td></tr>}
          {ticketFees.map(tf => (
            <tr key={tf.id}>
              <td>{tf?.feeType?.name}</td>
              <td>${commaDeliminate(tf.amount)}</td>
              <td>{tf.quantity}</td>
              <td>${commaDeliminate(tf?.total)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.invoiceTotalContainer}>
        <div className={styles.invoiceTotalContainerRow}>
          <div className={styles.invoiceTotalContainerCell}>Discount</div>
          <div className={styles.invoiceTotalContainerCell}>({formatDollarAmount(discountAndTax?.discountAmount)})</div>
        </div>
        <div className={styles.invoiceTotalContainerRow}>
          <div className={styles.invoiceTotalContainerCell}>Subtotal</div>
          <div className={styles.invoiceTotalContainerCell}>{formatDollarAmount(ticket?.subtotal)}</div>
        </div>
        <div className={styles.invoiceTotalContainerRow}>
          <div className={styles.invoiceTotalContainerCell}>Tax</div>
          <div className={styles.invoiceTotalContainerCell}>{formatDollarAmount(discountAndTax?.taxAmount)}</div>
        </div>
        <div className={styles.invoiceTotalContainerRow}>
          <div className={styles.invoiceTotal}>Total</div>
          <div className={styles.invoiceTotal}>${formatDollarAmount(ticket?.total)}</div>
        </div>
      </div>
      {showQBOMessaging && <div className={styles.qboDisclaimer}>* Fees and totals may have changed in QBO and will not be reflected here.</div>}
    </>
  )
}

TicketInvoiceTable.propTypes = {
  ticket: PropTypes.object,
  discountAndTax: PropTypes.object,
  ticketFees: PropTypes.array,
  showQBOMessaging: PropTypes.bool.isRequired
}
