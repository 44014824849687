
import {
  REQUEST_TICKET_TYPES_ACTION,
  RECEIVE_TICKET_TYPES_SUCCESS,
  RECEIVE_TICKET_TYPES_FAILURE
} from '../sagas/ticketTypesSaga'

const initialState = {
  isFetching: false,
  errors: [],
  ticketTypesArray: [],
  create: {
    isFetching: false,
    isCreating: false,
    errors: []
  },
  edit: {
    isFetching: false,
    isEditing: false,
    id: '',
    name: '',
    short_code: '',
    status: '',
    errors: []
  }
}

const ticketTypesReducer = (reduxState = initialState, action) => {
  const { payload, type } = action
  switch (type) {
    case REQUEST_TICKET_TYPES_ACTION:
      return {
        ...reduxState,
        isFetching: true,
        errors: []
      }

    case RECEIVE_TICKET_TYPES_SUCCESS:
      return {
        ...reduxState,
        isFetching: false,
        errors: [],
        ticketTypesArray: payload
      }

    case RECEIVE_TICKET_TYPES_FAILURE:
      return {
        ...reduxState,
        isFetching: false,
        errors: payload.errors
      }

    default:
      return reduxState
  }
}

export default ticketTypesReducer
