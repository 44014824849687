import { loader } from 'graphql.macro'

export default async function loginUser ({ dataLoaders, variables }) {
  const schema = loader('./loginUser.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      email: variables.email,
      password: variables.password
    }
  })
  return {
    accessToken: response.signin.auth.accessToken,
    refreshToken: response.signin.auth.refreshToken
  }
}
