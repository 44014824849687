import { loader } from 'graphql.macro'
import { intercomConnection } from '../../intercomConnection'

export default async function updateTicketStatus ({ dataLoaders, variables }) {
  const schema = loader('./updateTicketStatus.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, { input: variables })

  let intercomEvent
  switch (variables.status) {
    case 'en_route':
      intercomEvent = 'ticket_started'
      break
    case 'open':
      intercomEvent = 'ticket_opened'
      break
    case 'completed':
      intercomEvent = 'ticket_completed'
      break
    case 'cancelled':
      intercomEvent = 'ticket_cancelled'
      break
    default:
  }
  if (intercomEvent) {
    intercomConnection.trackEvent('ticket_canceled', {})
  }

  return response.updateTicketStatus
}
