import React from 'react'
import PropTypes from 'prop-types'
import { ROUTES } from '../../routes'
import { generatePath, Link } from 'react-router-dom'
import styles from './index.module.scss'
import dayjs from '../../utilities/dayjs'
import { commaDeliminate, enumToTitleCase } from '../../utilities/stringUtilities'
import InvoiceDetailsButtons from './InvoiceDetailsButtons'
export default function InvoiceDetails ({
  invoice,
  qboInvoiced,
  setShowEditing,
  total,
  isConnectedToQB,
  invoicer,
  isWastepayConnected
}) {
  const qboInvoicedUnpaid = qboInvoiced && invoice?.status !== 'PAID'
  const isOldReference = invoice?.qboDocNumberOld !== null || invoice?.qboInvoiceIdOld !== null
  const qboInvoiceId = isOldReference ? invoice?.qboDocNumberOld || invoice?.qboInvoiceIdOld : invoice?.qboDocNumber || invoice?.qboInvoiceId
  return (
    <div className={styles.invoiceDetailsContainer}>
      <Link
        className={styles.invoiceLink}
        to={generatePath(ROUTES.invoice, { id: invoice?.id })}>
        {`Invoice #${invoice?.dispatcherInvoiceNumber ? invoice?.dispatcherInvoiceNumber : qboInvoiceId}`}
      </Link>
      <div className={styles.invoiceDetailTotal}>${commaDeliminate(total)}</div>
      <div className={styles.invoiceDetails}>
        <div>
          <span className={styles.invoiceDetail}>Status:</span>
          {qboInvoicedUnpaid ? <span>Closed in QBO</span> : <span>{enumToTitleCase(invoice?.status)}</span>}
        </div>
        <div>
          <span className={styles.invoiceDetail}>Create Date:</span>
          <span>{dayjs(invoice?.createdAt).format('MMM D, YYYY')}</span>
        </div>
      </div>
      <InvoiceDetailsButtons
        invoice={invoice}
        qboInvoicedUnpaid={qboInvoicedUnpaid}
        setShowEditing={setShowEditing}
        isOldQboReference={isOldReference}
        isWastepayConnected={isWastepayConnected}
        isConnectedToQB={isConnectedToQB}
        invoicer={invoicer}
      />
    </div>
  )
}

InvoiceDetails.propTypes = {
  invoice: PropTypes.object.isRequired,
  isWastepayConnected: PropTypes.bool.isRequired,
  qboInvoiced: PropTypes.bool.isRequired,
  setShowEditing: PropTypes.func.isRequired,
  total: PropTypes.number,
  isConnectedToQB: PropTypes.bool.isRequired,
  invoicer: PropTypes.string.isRequired
}
