import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import dayjs from 'dayjs'
import styles from './ticket-details.module.scss'
import { Link, generatePath } from 'react-router-dom'
import { ROUTES } from '../../../routes'
import { stringToTime } from '../../../utilities/stringUtilities'
import ExceptionMessageDisplay from './ExceptionMessageDisplay'

export default function TicketDetails (
  {
    metric,
    ticket,
    onToggleFlagged,
    onClearException,
    onRestoreException
  }) {
  function weightString (weight) {
    const tons = metric ? (weight * 0.001102).toFixed(2) : (weight / 2000).toFixed(2)
    if (metric) {
      return `${weight} kg or ${tons} tons`
    }
    return `${weight} lbs or ${tons} tons`
  }

  return (
    <>
      <div className={cn('dis-panel', styles.detailsContainer)}>
        <Link className={styles.detailsEditItem} to={generatePath(ROUTES.rescheduleTicket, { id: ticket.id })}>
          <i className={cn(styles.detailsEditIcon, styles.calendarIcon, 'material-icons')}>event</i>
          <div>
            <div className={styles.detailsEditText}>
              {dayjs(ticket.date).format('MMM D, YYYY')}
            </div>
            <div className={styles.actionText}>
              Reschedule
            </div>
          </div>
        </Link>

        <Link className={styles.detailsEditItem} to={generatePath(ROUTES.assignTicket, { id: ticket.id })}>
          <i className={cn(styles.detailsEditIcon, 'material-icons')}>transfer_within_a_station</i>
          <div>
            <div className={styles.detailsEditText}>
              {ticket.driverId && ticket?.driverAssigned?.firstName ? `${ticket?.driverAssigned?.firstName} ${ticket?.driverAssigned?.lastName}` : 'Unassigned'}
            </div>
            <div className={styles.actionText}>
              {ticket.driverId ? 'Reassign' : 'Assign'}
            </div>
          </div>
        </Link>

        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div role='button' tabIndex='0' className={styles.detailsEditItem} onClick={() => onToggleFlagged(ticket.id, !ticket.flagged)}>
          <i className={cn(styles.detailsEditIcon, {
            'text-danger': ticket.flagged,
            'material-icons-outlined': !ticket.flagged,
            'material-icons': ticket.flagged
          })}>flag</i>
          <div>
            <div className={styles.detailsEditText}>
              {ticket.flagged ? 'Flagged' : 'Not Flagged'}
            </div>
            <div className={styles.actionText}>
              {ticket.flagged ? 'Unflag' : 'Flag'}
            </div>
          </div>
        </div>
      </div>

      <div className='dis-list-group'>
        <div className={cn('dis-list-item dis-list-item-gray', styles.detailsItem)}>
          <div className='dis-list-group-header'>
            <h5>Ticket Details</h5>
            <Link
              to={generatePath(ROUTES.editTicket, { id: ticket.id })}
              className='dis-list-group-header-link'>
              Edit Ticket</Link>
          </div>
        </div>

        <div className={cn('dis-list-item', styles.detailsItem)}>
          <i className={cn('material-icons', styles.detailsIcon)}>watch_later</i>
          <div className={styles.detailType}>Requested Time Range</div>
          <div className={styles.detailsInfo}>
            {
              ticket.requestedStartTime
                ? `${stringToTime(ticket.requestedStartTime)} - ${stringToTime(ticket.requestedEndTime)}`
                : 'Not Specified'
            }
          </div>
        </div>

        <div className={cn('dis-list-item', styles.detailsItem)}>
          <i className={cn('material-icons', styles.detailsIcon)}>widgets</i>
          <div className={styles.detailType}>Asset Type</div>
          <div className={styles.detailsInfo}>
            {ticket.resourceType.name} ({ticket.resourceType.shortCode})
          </div>
        </div>

        <div className={cn('dis-list-item', styles.detailsItem)}>
          <i className={cn('material-icons', styles.detailsIcon)}>assignment</i>
          <div className={styles.detailType}>Ticket Type</div>
          <div className={styles.detailsInfo}>
            {ticket.ticketType.name} ({ticket.ticketType.shortCode})
          </div>
        </div>

        <div className={cn('dis-list-item', styles.detailsItem)}>
          <i className={cn('material-icons', styles.detailsIcon)}>fitness_center</i>
          <div className={styles.detailType}>Weight</div>
          <div className={styles.detailsInfo}>
            <div>{ticket.weight ? weightString(ticket.weight) : 'Not Entered'}</div>
          </div>
        </div>

        <div className={cn('dis-list-item', styles.detailsItem)}>
          <i className={cn('material-icons', styles.detailsIcon)}>label</i>
          <div className={styles.detailType}>Weight Ticket Number</div>
          <div className={styles.detailsInfo}>
            <div>{ticket.weightTicketNumber ? ticket.weightTicketNumber : 'Not Entered'}</div>
          </div>
        </div>

        <div className={cn('dis-list-item', styles.detailsItem)}>
          <i className={cn('material-icons', styles.detailsIcon)}>attach_money</i>
          <div className={styles.detailType}>Billing Notes</div>
          <div className={cn(styles.detailsInfo, 'wordwrap')}>
            {ticket.billingNotes || 'None'}
          </div>
        </div>
      </div>
      {ticket.exceptionMessage && (
        <ExceptionMessageDisplay
          id={ticket.id}
          hasActiveException={ticket.hasActiveException}
          exceptionMessage={ticket.exceptionMessage}
          lastClearedExceptionAt={ticket.lastClearedExceptionAt}
          onClearException={onClearException}
          onRestoreException={onRestoreException}
        />
      )}
    </>
  )
}

TicketDetails.propTypes = {
  metric: PropTypes.any,
  ticket: PropTypes.shape({
    id: PropTypes.string,
    date: PropTypes.string,
    hasActiveException: PropTypes.bool,
    exceptionMessage: PropTypes.string,
    driverAssigned: PropTypes.any,
    driverId: PropTypes.any,
    lastClearedExceptionAt: PropTypes.string,
    ticketType: PropTypes.any,
    resourceType: PropTypes.any,
    weight: PropTypes.any,
    weightTicketNumber: PropTypes.any,
    billingNotes: PropTypes.string,
    requestedEndTime: PropTypes.string,
    requestedStartTime: PropTypes.string,
    flagged: PropTypes.bool
  }).isRequired,
  onClearException: PropTypes.func.isRequired,
  onToggleFlagged: PropTypes.func.isRequired,
  onRestoreException: PropTypes.func.isRequired
}
