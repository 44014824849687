import { put, takeLatest } from 'redux-saga/effects'
import { loader } from 'graphql.macro'
import { handleError } from '../../../../utilities/handleError'
import { makeGraphQLClient } from '../../../../utilities/graphqlClient'

export const REQUEST_CURRENT_JOB_ACTIVITIES_ACTIONS = 'REQUEST_CURRENT_JOB_ACTIVITIES_ACTIONS'
export const RECEIVE_CURRENT_JOB_ACTIVITIES_SUCCESS = 'RECEIVE_CURRENT_JOB_ACTIVITIES_SUCCESS'
export const RECEIVE_CURRENT_JOB_ACTIVITIES_FAILURE = 'RECEIVE_CURRENT_JOB_ACTIVITIES_FAILURE'

function * requestCurrentJobActivities (data) {
  try {
    const { page, id } = data.payload
    const graphQLClient = makeGraphQLClient()
    const activitiesQuery = loader('../../../../graphql/queries/activitiesPages.graphql')
    const response = yield graphQLClient.request(activitiesQuery, {
      entity: 'JOB',
      id,
      page
    })
    yield put({ type: RECEIVE_CURRENT_JOB_ACTIVITIES_SUCCESS, payload: response.activitiesPages })
  } catch (e) {
    const errors = handleError(e)
    yield put({ type: RECEIVE_CURRENT_JOB_ACTIVITIES_FAILURE, payload: { errors } })
  }
}

export default function * currentJobActivitesSaga () {
  yield takeLatest(REQUEST_CURRENT_JOB_ACTIVITIES_ACTIONS, requestCurrentJobActivities)
}
