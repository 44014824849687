import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { commaDeliminate } from '../../../../../../utilities/stringUtilities'
import StaticGoogleMap from '../../../../../shared/locationPickers/StaticGoogleMap'

export default function SiteDisplay ({
  showMetric,
  site
}) {
  return (
    <div className='row'>
      <div>
        <ul className='list-group'>
          <div className='list-group-item'>
            <i className='material-icons large-icon pull-right'>label</i>
            <div className='list-group-item-heading'>Site Name</div>
            <div className='list-group-item-text'>
              {site?.name}
            </div>
          </div>

          <div className='list-group-item'>
            <i className='material-icons large-icon pull-right'>store</i>
            <div className='list-group-item-heading'>Site Type</div>
            <div className='list-group-item-text'>
              <Link to={'/hauler/site-types'}>
                {site?.siteType?.name} ({site?.siteType?.shortCode})
              </Link>
            </div>
          </div>

          <div className='list-group-item'>
            <i className='material-icons large-icon pull-right'>paid</i>
            <div className='list-group-item-heading'>{`Site Disposal Cost (per ${showMetric ? 'metric' : ''} ton)`}</div>
            <div className='list-group-item-text'>
              { `$${commaDeliminate(site?.cost)} / ton` }
            </div>
          </div>

          <div className='list-group-item'>
            <i className='material-icons large-icon pull-right'>speaker_notes</i>
            <div className='list-group-item-heading'>Note</div>
            <div className='list-group-item-text'>
              {site?.note || 'None'}
            </div>
          </div>

        </ul>

        <ul className='list-group'>
          <div className='list-group-item'>
            <i className='material-icons large-icon pull-right'>map</i>
            <div className='list-group-item-heading'>Location</div>
            <div className='list-group-item-text'>
              <div className='clearfix' />
              <StaticGoogleMap
                latitude={site?.location?.latitude}
                longitude={site?.location?.longitude}
                place_id={site?.location?.placeId}
                addrComponents={site?.location}
              />
            </div>
          </div>
        </ul>
      </div>
      <div className='col-xs-12 col-md-6' />
    </div>
  )
}

SiteDisplay.propTypes = {
  showMetric: PropTypes.bool.isRequired,
  site: PropTypes.object.isRequired
}
