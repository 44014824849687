import React, { Component } from 'react'

export default class NoResults extends Component {
  render () {
    return (<div>
      <h2 className='text-center'>
        <i className='material-icons center'>block</i>
      </h2>
      <h3 className='text-center'>No Results</h3>
    </div>)
  }
}
