const ALERT_TIMEOUT = 500000
export const CLEAR_ALERT_MESSAGE = 'CLEAR_ALERT_MESSAGE'
export const SET_ALERT_MESSAGE = 'SET_ALERT_MESSAGE'

// ==========================================================================
/** LOGIN ACTION CREATORS */
// ==========================================================================
export const clearAlert = () => ({
  type: CLEAR_ALERT_MESSAGE
})

export const setAlert = message => ({
  type: SET_ALERT_MESSAGE,
  message: message || 'Success!'
})

export const triggerAlert = message => (dispatch) => {
  dispatch(setAlert(message))
  setTimeout(() => {
    dispatch(clearAlert())
  }, ALERT_TIMEOUT)
}
