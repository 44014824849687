import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { DatePickerInput } from '../../../../../../components/DatePickerInput'

const validate = (values) => {
  const errors = {}

  if (!values.date) {
    errors.date = 'Required'
  }

  return errors
}

class CurrentTicketRescheduleForm extends Component {
  render () {
    const { onSubmit, pristine, invalid, isFetching } = this.props

    return (
      <div className='row panel panel-default push-down-super-small'>
        <div className='container-fluid push-down-super-small'>
          <div className='col-xs-12'>
            <form onSubmit={onSubmit} autoComplete='off'>

              <Field
                name='date'
                placeholder='Date'
                component={DatePickerInput}
              />

              <button
                type='submit'
                className='btn btn-lg btn-success push-down-super-small pull-right'
                disabled={pristine || invalid || isFetching}>
                <span className='pull-left'>Save Changes</span>
                <i className='material-icons pull-right thumbster-button-icon'>check</i>
              </button>

            </form>
          </div>
        </div>
        <br />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  initialValues: {
    date: state.currentTicket.ticket.date
  }
})

// eslint-disable-next-line no-class-assign
CurrentTicketRescheduleForm = reduxForm({
  form: 'currentTicketRescheduleForm',
  validate
})(CurrentTicketRescheduleForm)

CurrentTicketRescheduleForm.propTypes = {
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool
}

export default connect(mapStateToProps)(CurrentTicketRescheduleForm)
