import React, { useState, useEffect } from 'react'
import { generatePath, Link, useHistory, useParams } from 'react-router-dom'
import cn from 'classnames'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import { useSelector } from 'react-redux'
import { Spinner } from '../shared/Spinner'
import QUERY_KEYS from '../../graphql/queryKeys'
import invoicesQuery from '../../graphql/queries/invoices'
import { PageTitle } from '../../components/page-title'
import { ROUTES } from '../../routes'
import styles from './index.module.scss'
import ClientDisplay from '../../components/client-display'
import ClientBillingDetails from '../../components/client-display/ClientBillingDetails'
import SelectWPCustomerModal from '../../components/select-wp-customer-modal'
import ClientJobsTable from '../../components/ClientJobsTable'
import ClientInvoicesTable from '../hauler/clients/_Id/index/components/ClientInvoicesTable'
import ClientActivitiesDisplay from '../hauler/clients/_Id/index/components/ClientActivitiesDisplay'
import notify from '../../utilities/notify'
import { camelCasedObjectToSnakeCasedObject } from '../../utilities/randomUtilities'
import useQuery, { generateQueryKey } from '../../hooks/useQuery'
import useMutation from '../../hooks/useMutation'
import clientQuery from '../../graphql/queries/clientQuery'
import clientQuickbooksCustomerQuery from '../../graphql/queries/clientQuickbooksCustomer'
import clientJobsQuery from '../../graphql/queries/clientJobs'
import activitiesPagesQuery from '../../graphql/queries/activitiesPages'
import destroyClient from '../../graphql/mutations/destroyClient'
import exportClientToQboMutation from '../../graphql/mutations/exportClientToQbo'
import unlinkClientFromQboMutation from '../../graphql/mutations/unlinkClientFromQbo'
import unlinkClientFromWastepayMutation from '../../graphql/mutations/unlinkClientFromWastepay'
import exportClientToWastepayMutation from '../../graphql/mutations/exportClientToWastepay.js'
import updateClientMutation from '../../graphql/mutations/updateClient'
import { useQueryClient } from 'react-query'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import SMSEnableButton from '../../components/sms-enable-button'

const pageSize = 25
const jobListSize = 10

export default function ClientDetailPage () {
  const { clientId } = useParams()
  const queryClient = useQueryClient()
  const history = useHistory()
  const [invoicesFakeCount, setInvoicesFakeCount] = useState(null)
  const [invoicesPager, setInvoicesPager] = useState(null)
  const [openJobsPager, setOpenJobsPager] = useState(null)
  const [closedJobsPager, setClosedJobsPager] = useState(null)
  const [openJobsFakeCount, setOpenJobsFakeCount] = useState(null)
  const [closedJobsFakeCount, setClosedJobsFakeCount] = useState(null)
  const [activitiesPage, setActivitiesPage] = useState(1)
  const [isSMSEnabled, setIsSMSEnabled] = useState(null)
  const [showSelectCustomerModal, setShowSelectCustomerModal] = useState(false)
  const [wastepayCustomers, setWastepayCustomers] = useState([])
  const {
    user,
    hauler
  } = useSelector(({ user }) => ({
    user: user.user,
    hauler: user.hauler
  }))

  const { data: invoices, isFetching: isFetchingInvoices } = useQuery([
    QUERY_KEYS.invoices,
    user.haulerId,
    {
      column: 'createdAt',
      direction: 'desc'
    },
    null,
    null,
    null,
    null,
    invoicesPager,
    clientId
  ],
  invoicesQuery,
  {
    enabled: Boolean(user.haulerId) && Boolean(clientId),
    onError (error) {
      captureErrorAndNotify(error, 'Unable to retrieve account invoices')
    },
    placeholderData: { invoices: { nodes: [], totalCount: 0 } },
    keepPreviousData: true
  })

  useEffect(function toggleCustomerSelectionModal () {
    setShowSelectCustomerModal(wastepayCustomers.length !== 0)
  }, [wastepayCustomers, setShowSelectCustomerModal])

  useEffect(function toggleInvoicesFakePagination () {
    if (!invoices?.invoices?.totalCount || isFetchingInvoices || invoicesFakeCount !== null) return
    setInvoicesFakeCount({ start: 1, end: Math.min(pageSize, invoices.invoices.totalCount) })
  }, [invoices?.invoices?.totalCount, invoices, invoicesFakeCount, setInvoicesFakeCount, isFetchingInvoices])

  const { data: clientData, isFetching: isFetchingClient } = useQuery(
    [QUERY_KEYS.client, clientId],
    clientQuery
  )

  // Loaded separately from the client, so we don't depend on Quickbooks API before showing our own data
  const { data: qboCustomerData, isFetching: isFetchingQboCustomer, isError: isErrorFetchingQboCustomer } = useQuery(
    [QUERY_KEYS.clientQuickbooksCustomer, { id: clientData?.client.id }],
    clientQuickbooksCustomerQuery,
    {
      enabled: hauler.quickbooks.isConnected && clientData?.client.qboCustomerId != null
    }
  )

  const { data: openJobsData, isFetching: isFetchingOpenJobs } = useQuery(
    [QUERY_KEYS.clientJobs, {
      haulerId: user.haulerId,
      searchFields: {
        clientId,
        status: 'open'
      },
      first: jobListSize,
      pager: openJobsPager,
      sortColumn: 'ID',
      sortDirection: 'ASC'
    }],
    clientJobsQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Failed to load account open jobs')
      }
    }
  )

  const { data: closedJobsData, isFetching: isFetchingClosedJobs } = useQuery(
    [QUERY_KEYS.clientJobs, {
      haulerId: user.haulerId,
      searchFields: {
        clientId,
        status: 'closed'
      },
      first: jobListSize,
      pager: closedJobsPager,
      sortColumn: 'ID',
      sortDirection: 'ASC'
    }],
    clientJobsQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Failed to load account closed jobs')
      }
    }
  )

  const { data: activitiesData, isFetching: isFetchingActivities } = useQuery(
    [QUERY_KEYS.activitiesPages, { entity: 'CLIENT', id: clientId, page: activitiesPage }],
    activitiesPagesQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Failed to load activities')
      }
    }
  )

  const { mutateAsync: updateClient, isLoading: isUpdatingClient } = useMutation(updateClientMutation, {
    onSuccess (data) {
      notify('success', `Successfully ${data.client.smsActive ? 'Enabled' : 'Disabled'} SMS.`)
    },
    onError () {
      notify('error', 'Failed to Update Account')
    }
  })

  const { mutate: exportClientToWastePay, isLoading: isExportingClientToWastePay } = useMutation(exportClientToWastepayMutation, {
    onSuccess (data) {
      const { client, wastepayCustomers } = data
      if (wastepayCustomers != null) {
        return setWastepayCustomers(wastepayCustomers)
      }
      setWastepayCustomers([])
      updateCachedClientData(data)
      notify('success', `Account ${client.name} was exported to Wastepay`)
    },
    onError () {
      notify('error', 'There was a problem exporting this account')
    }
  })

  const { mutate: destroyClientMutation } = useMutation(destroyClient, {
    onSuccess () {
      notify('success', 'Account deleted successfully')
      history.replace(ROUTES.clients)
    },
    onError (error) {
      captureErrorAndNotify(error, 'Failed to destroy account')
    }
  })

  const { mutate: exportClientToQbo, isLoading: isExportingClientToQbo } = useMutation(exportClientToQboMutation, {
    onSuccess (data) {
      updateCachedClientData(data)
      notify('success', `Account ${data.client.name} was exported to QuickBooks`)
    },
    onError (error) {
      captureErrorAndNotify(error, 'There was a problem creating this account in QuickBooks.')
    }
  })

  const { mutate: unlinkClientFromQbo, isLoading: isUnlinkingClientFromQbo } = useMutation(unlinkClientFromQboMutation, {
    onSuccess (data) {
      updateCachedClientData(data)
      notify('success', 'Account successfully unlinked from QBO customer')
    },
    onError (error) {
      captureErrorAndNotify(error, 'Failed to unlink account from QBO customer')
    }
  })
  const { mutate: unlinkClientFromWastepay, isLoading: isUnlinkingClientFromWastepay } = useMutation(unlinkClientFromWastepayMutation, {
    onSuccess (data) {
      updateCachedClientData(data)
      notify('success', 'Account successfully unlinked from WastePay customer')
    },
    onError (error) {
      captureErrorAndNotify(error, 'Failed to unlink account from WastePay customer')
    }
  })

  useEffect(function initialSetOfSMSEnabled () {
    if (clientData?.client && isSMSEnabled === null) {
      setIsSMSEnabled(clientData.client.smsActive)
    }
  }, [clientData, isSMSEnabled])

  useEffect(function toggleFakeOpenJobsPagination () {
    if (!openJobsData?.jobs?.totalCount || isFetchingOpenJobs || openJobsFakeCount !== null) return
    setOpenJobsFakeCount({ start: 1, end: Math.min(jobListSize, openJobsData.jobs.totalCount) })
  }, [openJobsData, openJobsFakeCount, setOpenJobsFakeCount, isFetchingOpenJobs])

  useEffect(function toggleFakeClosedJobsPagination () {
    if (!closedJobsData?.jobs?.totalCount || isFetchingClosedJobs || closedJobsFakeCount !== null) return
    setClosedJobsFakeCount({ start: 1, end: Math.min(jobListSize, closedJobsData.jobs.totalCount) })
  }, [closedJobsData, closedJobsFakeCount, setClosedJobsFakeCount, isFetchingClosedJobs])

  function onOpenJobsPageRequest (direction, cursor) {
    setOpenJobsPager({
      cursor,
      direction,
      pageSize: jobListSize
    })

    if (!openJobsFakeCount) return
    if (direction === 'before') {
      setOpenJobsFakeCount(prevFakeCount => ({ start: prevFakeCount.start - jobListSize, end: prevFakeCount.end - jobListSize }))
    }
    if (direction === 'after') {
      setOpenJobsFakeCount(prevFakeCount => ({ start: prevFakeCount.start + jobListSize, end: prevFakeCount.end + jobListSize }))
    }
  }

  function onClosedJobsPageRequest (direction, cursor) {
    setClosedJobsPager({
      cursor,
      direction,
      pageSize: jobListSize
    })

    if (!closedJobsFakeCount) return
    if (direction === 'before') {
      setClosedJobsFakeCount(prevFakeCount => ({ start: prevFakeCount.start - jobListSize, end: prevFakeCount.end - jobListSize }))
    }
    if (direction === 'after') {
      setClosedJobsFakeCount(prevFakeCount => ({ start: prevFakeCount.start + jobListSize, end: prevFakeCount.end + jobListSize }))
    }
  }

  function handleActivitiesPageRequest (page) {
    setActivitiesPage(page)
  }

  function onInvoicesPageRequest (direction, cursor) {
    setInvoicesPager({ cursor, direction, pageSize })

    if (!invoicesFakeCount) return
    if (direction === 'before') {
      setInvoicesFakeCount(prevFakeCount => ({ start: prevFakeCount.start - pageSize, end: prevFakeCount.end - pageSize }))
    }
    if (direction === 'after') {
      setInvoicesFakeCount(prevFakeCount => ({ start: prevFakeCount.start + pageSize, end: prevFakeCount.end + pageSize }))
    }
  }

  function updateCachedClientData (data) {
    queryClient.setQueryData(generateQueryKey([QUERY_KEYS.client, data.client.id], user.id), oldData => ({
      ...(oldData ?? {}),
      client: { ...(oldData?.client ?? {}), ...data.client }
    }))
    queryClient.setQueryData(generateQueryKey([QUERY_KEYS.clientQuickbooksCustomer, { id: data.client.id }], user.id), oldData => ({
      ...(oldData ?? {}),
      client: { ...(oldData?.client ?? {}), ...data.client }
    }))
  }

  function handleExportToQbo (clientId) {
    const result = window.confirm(
      'NOTICE: \n\nIf this account already exists in QuickBooks, ' +
      'please make sure the account name matches the customer name ' +
      'EXACTLY in Quickbooks or duplicate customers will be created.'
    )
    if (result) {
      exportClientToQbo({ id: clientId })
    }
  }

  function handleQboUnlink (clientId) {
    unlinkClientFromQbo({ id: clientId })
  }

  function handleWPUnlink (clientId) {
    unlinkClientFromWastepay({ id: clientId })
  }

  function handleExportToWP (clientId, { wpCustomerId = null, forceCreate = null } = {}) {
    const exportArguments = {
      id: clientId
    }
    if (wpCustomerId != null) {
      exportArguments.customerId = wpCustomerId
    }
    if (forceCreate != null) {
      exportArguments.forceCreate = true
    }
    exportClientToWastePay(exportArguments)
  }

  function handleDestroyClient () {
    const result = window.confirm(
      'THIS CANNOT BE UNDONE! Deleting an account will remove any record ' +
      'that it existed, including its jobs and tickets.')
    if (result) {
      destroyClientMutation(clientData.client)
    }
  }

  async function handleSMSUpdate (isEnabledAtTimeOfClick) {
    await updateClient({ haulerId: user.haulerId, clientFields: { smsActive: !isEnabledAtTimeOfClick }, clientId })
    setIsSMSEnabled(!isEnabledAtTimeOfClick)
  }

  return (
    <>
      <SelectWPCustomerModal
        closeModal={() => setWastepayCustomers([])}
        isOpen={showSelectCustomerModal}
        wastepayCustomers= {wastepayCustomers}
        handleSubmit={handleExportToWP}
        submitting={isExportingClientToWastePay}
        clientId = {clientData?.client?.id}
        resetCustomers={() => setWastepayCustomers([])}
      />
      <NormalLayoutContainer showBackLink>
        <div className='container-fluid'>
          {isFetchingClient
            ? <Spinner isFetching />
            : (<>
              {clientData && (<>
                <PageTitle title={`${clientData.client.name}`}>
                  <div className={cn(styles.headerButtonWrapper)}>
                    <Link
                      to={generatePath(ROUTES.clientEdit, { clientId })}
                      className='btn btn-lg btn-primary-dk'>
                      <span className='pull-left'>Edit Account</span>
                      <i className='material-icons pull-right thumbster-button-icon'>mode_edit</i>
                    </Link>
                    <SMSEnableButton
                      smsEnabled={isSMSEnabled}
                      handleUpdate={handleSMSUpdate}
                      isLoading={isUpdatingClient}
                      step={'ACCT'}
                      isParentEnabled={hauler.smsActive}
                      levelName={clientData.client?.name}
                    />
                  </div>
                </PageTitle>
                <div className={styles.displayContainer}>
                  <div className={styles.displayDetails}>
                    <ClientDisplay client={clientData.client} />
                  </div>
                  <div className={styles.billingDetails}>
                    <ClientBillingDetails
                      client={clientData.client}
                      onWPExport={handleExportToWP}
                      isWPLoading={isExportingClientToWastePay || isUnlinkingClientFromWastepay}
                      onWPUnlink={handleWPUnlink}
                      onQboExport={handleExportToQbo}
                      onQboUnlink={handleQboUnlink}
                      isQboLoading={isExportingClientToQbo || isFetchingQboCustomer || isUnlinkingClientFromQbo}
                      isErrorFetchingQboCustomer={isErrorFetchingQboCustomer}
                      qboCustomer={qboCustomerData?.client.qboCustomer}
                    />
                  </div>
                </div>
              </>)
              }

              <div className='push-down-small'>
                {isFetchingOpenJobs
                  ? <Spinner isFetching />
                  : openJobsData && (
                    <ClientJobsTable
                      client={clientData.client}
                      pagination={openJobsData.pagination}
                      jobs={openJobsData.jobs}
                      title='Open Jobs'
                      fakeCount={openJobsFakeCount}
                      onPageRequest={onOpenJobsPageRequest}
                    />
                  )
                }
                {isFetchingClosedJobs
                  ? <Spinner isFetching />
                  : closedJobsData && (
                    <ClientJobsTable
                      client={clientData.client}
                      pagination={closedJobsData.pagination}
                      jobs={closedJobsData.jobs}
                      title='Closed Jobs'
                      fakeCount={closedJobsFakeCount}
                      onPageRequest={onClosedJobsPageRequest}
                    />
                  )
                }
              </div>

              {isFetchingInvoices
                ? <Spinner isFetching />
                : (
                  <ClientInvoicesTable
                    invoices={invoices.invoices}
                    onPageRequest={onInvoicesPageRequest}
                    fakeCount={invoicesFakeCount}
                    hauler={hauler}
                  />
                  )
              }

              {isFetchingActivities
                ? <Spinner isFetching />
                : activitiesData && (
                  <ClientActivitiesDisplay
                    activities={activitiesData.activitiesPages.activities}
                    pagination={camelCasedObjectToSnakeCasedObject(activitiesData.activitiesPages.pagination)}
                    onPageRequest={handleActivitiesPageRequest}
                  />
                )
              }

              <button
                className='btn btn-link center-block'
                onClick={handleDestroyClient}>
                <span className='pull-left'>Destroy Account</span>
                <i className='material-icons pull-right thumbster-button-icon'>delete</i>
              </button>
            </>)
            }

        </div>
      </NormalLayoutContainer>
    </>
  )
}
