import React from 'react'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../routes'

export default function HaulerRequiredActionsDisplay () {
  const history = useHistory()
  return (
    <div className='list-group-item'>
      <div className='flex justify-between flex-row'>
        <div className='mr-16'>
          <div className='font-bold text-3xl my-2'>Required Actions</div>
          <div className='text-2xl my-2'>
            Required Actions allow you to configure specific steps that must be completed before a
            specific ticket type can be completed. Drivers will be prompted in the app to complete any
            outstanding actions upon trying to complete a ticket.
          </div>
        </div>

        <div className='h-12 flex self-center'>
          <button
            className='btn bg-green-light'
            onClick={() => history.push(ROUTES.ticketTypesRequiredActions)}>
            <span className='font-medium text-2xl'>
              SET REQUIRED ACTIONS
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}
