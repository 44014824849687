import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { gql } from 'graphql-request'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import styles from './ReportListPage.module.css'
import { Link } from 'react-router-dom'
import { Spinner } from '../shared/Spinner'
import { useDispatch, useSelector } from 'react-redux'
import { SET_REPORTS } from './reducers/reportsReducer'

function ReportCard ({ report }) {
  return (
    <li key={report.id} className={styles.reportItem}>
      <div className={styles.reportCard}>
        <div className={styles.reportCardBody}>
          <div>
            <h3 className={styles.reportCardTitle}>{report.name}</h3>
            <p className={styles.reportCardDescription}>{report.description}</p>
          </div>
          <div>
            <Link className={styles.reportCardButton} to={{
              pathname: `/reports/${report.id}`
            }}>View Report</Link>
          </div>
        </div>
        <div className={styles.reportCardImageContainer}>
          <img className={styles.reportCardImage} src={report.image} alt='report graphic' />
        </div>
      </div>
    </li>
  )
}

ReportCard.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    embedURL: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired
  })
}

export default function ReportListPage () {
  const dispatch = useDispatch()
  const { reports, reportToken, graphQLClient, hauler } = useSelector(({ reports: { reports, reportToken }, dataLoaders, user }) => ({
    reports,
    reportToken,
    graphQLClient: dataLoaders.dispatcher.graphQLClient,
    hauler: user.hauler
  }))

  useEffect(function onInitialRender () {
    const query = gql`
      query Reports($haulerId: String!) {
        reports(haulerId: $haulerId) {
          token
          reports {
            id
            name
            description
            embedURL
            image
          }
        }    
      }
    `

    graphQLClient.request(query, { haulerId: hauler.id })
      .then(({ reports: { token, reports } }) => {
        dispatch({ type: SET_REPORTS, payload: { reports, reportToken: token } })
      })
  }, [hauler.id, dispatch, graphQLClient])

  return (
    <NormalLayoutContainer>
      <h1 className={styles.title}>Reports</h1>
      <p className={styles.description}>Please note, reports will include activity through the prior day.</p>
      {
        reportToken
          ? <ul className={styles.reports}>
            {reports.map(report => (
              <ReportCard key={report.id} report={report} />
            ))}
          </ul>
          : <Spinner isFetching />
      }

    </NormalLayoutContainer>
  )
}
