import React from 'react'
import PropTypes from 'prop-types'

export default function JobActionButton ({ status, disabled, onTouchTap }) {
  const closeJob = () => {
    onTouchTap('closed')
  }
  const openJob = () => {
    onTouchTap('open')
  }

  return (
    <div>
      {(() => {
        if (status === 'open') {
          return (
            <button
              onClick={closeJob}
              className='btn btn-lg btn-warning pull-right push-down-super-small space-left'
              disabled={disabled}>
              <span className='pull-left'>Close Job</span>
              <i className='material-icons pull-right thumbster-button-icon'>lock</i>
            </button>
          )
        } else if (status === 'closed') {
          return (
            <button
              onClick={openJob}
              className='btn btn-lg btn-primary pull-right push-down-super-small space-left'>
              <span className='pull-left'>Open Job</span>
              <i className='material-icons pull-right thumbster-button-icon'>lock_open</i>
            </button>
          )
        }
        return null
      })()}
    </div>
  )
}

JobActionButton.propTypes = {
  disabled: PropTypes.bool,
  status: PropTypes.string,
  onTouchTap: PropTypes.func.isRequired
}
