export const DIRECT_UPLOAD_URL = `${process.env.REACT_APP_NEW_API_URL}/bucket/storage/direct_uploads`
export const MAX_ALLOWED_BYTES = 10485760 // 10 MB

export const fileExtensionValidator = (file) => {
  const filePieces = file.name.split('.')
  const fileExtension = filePieces[filePieces.length - 1]
  if (fileExtension === 'csv') return null
  return {
    code: 'invalid-extension',
    message: 'File must have .csv extension'
  }
}
