import { loader } from 'graphql.macro'
import { intercomConnection } from '../../intercomConnection'

export default async function deleteResource ({ dataLoaders, variables }) {
  const schema = loader('./deleteResource.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      id: variables.id
    }
  })
  intercomConnection.trackEvent('resource_deleted', { id: variables.id })
  return response.deleteResource
}
