import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../../../../routes'
import { stringToTime, enumToTitleCase } from '../../../../../../utilities/stringUtilities'
import dayjs from '../../../../../../utilities/dayjs'
import WarningIcon from '../../../../../shared/WarningIcon'
import TicketStatusLabel from '../../../../../../components/TicketStatusLabel'
import CursorPagination from '../../../../../../components/cursor-pagination'
import BillingStatusIcon from '../../../../../../components/billing-status-display'

export default class JobTicketsTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      selectedTickets: [],
      allSelected: false
    }

    this.selectTicket = (id) => {
      const { selectedTickets } = this.state
      const idx = selectedTickets.indexOf(id)
      if (idx === -1) {
        selectedTickets.push(id)
        this.setState({ selectedTickets })
      } else {
        selectedTickets.splice(idx, 1)
        this.setState({
          selectedTickets,
          allSelected: false
        })
      }
    }

    this.selectAll = () => {
      if (this.state.allSelected) {
        this.setState({
          selectedTickets: [],
          allSelected: false
        })
      } else {
        this.setState({
          selectedTickets: this.props.tickets?.nodes.map(ticket => ticket.id),
          allSelected: true
        })
      }
    }

    this.onPageRequest = (page = 1) => {
      this.setState({
        selectedTickets: [],
        allSelected: false
      })
      this.props.onPageRequest(page)
    }
  }

  render () {
    const { tickets, title, job, handleCreateTicket, onPageRequest, fakeCount } = this.props

    return (
      <div className='row panel panel-default'>
        <div className='panel-heading'>
          <div className='container-fluid'>

            {(() => {
              if (title === 'Open Tickets') {
                return (
                  <div>
                    <h4 className='pull-left'>
                      {title} - {job?.activeTicketsCount} tickets
                      &nbsp;
                      <Link to={`/hauler/tickets/search?jobCustomId=${job?.customId}&status=active`}>
                        <small className='text-primary link'>(View All Open Tickets)</small>
                      </Link>
                    </h4>

                    {(() => {
                      if (job?.status !== 'open') return null
                      return (
                        <div>
                          <Link
                            className='btn btn-lg btn-primary pull-right'
                            to={`${ROUTES.bulkCreateTickets}?job_id=${job.id}`}>
                            <span className='hidden-xs pull-left'>Bulk Create Tickets</span>
                            <i className='material-icons pull-right thumbster-button-icon no-margin-bs-xs'>add_circle</i>
                          </Link>
                          <button
                            className='btn btn-lg btn-primary pull-right space-right'
                            onClick={handleCreateTicket}>
                            <span className='hidden-xs pull-left'>Create New Ticket</span>
                            <i className='material-icons pull-right thumbster-button-icon no-margin-bs-xs'>add</i>
                          </button>
                        </div>
                      )
                    })()}
                  </div>
                )
              }
              return (
                <div>
                  <h4 className='pull-left'>
                    {title} - {job?.terminalTicketsCount} tickets
                      &nbsp;
                    <Link
                      to={`/hauler/tickets/search?jobCustomId=${job?.customId}&status=terminal`}>
                      &nbsp;<small className='text-primary link'>(View All Closed Tickets)</small>
                    </Link>
                  </h4>
                </div>
              )
            })()}
          </div>
        </div>
        <div className='panel-body table-responsive'>
          <div className='dropdown'>
            <button
              className='btn btn-lg btn-alert dropdown-toggle'
              type='button'
              id='batchActions'
              data-toggle='dropdown'
              disabled={!this.state.selectedTickets.length}>
              <span className='space-right'>Bulk Actions ({this.state.selectedTickets.length} tickets)</span>
              <span className='caret' />
            </button>
            <ul
              className='dropdown-menu'>
              <li>
                {/*
                 eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                 */}
                <a
                  onClick={() => this.props.onBatchEdit(this.state.selectedTickets)}>
                  EDIT {this.state.selectedTickets.length} TICKETS
                </a>
              </li>
              <li>
                {/*
                 eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                  */}
                <a
                  className='text-danger'
                  onClick={() => this.props.onBatchDelete(this.state.selectedTickets)}>
                  DELETE {this.state.selectedTickets.length} TICKETS
                </a>
              </li>
            </ul>
          </div>
          <table className='table table-striped push-down-super-small'>
            <thead>
              <tr>
                <th width='4%'>
                  <input
                    type='checkbox'
                    onChange={this.selectAll}
                    checked={this.state.allSelected}
                  />
                </th>
                <th width='3%' />
                <th width='5%'>ID</th>
                <th width='8%'>Status</th>
                <th width='1%' className='cell-no-padding-x' />
                <th width='9%'>Billing Status</th>
                <th width='15%'>Date</th>
                <th width='17%'>Ticket Type</th>
                <th width='17%'>Asset Type</th>
                <th width='9%'>View Details</th>
              </tr>
            </thead>
            <tbody>
              {(() => {
                if (!tickets || tickets.totalCount === 0) {
                  return (<tr>
                    <td colSpan={9}>
                      <h4 className='text-center'>No Tickets</h4>
                    </td>
                  </tr>)
                }
              })()}
              {tickets.nodes.map(ticket => {
                const ticketPath = `/hauler/tickets/${ticket.id}`
                return (
                  <tr key={ticket.id}>
                    <td>
                      <input
                        type='checkbox'
                        onChange={() => this.selectTicket(ticket.id)}
                        checked={this.state.selectedTickets.indexOf(ticket.id) !== -1}
                      />
                    </td>
                    <td>
                      {ticket.flagged ? <WarningIcon /> : null}
                    </td>
                    <td>
                      <Link to={ticketPath}>{ticket.customId}</Link>
                    </td>
                    <td>
                      <Link to={ticketPath}>
                        <TicketStatusLabel
                          status={ticket.status}
                          exceptionMessage={ticket.exceptionMessage}
                          hasActiveException={ticket.hasActiveException}
                        />
                      </Link>
                    </td>
                    <td className='cell-no-padding-x'>
                      <BillingStatusIcon billingStatus={ticket.billingStatus} />
                    </td>
                    <td>{enumToTitleCase(ticket.billingStatus)}</td>
                    <td>
                      <div>
                        {dayjs(ticket.date).format('MMM D, YYYY')}
                        <br />
                        <small>
                          {
                            ticket.requestedStartTime
                              ? `${stringToTime(ticket.requestedStartTime)}-${stringToTime(ticket.requestedEndTime)}`
                              : null
                          }
                        </small>
                      </div>
                    </td>
                    <td>
                      {ticket.ticketType.name} ({ticket.ticketType.shortCode})
                    </td>
                    <td>
                      {ticket.resourceType.name} ({ticket.resourceType.shortCode})
                    </td>
                    <td>
                      <Link to={ticketPath}>
                        <i className='material-icons medium-icon'>info</i>
                      </Link>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          {tickets.totalCount > 0 && <CursorPagination
            pageInfo={tickets.pageInfo}
            totalCount={tickets.totalCount}
            onPageRequest={onPageRequest}
            fakeCount={fakeCount}
                                     />}
        </div>
      </div>
    )
  }
}

JobTicketsTable.propTypes = {
  handleCreateTicket: PropTypes.func,
  job: PropTypes.any.isRequired,
  onPageRequest: PropTypes.func.isRequired,
  tickets: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  onBatchDelete: PropTypes.func,
  onBatchEdit: PropTypes.func,
  fakeCount: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired
  })
}
