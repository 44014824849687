import { loader } from 'graphql.macro'

export default function clientImports ({ dataLoaders, queryKey }) {
  const [, variables = {}] = queryKey
  const schema = loader('./clientImports.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(schema, {
    first: variables.first,
    before: variables.before,
    after: variables.after,
    last: variables.last
  })
}
