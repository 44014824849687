import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { randomAlphabeticString } from '../../../utilities/randomUtilities'
import { googleMapsLink, prettyAddress, prettyAddressWithDetails } from '../../../utilities/locationUtilities'

export default class StaticGoogleMap extends Component {
  static buildInfoWindow (addrComponents, lat, lng, showLatLng) {
    let prettified = ''
    if ((addrComponents && (addrComponents.address_details || addrComponents.addressDetails)) || addrComponents.job) {
      prettified = prettyAddressWithDetails(addrComponents)
    } else {
      prettified = prettyAddress(addrComponents)
    }
    const latLng = showLatLng ? `(${lat}, ${lng})` : ''
    const infowindow = new google.maps.InfoWindow()
    // const incompleteAddress = prettified.includes('unkown')
    infowindow.setContent(
      `<a href='${googleMapsLink(addrComponents)}' target='_blank'>
        <h6>
          <strong>${prettified}</strong>
          <div>${latLng}</div>
          &nbsp;<small className="text-primary link">(View in Google Maps)</small>
        </h6>
      </a>`
    )

    return infowindow
  }

  static buildMap (mapId, latitude, longitude) {
    return new google.maps.Map(document.getElementById(mapId), {
      center: {
        lat: latitude + 0.1,
        lng: longitude
      },
      zoom: 10,
      mapTypeId: 'roadmap',
      draggable: false,
      clickableIcons: false,
      scrollwheel: false
    })
  }

  static buildMarker (map) {
    return new google.maps.Marker({
      map
    })
  }

  static moveMarker (marker, lat, lng) {
    const latLng = new google.maps.LatLng(lat, lng)
    marker.setPosition(latLng)

    marker.setVisible(true)
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.setState({
      mapId: randomAlphabeticString(10)
    })
  }

  componentDidMount () {
    const { mapId } = this.state
    const { addrComponents, showLatLngInfo } = this.props
    const latitude = parseFloat(this.props.latitude)
    const longitude = parseFloat(this.props.longitude)
    if (!(addrComponents && latitude && longitude)) {
      return
    }
    const map = StaticGoogleMap.buildMap(
      mapId,
      latitude,
      longitude
    )

    map.addListener('zoom_changed', () => {
      map.setCenter({
        lat: this.props.latitude,
        lng: this.props.longitude
      })
    })

    const marker = StaticGoogleMap.buildMarker(map)

    StaticGoogleMap.moveMarker(marker, latitude, longitude)
    if (addrComponents) {
      const infoWindow = StaticGoogleMap.buildInfoWindow(addrComponents, latitude, longitude, showLatLngInfo)
      infoWindow.open(map, marker)
    }
  }

  render () {
    return (
      <div>
        <div className={this.props.mapClass || 'static-google-map'} id={this.state.mapId} />
      </div>
    )
  }
}

StaticGoogleMap.propTypes = {
  place_id: PropTypes.string,
  addrComponents: PropTypes.any,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  showLatLngInfo: PropTypes.bool,
  mapClass: PropTypes.string
}
