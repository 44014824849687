import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../routes'
import NormalLayoutContainer from './shared/NormalLayoutContainer'
import { PageTitle } from '../components/page-title'
import { Spinner } from './shared/Spinner'
import TicketTypeItem from '../components/TicketTypeItem'
import { useSelector } from 'react-redux'
import ticketTypesQuery from '../graphql/queries/ticketTypes'
import QUERY_KEYS from '../graphql/queryKeys'
import { captureErrorAndNotify } from '../utilities/errorHandlers'
import useQuery from '../hooks/useQuery'

export default function TicketTypesPage () {
  const { user } = useSelector(({ user }) => ({ user: user.user }))

  const { data: { ticketTypes }, isFetching: isLoadingTicketTypes } = useQuery(
    [QUERY_KEYS.ticketTypes, { haulerId: user.haulerId }],
    ticketTypesQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Failed to retrieve ticket types')
      },
      placeholderData: { ticketTypes: [] }
    }
  )

  return (
    <NormalLayoutContainer>
      <div className='container-fluid'>
        <PageTitle title='Ticket Types'>
          <Link className='dis-btn dis-btn-lg dis-btn-primary push-down-super-small' to={ROUTES.newTicketType}>
            New Ticket Type
            <i className='material-icons pull-right thumbster-button-icon no-margin-bs-xs'>add_circle</i>
          </Link>
        </PageTitle>
        <br />

        {isLoadingTicketTypes
          ? <Spinner isFetching />
          : (
            <div className='row panel panel-default'>
              {ticketTypes.length === 0 && <h4 className='text-center'>No Ticket Types</h4>}
              {ticketTypes.map(ticketType => (
                <TicketTypeItem
                  key={ticketType.id}
                  ticketType={ticketType}
                />
              ))}
            </div>
            )
          }
      </div>
    </NormalLayoutContainer>
  )
}
