import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { HorizontalBar } from 'react-chartjs-2'
import {
  determineData,
  getElementClick,
  handleTicketsByResourceType,
  options
} from './TicketsPerResourceTypeGraphUtils'

export default function TicketsPerResourceTypeGraph ({ tickets }) {
  const history = useHistory()
  const ticketsByResourceType = handleTicketsByResourceType(tickets)
  const handleElementClick = useCallback(
    (elem) => getElementClick(elem, history, ticketsByResourceType),
    [history, ticketsByResourceType]
  )

  const keys = Object.keys(ticketsByResourceType)
  const data = determineData(keys, ticketsByResourceType)

  return (
    <div className='text-center'>
      <div className='push-down-very-small'>
        <HorizontalBar
          data={data}
          options={options}
          width={1000}
          height={500}
          onElementsClick={handleElementClick}
        />
      </div>
    </div>
  )
}

TicketsPerResourceTypeGraph.propTypes = {
  tickets: PropTypes.array
}
