import React from 'react'
import { Link, generatePath } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ROUTES } from '../routes'
import TicketTypeStatusLabel from '../pages/shared/TicketTypeStatusLabel'

export default function TicketTypeItem ({ ticketType }) {
  return (
    <div className='list-group-item'>
      <h4 className='list-group-item-heading'>
        <div className='font-size-0-75em inline-block status-pill'>
          <TicketTypeStatusLabel status={ticketType.status} />
        </div>
        &nbsp;
        {`${ticketType.name} (${ticketType.shortCode})`}
        <Link className='btn btn-primary-dk pull-right space-left' to={generatePath(ROUTES.editTicketType, { id: ticketType?.id })}>
          <span className='pull-left hidden-xs'>Edit</span>
          <i className='material-icons pull-right thumbster-button-icon no-margin-bs-xs'>mode_edit</i>
        </Link>
        <Link className='btn btn-link pull-right hidden-xs' to={{
          pathname: ROUTES.ticketSearch,
          search: `?${new URLSearchParams({ ticketTypeId: ticketType.id }).toString()}`
        }}>
          <span className='pull-left'>{ticketType.ticketsCount} TICKETS</span>
          <i className='material-icons pull-right thumbster-button-icon'>search</i>
        </Link>
      </h4>
    </div>
  )
}

TicketTypeItem.propTypes = {
  ticketType: PropTypes.object.isRequired
}
