import {
  REQUEST_BATCH_TICKETS_DELETE_ACTION,
  RECEIVE_BATCH_TICKETS_DELETE_SUCCESS,
  RECEIVE_BATCH_TICKETS_DELETE_FAILURE
} from '../sagas/batchTicketsDeleteSaga'

export const initialState = {
  isFetching: false,
  errors: []
}

export default function batchTicketsDeleteReducer (reduxState = initialState, action) {
  const { payload, type } = action

  switch (type) {
    case REQUEST_BATCH_TICKETS_DELETE_ACTION:
      return {
        ...reduxState,
        isFetching: true,
        errors: []
      }
    case RECEIVE_BATCH_TICKETS_DELETE_SUCCESS:
      return {
        ...reduxState,
        isFetching: false
      }
    case RECEIVE_BATCH_TICKETS_DELETE_FAILURE:
      return {
        ...reduxState,
        isFetching: false,
        errors: payload.errors
      }
    default:
      return reduxState
  }
}
