
import {
  REQUEST_CURRENT_SITE_TYPE_ACTION,
  RECEIVE_CURRENT_SITE_TYPE_SUCCESS,
  RECEIVE_CURRENT_SITE_TYPE_FAILURE,
  REQUEST_UPDATE_CURRENT_SITE_TYPE_ACTION,
  RECEIVE_UPDATE_CURRENT_SITE_TYPE_SUCCESS,
  RECEIVE_UPDATE_CURRENT_SITE_TYPE_FAILURE,
  CLEAR_CURRENT_SITE_TYPE_ERRORS
} from '../sagas/currentSiteTypeSaga'

const initialState = {
  isFetching: false,
  errors: [],
  siteType: {}
}

const currentSiteTypeReducer = (reduxState = initialState, action) => {
  const { payload, type } = action
  switch (type) {
    case REQUEST_CURRENT_SITE_TYPE_ACTION:
      return {
        ...reduxState,
        isFetching: true,
        errors: []
      }

    case RECEIVE_CURRENT_SITE_TYPE_SUCCESS:
      return {
        ...reduxState,
        isFetching: false,
        errors: [],
        siteType: payload.site_type
      }

    case RECEIVE_CURRENT_SITE_TYPE_FAILURE:
      return {
        ...reduxState,
        isFetching: false,
        errors: payload.errors
      }

    case REQUEST_UPDATE_CURRENT_SITE_TYPE_ACTION:
      return {
        ...reduxState,
        isFetching: false,
        errors: []
      }

    case RECEIVE_UPDATE_CURRENT_SITE_TYPE_SUCCESS:
      return {
        ...reduxState,
        isFetching: true,
        errors: [],
        siteType: payload.site_type
      }

    case RECEIVE_UPDATE_CURRENT_SITE_TYPE_FAILURE:
      return {
        ...reduxState,
        isFetching: false,
        errors: payload.errors
      }

    case CLEAR_CURRENT_SITE_TYPE_ERRORS:
      return {
        ...reduxState,
        isFetching: false,
        errors: []
      }

    default:
      return reduxState
  }
}

export default currentSiteTypeReducer
