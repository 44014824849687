import React, { useEffect, useState } from 'react'
// import React, { useEffect, useMemo, useState } from 'react'
import { validate, formatJobAddressComponents } from './utils'
import styles from './index.module.scss'
import { Field, Form } from 'react-final-form'
import cn from 'classnames'
import Spinner from 'react-spinkit'
import { commaDeliminate, buildCardName } from '../../utilities/stringUtilities'
import PropTypes from 'prop-types'
import Modal from '../modal/Modal'
import ManageCcCardModal from '../manage-cc-card-modal'
import NewCardModal from './new-card-modal'

export function CcPaymentForm ({
  onSubmit,
  invoice,
  initialValues = {},
  cardList = [],
  hasCardQueryError = false,
  errorMessage = [],
  disabled = false,
  billingAddress = {},
  isSolo,
  hauler,
  newCard,
  setNewCard,
  refetchCardList,
  setCardError
}) {
  const [showManageCardsModal, setShowManageCardsModal] = useState(false)
  const [showNewCardModal, setShowNewCardModal] = useState(false)

  function handleOpenModal () {
    setShowManageCardsModal(true)
  }

  function handleCloseModal () {
    setShowManageCardsModal(false)
  }

  function handleOpenNewCardModal () {
    setNewCard(null)
    setCardError([])
    setShowNewCardModal(true)
  }

  function handleCloseNewCardModal () {
    setShowNewCardModal(false)
  }

  function uncheckBillingAddressSameAsDelivery (args, state, tools) {
    const [name, value] = args
    tools.changeValue(state, 'billingAddressSameAsDelivery', () => false)
    tools.changeValue(state, name, () => value)
  }

  return (
    <>
      <Modal
        className={styles.newCardsModalContainer}
        isOpen={showNewCardModal}
        closeModal={handleCloseNewCardModal}>
        <NewCardModal
          invoice={invoice}
          hauler={hauler}
          billingAddress={billingAddress}
          close={handleCloseNewCardModal}
          setNewCard={setNewCard}
          setCardError={setCardError}
          refetchCardList={refetchCardList}
        />
      </Modal>
      <Modal
        className={styles.manageCardsModalContainer}
        isOpen={showManageCardsModal}
        closeModal={handleCloseModal}>
        <ManageCcCardModal
          clientName={invoice.client.name}
          clientId={invoice.client.id}
          countryCode={hauler.country}
          cardList={cardList}
          close={handleCloseModal}
          refetchCardList={refetchCardList}
        />
      </Modal>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{ uncheckBillingAddressSameAsDelivery }}
        invoice={invoice}
        openManageCardsModal={handleOpenModal}
        openNewCardModal={handleOpenNewCardModal}
        cardList={cardList}
        validate={validate}
        component={InternalForm}
        hasCardQueryError={hasCardQueryError}
        errorMessage={errorMessage}
        disabled={disabled}
        isSolo={isSolo}
        newCard={newCard}
      />
    </>
  )
}

CcPaymentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  invoice: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  cardList: PropTypes.arrayOf(PropTypes.object),
  hasCardQueryError: PropTypes.bool,
  errorMessage: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  isSolo: PropTypes.bool.isRequired,
  billingAddress: PropTypes.shape({
    streetAddress: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string
  }),
  hauler: PropTypes.shape({
    country: PropTypes.string.isRequired
  }).isRequired,
  setNewCard: PropTypes.func.isRequired,
  newCard: PropTypes.shape({
    cardBrand: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    id: PropTypes.number,
    isUnsavedCard: PropTypes.bool
  }),
  refetchCardList: PropTypes.func.isRequired,
  setCardError: PropTypes.func.isRequired
}

function InternalForm ({
  handleSubmit,
  form,
  values,
  invalid,
  submitting,
  initialValues = {},
  invoice,
  openManageCardsModal,
  openNewCardModal,
  cardList = [],
  hasCardQueryError = false,
  errorMessage = [],
  disabled = false,
  isSolo,
  newCard
}) {
  const job = invoice?.tickets?.[0]?.job

  useEffect(function setJobAddress () {
    if (!values.billingAddressSameAsDelivery) return
    form.batch(() => {
      form.change('streetAddress', formatJobAddressComponents(values.billingAddressSameAsDelivery ? job?.addressline1 : initialValues.streetAddress))
      form.change('city', formatJobAddressComponents(values.billingAddressSameAsDelivery ? job?.city : initialValues.zip))
      form.change('state', formatJobAddressComponents(values.billingAddressSameAsDelivery ? job?.state : initialValues.state))
      form.change('zip', formatJobAddressComponents(values.billingAddressSameAsDelivery ? job?.zip : initialValues.zip))
    })
  }, [
    form,
    job?.addressline1,
    initialValues.streetAddress,
    job?.city,
    initialValues.city,
    job?.state,
    initialValues.state,
    job?.zip,
    initialValues.zip,
    values.billingAddressSameAsDelivery
  ])

  useEffect(function selectNewSavedCard () {
    if (!newCard) return

    form.batch(() => {
      form.change('saleCC', newCard.id ?? newCard.token)
      form.change('streetAddress', newCard.billingAddress.streetAddress)
      form.change('city', newCard.billingAddress.city)
      form.change('state', newCard.billingAddress.state)
      form.change('zip', newCard.billingAddress.zip)
    })
  }, [form, newCard])

  function handleReset () {
    form.reset()
  }

  const hasError = meta => meta.touched && meta.error && !meta.active
  const cardOnFile = cardList.length > 0 || hasCardQueryError

  return (
    <form onSubmit={handleSubmit} autoComplete='off' className={cn({ [styles.soloForm]: isSolo })}>
      <div className={styles.cardDetails}>
        <p>
          Card Details
        </p>
        <div className={styles.ccImgContainer}>
          <img src='https://www.dispatcher.com/images/credit-card-logos.png' alt='Credit Card Logos' className={styles.ccImg} />
        </div>
        <div className={styles.manageCardsWrapper}>
          {cardOnFile && !hasCardQueryError && (
            <button
              className={cn('dis-btn dis-btn-link', styles.manageCardsBtn)}
              type='button'
              onClick={openManageCardsModal}>
              Manage Cards
            </button>)
            }
        </div>
      </div>
      <div className={styles.radioInputContainer}>
        <div className={styles.savedCardFormSection}>
          {(newCard && newCard.token) && (
            <div className={styles.radioInput}>
              <Field id='card-not-saved' name='saleCC' type='radio' component='input' value={newCard.token} />
              <label htmlFor='card-not-saved' className={styles.radioLabel}>
                {`${newCard.cardBrand}${newCard.cardNumber.slice(-5)}`}
              </label>
            </div>
          )}
          {cardList.map((card, key) =>
            (
              <div className={styles.radioInput} key={`card-${key}`}>
                <Field id={`card-${key}`} name='saleCC' type='radio' component='input' value={card.id} disabled={hasCardQueryError} />
                <label htmlFor={`card-${key}`} className={styles.radioLabel}>
                  {buildCardName(card)}
                </label>
              </div>
            )
          )}
        </div>
        {hasCardQueryError && <div className={styles.cardQueryError}>Error loading saved cards</div>}
      </div>
      <div>
        <button
          className={cn(styles.newCardBtn, 'dis-btn dis-btn-link')}
          type='button'
          onClick={openNewCardModal}>
          + Add New Card
        </button>
      </div>
      <Field name='streetAddress'>
        {({ input }) => (
          <input {...input} type='hidden' />
        )}
      </Field>
      <Field name='city'>
        {({ input }) => (
          <input {...input} type='hidden' />
        )}
      </Field>
      <Field name='state'>
        {({ input }) => (
          <input {...input} type='hidden' />
        )}
      </Field>
      <Field name='zip'>
        {({ input }) => (
          <input {...input} type='hidden' />
        )}
      </Field>
      <Field name='email'>
        {({ input, meta }) => (
          <div className={cn({ 'has-error': hasError(meta) })}>
            <label className={styles.fullWidthInput}>
              Email (comma separated)
              <textarea
                {...input}
                className='form-control'
              />
            </label>
            {hasError(meta) && <h6>{meta.error}</h6>}
          </div>
        )}
      </Field>
      {errorMessage.length > 0 &&
        <div className={styles.paymentError}>
          <div><i className={cn(styles.errorIcon, 'material-icons')}>error</i></div>
          <div>
            <p className={styles.paymentErrorHeader}>{errorMessage[0]}</p>
            {errorMessage.length > 1 &&
              <p className={styles.errorDetails}>{errorMessage[1]}</p>
            }
          </div>
        </div>
      }
      <div className={styles.btnContainer}>
        <button
          type='button'
          onClick={handleReset}
          className={cn('dis-btn dis-btn-white dis-btn-lg', styles.btn)}>
          Reset
        </button>
        <button
          type='submit'
          className={cn('dis-btn dis-btn-primary dis-btn-lg', styles.btn)}
          disabled={disabled || submitting || invalid || invoice.total <= 0}>
          {submitting ? <Spinner name='circle' fadeIn='none' /> : `Charge $${commaDeliminate(invoice.outstandingBalance)}`}
        </button>
      </div>
      <div className={styles.wpImgContainer}>
        <img src='https://www.dispatcher.com/images/WP-Secure-Payments.svg' alt='Waste Pay secure payments logo' className={styles.wpImg} />
      </div>
    </form>
  )
}

InternalForm.propTypes = {
  /**
   * https://final-form.org/docs/react-final-form/types/FormRenderProps#handlesubmit
   */
  handleSubmit: PropTypes.func.isRequired,
  /**
   * https://final-form.org/docs/react-final-form/types/FormRenderProps#form
   */
  form: PropTypes.shape({
    /**
     * https://final-form.org/docs/final-form/types/FormApi#batch
     */
    batch: PropTypes.func.isRequired,
    /**
     * https://final-form.org/docs/final-form/types/FormApi#change
     */
    change: PropTypes.func.isRequired,
    /**
     * https://final-form.org/docs/final-form/types/FormApi#reset
     */
    reset: PropTypes.func.isRequired,
    mutators: PropTypes.shape({
      uncheckBillingAddressSameAsDelivery: PropTypes.func.isRequired
    }).isRequired
  }).isRequired,
  /**
   * https://final-form.org/docs/final-form/types/FormState#values
   */
  values: PropTypes.shape({
    paymentType: PropTypes.string,
    saleCC: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    cardNumber: PropTypes.string,
    expiration: PropTypes.string,
    securityCode: PropTypes.string,
    billingAddressSameAsDelivery: PropTypes.bool,
    streetAddress: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    email: PropTypes.string
  }).isRequired,
  /**
   * https://final-form.org/docs/final-form/types/FormState#invalid
   */
  invalid: PropTypes.bool.isRequired,
  /**
   * https://final-form.org/docs/final-form/types/FormState#submitting
   */
  submitting: PropTypes.bool.isRequired,
  /**
   * https://final-form.org/docs/final-form/types/FormState#initialvalues
   */
  initialValues: PropTypes.shape({
    saleCC: PropTypes.string,
    email: PropTypes.string
  }),
  invoice: PropTypes.shape({
    total: PropTypes.number,
    outstandingBalance: PropTypes.number,
    client: PropTypes.shape({
      billingDetails: PropTypes.string
    }).isRequired,
    tickets: PropTypes.arrayOf(PropTypes.shape({
      job: PropTypes.shape({
        id: PropTypes.string.isRequired,
        addressline1: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string
      })
    }))
  }).isRequired,
  openManageCardsModal: PropTypes.func.isRequired,
  openNewCardModal: PropTypes.func.isRequired,
  cardList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    cardNumber: PropTypes.string.isRequired,
    cardBrand: PropTypes.string.isRequired,
    isPrimary: PropTypes.bool.isRequired
  })),
  hasCardQueryError: PropTypes.bool,
  errorMessage: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  isSolo: PropTypes.bool.isRequired,
  billingAddress: PropTypes.shape({
    billingAddressSameAsDelivery: PropTypes.bool,
    streetAddress: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string
  }),
  newCard: PropTypes.shape({
    cardBrand: PropTypes.string.isRequired,
    cardNumber: PropTypes.string.isRequired,
    token: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    billingAddress: PropTypes.shape({
      streetAddress: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired
    })
  })
}
