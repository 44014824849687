import { loader } from 'graphql.macro'

export default function exportClientToQboWastepay ({ dataLoaders, variables }) {
  const schema = loader('./exportClientToQboWastepay.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(schema, {
    id: variables.id,
    exportToQuickBooks: variables.exportToQuickBooks,
    exportToWastepay: variables.exportToWastepay,
    exportDontAskAgain: {
      haulerId: variables.haulerId,
      clientFields: {
        didPromptClientExportSelection: variables.dontAskAgain
      },
      clientId: variables.id
    }
  })
}
