import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Form, Field } from 'react-final-form'
import fromPairs from 'lodash/fromPairs'
import map from 'lodash/map'
import { TextInput } from '../TextInput'
import { SelectInput } from '../SelectInput'
import AutocompleteGoogleMap from '../../pages/shared/locationPickers/AutocompleteGoogleMap'
import { prettyAddress, prettyAddressWithDetails } from '../../utilities/locationUtilities'
import { ROUTES } from '../../routes'

export default function CurrentResourceEditForm ({
  setLocationValues,
  onSubmit,
  pristine,
  invalid,
  resourceTypes,
  currentResource,
  initialValues,
  isFetching
}) {
  const onLocationChange = (deets) => {
    const location = {
      latitude: deets.latitude,
      longitude: deets.longitude,
      place_id: deets.place_id,
      addressline1: deets.addressline1,
      city: deets.city,
      state: deets.state,
      zip: deets.zip,
      country: deets.country
    }
    setLocationValues(location)
  }
  const resourceTypesOptions = fromPairs(map(resourceTypes, rt => [rt.id, rt.name]))

  return (
    <div className='row panel panel-default'>
      <div className='container-fluid push-down-super-small'>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className='row'>

                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                  <Field
                    name='uid'
                    label='Asset ID'
                    component={TextInput}
                    maxLength='30'
                  />
                </div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                  <Field
                    name='resourceTypeId'
                    component={SelectInput}
                    label='Asset Type'
                    options={resourceTypesOptions}
                  />
                </div>
                <div className='col-xs-12'>
                  <Field
                    name='placementNote'
                    component={TextInput}
                    label='Placement Note'
                    maxLength='250'
                  />
                </div>
              </div>

              <div className='push-down-very-small'>
                {(() => {
                  if (currentResource && !currentResource?.job?.id && !currentResource?.site?.id) {
                    return (
                      <div>
                        <div>
                          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                          <label className='control-label'>Location</label>
                          {(() => {
                            const beginString = initialValues.latitude ? 'This asset has a manually entered location.' : 'This asset DOES NOT have a location.'
                            return (
                              <h5>
                                {beginString}
                                <br />
                                <br />
                                You can edit the location on the map below,&nbsp;
                                <Link to='/hauler/jobs/search'>
                                  attach it to a job
                                </Link>, or&nbsp;
                                <Link to={ROUTES.sitesSearch}>
                                  attach it to a site
                                </Link>.
                              </h5>
                            )
                          })()}
                          <br />
                        </div>
                        <AutocompleteGoogleMap
                          place_id={initialValues.placeId}
                          latitude={parseFloat(initialValues.latitude)}
                          longitude={parseFloat(initialValues.longitude)}
                          placeholder='Enter Address'
                          initialValue={initialValues.initialAddress === 'unknown unknown, unknown unknown' ? '' : initialValues.initialAddress}
                          onLocationChange={onLocationChange}
                        />
                      </div>
                    )
                  }
                  if (currentResource && currentResource?.site?.id) {
                    return (
                      <div>
                        <div>Location</div>
                        <h5>
                          {'This asset is attached to '}
                          <Link to={`/hauler/sites/${currentResource?.site?.id}`}>
                            Site {currentResource?.site.name}
                          </Link>
                          {`, located at ${prettyAddress(currentResource?.currentLocation)}.`}
                        </h5>
                        <h5>
                          {'To change the location manually, remove it from '}
                          <Link to={`/hauler/sites/${currentResource?.site?.id}`}>
                            Site {currentResource?.site.name}
                          </Link>
                          {', then you can set it on a map.'}
                        </h5>
                      </div>
                    )
                  }
                  return (
                    <div>
                      <div>Location</div>
                      <h5>
                        {'This asset is attached to '}
                        <Link to={`/hauler/jobs/${currentResource?.job?.id}`}>
                          Job #{currentResource?.job?.customId}
                        </Link>
                        {`, located at ${prettyAddressWithDetails(currentResource?.job)}.`}
                      </h5>
                      <h5>
                        {'To change the location manually, remove it from '}
                        <Link to={`/hauler/jobs/${currentResource?.job.id}`}>
                          Job #{currentResource?.job.customId}
                        </Link>
                        {', then you can set it on a map.'}
                      </h5>
                    </div>
                  )
                })()}
                <button
                  type='submit'
                  className='btn btn-lg btn-primary push-down-super-small pull-right'
                  disabled={pristine || invalid || isFetching}>
                  <span className='pull-left'>Save Changes</span>
                  <i className='material-icons pull-right thumbster-button-icon'>check</i>
                </button>
              </div>
            </form>
          )}
        </Form>
      </div>
      <br />
    </div>
  )
}

CurrentResourceEditForm.propTypes = {
  setLocationValues: PropTypes.func,
  currentResource: PropTypes.any,
  initialValues: PropTypes.any,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  onSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  resource: PropTypes.any,
  resourceTypes: PropTypes.array
}
