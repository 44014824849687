import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

class ActivityLink extends Component {
  render () {
    const activityType = this.props.activityType
    const activityId = this.props.activityId
    let url
    if (activityId) {
      url = `hauler/${activityType}/${activityId}`
    } else {
      url = `hauler/${activityType}`
    }

    return (
      <Link to={url}>
        {this.props.children}
      </Link>
    )
  }
}

ActivityLink.propTypes = {
  activityType: PropTypes.string.isRequired,
  activityId: PropTypes.number.isRequired,
  children: PropTypes.any
}

export default ActivityLink
