import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import QUERY_KEYS from '../../graphql/queryKeys'
import { Spinner } from '../shared/Spinner'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import PageTitle from '../../components/page-title'
import RefundForm from '../../components/refunds/RefundForm'
import invoicePaymentDetailsQuery from '../../graphql/queries/invoicePaymentDetails'
import wastepayTransactionQuery from '../../graphql/queries/wastepayTransaction'
import invoiceSettingsQuery from '../../graphql/queries/invoiceSettings'
import wastepayRefundTransactionMutation from '../../graphql/mutations/wastepayRefundTransaction'
import exportRefundToQuickbooksMutation from '../../graphql/mutations/exportRefundToQuickbooks'
import useQuery from '../../hooks/useQuery'
import useMutation from '../../hooks/useMutation'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import notify from '../../utilities/notify'
import TransactionConfirmation from '../../components/transaction-confirmation'

export default function WastepayRefundPage () {
  const { invoiceId, transactionId } = useParams()
  const history = useHistory()
  const [refundError, setRefundError] = useState(null)
  const [refundData, setRefundData] = useState(null)
  const [refundEmails, setRefundEmails] = useState(null)

  const { hauler, accessToken } = useSelector(({ user }) => ({
    hauler: user.hauler,
    user: user.user,
    accessToken: user.accessToken
  }))

  const { data: invoiceSettingsData, isFetching: isFetchingInvoiceSettings } = useQuery([
    QUERY_KEYS.invoiceSettings,
    hauler.id
  ],
  invoiceSettingsQuery,
  {
    onError: (error) => captureErrorAndNotify(error, 'Error Fetching Invoice Settings')
  })

  const { data: invoiceData, isFetching: isFetchingInvoice } = useQuery(
    [QUERY_KEYS.invoicePaymentDetails, invoiceId],
    invoicePaymentDetailsQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Error fetching invoice details')
      }
    })

  const { data: transactionData, isFetching: isFetchingTransaction } = useQuery(
    [QUERY_KEYS.wastepayTransaction, { transactionId }],
    wastepayTransactionQuery,
    {
      enabled: hauler.isWastepayConnected,
      onError: (error) => captureErrorAndNotify(error, 'Error fetching transactions')
    })
  const transaction = transactionData?.wastepayTransaction

  const pageTitle = useMemo(() => {
    if (isFetchingInvoice) return null

    if (refundData) return `Refund for Invoice #${refundData.invoiceNumber}`
    if (invoiceData?.invoice) return `Issue Refund for Invoice #${invoiceData.invoice.dispatcherInvoiceNumber}`

    return null
  }, [invoiceData?.invoice, isFetchingInvoice, refundData])

  const { mutateAsync: wastepayRefundTransaction, isLoading: isRefunding } = useMutation(wastepayRefundTransactionMutation, {
    onSuccess ({ refund }) {
      setRefundData(refund)
    },
    onError (mutationError) {
      const { response } = mutationError
      const [error] = response.errors
      setRefundError(error.message)
    }
  })

  const { mutate: retryExportRefundToQuickbooks } = useMutation(exportRefundToQuickbooksMutation, {
    onSuccess (data) {
      setRefundData(data.refund)
      notify('success', `Refund for payment ${transactionId} was successfully exported to QuickBooks`)
    },
    onError (error, variables) {
      captureErrorAndNotify(error, `Failed to export refund for payment ${transactionId} to QuickBooks`)
    }
  })

  function handleRetryExport () {
    retryExportRefundToQuickbooks({ invoiceId, transactionId })
  }

  function handleSubmit (data) {
    setRefundError(null)
    const { invoice } = invoiceData
    const input = {
      invoiceNumber: invoice.dispatcherInvoiceNumber,
      transactionId,
      amountToRefund: Number(data.refundAmount)
    }

    if (invoiceSettingsData.invoiceSettings.sendRefundReceipt) {
      const refundEmails = data.email.split(/[\s,]+/)
      input.sendTo = refundEmails.map(email => ({ email }))
      setRefundEmails(refundEmails)
    }

    wastepayRefundTransaction(input)
  }

  function handleCancel () {
    history.goBack()
  }

  return (
    <NormalLayoutContainer showBackLink>
      <PageTitle>
        <div className='dis-page-title-flex'>
          <div>{pageTitle}</div>
          {!refundData && (
            <button
              type='button'
              className='dis-btn dis-btn-lg dis-btn-blank'
              onClick={handleCancel}>
              Cancel
            </button>
          )}
        </div>
      </PageTitle>
      {
        isFetchingInvoice || isFetchingInvoiceSettings || isFetchingTransaction
          ? <Spinner isFetching />
          : (<>
            {refundData
              ? invoiceData && (
                <TransactionConfirmation
                  transactionType='Refund'
                  invoice={invoiceData.invoice}
                  retryQboExport={handleRetryExport}
                  transactions={[
                    {
                      id: refundData.id,
                      transactionType: 'Refund',
                      qboLink: refundData.qbo.link,
                      qboExportStatus: refundData.qbo.exportStatus,
                      amount: refundData.amount,
                      transactionDate: refundData.transactionDate
                    }
                  ]}
                  receiptEmails={invoiceSettingsData.invoiceSettings.sendRefundReceipt ? refundEmails : undefined}
                  transactionQboExportStatus={transaction.qbo.exportStatus}
                  transactionQboUrl={transaction.qbo.link}
                />
              )
              : transaction && invoiceData &&
                <RefundForm
                  hideCheckWastepayConnection={hauler.isWastepayConnected}
                  handleSubmit={handleSubmit}
                  invoiceData={invoiceData}
                  invoiceSettingsData={invoiceSettingsData}
                  isRefunding={isRefunding}
                  transactionId={transaction.id}
                  paymentMethod={`${transaction.cardBrand} ${transaction.cardNumber}`}
                  transactionAmount={Number(transaction?.remainingAmount || 0)}
                  refundError={refundError}
                  accessToken={accessToken}
                />
            }
          </>)
      }
    </NormalLayoutContainer>
  )
}
