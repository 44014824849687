export default async function ticketNotifications ({ dataLoaders, queryKey }) {
  const [, id, page] = queryKey
  const response = await dataLoaders.thumbster.axios.get('/hauler/notifications', {
    params: {
      entity_type: 'Ticket',
      entity_id: id,
      page
    }
  })

  return response.data
}
