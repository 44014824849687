import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import ClientsTableSearchForm from '../../components/clients-table-search-form'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import PageTitle from '../../components/page-title'
import ClientsTable from '../../components/ClientsTable'
import Modal from '../../components/modal/Modal'
import ImportModal from '../../components/import/ImportModal'
import { ObjectParam, StringParam, BooleanParam, useQueryParams } from 'use-query-params'
import { Spinner } from '../shared/Spinner'
import clientsQuery from '../../graphql/queries/clients'
import QUERY_KEYS from '../../graphql/queryKeys'
import useQuery from '../../hooks/useQuery'
import useMutation from '../../hooks/useMutation'
import exportClients from '../../graphql/mutations/exportClients'
import { clientsSortColumnEnumMap } from '../../graphql/enums'
import notify from '../../utilities/notify'

const pageSize = 25

export default function ClientsPage () {
  const [fakeCount, setFakeCount] = useState(null)
  const [clientsExporting, setClientsExporting] = useState(false)
  const { user } = useSelector(({ user }) => ({ user: user.user }))

  const [queryParams, setQueryParams] = useQueryParams({
    sort: ObjectParam,
    generalSearch: StringParam,
    importModalOpen: BooleanParam,
    pager: ObjectParam
  })

  const { data: { clients }, isFetching } = useQuery([
    QUERY_KEYS.clients,
    user.haulerId,
    queryParams.sort,
    queryParams.generalSearch || null,
    queryParams.pager
  ],
  clientsQuery,
  {
    enabled: Boolean(queryParams.sort),
    placeholderData: { clients: undefined }
  })

  function clearSearchForm () {
    setFakeCount(null)
    setQueryParams({}, 'replace')
  }

  function onSearch ({ generalSearch }) {
    setFakeCount(null)
    setQueryParams({ generalSearch })
  }

  // Handle when a user clicks the "Next" or "Previous" buttons to go forward and backward in our search results.
  function onPageRequest (direction, cursor) {
    setQueryParams({ pager: { cursor, direction, pageSize } })

    if (!fakeCount) return
    if (direction === 'before') {
      setFakeCount(prevFakeCount => ({ start: prevFakeCount.start - pageSize, end: prevFakeCount.end - pageSize }))
    }
    if (direction === 'after') {
      setFakeCount(prevFakeCount => ({ start: prevFakeCount.start + pageSize, end: prevFakeCount.end + pageSize }))
    }
  }

  // Handle when a user clicks on the column to sort by it. Clicking the column once should result in a descending sort
  // and clicking it twice should result in an ascending sort.
  function onSortChange (newSortColumn) {
    let newSortDirection = 'desc'
    if (newSortColumn === queryParams.sort.column) {
      newSortDirection = queryParams.sort.direction === 'asc' ? 'desc' : 'asc'
    }

    setFakeCount(null)
    setQueryParams({
      sort: { column: newSortColumn, direction: newSortDirection }
    })
  }

  // Always make sure we have the bare minimum query parameters by setting reasonable defaults if not already set.
  useEffect(function setDefaultQueryParams () {
    const defaultQueryParams = {}
    if (!queryParams.sort) {
      defaultQueryParams.sort = { column: 'customId', direction: 'desc' }
    }
    setQueryParams(defaultQueryParams)
  }, [queryParams, setQueryParams])

  // We are using cursor based pagination which means we don't have the concept of pages, but we can simulate pages
  // in certain scenarios like when the user starts at the beginning and goes forward. If the user starts in the middle
  // of a paginated set then we have no hope in providing this pseudo page based pagination. This is NOT a great solution
  // but should handle making our cursor based pagination match the look and feel of the old pagination as much as possible.
  // Ideally we would have a load more or an infinite scroll type feature instead of pages.
  useEffect(function toggleFakePagination () {
    if (!clients || isFetching || fakeCount !== null) return
    setFakeCount({ start: 1, end: Math.min(pageSize, clients.totalCount) })
  }, [clients, isFetching, fakeCount, setFakeCount])

  // Client export
  const { mutate: exportClientsMutation } = useMutation(exportClients, {
    onSuccess (data) {
      window.alert(`Export Complete!  The export will be emailed to ${data?.userEmail} with a link to download.`)
    },
    onError () {
      notify('error', 'Error exporting tickets')
    },
    onSettled () {
      setClientsExporting(false)
    }
  })

  function handleExportClients (generalSearchValue) {
    if (clientsExporting) {
      return
    }
    setClientsExporting(true)
    return exportClientsMutation({
      haulerId: user.haulerId,
      sortColumn: clientsSortColumnEnumMap[queryParams.sort.column],
      sortDirection: queryParams.sort.direction.toUpperCase(),
      searchTerm: generalSearchValue
    })
  }

  return (
    <>
      <Modal className={styles.modal} isOpen={queryParams.importModalOpen || false}>
        <ImportModal handleClose={() => setQueryParams({ importModalOpen: false })} pluralImportName='accounts' />
      </Modal>

      <NormalLayoutContainer>
        <div className={styles.header}>
          <PageTitle>All Accounts</PageTitle>
          <div className={styles.headerBtns}>
            <button
              className={cn(styles.importAcctsBtn, 'dis-btn dis-btn-lg dis-btn-white')}
              onClick={() => setQueryParams({ importModalOpen: true })}>
              Import Accounts
              <i className='material-icons dis-btn-icon'>publish</i>
            </button>

            <Link className={cn(styles.newAcctBtn, 'dis-btn dis-btn-lg dis-btn-primary')} to='/hauler/clients/new'>
              New Account
              <i className='material-icons dis-btn-icon'>add</i>
            </Link>
          </div>
        </div>

        <div className={cn(styles.searchForm, 'dis-panel')}>
          <div className='dis-panel-body'>
            <ClientsTableSearchForm
              onSubmit={onSearch}
              onClear={clearSearchForm}
              initialValues={{ generalSearch: queryParams.generalSearch }}
              disabled={isFetching}
              handleExportClients={handleExportClients}
            />
          </div>
        </div>

        {isFetching || !queryParams.sort
          ? <Spinner isFetching />
          : (
            <div className='dis-panel'>
              <div className='dis-panel-body'>
                <ClientsTable
                  clients={clients}
                  sort={queryParams.sort}
                  onPageRequest={onPageRequest}
                  onSortChange={onSortChange}
                  fakeCount={fakeCount}
                />
              </div>
            </div>
            )
        }

      </NormalLayoutContainer>
    </>
  )
}
