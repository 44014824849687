import { loader } from 'graphql.macro'

export default async function updateTicketFeesTaxDiscount ({ dataLoaders, variables }) {
  const schema = loader('./updateTicketFeesTaxDiscount.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      haulerId: variables.haulerId,
      deletedTicketFees: variables.deletedTicketFees,
      discountAndTax: variables.discountAndTax,
      newTicketFees: variables.newTicketFees,
      ticketId: variables.ticketId,
      updatedTicketFees: variables.updatedTicketFees
    }
  })
  return response.updateTicketFeesTaxDiscount
}
