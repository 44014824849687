import { loader } from 'graphql.macro'

export default async function wastepayChargeUnsavedCard ({ dataLoaders, variables }) {
  const schema = loader('./wastepayChargeUnsavedCard.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      amount: variables.amount,
      invoiceId: variables.invoiceId,
      description: variables.description,
      sendTo: variables.sendTo,
      addressLine1: variables.addressLine1,
      addressLine2: variables.addressLine2,
      city: variables.city,
      state: variables.state,
      zip: variables.zip,
      countryCode: variables.countryCode,
      cardBrand: variables.cardBrand,
      cardLastFour: variables.cardLastFour,
      expirationMonth: variables.expirationMonth,
      expirationYear: variables.expirationYear,
      cardType: variables.cardType,
      cardToken: variables.cardToken
    }
  })
  return response.wastepayChargeUnsavedCard
}
