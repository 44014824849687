import React from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import ModalStatusBar from '../ModalStatusBar'
import ConfirmPanel from '../confirm-panel'
import { ROUTES } from '../../../routes'
import { FilePreview } from '../file-preview'

export default function ValidatedView ({ importDetails, onConfirm, disabled, pluralImportName, validatedImportRowsTableComponent }) {
  return (
    <>
      <h2 className='text-center my-20'>Import <span className={styles.capitalize}>{pluralImportName}</span></h2>
      <ModalStatusBar farthestStage={3} />
      <ConfirmPanel
        disabled={disabled || importDetails.validRowsCount === 0}
        hasError={importDetails.invalidRowsCount !== 0}
        onConfirm={onConfirm}
        pluralImportName={pluralImportName}
        returnTo={`${ROUTES[pluralImportName]}?importModalOpen=1`}
      />
      <hr />
      <FilePreview
        importDetails={importDetails}
        pluralImportName={pluralImportName}
        validatedImportRowsTableComponent={validatedImportRowsTableComponent}
      />
    </>
  )
}

ValidatedView.propTypes = {
  importDetails: PropTypes.shape({
    id: PropTypes.string.isRequired,
    validRowsCount: PropTypes.number.isRequired,
    invalidRowsCount: PropTypes.number.isRequired
  }).isRequired,
  onConfirm: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  pluralImportName: PropTypes.string.isRequired,
  validatedImportRowsTableComponent: PropTypes.elementType.isRequired
}
