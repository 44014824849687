import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { randomAlphabeticString } from '../../../utilities/randomUtilities'
import { stateToAbbreviation } from '../../../utilities/locationUtilities'

const countryToAbbr = {
  'United States': 'US',
  Canada: 'CA'
}

class AutocompleteGoogleMap extends Component {
  static buildAddressComponents (place) {
    const addressComponents = {}
    place.address_components.map((obj) => {
      const key = obj.types[0]
      const val = obj.long_name
      addressComponents[key] = val
      return null
    })
    return addressComponents
  }

  static buildMap ({ mapId, latitude, longitude, draggable, gestureHandling, hauler }) {
    const lat = latitude || hauler?.latitude
    const lng = longitude || hauler?.longitude
    return new google.maps.Map(document.getElementById(mapId), {
      center: {
        lat: lat || 50.754083,
        lng: lng || -96.943359
      },
      zoom: lat && lng ? 10 : 2,
      mapTypeId: 'roadmap',
      draggable: draggable || false,
      clickableIcons: false,
      gestureHandling: gestureHandling || 'none'
    })
  }

  static buildMarker (map) {
    return new google.maps.Marker({
      map
    })
  }

  static buildAutocomplete (input) {
    return new google.maps.places.Autocomplete(input, {
      componentRestrictions: {
        country: ['us', 'ca']
      }
    })
  }

  static moveMarker (marker, lat, lng) {
    const updatedLatLng = new google.maps.LatLng(lat, lng)
    marker.setPosition(updatedLatLng)
    marker.setVisible(true)
  }

  constructor (props) {
    super(props)

    this.blankIfEmpty = this.blankIfEmpty.bind(this)
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.setState({
      inputId: randomAlphabeticString(10),
      mapId: randomAlphabeticString(10)
    })
  }

  componentDidMount () {
    const { inputId, mapId } = this.state
    const { onLocationChange, externalInput, draggable, gestureHandling, hauler } = this.props
    const latitude = parseFloat(this.props.latitude)
    const longitude = parseFloat(this.props.longitude)

    const map = AutocompleteGoogleMap.buildMap({
      mapId,
      latitude,
      longitude,
      draggable,
      gestureHandling,
      hauler
    })

    const input = document.getElementById(inputId)
    const autocomplete = AutocompleteGoogleMap.buildAutocomplete(input)

    if (!externalInput) {
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(input)
    }

    const marker = AutocompleteGoogleMap.buildMarker(map)

    if (latitude && longitude) {
      AutocompleteGoogleMap.moveMarker(marker, latitude, longitude)
    }

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()
      if (!place.geometry) {
        this.blankLocation()
        return
      }

      // Move Map to New Location
      map.setCenter(place.geometry.location)
      map.setZoom(16)

      // Set the position of the marker using the place ID and location.
      AutocompleteGoogleMap.moveMarker(
        marker,
        place.geometry.location.lat(),
        place.geometry.location.lng()
      )

      const addrComponents = AutocompleteGoogleMap.buildAddressComponents(place)

      // OK, so this is terrible, but there is a reason
      // There is a glitch in the Google Maps database where
      // 2020 Lee St SW, Atlanta, Georgia 30310
      // is not returning a zip code. This has been reported to Google.
      if (place.place_id === 'EiAyMDIwIExlZSBTdCBTVywgQXRsYW50YSwgR0EsIFVTQQ') {
        addrComponents.postal_code = '30310'
      }

      onLocationChange({
        place_id: place.place_id,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        addressline1: `${addrComponents.street_number || ''} ${addrComponents.route || ''}`,
        city: addrComponents.locality ||
          addrComponents.sublocality_level_1 ||
          addrComponents.administrative_area_level_3 ||
          addrComponents.neighborhood ||
          addrComponents.administrative_area_level_2,
        state: stateToAbbreviation(addrComponents.administrative_area_level_1),
        zip: addrComponents.postal_code || addrComponents.postal_code_prefix,
        country: countryToAbbr[addrComponents.country],
        displayAddress: null
      })
    })
  }

  blankLocation () {
    this.props.onLocationChange({
      place_id: null,
      latitude: null,
      longitude: null,
      addressline1: null,
      city: null,
      state: null,
      zip: null,
      country: null
    })
  }

  blankIfEmpty (e) {
    if (!e.target.value || !e.target.value.length) { // if empty
      this.blankLocation()
    }
  }

  render () {
    const placeholder = this.props.placeholder ? ` ${this.props.placeholder}` : ' Enter a Location'
    return (
      <div>
        {this.props.externalInput && (
          <>
            <label className='w-full' htmlFor={this.state.inputId}>
              Address
            </label>
            <div className='mb-12 pr-6 -ml-6'>
              <input
                id={this.state.inputId}
                className='google-controls w-full'
                type='text'
                placeholder=''
                defaultValue={this.props.initialValue}
                onBlur={this.blankIfEmpty}
                autoComplete='off'
              />
            </div>
          </>
        )}
        <div>
          {!this.props.externalInput && <input
            id={this.state.inputId}
            className='google-controls'
            type='text'
            placeholder={placeholder}
            defaultValue={this.props.initialValue}
            onBlur={this.blankIfEmpty}
            autoComplete='off'
                                        />}
          <div className={this.props.mapClass || 'static-google-map'} id={this.state.mapId} />
        </div>
      </div>
    )
  }
}

AutocompleteGoogleMap.propTypes = {
  initialValue: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  onLocationChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  place_id: PropTypes.string,
  externalInput: PropTypes.bool,
  mapClass: PropTypes.string,
  draggable: PropTypes.bool,
  gestureHandling: PropTypes.string,
  hauler: PropTypes.object
}

export default AutocompleteGoogleMap
