import { loader } from 'graphql.macro'

export default async function createDispatcherRefund ({ dataLoaders, variables }) {
  const schema = loader('./createDispatcherRefund.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      amountToRefund: variables.amountToRefund,
      dispatcherPaymentId: variables.dispatcherPaymentId,
      sendTo: variables.sendTo
    }
  })
  return response.createDispatcherRefund
}
