import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'
import { ROUTES } from '../../routes'
import { generatePath, Link } from 'react-router-dom'

export default function InvoiceDetailsButtons ({
  invoice,
  qboInvoicedUnpaid,
  setShowEditing,
  isOldQboReference,
  isWastepayConnected,
  isConnectedToQB,
  invoicer
}) {
  return (
    <>
      {invoice.status === 'PAID' &&
        <div className={cn(styles.invoiceDetailButtonContainer, styles.buttonContainerSolo)}>
          <Link
            type='button'
            className='dis-btn dis-btn-sm dis-btn-primary-dk'
            to={generatePath(ROUTES.invoice, { id: invoice?.id })}>
            View Details
          </Link>
        </div>
      }
      {invoice.status === 'OPEN' && <div>
        {
          (invoicer === 'DISPATCHER' || isWastepayConnected) && (
            <Link
              className={cn('dis-btn dis-btn-sm dis-btn-primary', styles.invoiceDetailsBtn)}
              to={`${generatePath(ROUTES.invoicePayment, { id: invoice?.id })}`}>
              Collect Payment
            </Link>
          )
        }
        {isConnectedToQB && !isWastepayConnected && <Link
          className={cn('dis-btn dis-btn-sm dis-btn-primary', styles.invoiceDetailsBtn)}
          to={{
            pathname: `${generatePath(ROUTES.invoicesExport, { id: invoice.id })}`,
            state: { multiTicket: true }
          }}>
          Open Invoice
        </Link>}
        <button
          className={cn('dis-btn dis-btn-sm dis-btn-gray', styles.invoiceDetailsBtn, styles.invoiceDetailsBtnMarginTop)}
          onClick={() => setShowEditing(true)}>
          Edit Fees
        </button>
      </div>}
      {qboInvoicedUnpaid &&
        <div className={cn({ [styles.invoiceDetailButtonContainer]: !isOldQboReference })}>
          {isOldQboReference && <div className={styles.oldReferenceText}>Linked to prior QBO account</div>}
          <Link
            className={cn('dis-btn dis-btn-sm dis-btn-primary-dk', {
              [styles.invoiceDetailsBtn]: isOldQboReference,
              [styles.invoiceDetailsBtnMarginTop]: isOldQboReference
            })}
            to={generatePath(ROUTES.invoice, { id: invoice?.id })}>
            View Details
          </Link>
          {
            !isOldQboReference && <a
              href={invoice?.qboUrl} className={cn('dis-btn dis-btn-sm dis-btn-gray', styles.qboLink)}
              target='_blank noopener noreferrer'>
              Open in QBO
            </a>
          }
        </div>
      }
    </>
  )
}

InvoiceDetailsButtons.propTypes = {
  invoice: PropTypes.object.isRequired,
  qboInvoicedUnpaid: PropTypes.bool.isRequired,
  setShowEditing: PropTypes.func.isRequired,
  isOldQboReference: PropTypes.bool,
  isWastepayConnected: PropTypes.bool.isRequired,
  isConnectedToQB: PropTypes.bool.isRequired,
  invoicer: PropTypes.string.isRequired
}
