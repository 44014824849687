import React from 'react'
import cn from 'classnames'
import styles from './index.module.scss'
import PropTypes from 'prop-types'

export default function TicketFormUnsavedModal ({
  currentPath,
  form,
  setFormModalOpen,
  valid,
  unblockRef
}) {
  function handleResetForm () {
    if (unblockRef) {
      unblockRef.current()
      /* TO DO: There is an issue with react-router-dom that is disallowing the page to render correctly after unblocking navigation when using history.push and the custom modal */
      window.location.href = currentPath
    }
  }

  function handleCancel () {
    setFormModalOpen(false)
  }

  function handleSubmit () {
    form.batch(() => {
      form.change('afterSubmit', 'navigateToRoute')
      form.change('route', currentPath)
      form.change('pristine', form.getState().pristine)
      form.submit()
    })
  }

  return (
    <div className={cn('dis-panel', styles.formModalBody)}>
      <i className={cn('material-icons-outlined', styles.formModalIcon)}>error_outline</i>
      <div className={styles.formModalText}>You have unsaved changes</div>
      <div className={styles.formModalDetails }>Would you like to save your changes before leaving?</div>
      <div className={styles.formModalButtonContainer}>
        <button
          type='button'
          className='dis-btn dis-btn-lg dis-btn-no-shadow'
          onClick={handleCancel}>
          Cancel
        </button>
        <button
          type='button'
          className='dis-btn dis-btn-primary-lt dis-btn-lg'
          onClick={handleResetForm}>
          Discard
        </button>
        <button
          disabled={!valid}
          type='submit'
          className='dis-btn dis-btn-primary dis-btn-lg'
          onClick={handleSubmit}>
          <span>Save And Continue</span>
        </button>
      </div>
    </div>
  )
}

TicketFormUnsavedModal.propTypes = {
  currentPath: PropTypes.string,
  form: PropTypes.object.isRequired,
  setFormModalOpen: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  unblockRef: PropTypes.object.isRequired
}
