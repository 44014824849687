import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { generatePath, Link } from 'react-router-dom'
import classNames from 'classnames'
import styles from './ticket-sites.module.scss'
import TicketSiteCreateForm from './TicketSiteCreateForm'
import { ROUTES } from '../../../routes'
import { commaDeliminate } from '../../../utilities/stringUtilities'

export default function TicketSitesDisplay ({
  ticketSites,
  ticketSiteCosts,
  sites,
  onSubmit,
  onDestroy,
  disabled = false
}) {
  const nonMatchedSites = useMemo(
    () => sites.filter(site => !ticketSites.some(ticketSite => site.id === ticketSite.id)),
    [sites, ticketSites]
  )

  return (
    <div className='dis-panel'>
      <div className='dis-panel-header'>
        <h5>Associated Sites</h5>
      </div>
      <div className='dis-panel-body'>
        <TicketSiteCreateForm onSubmit={onSubmit} sites={nonMatchedSites} disabled={disabled} />
        {ticketSites.map((ticketSite, index) => {
          const savedSiteCost = ticketSiteCosts.find(ticketSiteCost => ticketSiteCost.siteId === ticketSite.id)
          const hasSiteCost = Boolean(ticketSite.cost)
          return (
            <li key={ticketSite.id} className={styles.siteItemContainer}>
              <div className={classNames(styles.siteItem, { [`${styles.lastSiteItem}`]: index === ticketSites.length - 1 })}>
                <Link to={generatePath(ROUTES.site, { id: ticketSite.id })}>{ticketSite.name}</Link>
                <span className={styles.siteItemName}>- {ticketSite.siteType.name}</span>
                {(hasSiteCost || savedSiteCost) && <span className={styles.siteItemCost}>$
                  {commaDeliminate(savedSiteCost?.cost || ticketSite.cost)}
                </span>}
              </div>
              <button className={styles.removeBtn} type='button' onClick={() => onDestroy({ siteId: ticketSite.id })}>
                <i className={classNames('material-icons', styles.removeIcon)}>close</i>
              </button>
            </li>
          )
        })}
      </div>
    </div>
  )
}

TicketSitesDisplay.propTypes = {
  ticketSites: PropTypes.array.isRequired,
  ticketSiteCosts: PropTypes.array.isRequired,
  sites: PropTypes.array.isRequired,
  onDestroy: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}
