import React, { useRef } from 'react'
import classNames from 'classnames'
import useExpand from './useExpand'
import PropTypes from 'prop-types'
import styles from './tooltip.module.scss'
import classNamePropTypes from '../../../utilities/classNamePropTypes'

export default function Tooltip ({ children, trigger, position, name, className }) {
  const parentRef = useRef()
  const { isOpened, toggle } = useExpand(parentRef)

  return (
    <span ref={parentRef} className={classNames(styles.tooltip, className)} role='tooltip' aria-label={name}>
      <span onMouseEnter={toggle}>{trigger}</span>
      {isOpened && (
        <div className={classNames(styles.wrapper, styles[`top${position}`])}>
          <div className={classNames(styles.arrow, styles[`arrowTop${position}`])} />
          {children}
        </div>
      )}
    </span>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  className: classNamePropTypes,
  trigger: PropTypes.node.isRequired,
  position: PropTypes.oneOf(['Right', 'Left', 'Center']).isRequired,
  name: PropTypes.string.isRequired
}
