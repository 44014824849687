import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import cn from 'classnames'

export function GridHeader ({ additionalClasses, label, infoText }) {
  return (
    <div className={cn('text-2xl font-medium border-b px-2 py-6 flex self-center', additionalClasses)}>
      {label}
      {
        infoText && (
          <>
            <ReactTooltip />
            <i className='material-icons text-xl text-blue opacity-60 ml-1' data-tip={infoText}>
              info
            </i>
          </>
        )
      }
    </div>
  )
}

GridHeader.propTypes = {
  additionalClasses: PropTypes.string,
  infoText: PropTypes.string,
  label: PropTypes.string.isRequired
}
