import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import ConnectToQuickbooksOnlineButton from '../../components/ConnectToQuickbooksOnlineButton'
import { NumberParam, useQueryParams } from 'use-query-params'
import QuickbooksImportForm from '../../components/QuickbooksImportForm'
import useQuery from '../../hooks/useQuery'
import QUERY_KEYS from '../../graphql/queryKeys'
import quickbooksResourcesQuery from '../../graphql/queries/quickbooksResources'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import { Spinner } from '../shared/Spinner'
import { Link, useHistory } from 'react-router-dom'
import { ROUTES } from '../../routes'
import { Form, Field, FormSpy } from 'react-final-form'
import PageTitle from '../../components/page-title'
import useMutation from '../../hooks/useMutation'
import importQuickbooksCustomersMutation from '../../graphql/mutations/importQuickbooksCustomers'
import updateQuickbooksMutation from '../../graphql/mutations/updateQuickbooks'
import notify from '../../utilities/notify'
import QuickbooksImportStarted from '../../components/quickbooks-import-started'
import USER_ACTIONS from '../../store/user/userActions'

export default function QuickbooksSettingsPage () {
  const history = useHistory()
  const dispatch = useDispatch()
  const { hauler, accessToken } = useSelector(({ user }) => ({ hauler: user.hauler, accessToken: user.accessToken }))
  const [queryParams] = useQueryParams({ step: NumberParam })

  const { data: quickbooksResourceData, isLoading: isLoadingQuickbooksResourceData } = useQuery(
    [QUERY_KEYS.quickbooksCustomerTypesAndTaxCodes, { haulerId: hauler.id }],
    quickbooksResourcesQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Failed to retrieve data')
      },
      enabled: hauler.quickbooks.isConnected
    }
  )

  const { mutate: importQuickbooksCustomers, isSuccess: isImportStarted, isLoading: isStartingImport } = useMutation(
    importQuickbooksCustomersMutation,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Failed to start import')
      }
    })

  const { mutate: updateQuickbooks } = useMutation(updateQuickbooksMutation, {
    onSuccess (data) {
      dispatch({
        type: USER_ACTIONS.UPDATE_HAULER,
        payload:
        {
          hauler: {
            quickbooks: data.quickbooks
          }
        }
      })

      notify('success', 'QuickBooks settings saved')
    },
    onError (error) {
      captureErrorAndNotify(error, 'Failed to save QuickBooks settings')
    }
  })

  function handleImport (data) {
    switch (data.importStatus) {
      case 'skip':
        history.push(ROUTES.quickbooksSettings)
        break
      case 'all':
        importQuickbooksCustomers({ haulerId: hauler.id })
        break
      case 'custom':
        importQuickbooksCustomers({ haulerId: hauler.id, ...data })
        break
      default:
        throw new Error(`Unhandled QuickBooks import status - ${data.importStatus}`)
    }
  }

  if (!hauler.quickbooks.isConnected) {
    return (
      <NormalLayoutContainer>
        <PageTitle>QuickBooks Settings</PageTitle>
        <NotConnected accessToken={accessToken} hauler={hauler} />
      </NormalLayoutContainer>
    )
  }

  if (queryParams.step === 2) {
    return (
      <NormalLayoutContainer>
        <PageTitle>QuickBooks Connection Success</PageTitle>
        {isLoadingQuickbooksResourceData || isStartingImport
          ? <Spinner isFetching />
          : isImportStarted
            ? <QuickbooksImportStarted />
            : <StepTwo onImport={handleImport} customerTypes={quickbooksResourceData.quickbooksCustomerTypes} />
        }
      </NormalLayoutContainer>
    )
  }

  return (
    <NormalLayoutContainer>
      <PageTitle>QuickBooks Settings</PageTitle>
      {isLoadingQuickbooksResourceData
        ? <Spinner isFetching />
        : (<Connected
            hauler={hauler}
            onSettingsUpdate={data => updateQuickbooks({ haulerId: hauler.id, ...data })}
            taxCodes={quickbooksResourceData.quickbooksTaxCodes}
           />)
      }
    </NormalLayoutContainer>
  )
}

function Connected ({ hauler, onSettingsUpdate, taxCodes }) {
  return (<>
    <div className='flex justify-between items-center mt-8'>
      <h2 className='text-4xl m-0'>You are connected to QuickBooks Online.</h2>
      <div>
        <Link
          to={ROUTES.invoices}
          className={`uppercase bg-green py-3 px-10 shadow-md rounded text-2xl text-black font-bold hover:bg-green-10-dark
          hover:no-underline hover:text-black whitespace-nowrap`}>
          Go to invoicing
        </Link>
        <Link to={ROUTES.quickbooksDisconnect} className={`uppercase ml-4 bg-white py-3 px-10 rounded shadow-md text-2xl text-black
        hover:bg-white-10-dark hover:no-underline hover:text-black font-bold whitespace-nowrap`}>
          Disconnect QuickBooks
        </Link>
      </div>
    </div>
    <hr />

    <Form onSubmit={onSettingsUpdate} initialValues={hauler.quickbooks}>
      {({ handleSubmit }) => (<>
        <AutoSave save={onSettingsUpdate} />

        <div className={cn('bg-white p-8 rounded border border-gray-light')}>
          <h3 className='text-4xl m-0'>Invoice Settings</h3>
          <p className='text-xl mt-2'>Select the default settings you would like to use when exporting invoices to QuickBooks:</p>
          <form onSubmit={handleSubmit}>
            <div className={cn({ hidden: hauler.country === 'US' })}>
              <label htmlFor='taxCodeId' className='flex flex-col'>
                <span className='text-gray'>Default Tax Rate (Required in Canada)</span>
                <Field id='taxCodeId' name='taxCodeId' component='select' className='py-2 pl-2 pr-40 w-min'>
                  <option value=''>Select One</option>
                  {taxCodes.map(taxCode => <option key={taxCode.id} value={taxCode.id}>{taxCode.name}</option>)}
                </Field>
              </label>
            </div>
            <div className={styles.titledSection}>
              <h4 className={styles.subtitle}>Invoice Numbers</h4>
              <label htmlFor='sendInvoiceNumberToQboTrue' className={styles.radio}>
                <Field id='sendInvoiceNumberToQboTrue'
                  name='sendInvoiceNumberToQbo'
                  component='input'
                  type='radio'
                  value={true}
                  parse={(value) => value === 'true'}
                />
                <span>Send Dispatcher Invoice Number to QuickBooks</span>
              </label>
              <label htmlFor='sendInvoiceNumberToQboFalse' className={styles.radio}>
                <Field id='sendInvoiceNumberToQboFalse'
                  name='sendInvoiceNumberToQbo'
                  component='input'
                  type='radio'
                  value={false}
                  parse={(value) => value === 'true'}
                />
                <span>Use QuickBooks generated Invoice Number</span>
              </label>
            </div>
            <div className='flex mt-4 mb-0 hidden'>
              <Field name='shouldAutoImportCustomers' type='checkbox' component='input' />
              <div className='ml-2'>
                <label className='m-0' htmlFor='customers'>
                  <span className='font-bold text-2xl'>Customers</span> (Recommended)
                </label>
                <p>Automatically export new accounts created in Dispatcher to QuickBooks. Unchecking this will prompt
                  you to export new accounts at the time of invoicing.</p>
              </div>
            </div>
            <div className='flex mt-4 mb-0 hidden'>
              <Field name='shouldAutoFeeTypes' type='checkbox' component='input' />
              <div className='ml-2'>
                <label className='font-bold text-2xl m-0' htmlFor='fee-types'>Fee Types</label>
                <p>Automatically export new fee types created in Dispatcher to QuickBooks as products & services.
                  Unchecking this will prompt you to export new fee types at the time of invoicing.</p>
              </div>
            </div>
          </form>
        </div>
        <div className={styles.settingsSection}>
          <h3 className={styles.settingsSectionTitle}>Integration Settings</h3>
          <div className={styles.settingsSectionSecondaryTitle}>Choose the information you would like to keep updated in realtime:</div>
          <div>
            <Field
              id='shouldAutoImportInvoices'
              name='shouldAutoImportInvoices'
              type='checkbox'
              component='input'
              className={styles.settingsCheckbox}
            />
            <label className={styles.invoiceSettingsLabel} htmlFor='shouldAutoImportInvoices'>
              <span className={styles.invoiceSettingsLabelText}>Paid Invoices</span> (Recommended)
            </label>
          </div>
          <p className={styles.invoiceSettingsMessage}>
            Automatically export invoices to QuickBooks after submitting payment.
            Please note that this will only occur when the invoice is paid in full and applies to all payment methods,
            not just credit card payments. Unchecking this option will prompt you to manually export the invoice after submitting payment.
          </p>
        </div>
      </>)}
    </Form>
    <div className={styles.settingsSection}>
      <h3 className='text-4xl m-0'>Data Import</h3>
      <div className='flex items-center justify-between'>
        <div className={styles.titledSection}>
          <span className={styles.subtitle}>Customers</span>
          <p className='block'>Manually trigger an import of customers from Dispatcher or QuickBooks..</p>
        </div>
        <Link to={ROUTES.quickbooksImport}
          className={`uppercase bg-green-light py-3 px-10 rounded text-black shadow-md mt-8 font-bold hover:bg-green-light-10-dark
          hover:no-underline hover:text-black`}>
          Import from QuickBooks
        </Link>
      </div>
    </div>
  </>
  )
}
Connected.propTypes = {
  hauler: PropTypes.shape({
    country: PropTypes.string,
    isWastepayApproved: PropTypes.bool.isRequired,
    quickbooks: PropTypes.shape({
      shouldAutoImportCustomers: PropTypes.bool.isRequired,
      shouldAutoImportFeeTypes: PropTypes.bool.isRequired,
      shouldAutoImportInvoices: PropTypes.bool.isRequired,
      taxCodeId: PropTypes.string
    }).isRequired
  }).isRequired,
  onSettingsUpdate: PropTypes.func.isRequired,
  taxCodes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired
}

function StepTwo ({ customerTypes, onImport }) {
  return (<>
    <hr />
    <h3 className='text-2xl font-bold mt-8 mb-0'>Step 2 of 2</h3>
    <p className='text-3xl mt-2'>Choose an option below to import all or just some of your customers from
      QuickBooks. We will attempt to match account names between Dispatcher and QuickBooks to prevent duplication.
      If there are duplicate account names already present in Dispatcher, we will link the one created first with
      the same name in QuickBooks.</p>
    <QuickbooksImportForm onSubmit={onImport} customerTypes={customerTypes} />
  </>)
}
StepTwo.propTypes = {
  onImport: PropTypes.func.isRequired,
  customerTypes: PropTypes.array.isRequired
}

function NotConnected ({ accessToken, hauler }) {
  return (<>
    <div className={styles.wrapper}>
      <div className={styles.qbBenefits}>
        <p className='mt-8 mb-0 text-2xl leading-10'>
          The QuickBooks Online integration allows you to keep accounts, tickets, and invoices up-to-date and organized.
        </p>
        <ul className='list-disc ml-16 text-2xl leading-10'>
          <li>Import existing customers into Dispatcher</li>
          <li>Export new customers from Dispatcher into QuickBooks</li>
          <li>Send invoices from Dispatcher to QuickBooks</li>
        </ul>
      </div>

      {hauler.isWastepayEnabled && !hauler.isWastepayApproved && <NotWastepayApproved />}
    </div>
    <hr />

    <h3 className='text-2xl font-bold mt-8 mb-0'>Step 1 of 2</h3>
    <p className='text-3xl mt-2'>Get started by clicking below to connect your QuickBooks Online account.</p>
    <ConnectToQuickbooksOnlineButton accessToken={accessToken} />
  </>)
}
NotConnected.propTypes = {
  hauler: PropTypes.shape({
    isWastepayEnabled: PropTypes.bool.isRequired,
    isWastepayApproved: PropTypes.bool.isRequired
  }).isRequired,
  accessToken: PropTypes.string.isRequired
}

function AutoSave (props) {
  return <FormSpy {...props} subscription={{ values: true }} component={AutoSaveComponent} />
}

function AutoSaveComponent ({ values, save }) {
  const [currentValues, setCurrentValues] = useState(undefined)
  if (currentValues === undefined) {
    setCurrentValues(values)
  }

  useEffect(function onValuesChange () {
    if (
      // There is probably a better way to compare each value, but this is fine.
      values.shouldAutoImportInvoices !== currentValues.shouldAutoImportInvoices ||
      values.taxCodeId !== currentValues.taxCodeId ||
      values.shouldAutoImportCustomers !== currentValues.shouldAutoImportCustomers ||
      values.shouldAutoImportFeeTypes !== currentValues.shouldAutoImportFeeTypes ||
      values.sendInvoiceNumberToQbo !== currentValues.sendInvoiceNumberToQbo
    ) {
      setCurrentValues(values)
      save(values)
    }
  }, [values, save, currentValues, setCurrentValues])

  return null
}
AutoSaveComponent.propTypes = {
  values: PropTypes.object.isRequired,
  save: PropTypes.func.isRequired
}

function NotWastepayApproved () {
  return (
    <div className={styles.wpad}>
      <h6>NEW!</h6>
      <h2 className={styles.ad}>Credit Card Processing</h2>
      <p>With the new WastePay integration, charge a credit card from a ticket!</p>
      <a href='https://www.dispatcher.com/payments-demo' target='_blank' rel='noopener nofollow noreferrer'>Learn More</a>
    </div>
  )
}
