import USER_ACTIONS from './userActions'

const initialState = {
  // Convenience boolean to check if we have a user, useful if you don't care about any of the users details
  isAuthenticated: false,
  // undefined = we are not sure i.e. we haven't done the work to check yet, null = we checked and there is no user
  user: undefined,
  hauler: undefined,
  jwt: undefined,
  accessToken: undefined,
  refreshToken: undefined
}

const userReducer = (state = initialState, action) => {
  const { payload, type } = action
  switch (type) {
    case USER_ACTIONS.SET_USER: {
      if (!payload.user) {
        return { ...state, isAuthenticated: false, user: null, hauler: null }
      }

      return {
        ...state,
        isAuthenticated: true,
        user: parseUserData(payload.user),
        hauler: parseHaulerData(payload.user.hauler)
      }
    }
    case USER_ACTIONS.CLEAR_USER: {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        hauler: null,
        jwt: null,
        accessToken: null,
        refreshToken: null
      }
    }
    case USER_ACTIONS.SET_JWT: {
      return {
        ...state,
        jwt: payload.jwt,
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken
      }
    }
    case USER_ACTIONS.UPDATE_HAULER: {
      if (!state.hauler || !payload.hauler) return state

      return {
        ...state,
        hauler: parseHaulerData({
          ...state.hauler,
          ...payload.hauler,
          quickbooks: { ...state.hauler.quickbooks, ...(payload.hauler.quickbooks || {}) }
        })
      }
    }
    default:
      return state
  }
}

export default userReducer

/**
 * Strip excessive information from a hauler object
 * @param hauler
 * @return object
 */
function parseHaulerData (hauler) {
  return {
    id: hauler.id,
    name: hauler.name,
    prettyPrimaryPhone: hauler.prettyPrimaryPhone,
    placeId: hauler.placeId,
    addressline1: hauler.addressline1,
    addressline2: hauler.addressline2,
    city: hauler.city,
    state: hauler.state,
    zip: hauler.zip,
    country: hauler.country,
    latitude: hauler.latitude,
    longitude: hauler.longitude,
    createdAt: hauler.createdAt,
    metric: hauler.metric,
    proBrokerAccess: hauler.proBrokerAccess,
    proSettings: hauler.proSettings,
    isWastepayEnabled: hauler.isWastepayEnabled,
    isWastepayApproved: hauler.isWastepayApproved,
    showDriversOnly: hauler.showDriversOnly,
    requiredActionsAllowed: hauler.requiredActionsAllowed,
    smsActive: hauler.smsActive,
    smsPhoneNumber: hauler.smsPhoneNumber,
    quickbooks: {
      isConnected: hauler.quickbooks.isConnected,
      realmId: hauler.quickbooks.realmId,
      shouldAutoImportCustomers: hauler.quickbooks.shouldAutoImportCustomers,
      shouldAutoImportFeeTypes: hauler.quickbooks.shouldAutoImportFeeTypes,
      shouldAutoImportInvoices: hauler.quickbooks.shouldAutoImportInvoices,
      taxCodeId: hauler.quickbooks.taxCodeId,
      sendInvoiceNumberToQbo: hauler.quickbooks.sendInvoiceNumberToQbo
    },
    isWastepayConnected: hauler.isWastepayConnected,
    canCreateInvoice: hauler.canCreateInvoice
  }
}

/**
 * Strip excessive information from a user like the user.hauler
 * @param user
 * @return object
 */
function parseUserData (user) {
  return {
    id: user.id,
    email: user.email,
    createdAt: user.createdAt,
    firstName: user.firstName,
    lastName: user.lastName,
    role: user.role,
    primaryPhone: user.primaryPhone,
    prettyPrimaryPhone: user.prettyPrimaryPhone,
    haulerId: user.haulerId,
    latitude: user.latitude,
    longitude: user.longitude,
    trackLocation: user.trackLocation,
    lastLocatedAt: user.lastLocatedAt
  }
}
