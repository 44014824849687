import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Spinner } from '../../pages/shared/Spinner'
import { DECLINE_VALUES } from './constants'

export default function NewRequestDeclineForm ({
  index,
  request,
  declineReason,
  setDeclineReason,
  toggleDeclineForm,
  handleConfirm,
  declineDetails,
  setDeclineDetails,
  toggleDetails
}) {
  const [errorReason, setErrorReason] = useState(false)
  const [errorDetails, setErrorDetails] = useState(false)
  const declineServiceLoading = false
  const needsReasonSelected = (request.tickets[0].standardTicketTypeName.toLowerCase() !== 'pickup')

  function submitResponse (event) {
    event.preventDefault()
    let error = false

    if (needsReasonSelected && declineReason === '') {
      setErrorReason(true)
      error = true
    }

    if (declineDetails === '') {
      setErrorDetails(true)
      error = true
    }

    if (error) {
      return
    }

    handleConfirm(index, request.tickets.map(ticket => ticket.id), false, declineReason, declineDetails)
    toggleDetails(false)
  }

  function handleTextArea (event) {
    const inputValue = event.target.value

    if (inputValue !== '') {
      setErrorDetails(false)
    }
    setDeclineDetails(inputValue)
  }
  return (
    <div className='flex flex-row ml-8'>
      {declineServiceLoading
        ? <Spinner isFetching={true} />
        : <form
            onSubmit={submitResponse}
            className='w-full'>
          <div>
            {/* PICKUP is the only service type that reasons are not required. */}
            {needsReasonSelected &&
              <div className={classNames('form-group mb-6', {
                'has-error': errorReason
              })}>
                <label htmlFor='decline reason' className='text-xl mb-2 control-label'>Select A Reason</label>
                <select
                  name='decline reason'
                  className='w-full outline-none focus:border-green border-2 border-b form-control mb-2'
                  value={declineReason}
                  onChange={(e) => setDeclineReason(e.currentTarget.value)}>
                  <option value=''></option>
                  {DECLINE_VALUES.map((opt, index) =>
                    (
                      <option
                        value={opt.value}
                        key={index}>
                        {opt.label}
                      </option>
                    )
                  )}
                </select>
                {errorReason && <div className='text-red'>Required</div>}
              </div>
            }
            <div className={classNames('form-group', { 'has-error': errorDetails })}>
              <label htmlFor='decline-details'
                className='w-full text-xl control-label'>Please Provide Details</label>
              <textarea name='decline-details'
                className='w-full focus:border-green border-2 border-b outline-none form-control'
                defaultValue={declineDetails}
                onBlur={handleTextArea}
                maxLength='200'
              />
              {errorDetails && <div className='text-red'>Required</div>}
            </div>
          </div>
          <div className='w-full mt-4'>
            <button
              type='submit'
              className='button button_warning mr-4'>
              Submit Response
            </button>
            <button
              type='button'
              className='button button_white'
              onClick={() => toggleDeclineForm(false)}>
              Cancel
            </button>
          </div>
        </form>
      }
    </div>
  )
}

NewRequestDeclineForm.propTypes = {
  index: PropTypes.number.isRequired,
  request: PropTypes.object.isRequired,
  declineReason: PropTypes.string.isRequired,
  setDeclineReason: PropTypes.func.isRequired,
  declineDetails: PropTypes.string.isRequired,
  setDeclineDetails: PropTypes.func.isRequired,
  toggleDeclineForm: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  toggleDetails: PropTypes.func.isRequired
}
