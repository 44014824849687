import { GraphQLClient } from 'graphql-request'

export function accessToken () {
  const auth = window.localStorage.getItem('auth')
  return JSON.parse(auth).access_token
}

export function makeGraphQLClient () {
  return new GraphQLClient(`${process.env.REACT_APP_NEW_API_URL}/graphql`, {
    headers: {
      authorization: `Bearer ${accessToken()}`
    }
  })
}
