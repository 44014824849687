import { loader } from 'graphql.macro'

export default async function invoicePreview ({ dataLoaders, queryKey }) {
  const [, variables] = queryKey
  const schema = loader('./invoicePreview.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(schema, {
    companyName: variables.companyName,
    companyPhone: variables.companyPhone,
    companyAddress: variables.companyAddress
  })
}
