import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../routes'

export default function ForgotPasswordButton () {
  return (
    <div className='text-center mt-10'>
      <Link to={ROUTES.forgotPassword} className='btn btn-link'>
        Forgot Password
      </Link>
    </div>
  )
}
