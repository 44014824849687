export const convertLocationsToList = (locations) => {
  return [locations?.onSite, locations?.onJob, locations?.onUser, locations?.onManualLocation, locations?.noLocation]
}

export const determineDataLabels = (locations) => (
  [
    `On Sites - ${locations.onSite}`,
    `On Jobs - ${locations.onJob}`,
    `On Trucks - ${locations.onUser}`,
    `Manual Location - ${locations.onManualLocation}`,
    `Location Not Set - ${locations.noLocation}`
  ]
)

export const determineData = (dataLabels, locations) => (
  {
    labels: dataLabels,
    datasets: [{
      data: locations,
      backgroundColor: [
        '#0A3936',
        '#33CA80',
        '#D7F7D8',
        '#6A7789'
      ]
    }]
  }
)

export const options = {
  cutoutPercentage: 20,
  legend: {
    position: 'top'
  },
  tooltips: {
    enabled: true
  }
}
