import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import cloudinary from 'cloudinary-core/cloudinary-core-shrinkwrap'
import cn from 'classnames'
import styles from './index.module.scss'

export default function WastepaySettingsImage ({ handleDelete, handleUpload, publicId }) {
  const [cl] = useState(cloudinary.Cloudinary.new({ cloud_name: 'thumbster' }))

  const image = useMemo(() => {
    if (!cl.url) return
    return ({
      downloadUrl: cl.url(publicId, {
        flags: 'attachment',
        crop: 'fit',
        fetch_format: 'jpg',
        quality: 'auto'
      }),
      public_id: publicId,
      src: cl.url(publicId, {
        flags: 'attachment',
        crop: 'fit',
        fetch_format: 'jpg',
        quality: 'auto'
      })
    })
  }, [cl, publicId])

  return (<div className={styles.imageSettingsContainer}>
    <div className={styles.uploadTitle}>Logo</div>
    <div className={styles.uploadDisclaimer}>
      Image should be no larger than 200 x 200 px. SVG or Transparent PNG recommended, JPG also supported.
    </div>
    {publicId && <img
      className={styles.logoImage} src={image.src}
      alt='Wastepay Settings Email Logo'
                 />}
    <button
      className='dis-btn dis-btn-sm dis-btn-gray'
      type='button'
      onClick={handleUpload}>
      {publicId ? 'Change' : 'Upload Logo'}
      <i className='material-icons dis-btn-icon'>file_upload</i>
    </button>
    {publicId && <button
      className={cn('dis-btn dis-btn-sm dis-btn-gray', styles.removeButton)}
      type='button'
      onClick={handleDelete}>
      Remove
      <i className='material-icons dis-btn-icon'>delete</i>
    </button>}
  </div>)
}

WastepaySettingsImage.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired,
  publicId: PropTypes.string
}
