import { loader } from 'graphql.macro'

export default function activitiesQuery ({ dataLoaders, queryKey }) {
  const [, entity, id, key, pager = null] = queryKey

  let graphQLVariables = {
    entity,
    id,
    key
  }
  if (pager) {
    const paginatedVariables = {
      before: pager.direction === 'before' ? pager.cursor : null,
      after: pager.direction === 'after' ? pager.cursor : null,
      first: pager.direction === 'after' ? Number(pager.pageSize) : null,
      last: pager.direction === 'before' ? Number(pager.pageSize) : null
    }
    graphQLVariables = { ...graphQLVariables, ...paginatedVariables }
  }

  const activitiesQuery = loader('./activities.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(activitiesQuery, graphQLVariables)
}
