import { loader } from 'graphql.macro'

export default async function updateQuickbooks ({ dataLoaders, variables }) {
  const schema = loader('./updateQuickbooks.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      haulerId: variables.haulerId,
      shouldAutoImportCustomers: variables.shouldAutoImportCustomers,
      shouldAutoImportFeeTypes: variables.shouldAutoImportFeeTypes,
      shouldAutoImportInvoices: variables.shouldAutoImportInvoices,
      taxCodeId: variables.taxCodeId,
      sendInvoiceNumberToQbo: variables.sendInvoiceNumberToQbo
    }
  })
  return response.updateQuickbooks
}
