import React from 'react'
import styles from './notifications-read-only.module.scss'
import PropTypes from 'prop-types'
import Pagination from '../../pages/shared/Pagination'
import NotificationsReadOnlyListItem from './NotificationsReadOnlyListItem'

export default function NotificationsReadOnlyList (
  {
    notifications,
    title,
    showReceiver,
    pagination,
    onPageRequest,
    includeLink
  }) {
  return (
    <div className='dis-panel'>
      <div className='dis-panel-header'>
        <h5>{title}</h5>
      </div>

      {
        notifications && notifications.length === 0
          ? <div className={styles.noNotifications}>No Notifications for Ticket.</div>
          : notifications.map(({ notification }) => (
            <NotificationsReadOnlyListItem
              key={notification.id}
              notification={notification}
              showReceiver={showReceiver}
              includeLink={includeLink}
            />
          ))
      }

      <div className={styles.panelFooter}>
        <Pagination pagination={pagination} onPageRequest={onPageRequest} />
      </div>
    </div>
  )
}

NotificationsReadOnlyList.propTypes = {
  includeLink: PropTypes.any,
  notifications: PropTypes.array,
  onPageRequest: PropTypes.func,
  pagination: PropTypes.any,
  showReceiver: PropTypes.bool,
  title: PropTypes.string
}
