import React, { useEffect, useMemo } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { ROUTES } from '../routes'
import jwtDecode from 'jwt-decode'
import USER_ACTIONS from '../store/user/userActions'
import notify from '../utilities/notify'
import { useDispatch } from 'react-redux'

export default function ImpersonationPage () {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search])

  useEffect(function onQueryParamChange () {
    dispatch({ type: USER_ACTIONS.CLEAR_USER })
    const accessToken = queryParams.get('jwt')
    if (!accessToken) {
      notify('error', 'Error impersonating')
      return
    }

    window.localStorage.setItem('auth', JSON.stringify({
      access_token: accessToken,
      // No refresh token is given during impersonation
      refresh_token: null
    }))
    const decodedJwt = jwtDecode(accessToken)
    dispatch({
      type: USER_ACTIONS.SET_JWT,
      payload: {
        jwt: decodedJwt,
        accessToken,
        refreshToken: null
      }
    })

    history.push(ROUTES.home)
  }, [queryParams, dispatch, history])

  return (
    <div className='mx-20'>
      <h2>Redirecting...</h2>
      <p>
        If you are not redirected in a few seconds, then you might be trying to impersonate while already logged in.
        Try logging out first
      </p>
      <Link to='/'>Home</Link>
    </div>
  )
}
