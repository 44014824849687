import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import Providers from './Providers'

if (process.env.REACT_APP_ENV === 'production') {
  // Why not, lets have fun
  // eslint-disable-next-line no-console
  console.log(`                                                              
,------.  ,--.                        ,--.        ,--.                    
|  .-.  \\ \`--' ,---.  ,---.  ,--,--.,-'  '-. ,---.|  ,---.  ,---. ,--.--. 
|  |  \\  :,--.(  .-' | .-. |' ,-.  |'-.  .-'| .--'|  .-.  || .-. :|  .--' 
|  '--'  /|  |.-'  \`)| '-' '\\ '-'  |  |  |  \\ \`--.|  | |  |\\   --.|  |    
\`-------' \`--'\`----' |  |-'  \`--\`--'  \`--'   \`---'\`--' \`--' \`----'\`--'    
                     \`--'       
`)

  // TODO we should explore an error tracking solution for elevated environments. We are currently relying on customers
  //  to tell us when an error occurs which is not great. Some options are sentry, data dog, rollbar, initial searching
  //  seems like rollbar would be a good choice. We already use it on the API side and its NPM package is substantially
  //  smaller than its competitors.

  // production builds should not output to the console
  // eslint-disable-next-line no-console
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
