import { loader } from 'graphql.macro'

export default function resourceTypes ({ dataLoaders, queryKey }) {
  const [, { haulerId, status = undefined, includeExtra = false, includeCounts = false }] = queryKey
  const schema = loader('./resourceTypes.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(schema, {
    haulerId,
    status,
    includeExtra,
    includeCounts
  })
}
