import { useMutation as reactQueryUseMutation } from 'react-query'
import { useSelector } from 'react-redux'

/**
 * Custom hook that wraps react-query's useMutation. We do this for a few reasons:
 * * Allows us to pass common custom data to query functions like dataLoaders
 * * Allows us to set reasonable defaults like only make API requests if we have authentication.
 * * Allows us to be reactive to user changes automatically without boilerplate code in every component
 * * Gives us the flexibility to replace react-query in the future without changing 100 files
 *
 * @param {function} mutationFn - Akin to react-query's query functions https://react-query-v3.tanstack.com/guides/mutations
 * @param {Object} [options = {}] - Akin to react-query options https://react-query-v3.tanstack.com/reference/useMutation
 *  with some additional options.
 */
export default function useMutation (mutationFn, options = {}) {
  const { dataLoaders } = useSelector(({ dataLoaders }) => ({ dataLoaders }))

  return reactQueryUseMutation(
    (variables) => mutationFn({
      dataLoaders,
      variables
    }),
    options
  )
}
