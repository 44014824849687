export function formatTransactionMethod (txn) {
  let txnMethod = ''
  switch (txn.paymentMethod) {
    case 'CASH':
      txnMethod = 'Cash'
      break
    case 'CHECK':
      txnMethod = txn.checkNumber ? `Check ${txn.checkNumber}` : ''
      break
    case 'OTHER':
      txnMethod = txn.notes ? `Other: ${txn.notes}` : ''
      break
    default:
      break
  }
  return txnMethod
}

export function formatPaymentsForQBOExportConfirmation (invoice, transaction) {
  const payments = []
  if (invoice?.wastepaySales?.length > 0) {
    payments.push({
      id: invoice?.wastepaySales?.[0]?.transactionId,
      transactionType: 'Payment',
      qboLink: invoice?.wastepaySales?.[0]?.qboPaymentUrl,
      qboExportStatus: invoice?.wastepaySales?.[0]?.qboPaymentExportStatus,
      amount: transaction?.amount,
      transactionDate: transaction?.transactionDate,
      sortDate: transaction?.transactionDate
    })
  }
  invoice?.dispatcherPayments?.forEach(payment => {
    payments.push({
      id: payment.id,
      transactionType: 'Payment',
      qboLink: payment.qboPaymentUrl,
      qboExportStatus: payment.qboPaymentExportStatus,
      amount: payment.paymentAmount,
      transactionDate: payment.paymentDate,
      sortDate: payment.createdAt
    })
  })
  /* sort is necessary to retrieve latest payment for payment and export confirmation views */
  return payments.sort((a, b) => new Date(b.sortDate) - new Date(a.sortDate))
}
