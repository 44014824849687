import React from 'react'
import { generatePath, Link, useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import QUERY_KEYS from '../graphql/queryKeys'
import notify from '../utilities/notify'
import NormalLayoutContainer from './shared/NormalLayoutContainer'
import { PageTitle } from '../components/page-title'
import { Spinner } from './shared/Spinner'
import FormExplanation from './shared/FormExplanation'
import JobEditForm from '../components/job-edit-form'
import useQuery from '../hooks/useQuery'
import useMutation from '../hooks/useMutation'
import jobQuery from '../graphql/queries/job'
import { ROUTES } from '../routes'
import updateJob from '../graphql/mutations/updateJob'
import { formatFormValuesForSubmit } from '../utilities/randomUtilities'

export default function JobEditPage () {
  const { id } = useParams()
  const history = useHistory()
  const { hauler } = useSelector(({ user }) => ({ hauler: user.hauler }))

  const { data: { job }, isFetching: isFetchingJob } = useQuery([QUERY_KEYS.job, id], jobQuery, {
    onError () {
      notify('error', 'Error fetching job')
    },
    initialData: { job: {} }
  })

  const { mutate: updateJobMutation, isLoading: isUpdatingJob } = useMutation(updateJob, {
    onSuccess () {
      notify('success', 'Job Updated Successfully')
      history.push(generatePath(ROUTES.job, { id }))
    },
    onError () {
      notify('error', 'Failed to update job')
    }
  })

  function handleSubmit (values, form) {
    const initialValues = form.getState().initialValues
    const updatedJob = formatFormValuesForSubmit(initialValues, values)

    // For right now, the updateJob.js file has a stipulation if any one of the 3 options are passed in. There is no need to run the extra
    // processing on the api to take in everything and not save it so begone with the silliness
    delete updatedJob.smsActive
    delete updatedJob.smsPrimaryContactActive
    delete updatedJob.smsSecondaryContactActive

    updateJobMutation({
      ...updatedJob,
      latitude: parseFloat(updatedJob.latitude),
      longitude: parseFloat(updatedJob.longitude),
      addressline1: updatedJob.displayAddress || updatedJob.addressline1,
      id
    })
  }

  return (
    <div>
      <NormalLayoutContainer showBackLink>
        <div className='container-fluid'>
          <PageTitle title={`Edit Job ${job?.customId ?? ''}`}>
            <Link
              className='btn btn-link pull-right push-down-super-small'
              to={generatePath(ROUTES.job, { id })}>
              Cancel
            </Link>
          </PageTitle>

          {
            isFetchingJob
              ? <Spinner isFetching />
              : (
                <div className='row'>
                  <div className='col-xs-12 col-sm-12 col-md-7 col-lg-7'>
                    <JobEditForm
                      disabled={isUpdatingJob}
                      onSubmit={handleSubmit}
                      initialValues={job}
                      defaultCoordinates={{ latitude: hauler.latitude, longitude: hauler.longitude }}
                    />
                  </div>
                  <div className='col-xs-12 col-sm-12 col-md-4 col-md-offset-1 col-lg-4 col-lg-offset-1'>
                    <div className='row'>
                      <FormExplanation
                        messages={[
                          'Jobs allow you to create a location where a specific action will take place.',
                          'Jobs can either be open or closed and can be manually edited to reflect the appropriate details of each job.'
                        ]}
                      />
                    </div>
                  </div>
                </div>
                )
          }
        </div>
      </NormalLayoutContainer>
    </div>
  )
}
