import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation, generatePath, Link } from 'react-router-dom'
import { ROUTES } from '../../routes'
import QUERY_KEYS from '../../graphql/queryKeys'
import notify from '../../utilities/notify'
import { TICKET_BILLING_STATUSES } from '../../utilities/constants'
import styles from './index.module.scss'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import PageTitle from '../../components/page-title'
import { Spinner } from '../shared/Spinner'
import FormExplanation from '../shared/FormExplanation'
import NewTicketForm from '../../components/create-ticket/NewTicketForm'
import RecentTickets from '../../components/RecentTickets'
import useQuery from '../../hooks/useQuery'
import useMutation from '../../hooks/useMutation'
import createTicketPageQuery from '../../graphql/queries/pages/createTicketPage'
import createNewTicketMutation from '../../graphql/mutations/createNewTicket'

export default function CreateTicketPage () {
  const [ticketTypeBillingStatusSelected, setTicketTypeBillingStatusSelected] = useState(null)
  const [resourceTypeBillingStatusSelected, setResourceTypeBillingStatusSelected] = useState(null)
  const history = useHistory()
  const location = useLocation()
  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location.search])
  const jobId = queryParams.get('job_id')
  const { user } = useSelector(({ user: { user } }) => ({ user }))

  const { data: pageData, isFetching: isFetchingPageData } = useQuery(
    [QUERY_KEYS.createTicketPage, jobId, user.haulerId],
    createTicketPageQuery
  )

  const { mutate: createTicket, isLoading: isCreatingTicket } = useMutation(createNewTicketMutation, {
    onSuccess ({ ticket }, variables) {
      notify('success', 'Ticket Successfully Created!')
      const navigateTo = variables.navigateToFees
        ? generatePath(ROUTES.ticket, { id: ticket.id })
        : generatePath(ROUTES.job, { id: jobId })
      history.push({
        pathname: navigateTo,
        search: variables.navigateToFees ? `?${new URLSearchParams({ scrollTo: 'fees' }).toString()}` : undefined
      })
    },
    onError () {
      notify('error', 'Unable to update ticket')
    }
  })

  function handleFormSubmit (vals) {
    const { navigateToFees, ...formValues } = vals
    return createTicket({ ticketParams: { ...formValues, jobId }, navigateToFees })
  }

  const isLoading = isFetchingPageData || isCreatingTicket

  return (
    <NormalLayoutContainer>
      <PageTitle>
        <div className={styles.pageTitle}>
          <div>Create a New Ticket</div>
          <Link className='dis-btn dis-btn-blank dis-btn-lg' to={generatePath(ROUTES.job, { id: jobId })}>
            Cancel
          </Link>
        </div>
      </PageTitle>

      {
        isLoading
          ? <Spinner isFetching />
          : (<>
            <div className='dis-form-page-container'>
              <div className='dis-form-container-left'>
                <div className='dis-panel'>
                  <div className='dis-panel-body'>
                    <NewTicketForm
                      onSubmit={handleFormSubmit}
                      resourceTypes={pageData.resourceTypes}
                      ticketTypes={pageData.ticketTypes}
                      billingStatuses={TICKET_BILLING_STATUSES}
                      ticketTypeBillingStatusSelected={ticketTypeBillingStatusSelected}
                      setTicketTypeBillingStatusSelected={setTicketTypeBillingStatusSelected}
                      resourceTypeBillingStatusSelected={resourceTypeBillingStatusSelected}
                      setResourceTypeBillingStatusSelected={setResourceTypeBillingStatusSelected}
                    />
                  </div>
                </div>
              </div>
              <div className='dis-form-container-right'>
                <FormExplanation
                  messages={[
                    'Tickets are the action that takes place on jobs. Here you will select the following:',
                    ' - Date in which the action needs to take place',
                    ' - Type of action needed',
                    " - Asset type that's needed",
                    ' - Add comments to each specific action (if needed)'
                  ]}
                />
              </div>
            </div>

            <div className={styles.recentTickets}>
              {pageData.ticketsSearch.nodes && <RecentTickets tickets={pageData.ticketsSearch.nodes} job={pageData.job} />}
            </div>
          </>)
      }

    </NormalLayoutContainer>
  )
}
