import { loader } from 'graphql.macro'

export default async function updateTicketTypeSms ({ dataLoaders, variables }) {
  const schema = loader('./updateTicketTypeSms.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      ticketTypeId: variables.ticketTypeId,
      steps: variables.steps
    }
  })
  return response.updateTicketTypeSms
}
