import { EmailValidator, PrimaryPhoneValidator } from '../../../../../utilities/commonValidators'

export const validate = (values) => {
  const errors = {}

  /* First Name */
  if (!values.firstName) {
    errors.firstName = 'Required'
  } else if (values.firstName.length > 100) {
    errors.firstName = 'First Name must be less than 100 characters'
  }

  /* Last Name */
  if (!values.lastName) {
    errors.lastName = 'Required'
  } else if (values.lastName.length > 100) {
    errors.lastName = 'Last Name must be less than 100 characters'
  }

  /* Email */
  if (!values.email) {
    errors.email = 'Required'
  } else if (!EmailValidator.test(values.email)) {
    errors.email = 'Invalid email'
  }

  /* Primary Phone */
  if (!values.primaryPhone) {
    errors.primaryPhone = 'Required'
    // eslint-disable-next-line new-cap
  } else if (!PrimaryPhoneValidator(values.primaryPhone)) {
    errors.primaryPhone = 'Invalid Phone Number'
  }

  /* Role */
  if (!values.role) {
    errors.role = 'Required'
  }

  /* Password */
  if (!values.password) {
    errors.password = 'Required'
  } else if (values.password.length < 6) {
    errors.password = 'Password must be at least 6 characters'
  }

  /* Password Confirmation */
  if (!values.passwordConfirmation) {
    errors.passwordConfirmation = 'Required'
  } else if (values.passwordConfirmation !== values.password) {
    errors.passwordConfirmation = 'Passwords do not match'
  }

  return errors
}
