import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import PageTitle from '../../components/page-title'
import QUERY_KEYS from '../../graphql/queryKeys'
import notify from '../../utilities/notify'
import { TICKET_BILLING_STATUSES } from '../../utilities/constants'
import styles from './index.module.scss'
import CreateTypeForm from '../../components/create-type-form'
import CreateResourceTypeExplanation from '../hauler/resource-types/new/components/CreateResourceTypeExplanation'
import ExistingResourceOrTicketTypeList from '../../components/ExistingResourceOrTicketTypeList'
import useMutation from '../../hooks/useMutation'
import useQuery from '../../hooks/useQuery'
import resourceTypesQuery from '../../graphql/queries/resourceTypes'
import createResourceTypeMutation from '../../graphql/mutations/createResourceType'
import { ROUTES } from '../../routes'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'

export default function CreateResourceType () {
  const history = useHistory()
  const [resErrors, setResErrors] = useState({
    name: false,
    shortCode: false
  })
  const { user } = useSelector(({ user: { user } }) => ({ user }))

  const { mutate: createResourceType, isLoading: createResourceTypeLoading } = useMutation(createResourceTypeMutation, {
    onSuccess () {
      notify('success', 'Created new asset type successfully!')
      history.push(ROUTES.resourceTypes)
    },
    onError (error) {
      const messages = error.response?.errors[0]?.message.split(',')
      const checkErrorMessages = (msg) => {
        return messages.findIndex(errMsg => errMsg.toLowerCase().trim() === msg)
      }
      const shortCodeErrorIndex = checkErrorMessages('short code has already been taken')
      const nameErrorIndex = checkErrorMessages('name has already been taken')
      if (shortCodeErrorIndex > -1 || nameErrorIndex > -1) {
        setResErrors({
          name: nameErrorIndex > -1 ? messages[nameErrorIndex] : '',
          shortCode: shortCodeErrorIndex > -1 ? messages[shortCodeErrorIndex] : ''
        })
      } else {
        captureErrorAndNotify(error, 'Unable to create asset type')
      }
    }
  })

  const { data, isFetching } = useQuery(
    [QUERY_KEYS.resourceTypes, { haulerId: user.haulerId }],
    resourceTypesQuery,
    {
      placeholderData: { resourceTypes: [] },
      onError (error) {
        captureErrorAndNotify(error, 'Error retrieving asset types list')
      }
    })

  function handleFormSubmit (data) {
    createResourceType(data)
  }

  return (
    <div>
      <NormalLayoutContainer showBackLink>
        <PageTitle>
          <div className={styles.pageTitle}>
            <div>Create a New Asset Type</div>
            <Link className='dis-btn dis-btn-blank dis-btn-lg' to={ROUTES.resourceTypes}>
              Cancel
            </Link>
          </div>
        </PageTitle>

        <div className='dis-form-page-container'>
          <div className='dis-form-container-left'>
            <CreateTypeForm
              billingStatuses={TICKET_BILLING_STATUSES}
              resErrors={resErrors}
              isFetching={isFetching || createResourceTypeLoading}
              onSubmit={handleFormSubmit}
              type='Asset'
            />
          </div>
          <div className='dis-form-container-right'>
            <CreateResourceTypeExplanation />
            <hr />
            {data && <ExistingResourceOrTicketTypeList listItems={data?.resourceTypes} type='Asset' />}
          </div>

        </div>
      </NormalLayoutContainer>
    </div>
  )
}
