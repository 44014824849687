import React from 'react'
import PropTypes from 'prop-types'

export default function CancelTicketButton ({ onClick, ticket, disabled }) {
  const status = ticket.status
  if (!status) return null
  const notTerminal = status.length > 0 &&
  status !== 'completed' &&
  status !== 'cancelled'
  return notTerminal && (
    <button
      type='button'
      className='dis-btn dis-btn-danger dis-btn-lg'
      onClick={onClick}
      disabled={disabled}>
      Cancel Ticket
      <i className='material-icons dis-btn-icon'>clear</i>
    </button>
  )
}

CancelTicketButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  ticket: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    externalChangeOrderId: PropTypes.string
  }),
  disabled: PropTypes.bool
}
