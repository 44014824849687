import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import QUERY_KEYS from '../../graphql/queryKeys'
import { TICKET_BILLING_STATUSES } from '../../utilities/constants'
import cn from 'classnames'
import styles from './index.module.scss'
import notify from '../../utilities/notify'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import PageTitle from '../../components/page-title'
import { Spinner } from '../shared/Spinner'
import FormExplanation from '../shared/FormExplanation'
import EditResourceTypeForm from '../../components/edit-resource-type/EditResourceTypeForm'
import EditResourceTypeStatus from '../../components/edit-resource-type/EditResourceTypeStatus'
import resourceTypeQuery from '../../graphql/queries/resourceType'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import { ROUTES } from '../../routes'
import updateResourceTypeMutation from '../../graphql/mutations/updateResourceType'
import useMutation from '../../hooks/useMutation'
import useQuery from '../../hooks/useQuery'

export default function EditResourceType () {
  const history = useHistory()
  const { id } = useParams()
  const [resErrors, setResErrors] = useState({
    name: false,
    shortCode: false
  })

  const { user } = useSelector(({ user: { user } }) => ({ user }))

  const { mutate: updateResourceType, isLoading: updateResourceTypeLoading } = useMutation(updateResourceTypeMutation, {
    onSuccess (data, { statusUpdate, status }) {
      notify('success', statusUpdate ? `Asset type successfully ${status}` : 'Asset Type updated successfully')
      history.push(ROUTES.resourceTypes)
    },
    onError (error) {
      const messages = error.response?.errors[0]?.message.split(',')
      const checkErrorMessages = (msg) => {
        return messages.findIndex(errMsg => errMsg.toLowerCase().trim() === msg)
      }
      const shortCodeErrorIndex = checkErrorMessages('short code has already been taken')
      const nameErrorIndex = checkErrorMessages('name has already been taken')
      if (shortCodeErrorIndex > -1 || nameErrorIndex > -1) {
        setResErrors({
          name: nameErrorIndex > -1 ? messages[nameErrorIndex] : '',
          shortCode: shortCodeErrorIndex > -1 ? messages[shortCodeErrorIndex] : ''
        })
      } else {
        captureErrorAndNotify(error, 'Unable to update asset type')
      }
    }
  })

  const { data, isFetching } = useQuery([QUERY_KEYS.resourceType, user.haulerId, id],
    resourceTypeQuery,
    {
      enabled: Boolean(id),
      onError (error) {
        captureErrorAndNotify(error, 'Error retrieving asset type')
      }
    })

  const handleUpdate = ({ id: resourceTypeId, name, shortCode, status, defaultBillingStatus }, statusUpdate = false) => {
    updateResourceType({ resourceTypeId, name, shortCode, status, defaultBillingStatus, statusUpdate })
  }

  return (
    <NormalLayoutContainer showBackLink>
      <PageTitle>
        <div className={styles.pageTitle}>
          <div>Edit Existing Asset Type</div>
          <Link className='dis-btn dis-btn-blank dis-btn-lg' to={ROUTES.resourceTypes}>Cancel</Link>
        </div>
      </PageTitle>

      <Spinner isFetching={isFetching}>
        <div className='dis-form-page-container'>
          <div className='dis-form-container-left'>
            <div className={cn(styles.statusPanel, 'dis-panel')}>
              <div className={styles.statusDisplay}>
                {data && <EditResourceTypeStatus
                  updateResourceType={handleUpdate}
                  resourceType={data.resourceType}
                         />}
              </div>
            </div>

            {data && <EditResourceTypeForm
              initialValues={data?.resourceType}
              billingStatuses={TICKET_BILLING_STATUSES}
              isFetching={isFetching || updateResourceTypeLoading}
              onSubmit={handleUpdate}
              resErrors={resErrors}
                     />}
          </div>
          <div className='dis-form-container-right'>
            <FormExplanation
              messages={[
                'You can edit the visible name of an existing asset type on this page.',
                'Asset Types are meant to be types of rentable items, such as containers and porta potties.',
                `Note that editing the name of a asset type here changes the name of that asset type across the system, including 
                any tickets that ocurred before the name change ocurred.`
              ]}
            />
          </div>
        </div>
      </Spinner>
    </NormalLayoutContainer>
  )
}
