import { loader } from 'graphql.macro'

export default function dispatchResources ({ dataLoaders, queryKey, pageParam = undefined }) {
  const [, variables] = queryKey
  const schema = loader('./dispatchResources.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(schema, {
    haulerId: variables.haulerId,
    after: pageParam
  })
}
