import React from 'react'
import PropTypes from 'prop-types'
import ModalStatusBar from './ModalStatusBar'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../routes'

export default function StageOne ({ farthestStage, setFarthestStage, pluralImportName }) {
  return (
    <>
      <h3 className='p-6 text-center'>{`Import ${pluralImportName.replace(/^./, pluralImportName[0].toUpperCase())}`}</h3>
      <ModalStatusBar farthestStage={farthestStage} />
      <h4 className='text-center my-8'>Download Template</h4>
      <p className='text-center'>
        In order to ensure that your customer information is accurately populated in Dispatcher, please download the
        following template and copy your data into the appropriate columns. When your document is ready, please save
        it as a CSV file.
      </p>
      <div className='flex justify-center my-12'>
        <a className='btn btn-primary mr-16' href={`/${pluralImportName}-import-template.csv`} download>
          Download Import Template
        </a>

        <button className='btn' onClick={() => setFarthestStage(2)}>
          Continue To Upload
        </button>
      </div>
      <div className='text-center'>
        <Link to={ROUTES[`${pluralImportName === 'accounts' ? 'clientImports' : 'resourceImports'}`]}>
          View Previous Imports
        </Link>
      </div>
    </>
  )
}

StageOne.propTypes = {
  farthestStage: PropTypes.number.isRequired,
  setFarthestStage: PropTypes.func.isRequired,
  pluralImportName: PropTypes.string.isRequired
}
