import { loader } from 'graphql.macro'

export default async function updateAsset ({ dataLoaders, variables }) {
  const schema = loader('./updateAsset.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      id: variables.id,
      assetUid: variables.assetUid,
      assetTypeId: variables.assetTypeId,
      placementNote: variables.placementNote,
      location: variables.location
    }
  })
  return response.updateAsset
}
