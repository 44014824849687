import React from 'react'
import NormalLayoutContainer from './shared/NormalLayoutContainer'
import { PageTitle } from '../components/page-title'
import FormExplanation from './shared/FormExplanation'
import UserPasswordChangeForm from '../components/UserPasswordChangeForm'
import { Link, useHistory } from 'react-router-dom'
import { ROUTES } from '../routes'
import changePasswordMutation from '../graphql/mutations/changePassword'
import notify from '../utilities/notify'
import { useSelector } from 'react-redux'
import useMutation from '../hooks/useMutation'

export default function UserChangePasswordPage () {
  const history = useHistory()
  const { user } = useSelector(({ user: { user } }) => ({ user }))
  const { mutate: changePassword, isLoading } = useMutation(changePasswordMutation, {
    onSuccess () {
      notify('success', 'Password successfully updated')
      history.push(ROUTES.userProfile)
    },
    onError () {
      notify('error', 'Failed to change password')
    }
  })

  function handleSubmit (data) {
    changePassword({ userId: user.id, ...data })
  }

  return (
    <div>
      <NormalLayoutContainer showBackLink>
        <div className='container-fluid'>
          <PageTitle title='Change Password'>
            <Link to={ROUTES.userProfile} className='btn btn-link push-down-very-small pull-right'>Cancel</Link>
          </PageTitle>

          <div className='row'>
            <div className='col-xs-12 col-sm-12 col-md-7 col-lg-7'>
              <UserPasswordChangeForm disabled={isLoading} onSubmit={handleSubmit} />
            </div>
            <div className='col-xs-12 col-sm-12 col-md-4 col-md-offset-1 col-lg-4 col-lg-offset-1'>
              <div className='row'>
                <FormExplanation
                  messages={[
                    `Passwords can consist of any letters, numbers, upper and lower case characters as well as any numerical 
                    characters (example *!#@). However, your password must be at least 6 characters long for increased security purposes.`
                  ]}
                />
              </div>
            </div>
          </div>

        </div>
      </NormalLayoutContainer>
    </div>
  )
}

UserChangePasswordPage.propTypes = {}
