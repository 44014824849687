import React from 'react'
import NormalLayoutContainer from './shared/NormalLayoutContainer'
import { Link } from 'react-router-dom'
import ClientImportsTable from '../components/import/ClientImportsTable'
import { ROUTES } from '../routes'
import useQuery from '../hooks/useQuery'
import QUERY_KEYS from '../graphql/queryKeys'
import clientImportsQuery from '../graphql/queries/clientImports'
import { Spinner } from './shared/Spinner'
import { captureErrorAndNotify } from '../utilities/errorHandlers'
import CursorPagination from '../components/cursor-pagination'
import { ObjectParam, useQueryParams } from 'use-query-params'

const pageSize = 50

export default function ClientImportsPage () {
  const [queryParams, setQueryParams] = useQueryParams({ pager: ObjectParam })

  const { data: { clientImports }, isFetching: isFetchingClientImports } = useQuery(
    [QUERY_KEYS.clientImports, {
      first: queryParams.pager?.direction === undefined || queryParams.pager?.direction === 'after' ? pageSize : undefined,
      last: queryParams.pager?.direction === 'before' ? pageSize : undefined,
      after: queryParams.pager?.direction === 'after' ? queryParams.pager?.cursor : undefined,
      before: queryParams.pager?.direction === 'before' ? queryParams.pager?.cursor : undefined
    }],
    clientImportsQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Failed to retrieve data')
      },
      placeholderData: { clientImports: {} }
    })

  function handlePageRequest (direction, cursor) {
    setQueryParams({ pager: { direction, cursor } })
  }

  return (
    <NormalLayoutContainer>
      {isFetchingClientImports
        ? <Spinner isFetching />
        : (<>
          <div className='flex justify-between items-end'>
            <h3 className='mt-20'>Previous Uploads and Imports</h3>
            <Link to={`${ROUTES.clients}?importModalOpen=1`}>
              <button className='btn btn-lg btn-primary'>
                <span className='hidden-xs pull-left'>New Import</span>
                <i className='material-icons pull-right thumbster-button-icon no-margin-bs-xs'>publish</i>
              </button>
            </Link>
          </div>

          <div className='panel panel-default row mt-12'>
            <div className='panel-body'>
              <ClientImportsTable clientImports={clientImports.nodes} />
              <CursorPagination
                onPageRequest={handlePageRequest}
                pageInfo={clientImports.pageInfo}
                totalCount={clientImports.totalCount}
              />
            </div>
          </div>
        </>)
      }
    </NormalLayoutContainer>
  )
}
