import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Pagination from '../../../../../shared/Pagination'
import ActivityIcon from '../../../../../shared/ActivityIcon'

export const ResourceActivitiesDisplay = (props) => {
  const { activities, pagination, onPageRequest } = props

  return (
    <div className='row panel panel-default'>
      <div className='panel-heading'>
        <div className='container-fluid'>
          <div>
            <h4 className='pull-left'>Asset Activities</h4>
          </div>
        </div>
      </div>
      <div className='panel-body'>
        <div className='list-group'>

          {(() => activities.map((activityWrapper) => {
            const activity = activityWrapper.activity
            const user = activity.user
            const comment = activity.key === 'ticket.add_comment'
            const listItemClasses = `list-group-item ${comment ? 'highlight-bg' : ''}`
            return (
              <li className={listItemClasses} key={activity.id}>
                <div className='pull-left font-size-1-25em'>
                  {comment ? `Comment: ${activity.message}` : activity.message}
                </div>
                <ActivityIcon iconName={activity.key} />
                <div className='clearfix' />

                <h6 className='pull-right'>
                  {moment(activity.created_at).format('dddd, MMMM Do YYYY, h:mm:ss a')}
                </h6>
                <h6 className='text-left'>
                  <em>
                    By
                    <Link to={`/hauler/team/${user.id}`}>
                      {` ${user.first_name} ${user.last_name}`}
                    </Link>
                  </em>
                </h6>
              </li>
            )
          }))()}
        </div>
      </div>
      <div className='panel-footer'>
        <Pagination pagination={pagination} onPageRequest={onPageRequest} />
      </div>
    </div>
  )
}

ResourceActivitiesDisplay.propTypes = {
  activities: PropTypes.array.isRequired,
  onPageRequest: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired
}
