import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import ReactSelect from 'react-select'

export default function Select ({
  className,
  options,
  isMulti,
  onChange,
  styles,
  components,
  isClearable,
  removeSelected,
  defaultValue,
  placeholder,
  isDisabled = false,
  closeMenuOnSelect = false,
  isSearchable = false
}) {
  return (
    <ReactSelect
      className={cn(className)}
      styles={styles}
      isMulti={isMulti}
      components={components}
      options={options}
      onChange={onChange}
      isClearable={isClearable}
      hideSelectedOptions={removeSelected}
      value={defaultValue}
      isDisabled={isDisabled}
      closeMenuOnSelect={closeMenuOnSelect}
      isSearchable={isSearchable}
      placeholder={`${placeholder}`}
    />
  )
}

Select.defaultProps = {
  styles: {
    control: (provided) => ({
      ...provided,
      borderTop: 'none',
      borderRight: 'none',
      borderLeft: 'none',
      backgroundColor: 'transparent'
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      '&:hover': {
        'background-color': '#F93B59',
        color: '#FFF'
      }
    })
  },
  isMulti: false,
  placholder: '',
  components: null,
  isClearable: true,
  removeSelected: true
}

Select.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  styles: PropTypes.object,
  components: PropTypes.object,
  isClearable: PropTypes.bool,
  removeSelected: PropTypes.bool,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.any,
  isDisabled: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  isSearchable: PropTypes.bool
}
