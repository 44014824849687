import React from 'react'
import PropTypes from 'prop-types'
import fromPairs from 'lodash/fromPairs'
import map from 'lodash/map'
import { Form, Field } from 'react-final-form'
import { SelectInput } from '../SelectInput'

const validate = (values) => {
  const errors = {}

  if (!values.driverId) {
    errors.driverId = 'Required'
  }

  return errors
}

export default function TicketAssignmentForm ({
  initialValues,
  isFetching,
  onSubmit,
  users
}) {
  const userSelectOptions = () => {
    let options = map(users, u => [u.id, `${u.firstName} ${u.lastName}`])
    options = options.sort()
    options = fromPairs(options)
    options.null = '- Unassigned -'
    return options
  }

  return (
    <div className='row panel panel-default push-down-super-small'>
      <div className='container-fluid push-down-super-small'>
        <div className='col-xs-12'>
          <Form onSubmit={onSubmit} validate={validate} initialValues={initialValues}>
            {({ handleSubmit, pristine, invalid }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name='driverId'
                  label='Assigned Driver'
                  alphabetize
                  options={userSelectOptions()}
                  component={SelectInput}
                />
                <button
                  type='submit'
                  className='btn btn-lg btn-success push-down-super-small pull-right'
                  disabled={pristine || invalid || isFetching}>
                  <span className='pull-left'>Save Changes</span>
                  <i className='material-icons pull-right thumbster-button-icon'>check</i>
                </button>
              </form>
            )}
          </Form>
        </div>
      </div>
      <br />
    </div>
  )
}

TicketAssignmentForm.propTypes = {
  initialValues: PropTypes.shape({
    driverId: PropTypes.number
  }),
  isFetching: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired
}
