import {
  REQUEST_CURRENT_TICKET_ACTION,
  RECEIVE_CURRENT_TICKET_SUCCESS,
  RECEIVE_CURRENT_TICKET_FAILURE,
  REQUEST_RESCHEDULE_CURRENT_TICKET_ACTION,
  RECEIVE_RESCHEDULE_CURRENT_TICKET_SUCCESS,
  RECEIVE_RESCHEDULE_CURRENT_TICKET_FAILURE
} from '../sagas/currentTicketSaga'

import { RECEIVE_TICKET_ASSIGNMENT_SUCCESS } from '../sagas/ticketAssignmentSaga'

import {
  NEW_PUSHER_TICKET_DATA,
  NEW_PUSHER_JOB_DATA,
  NEW_PUSHER_CLIENT_DATA
} from '../../../../store/pusher/pusherActions'

const initialState = {
  isFetching: false,
  newData: false,
  errors: [],
  ticket: {
    client: {},
    invoice: {},
    job: {},
    resource_type: {},
    status: '',
    ticket_images: [],
    ticket_type: {},
    user: {}
  }
}

export default function currentTicketReducer (reduxState = initialState, action) {
  const { payload, type } = action

  const clientUpdated = action.newClientData && (action.newClientData.client.id === reduxState.ticket.client.id)
  const jobUpdated = action.newJobData && (action.newJobData.job.id === reduxState.ticket.job_id)
  const ticketUpdated = action.newTicketData && (action.newTicketData.ticket.id === reduxState.ticket.id)

  switch (type) {
    case NEW_PUSHER_CLIENT_DATA:
      return {
        ...reduxState,
        newData: clientUpdated
      }
    case NEW_PUSHER_JOB_DATA:
      return {
        ...reduxState,
        newData: jobUpdated
      }
    case NEW_PUSHER_TICKET_DATA:
      return {
        ...reduxState,
        newData: ticketUpdated
      }
    case REQUEST_CURRENT_TICKET_ACTION:
      return {
        ...reduxState,
        isFetching: true,
        errors: []
      }
    case RECEIVE_TICKET_ASSIGNMENT_SUCCESS:
    case RECEIVE_CURRENT_TICKET_SUCCESS:
      return {
        ...reduxState,
        isFetching: false,
        ticket: payload.ticket
      }
    case RECEIVE_CURRENT_TICKET_FAILURE:
      return {
        ...reduxState,
        isFetching: false,
        errors: payload.errors
      }
    case REQUEST_RESCHEDULE_CURRENT_TICKET_ACTION:
      return {
        ...reduxState,
        isFetching: true,
        errors: []
      }
    case RECEIVE_RESCHEDULE_CURRENT_TICKET_SUCCESS:
      return {
        ...reduxState,
        isFetching: false,
        errors: []
      }
    case RECEIVE_RESCHEDULE_CURRENT_TICKET_FAILURE:
      return {
        ...reduxState,
        isFetching: false,
        errors: payload.errors
      }
    default:
      return reduxState
  }
}
