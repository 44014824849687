
import {
  REQUEST_SITE_TYPES_ACTION,
  RECEIVE_SITE_TYPES_SUCCESS,
  RECEIVE_SITE_TYPES_FAILURE,
  CLEAR_SITE_TYPES_ERRORS
} from '../sagas/siteTypesSaga'

export const initialState = {
  isFetching: false,
  errors: [],
  siteTypesArray: []
}

const siteTypesReducer = (reduxState = initialState, action) => {
  const { payload, type } = action
  switch (type) {
    case REQUEST_SITE_TYPES_ACTION:
      return {
        ...reduxState,
        isFetching: true,
        errors: []
      }

    case RECEIVE_SITE_TYPES_SUCCESS:
      return {
        ...reduxState,
        isFetching: false,
        siteTypesArray: payload
      }

    case RECEIVE_SITE_TYPES_FAILURE:
      return {
        ...reduxState,
        isFetching: false,
        errors: payload.errors
      }

    case CLEAR_SITE_TYPES_ERRORS:
      return {
        ...reduxState,
        isFetching: false,
        errors: []
      }

    default:
      return reduxState
  }
}

export default siteTypesReducer
