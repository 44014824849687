import React from 'react'
import PropTypes from 'prop-types'
import styles from './ticket-details.module.scss'
import cn from 'classnames'

export default function ExceptionMessageDisplay ({
  id,
  exceptionMessage,
  hasActiveException,
  lastClearedExceptionAt,
  onClearException,
  onRestoreException
}) {
  return (
    <div className={cn('dis-panel', styles.detailsContainer)}>
      <i className={cn('material-icons', hasActiveException && 'text-red')}>error</i>
      <div className={styles.exceptionBody}>
        <div className={styles.exceptionTitle}>Completed With Exception</div>
        <div>
          {lastClearedExceptionAt && <div>Exception Cleared</div>}
          {exceptionMessage || 'None'}
        </div>
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        role='button'
        tabIndex={0}
        className={styles.exceptionButton}
        onClick={hasActiveException ? () => onClearException(id) : () => onRestoreException(id)}>
        {hasActiveException ? 'Clear Exception Status' : 'Restore Exception Status'}
      </div>
    </div>
  )
}

ExceptionMessageDisplay.propTypes = {
  id: PropTypes.string.isRequired,
  exceptionMessage: PropTypes.string,
  hasActiveException: PropTypes.bool,
  lastClearedExceptionAt: PropTypes.string,
  onClearException: PropTypes.func.isRequired,
  onRestoreException: PropTypes.func.isRequired
}
