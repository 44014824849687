import React, { useState } from 'react'
import PropTypes from 'prop-types'

/**
 * I want you to know that I know this component is god awful, replacing the image src to have a slightly darker button is NOT
 * how we should handle styling changes that should be done with CSS. This component is leftover from Thumbster and just hasn't been
 * refactored outside of moving to a functional component.
 */
export default function ConnectToQuickbooksOnlineButton ({ accessToken }) {
  const [imageSrc, setImageSrc] = useState('/quickbooks/C2QB_green_btn_lg_default.png')

  function handleActive () {
    setImageSrc('/quickbooks/C2QB_green_btn_lg_hover.png')
  }

  function handleInactive () {
    setImageSrc('/quickbooks/C2QB_green_btn_lg_default.png')
  }

  return (
    <a
      href={`${process.env.REACT_APP_NEW_API_URL}/quickbooks/oauth2/connect?authorization=${accessToken}`}
      onMouseOver={handleActive} onFocus={handleActive}
      onMouseOut={handleInactive} onBlur={handleInactive}>
      <img
        alt='Connect To Quickbooks Logo'
        src={imageSrc}
        width='224'
        height='40'
      />
    </a>
  )
}

ConnectToQuickbooksOnlineButton.propTypes = {
  accessToken: PropTypes.string.isRequired
}
