import {
  REQUEST_TICKET_ASSIGNMENT_ACTION,
  RECEIVE_TICKET_ASSIGNMENT_SUCCESS,
  RECEIVE_TICKET_ASSIGNMENT_FAILURE,
  CLEAR_TICKET_ASSIGNMENT_ERRORS
} from '../sagas/ticketAssignmentSaga'

const initialState = {
  isFetching: false,
  errors: []
}

const ticketAssignmentReducer = (reduxState = initialState, action) => {
  const { payload, type } = action
  switch (type) {
    case REQUEST_TICKET_ASSIGNMENT_ACTION:
      return {
        ...reduxState,
        isFetching: true,
        errors: []
      }
    case RECEIVE_TICKET_ASSIGNMENT_SUCCESS:
      return {
        ...reduxState,
        isFetching: false,
        errors: []
      }
    case RECEIVE_TICKET_ASSIGNMENT_FAILURE:
      return {
        ...reduxState,
        isFetching: false,
        errors: payload.errors
      }
    case CLEAR_TICKET_ASSIGNMENT_ERRORS:
      return {
        ...reduxState,
        isFetching: false,
        errors: []
      }
    default:
      return reduxState
  }
}

export default ticketAssignmentReducer
