export function validate (values) {
  const errors = {}

  if (!values.streetAddress) {
    errors.streetAddress = 'Required'
  }

  if (!values.city) {
    errors.city = 'Required'
  }

  if (!values.state) {
    errors.state = 'Required'
  }

  if (!values.zip) {
    errors.zip = 'Required'
  }

  return errors
}
