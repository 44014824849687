import { loader } from 'graphql.macro'

export default async function clearExceptionFromTicket ({ dataLoaders, variables }) {
  const schema = loader('./clearExceptionFromTicket.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      ticketId: variables.ticketId
    }
  })
  return response.clearExceptionFromTicket
}
