import moment from 'moment'

export const baseCounts = {
  open: 0,
  en_route: 0,
  completed: 0,
  cancelled: 0
}

export const options = {
  maintainAspectRatio: true,
  tooltips: {
    mode: 'y-axis'
  },
  scales: {
    yAxes: [
      {
        stacked: true
      }
    ],
    xAxes: [
      {
        ticks: { stepSize: 1, min: 0 },
        stacked: true
      }
    ]
  }
}

export const handleTicketsByResourceType = (tickets) => (
  tickets.reduce((acc, ticket) => {
    const resourceType = ticket.resource_type.name
    if (!acc[resourceType]) {
      acc[resourceType] = {
        ...baseCounts,
        resource_type: ticket.resource_type
      }
    }
    acc[resourceType][ticket.status]++
    return acc
  }, {})
)

export const determineData = (keys, ticketsByResourceType) => (
  {
    labels: keys,
    datasets: [
      {
        label: 'Open',
        data: keys.map((key) => ticketsByResourceType[key].open),
        backgroundColor: '#33ca80',
        borderColor: '#33ca80',
        borderWidth: 0
      },
      {
        label: 'En Route',
        data: keys.map((key) => ticketsByResourceType[key].en_route),
        backgroundColor: '#FF8F00',
        borderColor: '#FF8F00',
        borderWidth: 0
      },
      {
        label: 'Completed',
        data: keys.map((key) => ticketsByResourceType[key].completed),
        backgroundColor: '#131514',
        borderColor: '#131514',
        borderWidth: 0
      },
      {
        label: 'Cancelled',
        data: keys.map((key) => ticketsByResourceType[key].cancelled),
        backgroundColor: '#f93b59',
        borderColor: '#f93b59',
        borderWidth: 0
      }
    ]
  }
)

export const getElementClick = (element, history, ticketsByResourceType) => {
  if (element[0]) {
    const key = Object.keys(ticketsByResourceType)[element[0]._index]
    const resourceTypeId = ticketsByResourceType[key].resource_type.id
    const today = moment().format('YYYY-MM-DD')
    history.push(`/hauler/tickets/search?resourceTypeId=${resourceTypeId}&minDate=${today}&maxDate=${today}`)
  }
}
