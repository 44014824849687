import { loader } from 'graphql.macro'

export default async function exportTickets ({ dataLoaders, variables }) {
  const schema = loader('./exportTickets.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      haulerId: variables.haulerId,
      searchFields: variables.searchFields,
      sortColumn: variables.sortColumn,
      sortDirection: variables.sortDirection
    }
  })
  return response.exportTicketsSearch
}
