import React from 'react'
import { Form, Field } from 'react-final-form'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'
import { enumToTitleCase } from '../../utilities/stringUtilities'

const required = value => (value ? undefined : 'Required')

export default function CreateTypeForm ({
  initialValues = {
    name: null,
    shortCode: null,
    defaultBillingStatus: null
  },
  onSubmit,
  isFetching = false,
  resErrors,
  billingStatuses,
  type
}) {
  return (
    <div className='dis-panel'>
      <div className='dis-panel-body'>
        <Form onSubmit={onSubmit} initialValues={initialValues}>
          {({ handleSubmit, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <div className={cn('font-size-1-5em', styles.inputSectionTitle)}>Name and Short Code</div>
              <div className={styles.nameShortCode}>
                <Field name='name' validate={required}>
                  {({ input, meta }) => (
                    <div className={cn(styles.textInput, { 'has-error': (meta.error && meta.touched) || resErrors.name })}>
                      <label className='control-label' htmlFor='name'>{`${type} Type Name`}</label>
                      <input
                        {...input}
                        type='text'
                        className='form-control'
                        maxLength='15'
                      />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                      {!meta.dirtySinceLastSubmit && Boolean(resErrors.name.length) && <h6>{resErrors.name}</h6> }
                    </div>
                  )}
                </Field>
                <Field name='shortCode' validate={required}>
                  {({ input, meta }) => (
                    <div className={cn(styles.textInput, { 'has-error': (meta.error && meta.touched) || resErrors.shortCode.length })}>
                      <label className='control-label' htmlFor='short code'>{`${type} Type Short Code`}</label>
                      <input
                        {...input}
                        type='text'
                        className='form-control'
                        maxLength='2'
                      />
                      {meta.error && meta.touched && <h6>{meta.error}</h6>}
                      {!meta.dirtySinceLastSubmit && Boolean(resErrors.shortCode.length) && <h6>{resErrors.shortCode}</h6>}
                    </div>
                  )}
                </Field>
              </div>

              <div className={cn('font-size-1-5em', styles.inputSectionTitle)}>Settings</div>

              <Field name='defaultBillingStatus'>
                {({ input }) => (
                  <>
                    <label htmlFor='billing status' className='control-label'>Default Billing Status</label>
                    <select
                      {...input}
                      type='select'
                      className='form-control'>
                      <option key='placeholder' value=''></option>
                      {Object.keys(billingStatuses).map((opt) =>
                        (
                          <option
                            value={opt}
                            key={opt}>
                            {enumToTitleCase(opt)}
                          </option>
                        )
                      )}
                    </select>
                  </>
                )}
              </Field>
              <div className={styles.buttonContainer}>
                <button
                  type='submit'
                  className='dis-btn dis-btn-primary dis-btn-lg'
                  disabled={isFetching || pristine || !values.name || !values.shortCode}>
                  {`Save New ${type} Type`}
                  <i className='material-icons dis-btn-icon'>add_circle</i>
                </button>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  )
}

CreateTypeForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  resErrors: PropTypes.object,
  billingStatuses: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
}
