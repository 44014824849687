import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { TextInput } from '../../../../../components/TextInput'
import normalizePhone from '../../../../shared/normalizers/normalizePhone'
import { validate } from './CreateHaulerTeamMemberFormUtils'
import { SelectInput } from '../../../../../components/SelectInput'
import { RadioInput } from '../../../../../components/RadioInput'

function CreateHaulerTeamMemberForm ({ invalid, isFetching, onSubmit, pristine }) {
  return (
    <div className='row panel panel-default'>
      <div className='container-fluid push-down-super-small'>
        <form onSubmit={onSubmit}>
          <div className='row'>
            <div className='col-xs-12'>
              <Field
                name='firstName'
                label='First Name'
                component={TextInput}
                maxLength='20'
              />

              <Field
                name='lastName'
                label='Last Name'
                component={TextInput}
                maxLength='20'
              />
              <Field
                name='email'
                type='email'
                label='Email'
                component={TextInput}
                maxLength='64'
              />
              <Field
                name='primaryPhone'
                label='Primary Phone Number'
                component={TextInput}
                normalize={normalizePhone}
              />
              <Field
                name='role'
                label='Role'
                component={SelectInput}
                options={{ hauler_admin: 'Admin', hauler_driver: 'Driver' }}
              />
              <div className='w-full'> Show User Icon on Dispatch Map?</div>
              <Field
                classNames='mr-8'
                name='trackLocation'
                radioVal={true}
                label='Yes'
                component={RadioInput}
              />
              <Field
                name='trackLocation'
                radioVal={false}
                label='No'
                component={RadioInput}
              />
              <Field
                name='password'
                autoComplete='new-password'
                type='password'
                label='Password'
                component={TextInput}
                maxLength='40'
              />
              <Field
                name='passwordConfirmation'
                type='password'
                autoComplete='new-password'
                label='Confirm Password'
                component={TextInput}
                maxLength='40'
              />
              <button
                type='submit'
                className='btn btn-primary push-down-very-small pull-right'
                disabled={pristine || invalid || isFetching}>
                <span className='pull-left'>Save New Team Member</span>
                <i className='material-icons pull-right thumbster-button-icon'>check</i>
              </button>
            </div>
          </div>
        </form>
        <br />
      </div>
    </div>
  )
}

CreateHaulerTeamMemberForm.propTypes = {
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool
}

export default reduxForm({
  form: 'createHaulerTeamMemberForm',
  validate,
  initialValues: { trackLocation: true }
})(CreateHaulerTeamMemberForm)
