import React from 'react'
import PropTypes from 'prop-types'
import styles from './edit-resource-type.module.scss'
import ResourceTypeStatusLabel from '../../pages/shared/ResourceTypeStatusLabel'
import ReactTooltip from 'react-tooltip'

export default function EditResourceTypeStatus ({
  updateResourceType,
  resourceType
}) {
  function handleSaveChanges (status) {
    updateResourceType({
      name: resourceType.name,
      shortCode: resourceType.shortCode,
      status,
      id: resourceType.id,
      defaultBillingStatus: resourceType.defaultBillingStatus
    }, true)
  }

  function disableMessaging () {
    let message = ''
    if (resourceType.ticketsCount > 0) {
      message = "Can't disable with attached tickets. "
    }
    if (resourceType.standardResourceTypeId) {
      message += 'This asset type is being used by the broker integration and cannot be disabled.'
    }
    return message
  }

  return (
    <>
      <ReactTooltip />
      <div className={styles.statusContainer}>
        <div>
          <div className={styles.currentStatus}>Current Status:</div>
          <ResourceTypeStatusLabel
            status={resourceType.status}
          />
        </div>
        {(() => {
          if (resourceType.status === 'enabled') {
            const disallowDisable = resourceType.ticketsCount > 0 || resourceType.standardResourceTypeId
            return (<div
              data-tip={disallowDisable ? disableMessaging() : null}>
              <button
                className='dis-btn dis-btn-danger dis-btn-sm'
                onClick={() => handleSaveChanges('disabled')}
                disabled={disallowDisable}>
                <strong>Disable</strong>
              </button>
            </div>)
          } else if (resourceType.status === 'disabled') {
            return (<button
              className='dis-btn dis-btn-success dis-btn-sm'
              onClick={() => handleSaveChanges('enabled')}>
              <strong>Enable</strong>
            </button>)
          }
          return null
        })()}
      </div>
    </>
  )
}

EditResourceTypeStatus.propTypes = {
  updateResourceType: PropTypes.func.isRequired,
  resourceType: PropTypes.object.isRequired
}
