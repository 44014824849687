import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import styles from './index.module.scss'
import dayjs from '../../utilities/dayjs'
import DefaultBillingStatusInputs from '../default-billing-status-inputs'
import { TextareaInput } from '../TextareaInput'
import { DatePickerInput } from '../DatePickerInput'
import { TimeInput } from '../TimeInput'

export default function NewTicketForm ({
  onSubmit,
  resourceTypes,
  ticketTypes,
  billingStatuses,
  ticketTypeBillingStatusSelected,
  setTicketTypeBillingStatusSelected,
  resourceTypeBillingStatusSelected,
  setResourceTypeBillingStatusSelected,
  disabled = false
}) {
  return (
    <Form validate={validate} onSubmit={onSubmit}>
      {({ handleSubmit, pristine, form, invalid, values }) => {
        const formState = form.getState()
        const billingStatusManuallyUpdated = formState.dirtyFields?.billingStatus && formState.visited?.billingStatus
        return (
          <form onSubmit={handleSubmit} autoComplete='off'>
            <DefaultBillingStatusInputs
              billingStatuses={billingStatuses}
              billingStatusManuallyUpdated={billingStatusManuallyUpdated}
              resourceTypeBillingStatusSelected={resourceTypeBillingStatusSelected}
              resourceTypes={resourceTypes}
              setResourceTypeBillingStatusSelected={setResourceTypeBillingStatusSelected}
              setTicketTypeBillingStatusSelected={setTicketTypeBillingStatusSelected}
              ticketTypeBillingStatusSelected={ticketTypeBillingStatusSelected}
              ticketTypes={ticketTypes}
              values={values}
            />

            <Field
              name='date'
              label='Ticket Date'
              component={DatePickerInput}
            />

            <Field
              component={TextareaInput}
              label='Comment (Optional)'
              name='comment'
              placeholder='(Comments are for each individual ticket and can be used for placement details, messages for drivers, etc.
              Drivers can view comments on the mobile app.)'
            />

            <Field
              component={TextareaInput}
              label='Billing Notes (Optional)'
              name='billingNotes'
              maxLength='1000'
              placeholder='(Billing Notes can be used to track quoted prices or any other billing information.
              Drivers do not see billing notes on the mobile app.)'
            />

            <div className={styles.requestedTimes}>
              <div className={styles.timeInput}>
                <Field
                  name='requestedStartTime'
                  label='Customer Requested Start Time'
                  component={TimeInput}
                />
              </div>
              <div className={styles.timeInput}>
                <Field
                  name='requestedEndTime'
                  label='Customer Requested End Time'
                  component={TimeInput}
                />
              </div>
            </div>

            <div className={styles.buttonContainer}>
              <button
                type='submit'
                className='dis-btn dis-btn-blank dis-btn-lg'
                disabled={invalid || pristine || disabled}
                onClick={() => form.change('navigateToFees', false)}>
                Create Ticket
              </button>
              <button
                type='submit'
                className='dis-btn dis-btn-primary dis-btn-lg'
                disabled={invalid || pristine || disabled}
                onClick={() => form.change('navigateToFees', true)}>
                Create Ticket & Add Fees
                <i className='material-icons dis-btn-icon'>add_circle</i>
              </button>
            </div>
          </form>
        )
      }}
    </Form>
  )
}

NewTicketForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  resourceTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    shortCode: PropTypes.string.isRequired
  })).isRequired,
  ticketTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    shortCode: PropTypes.string.isRequired
  })).isRequired,
  billingStatuses: PropTypes.object.isRequired,
  ticketTypeBillingStatusSelected: PropTypes.string,
  setTicketTypeBillingStatusSelected: PropTypes.func,
  resourceTypeBillingStatusSelected: PropTypes.string,
  setResourceTypeBillingStatusSelected: PropTypes.func,
  disabled: PropTypes.bool
}

function validate (values) {
  const errors = {}

  if (!values.date) {
    errors.date = 'Required'
  }

  if (!values.ticketTypeId) {
    errors.ticket_type_id = 'Required'
  }

  if (!values.resourceTypeId) {
    errors.resource_type_id = 'Required'
  }

  if (values.requestedStartTime && !values.requestedEndTime) {
    errors.requestedEndTime = 'Required if Start Time'
  }

  if (values.requestedEndTime && !values.requestedStartTime) {
    errors.requestedStartTime = 'Required if End Time'
  }

  const formattedRequestedStartTime = dayjs(values.requestedStartTime, 'HH:mm')
  const formattedRequestedEndTime = dayjs(values.requestedEndTime, 'HH:mm')
  const invalidTimes =
    values.requestedStartTime &&
    values.requestedEndTime &&
    formattedRequestedEndTime.isBefore(formattedRequestedStartTime)
  const sameTimes =
    values.requestedStartTime &&
    values.requestedEndTime &&
    formattedRequestedEndTime.isSame(formattedRequestedStartTime)

  if (invalidTimes || sameTimes) {
    errors.requestedEndTime = 'End Time must be after Start Time'
    errors.requestedStartTime = 'Start Time must be before End Time'
  }

  return errors
}
