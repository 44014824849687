import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import Spinner from 'react-spinkit'
import createWastepaySessionTokenMutation from '../../graphql/mutations/createWastepaySessionToken'
import createWastepaySessionTokenByUuidMutation from '../../graphql/mutations/createWastepaySessionTokenByUuid'
import { cardBrandToEnum, parseExpirationToMonthAndYear } from '../../utilities/card'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import useLightbox from '../../hooks/useLightbox'
import useMutation from '../../hooks/useMutation'
import styles from './index.module.scss'

export default function ElavonLightbox ({
  handleSubmit,
  close = () => null,
  client,
  billingAddress,
  isCreatingCard,
  setCardError,
  allowCreateCard = true,
  uuid = null,
  hideCards = false,
  buttonText = 'Add Card',
  allowAddNickname = false
}) {
  const lightboxReady = useLightbox()
  const [showSpinner, setShowSpinner] = useState(true)
  const [elavonActive, setElavonActive] = useState(false)

  const { mutateAsync: createWastepaySessionToken } = useMutation(
    createWastepaySessionTokenMutation,
    {
      onSuccess ({ sessionToken }) {
        openLightbox(sessionToken)
      },
      onError (error) {
        captureErrorAndNotify(error, 'Error processing payment')
        setShowSpinner(false)
      }
    }
  )

  const { mutateAsync: createWastepaySessionTokenByUuid } = useMutation(
    createWastepaySessionTokenByUuidMutation,
    {
      onSuccess ({ sessionToken }) {
        openLightbox(sessionToken)
      },
      onError (error) {
        captureErrorAndNotify(error, 'Error processing payment')
        setShowSpinner(false)
      }
    }
  )

  useEffect(() => {
    if (lightboxReady?.toLowerCase() === 'ready' && !elavonActive) {
      if (uuid) {
        createWastepaySessionTokenByUuid({ uuid })
      } else {
        createWastepaySessionToken({ clientId: client.id })
      }
    }
  }, [client, uuid, createWastepaySessionToken, createWastepaySessionTokenByUuid, lightboxReady, elavonActive])

  async function openLightbox (sessionToken) {
    const convergeCCToken = {
      ssl_add_token: 'Y',
      ssl_avs_address: billingAddress.streetAddress,
      ssl_avs_zip: billingAddress.zip,
      ssl_card_present: 'N',
      ssl_first_name: '',
      ssl_last_name: '',
      ssl_transaction_type: 'ccgettoken',
      ssl_txn_auth_token: sessionToken
    }

    setElavonActive(true)

    return await new Promise((resolve, reject) => {
      const options = {
        cssOverride: `html, body {background-color: rgba(0, 0, 0, 0); overflow: hidden;}
          .hpm-content {box-shadow: unset; padding:0; margin-top: unset; margin-left: auto; margin-right: auto;}
          md-card {box-shadow: unset;}
           .layout-padding {padding:0;}
          div span.ng-binding:before {content:"${buttonText}"; color:#141514;}
          button.md-button.md-primary.md-raised {background-color:#33CA80; color:#131514;}
          button.md-button.md-primary.md-raised:hover {background-color: #2db271 !important;}
          button.md-button.md-primary:not([disabled]):hover {background-color: #2db271 !important;}
          button.md-button.md-primary.md-focused {background-color: #2db271 !important;}
          button.md-button.md-primary.md-raised:active {background-color:#33CA80;}
          button.md-button.md-primary.md-raised:disabled {background-color:#33CA80; opacity: 0.65;}
          md-input-container .md-input {border-color: #DADADA;}
          md-input-container label {color:#AAAAAA;}
          ${hideCards ? '.hp-card-brands { display: none; }' : ''}
          .hp-card-brands img[alt="CUP"], .hp-card-brands img[alt="JCB"] { display: none; }
          md-dialog-content div h3 {text-align:center;}
          md-dialog-content div h3:before {content:"Unable to save card"; white-space: pre-wrap;}
          md-dialog-content div h3:after {content:" \\A Check your information is correct and try again"; white-space: pre-wrap; font-weight: normal;}
          md-dialog-content div h3 div {display:none;}
          md-dialog md-dialog-actions button.md-button:nth-of-type(2) {color:white !important; background-color: #ff0000;}
          md-dialog md-dialog-actions button.md-button:first-of-type {display:none;}
          md-dialog md-dialog-actions {justify-content:center;}`,
        frame: document.getElementById('id_payment')
      }

      window.PayWithConverge.open(
        convergeCCToken,
        {
          onApproval: (response) => {
            setCardError([])
            cardAction(response)
            resolve(response)
          },
          onCancelled: (error) => {
            setCardError([error?.ssl_result_message ?? 'User Cancelled'])
            close()
            reject(error)
          },
          onDeclined: (error) => {
            setCardError([error?.ssl_result_message ?? 'Declined'])
            close()
            reject(error)
          },
          onReady: () => {
            setCardError([])
            setShowSpinner(false)
            resolve({})
          }
        }, options
      )
    })
  }

  async function cardAction (cardResponse) {
    const {
      ssl_token: token,
      ssl_card_short_description: cardShortDescription,
      ssl_card_type: cardType,
      ssl_exp_date: expirationDate,
      ssl_card_number: cardNumber
    } = cardResponse
    const {
      month: cardExpirationMonth,
      year: cardExpirationYear
    } = parseExpirationToMonthAndYear(expirationDate)

    const card = {
      cardBrand: cardBrandToEnum(cardShortDescription),
      cardLastFour: cardNumber.slice(-4),
      billingAddress,
      cardShortDescription,
      cardNumber,
      cardType,
      cardExpirationMonth,
      cardExpirationYear,
      token,
      nickname: document.getElementById('nickname')?.value
    }

    const saveCard = !allowCreateCard ? false : document.getElementById('saveCard').checked
    return handleSubmit(card, saveCard)
  }

  return (
    <>
      <section>
        {allowAddNickname && (
          <label className={cn(styles.inputWrapper)}>
            Card Nickname (optional)
            <input
              id='nickname'
              type='text'
              className='form-control'
              placeholder='Enter a nickname for easy reference'
            />
          </label>
        )}
        {allowCreateCard && (
          <div>
            <label>
              <input type='checkbox' id='saveCard' defaultChecked={true} />
              Save card to account
            </label>
          </div>
        )}
      </section>
      {(showSpinner || isCreatingCard) && (
        <Spinner name='circle' className={styles.spinner} fadeIn='none' />
      )}
      <iframe title='enter payment frame' id='id_payment' className={cn(styles.paymentFrame)} />
    </>
  )
}

ElavonLightbox.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  close: PropTypes.func,
  client: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  setNewCard: PropTypes.func,
  setCardError: PropTypes.func,
  billingAddress: PropTypes.shape({
    streetAddress: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired
  }).isRequired,
  isCreatingCard: PropTypes.bool.isRequired,
  allowCreateCard: PropTypes.bool,
  allowAddNickname: PropTypes.bool,
  hideCards: PropTypes.bool,
  buttonText: PropTypes.string,
  uuid: PropTypes.string
}
