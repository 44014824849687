import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import MobileAppLinks from '../MobileAppLinks'

export default function AfterAddTeamMemberContainer () {
  const history = useHistory()

  const continueToTeam = useCallback(
    () => history.push('/hauler/team'),
    [history]
  )

  return (
    <div className='center__all--large mt-10'>
      <h4>Excellent! To finish onboarding, follow the steps below:</h4>

      <div className='flex flex-col md:items-start items-center md:flex-row mx-4 mt-20'>
        <div className='w-3/6 text-center'>
          <h3 className='mb-3'><strong>Onboard an Admin</strong></h3>
          <h5 className='mb-3'>Give your Admin their Dispatcher password.</h5>
          <h5>
            Have them log in on the website.
            <span className='text-primary ml-2'>
              app.dispatcher.com
            </span>
          </h5>
        </div>
        <div className='w-3/6 text-center'>
          <h3 className='mb-3'><strong>Onboard a Driver</strong></h3>
          <h5 className='mb-3'>Have them download the Dispatcher app for their phone.</h5>
          <MobileAppLinks />
          <div className='h-4' />
          <h5>Have them log in with their email and Dispatcher password.</h5>
        </div>
      </div>
      <button
        className='btn btn-link btn-lg mt-28'
        onClick={continueToTeam}>
        Okay, got it!
      </button>
    </div>
  )
}
