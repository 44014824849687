import React from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import cn from 'classnames'

export default function ConnectToWastePay ({ accessToken }) {
  return (
    <div>
      <p className={styles.description}>
        Connect your Dispatcher account to your WastePay account in order to add a credit card payment to this invoice
      </p>
      <button
        type='button'
        className={cn(styles.button, 'dis-btn dis-btn-primary dis-btn-lg')}
        onClick={() => {
          window.location.href = `${process.env.REACT_APP_NEW_API_URL}/wastepay/oauth2/connect?authorization=${accessToken}`
        }}>
        <span>
          CONNECT TO WASTEPAY
        </span>
      </button>
      <div className={styles.wpImgContainer}>
        <img
          src='https://www.dispatcher.com/images/WP-Secure-Payments.svg' alt='Waste Pay secure payments logo'
        />
      </div>
    </div>
  )
}

ConnectToWastePay.propTypes = {
  accessToken: PropTypes.string.isRequired
}
