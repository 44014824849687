import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { history } from '../../history'
import Nav from './Nav'
import NewDataAvailable from './NewDataAvailable'

export default class NormalLayoutContainer extends Component {
  constructor (props) {
    super(props)
    this.state = { navHeight: 70 }
    this.navbarRef = React.createRef()
    // So we're doing this messiness because the bootstrap navbar will wrap and we need to move the content below that
    this.handleResize = () => {
      if (!this.navbarRef.current) return
      this.setState({ navHeight: this.navbarRef.current.offsetHeight })
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    setTimeout(() => { this.handleResize() }, 2000) // Page has to render before we can do this, so we have to wait
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize)
  }

  render () {
    return (
      <div className='container-fluid' style={{ paddingTop: this.state.navHeight }}>
        <div className='row'>
          <Nav ref={this.navbarRef} />

          <NewDataAvailable show={this.props.showNewData || false} />

          {
            this.props.fullWidth
              ? (
                <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                  {this.props.children}
                </div>
                )
              : (
                <div>
                  <div className='hidden-xs hidden-sm col-md-1 col-lg-1'>
                    {this.props.showBackLink && (
                      <div className='row push-down-super-small pull-right'>
                        <button className='btn btn-lg btn-link' onClick={() => history.goBack()}>
                          <i className='material-icons back-button-icon'>arrow_back</i>
                        </button>
                      </div>
                    )}
                  </div>

                  <div className='col-xs-12 col-sm-12 col-md-10 col-lg-10 pb-10'>
                    {this.props.children}
                  </div>
                </div>
                )
          }
        </div>
      </div>
    )
  }
}

NormalLayoutContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array
  ]),
  fullWidth: PropTypes.bool,
  showBackLink: PropTypes.bool,
  showNewData: PropTypes.bool
}
