import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { useHistory } from 'react-router-dom'
import ModalStatusBar from './ModalStatusBar'
import ErrorMessage from './ErrorMessage'
import InnerDropzone from './InnerDropzone'
import ProgressBar from './ProgressBar'
import { DIRECT_UPLOAD_URL, fileExtensionValidator, MAX_ALLOWED_BYTES } from './utils'
import cn from 'classnames'
import { DirectUpload } from '@rails/activestorage'
import useMutation from '../../hooks/useMutation'
import createClientImportMutation from '../../graphql/mutations/createClientImport'
import createResourceImportMutation from '../../graphql/mutations/createResourceImport'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import { ROUTES } from '../../routes'

export default function StageTwo ({ farthestStage, pluralImportName }) {
  const history = useHistory()
  const [blob, setBlob] = useState({})
  const [file, setFile] = useState(null)
  const [errors, setErrors] = useState([])
  const [progress, setProgress] = useState(null)
  const [request, setRequest] = useState(null)

  function handleDropRejected (rejectedFiles) {
    setErrors([])
    setProgress(0)
    const { file, errors } = rejectedFiles[0]

    if (!file) return
    setErrors(errors)
  }

  function handleDropAccepted (acceptedFiles) {
    setErrors([])
    setProgress(null)
    setBlob(null)
    const file = acceptedFiles[0]
    setFile(file)
    if (!file) return
    const upload = new DirectUpload(
      file,
      DIRECT_UPLOAD_URL,
      {
        directUploadWillStoreFileWithXHR: (request) => {
          setRequest(request)
          request.upload.addEventListener(
            'progress',
            e => { setProgress(e.loaded * 100 / e.total) })
        }
      }
    )

    upload.create((error, blob) => {
      if (error) {
        setErrors([{ code: 'activestorage-error', message: error }])
        return
      }

      setBlob(blob)
    })
  }

  const inProgress = Boolean(progress && progress > 0)

  const showProgressBar = request && inProgress

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDropAccepted: handleDropAccepted,
    onDropRejected: handleDropRejected,
    maxFiles: 1,
    maxSize: MAX_ALLOWED_BYTES,
    minSize: 1,
    multiple: false,
    validator: fileExtensionValidator,
    disabled: inProgress
  })

  function handleCancel () {
    if (request) request.abort()
    setFile(null)
    setBlob({})
    setProgress(null)
    setRequest(null)
    setErrors([])
  }

  const signedId = blob?.signed_id

  const { mutate: createClientImport, isLoading: isCreatingClientImport } = useMutation(createClientImportMutation, {
    onSuccess ({ clientImport }) {
      history.push(`${ROUTES.clientImports}/${clientImport.id}`)
    },
    onError (error) {
      captureErrorAndNotify(error, 'Failed to start import')
    }
  })

  const { mutate: createResourceImport, isLoading: isCreatingResourceImport } = useMutation(createResourceImportMutation, {
    onSuccess ({ resourceImport }) {
      history.push(`${ROUTES.resourceImports}/${resourceImport.id}`)
    },
    onError (error) {
      captureErrorAndNotify(error, 'Failed to start import')
    }
  })

  return (
    <>
      <h3 className='p-6 text-center'>{`Import ${pluralImportName.replace(/^./, pluralImportName[0].toUpperCase())}`}</h3>
      <ModalStatusBar farthestStage={farthestStage} />
      <h4 className='text-center my-8'>Upload File</h4>
      <div {...getRootProps({
        className: cn(
          'text-gray-md-dark bg-gray-light border-dashed p-8 flex flex-1 flex-col items-center border-2',
          'rounded-lg outline-none border-gray border-opacity-40 h-60',
          {
            'border-green-400': isDragAccept,
            'border-red-500': isDragReject,
            'border-blue-400': isDragActive,
            'cursor-not-allowed': inProgress,
            'hover:bg-gray-light-10-dark cursor-pointer': !inProgress
          }
        )
      })}>
        <input {...getInputProps()} />
        <InnerDropzone isDragActive={isDragActive} inProgress={inProgress} />
      </div>
      {showProgressBar && <ProgressBar fileName={file?.name} progress={progress} onCancel={handleCancel} />}
      {errors && errors.length > 0 && <ErrorMessage errors={errors} />}
      <div className='flex justify-center'>
        <button
          className={cn('btn btn-primary disabled:opacity-50 w-80 mt-8 relative', { 'animate-pulse': signedId })}
          onClick={() => pluralImportName === 'accounts' ? createClientImport({ signedId }) : createResourceImport({ signedId })}
          disabled={(isCreatingClientImport || isCreatingResourceImport) || !signedId}>
          NEXT
          {(isCreatingClientImport || isCreatingResourceImport) && (
            <i className='material-icons text-xl text-gray-med-dark opacity-80 animate-spin ml-4 absolute top-3'>autorenew</i>
          )}
        </button>
      </div>
    </>
  )
}

StageTwo.propTypes = {
  farthestStage: PropTypes.number.isRequired,
  pluralImportName: PropTypes.string.isRequired
}
