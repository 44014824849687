import React, { useState } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import Modal from '../modal/Modal'

export default function SMSEnableButton ({
  smsEnabled,
  handleUpdate,
  isLoading,
  step,
  isParentEnabled = true,
  levelName = ''
}) {
  const [modalOpen, setModalOpen] = useState(false)

  function handleContinueClick () {
    setModalOpen(false)
    return handleUpdate(smsEnabled)
  }

  function handleCancelClick () {
    setModalOpen(false)
  }

  function handleButtonClick () {
    if (!smsEnabled) {
      return handleUpdate(smsEnabled)
    }

    return setModalOpen(true)
  }

  function warningText () {
    if (step === 'HAULER') {
      return 'Disabling SMS at this level will deactivate all SMS. No messages will be sent to any account or job.'
    }

    if (step === 'ACCT') {
      return `Disabling SMS at this level will deactivate all SMS for ${levelName}. No messages will be sent to this customer.`
    }

    // linter wasn't having a good day with this being as long as it was.
    if (step === 'JOB') {
      return `Disabling SMS at this level will deactivate all SMS for job #${levelName}.
      No messages will be sent for this job's tickets, but messages could be sent for other jobs related to this customer.`
    }

    return `Disabling SMS will deactivate SMS for this contact only for job #${levelName}.`
  }

  return (
    <>
      <Modal
        className={styles.mainModalContainer}
        isOpen={modalOpen}
        closeModal={handleCancelClick}>
        <div className={cn(styles.modalWrapper)}>
          <header className={cn(styles.modalHeader)}>
            <h4>Disable SMS</h4>
            <button
              className={cn(styles.closeModalBtn, 'dis-btn dis-btn-no-shadow')}
              type='button'
              onClick={handleCancelClick}>
              <i className={cn(styles.closeModalIcon, 'material-icons dis-btn-icon')}>close</i>
            </button>
          </header>
          <p>{warningText()}</p>
          <div className={cn(styles.buttonWrapper)}>
            <button
              onClick={handleCancelClick}
              type='button'
              className={cn('dis-btn dis-btn-blank dis-btn-lg')}>
              Cancel
            </button>
            <button
              type='button'
              className={cn('dis-btn dis-btn-lg dis-btn-danger')}
              onClick={handleContinueClick}>
              OK, Disable SMS
            </button>
          </div>
        </div>
      </Modal>
      <button
        type='button'
        className={cn('dis-btn dis-btn-lg', {
          'dis-btn-primary': !smsEnabled,
          'dis-btn-danger': smsEnabled
        })}
        disabled = {isLoading || !isParentEnabled}
        onClick={handleButtonClick}>
        { smsEnabled ? 'Disable' : 'Enable'} SMS
      </button>
    </>
  )
}

SMSEnableButton.propTypes = {
  smsEnabled: PropTypes.bool.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isParentEnabled: PropTypes.bool,
  step: PropTypes.oneOf(['HAULER', 'ACCT', 'JOB', 'CONTACT']),
  levelName: PropTypes.string
}
