import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, Link } from 'react-router-dom'
import NoResults from '../pages/shared/NoResults'
import { ROUTES } from '../routes'
import { OverlayTrigger } from 'react-bootstrap'
import Tooltip from 'react-bootstrap/lib/Tooltip'
import CursorPagination from './cursor-pagination'
import TableHeader from './table-header'

export default function ClientsTable ({ clients, sort, onSortChange, onPageRequest, fakeCount }) {
  return (
    <div className='row'>
      <div className='col-xs-12 table-responsive'>
        <table className='table table-striped'>
          <thead>
            <tr>
              <th width='5%'>
                <TableHeader
                  label='ID'
                  columnName='customId'
                  sort={sort}
                  onSortChange={onSortChange}
                />
              </th>
              <th width='10%'>
                <TableHeader
                  label='Account Notes'
                  columnName='notes'
                  sort={sort}
                  onSortChange={onSortChange}
                />
              </th>
              <th width='20%'>
                <TableHeader
                  label='Name'
                  columnName='name'
                  sort={sort}
                  onSortChange={onSortChange}
                />
              </th>
              <th width='15%'>
                <TableHeader
                  label='Contact Name'
                  columnName='lastName'
                  sort={sort}
                  onSortChange={onSortChange}
                />
              </th>
              <th width='15%'>
                <TableHeader
                  label='Phone'
                  columnName='primaryPhone'
                  sort={sort}
                  onSortChange={onSortChange}
                />
              </th>
              <th width='10%'>
                <TableHeader
                  label='Open Jobs'
                  columnName='openJobsCount'
                  sort={sort}
                  onSortChange={onSortChange}
                />
              </th>
              <th width='10%'>
                <TableHeader
                  label='Closed Jobs'
                  columnName='closedJobsCount'
                  sort={sort}
                  onSortChange={onSortChange}
                />
              </th>
              <th width='15%'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {clients.nodes.length === 0 && <tr><td colSpan={8}><NoResults /></td></tr>}
            {clients.nodes.map(client => {
              const clientPath = generatePath(ROUTES.client, { clientId: client.id })
              return (
                <tr key={client.id}>
                  <td>
                    <Link to={clientPath}>
                      {client.customId}
                    </Link>
                  </td>
                  <td>
                    {client.notes &&
                      <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip'>{client.notes}</Tooltip>}>
                        <i className='material-icons'>speaker_notes</i>
                      </OverlayTrigger>
                    }
                  </td>
                  <td>
                    <Link to={clientPath}><strong>{client.name}</strong></Link>
                  </td>
                  <td>
                    {client.firstName || client.lastName ? `${client.firstName || ''} ${client.lastName || ''}` : 'None'}
                  </td>
                  <td>
                    {client.prettyPrimaryPhone ? client.prettyPrimaryPhone : 'None'}
                  </td>
                  <td>
                    {
                      client.openJobsCount
                        ? (
                          <Link to={`${ROUTES.jobs}?client_id=${client.id}&status=open`}>
                            {client.openJobsCount}
                          </Link>
                          )
                        : 'None'
                    }
                  </td>
                  <td>
                    {
                      client.closedJobsCount
                        ? (
                          <Link to={`${ROUTES.jobs}?client_id=${client.id}&status=closed`}>
                            {client.closedJobsCount}
                          </Link>
                          )
                        : 'None'
                    }
                  </td>
                  <td>
                    <div className='btn-group'>
                      <Link to={clientPath} className='btn btn-sm btn-primary-dk'>
                        Details
                      </Link>
                      <button className='btn btn-primary-dk btn-sm dropdown-toggle hidden-xs hidden-sm drop-down' data-toggle='dropdown'>
                        <span className='caret' />
                      </button>
                      <ul className='dropdown-menu'>
                        <li>
                          <Link to={`${ROUTES.newJob}?client_id=${client.id}`}>
                            <span>Create New Job</span>
                            <i className='material-icons'>add</i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        {clients.nodes.length > 0 && (
          <CursorPagination
            pageInfo={clients.pageInfo}
            totalCount={clients.totalCount}
            onPageRequest={onPageRequest}
            fakeCount={fakeCount}
          />
        )}
      </div>
    </div>
  )
}
ClientsTable.propTypes = {
  clients: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.object).isRequired,
    pageInfo: PropTypes.shape({
      endCursor: PropTypes.string,
      hasNextPage: PropTypes.bool.isRequired,
      hasPreviousPage: PropTypes.bool.isRequired,
      startCursor: PropTypes.string
    }).isRequired,
    totalCount: PropTypes.number.isRequired
  }).isRequired,
  sort: PropTypes.shape({
    column: PropTypes.oneOf(['customId', 'notes', 'name', 'lastName', 'primaryPhone', 'openJobsCount', 'closedJobsCount']).isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired
  }).isRequired,
  onPageRequest: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  fakeCount: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired
  })
}
