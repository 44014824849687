import React from 'react'
import PropTypes from 'prop-types'

export default function TableHeader ({ label, columnName, onSortChange, sort }) {
  const currentlySorted = sort.column === columnName

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className='cursor-pointer' onClick={() => onSortChange(columnName)}>
      {label}
      {currentlySorted && (
        <span className='pull-right'>{ sort.direction === 'asc' ? <>&uarr;</> : <>&darr;</> }</span>
      )}
    </div>
  )
}

TableHeader.propTypes = {
  label: PropTypes.string.isRequired,
  columnName: PropTypes.string.isRequired,
  sort: PropTypes.shape({
    column: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired
  }).isRequired,
  onSortChange: PropTypes.func.isRequired
}
