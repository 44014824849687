import { useHistory } from 'react-router-dom'
import React from 'react'
import { useSelector } from 'react-redux'
import { ROUTES } from '../../routes'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import { Spinner } from '../shared/Spinner'
import styles from './index.module.scss'
import QuickbooksImportForm from '../../components/QuickbooksImportForm'
import useQuery from '../../hooks/useQuery'
import QUERY_KEYS from '../../graphql/queryKeys'
import quickbooksResourcesQuery from '../../graphql/queries/quickbooksResources'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import useMutation from '../../hooks/useMutation'
import importQuickbooksCustomersMutation from '../../graphql/mutations/importQuickbooksCustomers'
import QuickbooksImportStarted from '../../components/quickbooks-import-started'

export default function QuickbooksImportPage () {
  const history = useHistory()
  const { hauler } = useSelector(({ user }) => ({ hauler: user.hauler }))

  const { data: quickbooksResourceData, isLoading: isLoadingQuickbooksResourceData } = useQuery(
    [QUERY_KEYS.quickbooksCustomerTypesAndTaxCodes, { haulerId: hauler.id }],
    quickbooksResourcesQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Failed to retrieve data')
      },
      enabled: hauler.quickbooks.isConnected
    }
  )

  const { mutate: importQuickbooksCustomers, isSuccess: isImportStarted, isLoading: isStartingImport } = useMutation(
    importQuickbooksCustomersMutation,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Failed to start import')
      }
    })

  function handleImport (data) {
    switch (data.importStatus) {
      case 'skip':
        history.push(ROUTES.quickbooksSettings)
        break
      case 'all':
        importQuickbooksCustomers({ haulerId: hauler.id })
        break
      case 'custom':
        importQuickbooksCustomers({ haulerId: hauler.id, ...data })
        break
      default:
        throw new Error(`Unhandled QuickBooks import status - ${data.importStatus}`)
    }
  }

  return (
    <NormalLayoutContainer>
      {isLoadingQuickbooksResourceData || isStartingImport
        ? <Spinner isFetching />
        : isImportStarted
          ? <QuickbooksImportStarted />
          : (<>
            <h1 className={styles.title}>Import Customers</h1>
            <p className={styles.description}>Choose an option below to import all or just some of your customers
              from QuickBooks. We will attempt to match account names between Dispatcher and QuickBooks to prevent duplication.
              If there are duplicate account names already present in Dispatcher, we will link the one created first with the
              same name in QuickBooks.</p>
            <QuickbooksImportForm onSubmit={handleImport} customerTypes={quickbooksResourceData.quickbooksCustomerTypes} />
          </>)
        }
    </NormalLayoutContainer>
  )
}
