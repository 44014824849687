import { intercomConnection } from '../../intercomConnection'
import { loader } from 'graphql.macro'

export default async function createClient ({ dataLoaders, variables }) {
  const schema = loader('./createClient.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      haulerId: variables.haulerId,
      clientFields: variables.clientFields
    }
  })
  intercomConnection.trackEvent('client_created', {
    client_id: response.createClient.client.id,
    client_custom_id: response.createClient.client.customId,
    client_name: response.createClient.client.name
  })

  return response.createClient
}
