import { loader } from 'graphql.macro'

export default function finalizePasswordReset ({ dataLoaders, variables }) {
  const { token, password, passwordConfirmation } = variables
  const schema = loader('./finalizePasswordReset.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      token,
      password,
      passwordConfirmation
    }
  })
}
