import React from 'react'

export default function ParsingView () {
  return (
    <div className='text-center my-20'>
      <i className='material-icons text-8xl text-gray-med-dark opacity-80 animate-spin'>autorenew</i>
      <h4 className='text-center mt-10 mb-32'>Processing Uploaded File...</h4>
    </div>
  )
}
