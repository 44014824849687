import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'
import dayjs from '../../utilities/dayjs'
import { generatePath, Link } from 'react-router-dom'
import useMutation from '../../hooks/useMutation'
import removeTicketsFromInvoice from '../../graphql/mutations/removeTicketsFromInvoice'
import { ROUTES } from '../../routes'
import { commaDeliminate, formatDollarAmount } from '../../utilities/stringUtilities'
import notify from '../../utilities/notify'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import RemoveTicketModal from './remove-ticket-modal'
import Modal from '../modal/Modal'
import ReactTooltip from 'react-tooltip'

export default function InvoiceLineItemsTable ({
  invoice,
  showActionColumn,
  showLineItemTotals,
  showDiscountAndTax,
  editRoute,
  refetchInvoice = () => null
}) {
  const [removeTicketId, setRemoveTicketId] = useState(undefined)
  let columnWidth = ['10%', '45%', '45%']

  const { mutate: removeTicketsMutation, isLoading: isRemovingTickets } = useMutation(removeTicketsFromInvoice, {
    onSuccess ({ invoice }) {
      closeModal()
      notify('success', 'Ticket removed from Invoice')
      refetchInvoice()
    },
    onError (error) {
      captureErrorAndNotify(error, 'Unable to remove tickets from invoice')
    }
  })

  if (showActionColumn) {
    columnWidth = [
      '12%',
      '35%',
      '35%'
    ]
  }

  function removeTicket (id) {
    removeTicketsMutation({ invoiceID: invoice.id, tickets: [id] })
  }

  function closeModal () {
    setRemoveTicketId(undefined)
  }

  return (
    <>
      <Modal isOpen={removeTicketId != null}>
        {removeTicketId != null && (
          <RemoveTicketModal
            ticketId={removeTicketId}
            onClose={closeModal}
            onSave={removeTicket}
            isLoading={isRemovingTickets}
          />
        )}
      </Modal>
      <div className={styles.ticketFeeTableContainer}>
        <table className='table table-responsive table-striped'>
          <thead>
            <tr>
              <th width={columnWidth[0]}>Ticket ID</th>
              <th width={columnWidth[1]}>Ticket Details</th>
              <th width={columnWidth[2]}>Charges</th>
              {showActionColumn && <th width={columnWidth[3]}>Action</th>}
            </tr>
          </thead>
          <tbody>
            {!invoice?.tickets?.length && <tr className={styles.noTicketsMessage}>
              <td className={styles.noTicketsMessageCell} colSpan={showActionColumn ? 4 : 3}>There are no tickets on this invoice.</td>
            </tr>}
            {invoice?.tickets?.map(ticket => (
              <tr key={ticket?.id}>
                <td>
                  <Link to={generatePath(ROUTES.ticket, { id: ticket?.id })}>
                    {ticket?.customId}
                  </Link>
                </td>
                <td>
                  <div>
                    <div className={styles.ticketTypeResourceType}>{ticket?.ticketType?.name } - {ticket?.resourceType?.name}</div>
                    <div>{ticket?.job?.addressline1}</div>
                    <div>{dayjs(ticket?.date).format('MMM D, YYYY')}</div>
                  </div>
                </td>
                <td>
                  {!ticket?.ticketFees?.length
                    ? <div className='text-danger'>No Ticket Fees</div>
                    : (
                      <table className={showActionColumn ? styles.feeTypesTable : styles.feeTypesTableFullWidth}>
                        <thead>
                          <tr>
                            <th width='60%'>Fee Type</th>
                            <th width='5%'>Qty</th>
                            <th width='35%' className='text-right'>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            ticket?.ticketFees.map((ticketFee, index, arr) => {
                              const lastIndex = index === arr.length - 1
                              return (
                                (
                                  <tr key={index}>
                                    <td className={cn({
                                      [styles.lastTicketFee]: lastIndex && showLineItemTotals
                                    })}>
                                      <span className={styles.feeType}>
                                        <ReactTooltip />
                                        {ticketFee?.name}
                                        {ticketFee?.description &&
                                          <i
                                            className={cn('material-icons', styles.feeTypeIcon)}
                                            data-tip={ticketFee?.description}>
                                            speaker_notes</i>
                                        }
                                      </span>
                                    </td>
                                    <td className={cn({
                                      [styles.lastTicketFee]: lastIndex && showLineItemTotals
                                    })}>
                                      {ticketFee?.quantity}
                                    </td>
                                    <td className={cn('text-right', {
                                      [styles.lastTicketFee]: lastIndex && showLineItemTotals
                                    })}>
                                      ${commaDeliminate(ticketFee?.amountCents * ticketFee?.quantity / 100)}
                                    </td>
                                  </tr>
                                )
                              )
                            })
                          }
                          {showLineItemTotals && <>
                            {showDiscountAndTax && <>
                              <tr>
                                <td className={styles.chargeTotals} colSpan={2}>Discount</td>
                                <td className={cn(styles.chargeTotals, 'text-right')}>
                                  (${(formatDollarAmount(ticket?.discountAndTax?.discountAmount))})
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2}>Tax</td>
                                <td className='text-right'>
                                  ${formatDollarAmount(ticket?.discountAndTax?.taxAmount)}
                                </td>
                              </tr>
                            </>}
                            <tr>
                              <td colSpan={2}><strong>Ticket Total</strong></td>
                              <td className='text-right'>
                                ${formatDollarAmount(ticket?.total)}
                              </td>
                            </tr>
                          </>}
                        </tbody>
                      </table>
                      )}
                </td>
                {showActionColumn && <td>
                  <Link
                    className={cn('dis-btn dis-btn-sm dis-btn-primary-dk', styles.editFeesBtn)}
                    to={{
                      pathname: `${generatePath(ROUTES.ticket, { id: ticket?.id })}?scrollTo=fees`,
                      state: { returnTo: editRoute }
                    }}>
                    Edit Fees
                  </Link>
                  <button onClick={() => setRemoveTicketId(ticket?.id)} className={cn('dis-btn dis-btn-sm dis-btn-danger', styles.editFeesBtn)}>
                    Remove
                  </button>
                </td>}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

InvoiceLineItemsTable.propTypes = {
  invoice: PropTypes.object.isRequired,
  showActionColumn: PropTypes.bool,
  showDiscountAndTax: PropTypes.bool.isRequired,
  showLineItemTotals: PropTypes.bool,
  editRoute: PropTypes.string,
  refetchInvoice: PropTypes.func
}
