import { loader } from 'graphql.macro'

export default async function revokeRefreshToken ({ dataLoaders, variables }) {
  const schema = loader('./revokeRefreshToken.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      refreshToken: variables
    }
  })
  return response.revokeRefreshToken
}
