import React from 'react'
import PropTypes from 'prop-types'
import { underscoreToCapital } from '../../utilities/stringUtilities'

export default function InvoiceStatusLabel ({ children }) {
  const displayStatus = underscoreToCapital(children?.toUpperCase())
  return (
    <span className={labelClasses(children)}>{displayStatus}</span>
  )
}
InvoiceStatusLabel.propTypes = {
  children: PropTypes.string.isRequired
}

function labelClasses (status) {
  let classes = 'dis-label dis-label-pill'

  if (status === 'OPEN') {
    classes += ' dis-label-alert'
  } else if (status === 'PAID') {
    classes += ' dis-label-success'
  } else if (status === 'CLOSED_IN_QBO') {
    classes += ' dis-label-default-dk'
  } else if (status === 'VOIDED') {
    classes += ' dis-label-default-md'
  } else if (status === 'OVERDUE') {
    classes += ' dis-label-warning'
  }
  return classes
}
