import { loader } from 'graphql.macro'

export default function resourceImportRows ({ dataLoaders, queryKey }) {
  const [, variables] = queryKey
  const schema = loader('./resourceImportRows.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(schema, {
    status: variables.status,
    resourceImportId: variables.resourceImportId,
    first: variables.first,
    before: variables.before,
    after: variables.after,
    last: variables.last
  })
}
