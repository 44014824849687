import { put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import { history } from '../../../../history'
import BASE_URI from '../../../../utilities/BASE_URI'
import { handleError } from '../../../../utilities/handleError'
import { getHeaders } from '../../../../utilities/getHeaders'
import notify from '../../../../utilities/notify'
import { intercomConnection } from '../../../../intercomConnection'

export const REQUEST_CURRENT_JOB_ACTION = 'REQUEST_CURRENT_JOB_ACTION'
export const RECEIVE_CURRENT_JOB_SUCCESS = 'RECEIVE_CURRENT_JOB_SUCCESS'
export const RECEIVE_CURRENT_JOB_FAILURE = 'RECEIVE_CURRENT_JOB_FAILURE'

export const REQUEST_UPDATE_CURRENT_JOB_STATUS_ACTION = 'REQUEST_UPDATE_CURRENT_JOB_STATUS_ACTION'
export const RECEIVE_UPDATE_CURRENT_JOB_STATUS_SUCCESS = 'RECEIVE_UPDATE_CURRENT_JOB_STATUS_SUCCESS'
export const RECEIVE_UPDATE_CURRENT_JOB_STATUS_FAILURE = 'RECEIVE_UPDATE_CURRENT_JOB_STATUS_FAILURE'

export const REQUEST_DELETE_CURRENT_JOB_ACTION = 'REQUEST_DELETE_CURRENT_JOB_ACTION'
export const RECEIVE_DELETE_CURRENT_JOB_SUCCESS = 'RECEIVE_DELETE_CURRENT_JOB_SUCCESS'
export const RECEIVE_DELETE_CURRENT_JOB_FAILURE = 'RECEIVE_DELETE_CURRENT_JOB_FAILURE'

function * requestCurrentJob (data) {
  try {
    const { id } = data.payload
    const response = yield axios.get(
      `${BASE_URI}/hauler/jobs/${id}`,
      getHeaders()
    )
    yield put({ type: RECEIVE_CURRENT_JOB_SUCCESS, payload: response.data })
  } catch (e) {
    const errors = handleError(e)
    yield put({ type: RECEIVE_CURRENT_JOB_FAILURE, payload: { errors } })
  }
}

function * requestUpdateJobStatus (data) {
  try {
    const { id, status } = data.payload
    const response = yield axios.patch(
      `${BASE_URI}/hauler/jobs/${id}`,
      { status },
      getHeaders()
    )
    yield put({ type: RECEIVE_UPDATE_CURRENT_JOB_STATUS_SUCCESS, payload: response.data })
    yield notify('success', `Job Status Updated to ${status}`)
    yield intercomConnection.trackEvent('job_updated_status', {
      id: response.data.job.id,
      custom_id: response.data.job.custom_id,
      status: response.data.job.status
    })
  } catch (e) {
    const errors = handleError(e)
    yield put({ type: RECEIVE_UPDATE_CURRENT_JOB_STATUS_FAILURE, payload: { errors } })
  }
}

function * requestDeleteJob (data) {
  try {
    const { job } = data.payload
    const response = yield axios.delete(
      `${BASE_URI}/hauler/jobs/${job.id}`,
      getHeaders()
    )
    yield put({ type: RECEIVE_DELETE_CURRENT_JOB_SUCCESS, payload: response.data })
    intercomConnection.trackEvent('job_destroyed', {
      id: job.id,
      custom_id: job?.customId,
      status: job?.status
    })
    yield history.replace('/hauler/jobs/search')
    yield notify('success', 'Job Deleted')
  } catch (e) {
    const errors = handleError(e)
    yield put({ type: RECEIVE_DELETE_CURRENT_JOB_FAILURE, payload: { errors } })
  }
}

export default function * currentJobSaga () {
  yield takeLatest(REQUEST_CURRENT_JOB_ACTION, requestCurrentJob)
  yield takeLatest(REQUEST_UPDATE_CURRENT_JOB_STATUS_ACTION, requestUpdateJobStatus)
  yield takeLatest(REQUEST_DELETE_CURRENT_JOB_ACTION, requestDeleteJob)
}
