import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

export default class FeeTypeStatusLabel extends Component {
  labelClasses (status) {
    let classes = 'label label-pill'
    if (status === 'enabled') {
      classes += ' label-success'
    } else if (status === 'disabled') {
      classes += ' label-danger'
    }
    return classes
  }

  render () {
    const { status, linkPath } = this.props
    if (!status) {
      return null
    }
    const label = <span className={this.labelClasses(status)}> {status.toUpperCase()} </span>
    if (linkPath) {
      return <Link to={linkPath}>{label}</Link>
    }
    return label
  }
}

FeeTypeStatusLabel.propTypes = {
  linkPath: PropTypes.string,
  status: PropTypes.oneOf(['enabled', 'disabled'])
}
