import React from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.css'
import cn from 'classnames'
import classNamePropTypes from '../../utilities/classNamePropTypes'

/**
 * Meant to be a very small reusable component to make sure we have a consistent header and styling for every page.
 * The old page title component below was providing layout and forcing bootstrap, all things we don't really
 * want to continue doing.
 */
export default function Index ({ children, className }) {
  return (
    <h1 className={cn(styles.pageTitle, className)}>{children}</h1>
  )
}
Index.propTypes = {
  children: PropTypes.node.isRequired,
  className: classNamePropTypes
}

/**
 * @deprecated Use the default export page title instead. This is legacy and is a confusing component because it is called
 * "PageTitle" but then it was commonly used to nest buttons inside it and provides a layout. This also uses bootstrap
 * which we are trying to remove.
 */
export function PageTitle ({ children, title }) {
  return (
    <div className='row'>
      <h3 className='pull-left'>{title}</h3>
      <div className='pull-right'>{children}</div>
    </div>
  )
}
PageTitle.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
}
