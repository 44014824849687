import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import {
  TextInput
} from '../../../../../../components/TextInput'
import { decodeJWT } from '../../../../../shared/decodeJWT'
import normalizePhone from '../../../../../shared/normalizers/normalizePhone'
import { validate } from './HaulerTeamMemberEditFormUtils'
import { SelectInput } from '../../../../../../components/SelectInput'
import { RadioInput } from '../../../../../../components/RadioInput'

let HaulerTeamMemberEditForm = ({ onSubmit, pristine, invalid, isFetching, initialValues }) => {
  return (
    <div className='row panel panel-default'>
      <div className='container-fluid push-down-super-small'>
        <form onSubmit={onSubmit}>
          <div className='row'>
            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
              <Field
                name='first_name'
                label='First Name'
                component={TextInput}
                maxLength='20'
              />
              <Field
                name='last_name'
                label='Last Name'
                component={TextInput}
                maxLength='20'
              />
              {decodeJWT().u !== initialValues.id && (
                <Field
                  name='role'
                  component={SelectInput}
                  label='Role'
                  options={{ hauler_admin: 'Company Admin', hauler_driver: 'Company Driver' }}
                />
              )}
            </div>
            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
              <Field
                name='email'
                type='email'
                label='Email'
                component={TextInput}
                maxLength='64'
              />
              <Field
                name='pretty_primary_phone'
                label='Primary Phone Number'
                component={TextInput}
                normalize={normalizePhone}
              />
              <div className='w-full'> Show User Icon on Dispatch Map?</div>
              <Field
                classNames='mr-8'
                name='track_location'
                radioVal={true}
                label='Yes'
                component={RadioInput}
              />
              <Field
                name='track_location'
                radioVal={false}
                label='No'
                component={RadioInput}
              />
            </div>
          </div>
          <button
            type='submit'
            className='btn btn-lg btn-primary push-down-very-small pull-right'
            disabled={pristine || invalid || isFetching}>
            <span className='pull-left'>Save Changes</span>
            <i className='material-icons pull-right thumbster-button-icon'>check</i>
          </button>
        </form>
      </div>
      <br />
    </div>
  )
}

const mapStateToProps = state => ({
  initialValues: state.currentHaulerTeamMember
})

// eslint-disable-next-line no-class-assign
HaulerTeamMemberEditForm = reduxForm({
  form: 'haulerTeamMemberEditForm',
  validate
})(HaulerTeamMemberEditForm)

HaulerTeamMemberEditForm.propTypes = {
  initialValues: PropTypes.object,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool
}

export default connect(mapStateToProps)(HaulerTeamMemberEditForm)
