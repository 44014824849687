import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import { TextInput } from '../TextInput'
import { composeValidators, required, validPhone } from '../../utilities/commonValidators'
import normalizePhone from '../../pages/shared/normalizers/normalizePhone'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../routes'

export default function SignUpStepOne ({ onNext, signUpData }) {
  return (
    <div className='panel text-center'>
      <div className='container-fluid text-center'>
        <br />
        <h4>Start with basic information about your company.</h4>
      </div>
      <Form onSubmit={onNext} initialValues={{
        name: signUpData.name,
        companyPrimaryPhone: signUpData.companyPrimaryPhone
      }}>
        {({ handleSubmit, invalid }) => (
          <form className='container-fluid spacer__bottom--large' onSubmit={handleSubmit}>
            <div className='row'>
              <div className='push-down-very-small center__all--large'>
                <Field
                  name='name'
                  type='text'
                  component={TextInput}
                  placeholder='Company Name'
                  autoComplete='organization'
                  id='organization'
                  validate={required}
                />
              </div>
              <div className='push-down-very-small center__all--large'>
                <Field
                  name='companyPrimaryPhone'
                  component={TextInput}
                  placeholder='Company Phone Number'
                  format={normalizePhone}
                  validate={composeValidators(required, validPhone)}
                />
              </div>
            </div>
            <div className='row'>
              <button
                type='submit'
                className='btn btn-success btn-lg push-down-very-small'
                disabled={invalid}>
                Next
              </button>
            </div>
            <div className='row'>
              <Link to={ROUTES.userProfile} className='btn btn-link push-down-very-small'>
                I already have an account
              </Link>
            </div>
          </form>
        )}
      </Form>
    </div>
  )
}

SignUpStepOne.propTypes = {
  onNext: PropTypes.func.isRequired,
  signUpData: PropTypes.object.isRequired
}
