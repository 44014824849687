import React from 'react'
import PropTypes from 'prop-types'
import ReactSwitch from 'react-switch'

export default function Switch ({ onChange, checked = false, size = 'default' }) {
  if (size === 'small') {
    return (
      <ReactSwitch
        onColor='#89c0bb'
        onHandleColor='#009688'
        handleDiameter={13}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow='0px 2px 2px 0px rgba(0, 0, 0, 0.24)'
        activeBoxShadow='0px 2px 2px 0px rgba(0, 0, 0, 0.2)'
        height={9}
        width={21}
        checked={checked}
        onChange={onChange}
      />
    )
  }

  return (
    <ReactSwitch
      onColor='#D7F7D8'
      onHandleColor='#33CA80'
      handleDiameter={22}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
      activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
      height={15}
      width={35}
      checked={checked}
      onChange={onChange}
    />
  )
}

Switch.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  size: PropTypes.string
}
