import React from 'react'
import PropTypes from 'prop-types'
import { prettyAddress } from '../../utilities/locationUtilities'

export default function HaulerProfileDisplay ({ hauler }) {
  return (
    <div className='list-group'>
      <div className='list-group'>

        {/* Name */}
        <div className='list-group-item'>
          <i className='material-icons large-icon pull-right'>person</i>
          <div className='list-group-item-heading'>Name</div>
          <div className='list-group-item-text'>{hauler?.name}</div>
        </div>

        {/* Address */}
        <div className='list-group-item'>
          <i className='material-icons large-icon pull-right'>location_on</i>
          <div className='list-group-item-heading'>Address</div>
          <div className='list-group-item-text'>{prettyAddress(hauler)}</div>
        </div>

        {/* Phone Number */}
        <div className='list-group-item'>
          <i className='material-icons large-icon pull-right'>phone</i>
          <div className='list-group-item-heading'>Phone Number</div>
          <div className='list-group-item-text'>{hauler?.prettyPrimaryPhone}</div>
        </div>

      </div>
    </div>
  )
}

HaulerProfileDisplay.propTypes = {
  hauler: PropTypes.object.isRequired
}
