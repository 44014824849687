import { loader } from 'graphql.macro'

export default function invoicesQuery ({ dataLoaders, queryKey }) {
  const [,
    haulerId,
    sort,
    invoiceNumber = null,
    status = null,
    createdAtRange = null,
    accountName = null,
    pager = null,
    clientId = null
  ] = queryKey
  // Having to maintain this map is a bit of a pain, but we can't store underscores in the query parameters with the
  // ObjectParam type, so we need this to go from camelCase to our GraphQL enum CAPITALIZED_UNDERSCORE
  const sortColumnEnumMap = {
    invoiceNumber: 'DISPATCHER_INVOICE_NUMBER',
    qboDocNumber: 'QBO_DOC_NUMBER',
    accountName: 'ACCOUNT_NAME',
    createdAt: 'CREATED_AT',
    qboUploadStatus: 'QBO_UPLOAD_STATUS',
    ticketsCount: 'TICKETS_COUNT',
    billingStatus: 'BILLING_STATUS',
    status: 'STATUS'
  }

  const sortColumnEnum = sortColumnEnumMap[sort.column]
  if (!sortColumnEnum) {
    return Promise.reject(new Error(`Invalid sort.column: ${sort.column}`))
  }

  let graphQLVariables = {
    haulerId,
    sortColumn: sortColumnEnum,
    sortDirection: sort.direction.toUpperCase(),
    invoiceNumber,
    createdAtRange,
    accountName,
    clientId,
    status
  }

  if (pager) {
    const paginatedVariables = {
      before: pager.direction === 'before' ? pager.cursor : null,
      after: pager.direction === 'after' ? pager.cursor : null,
      first: pager.direction === 'after' ? Number(pager.pageSize) : null,
      last: pager.direction === 'before' ? Number(pager.pageSize) : null
    }
    graphQLVariables = { ...graphQLVariables, ...paginatedVariables }
  }

  const invoicesSchema = loader('./invoices.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(invoicesSchema, graphQLVariables)
}
