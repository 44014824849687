import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './job-search-table-select.module.scss'

export default function JobSearchTableSelectComponent ({
  searchResults,
  cancelAction,
  setJobID,
  NoJobsFoundDiv
}) {
  const [selectedJob, setSelectedJob] = useState(null)

  function buildName ({ firstName = '', lastName = '' }) {
    const prettyName = [
      firstName,
      lastName
    ]
      .filter((v) => v !== '')
      .join(' ')

    return prettyName
  }

  function formatNumber (num) {
    if (isNaN(num) || Number(num) === 0) {
      return 'None'
    }

    return num
  }

  function handleJobSelect (e) {
    setSelectedJob(Number(e.target.value))
  }

  function submitForm (e) {
    e.preventDefault()
    setJobID(selectedJob)
  }

  return (
    <form name='job-search-results' onSubmit={submitForm}>
      <table className={styles['job-search-table']}>
        <thead>
          <tr>
            <th className={styles['sm-cell']}></th>
            <th className={styles['sm-cell']}>
              ID
            </th>
            <th className={styles['lg-cell']}>
              Street Address
            </th>
            <th>
              Primary Contact
            </th>
            <th className={styles['med-cell']}>
              Open Tickets
            </th>
            <th className={styles['med-cell']}>
              Closed Tickets
            </th>
          </tr>
        </thead>
        <tbody>
          { searchResults.length
            ? searchResults.map(result =>
              (
                <tr key={`search-result-${result.customId}`}>
                  <td className={styles['sm-cell']}>
                    <input
                      aria-label={`Select job with custom id ${result.customId}`}
                      name='job-select'
                      type='radio'
                      onChange={handleJobSelect}
                      required
                      value={result.id}
                    />
                  </td>
                  <td className={styles['sm-cell']}>
                    <a href={`/hauler/jobs/${result.id}`}>{result.customId}</a>
                  </td>
                  <td className={styles['lg-cell']}>{result.addressline1}</td>
                  <td>{buildName(result)}</td>
                  <td className={styles['med-cell']}>{formatNumber(result.activeTicketsCount)}</td>
                  <td className={styles['med-cell']}>{formatNumber(result.terminalTicketsCount)}</td>
                </tr>
              )
            )
            : <tr>
              <td colSpan='5'>
                <div className={styles['no-jobs-found']}>
                  {NoJobsFoundDiv ? <NoJobsFoundDiv /> : null}
                </div>
              </td>
            </tr>
          }
        </tbody>
      </table>

      {searchResults.length
        ? (
          <div className={styles['button-wrapper']}>
            <button className='dis-btn dis-btn-sm dis-btn-primary' disabled={!selectedJob} type='submit'>
              ADD TO SELECTED JOB <i className='material-icons dis-btn-icon'>add_circle</i>
            </button>
            <button className='dis-btn dis-btn-sm dis-btn-blank dis-btn-blank-dk-bg' onClick={cancelAction}>
              CANCEL
            </button>
          </div>
          )
        : (
          <button className={`dis-btn dis-btn-sm dis-btn-blank dis-btn-blank-dk-bg ${styles['no-jobs-found-cancel']}`} onClick={cancelAction}>
            CANCEL
          </button>
          )
      }
    </form>
  )
}

JobSearchTableSelectComponent.propTypes = {
  searchResults: PropTypes.array.isRequired,
  cancelAction: PropTypes.func.isRequired,
  setJobID: PropTypes.func.isRequired,
  NoJobsFoundDiv: PropTypes.func
}
