import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import NormalLayoutContainer from './shared/NormalLayoutContainer'
import { PageTitle } from '../components/page-title'
import HaulerEditForm from '../components/hauler-edit-form'
import HaulerProfileExplanation from '../components/HaulerProfileExplanation'
import useMutation from '../hooks/useMutation'
import updateHauler from '../graphql/mutations/updateHauler'
import notify from '../utilities/notify'
import USER_ACTIONS from '../store/user/userActions'

function HaulerEditPage () {
  const dispatch = useDispatch()
  const history = useHistory()
  const { hauler } = useSelector(({ user: { hauler, user } }) => ({ hauler, user }))

  const { mutate: updateHaulerMutation, isLoading: isUpdatingHauler } = useMutation(updateHauler, {
    onSuccess (updatedHauler) {
      dispatch({ type: USER_ACTIONS.UPDATE_HAULER, payload: { hauler: updatedHauler } })
      notify('success', 'Profile Updated')
      history.push('/hauler')
    },
    onError () {
      notify('error', 'Failed to Update Profile')
    }
  })

  return (
    <NormalLayoutContainer showBackLink>
      <div className='container-fluid'>
        <PageTitle title='Edit Company Profile'>
          <Link className='btn btn-lg btn-link pull-right push-down-very-small' to='/hauler'>
            Cancel
          </Link>
        </PageTitle>

        <div className='row'>
          <div className='col-xs-12 col-sm-12 col-md-7 col-lg-7'>
            <HaulerEditForm
              hauler={hauler}
              onSubmit={updateHaulerMutation}
              submitDisabled={isUpdatingHauler}
            />
          </div>
          <div className='col-xs-12 col-md-4 col-md-offset-1'>
            <HaulerProfileExplanation />
          </div>
        </div>
      </div>
    </NormalLayoutContainer>
  )
}

HaulerEditPage.propTypes = {
}

export default HaulerEditPage
