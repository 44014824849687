/* TODO
  -reset invoice cache after adding ticket
*/
import React, { useEffect, useState, useMemo } from 'react'
import NormalLayoutContainer from './shared/NormalLayoutContainer'
import { ROUTES } from '../routes'
import { generatePath, Link, useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import exportClientToWastepayMutation from '../graphql/mutations/exportClientToWastepay.js'
import exportClientToQboWastepay from '../graphql/mutations/exportClientToQboWastepay.js'
import createInvoiceMutation from '../graphql/mutations/createInvoiceV2'
import addTicketsToInvoice from '../graphql/mutations/addTicketsToInvoice'
import ticketSearchQuery from '../graphql/queries/ticketsSearch'
import QUERY_KEYS from '../graphql/queryKeys'
import { Spinner } from './shared/Spinner'
import notify from '../utilities/notify'
import SelectWPCustomerModal from '../components/select-wp-customer-modal'
import InvoiceableTicketsSearchForm from '../components/invoice/InvoiceableTicketsSearchForm'
import InvoiceableTicketsTable from '../components/invoice/InvoiceableTicketsTable'
import AccountVerifyErrorDisplay from '../components/invoice/AccountVerifyErrorDisplay'
import Modal from '../components/modal/Modal'
import AddTicketToInvoiceConfirmationModal from '../components/add-ticket-to-invoice-confirmation-modal'
import useQuery, { generateQueryKey } from '../hooks/useQuery'
import useMutation from '../hooks/useMutation'
import { captureErrorAndNotify } from '../utilities/errorHandlers'
import clientQuery from '../graphql/queries/clientQuery'
import invoiceQuery from '../graphql/queries/invoice'
import resourceAndTicketTypesQuery from '../graphql/queries/resourceAndTicketTypes'
import { ObjectParam, StringParam, useQueryParams } from 'use-query-params'
import { useQueryClient } from 'react-query'

const pageSize = 25

export default function InvoiceNewClientTicketsPage () {
  const history = useHistory()
  const { user, hauler } = useSelector(({ user, resourceTypes, ticketTypes }) => ({
    user: user.user,
    hauler: user.hauler,
    resourceTypes,
    ticketTypes
  }))
  const [isExportAttempted, setIsExportAttempted] = useState(false)
  const [showAddTicketModal, setShowAddTicketModal] = useState(false)
  const [hasInvoiceDiscount, setHasInvoiceDiscount] = useState(false)
  const [hasInvoiceTax, setHasInvoiceTax] = useState(false)
  const [selectedTickets, setSelectedTickets] = useState([])
  const [clientFromInvoice, setClientFromInvoice] = useState()
  const { clientId, invoiceId } = useParams()
  const [fakeCount, setFakeCount] = useState(null)
  const [showSelectCustomerModal, setShowSelectCustomerModal] = useState(false)
  const [wastepayCustomers, setWastepayCustomers] = useState([])
  const queryClient = useQueryClient()

  const [queryParams, setQueryParams] = useQueryParams({
    sort: ObjectParam,
    pager: ObjectParam,
    maxDate: StringParam,
    minDate: StringParam,
    resourceTypeId: StringParam,
    ticketTypeId: StringParam,
    status: StringParam,
    dispatchSearch: StringParam
  })

  const formParams = {
    maxDate: queryParams.maxDate,
    minDate: queryParams.minDate,
    resourceTypeId: queryParams.resourceTypeId,
    ticketTypeId: queryParams.ticketTypeId,
    status: queryParams.status,
    dispatchSearch: queryParams.dispatchSearch
  }

  const { data: { client }, isFetching: isFetchingClient, refetch: refetchClient } = useQuery(
    [QUERY_KEYS.client, clientId || clientFromInvoice],
    clientQuery,
    {
      enabled: Boolean(clientId) || Boolean(clientFromInvoice),
      placeholderData: { client: undefined },
      onError (error) {
        captureErrorAndNotify(error, 'Failed to fetch account')
      }
    }
  )

  const { data: { invoice }, isFetching: isFetchingInvoice } = useQuery(
    [QUERY_KEYS.invoice, invoiceId],
    invoiceQuery,
    {
      enabled: Boolean(invoiceId),
      placeholderData: { invoice: undefined },
      onSuccess ({ invoice }) {
        setClientFromInvoice(invoice.client.id)
        // TODO Get these coming back from the database. Should just uncomment the stuff
        // setHasInvoiceDiscount(Boolean(invoice?.discountAndTax?.discountAmount))
        // setHasInvoiceTax(Boolean(invoice?.discountAndTax?.taxAmount))
        // hardcoded for now until the discount and tax stuff is added to the invoice itself
        setHasInvoiceDiscount(true)
        setHasInvoiceTax(true)
      },
      onError (error) {
        captureErrorAndNotify(error, 'Failed to fetch account')
      }
    }
  )
  const wpClientExportNeeded = !client?.wastepayCustomerId && hauler.isWastepayConnected
  const qboClientExportNeeded = !client?.qboCustomerId && hauler.quickbooks.isConnected
  const manualExportNeeded = useMemo(() => {
    if (isExportAttempted) {
      return false
    }
    return (!hauler.quickbooks.shouldAutoImportCustomers && qboClientExportNeeded) || wpClientExportNeeded
  }, [isExportAttempted, qboClientExportNeeded, wpClientExportNeeded, hauler])

  const { data: types, isFetching: isFetchingTypes } = useQuery(
    [QUERY_KEYS.resourceAndTicketTypes, { haulerId: hauler.id, status: 'ENABLED' }],
    resourceAndTicketTypesQuery,
    {
      enabled: !manualExportNeeded,
      onError (error) {
        captureErrorAndNotify(error, 'Failed to fetch asset/ticket types')
      }
    }
  )

  const { data: invoiceableTickets, isFetching: isFetchingTickets } = useQuery([
    QUERY_KEYS.ticketsSearch,
    hauler.id,
    pageSize,
    queryParams.sort,
    {
      clientId: clientId || clientFromInvoice,
      invoiceable: 'true',
      ...formParams
    },
    queryParams.pager
  ],
  ticketSearchQuery,
  {
    enabled: (Boolean(clientId) || Boolean(clientFromInvoice)) && Boolean(queryParams.sort) && !manualExportNeeded,
    onError (error) {
      captureErrorAndNotify(error, 'Unable to fetch tickets')
    }
  })

  const { mutate: exportClientToWastePay, isLoading: isExportingClientToWastePay } = useMutation(exportClientToWastepayMutation, {
    onSuccess (data) {
      const { wastepayCustomers } = data
      if (wastepayCustomers != null) {
        return setWastepayCustomers(wastepayCustomers)
      }
      setWastepayCustomers([])
      notify('success', `Account ${invoice?.client?.name || ''} was exported to selected systems.`)
      setIsExportAttempted(true)
    },
    onError () {
      notify('error', 'There was a problem exporting this account')
    }
  })

  const { mutate: exportClient, isLoading: isExportingClient } = useMutation(exportClientToQboWastepay, {
    onSuccess (data) {
      const newClientData = {
        ...(data.exportClientToQuickbooks?.client || {}),
        ...(data.exportClientToWastepay?.client || {}),
        ...(data?.updateClient?.client?.didPromptClientExportSelection || {})
      }
      if (data.exportClientToWastepay?.wastepayCustomers != null) {
        return setWastepayCustomers(data.exportClientToWastepay?.wastepayCustomers)
      }
      setIsExportAttempted(true)
      queryClient.setQueryData(generateQueryKey([QUERY_KEYS.client, clientId], user.id), (oldData) => ({
        ...oldData,
        client: { ...oldData.client, ...newClientData }
      }))
      notify('success', `Account ${client.name} was exported to selected systems.`)
    },
    onError (error) {
      // In this case re-fetching is our best option, the mutation could have partially completed leaving us in a weird state without this.
      refetchClient()
      captureErrorAndNotify(error, 'There was a problem exporting this account.')
    }
  })
  const { mutate: createInvoice, isLoading: isCreatingInvoice } = useMutation(createInvoiceMutation, {
    onSuccess ({ invoice }) {
      history.push({
        pathname: `${generatePath(ROUTES.invoiceEdit, { id: invoice?.id })}`,
        state: { multiTicket: true }
      })
    },
    onError (error) {
      captureErrorAndNotify(error, 'Unable to create invoice')
    }
  })
  const { mutate: addTicketsMutation, isLoading: isAddingTickets } = useMutation(addTicketsToInvoice, {
    onSuccess ({ invoice }) {
      setShowAddTicketModal(false)
      notify('success', `${selectedTickets.length} ticket(s) added to invoice ${invoice.dispatcherInvoiceNumber}`)
      history.push({
        pathname: `${generatePath(ROUTES.invoiceEdit, { id: invoice?.id })}`,
        state: { multiTicket: true }
      })
    },
    onError (error) {
      setShowAddTicketModal(false)
      captureErrorAndNotify(error, 'Unable to add tickets to invoice')
    }
  })

  useEffect(function toggleCustomerSelectionModal () {
    setShowSelectCustomerModal(wastepayCustomers.length !== 0)
  }, [wastepayCustomers, setShowSelectCustomerModal])

  useEffect(function autoExportClientToQBO () {
    if (
      !hauler.quickbooks.isConnected ||
      !hauler.quickbooks.shouldAutoImportCustomers ||
      client === null ||
      client.existsInQbo === true
    ) return

    exportClient({ id: clientId, exportToQuickBooks: true, exportToWastepay: false, haulerId: hauler.id })
  }, [client, hauler.quickbooks.isConnected, hauler.quickbooks.shouldAutoImportCustomers, hauler.id, exportClient, clientId])

  useEffect(function setDefaultQueryParams () {
    const defaultQueryParams = {}
    if (!queryParams.sort) {
      defaultQueryParams.sort = { column: 'date', direction: 'desc' }
    }
    setQueryParams(defaultQueryParams)
  }, [queryParams, setQueryParams])

  useEffect(function toggleFakePagination () {
    if (!invoiceableTickets || isFetchingTickets || fakeCount !== null) return
    setFakeCount({ start: 1, end: Math.min(pageSize, invoiceableTickets.ticketsSearch.totalCount) })
  }, [invoiceableTickets, isFetchingTickets, fakeCount, setFakeCount])

  function selectTicket (ticket) {
    const tickets = new Set(Array.from(selectedTickets))
    if (tickets.has(ticket)) {
      tickets.delete(ticket)
      setSelectedTickets([...tickets])
      return
    }
    tickets.add(ticket)
    setSelectedTickets([...tickets])
  }

  function onSortChange (newSortColumn) {
    let newSortDirection = 'desc'
    if (newSortColumn === queryParams.sort.column) {
      newSortDirection = queryParams.sort.direction === 'asc' ? 'desc' : 'asc'
    }

    setFakeCount(null)
    setQueryParams({ sort: { column: newSortColumn, direction: newSortDirection } })
  }

  function onPageRequest (direction, cursor) {
    setQueryParams({ pager: { cursor, direction, pageSize } })

    if (!fakeCount) return
    if (direction === 'before') {
      setFakeCount(prevFakeCount => ({ start: prevFakeCount.start - pageSize, end: prevFakeCount.end - pageSize }))
    }
    if (direction === 'after') {
      setFakeCount(prevFakeCount => ({ start: prevFakeCount.start + pageSize, end: prevFakeCount.end + pageSize }))
    }
  }

  function confirmInvoice () {
    const ticketIds = selectedTickets.map(t => t.id).join(',')
    createInvoice({ clientId, haulerId: hauler.id, ticketIds })
  }

  function handleSearch (data) {
    setFakeCount(null)
    setQueryParams({
      maxDate: data.maxDate,
      minDate: data.minDate,
      resourceTypeId: data.resourceTypeId,
      ticketTypeId: data.ticketTypeId,
      status: data.status,
      dispatchSearch: data.dispatchSearch
    })
  }

  function getClientTitle (client, invoice) {
    if (invoiceId) {
      return `Add to Invoice ${invoice.dispatcherInvoiceNumber}: Select Tickets for ${client.name}`
    }
    return `New Invoice: Select Tickets for ${client.name}`
  }

  function addToInvoice () {
    const { ticketDiscounts, ticketTaxes } = selectedTickets.reduce((acc, ticket) => {
      if (ticket.ticketFeesCount === 0) {
        return acc
      }
      if (ticket.discountAndTax?.discountAmount != null && ticket.discountAndTax?.discountAmount !== 0) {
        acc.ticketDiscounts.push(ticket)
      }
      if (ticket.discountAndTax?.taxAmount != null && ticket.discountAndTax?.taxAmount !== 0) {
        acc.ticketTaxes.push(ticket)
      }
      return acc
    }, { ticketDiscounts: [], ticketTaxes: [] })

    if ((hasInvoiceDiscount && ticketDiscounts.length > 0) || (hasInvoiceTax && ticketTaxes.length > 0)) {
      setShowAddTicketModal(true)
      return
    }

    addTicketsMutation({ invoiceID: invoice.id, tickets: selectedTickets.map(s => s.id) })
  }

  function onCancel () {
    setShowAddTicketModal(false)
  }

  function onConfirm () {
    addTicketsMutation({ invoiceID: invoice.id, tickets: selectedTickets.map(s => s.id) })
  }

  function handleExportToWP (clientId, { wpCustomerId = null, forceCreate = null } = {}) {
    const exportArguments = {
      id: clientId
    }
    if (wpCustomerId != null) {
      exportArguments.customerId = wpCustomerId
    }
    if (forceCreate != null) {
      exportArguments.forceCreate = true
    }
    exportClientToWastePay(exportArguments)
  }

  return (
    <NormalLayoutContainer>
      {isExportingClient || isFetchingClient || isCreatingInvoice || isFetchingInvoice
        ? <Spinner isFetching />
        : (<>
          <SelectWPCustomerModal
            closeModal={() => setWastepayCustomers([])}
            isOpen={showSelectCustomerModal}
            wastepayCustomers= {wastepayCustomers}
            handleSubmit={handleExportToWP}
            submitting={isExportingClientToWastePay}
            clientId = {client.id}
            resetCustomers={() => setWastepayCustomers([])}
          />
          <Modal isOpen={showAddTicketModal}>
            <AddTicketToInvoiceConfirmationModal
              onCancel={onCancel}
              onConfirm={onConfirm}
              hasInvoiceDiscount={hasInvoiceDiscount}
              hasInvoiceTax={hasInvoiceTax}
              isSaving={isAddingTickets}
            />
          </Modal>
          {manualExportNeeded
            ? (
              <AccountVerifyErrorDisplay
                className='mt-8'
                client={client}
                onExport={exportClient}
                qboClientExportNeeded={qboClientExportNeeded}
                wpClientExportNeeded={wpClientExportNeeded}
              />
              )
            : (<>
              <div className='flex items-center mt-8'>
                <Link to={ROUTES.invoiceNewClient} className='btn btn-lg btn-link'>
                  <i className='material-icons back-button-icon'>arrow_back</i>
                </Link>
                <h1 className='text-5xl m-0'>
                  {client
                    ? getClientTitle(client, invoice)
                    : 'New Invoice: Select Tickets'
                    }
                </h1>
              </div>
              <div className='mt-8'>
                <div className='panel panel-default'>
                  <div className='panel-body'>
                    {isFetchingTypes
                      ? <Spinner isFetching />
                      : (<InvoiceableTicketsSearchForm
                          initialValues={formParams}
                          onSubmit={handleSearch}
                          resourceTypes={types.resourceTypes}
                          ticketTypes={types.ticketTypes}
                          disabled={isFetchingTickets}
                         />)
                    }
                  </div>
                </div>
              </div>

              <div className='panel panel-default'>
                <div className='panel-heading'>
                  <div className='container-fluid row'>
                    <div>
                      {
                        clientId
                          ? (
                            <button
                              className='btn btn-primary btn-lg pull-right'
                              disabled={selectedTickets.length <= 0}
                              onClick={confirmInvoice}>
                              Create Invoice
                            </button>
                            )
                          : (
                            <button
                              className='btn btn-primary btn-lg pull-right'
                              disabled={selectedTickets.length <= 0}
                              onClick={addToInvoice}>
                              Add to Invoice
                            </button>
                            )
                      }
                    </div>
                  </div>
                </div>
                {isFetchingTickets || !queryParams.sort
                  ? <Spinner isFetching />
                  : (
                    <div className='panel-body'>
                      <InvoiceableTicketsTable
                        selectTicket={selectTicket}
                        selectedTickets={selectedTickets}
                        onSortChange={onSortChange}
                        onPageRequest={onPageRequest}
                        tickets={invoiceableTickets.ticketsSearch}
                        fakeCount={fakeCount}
                        sort={queryParams.sort}
                      />
                    </div>
                    )
                }
              </div>
            </>)
          }
        </>)
      }

    </NormalLayoutContainer>
  )
}
