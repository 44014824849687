import { loader } from 'graphql.macro'

export default async function createResourceType ({ dataLoaders, variables }) {
  const schema = loader('./createResourceType.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      defaultBillingStatus: variables.defaultBillingStatus,
      name: variables.name,
      shortCode: variables.shortCode
    }
  })
  return response.createResourceType
}
