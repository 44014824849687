import { loader } from 'graphql.macro'

export default async function addTicketsToInvoice ({ dataLoaders, variables }) {
  const schema = loader('./addTicketsToInvoice.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      invoiceId: variables.invoiceID,
      tickets: variables.tickets
    }
  })
  return response.addTicketsToInvoice
}
