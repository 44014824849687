import React from 'react'
import PropTypes from 'prop-types'

const InnerDropzone = ({ inProgress, isDragActive }) => (
  <div className='text-center flex flex-col justify-center items-center h-full mt-2'>
    <i className='material-icons text-7xl text-gray-med-dark opacity-80'>upload_file</i>
    { inProgress
      ? <p>Uploading file...</p>
      : isDragActive
        ? <p>Drop the CSV file here...</p>
        : <p>Drag and drop or <span className='text-blue'>browse</span> your file</p>

    }
  </div>
)

InnerDropzone.propTypes = {
  inProgress: PropTypes.bool.isRequired,
  isDragActive: PropTypes.bool.isRequired
}
export default React.memo(InnerDropzone)
