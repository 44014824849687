import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import styles from './index.module.scss'
import { Form, Field } from 'react-final-form'
import { CheckboxInput } from '../CheckboxInput'

export default function AccountVerifyErrorDisplay ({
  client,
  onExport,
  className,
  qboClientExportNeeded,
  wpClientExportNeeded
}) {
  const { hauler } = useSelector(({ user }) => ({ user: user.user, hauler: user.hauler }))
  function exportMessaging () {
    if (qboClientExportNeeded && wpClientExportNeeded) {
      return ({
        message: ' to QuickBooks Online and WastePay to invoice their tickets.'
      })
    }
    if (wpClientExportNeeded) {
      return ({
        message: ' to WastePay to invoice their tickets.'
      })
    }
    return ({
      message: ' to QuickBooks Online to invoice their tickets.'
    })
  }

  function handleOnExport ({ exportToQuickBooks, exportToWastepay, dontAskAgain }) {
    if (exportToQuickBooks) {
      const result = window.confirm(
        'NOTICE: \n\nIf this account already exists in QuickBooks, ' +
            'please make sure the account name matches the customer name ' +
            'EXACTLY in Quickbooks or duplicate customers will be created.'
      )
      if (result) {
        return onExport({ id: client.id, haulerId: hauler.id, exportToQuickBooks, exportToWastepay, dontAskAgain })
      }
    } else {
      return onExport({ id: client.id, haulerId: hauler.id, exportToQuickBooks, exportToWastepay, dontAskAgain })
    }
  }

  const messaging = exportMessaging()
  return (
    <div className={cn(className, 'row')}>
      <div className='panel panel-default'>
        <div className='panel-body'>
          <div className='text-center'>
            <h3 className='text-warning text-center'>
              Invoicing Action Required
            </h3>
          </div>
          <hr />
          <h5>
            {'Export '}
            <strong>{`Account #${client.customId} - ${client.name}`}</strong>
            {messaging.message}
          </h5>

          <Form
            onSubmit={handleOnExport}
            initialValues={{ exportToQuickBooks: qboClientExportNeeded, exportToWastepay: wpClientExportNeeded, dontAskAgain: false }}
            className={cn(styles.formRow)}
            render={
              ({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div className={cn(styles.formRow)}>
                    {qboClientExportNeeded && (
                      <Field
                        type='checkbox'
                        name='exportToQuickBooks'
                        label='Export to QBO'
                        component={CheckboxInput}
                      />
                    )}
                    {wpClientExportNeeded && (
                      <Field
                        type='checkbox'
                        name='exportToWastepay'
                        label='Export to Wastepay'
                        component={CheckboxInput}
                      />
                    )}
                  </div>
                  <button
                    type='submit'
                    className='btn dis-btn-primary'>
                    Export
                  </button>
                  <div className={cn(styles.formRow, styles.doNotPromptInput)}>
                    <Field
                      type='checkbox'
                      name='dontAskAgain'
                      label={`Do Not Prompt Where to Export Account #${client.customId} - ${client.name} Again`}
                      component={CheckboxInput}
                    />
                  </div>
                </form>)
              }
          />
        </div>
      </div>
    </div>
  )
}

AccountVerifyErrorDisplay.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.string.isRequired,
    customId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    existsInQbo: PropTypes.bool.isRequired,
    wastepayEnabled: PropTypes.bool.isRequired
  }).isRequired,
  onExport: PropTypes.func.isRequired,
  className: PropTypes.string,
  qboClientExportNeeded: PropTypes.bool.isRequired,
  wpClientExportNeeded: PropTypes.bool.isRequired
}
