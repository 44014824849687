import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'

export default function QBOFeeTypeDescriptionModal ({
  cancel,
  handleContinue,
  index,
  mismatchedDescriptions,
  setMismatchedDescriptions
}) {
  const descriptions = mismatchedDescriptions[index]

  function handleChange (value) {
    const updatedDescriptions = [...mismatchedDescriptions]
    updatedDescriptions[index].mismatchedDescriptionAction = value
    setMismatchedDescriptions(updatedDescriptions)
  }

  return (<div className={cn('dis-panel', styles.modalContainer)}>
    <h4 className={styles.modalHeader}>Descriptions Don’t Match</h4>
    <div className={styles.modalExplanation}>
      The fee type description in Dispatcher doesn’t match the product description in QuickBooks. Choose an option below to continue:
    </div>
    <hr />
    <div className={styles.compareDescriptions}>
      <div className={styles.feeTypeSection}>
        <h5>Dispatcher Fee Type</h5>
        <div className={styles.feeTypeName}>{descriptions.feeTypeName}</div>
        <div>{descriptions.feeTypeDescription}</div>
      </div>
      <div className={styles.feeTypeSection}>
        <h5>QuickBooks Product</h5>
        <div className={styles.feeTypeName}>{descriptions.qboName}</div>
        <div>{descriptions.qboDescription}</div>
      </div>
    </div>
    <hr />
    <form className={styles.feeTypeForm} onSubmit={handleContinue}>
      <label className={styles.feeTypeFormLabel}>
        <input
          className={styles.radioInput}
          type='radio'
          name='handleQBODescription'
          value='UPDATE_DISPATCHER'
          onChange={e => handleChange(e.currentTarget.value)}
          checked={descriptions.mismatchedDescriptionAction === 'UPDATE_DISPATCHER'}
        />
        <span>Update Dispatcher with QuickBooks product description</span>
      </label>
      <label className={styles.feeTypeFormLabel}>
        <input
          className={styles.radioInput}
          type='radio'
          name='handleQBODescription'
          value='UPDATE_QBO'
          onChange={e => handleChange(e.currentTarget.value)}
          checked={descriptions.mismatchedDescriptionAction === 'UPDATE_QBO'}
        />
        <span>Update QuickBooks with Dispatcher fee type description</span>
      </label>
      <label className={styles.feeTypeFormLabel}>
        <input
          className={styles.radioInput}
          type='radio'
          name='handleQBODescription'
          value='KEEP_DESCRIPTIONS'
          onChange={e => handleChange(e.currentTarget.value)}
          checked={descriptions.mismatchedDescriptionAction === 'KEEP_DESCRIPTIONS'}
        />
        <span>Keep Dispatcher and QuickBooks descriptions different</span>
      </label>
      <div className={styles.buttonContainer}>
        <button
          type='button'
          className='dis-btn dis-btn-blank dis-btn-lg'
          onClick={cancel}>
          Cancel
        </button>
        <button
          disabled={!descriptions.mismatchedDescriptionAction}
          type='submit'
          className={cn('dis-btn dis-btn-primary dis-btn-lg', styles.continueButton)}>
          Continue
        </button>
      </div>
    </form>
  </div>)
}

QBOFeeTypeDescriptionModal.propTypes = {
  cancel: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  mismatchedDescriptions: PropTypes.array,
  handleContinue: PropTypes.func.isRequired,
  setMismatchedDescriptions: PropTypes.func.isRequired
}
