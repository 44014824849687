import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'react-bootstrap/lib/Tooltip'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'

export default function NotesCell ({ notes }) {
  if (!notes) return <span />

  const tooltip = (
    <Tooltip id='tooltip'>{notes}</Tooltip>
  )

  return (
    <OverlayTrigger placement='top' overlay={tooltip}>
      <i className='material-icons'>speaker_notes</i>
    </OverlayTrigger>
  )
}

NotesCell.propTypes = {
  notes: PropTypes.string
}
