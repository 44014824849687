import React from 'react'
import PropTypes from 'prop-types'
import classNamePropTypes from '../utilities/classNamePropTypes'

export function RadioInput ({
  radioVal,
  label,
  input,
  classNames
}) {
  function formatVal (val) {
    if (val.toLowerCase() === 'true') {
      return true
    }
    if (val.toLowerCase() === 'false') {
      return false
    }
    return val
  }

  function onChange (val) {
    input.onChange(formatVal(val))
  }

  return (
    <label className={classNames}>
      <input
        type='radio'
        value={radioVal}
        checked={(radioVal === input.value)}
        onChange={e => onChange(e.currentTarget.value)}
      />
      <span>{label}</span>
    </label>
  )
}

RadioInput.propTypes = {
  radioVal: PropTypes.any,
  label: PropTypes.string,
  input: PropTypes.any,
  classNames: classNamePropTypes
}
