import React from 'react'
import PropTypes from 'prop-types'
import { arrayMove, List } from 'react-movable'
import { DriverListSortableItem } from './DriverListSortableItem'

export default function DriverListSortableList ({
  userList,
  setUserList,
  viewHidden
}) {
  function handleChange ({ oldIndex, newIndex }) {
    if ((oldIndex !== newIndex) && !userList[newIndex].isHidden) {
      setUserList(arrayMove(userList, oldIndex, newIndex))
    }
  }

  return (
    <List
      lockVertically='true'
      values={userList}
      onChange={handleChange}
      renderList={({ children, props }) => (
        <ul {...props} className='driver-sort_sort-list flex flex-col w-full overflow-x-auto mb-6'>
          {children}
        </ul>
      )}
      renderItem={({ value, index, props, isDragged }) => (
        <li {...props} className='driver-sort_sortable-item list-none'>
          <DriverListSortableItem
            value={value}
            index={index}
            isDragged={isDragged}
            setUserList={setUserList}
            userList={userList}
            viewHidden={viewHidden}
          />
        </li>
      )}
    />
  )
}

DriverListSortableList.propTypes = {
  userList: PropTypes.array.isRequired,
  setUserList: PropTypes.func.isRequired,
  viewHidden: PropTypes.bool.isRequired
}
