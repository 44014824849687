import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import useQuery from '../../../hooks/useQuery'
import { useSelector } from 'react-redux'
import { jobsTableQuery } from '../../../graphql/queries/jobs'
import debounce from 'lodash/debounce'
import CursorPagination from '../../cursor-pagination/enhanced'
import notify from '../../../utilities/notify'
import QUERY_KEYS from '../../../graphql/queryKeys'
import styles from './job-search-form.module.scss'

export default function JobSearchFormComponent ({
  initialSearchParams = {
    streetAddress: null,
    clientID: null,
    customID: null,
    status: null
  },
  hiddenFields = [],
  setSearchResults,
  autoSubmit = false,
  children = null,
  onlyShowResults = false
}) {
  const [searchInputs, setSearchInputs] = useState({
    customId: initialSearchParams?.customID,
    streetAddress: initialSearchParams?.streetAddress,
    clientId: initialSearchParams?.clientID,
    status: initialSearchParams?.status
  })
  const [resultsCount, setResultsCount] = useState(0)
  const [paginationPageInfo, setPaginationPageInfo] = useState({})
  const [paginationReset, setPaginationReset] = useState(false)
  const [paginationSearch, setPaginationSearch] = useState({
    cursor: null,
    direction: 'after'
  })

  const {
    status: jobStatus,
    streetAddress,
    clientID,
    customID
  } = initialSearchParams
  const pageSize = 10

  const { user } = useSelector(({ user: { user } }) => ({ user }))

  useQuery([
    QUERY_KEYS.invoices,
    user.haulerId,
    'CUSTOM_ID',
    'DESC',
    searchInputs,
    {
      pageSize,
      cursor: paginationSearch.cursor,
      direction: paginationSearch.direction
    }
  ],
  jobsTableQuery,
  {
    enabled: true,
    onError: (error) => {
      console.error(error)
      notify('error', 'Unable to retrieve jobs')
    },
    keepPreviousData: true,
    onSuccess: ({ jobs }) => {
      const { nodes, totalCount, pageInfo } = jobs
      setPaginationPageInfo(pageInfo)
      setResultsCount(totalCount)
      setSearchResults(nodes)
      setPaginationReset(false)
    }
  })

  function isHidden (field) {
    return hiddenFields.some(v => v.toLowerCase() === field.toLowerCase())
  }

  function updateInputs () {
    if (autoSubmit) {
      debounce(() => submitForm(), 500)()
    }
  }

  function resetPagination () {
    setPaginationSearch({
      cursor: null,
      direction: 'after'
    })
    setPaginationReset(true)
  }

  function submitForm (e) {
    const daForm = document.forms['job-search-form']
    const formElements = daForm.elements
    e?.preventDefault()
    resetPagination()
    setSearchInputs({
      customId: formElements.customID?.value || customID,
      streetAddress: formElements.streetAddress?.value || streetAddress,
      clientId: formElements.clientID?.value || clientID,
      status: formElements.jobStatus?.value || jobStatus
    })
  }

  return (
    <>
      <form
        name='job-search-form'
        className={cn(styles['job-search-form'], onlyShowResults && styles.hidden)}
        onSubmit={submitForm}>
        <div className={styles['form-fields']}>
          { !isHidden('customID') &&
            <label>
              Job ID
              <input name='customID' defaultValue={customID} type='text' onKeyUp={updateInputs} />
            </label>
          }
          { !isHidden('streetAddress') &&
            <label>
              Street Address
              <input name='streetAddress' type='text' defaultValue={streetAddress} onKeyUp={updateInputs} />
            </label>
          }
          { !isHidden('clientID') &&
            <label>
              Account Name
              {/* this will be an autocomplete at some point */}
              <input name='clientID' type='text' defaultValue={clientID} onKeyUp={updateInputs} />
            </label>
          }
          { !isHidden('jobStatus') &&
            <label className={styles['select-box']}>
              Job Status
              <select defaultValue={jobStatus} name='jobStatus' onChange={updateInputs}>
                <option value=''></option>
                <option value='open'>Open</option>
                <option value='closed'>Closed</option>
              </select>
            </label>
          }
        </div>
        {!autoSubmit &&
          <button className='dis-button dis-button-primary' type='submit'>Submit!</button>
        }
      </form>
      {children}
      { resultsCount > pageSize &&
        <CursorPagination
          totalCount={resultsCount}
          pageInfo={paginationPageInfo}
          setSearchFor={setPaginationSearch}
          resetPagination={paginationReset}
          pageSize={pageSize}
        />
      }
    </>
  )
}

JobSearchFormComponent.propTypes = {
  initialSearchParams: PropTypes.shape({
    customID: PropTypes.number,
    clientID: PropTypes.number,
    status: PropTypes.string,
    streetAddress: PropTypes.string
  }),
  hiddenFields: PropTypes.array,
  setSearchResults: PropTypes.func.isRequired,
  children: PropTypes.object,
  autoSubmit: PropTypes.bool,
  onlyShowResults: PropTypes.bool
}
