import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'
import FormGroupAdapter from '../form-group-adaptor'
import styles from './index.module.scss'
import cn from 'classnames'

export default function InvoiceForm ({
  hauler
}) {
  return (
    <div className={styles.receiptSection}>

      { hauler.isWastepayConnected && (
        <div>
          <Field
            type='checkbox'
            name='includePayNowLink'
            component={FormGroupAdapter}
          />
          <label className={styles.checkBoxLabel} htmlFor='includePayNowLink'>Include Pay Now button</label>
        </div>
      )}

      <div className='form-group push-down-super-small'>
        <Field type='text' name='invoiceEmailSubject'>
          {({ input, meta }) => {
            const hasError = (meta) => {
              return meta.touched && meta.error && !meta.active
            }
            return (
              <div className={cn({ 'has-error': hasError(meta) })}>
                <label className='control-label' htmlFor='invoiceEmailSubject'>Email Subject Line</label>
                <div className={styles.subjectLine}>
                  <FormGroupAdapter input={input} />
                  <div className={styles.subjectLineSuffix}> - #[DXXXXX]</div>
                </div>
                {hasError(meta) && <h6>{meta.error}</h6>}
              </div>
            )
          }}
        </Field>
      </div>

      <div className='form-group'>
        <label className='control-label' htmlFor='invoiceEmailMessage'>Email Message (Optional)</label>
        <Field
          type='textArea'
          name='invoiceEmailMessage'
          component={FormGroupAdapter}
        />
      </div>
    </div>
  )
}

InvoiceForm.propTypes = {
  hauler: PropTypes.shape({
    isWastepayConnected: PropTypes.bool.isRequired
  }).isRequired
}
