import React, { useEffect, useCallback } from 'react'
import bowser from 'bowser'
import UnsupportedBrowserPage from './pages/UnsupportedBrowserPage'
import { useDispatch, useSelector } from 'react-redux'
import jwtDecode from 'jwt-decode'
import { Spinner } from './pages/shared/Spinner'
import USER_ACTIONS from './store/user/userActions'
import useQuery from './hooks/useQuery'
import QUERY_KEYS from './graphql/queryKeys'
import userQuery from './graphql/queries/user'
import notify from './utilities/notify'
import Alert from './pages/shared/Alert'
import { intercomConnection } from './intercomConnection'
import Router from './Router'
import { useLocation } from 'react-router-dom'
import PUSHER_ACTIONS from './store/pusher/pusherActions'
import Pusher from 'pusher-js'
import { useChannelEvent } from './hooks/pusher'

export default function App () {
  const dispatch = useDispatch()
  const location = useLocation()
  const { user, hauler, accessToken, pusher, pusherUserId, pusherHaulerId, jwt, userChannel } = useSelector(({ user, pusher, refreshToken }) => ({
    user: user.user,
    jwt: user.jwt,
    hauler: user.hauler,
    accessToken: user.accessToken,
    pusher: pusher.pusher,
    pusherUserId: pusher.userId,
    pusherHaulerId: pusher.haulerId,
    userChannel: pusher.userChannel,
    refreshToken
  }))

  const { error: userError, isLoading } = useQuery(
    [QUERY_KEYS.user, jwt], // The query will automatically update when the jwt changes when including it in the query key
    userQuery,
    {
      onSuccess ({ user }) {
        dispatch({ type: USER_ACTIONS.SET_USER, payload: { user } })
      },
      onError () {
        notify('error', 'Failed to retrieve user')
      }
    }
  )

  useEffect(function onInitialRender () {
    if (window.Storage === undefined) return

    const auth = window.localStorage.getItem('auth')
    if (auth === null) {
      dispatch({ type: USER_ACTIONS.CLEAR_USER })
    } else {
      const parsedAuth = JSON.parse(auth)
      const decodedJwt = jwtDecode(parsedAuth.access_token)
      dispatch({
        type: USER_ACTIONS.SET_JWT,
        payload: {
          jwt: decodedJwt,
          accessToken: parsedAuth.access_token,
          refreshToken: parsedAuth.refresh_token
        }
      })
    }

    // Keep in mind this event only fires in tabs that is not the current tab, i.e. if a user clicks on a button that
    // clears local storage then only other tabs with the application will have this event fire, not the tab where the
    // user clicked the button
    window.addEventListener('storage', (e) => {
      const clearedLocalStorage = e.key === '' || e.key === null
      if (clearedLocalStorage) {
        dispatch({ type: USER_ACTIONS.CLEAR_USER })
        return
      }

      if (e.key !== 'auth') return
      if (e.newValue === null) {
        dispatch({ type: USER_ACTIONS.CLEAR_USER })
        return
      }

      const parsedAuth = JSON.parse(e.newValue)
      const decodedJwt = jwtDecode(parsedAuth.access_token)
      dispatch({
        type: USER_ACTIONS.SET_JWT,
        payload: {
          jwt: decodedJwt,
          accessToken: parsedAuth.access_token,
          refreshToken: parsedAuth.refresh_token
        }
      })
    })
  }, [dispatch])

  useEffect(function managePusher () {
    if (!user?.id) return
    if (pusher && pusherUserId && pusherHaulerId && pusherUserId !== user?.id) {
      pusher.unsubscribe(`private-user-${pusherUserId}`)
      pusher.unsubscribe(`private-hauler-${pusherHaulerId}`)
      pusher.disconnect()
    }

    if (pusher && !user) {
      dispatch({ type: PUSHER_ACTIONS.SET_PUSHER, payload: { pusher: undefined } })
      return
    }

    if (pusher || pusherUserId === user?.id) return

    const newPusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      userAuthentication: {
        endpoint: `${process.env.REACT_APP_NEW_API_URL}/pusher/auth`,
        transport: 'ajax',
        params: {},
        headers: {
          authorization: `Bearer ${accessToken}`
        },
        customHandler: null,
        useTLS: process.env.NODE_ENV === 'production'
      },
      channelAuthorization: {
        endpoint: `${process.env.REACT_APP_NEW_API_URL}/pusher/channel_auth`,
        headers: {
          authorization: `Bearer ${accessToken}`
        },
        useTLS: process.env.NODE_ENV === 'production'
      }
    })
    dispatch({
      type: PUSHER_ACTIONS.SET_PUSHER,
      payload: {
        pusher: newPusher
      }
    })
    newPusher.signin()
    newPusher.connection.bind('connected', function onPusherConnection () {
      dispatch({
        type: PUSHER_ACTIONS.SET_CHANNELS,
        payload: {
          haulerChannel: newPusher.subscribe(`private-hauler-${user.haulerId}`),
          userChannel: newPusher.subscribe(`private-user-${user.id}`),
          userId: user.id,
          haulerId: user.haulerId
        }
      })
    })
  }, [dispatch, accessToken, user, pusher, pusherUserId, pusherHaulerId])

  useEffect(function configureGoogleTracking () {
    window.gtag('config', 'G-65H0DRG9ZD', {
      user_id: user?.id
    })
  }, [user])

  useEffect(function setupIntercom () {
    if (!user || !hauler) {
      intercomConnection.disconnectFromIntercom()
      return
    }

    const userFullName = `${user.firstName} ${user.lastName}`
    intercomConnection.setUserId(user.id, user.haulerId, hauler.name, userFullName)
  }, [hauler, user])

  useEffect(function onRouteChange () {
    if (!user) return

    window.gtag('event', 'User_Pageview',
      {
        User_ID: user.id,
        Timestamp: new Date().toISOString(),
        Page_URL: location.pathname
      })
  }, [location.pathname, user])

  const logout = useCallback(() => {
    localStorage.removeItem('auth')
    dispatch({ type: USER_ACTIONS.CLEAR_USER })
    notify('error', 'You no longer have access. Please contact one of your account admins.')
  }, [dispatch])

  useChannelEvent(userChannel, 'user-disabled', logout)

  const isSupportedBrowser = !bowser.msie
  if (!isSupportedBrowser) {
    return <UnsupportedBrowserPage />
  }

  if (window.Storage === undefined) {
    window.alert('Your browser does not support localStorage. This is a common issue if you\'re in Private Mode')
    return null
  }

  if (userError) {
    return null
  }

  return (
    <div>
      {
        (user !== undefined && !isLoading)
          ? <Router />
          : <Spinner isFetching />
      }
      <Alert />
    </div>
  )
}
