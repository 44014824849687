import React from 'react'
import PropTypes from 'prop-types'
import styles from './fix-request.module.scss'
import { formatPhoneNumber } from '../../../utilities/stringUtilities'

export default function FixRequestContactWindow ({
  request
}) {
  function prettyContact (tickets) {
    const [ticket] = tickets
    const contact = [
      `${ticket.firstName || ''} ${ticket.lastName || ''}`.trim(),
      `${formatPhoneNumber(ticket.primaryPhone) || ticket.email}`.trim()
    ].filter(c => c !== 'null' && c !== '')

    return contact.join(' | ')
  }

  return (
    <div className={`dis-panel dis-panel-body ${styles['contact-window']}`}>
      <p>
        <span className={styles['window-header']}>
          {request.ticketTypeName}
        </span> {request.addressline1}, {request.city}, {request.state} {request.zip}
      </p>
      <p>
        <span className={styles['window-header']}>
          Contact
        </span> {prettyContact(request.tickets)}
      </p>
    </div>
  )
}

FixRequestContactWindow.propTypes = {
  request: PropTypes.object.isRequired
}
