import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'
import { formatToUSD } from '../../utilities/formatters'
import { Field, Form } from 'react-final-form'
import { TextInput } from '../TextInput'
import AutocompleteGoogleMap from '../../pages/shared/locationPickers/AutocompleteGoogleMap'
import { TextareaInput } from '../TextareaInput'
import { SelectInput } from '../SelectInput'
import { validate } from './utils'

export default function EditSiteForm ({
  initialValues,
  isFetching,
  onSubmit,
  siteTypes,
  showMetric
}) {
  function onLocationChange (form, newLocation) {
    form.batch(() => {
      form.change('latitude', newLocation.latitude)
      form.change('longitude', newLocation.longitude)
      form.change('placeId', newLocation.place_id)
      form.change('addressline1', newLocation.addressline1)
      form.change('city', newLocation.city)
      form.change('state', newLocation.state)
      form.change('zip', newLocation.zip)
      form.change('country', newLocation.country)
    })
  }

  const siteTypeOptions = useMemo(() => {
    return siteTypes?.reduce((acc, st) => ({
      ...acc,
      [st.id]: st.name
    }), {})
  }, [siteTypes])

  return (
    <div className='dis-panel'>
      <Form onSubmit={onSubmit} validate={validate} initialValues={initialValues}>
        {({
          form,
          handleSubmit,
          pristine,
          valid
        }) => (
          <form className={cn(styles.form, 'dis-panel-body')} onSubmit={handleSubmit}>
            <Field
              name='siteTypeId'
              label='Site Type'
              component={SelectInput}
              options={siteTypeOptions}
            />

            <Field
              name='name'
              label='Unique Site Name'
              component={TextInput}
              maxLength='30'
            />

            <Field
              name='cost'
              label={`Site Disposal Cost (per ${showMetric ? 'metric' : ''} ton)`}
              format={formatToUSD}
              formatOnBlur={true}
              component={TextInput}
            />

            {/* Hidden fields still need to be here for react-final-form to consider them in form state i.e. pristine */}
            <Field name='latitude'>{() => null}</Field>
            <Field name='longitude'>{() => null}</Field>
            <Field name='placeId'>{() => null}</Field>
            <Field name='addressLine1'>{() => null}</Field>
            <Field name='city'>{() => null}</Field>
            <Field name='state'>{() => null}</Field>
            <Field name='zip'>{() => null}</Field>
            <Field name='country'>{() => null}</Field>

            <AutocompleteGoogleMap
              place_id={initialValues.placeId}
              latitude={parseFloat(initialValues.latitude)}
              longitude={parseFloat(initialValues.longitude)}
              initialValue={initialValues.prettyAddress}
              onLocationChange={newLocation => onLocationChange(form, newLocation)}
            />

            <Field
              name='note'
              label='Site Notes'
              component={TextareaInput}
              rows={4}
            />

            <div className={styles.saveBtnContainer}>
              <button
                type='submit'
                className={cn(styles.saveBtn, 'dis-btn dis-btn-primary dis-btn-lg')}
                disabled={pristine || !valid || isFetching}>
                Save Site
                <i className='material-icons dis-btn-icon'>add_circle</i>
              </button>
            </div>
          </form>
        )}
      </Form>
    </div>
  )
}

EditSiteForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  siteTypes: PropTypes.array.isRequired,
  showMetric: PropTypes.bool.isRequired
}
