import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment'
import cn from 'classnames'

export default function Notification ({ id, read, entityType, entityId, createdAt, message, sendingUser, onRead }) {
  const handleClick = useCallback(() => onRead(id, true), [onRead, id])

  return (
    <div className={cn('list-group-item', 'row', read ? 'read-notification' : 'unread-notification')}>
      <div className='col-xs-1'>
        <Link to={`/hauler/${entityType === 'Ticket' ? 'tickets' : 'jobs'}/${entityId}`}>
          <i className='material-icons text-center'>link</i>
        </Link>
      </div>
      <div className='col-xs-11'>
        <h5 className='list-group-item-heading'>
          {message}
          <br />
          <span className='small-text'>
            {'By '}
            <Link to={`/hauler/team/${sendingUser.id}`}>
              {sendingUser.firstName} {sendingUser.lastName}
            </Link>
            {' at '}
            {moment(createdAt).format('l')} {moment(createdAt).format('LT')}
          </span>
          <button
            onClick={handleClick}
            disabled={read}
            className='btn btn-link pull-right pull-up'>
            {
              read
                ? (
                  <div>
                    <i className='material-icons pull-right thumbster-button-icon'>check</i>
                    <span className='pull-right'>Read</span>
                  </div>
                  )
                : (
                  <div>
                    <i className='material-icons pull-right thumbster-button-icon'>markunread</i>
                    <span className='pull-right'>Unread</span>
                  </div>
                  )
            }
          </button>
        </h5>
      </div>
      <div className='clearfix' />
    </div>

  )
}

Notification.propTypes = {
  id: PropTypes.string.isRequired,
  read: PropTypes.bool.isRequired,
  entityType: PropTypes.string.isRequired,
  entityId: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  sendingUser: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired
  }),
  onRead: PropTypes.func.isRequired
}
