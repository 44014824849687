import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { underscoreToCapital } from '../utilities/stringUtilities'
import TooltipWrapper from '../pages/shared/TooltipWrapper'

export default function TicketStatusLabel ({ status, linkPath, hasActiveException, exceptionMessage }) {
  const displayStatus = underscoreToCapital(status.toUpperCase())
  const label = (
    <TooltipWrapper text={exceptionMessage} condition={exceptionMessage && exceptionMessage.length > 0}>
      <div className={cn('label', 'label', {
        'label-success': status === 'open',
        'label-alert': status === 'en_route',
        'label-danger': status === 'cancelled',
        'label-default thumbster-grey-dark-bg': status === 'completed',
        'label-default': status === 'invoiced'
      })}>
        {displayStatus}
        {hasActiveException && (status === 'completed') && (
          <i className='material-icons text-red text-lg ml-1  align-middle'>error</i>
        )}
      </div>
    </TooltipWrapper>
  )
  if (linkPath) {
    return <Link to={linkPath}>{label}</Link>
  }
  return label
}

TicketStatusLabel.propTypes = {
  exceptionMessage: PropTypes.string,
  hasActiveException: PropTypes.bool,
  linkPath: PropTypes.string,
  status: PropTypes.string
}
