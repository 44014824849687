import React from 'react'
import PropTypes from 'prop-types'
import styles from './TicketStatusButtons.module.scss'
import CancelTicketButton from './CancelTicketButton'
import CompleteTicketButton from './CompleteTicketButton'
import PauseTicketButton from './PauseTicketButton'
import StartTicketButton from './StartTicketButton'
export default function TicketStatusButtons ({ ticket, onClick, disabled = false }) {
  return (
    <div className={styles.buttonContainer}>

      <StartTicketButton
        onClick={() => onClick(ticket.id, 'en_route')}
        ticket={ticket}
        disabled={disabled}
      />

      <PauseTicketButton
        onClick={() => onClick(ticket.id, 'open')}
        ticket={ticket}
        disabled={disabled}
      />

      <CompleteTicketButton
        onClick={() => onClick(ticket.id, 'completed')}
        ticket={ticket}
        disabled={disabled}
      />

      <CancelTicketButton
        onClick={() => onClick(ticket.id, 'cancelled')}
        ticket={ticket}
        disabled={disabled}
      />

    </div>
  )
}

TicketStatusButtons.propTypes = {
  ticket: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}
