import React, { useState, useEffect } from 'react'
import { gql } from 'graphql-request'
import notify from '../../utilities/notify'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import ProMappingContainer from '../../components/pro/ProMappingContainer'
import { useSelector } from 'react-redux'

function getPartnership (graphQLClient, haulerId) {
  const partnershipQuery = gql`
  query Partnership($haulerId: ID!) {
    partnership(haulerId: $haulerId) {
      clientCreationStrategy
      client {
        name
      }
    }
  }
`
  return graphQLClient.request(partnershipQuery, { haulerId })
}

export default function ProFeaturesPage () {
  const { user, graphQLClient } = useSelector(({ user, dataLoaders }) => ({
    user: user.user,
    graphQLClient: dataLoaders.dispatcher.graphQLClient
  }))
  const [clientName, setClientName] = useState(null)

  useEffect(function onInitialRender () {
    getPartnership(graphQLClient, user.haulerId)
      .then(({ partnership }) => {
        if (partnership[0].clientCreationStrategy === '1client') {
          setClientName(partnership[0].client.name)
        } else {
          setClientName('Standard Brands')
        }
      })
      .catch(() => notify('error', 'There was a problem retrieving partnership'))
  }, [user.haulerId, graphQLClient])

  return (
    <NormalLayoutContainer>
      <h3>PRO Settings</h3>
      <p className='text-2xl mt-6'>
        Use the options below to update your settings for the integration between Dispatcher and {clientName}.
      </p>
      <ProMappingContainer clientName={clientName} />
    </NormalLayoutContainer>
  )
}
