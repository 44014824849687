import React from 'react'
import PropTypes from 'prop-types'
import FeeTypeStatusLabel from '../../pages/shared/FeeTypeStatusLabel'
import { commaDeliminate } from '../../utilities/stringUtilities'
import { Link, generatePath } from 'react-router-dom'
import { ROUTES } from '../../routes'

export default function FeeTypeListItem ({ feeType: { id, name, defaultAmount, status } }) {
  return (
    <div className='list-group-item'>
      <h4 className='list-group-item-heading'>
        <div className='font-size-0-75em inline-block status-pill'>
          <FeeTypeStatusLabel status={status} />
        </div>
        &nbsp; {name}
        <Link to={generatePath(ROUTES.editFeeType, { id })} className='btn btn-primary-dk pull-right'>
          <span className='pull-left hidden-xs'>Edit Fee Type</span>
          <i className='material-icons pull-right thumbster-button-icon'>
            mode_edit
          </i>
        </Link>
        <label className={`pull-right bg-gray-light med-dark-gray-text inline-block
         label font-size-0-75em margin-top-0-5-pct margin-right-0-5-pct`}>
          {`$${commaDeliminate(defaultAmount)}`}
        </label>
      </h4>
    </div>
  )
}

FeeTypeListItem.propTypes = {
  feeType: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    defaultAmount: PropTypes.number.isRequired,
    qboItemId: PropTypes.string,
    status: PropTypes.oneOf(['enabled', 'disabled'])
  })
}
