import { parseUSDToNumber } from './parsers'
/**
 * Format to USD currency.
 *
 * @param {string|number} value
 * @return {string|undefined}
 *
 * @example
 * formatToUSD(200.5) // 200.5
 * formatToUSD(200.) // 200.
 * formatToUSD(200.67) // 200.67
 * formatToUSD(200.699) // 200.69 - Notice how there is no rounding
 * formatToUSD(4) // 4
 */
export function formatToUSD (value) {
  const formatOptions = {
    style: 'currency',
    currency: 'USD'
  }

  if (value === undefined || value === null || value === '') return ''

  return new Intl.NumberFormat('en-US', formatOptions)
    .format(Number(parseUSDToNumber(value)))
    .replace(/\$/g, '')
    .trim()
}
