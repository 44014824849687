import React from 'react'
import omit from 'lodash/omit'
import dayjs from 'dayjs'
import DatePicker from 'react-datepicker'
import PropTypes from 'prop-types'
import cn from 'classnames'

export function DatePickerInput ({
  input,
  placeholder,
  label,
  meta: {
    touched,
    error
  },
  disabled,
  minDate,
  maxDate,
  className
}) {
  let formGroupClasses = 'form-group'
  if (touched && error) {
    formGroupClasses += ' has-error'
  }

  const dateSplit = (input.value).split('-')
  const dateYear = parseInt(dateSplit[0])
  const dateMonth = parseInt(dateSplit[1] - 1)
  const dateDay = parseInt(dateSplit[2])
  const dateValue = new Date(dateYear, dateMonth, dateDay).toUTCString()

  const filteredInput = omit(input, ['onChange', 'value', 'onBlur'])

  function onChange (date) {
    if (!date) {
      input.onChange(date)
      return
    }

    input.onChange(dayjs(date).format('YYYY-MM-DD'))
  }

  return (
    <div className={cn(className, formGroupClasses)}>
      {label && <label className='control-label'>{label}</label>}
      <div>
        <DatePicker
          dateFormat='MM/dd/yyyy'
          disabled={disabled}
          maxDate={maxDate}
          minDate={minDate}
          {...filteredInput}
          placeholderText={placeholder}
          selected={input.value ? new Date(dateValue) : undefined}
          onChange={onChange}
          fixedHeight
        />
      </div>
      {(touched && error) && <h5>{error}</h5>}
    </div>
  )
}

DatePickerInput.propTypes = {
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  }),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  className: PropTypes.string
}
