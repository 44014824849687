import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import PageTitle from '../../components/page-title'
import USER_ACTIONS from '../../store/user/userActions'
import QUERY_KEYS from '../../graphql/queryKeys'
import useQuery from '../../hooks/useQuery'
import useMutation from '../../hooks/useMutation'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import notify from '../../utilities/notify'
import haulerQuery from '../../graphql/queries/hauler'
import updateHaulerMutation from '../../graphql/mutations/updateHaulerDetails'
import Spinner from 'react-spinkit'
import { RadioInput } from '../../components/RadioInput'
import { Form, Field } from 'react-final-form'
import styles from './index.module.scss'

export default function DriverAssignmentSettingPage () {
  const history = useHistory()
  const dispatch = useDispatch()
  const { user } = useSelector(({ user: { user } }) => ({ user }))

  const { data, isFetching } = useQuery(
    [QUERY_KEYS.hauler, { haulerId: user.haulerId }],
    haulerQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'Error retrieving hauler settings')
      }
    })

  const { mutate: updateHauler } = useMutation(updateHaulerMutation, {
    onSuccess (data) {
      dispatch({ type: USER_ACTIONS.SET_USER, payload: { ...user, showDriversOnly: data?.hauler?.showDriversOnly } })
      notify('success', 'Driver Assignment List Settings Updated')
      history.goBack()
    },
    onError (error) {
      captureErrorAndNotify(error, 'Failed to Update Hauler Settings')
    }
  })

  function handleSubmit (vals) {
    updateHauler({ haulerId: user.haulerId, haulerFields: { showDriversOnly: vals.showDriversOnly } })
  }

  return (
    <div>
      <NormalLayoutContainer showBackLink>
        <PageTitle>
          Driver Assignment
          <div className={styles.subtitle}>
            Which users should appear in the Driver List Options when assigning tickets?
          </div>
        </PageTitle>
        {isFetching
          ? <Spinner isFetching={isFetching} />
          : (<Form onSubmit={handleSubmit} initialValues={{ showDriversOnly: data?.showDriversOnly }}>
            {({ handleSubmit, pristine }) => (
              <form onSubmit={handleSubmit}>
                <div className='dis-panel dis-panel-body'>
                  <Field
                    classNames={styles.radioBtn}
                    name='showDriversOnly'
                    radioVal={false}>
                    {({ input }) => (
                      <label className={styles.radioBtn} htmlFor='showDriversOnly'>
                        <RadioInput
                          input={input}
                          radioVal={false}
                        />
                        <span>Admins and Drivers</span>
                        <span className={styles.defaultSetting}>(default)</span>
                      </label>
                    )}
                  </Field>
                  <Field
                    classNames={styles.radioBtnBlock}
                    name='showDriversOnly'
                    radioVal={true}
                    label='Drivers Only'
                    component={RadioInput}
                  />
                </div>
                <div className={styles.btnContainer}>
                  <button type='submit'
                    className='dis-btn dis-btn-lg dis-btn-primary'
                    disabled={pristine}>
                    Save
                    <i className='material-icons dis-btn-icon'>check</i>
                  </button>
                </div>
              </form>
            )}
          </Form>)}
      </NormalLayoutContainer>
    </div>
  )
}
