import React from 'react'
import PropTypes from 'prop-types'
import { Link, generatePath } from 'react-router-dom'
import Tooltip from 'react-bootstrap/lib/Tooltip'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'
import NoResults from '../../pages/shared/NoResults'
import { ROUTES } from '../../routes'
import CursorPagination from '../cursor-pagination'

export default function InvoiceableAccountsTable ({ clients, sort, onSortChange, onPageRequest, fakeCount }) {
  return (
    <div className='row'>
      <div className='col-xs-12'>
        <table className='table table-responsive table-striped'>
          <thead>
            <tr>
              <th width='5%'>
                <TableHeader
                  label='ID'
                  columnName='customId'
                  sort={sort}
                  onSortChange={onSortChange}
                />
              </th>
              <th width='10%'>
                <TableHeader
                  label='Account Notes'
                  columnName='notes'
                  sort={sort}
                  onSortChange={onSortChange}
                />
              </th>
              <th width='20%'>
                <TableHeader
                  label='Name'
                  columnName='name'
                  sort={sort}
                  onSortChange={onSortChange}
                />
              </th>
              <th width='15%'>
                <TableHeader
                  label='Contact Name'
                  columnName='lastName'
                  sort={sort}
                  onSortChange={onSortChange}
                />
              </th>
              <th width='15%'>
                <TableHeader
                  label='Phone'
                  columnName='primaryPhone'
                  sort={sort}
                  onSortChange={onSortChange}
                />
              </th>
              <th width='20%'>
                <TableHeader
                  label='Invoiceable Tickets'
                  columnName='invoiceableTicketsCount'
                  sort={sort}
                  onSortChange={onSortChange}
                />
              </th>
              <th width='15%'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {clients.nodes.length ? null : <tr><td colSpan={8}><NoResults /></td></tr>}
            {clients.nodes.map(client => {
              const clientPath = generatePath(ROUTES.client, { clientId: client.id })
              const newClientInvoicePath = generatePath(ROUTES.invoiceNewClientsTickets, { clientId: client.id })
              return (
                <tr key={client.id}>
                  <td>
                    <Link to={clientPath}>
                      {client.customId}
                    </Link>
                  </td>
                  <td>
                    {client.notes &&
                      <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip id='tooltip'>{client.notes}</Tooltip>}>
                        <i className='material-icons'>speaker_notes</i>
                      </OverlayTrigger>
                    }
                  </td>
                  <td>
                    <Link to={clientPath}>
                      <strong>{client.name}</strong>
                    </Link>
                  </td>
                  <td>
                    {client.firstName || client.lastName ? `${client.firstName} ${client.lastName}` : 'None'}
                  </td>
                  <td>
                    {client.prettyPrimaryPhone ? client.prettyPrimaryPhone : 'None'}
                  </td>
                  <td>
                    {client.invoiceableTicketsCount}
                  </td>
                  <td>
                    <Link
                      to={newClientInvoicePath}
                      className='dis-btn dis-btn-sm dis-btn-primary-dk'>
                      Create Invoice
                    </Link>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        {clients.nodes.length > 0 && <CursorPagination
          pageInfo={clients.pageInfo}
          totalCount={clients.totalCount}
          onPageRequest={onPageRequest}
          fakeCount={fakeCount}
                                     />}
      </div>
    </div>
  )
}
InvoiceableAccountsTable.propTypes = {
  clients: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.object).isRequired,
    pageInfo: PropTypes.shape({
      endCursor: PropTypes.string,
      hasNextPage: PropTypes.bool.isRequired,
      hasPreviousPage: PropTypes.bool.isRequired,
      startCursor: PropTypes.string
    }).isRequired,
    totalCount: PropTypes.number.isRequired
  }).isRequired,
  sort: PropTypes.shape({
    column: PropTypes.oneOf(['customId', 'notes', 'name', 'lastName', 'primaryPhone', 'openJobsCount', 'closedJobsCount']).isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired
  }).isRequired,
  onPageRequest: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  fakeCount: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired
  })
}

function TableHeader ({ label, columnName, onSortChange, sort }) {
  const currentlySorted = sort.column === columnName

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className='cursor-pointer' onClick={() => onSortChange(columnName)}>
      {label}
      {currentlySorted && (
        <span className='pull-right'>{ sort.direction === 'asc' ? <>&uarr;</> : <>&darr;</> }</span>
      )}
    </div>
  )
}
TableHeader.propTypes = {
  label: PropTypes.string.isRequired,
  columnName: PropTypes.string.isRequired,
  sort: PropTypes.shape({
    column: PropTypes.oneOf(['customId', 'notes', 'name', 'lastName', 'primaryPhone', 'openJobsCount', 'closedJobsCount']).isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired
  }).isRequired,
  onSortChange: PropTypes.func.isRequired
}
