import { loader } from 'graphql.macro'

export default async function wastepayCreateCard ({ dataLoaders, variables }) {
  const schema = loader('./wastepayCreateCard.graphql')
  const input = {
    cardBrand: variables.cardBrand,
    cardLastFour: variables.cardLastFour,
    cardToken: variables.token,
    cardType: variables.cardType,
    clientId: variables.clientId,
    expirationMonth: variables.cardExpirationMonth,
    expirationYear: variables.cardExpirationYear,
    saveAsPrimaryCard: variables.saveAsPrimaryCard ?? false,
    addressLine1: variables.billingAddress.streetAddress,
    addressLine2: variables.billingAddress.addressLine2 ?? '',
    city: variables.billingAddress.city,
    state: variables.billingAddress.state,
    zip: variables.billingAddress.zip,
    countryCode: variables.countryCode ?? 'US'
  }

  if (variables.nickname) input.nickname = variables.nickname

  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, { input })
  return response.wastepayCreateCard
}
