import React from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import TicketTypeStatusLabel from '../../pages/shared/TicketTypeStatusLabel'
import ReactTooltip from 'react-tooltip'

export default function EditTicketTypeStatus ({ updateTicketType, ticketType }) {
  function handleSaveChanges (status) {
    updateTicketType({ status })
  }

  function disableMessaging () {
    let message = ''
    if (ticketType.ticketsCount > 0) {
      message = 'Can\'t disable with attached tickets. '
    }
    if (ticketType.standardTicketTypeId) {
      message += 'This ticket type is being used by the broker integration and cannot be disabled.'
    }
    return message
  }

  const disallowDisable = ticketType.ticketsCount > 0 || ticketType.standardTicketTypeId

  return (
    <>
      <ReactTooltip />
      <div className={styles.statusContainer}>
        <div>
          <div className={styles.currentStatus}>Current Status:</div>
          <TicketTypeStatusLabel
            status={ticketType.status}
          />
        </div>
        {ticketType.status === 'enabled' && (
          <div
            data-tip={disallowDisable ? disableMessaging() : null}>
            <button
              className='dis-btn dis-btn-danger dis-btn-sm'
              onClick={() => handleSaveChanges('disabled')}
              disabled={disallowDisable}>
              <strong>Disable</strong>
            </button>
          </div>
        )}
        {ticketType.status === 'disabled' && (
          <button
            className='dis-btn dis-btn-success dis-btn-sm'
            onClick={() => handleSaveChanges('enabled')}>
            <strong>Enable</strong>
          </button>
        )}
      </div>
    </>
  )
}

EditTicketTypeStatus.propTypes = {
  ticketType: PropTypes.shape({
    status: PropTypes.string.isRequired,
    ticketsCount: PropTypes.number.isRequired,
    standardTicketTypeId: PropTypes.string
  }).isRequired,
  updateTicketType: PropTypes.func.isRequired
}
