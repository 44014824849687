import React from 'react'
import PropTypes from 'prop-types'
import styles from './notifications-read-only.module.scss'
import { Link, generatePath } from 'react-router-dom'
import { ROUTES } from '../../routes'
import moment from 'moment'
import cn from 'classnames'

export default function NotificationsReadOnlyListItem (props) {
  const {
    notification: {
      read,
      message,
      created_at: createdAt,
      user,
      receiving_user: receivingUser,
      entity_type: entityType,
      entity_id: entityId
    },
    includeLink,
    showReceiver
  } = props

  return (
    <div className={cn('dis-list-item', read ? 'read-notification' : 'unread-notification', styles.readOnlyItem)}>
      <div className={styles.readOnlyContainer}>
        <div className={styles.readOnlyText}>
          {
            includeLink &&
            (
              <Link to={generatePath(entityType === 'Ticket' ? ROUTES.ticket : ROUTES.job, { id: entityId })}
                className={styles.readOnlyLink}>
                <i className={cn('material-icons', styles.linkIcon)}>link</i>
              </Link>
            )
          }
          {message}
        </div>

        {showReceiver
          ? (
            <span>
              Sent to&nbsp;
              <Link to={generatePath(ROUTES.teamMember, { id: receivingUser.id })}>
                {receivingUser.first_name} {receivingUser.last_name}
              </Link>
              &nbsp;at&nbsp;
              {moment(createdAt).format('l')} {moment(createdAt).format('LT')}
            </span>
            )
          : (
            <span className=''>By <Link to={`/hauler/team/${user.id}`}>
              {user.first_name} {user.last_name}</Link> at {moment(createdAt).format('l')} {moment(createdAt).format('LT')}
            </span>
            )
        }
      </div>

      <div disabled={read} className={styles.readOnlyContainer}>
        {read
          ? (
            <div className={styles.readOnlyText}>
              <span className=''>Read</span>
              <i className={cn('material-icons', styles.readOnlyIcon)}>check</i>
            </div>
            )
          : (
            <div className={styles.readOnlyText}>
              <span className=''>Unread</span>
              <i className={cn('material-icons', styles.readOnlyIcon)}>markunread</i>
            </div>
            )
        }
      </div>
    </div>
  )
}

NotificationsReadOnlyListItem.propTypes = {
  includeLink: PropTypes.bool,
  notification: PropTypes.shape({
    created_at: PropTypes.any,
    entity_id: PropTypes.number,
    entity_type: PropTypes.string,
    message: PropTypes.string,
    read: PropTypes.bool,
    receiving_user: PropTypes.any,
    user: PropTypes.any
  }),
  showReceiver: PropTypes.bool
}
