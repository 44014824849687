import { mustBeMoney, required, EmailValidator } from '../../utilities/commonValidators'

export default function validate (total) {
  return (values) => {
    const errors = {}

    const paymentMethodError = required(values.paymentMethod)
    if (paymentMethodError !== undefined) {
      errors.paymentMethod = paymentMethodError
    }

    const paymentAmountError = mustBeMoney(values.paymentAmount)
    if (paymentAmountError !== undefined) {
      errors.paymentAmount = paymentAmountError
    }

    const totalAmount = Number(total || 0)
    const totalAmountFixed = totalAmount.toFixed(2)

    if (Number(values.paymentAmount) > totalAmount) {
      errors.paymentAmount = `Cannot be more than $${totalAmountFixed}`
    }

    if (Number(values.paymentAmount) === 0) {
      errors.paymentAmount = 'Must be more than 0'
    }

    if (!values.email) {
      errors.email = 'Required'
    }

    if (values.email) {
      const emailArray = values.email.replace(/\s+/g, '').split(',')
      const invalidEmail = emailArray.some(email => !EmailValidator.test(email))
      if (invalidEmail) {
        errors.email = 'Invalid Email'
      }
    }

    return errors
  }
}
