import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'
import { generatePath, Link } from 'react-router-dom'
import { ROUTES } from '../../routes'
import QBOExportStatus from './QBOExportStatus'

export default function QboExportInvoiceSuccess ({
  invoice,
  secondaryButton,
  retry,
  transactions = []
}) {
  return (
    <div className={cn('dis-panel', styles.exportSuccessContainer)}>
      <div className='dis-panel-body'>
        <h2 className={styles.successText}>Export Successful</h2>
        <h5>Your invoice has been exported to QuickBooks Online.</h5>
        <h5 className={styles.alertText}>We do not send your invoices automatically. You MUST review it in QBO to send it and collect payment.</h5>
        <div className={styles.btnContainer}>
          <a href={invoice?.qboUrl} className='dis-btn dis-btn-primary dis-btn-lg' target='_blank noopener noreferrer'>View In QuickBooks Online</a>
        </div>
        <div className={styles.paymentInvoiceExportContainer}>
          <QBOExportStatus
            invoice={invoice}
            retry={retry}
            transactions={transactions}
          />
        </div>
        <div className={styles.btnContainer}>
          {secondaryButton == null
            ? (
              <Link to={generatePath(ROUTES.ticket, { id: invoice?.tickets[0]?.id })} className='dis-btn dis-btn-gray dis-btn-lg'>
                Back to Ticket
              </Link>
              )
            : secondaryButton
          }
        </div>
      </div>
    </div>
  )
}

QboExportInvoiceSuccess.propTypes = {
  invoice: PropTypes.object.isRequired,
  secondaryButton: PropTypes.node,
  retry: PropTypes.func,
  transactions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    transactionType: PropTypes.oneOf(['Payment', 'Refund']),
    qboLink: PropTypes.string,
    qboExportStatus: PropTypes.oneOf(['SUCCESS', 'ERROR'])
  }))
}
