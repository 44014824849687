import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { generatePath, Link } from 'react-router-dom'
import Pagination from '../../../../../shared/Pagination'
import ActivityIcon from '../../../../../shared/ActivityIcon'
import { ROUTES } from '../../../../../../routes'

export default class JobActivitiesDisplay extends Component {
  render () {
    const { activities, pagination, onPageRequest } = this.props
    return (
      <div className='row panel panel-default'>
        <div className='panel-heading'>
          <div className='container-fluid'>
            <div>
              <h4 className='pull-left'>Job Activities & Comments</h4>
            </div>
          </div>
        </div>
        <div className='panel-body'>
          <div className='list-group'>

            {activities.map(activity => {
              const comment = activity.key === 'ticket.add_comment'
              const listItemClasses = `list-group-item ${comment ? 'highlight-bg' : ''}`
              return (
                <li className={listItemClasses} key={activity.id}>
                  <div className='pull-left font-size-1-25em'>
                    {comment ? `Comment: ${activity.message}` : activity.message}
                  </div>
                  <ActivityIcon iconName={activity.key} />
                  <div className='clearfix' />

                  <h6 className='pull-right'>
                    {moment(activity.createdAt).format('dddd, MMMM Do YYYY, h:mm:ss a')}
                  </h6>
                  <h6 className='text-left'>
                    <em>
                      By
                      {activity.ownerType.toUpperCase() === 'USER'
                        ? <Link to={generatePath(ROUTES.teamMember, { id: activity.authorId })}>
                          {` ${activity.author}`}
                        </Link>
                        : <>{` ${activity.author}`}</>
                      }
                    </em>
                  </h6>
                </li>
              )
            })}
          </div>
        </div>
        <div className='panel-footer'>
          <Pagination pagination={pagination} onPageRequest={onPageRequest} />
        </div>
      </div>
    )
  }
}

JobActivitiesDisplay.propTypes = {
  activities: PropTypes.array.isRequired,
  pagination: PropTypes.any.isRequired,
  onPageRequest: PropTypes.func.isRequired
}
