import React, { useState, createRef, useEffect } from 'react'
import classNames from 'classnames'
import styles from './new-requests.module.scss'
import PropTypes from 'prop-types'
import {
  CSSTransition,
  TransitionGroup
} from 'react-transition-group'
import { loader } from 'graphql.macro'
import NewRequestLineItem from './NewRequestLineItem'
import notify from '../../utilities/notify'
import { useSelector } from 'react-redux'
import Modal from '../modal/Modal'
import Availability from '../availability'
import FixRequest from './fix-request'

export default function NewRequestsContainer ({
  requests,
  setNewRequests,
  panelOpen,
  setPanelOpen
}) {
  const [newRequestsMaxHeight, setNewRequestsMaxHeight] = useState('0px')
  const newRequestsRef = createRef()
  const [formsOpen, setFormsOpen] = useState(1)
  const [clientName, setClientName] = useState(null)
  const [fixClientName, setFixClientName] = useState(null)
  const [requestSelected, setRequestSelected] = useState(null)
  const [requestClient, setRequestClient] = useState(null)
  const [fixStep, setFixStep] = useState(null)
  const [fixIndex, setFixIndex] = useState(null)
  const { hauler, graphQLClient } = useSelector(({ user, dataLoaders }) => ({
    hauler: user.hauler,
    graphQLClient: dataLoaders.dispatcher.graphQLClient
  }))
  const [availabilityOpen, setAvailabilityOpen] = useState(false)
  const [fixRequestOpen, setFixRequestOpen] = useState(false)

  useEffect(function onInitialRender () {
    const partnerships = loader('../../graphql/queries/partnerships.graphql')
    graphQLClient.request(partnerships, { haulerId: hauler.id })
      .then(({ partnership }) => {
        if (partnership[0]?.clientCreationStrategy === '1client') {
          setClientName(partnership[0].client.name)
        }
      })
  }, [hauler.id, setClientName, graphQLClient])

  function closeAvailability () {
    setAvailabilityOpen(false)
  }

  function closeFixRequestModal () {
    setFixRequestOpen(false)
  }

  useEffect(() => {
    if (newRequestsRef && newRequestsRef.current) {
      setNewRequestsMaxHeight(panelOpen && requests.length && formsOpen ? `${newRequestsRef.current.scrollHeight}px` : '0px')
    }
  }, [panelOpen, newRequestsRef, requests, formsOpen])

  function handleConfirmation (index, serviceIds, confirmed, issueReason = null, issueNotes = null, clientId = null, jobId = null) {
    const unconfirmedServiceConfirmation = loader('../../graphql/mutations/unconfirmedServiceConfirmation.graphql')
    graphQLClient.request(unconfirmedServiceConfirmation, { input: { clientId, jobId, serviceIds, confirmed, issueNotes, issueReason } })
      .then(({ unconfirmedServiceConfirmation: data }) => {
        const { jobId, success, clientId, status, ticketIds, clientName } = data

        setFixClientName(clientName)
        if (!success) {
          setRequestSelected(requests[index])
          setFixIndex(index)
          setFixStep(jobId === null ? 2 : 1)
          setRequestClient(Number(clientId))
          setFixRequestOpen(true)
          return
        }

        const updatedRequests = [...requests]
        const updated = updatedRequests[index].tickets.map(ticket => {
          return {
            ...ticket,
            ...{
              jobId,
              status,
              createdTickets: ticketIds
            }
          }
        })
        updatedRequests[index].tickets = updated
        setNewRequests(updatedRequests)
      })
      .catch(() => notify('error', 'Failed to update new request'))
      .finally(() => {
        if (!confirmed) {
          removeRequest(index)
        }
      })
  }

  function removeRequest (index) {
    const updatedRequests = [...requests]
    updatedRequests.splice(index, 1)
    setNewRequests(updatedRequests)
  }

  return (
    <>
      <Modal isOpen={fixRequestOpen} closeModal={closeFixRequestModal} className={styles['fix-request-modal']}>
        <FixRequest
          request={requestSelected}
          clientID={requestClient}
          initialFixStep={fixStep}
          closeModal={closeFixRequestModal}
          requestIndex={fixIndex}
          updateRequest={handleConfirmation}
          clientName={fixClientName}
        />
      </Modal>
      <Modal isOpen={availabilityOpen} closeModal={closeAvailability}>
        <Availability />
      </Modal>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        aria-expanded={panelOpen}
        className='bg-green-light px-6 py-2 w-full cursor-pointer'
        onClick={() => {
          if (hauler.proBrokerAccess) {
            setPanelOpen(!panelOpen)
          }
        }}>
        <div className='flex flex-row flex-nowrap w-full'>
          {hauler.proBrokerAccess &&
            <>
              <span className='dispatcher-badge my-auto w-1/12 bg-white mr-6'>
                {requests.filter(req => req.tickets[0].status === 'UNCONFIRMED').length}
              </span>
              <div>
                <div className='font-size-1-5em mt-2'>New Requests</div>
                {clientName && <div className={styles.clientName}>from {clientName}</div>}
              </div>
            </>}
          <button
            onClick={(e) => {
              e.stopPropagation()
              setAvailabilityOpen(true)
            }}
            className='button button_white self-center ml-auto'>
            Availability
          </button>
        </div>
      </div>
      <div className='overflow-y-scroll max-height-75'>
        <div
          style={{ maxHeight: newRequestsMaxHeight }}
          ref={newRequestsRef}
          className={classNames('m-0 p-0 dispatch-tickets-panel no-margin-bottom', {
            'dispatch-tickets-panel_open': panelOpen,
            'dispatch-tickets-panel_closed': !panelOpen
          })}>
          <TransitionGroup>
            {requests.map((request, index) => {
              return (
                <CSSTransition
                  key={request.tickets[0].id}
                  timeout={2200}
                  classNames={{
                    exit: styles.itemExit,
                    exitActive: styles.itemExitActive
                  }}>
                  <NewRequestLineItem
                    request={request}
                    formsOpen={formsOpen}
                    setFormsOpen={setFormsOpen}
                    removeRequest={removeRequest}
                    handleConfirmation={handleConfirmation}
                    index={index}
                  />
                </CSSTransition>
              )
            })}
          </TransitionGroup>
        </div>
      </div>
    </>
  )
}

NewRequestsContainer.propTypes = {
  requests: PropTypes.array,
  setNewRequests: PropTypes.func.isRequired,
  panelOpen: PropTypes.bool.isRequired,
  setPanelOpen: PropTypes.func.isRequired
}
