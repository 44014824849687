import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styles from './lat-lng-map.module.scss'
import classNames from 'classnames'
import notify from '../../../utilities/notify'
import { stateToAbbreviation, isLatitude, isLongitude } from '../../../utilities/locationUtilities'
import Spinner from 'react-spinkit'

const countryToAbbr = {
  'United States': 'US',
  Canada: 'CA'
}
export default function LatLngSearchGoogleMap ({
  mapId,
  latitude,
  longitude,
  onLocationChange,
  address,
  mapClass,
  hauler
}) {
  const [isFetching, setIsFetching] = useState(false)
  const [map, setMap] = useState(null)

  const [inputLatLng, setInputLatLng] = useState({
    lat: '',
    lng: ''
  })
  const [inputLatLngErrors, setInputLatLngErrors] = useState({
    lat: false,
    lng: false
  })
  const [resultsError, setResultsError] = useState(null)
  const [marker, setMarker] = useState(null)
  const [infoWindow, setInfoWindow] = useState(null)

  const updateMap = useCallback((lat, lng, place) => {
    const latLng = new google.maps.LatLng(lat, lng)
    map.setCenter(latLng)
    if (map.getZoom() === 2) {
      map.setZoom(16)
    }
    marker.setMap(map)
    if (!infoWindow) {
      infoWindow.open(map, marker)
    }

    const updatedLatLng = new google.maps.LatLng(lat, lng)
    marker.setPosition(updatedLatLng)

    const addrComponents = buildAddressComponents(place.address_components)
    const streetAddress = `${addrComponents.street_number || ''} ${addrComponents.route || ''}`

    onLocationChange({
      place_id: place.place_id || null,
      latitude: lat,
      longitude: lng,
      addressline1: streetAddress.trim().length ? streetAddress : 'unknown',
      city: addrComponents.locality ||
        addrComponents.sublocality_level_1 ||
        addrComponents.administrative_area_level_3 ||
        addrComponents.neighborhood || addrComponents.administrative_area_level_2 || null,
      state: stateToAbbreviation(addrComponents.administrative_area_level_1) || null,
      zip: addrComponents.postal_code || addrComponents.postal_code_prefix || null,
      country: countryToAbbr[addrComponents.country] || null,
      displayAddress: streetAddress.trim().length ? streetAddress : 'unknown'
    })
  }, [infoWindow, marker, onLocationChange, map])

  const handleNoResults = useCallback((err, noStreetAddress, lat, lng) => {
    if (noStreetAddress || err?.code === 'ZERO_RESULTS') {
      setResultsError('No address can be found near these coordinates. Please try a different lat/long.')
    }
    if (err.code && err.code !== 'ZERO_RESULTS') {
      notify('error', err.message)
    }
    onLocationChange({
      place_id: null,
      latitude: lat,
      longitude: lng,
      addressline1: null,
      city: null,
      state: null,
      zip: null,
      country: null,
      displayAddress: null
    })
  }, [onLocationChange])

  const reverseGeocodeLatLng = useCallback((lat, lng) => {
    setResultsError('')
    const geocoder = new google.maps.Geocoder()
    const streetAddressResults = []
    const routeResults = []
    return geocoder.geocode(
      {
        location: {
          lat: Number(lat),
          lng: Number(lng)
        }
      }
    )
      .then((response) => {
        const results = response.results?.filter(res => {
          res.address_components.map(addr => {
            if (addr.types.includes('street_number')) {
              streetAddressResults.push(res)
            } else if (addr.types.includes('route')) {
              routeResults.push(res)
            }
            return null
          })
          /* filtering out plus code results because they don't contain any parsable address components */
          return !res.types.includes('plus_code')
        })
        const result = streetAddressResults[0] || routeResults[0] || results[0]
        if (result) {
          const country = result.address_components.find(addr => {
            if (addr.types.includes('country')) {
              return addr
            }
            return null
          })
          if (country?.short_name === 'US' || country?.short_name === 'CA') {
            updateMap(lat, lng, result)
          } else {
            setResultsError('The latitude and longitude must be in the U.S. or Canada.')
          }
        } else {
          handleNoResults(null, true, lat, lng)
        }
      })
      .catch((err) => {
        handleNoResults(err, false, lat, lng)
      })
  }, [handleNoResults, updateMap])

  const buildMap = useCallback(({ mapId }) => {
    return new google.maps.Map(document.getElementById(mapId), {
      center: {
        lat: latitude || hauler.latitude,
        lng: longitude || hauler.longitude
      },
      zoom: latitude && longitude ? 16 : 10,
      mapTypeId: 'roadmap',
      draggable: true,
      clickableIcons: false,
      gestureHandling: 'cooperative'
    })
  }, [latitude, longitude, hauler])

  useEffect(() => {
    if (!map) {
      const newMap = buildMap({
        mapId
      })
      setMap(newMap)
    }

    if (!marker) {
      setMarker(new google.maps.Marker({
        draggable: true
      }))
    }

    if (!infoWindow) {
      const newInfoWindow = new google.maps.InfoWindow()
      setInfoWindow(newInfoWindow)
    }

    if (latitude && longitude && map) {
      const latLng = new google.maps.LatLng(latitude, longitude)

      map.setCenter(latLng)
      marker.setMap(map)
      marker.setPosition(latLng)

      google.maps.event.addListener(marker, 'dragend', function () {
        setInputLatLng({
          lat: marker.position.lat(),
          lng: marker.position.lng()
        })
        reverseGeocodeLatLng(marker.position.lat(), marker.position.lng())
      })

      if (map.getZoom() === 10) {
        map.setZoom(16)
      }

      setInputLatLng({
        lat: latitude,
        lng: longitude
      })

      infoWindow.setContent(
        `<h6>
          <strong>${address || 'No Address'}</strong>
          <div class=${styles.infoWindow}>(${latitude}, ${longitude})</div>
        </h6>`
      )
      if (!infoWindow?.map) {
        infoWindow.open(map, marker)
      }
    }

    if (map && !latitude && !longitude) {
      google.maps.event.addListener(map, 'click', function (event) {
        const coords = event.latLng.toJSON()
        reverseGeocodeLatLng(coords.lat, coords.lng)
      })
    }

    return function cleanup () {
      if (marker) {
        google.maps.event.clearListeners(marker, 'dragend')
      }
      if (map) {
        google.maps.event.clearListeners(map, 'click')
      }
    }
  }, [latitude, longitude, mapId, address, infoWindow, marker, map, reverseGeocodeLatLng, buildMap])

  function LatLongSearch (e) {
    e.preventDefault()
    let error = false
    if (!isLatitude(inputLatLng.lat) || !isLongitude(inputLatLng.lng)) {
      setInputLatLngErrors({
        lat: !isLatitude(inputLatLng.lat),
        lng: !isLongitude(inputLatLng.lng)
      })
      error = true
    }

    if (error) {
      return
    }

    setIsFetching(true)
    reverseGeocodeLatLng(inputLatLng.lat, inputLatLng.lng)
      .finally(() => setIsFetching(false))
  }

  function handleLngChange (val) {
    setInputLatLngErrors({ ...inputLatLngErrors, lng: false })
    const updatedLatLng = { ...inputLatLng, lng: val }
    setInputLatLng(updatedLatLng)
  }

  function handleLatChange (val) {
    setInputLatLngErrors({ ...inputLatLngErrors, lat: false })
    const updatedLatLng = { ...inputLatLng, lat: val }
    setInputLatLng(updatedLatLng)
  }

  function buildAddressComponents (address) {
    const addressComponents = {}
    address.map((obj) => {
      const key = obj.types[0]
      const val = obj.long_name
      addressComponents[key] = val
      return null
    })
    return addressComponents
  }

  return (
    <div>
      <div className={styles.inputContainer}>
        <label className={classNames(styles.textInput,
          { 'has-error': inputLatLngErrors.lat })}>
          Latitude
          <input
            id={`${mapId}Lat`}
            className='form-control'
            type='text'
            value={inputLatLng.lat}
            onChange={(e) => handleLatChange(e.target.value)}
          />
          {inputLatLngErrors.lat && <div>Invalid Latitude</div>}
        </label>
        <label className={classNames(styles.textInput, { 'has-error': inputLatLngErrors.lng })}>
          Longitude
          <input
            id={`${mapId}Lng`}
            className='form-control'
            type='text'
            value={inputLatLng.lng}
            onChange={(e) => handleLngChange(e.target.value)}
          />
          {inputLatLngErrors.lng && <div>Invalid Longitude</div>}
        </label>
        <button
          disabled={!inputLatLng.lng || !inputLatLng.lat}
          className={classNames('dis-btn dis-btn-sm dis-btn-primary', styles.searchBtn)}
          onClick={(e) => LatLongSearch(e)}>
          {isFetching
            ? (<Spinner name='circle' className='mx-auto' color='black' fadeIn='none' />)
            : (
              <i className='material-icons'>
                search
              </i>)}
        </button>
      </div>
      <div>Enter lat/long coordinates above or click on the map to drop an initial pin</div>
      <div className={styles.errorMessage}>{resultsError}</div>
      <div>
        <div className={mapClass || 'static-google-map'} id={mapId} />
      </div>
    </div>
  )
}

LatLngSearchGoogleMap.propTypes = {
  mapClass: PropTypes.string,
  mapId: PropTypes.string.isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  onLocationChange: PropTypes.func.isRequired,
  address: PropTypes.string,
  hauler: PropTypes.object
}
