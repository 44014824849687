import { loader } from 'graphql.macro'

export default function feeTypes ({ dataLoaders, queryKey }) {
  const [, status] = queryKey
  const graphQLVariables = {
    status
  }
  const feeTypesSchema = loader('./feeTypes.graphql')
  return dataLoaders.dispatcher.graphQLClient.request(feeTypesSchema, graphQLVariables)
}
