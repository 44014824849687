import moment from 'moment'

export const baseCounts = {
  open: 0,
  en_route: 0,
  completed: 0,
  cancelled: 0
}

export const options = {
  maintainAspectRatio: true,
  tooltips: {
    mode: 'y-axis'
  },
  scales: {
    yAxes: [
      {
        stacked: true
      }
    ],
    xAxes: [
      {
        ticks: { stepSize: 1, min: 0 },
        stacked: true
      }
    ]
  }
}

export const getElementAtEvent = (element, history, ticketsByUser) => {
  // eslint-disable-next-line
  if (element[0] && element[0]._model.label !== 'Unassigned') {
    // eslint-disable-next-line
    const key = Object.keys(ticketsByUser)[element[0]._index]
    const driverId = ticketsByUser[key].user.id
    const today = moment().format('YYYY-MM-DD')
    if (driverId) {
      history.push(`/hauler/tickets/search?driverId=${driverId}&minDate=${today}&maxDate=${today}`)
    } else {
      alert('We cannot filter on an unassigned ticket. Please select an available driver to search.')
    }
  }
}

export const determineTicketsByUser = (tickets) => (
  tickets.reduce((acc, ticket) => {
    ticket.driver_id = ticket.driver_id || 'Unassigned'
    if (!acc[ticket.driver_id]) {
      acc[ticket.driver_id] = {
        user: ticket.user,
        ...baseCounts
      }
    }
    acc[ticket.driver_id][ticket.status]++
    return acc
  }, {
    Unassigned: {
      user: {
        first_name: 'Unassigned',
        last_name: ''
      },
      ...baseCounts
    }
  })
)

export const determineData = (keys, ticketsByUser) => (
  {
    labels: keys.map((key) => {
      const { user } = ticketsByUser[key]
      return `${user.first_name} ${user.last_name}`
    }),
    datasets: [
      {
        label: 'Open',
        data: keys.map((key) => ticketsByUser[key].open),
        backgroundColor: '#33ca80',
        borderColor: '#33ca80',
        borderWidth: 0
      },
      {
        label: 'En Route',
        data: keys.map((key) => ticketsByUser[key].en_route),
        backgroundColor: '#FF8F00',
        borderColor: '#FF8F00',
        borderWidth: 0
      },
      {
        label: 'Completed',
        data: keys.map((key) => ticketsByUser[key].completed),
        backgroundColor: '#131514',
        borderColor: '#131514',
        borderWidth: 0
      },
      {
        label: 'Cancelled',
        data: keys.map((key) => ticketsByUser[key].cancelled),
        backgroundColor: '#f93b59',
        borderColor: '#f93b59',
        borderWidth: 0
      }
    ]
  }
)
